import { FC } from "react";
import { MultiValueProps, components } from "react-select";
import classNames from "classnames";
import FormInputOption from "../../_common/FormInputOption";

const dropdownMultiValue: (highlightedValue?: string[]) => FC<MultiValueProps<FormInputOption>> =
  (highlightedValue) =>
  (props): JSX.Element => {
    const highlighted = highlightedValue && highlightedValue.includes(props.data.value);

    return (
      <components.MultiValue {...props} className={classNames({ "dropdown__multi-value--highlighted": highlighted })} />
    );
  };

export default dropdownMultiValue;
