import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { FavouriteProjection } from "../../../../projection/favourite/favourite";
import { searchFavouritesByPsId } from "../../../../projection/favourite/searchFavouritesByPsId";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isProductMarkedAsFavourite } from "../../../../domain/favourite/model/productMarkedAsFavourite";
import { isProductUnmarkedAsFavourite } from "../../../../domain/favourite/model/productUnmarkedAsFavourite";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isProductMarkedAsFavourite(event) || isProductUnmarkedAsFavourite(event);

interface UseSearchFavouritesByPsIdFunctionArgs {
  readonly psId: string | undefined;
}

interface UseSearchFavouritesByPsIdFunction {
  (args: UseSearchFavouritesByPsIdFunctionArgs): UseQueryFunctionResult<Record<string, FavouriteProjection>>;
}

const useSearchFavouritesByPsId: UseSearchFavouritesByPsIdFunction = ({ psId }) =>
  useQuery({
    query: searchFavouritesByPsId({ psId: psId as string }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: { staleTime: Infinity, retry: 0, refetchOnWindowFocus: false, enabled: psId !== undefined },
  });

export { useSearchFavouritesByPsId };
