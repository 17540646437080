import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpStockSurveySubmit } from "./httpStockSurveySubmit";
import { StockSurveysGetFunction, StockSurveysSaveFunction } from "../../../../domain/stockSurvey/model/stockSurveys";

interface HttpStockSurveysGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpStockSurveysGetFunction extends StockSurveysGetFunction<HttpStockSurveysGetFunctionArgs> {}

const getStockSurvey: HttpStockSurveysGetFunction = () => async () => {
  throw new Error("StockSurvey not found");
};

interface HttpStockSurveysSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpStockSurveysSaveFunction extends StockSurveysSaveFunction<HttpStockSurveysSaveFunctionArgs> {}

const saveStockSurvey: HttpStockSurveysSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await httpStockSurveySubmit({ httpPost })(aggregateRoot);
  };

export type { HttpStockSurveysSaveFunction };
export { getStockSurvey, saveStockSurvey };
