import { useMemo } from "react";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import ShortlistFilterHeader from "../shortlistFilterHeader/ShortlistFilterHeader";
import ShortlistFilterItemRoot from "../shortlistFilterItemRoot/ShortlistFilterItemRoot";
import "./default-host-shortlist-filter-item.css";

const DefaultHostShortlistFilterItem: ShortlistFilterItemHOC = ({ useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function DefaultHostShortlistFilterItemComponent({ filter, children, level }) {
    const ShortlistFilterItemRootComponent = useMemo(() => ShortlistFilterItemRoot({ useShortlistFiltering }), []);

    return (
      <ShortlistFilterItemRootComponent
        className="default-host-shortlist-filter-item"
        filter={filter}
        item={<ShortlistFilterHeader filter={filter} level={level} />}
        level={level}
      >
        {children}
      </ShortlistFilterItemRootComponent>
    );
  };

export default DefaultHostShortlistFilterItem;
