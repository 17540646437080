import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { SelectionAlertProjection } from "./selectionAlert";

const LIST_SELECTION_ALERTS_BY_BOX_NUMBER = "list_selection_alerts_by_box_number";

interface ListSelectionAlertsByBoxNumberPayload {
  readonly boxNumber: string;
}

interface ListSelectionAlertsByBoxNumber
  extends Query<typeof LIST_SELECTION_ALERTS_BY_BOX_NUMBER>,
    ListSelectionAlertsByBoxNumberPayload {}

interface ListSelectionAlertsByBoxNumberFunction {
  (payload: ListSelectionAlertsByBoxNumberPayload): ListSelectionAlertsByBoxNumber;
}

const listSelectionAlertsByBoxNumber: ListSelectionAlertsByBoxNumberFunction = (payload) => ({
  ...query({ name: LIST_SELECTION_ALERTS_BY_BOX_NUMBER }),
  ...payload,
});

type ListSelectionAlertsByBoxNumberResult = SelectionAlertProjection[] | null;

interface SelectionAlertsByBoxNumberViewArgs extends CancelableQueryViewArgs {
  readonly boxNumber: string;
}

interface SelectionAlertsByBoxNumberView {
  (args: SelectionAlertsByBoxNumberViewArgs): Promise<ListSelectionAlertsByBoxNumberResult>;
}

interface ListSelectionAlertsByBoxNumberHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: SelectionAlertsByBoxNumberView;
}

const listSelectionAlertsByBoxNumberHandler: QueryHandlerFunction<
  ListSelectionAlertsByBoxNumber,
  ListSelectionAlertsByBoxNumberResult,
  ListSelectionAlertsByBoxNumberHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxNumber }) =>
    view({ boxNumber, signal });

export type { SelectionAlertsByBoxNumberView, ListSelectionAlertsByBoxNumber, ListSelectionAlertsByBoxNumberResult };
export { LIST_SELECTION_ALERTS_BY_BOX_NUMBER, listSelectionAlertsByBoxNumber, listSelectionAlertsByBoxNumberHandler };
