import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { removeNoteTemplate as removeNoteTemplateCommand } from "../../../../domain/noteTemplate/command/removeNoteTemplate";

interface RemoveNoteTemplateFunctionArgs {
  readonly id: string;
}

interface RemoveNoteTemplateFunction {
  (args: RemoveNoteTemplateFunctionArgs): Promise<void>;
}

type UseRemoveNoteTemplateReturn = [removeNoteTemplate: RemoveNoteTemplateFunction, status: CommandStatus];

interface UseRemoveNoteTemplate {
  (): UseRemoveNoteTemplateReturn;
}

const useRemoveNoteTemplate: UseRemoveNoteTemplate = () => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const removeNoteTemplate: RemoveNoteTemplateFunction = useCallback(
    async ({ id }) => {
      try {
        await commandBus(removeNoteTemplateCommand({ aggregateId: id }));
      } catch (error) {}
    },
    [commandBus],
  );

  return [removeNoteTemplate, status];
};

export { useRemoveNoteTemplate };
