import { useI18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import { FeedbackAnswer } from "../../../../core/projection/feedback/model/Feedback";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../_i18n/HistoryI18n";
import "./feedback.css";

const iconAndLabelForFeedback = (feedbackAnswer: FeedbackAnswer): { icon: IconVariant; label: string } => {
  switch (feedbackAnswer) {
    case FeedbackAnswer.ITEM_DIFFERENT_DESCRIPTION:
      return {
        icon: IconVariant.UNEQUAL_DESCRIPTION,
        label: HistoryI18n.FEEDBACK_ITEM_DIFFERENT_DESCRIPTION,
      };
    case FeedbackAnswer.ITEM_DIFFERENT_IMAGE:
      return {
        icon: IconVariant.UNEQUAL_PHOTO,
        label: HistoryI18n.FEEDBACK_ITEM_DIFFERENT_IMAGE,
      };
    case FeedbackAnswer.ITEM_DIFFERENT_ITEM:
      return {
        icon: IconVariant.UNEQUAL_PRODUCT,
        label: HistoryI18n.FEEDBACK_ITEM_DIFFERENT_ITEM,
      };
    case FeedbackAnswer.ITEM_QUALITY_WORSE:
      return {
        icon: IconVariant.STAMP,
        label: HistoryI18n.FEEDBACK_ITEM_QUALITY_WORSE,
      };
    case FeedbackAnswer.ITEM_SHAPE_DONT_LIKE:
      return {
        icon: IconVariant.SCISSORS,
        label: HistoryI18n.FEEDBACK_ITEM_SHAPE_DONT_LIKE,
      };
    case FeedbackAnswer.ITEM_SHAPE_RIGHT:
      return {
        icon: IconVariant.SCISSORS,
        label: HistoryI18n.FFEDBACK_ITEM_SHAPE_RIGHT,
      };
    case FeedbackAnswer.ITEM_SIZE_BIG:
      return {
        icon: IconVariant.SIZING_LARGE,
        label: HistoryI18n.FEEDBACK_ITEM_SIZE_BIG,
      };
    case FeedbackAnswer.ITEM_SIZE_SMALL:
      return {
        icon: IconVariant.SIZING_SMALL,
        label: HistoryI18n.FEEDBACK_ITEM_SIZE_SMALL,
      };
  }
};

type FeedbackProps = {
  readonly feedbackAnswer: FeedbackAnswer;
};
const Feedback: FC<FeedbackProps> = ({ feedbackAnswer }: FeedbackProps) => {
  const { icon, label } = iconAndLabelForFeedback(feedbackAnswer);
  const tooltipLabel = useI18nMessage({ id: label, prefix: HISTORY_I18N_PREFIX });

  return (
    <div className="feedback">
      <Tooltip title={tooltipLabel}>
        <Icon variant={icon} />
      </Tooltip>
    </div>
  );
};

export default Feedback;
