import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import {
  HistoryShopByCustomerIdView,
  SearchHistoryShopByCustomerIdResult,
} from "../../../projection/historyShop/searchHistoryShopByCustomerId";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { HistoryShopProductVariantDto, toHistoryShopProductVariantProjection } from "./historyShop";

interface HttpHistoryShopByCustomerIdView extends HistoryShopByCustomerIdView {}

interface HttpHistoryShopByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpHistoryShopByCustomerIdViewFunction {
  (args: HttpHistoryShopByCustomerIdViewFunctionArgs): HttpHistoryShopByCustomerIdView;
}

interface SearchHistoryShopByCustomerIdResponse {
  readonly result: HistoryShopProductVariantDto[];
}

const httpHistoryShopByCustomerIdView: HttpHistoryShopByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<SearchHistoryShopByCustomerIdResponse, SearchHistoryShopByCustomerIdResult>({
      endpoint: "/search-history-shop-by-customer-id",
      body: { customer_id: customerId },
      signal,
      result: {
        error: null,
        success: (response) => camelcaseKeys(response.result).map(toHistoryShopProductVariantProjection),
      },
    });

export { httpHistoryShopByCustomerIdView };
