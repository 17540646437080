import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const SELECTION_ORDER_REMOVED = "selection_order_removed";

interface SelectionOrderRemovedPayload {
  readonly aggregateId: string;
}

interface SelectionOrderRemoved extends DomainEvent<typeof SELECTION_ORDER_REMOVED> {}

interface SelectionOrderRemovedFunction {
  (payload: SelectionOrderRemovedPayload): SelectionOrderRemoved;
}

const selectionOrderRemoved: SelectionOrderRemovedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: SELECTION_ORDER_REMOVED });

const isSelectionOrderRemoved = (event: DomainEvent<MessageName>): event is SelectionOrderRemoved =>
  event.name === SELECTION_ORDER_REMOVED;

export type { SelectionOrderRemoved };
export { SELECTION_ORDER_REMOVED, selectionOrderRemoved, isSelectionOrderRemoved };
