import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { Customer } from "../../../../projection/customer/customer";
import { viewCustomerById } from "../../../../projection/customer/viewCustomerById";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface UseViewCustomerByIdFunctionArgs {
  readonly id: string;
}

interface UseViewCustomerByIdFunction {
  (args: UseViewCustomerByIdFunctionArgs): UseQueryFunctionResult<Customer>;
}

const useViewCustomerById: UseViewCustomerByIdFunction = ({ id }) =>
  useQuery({
    query: viewCustomerById({ id }),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

export { useViewCustomerById };
