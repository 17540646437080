import React, { createContext, FC, ReactNode, useContext } from "react";
import invariant from "tiny-invariant";
import { Logger } from "./Logger";

const LoggerContext = createContext<Logger>(null as unknown as Logger);

interface LoggerContextProviderProps {
  readonly logger: Logger;
  readonly children: ReactNode;
}

const LoggerProvider: FC<LoggerContextProviderProps> = ({ logger, children }) => (
  <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>
);

interface UseLoggerFunction {
  (): Logger;
}

const useLogger: UseLoggerFunction = () => {
  const logger = useContext(LoggerContext);

  invariant(logger, "Your are trying to use the useLogger hook without wrapping your app with the <LoggerProvider>.");

  return logger;
};

export { useLogger, LoggerProvider };
