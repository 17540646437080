import { FC, ReactNode } from "react";
import Button from "../../atoms/button/Button";
import { IconVariant } from "../../atoms/icon/Icon";
import Text, { TextVariant } from "../../atoms/text/Text";
import classNames from "classnames";
import "./pill.css";

interface PillProps {
  readonly className?: string;
  readonly children: ReactNode;
  readonly onRemove?: () => void;
}

const Pill: FC<PillProps> = ({ className, children, onRemove }: PillProps) => (
  <div className={classNames("pill", className)}>
    <Text className="pill__content" variant={TextVariant.BODY_SMALL}>
      {children}
    </Text>
    {onRemove && <Button className="pill__remove" icon={IconVariant.CLOSE} type="button" onClick={onRemove} />}
  </div>
);

export default Pill;
