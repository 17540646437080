import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { viewPendingBoxes } from "../../../../projection/pendingBox/viewPendingBoxes";
import { PendingBoxProjection } from "../../../../projection/pendingBox/pendingBox";

interface UseViewPendingBoxesFunction {
  (): UseQueryFunctionResult<PendingBoxProjection[]>;
}

const useViewPendingBoxes: UseViewPendingBoxesFunction = () =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewPendingBoxes(),
    options: {
      staleTime: 60 * 1000,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useViewPendingBoxes };
