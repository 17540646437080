import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { viewBoxByBoxNumber, ViewBoxByBoxNumberResult } from "./viewBoxByBoxNumber";
import {
  canPersonalShopperSendBoxesToSupervise,
  CanPersonalShopperSendBoxesToSuperviseResult,
} from "../personalShopper/canPersonalShopperSendBoxesToSupervise";
import nonSupervisableBoxStatuses from "../../domain/box/model/NonSupervisableBoxStatuses";
import { containsExactSupervisableProductVariantsCount } from "../selection/containsExactSupervisableProductVariantsCount";

const CAN_THE_BOX_SENT_TO_SUPERVISE = "can_the_box_sent_to_supervise";

interface CanTheBoxSentToSupervisePayload {
  readonly boxNumber: string;
}

interface CanTheBoxSentToSupervise
  extends Query<typeof CAN_THE_BOX_SENT_TO_SUPERVISE>,
    CanTheBoxSentToSupervisePayload {}

interface CanTheBoxSentToSuperviseFunction {
  (payload: CanTheBoxSentToSupervisePayload): CanTheBoxSentToSupervise;
}

const canTheBoxSentToSupervise: CanTheBoxSentToSuperviseFunction = (payload) => ({
  ...query({ name: CAN_THE_BOX_SENT_TO_SUPERVISE }),
  ...payload,
});

type CanTheBoxSentToSuperviseResult = boolean;

interface CanTheBoxSentToSuperviseHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const canTheBoxSentToSuperviseHandler: QueryHandlerFunction<
  CanTheBoxSentToSupervise,
  CanTheBoxSentToSuperviseResult,
  CanTheBoxSentToSuperviseHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ boxNumber }) => {
    const canSendBoxesToSupervise: CanPersonalShopperSendBoxesToSuperviseResult = await queryBus(
      canPersonalShopperSendBoxesToSupervise(),
    );

    if (!canSendBoxesToSupervise) {
      return false;
    }

    const box: ViewBoxByBoxNumberResult = await queryBus(viewBoxByBoxNumber({ boxNumber }));

    if (!box) {
      return false;
    }

    const isBoxStatusValid = !nonSupervisableBoxStatuses.includes(box.status);

    if (!isBoxStatusValid) {
      return false;
    }

    return await queryBus(containsExactSupervisableProductVariantsCount({ boxNumber }));
  };

export type { CanTheBoxSentToSupervise, CanTheBoxSentToSuperviseResult };
export { CAN_THE_BOX_SENT_TO_SUPERVISE, canTheBoxSentToSupervise, canTheBoxSentToSuperviseHandler };
