import { I18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback } from "react";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import EditorToolbarButton from "../../../componentLibrary/organisms/editor/editorToolbarButton/EditorToolbarButton";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../../componentLibrary/_i18n/NoteI18n";
import NoteTemplateI18n, { NOTE_TEMPLATE_I18N_PREFIX } from "../../../componentLibrary/_i18n/NoteTemplateI18n";
import { Routes } from "../../../_routing/Routing";
import { NoteAction } from "../../../../shared/tracking/Tracker";
import { generatePath, useNavigate } from "react-router-dom";
import { NoteProjection } from "../../../../core/projection/note/note";

type NoteEditorToolbarExtraActionsProps = {
  readonly locale: string;
  readonly legacyBoxId: string;
  readonly note?: NoteProjection;
  readonly onClick?: (action: NoteAction) => void;
};
const NoteEditorToolbarExtraActions: FC<NoteEditorToolbarExtraActionsProps> = ({
  locale,
  legacyBoxId,
  note,
  onClick,
}) => {
  const navigate = useNavigate();

  const previewNote = useCallback(() => {
    navigate(generatePath(Routes.BOX_SELECTION_PREVIEW_NOTE, { locale, box: legacyBoxId }));
    onClick?.("preview-note");
  }, [legacyBoxId, locale, navigate, onClick]);

  const viewHistoryNotes = useCallback(() => {
    navigate(generatePath(Routes.BOX_SELECTION_HISTORY_NOTES, { locale, box: legacyBoxId }));
    onClick?.("view-history-notes");
  }, [legacyBoxId, locale, navigate, onClick]);

  const viewNoteTemplates = useCallback(() => {
    navigate(generatePath(Routes.BOX_SELECTION_NOTES_TEMPLATES, { locale, box: legacyBoxId }));
    onClick?.("view-note-templates");
  }, [legacyBoxId, locale, navigate, onClick]);

  return (
    <>
      {note ? (
        <EditorToolbarButton
          icon={IconVariant.DOCUMENT_ZOOM}
          label="preview-toolbar-button"
          title={<I18nMessage id={NoteI18n.EDITOR_PREVIEW} prefix={NOTE_I18N_PREFIX} />}
          onClick={previewNote}
        />
      ) : null}
      <EditorToolbarButton
        icon={IconVariant.DOCUMENT_STACK}
        label="history-notes-toolbar-button"
        title={<I18nMessage id={NoteI18n.HISTORY_NOTES} prefix={NOTE_I18N_PREFIX} />}
        onClick={viewHistoryNotes}
      />
      <EditorToolbarButton
        icon={IconVariant.DOCUMENT_WRITE}
        label="note-templates-toolbar-button"
        title={<I18nMessage id={NoteTemplateI18n.TEMPLATES_TITLE} prefix={NOTE_TEMPLATE_I18N_PREFIX} />}
        onClick={viewNoteTemplates}
      />
    </>
  );
};

export default NoteEditorToolbarExtraActions;
