import Country from "./Country";
import { Language } from "./language";

/* eslint-disable @typescript-eslint/naming-convention */
enum Locale {
  es_ES = "es-ES",
  fr_FR = "fr-FR",
  fr_BE = "fr-BE",
  en_GB = "en-GB",
  it_IT = "it-IT",
  pt_PT = "pt-PT",
  de_DE = "de-DE",
  de_AT = "de-AT",
  nl_NL = "nl-NL",
  sv_SE = "sv-SE",
  de_CH = "de-CH",
  fr_CH = "fr-CH",
}
/* eslint-enable @typescript-eslint/naming-convention */

const LOCALE: Record<Locale, [Language, Country]> = {
  [Locale.es_ES]: [Language.ES, Country.ES],
  [Locale.fr_FR]: [Language.FR, Country.FR],
  [Locale.fr_BE]: [Language.FR, Country.BE],
  [Locale.en_GB]: [Language.EN, Country.GB],
  [Locale.it_IT]: [Language.IT, Country.IT],
  [Locale.pt_PT]: [Language.PT, Country.PT],
  [Locale.de_DE]: [Language.DE, Country.DE],
  [Locale.de_AT]: [Language.DE, Country.AT],
  [Locale.nl_NL]: [Language.NL, Country.NL],
  [Locale.sv_SE]: [Language.SV, Country.SE],
  [Locale.de_CH]: [Language.DE, Country.CH],
  [Locale.fr_CH]: [Language.FR, Country.CH],
};

const DEFAULT_LOCALE_FOR_LANGUAGE: Record<Language, Locale> = {
  [Language.ES]: Locale.es_ES,
  [Language.FR]: Locale.fr_FR,
  [Language.EN]: Locale.en_GB,
  [Language.IT]: Locale.it_IT,
  [Language.PT]: Locale.pt_PT,
  [Language.DE]: Locale.de_DE,
  [Language.NL]: Locale.nl_NL,
  [Language.SV]: Locale.sv_SE,
};

const isValidLocale = (locale: Locale): boolean => Boolean(LOCALE[locale]);

export { LOCALE, DEFAULT_LOCALE_FOR_LANGUAGE, isValidLocale };
export default Locale;
