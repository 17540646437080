import { FC, useCallback, useMemo } from "react";
import { EditorState } from "draft-js";
import Text, { TextVariant } from "../../../shared/ui/uiKit/atoms/text/Text";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../componentLibrary/_i18n/NoteI18n";
import { I18nMessage } from "@lookiero/i18n-react";
import Editor from "../../componentLibrary/organisms/editor/Editor";
import { useViewNoteByBoxId } from "../../../core/infrastructure/projection/note/react/useViewNoteByBoxId";
import useBoxSharedContext from "../_behaviors/useBoxSharedContext";
import Loader from "../../../shared/ui/uiKit/atoms/loader/Loader";
import NoteEditorStatus from "./noteEditorStatus/NoteEditorStatus";
import filterNoteContent from "./filterNoteContent";
import NoteEditorToolbarExtraActions from "./noteEditorToolbarExtraActions/NoteEditorToolbarExtraActions";
import NoteEditorToolbar from "../../componentLibrary/organisms/noteEditorToolbar/NoteEditorToolbar";
import { IconVariant } from "../../../shared/ui/uiKit/atoms/icon/Icon";
import Button from "../../../shared/ui/uiKit/atoms/button/Button";
import { useEditorSharedContext } from "../_behaviors/useEditorSharedContext";
import { NoteAction } from "../../../shared/tracking/Tracker";
import { useParams } from "react-router-dom";
import { useWriteNote } from "../../../core/infrastructure/domain/note/react/useWriteNote";
import AsyncActionState from "../../../shared/ui/uiKit/_common/AsyncActionState";
import { CommandStatus, QueryStatus } from "@lookiero/messaging-react";
import { useGenerateNote } from "../../../core/infrastructure/domain/note/react/useGenerateNote";
import { useCanPersonalShopperWriteNote } from "../../../core/infrastructure/projection/personalShopper/react/useCanPersonalShopperWriteNote";
import { useViewPersonalShopper } from "../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { NoteAssistant } from "./noteAssistant/NoteAssistant";
import "./note.css";

interface NoteProps {
  readonly onToggle: () => void;
  readonly onClickNoteEditorToolbarExtraActions?: (action: NoteAction) => void;
}
const Note: FC<NoteProps> = ({ onToggle, onClickNoteEditorToolbarExtraActions }) => {
  const { locale, box: legacyBoxId } = useParams();
  const { box } = useBoxSharedContext();
  const { editorRef } = useEditorSharedContext();

  const [personalShopper] = useViewPersonalShopper();
  const [canWriteNote] = useCanPersonalShopperWriteNote({ boxId: box.id });

  const [note, noteStatus] = useViewNoteByBoxId({ boxId: box.id });
  const [writeNote, writeNoteStatus] = useWriteNote({
    id: note?.id,
    psId: personalShopper?.id,
    legacyBoxId: String(box.legacyId),
    boxId: box.id,
  });
  const [generateNote, generateNoteStatus] = useGenerateNote({
    boxId: box.id,
    id: note?.id,
    psId: personalShopper?.id,
  });
  const handleOnEditorChanged = useCallback((content: string) => writeNote({ content }), [writeNote]);

  const toolbarExtraActions = useMemo(
    () => (
      <>
        <NoteEditorToolbarExtraActions
          legacyBoxId={legacyBoxId as string}
          locale={locale as string}
          note={note}
          onClick={onClickNoteEditorToolbarExtraActions}
        />

        <NoteAssistant
          boxId={box.id}
          content={note?.content}
          isPlaceholder={note?.isPlaceholder}
          noteGenerated={note?.noteGenerated}
          status={generateNoteStatus}
          onGenerate={generateNote}
        />
      </>
    ),
    [box.id, generateNote, generateNoteStatus, legacyBoxId, locale, note, onClickNoteEditorToolbarExtraActions],
  );
  const noteEditorState: AsyncActionState = useMemo(() => {
    if (writeNoteStatus === CommandStatus.ERROR || generateNoteStatus === CommandStatus.ERROR) {
      return AsyncActionState.ERROR;
    }

    if (writeNoteStatus === CommandStatus.LOADING || generateNoteStatus === CommandStatus.LOADING) {
      return AsyncActionState.PENDING;
    }

    if (writeNoteStatus === CommandStatus.SUCCESS || generateNoteStatus === CommandStatus.SUCCESS) {
      return AsyncActionState.SUCCESS;
    }

    return AsyncActionState.DEFAULT;
  }, [generateNoteStatus, writeNoteStatus]);

  const toolbar = useCallback(
    (state: EditorState, onChange: (state: EditorState) => void) => (
      <NoteEditorToolbar extraActions={toolbarExtraActions} state={state} onChange={onChange}>
        <NoteEditorStatus state={noteEditorState} />
      </NoteEditorToolbar>
    ),
    [toolbarExtraActions, noteEditorState],
  );

  return (
    <section className="note">
      <header className="note__header">
        <Button aria-label="note-header-collapse-note-button" icon={IconVariant.CHEVRON_RIGHT} onClick={onToggle} />
        <Text variant={TextVariant.HEADING_3}>
          <I18nMessage id={NoteI18n.HEADER_TITLE} prefix={NOTE_I18N_PREFIX} />
        </Text>
        {noteStatus === QueryStatus.LOADING && <Loader />}
      </header>

      <div className="note__editor">
        {note?.content !== undefined && (
          <Editor
            ref={editorRef}
            disabled={!canWriteNote || generateNoteStatus === CommandStatus.LOADING}
            filter={filterNoteContent}
            toolbar={toolbar}
            value={note.content}
            onChanged={handleOnEditorChanged}
          />
        )}
      </div>
    </section>
  );
};

export default Note;
