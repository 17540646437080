import { EventBus } from "@lookiero/messaging.js";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import BoxPreview from "../../../../domain/boxPreview/model/BoxPreview";
import BoxPreviews from "../../../../domain/boxPreview/model/BoxPreviews";
import { boxPreviewByBoxIdRequestPayload, sendBoxPreviewRequestPayload } from "./requestBuilder";
import { toBoxPreview } from "./responseMapper";
import { camelcaseKeys } from "../../../../../shared/delivery/infrastructure/camelcaseKeys";

class HttpBoxPreviews implements BoxPreviews {
  private readonly httpClient: HttpClient;
  private readonly eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async byBoxId(boxId: string): Promise<BoxPreview | undefined> {
    try {
      const requestPayload = boxPreviewByBoxIdRequestPayload(boxId);

      const response = await this.httpClient.post("/view-box-preview-by-box-id", requestPayload);

      if (!response.ok) {
        if (response.status === 404) {
          return undefined;
        }

        throw new Error("View BoxPreview by Box Id");
      }

      const { result: boxPreviewResponse } = await response.json();

      const boxPreview: BoxPreview = toBoxPreview(camelcaseKeys(boxPreviewResponse));

      return boxPreview;
    } catch (error) {
      console.error(error);
      throw new Error("View BoxPreview by Box Id");
    }
  }

  public async send(boxPreview: BoxPreview): Promise<void> {
    try {
      const requestPayload = sendBoxPreviewRequestPayload(
        boxPreview.boxPreviewId,
        boxPreview.boxId,
        boxPreview.looks.looks,
      );

      const response = await this.httpClient.post("/send-box-preview", requestPayload);

      if (!response.ok) {
        throw new Error("Send BoxPreview");
      }

      this.eventBus.publish(boxPreview.recordedEvents());
    } catch (error) {
      console.error(error);
      throw new Error("Send BoxPreview");
    }
  }
}

export default HttpBoxPreviews;
