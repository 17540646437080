import { useCallback } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { QueryParameterValue, read, produce } from "./queryParams";
import QueryParams from "./config";

interface SetParamsFunction {
  (params: Partial<Record<QueryParams, QueryParameterValue>>, navigateOptions?: NavigateOptions): void;
}

type UseQueryParametersApi = {
  params: Partial<Record<QueryParams, string | string[]>>;
  setParams: SetParamsFunction;
};
const useQueryParameters = (): UseQueryParametersApi => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParams: SetParamsFunction = useCallback(
    (paramsToUpdate, navigateOptions) => setSearchParams(produce(read(searchParams), paramsToUpdate), navigateOptions),
    [searchParams, setSearchParams],
  );

  return { params: read(searchParams), setParams };
};

export default useQueryParameters;
