import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { StartAutomaticSelection } from "../command/startAutomaticSelection";
import { automaticSelectionStarted } from "./automaticSelectionStarted";

interface AutomaticSelection extends AggregateRoot {
  readonly boxId: string;
}

const startAutomaticSelectionHandler: CommandHandlerFunction<StartAutomaticSelection, AutomaticSelection> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxId } = command;

    return {
      ...aggregateRoot,
      boxId,
      domainEvents: [automaticSelectionStarted({ aggregateId })],
    };
  };

export type { AutomaticSelection };
export { startAutomaticSelectionHandler };
