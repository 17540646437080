import { FavouriteProjection } from "../../../projection/favourite/favourite";

interface FavouriteDto {
  readonly id: string;
  readonly psId: string;
  readonly productId: string;
  readonly isEnabled: boolean;
}

interface ToFavouritesProjectionFunctionArgs {
  readonly favourites: FavouriteDto[];
}

interface ToFavouritesProjectionFunction {
  (args: ToFavouritesProjectionFunctionArgs): Record<string, FavouriteProjection>;
}

const toFavouritesProjection: ToFavouritesProjectionFunction = ({ favourites }) =>
  favourites.reduce(
    (acc, favourite) => ({
      ...acc,
      [favourite.productId]: favourite,
    }),
    {},
  );

export type { FavouriteDto };
export { toFavouritesProjection };
