import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import Country from "../../../../domain/country/model/Country";
import { viewCapacityCalendarByCriteria } from "../../../../projection/capacityCalendar/viewCapacityCalendarByCriteria";
import { Segment } from "../../../../projection/segment/segment";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isCapacityCalendarSynchronized } from "../../../../domain/capacityCalendar/model/capacityCalendarSynchronized";
import { CapacityCalendarProjection } from "../../../../projection/capacityCalendar/capacityCalendar";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isCapacityCalendarSynchronized(event);

interface UseViewCapacityCalendarByCriteriaFunctionArgs {
  readonly from: Date;
  readonly to: Date;
  readonly markets: Country[];
  readonly segments: Segment[];
  readonly page: number;
  readonly perPage: number;
}

interface UseViewCapacityCalendarByCriteriaFunction {
  (args: UseViewCapacityCalendarByCriteriaFunctionArgs): UseQueryFunctionResult<
    Record<number, CapacityCalendarProjection[]>
  >;
}

const useViewCapacityCalendarByCriteria: UseViewCapacityCalendarByCriteriaFunction = ({
  page,
  perPage,
  ...criteria
}) => {
  const [pagedCapacityCalendar, setPagedCapacityCalendar] = useState<Record<number, CapacityCalendarProjection[]>>({});
  const pagedCapacityCalendarRef = useRef(pagedCapacityCalendar);
  pagedCapacityCalendarRef.current = pagedCapacityCalendar;
  const criteriaRef = useRef(criteria);

  /**
   * Reset pagedCapacityCalendar when criteria changes
   */
  useEffect(() => {
    if (isEqual(criteriaRef.current, criteria)) {
      return;
    }

    criteriaRef.current = criteria;

    setPagedCapacityCalendar({});
    pagedCapacityCalendarRef.current = {};
  }, [criteria]);

  const [capacityCalendar, status] = useQuery<CapacityCalendarProjection[], Error>({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewCapacityCalendarByCriteria({ ...criteria, page, perPage }),
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

  /**
   * Add new page to pagedCapacityCalendar
   */
  useEffect(() => {
    if (!capacityCalendar || isEqual(pagedCapacityCalendarRef.current[page], capacityCalendar)) {
      return;
    }

    setPagedCapacityCalendar({ ...pagedCapacityCalendarRef.current, [page]: capacityCalendar });
  }, [capacityCalendar, page]);

  return [pagedCapacityCalendar, status];
};

export { useViewCapacityCalendarByCriteria };
