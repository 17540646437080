const REAL_TIME_BOX_PRODUCTION_I18N_PREFIX = "labs.real_time_box_production.";

enum RealTimeBoxProductionI18n {
  TITLE = "title",
  PERSONAL_SHOPPER_TEAMS_DROPDOWN_LABEL = "dropdown.personal_shopper_teams_label",
  PERSONAL_SHOPPER_COUNTRIES_DROPDOWN_LABEL = "dropdown.personal_shopper_countries_label",
  PENDING_BOXES_TITLE = "pending_boxes.title",
  CHART_TITLE = "chart.title",
  CLOSED_BOXES_CHART_LABEL = "chart.closed_boxes_label",
  SENT_PREVIEWS_CHART_LABEL = "chart.sent_previews_label",
}

export { REAL_TIME_BOX_PRODUCTION_I18N_PREFIX };

export default RealTimeBoxProductionI18n;
