import { ErrorBoundary, FallbackRender } from "@sentry/react";
import { FC, ReactElement, ReactNode } from "react";
import { Logger } from "./Logger";
import { LoggerProvider } from "./useLogger";

type SentryErrorHOCProps = {
  readonly children: ReactNode;
  readonly fallback?: ReactElement | FallbackRender;
  readonly showDialog?: boolean;
  readonly logger: Logger;
};
const SentryErrorHOC: FC<SentryErrorHOCProps> = ({
  children,
  fallback,
  showDialog = false,
  logger,
}: SentryErrorHOCProps) => (
  <ErrorBoundary fallback={fallback} showDialog={showDialog}>
    <LoggerProvider logger={logger}>{children}</LoggerProvider>
  </ErrorBoundary>
);

export { SentryErrorHOC };
