import { command, Command } from "@lookiero/messaging";

const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

interface RemoveNotificationPayload {
  readonly aggregateId: string;
}

interface RemoveNotification extends Command<typeof REMOVE_NOTIFICATION>, RemoveNotificationPayload {}

interface RemoveNotificationFunction {
  (payload: RemoveNotificationPayload): RemoveNotification;
}

const removeNotification: RemoveNotificationFunction = ({ aggregateId }) =>
  command({ aggregateId, name: REMOVE_NOTIFICATION });

export type { RemoveNotification };
export { REMOVE_NOTIFICATION, removeNotification };
