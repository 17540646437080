import { FC, useCallback } from "react";
import { IconVariant } from "../../../../../../shared/ui/uiKit/atoms/icon/Icon";
import AsyncActionState from "../../../../../../shared/ui/uiKit/_common/AsyncActionState";
import ProductPreviewActionButton from "../../../../../componentLibrary/molecules/productPreviewActionButton/ProductPreviewActionButton";
import { SelectionProductVariantProjection } from "../../../../../../core/projection/selection/selection";

interface OnMarkAsCandidateFunctionArgs {
  readonly productVariantId: string;
}

interface OnMarkAsCandidateFunction {
  (args: OnMarkAsCandidateFunctionArgs): Promise<void>;
}

interface OnUnmarkAsCandidateFunctionArgs {
  readonly productVariantId: string;
}

interface OnUnmarkAsCandidateFunction {
  (args: OnUnmarkAsCandidateFunctionArgs): Promise<void>;
}

type SelectionProductPreviewCandidateActionsProps = {
  readonly selectedProductVariant: SelectionProductVariantProjection;
  readonly markAsCandidateState: AsyncActionState | undefined;
  readonly onMarkAsCandidate: OnMarkAsCandidateFunction;
  readonly unmarkAsCandidateState: AsyncActionState | undefined;
  readonly onUnmarkAsCandidate: OnUnmarkAsCandidateFunction;
};
const SelectionProductPreviewCandidateActions: FC<SelectionProductPreviewCandidateActionsProps> = ({
  selectedProductVariant,
  markAsCandidateState,
  onMarkAsCandidate,
  unmarkAsCandidateState,
  onUnmarkAsCandidate,
}) => {
  const handleOnMarkProductVariantAsCandidate = useCallback(
    () => onMarkAsCandidate({ productVariantId: selectedProductVariant.id }),
    [onMarkAsCandidate, selectedProductVariant],
  );
  const handleOnUnmarkProductVariantAsCandidate = useCallback(
    () => onUnmarkAsCandidate({ productVariantId: selectedProductVariant.id }),
    [onUnmarkAsCandidate, selectedProductVariant],
  );

  return selectedProductVariant.isCandidate ? (
    <ProductPreviewActionButton
      icon={IconVariant.REMOVE}
      label="unmark-as-candidate"
      state={unmarkAsCandidateState}
      onClick={handleOnUnmarkProductVariantAsCandidate}
    />
  ) : (
    <ProductPreviewActionButton
      icon={IconVariant.CHECK}
      label="mark-as-candidate"
      state={markAsCandidateState}
      onClick={handleOnMarkProductVariantAsCandidate}
    />
  );
};

export default SelectionProductPreviewCandidateActions;
