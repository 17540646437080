import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const PRODUCT_UNMARKED_AS_FAVOURITE = "product_unmarked_as_favourite";

interface ProductUnmarkedAsFavouritePayload {
  readonly aggregateId: string;
}

interface ProductUnmarkedAsFavourite extends DomainEvent<typeof PRODUCT_UNMARKED_AS_FAVOURITE> {}

interface ProductUnmarkedAsFavouriteFunction {
  (payload: ProductUnmarkedAsFavouritePayload): ProductUnmarkedAsFavourite;
}

const productUnmarkedAsFavourite: ProductUnmarkedAsFavouriteFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: PRODUCT_UNMARKED_AS_FAVOURITE });

const isProductUnmarkedAsFavourite = (event: DomainEvent<MessageName>): event is ProductUnmarkedAsFavourite =>
  event.name === PRODUCT_UNMARKED_AS_FAVOURITE;

export type { ProductUnmarkedAsFavourite };
export { PRODUCT_UNMARKED_AS_FAVOURITE, productUnmarkedAsFavourite, isProductUnmarkedAsFavourite };
