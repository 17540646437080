import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { LookValidationProjection, ValidationErrors } from "./lookValidation";
import { LookProjection } from "../looks/look";
import { itemsPerLookTemplate, LookTemplate } from "../looks/lookTemplate";

const LIST_LOOK_VALIDATION_ERRORS = "list_look_validation_errors";

interface ListLookValidationErrorsPayload {
  readonly look: LookProjection;
  readonly lookTemplate: LookTemplate;
}

interface ListLookValidationErrors extends Query<typeof LIST_LOOK_VALIDATION_ERRORS>, ListLookValidationErrorsPayload {}

interface ListLookValidationErrorsFunction {
  (payload: ListLookValidationErrorsPayload): ListLookValidationErrors;
}

const listLookValidationErrors: ListLookValidationErrorsFunction = (payload) => ({
  ...query({ name: LIST_LOOK_VALIDATION_ERRORS }),
  ...payload,
});

type ListLookValidationErrorsResult = LookValidationProjection;

interface LookValidationErrorsViewArgs extends CancelableQueryViewArgs {
  readonly look: LookProjection;
  readonly lookTemplate: LookTemplate;
}

interface LookValidationErrorsView {
  (args: LookValidationErrorsViewArgs): Promise<ListLookValidationErrorsResult>;
}

interface ListLookValidationErrorsHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const listLookValidationErrorsHandler: QueryHandlerFunction<
  ListLookValidationErrors,
  ListLookValidationErrorsResult,
  ListLookValidationErrorsHandlerFunctionArgs
> =
  () =>
  async ({ look, lookTemplate }) => {
    const noEmptyProductVariantsLook = look.filter((productVariant) => Boolean(productVariant));

    const isLookValidForTemplate = look.length === itemsPerLookTemplate[lookTemplate];
    const noDuplicatedProductVariants = new Set(noEmptyProductVariantsLook).size === noEmptyProductVariantsLook.length;
    const noEmptyProductVariantsWithinLook = !look.some((productVariant) => !Boolean(productVariant));

    return {
      errors: [
        ...(!isLookValidForTemplate ? [ValidationErrors.NOT_VALID_FOR_TEMPLATE] : []),
        ...(!noDuplicatedProductVariants ? [ValidationErrors.DUPLICATED_PRODUCT_VARIANTS] : []),
        ...(!noEmptyProductVariantsWithinLook ? [ValidationErrors.EMPTY_PRODUCT_VARIANT] : []),
      ],
    };
  };

export type { LookValidationErrorsView, ListLookValidationErrors, ListLookValidationErrorsResult };
export { LIST_LOOK_VALIDATION_ERRORS, listLookValidationErrors, listLookValidationErrorsHandler };
