import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import {
  ViewPersonalShopperResult,
  viewPersonalShopper,
} from "../../../../projection/personalShopper/viewPersonalShopper";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface UseViewPersonalShopperFunction {
  (): UseQueryFunctionResult<ViewPersonalShopperResult>;
}

const useViewPersonalShopper: UseViewPersonalShopperFunction = () =>
  useQuery({
    query: viewPersonalShopper(),
    contextId: MESSAGING_CONTEXT_ID,
    options: { staleTime: Infinity },
  });

export { useViewPersonalShopper };
