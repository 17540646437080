import { WindowsMessengerChild, WindowsMessengerEvents } from "@lookiero/windows-messenger.js";
import isIframeEmbedded from "./isIframeEmbedded";

abstract class WindowsMessenger<
  ChildEvents extends WindowsMessengerEvents,
  ParentEvents extends WindowsMessengerEvents,
> {
  private readonly child: WindowsMessengerChild<ChildEvents, ParentEvents>;

  public constructor(child: WindowsMessengerChild<ChildEvents, ParentEvents>) {
    this.child = child;

    if (isIframeEmbedded()) {
      this.initializeListeners();
    }
  }

  protected abstract initializeListeners(): void;

  protected async emitEvent<K extends keyof ChildEvents>(event: K, payload: ChildEvents[K]): Promise<void> {
    if (!isIframeEmbedded()) {
      return;
    }

    await this.child.emit(event, payload);
  }

  protected async onEvent<K extends keyof ParentEvents>(
    event: K,
    callback: (value: ParentEvents[K]) => void,
  ): Promise<void> {
    if (!isIframeEmbedded()) {
      return;
    }

    this.child.on(event, callback);
  }
}

export default WindowsMessenger;
