import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import invariant from "ts-invariant";
import { markProductAsFavourite as markProductAsFavouriteCommand } from "../../../../domain/favourite/command/markProductAsFavourite";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface MarkProductAsFavouriteFunction {
  (): Promise<void> | undefined;
}

type UseMarkProductAsFavouriteResult = [mark: MarkProductAsFavouriteFunction, status: CommandStatus];

interface UseMarkProductAsFavouriteArgs {
  readonly favouriteId: string;
  readonly psId: string | undefined;
  readonly productId: string;
}

interface UseMarkProductAsFavourite {
  (args: UseMarkProductAsFavouriteArgs): UseMarkProductAsFavouriteResult;
}

const useMarkProductAsFavourite: UseMarkProductAsFavourite = ({ favouriteId, psId, productId }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const markProductAsFavourite: MarkProductAsFavouriteFunction = useCallback(async () => {
    try {
      invariant(psId !== undefined, "There is no personal shopper defined to mark product as favourite");

      await commandBus(
        markProductAsFavouriteCommand({
          aggregateId: favouriteId,
          psId,
          productId,
        }),
      );
    } catch (error) {}
  }, [commandBus, favouriteId, productId, psId]);

  return [markProductAsFavourite, status];
};

export { useMarkProductAsFavourite };
