import { MutableRefObject, RefObject, useRef } from "react";
import { createContext, FC, ReactNode, useCallback, useContext } from "react";
import { invariant } from "ts-invariant";
import { EditorHandle } from "../../componentLibrary/organisms/editor/Editor";
import { EditorState } from "draft-js";

interface EditorSharedContextApi {
  readonly editorRef: RefObject<EditorHandle>;
  readonly editorStateRef: MutableRefObject<EditorState | null>;
  readonly injectTemplate: (content: string) => void;
  readonly replaceContent: (content: string) => void;
}
const EditorSharedContext = createContext<EditorSharedContextApi>(null as unknown as EditorSharedContextApi);

interface EditorSharedContextProviderProps {
  readonly children: ReactNode;
}

const EditorSharedContextProvider: FC<EditorSharedContextProviderProps> = ({ children }) => {
  const editorRef = useRef<EditorHandle>(null);
  const editorStateRef = useRef<EditorState | null>(null);

  const injectTemplate = useCallback((newContent: string) => {
    editorRef.current?.inject(newContent);
  }, []);

  const replaceContent = useCallback((newContent: string) => {
    editorRef.current?.replace(newContent);
  }, []);

  const value: EditorSharedContextApi = {
    editorRef,
    editorStateRef,
    injectTemplate,
    replaceContent,
  };

  return <EditorSharedContext.Provider value={value}>{children}</EditorSharedContext.Provider>;
};

const useEditorSharedContext = (): EditorSharedContextApi => {
  const editorSharedContext = useContext<EditorSharedContextApi>(EditorSharedContext);

  invariant(
    editorSharedContext,
    "Your are trying to use the useEditorSharedContext hook without wrapping your app with the <EditorSharedContextProvider>.",
  );

  return editorSharedContext;
};

export { EditorSharedContextProvider, useEditorSharedContext };
