import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { LookProjection } from "./look";

const LIST_LOOKS_BY_SELECTION_ID = "list_looks_by_selection_id";

interface ListLooksBySelectionIdPayload {
  readonly selectionId: string;
}

interface ListLooksBySelectionId extends Query<typeof LIST_LOOKS_BY_SELECTION_ID>, ListLooksBySelectionIdPayload {}

interface ListLooksBySelectionIdFunction {
  (payload: ListLooksBySelectionIdPayload): ListLooksBySelectionId;
}

const listLooksBySelectionId: ListLooksBySelectionIdFunction = (payload) => ({
  ...query({ name: LIST_LOOKS_BY_SELECTION_ID }),
  ...payload,
});

type ListLooksBySelectionIdResult = LookProjection[];

interface LooksBySelectionIdViewArgs extends CancelableQueryViewArgs {
  readonly selectionId: string;
}

interface LooksBySelectionIdView {
  (args: LooksBySelectionIdViewArgs): Promise<ListLooksBySelectionIdResult>;
}

interface ListLooksBySelectionIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: LooksBySelectionIdView;
}

const listLooksBySelectionIdHandler: QueryHandlerFunction<
  ListLooksBySelectionId,
  ListLooksBySelectionIdResult,
  ListLooksBySelectionIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ selectionId }) =>
    view({ selectionId, signal });

export type { LooksBySelectionIdView, ListLooksBySelectionId, ListLooksBySelectionIdResult };
export { LIST_LOOKS_BY_SELECTION_ID, listLooksBySelectionId, listLooksBySelectionIdHandler };
