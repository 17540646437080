import { FC, SVGProps } from "react";
import classNames from "classnames";

import { ReactComponent as Actions } from "../../_assets/svg/icons/actions.svg";
import { ReactComponent as AlertError } from "../../_assets/svg/icons/alert-error.svg";
import { ReactComponent as AlertWarning } from "../../_assets/svg/icons/alert-warning.svg";
import { ReactComponent as ArrowLeft } from "../../_assets/svg/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../_assets/svg/icons/arrow-right.svg";
import { ReactComponent as ArrowUp } from "../../_assets/svg/icons/arrow-up.svg";
import { ReactComponent as Assistant } from "../../_assets/svg/icons/assistant.svg";
import { ReactComponent as BasicCheck } from "../../_assets/svg/icons/basic-check.svg";
import { ReactComponent as Basket } from "../../_assets/svg/icons/basket.svg";
import { ReactComponent as Bold } from "../../_assets/svg/icons/bold.svg";
import { ReactComponent as Buy } from "../../_assets/svg/icons/buy.svg";
import { ReactComponent as Calendar } from "../../_assets/svg/icons/calendar.svg";
import { ReactComponent as Camera } from "../../_assets/svg/icons/camera.svg";
import { ReactComponent as Check } from "../../_assets/svg/icons/check.svg";
import { ReactComponent as CheckFilled } from "../../_assets/svg/icons/check-filled.svg";
import { ReactComponent as ChevronDown } from "../../_assets/svg/icons/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "../../_assets/svg/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../_assets/svg/icons/chevron-right.svg";
import { ReactComponent as Close } from "../../_assets/svg/icons/close.svg";
import { ReactComponent as Comment } from "../../_assets/svg/icons/comment.svg";
import { ReactComponent as Compare } from "../../_assets/svg/icons/compare.svg";
import { ReactComponent as CurrencyEur } from "../../_assets/svg/icons/eur.svg";
import { ReactComponent as CurrencyGbp } from "../../_assets/svg/icons/gbp.svg";
import { ReactComponent as Dash } from "../../_assets/svg/icons/dash.svg";
import { ReactComponent as Dashboard } from "../../_assets/svg/icons/dashboard.svg";
import { ReactComponent as DocumentStack } from "../../_assets/svg/icons/document-stack.svg";
import { ReactComponent as DocumentWrite } from "../../_assets/svg/icons/document-write.svg";
import { ReactComponent as DocumentWrite2 } from "../../_assets/svg/icons/document-write-2.svg";
import { ReactComponent as DocumentZoom } from "../../_assets/svg/icons/document-zoom.svg";
import { ReactComponent as Export } from "../../_assets/svg/icons/export.svg";
import { ReactComponent as External } from "../../_assets/svg/icons/external.svg";
import { ReactComponent as Eye } from "../../_assets/svg/icons/eye.svg";
import { ReactComponent as EyeStroke } from "../../_assets/svg/icons/eye-stroke.svg";
import { ReactComponent as Facebook } from "../../_assets/svg/icons/facebook.svg";
import { ReactComponent as Garment } from "../../_assets/svg/icons/garment.svg";
import { ReactComponent as GridSizeLarge } from "../../_assets/svg/icons/grid-size-large.svg";
import { ReactComponent as GridSizeSmall } from "../../_assets/svg/icons/grid-size-small.svg";
import { ReactComponent as Heart } from "../../_assets/svg/icons/heart.svg";
import { ReactComponent as HeartCrossed } from "../../_assets/svg/icons/heart-crossed.svg";
import { ReactComponent as HeartFilled } from "../../_assets/svg/icons/heart-filled.svg";
import { ReactComponent as Hourglass } from "../../_assets/svg/icons/hourglass.svg";
import { ReactComponent as Info } from "../../_assets/svg/icons/info.svg";
import { ReactComponent as Instagram } from "../../_assets/svg/icons/instagram.svg";
import { ReactComponent as Label } from "../../_assets/svg/icons/label.svg";
import { ReactComponent as LookTemplate } from "../../_assets/svg/icons/look-template.svg";
import { ReactComponent as LookTemplateFour } from "../../_assets/svg/icons/look-template-four.svg";
import { ReactComponent as LookTemplateThree } from "../../_assets/svg/icons/look-template-three.svg";
import { ReactComponent as LookTemplateTwo } from "../../_assets/svg/icons/look-template-two.svg";
import { ReactComponent as Menu } from "../../_assets/svg/icons/menu.svg";
import { ReactComponent as MinorDropdown } from "../../_assets/svg/icons/minor-dropdown.svg";
import { ReactComponent as Minus } from "../../_assets/svg/icons/minus.svg";
import { ReactComponent as Newsfeed } from "../../_assets/svg/icons/newsfeed.svg";
import { ReactComponent as Order } from "../../_assets/svg/icons/order.svg";
import { ReactComponent as Pin } from "../../_assets/svg/icons/pin.svg";
import { ReactComponent as Pinterest } from "../../_assets/svg/icons/pinterest.svg";
import { ReactComponent as Plus } from "../../_assets/svg/icons/plus.svg";
import { ReactComponent as Print } from "../../_assets/svg/icons/print.svg";
import { ReactComponent as Redo } from "../../_assets/svg/icons/redo.svg";
import { ReactComponent as Remove } from "../../_assets/svg/icons/remove.svg";
import { ReactComponent as RemoveFilled } from "../../_assets/svg/icons/remove-filled.svg";
import { ReactComponent as Scissors } from "../../_assets/svg/icons/scissors.svg";
import { ReactComponent as Search } from "../../_assets/svg/icons/search.svg";
import { ReactComponent as ShapeDiamond } from "../../_assets/svg/icons/shape_diamond.svg";
import { ReactComponent as ShapeOval } from "../../_assets/svg/icons/shape_oval.svg";
import { ReactComponent as ShapeRectangle } from "../../_assets/svg/icons/shape_rectangle.svg";
import { ReactComponent as ShapeTriangle } from "../../_assets/svg/icons/shape_triangle.svg";
import { ReactComponent as ShapeWedge } from "../../_assets/svg/icons/shape_wedge.svg";
import { ReactComponent as ShoesComfortable } from "../../_assets/svg/icons/shoes_comfortable.svg";
import { ReactComponent as ShoesUncomfortable } from "../../_assets/svg/icons/shoes_uncomfortable.svg";
import { ReactComponent as SizingLarge } from "../../_assets/svg/icons/sizing_large.svg";
import { ReactComponent as SizingLarge1 } from "../../_assets/svg/icons/sizing_large_1.svg";
import { ReactComponent as SizingLarge2 } from "../../_assets/svg/icons/sizing_large_2.svg";
import { ReactComponent as SizingLarge3 } from "../../_assets/svg/icons/sizing_large_3.svg";
import { ReactComponent as SizingSmall } from "../../_assets/svg/icons/sizing_small.svg";
import { ReactComponent as SizingSmall1 } from "../../_assets/svg/icons/sizing_small_1.svg";
import { ReactComponent as SizingSmall2 } from "../../_assets/svg/icons/sizing_small_2.svg";
import { ReactComponent as SizingSmall3 } from "../../_assets/svg/icons/sizing_small_3.svg";
import { ReactComponent as SizingCorrect } from "../../_assets/svg/icons/sizing_correct.svg";
import { ReactComponent as Stamp } from "../../_assets/svg/icons/stamp.svg";
import { ReactComponent as Star } from "../../_assets/svg/icons/star.svg";
import { ReactComponent as Style } from "../../_assets/svg/icons/style.svg";
import { ReactComponent as Sync } from "../../_assets/svg/icons/sync.svg";
import { ReactComponent as Trash } from "../../_assets/svg/icons/trash.svg";
import { ReactComponent as Undo } from "../../_assets/svg/icons/undo.svg";
import { ReactComponent as User } from "../../_assets/svg/icons/user.svg";
import { ReactComponent as UnequalDescription } from "../../_assets/svg/icons/unqual_description.svg";
import { ReactComponent as UnequalPhoto } from "../../_assets/svg/icons/unqual_photo.svg";
import { ReactComponent as UnequalProduct } from "../../_assets/svg/icons/unqual_product.svg";
import { ReactComponent as Woman } from "../../_assets/svg/icons/woman.svg";

import "./icon.css";
import invariant from "ts-invariant";

enum IconVariant {
  ACTIONS = "ACTIONS",
  ALERT_ERROR = "ALERT_ERROR",
  ALERT_WARNING = "ALERT_WARNING",
  ARROW_LEFT = "ARROW_LEFT",
  ARROW_RIGHT = "ARROW_RIGHT",
  ARROW_UP = "ARROW_UP",
  ASSISTANT = "ASSISTANT",
  BASKET = "BASKET",
  BOLD = "BOLD",
  BUY = "BUY",
  CAMERA = "CAMERA",
  CALENDAR = "CALENDAR",
  CHECK = "CHECK",
  BASIC_CHECK = "BASIC_CHECK",
  CHECK_FILLED = "CHECK_FILLED",
  CHEVRON_DOWN = "CHEVRON_DOWN",
  CHEVRON_LEFT = "CHEVRON_LEFT",
  CHEVRON_RIGHT = "CHEVRON_RIGHT",
  CLOSE = "CLOSE",
  COMMENT = "COMMENT",
  COMPARE = "COMPARE",
  CURRENCY_EUR = "CURRENCY_EUR",
  CURRENCY_GBP = "CURRENCY_GBP",
  DASH = "DASH",
  DASHBOARD = "DASHBOARD",
  DOCUMENT_STACK = "DOCUMENT_STACK",
  DOCUMENT_WRITE = "DOCUMENT_WRITE",
  DOCUMENT_WRITE_2 = "DOCUMENT_WRITE_2",
  DOCUMENT_ZOOM = "DOCUMENT_ZOOM",
  EXPORT = "EXPORT",
  EXTERNAL = "EXTERNAL",
  EYE = "EYE",
  EYE_STROKE = "EYE_STROKE",
  FACEBOOK = "FACEBOOK",
  GARMENT = "GARMENT",
  GRID_SIZE_LARGE = "GRID_SIZE_LARGE",
  GRID_SIZE_SMALL = "GRID_SIZE_SMALL",
  HEART = "HEART",
  HEART_CROSSED = "HEART_CROSSED",
  HEART_FILLED = "HEART_FILLED",
  HOURGLASS = "HOURGLASS",
  INFO = "INFO",
  INSTAGRAM = "INSTAGRAM",
  LABEL = "LABEL",
  LOOK_TEMPLATE = "LOOK_TEMPLATE",
  LOOK_TEMPLATE_FOUR = "LOOK_TEMPLATE_FOUR",
  LOOK_TEMPLATE_THREE = "LOOK_TEMPLATE_THREE",
  LOOK_TEMPLATE_TWO = "LOOK_TEMPLATE_TWO",
  MENU = "MENU",
  MINOR_DROPDOWN = "MINOR_DROPDOWN",
  MINUS = "MINUS",
  NEWSFEED = "NEWSFEED",
  ORDER = "ORDER",
  PIN = "PIN",
  PINTEREST = "PINTEREST",
  PLUS = "PLUS",
  PRINT = "PRINT",
  REDO = "REDO",
  REMOVE = "REMOVE",
  REMOVE_FILLED = "REMOVE_FILLED",
  SCISSORS = "SCISSORS",
  SEARCH = "SEARCH",
  SHAPE_DIAMOND = "SHAPE_DIAMOND",
  SHAPE_OVAL = "SHAPE_OVAL",
  SHAPE_RECTANGLE = "SHAPE_RECTANGLE",
  SHAPE_TRIANGLE = "SHAPE_TRIANGLE",
  SHAPE_WEDGE = "SHAPE_WEDGE",
  SHOES_COMFORTABLE = "SHOES_COMFORTABLE",
  SHOES_UNCOMFORTABLE = "SHOES_UNCOMFORTABLE",
  SIZING_CORRECT = "SIZING_CORRECT",
  SIZING_LARGE = "SIZING_LARGE",
  SIZING_LARGE_1 = "SIZING_LARGE_1",
  SIZING_LARGE_2 = "SIZING_LARGE_2",
  SIZING_LARGE_3 = "SIZING_LARGE_3",
  SIZING_SMALL = "SIZING_SMALL",
  SIZING_SMALL_1 = "SIZING_SMALL_1",
  SIZING_SMALL_2 = "SIZING_SMALL_2",
  SIZING_SMALL_3 = "SIZING_SMALL_3",
  STAR = "STAR",
  STAMP = "STAMP",
  STYLE = "STYLE",
  SYNC = "SYNC",
  TRASH = "TRASH",
  UNDO = "UNDO",
  UNEQUAL_DESCRIPTION = "UNEQUAL_DESCRIPTION",
  UNEQUAL_PHOTO = "UNEQUAL_PHOTO",
  UNEQUAL_PRODUCT = "UNEQUAL_PRODUCT",
  USER = "USER",
  WOMAN = "WOMAN",
}

const ICON_MAP: Record<IconVariant, FC<SVGProps<SVGSVGElement>>> = {
  [IconVariant.ACTIONS]: Actions,
  [IconVariant.ALERT_ERROR]: AlertError,
  [IconVariant.ALERT_WARNING]: AlertWarning,
  [IconVariant.ARROW_LEFT]: ArrowLeft,
  [IconVariant.ARROW_RIGHT]: ArrowRight,
  [IconVariant.ARROW_UP]: ArrowUp,
  [IconVariant.ASSISTANT]: Assistant,
  [IconVariant.BOLD]: Bold,
  [IconVariant.BUY]: Buy,
  [IconVariant.BASKET]: Basket,
  [IconVariant.CAMERA]: Camera,
  [IconVariant.CALENDAR]: Calendar,
  [IconVariant.CHECK]: Check,
  [IconVariant.BASIC_CHECK]: BasicCheck,
  [IconVariant.CHECK_FILLED]: CheckFilled,
  [IconVariant.CHEVRON_DOWN]: ChevronDown,
  [IconVariant.CHEVRON_LEFT]: ChevronLeft,
  [IconVariant.CHEVRON_RIGHT]: ChevronRight,
  [IconVariant.CLOSE]: Close,
  [IconVariant.CURRENCY_EUR]: CurrencyEur,
  [IconVariant.CURRENCY_GBP]: CurrencyGbp,
  [IconVariant.COMMENT]: Comment,
  [IconVariant.COMPARE]: Compare,
  [IconVariant.DASH]: Dash,
  [IconVariant.DASHBOARD]: Dashboard,
  [IconVariant.DOCUMENT_STACK]: DocumentStack,
  [IconVariant.DOCUMENT_ZOOM]: DocumentZoom,
  [IconVariant.DOCUMENT_WRITE]: DocumentWrite,
  [IconVariant.DOCUMENT_WRITE_2]: DocumentWrite2,
  [IconVariant.EXPORT]: Export,
  [IconVariant.EXTERNAL]: External,
  [IconVariant.EYE]: Eye,
  [IconVariant.EYE_STROKE]: EyeStroke,
  [IconVariant.FACEBOOK]: Facebook,
  [IconVariant.GARMENT]: Garment,
  [IconVariant.GRID_SIZE_LARGE]: GridSizeLarge,
  [IconVariant.GRID_SIZE_SMALL]: GridSizeSmall,
  [IconVariant.HEART]: Heart,
  [IconVariant.HEART_CROSSED]: HeartCrossed,
  [IconVariant.HEART_FILLED]: HeartFilled,
  [IconVariant.HOURGLASS]: Hourglass,
  [IconVariant.INFO]: Info,
  [IconVariant.INSTAGRAM]: Instagram,
  [IconVariant.LABEL]: Label,
  [IconVariant.LOOK_TEMPLATE]: LookTemplate,
  [IconVariant.LOOK_TEMPLATE_TWO]: LookTemplateTwo,
  [IconVariant.LOOK_TEMPLATE_THREE]: LookTemplateThree,
  [IconVariant.LOOK_TEMPLATE_FOUR]: LookTemplateFour,
  [IconVariant.MENU]: Menu,
  [IconVariant.MINUS]: Minus,
  [IconVariant.MINOR_DROPDOWN]: MinorDropdown,
  [IconVariant.NEWSFEED]: Newsfeed,
  [IconVariant.ORDER]: Order,
  [IconVariant.PIN]: Pin,
  [IconVariant.PINTEREST]: Pinterest,
  [IconVariant.PLUS]: Plus,
  [IconVariant.PRINT]: Print,
  [IconVariant.REDO]: Redo,
  [IconVariant.REMOVE_FILLED]: RemoveFilled,
  [IconVariant.REMOVE]: Remove,
  [IconVariant.SCISSORS]: Scissors,
  [IconVariant.SEARCH]: Search,
  [IconVariant.SHAPE_DIAMOND]: ShapeDiamond,
  [IconVariant.SHAPE_OVAL]: ShapeOval,
  [IconVariant.SHAPE_RECTANGLE]: ShapeRectangle,
  [IconVariant.SHAPE_TRIANGLE]: ShapeTriangle,
  [IconVariant.SHAPE_WEDGE]: ShapeWedge,
  [IconVariant.SHOES_COMFORTABLE]: ShoesComfortable,
  [IconVariant.SHOES_UNCOMFORTABLE]: ShoesUncomfortable,
  [IconVariant.SIZING_LARGE_1]: SizingLarge1,
  [IconVariant.SIZING_LARGE_2]: SizingLarge2,
  [IconVariant.SIZING_LARGE_3]: SizingLarge3,
  [IconVariant.SIZING_LARGE]: SizingLarge,
  [IconVariant.SIZING_SMALL_1]: SizingSmall1,
  [IconVariant.SIZING_SMALL_2]: SizingSmall2,
  [IconVariant.SIZING_SMALL]: SizingSmall,
  [IconVariant.SIZING_SMALL_3]: SizingSmall3,
  [IconVariant.SIZING_CORRECT]: SizingCorrect,
  [IconVariant.STAR]: Star,
  [IconVariant.STAMP]: Stamp,
  [IconVariant.STYLE]: Style,
  [IconVariant.SYNC]: Sync,
  [IconVariant.TRASH]: Trash,
  [IconVariant.WOMAN]: Woman,
  [IconVariant.UNDO]: Undo,
  [IconVariant.UNEQUAL_DESCRIPTION]: UnequalDescription,
  [IconVariant.UNEQUAL_PHOTO]: UnequalPhoto,
  [IconVariant.UNEQUAL_PRODUCT]: UnequalProduct,
  [IconVariant.USER]: User,
};

export interface IconProps {
  readonly label?: string;
  readonly className?: string;
  readonly variant: IconVariant;
}
const Icon: FC<IconProps> = ({ label, variant, className }: IconProps) => {
  const IconSvg = ICON_MAP[variant];

  invariant(IconSvg, "No icon found!");

  return (
    <span className={classNames("icon", className)}>
      <IconSvg aria-label={label} role="icon" />
    </span>
  );
};

export { IconVariant };

export default Icon;
