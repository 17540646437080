import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { WriteNoteTemplate } from "../command/writeNoteTemplate";
import { noteTemplateWritten } from "./noteTemplateWritten";
import { RemoveNoteTemplate } from "../command/removeNoteTemplate";
import { noteTemplateRemoved } from "./noteTemplateRemoved";
import { NoteTemplateValidation } from "../../../projection/noteTemplateValidation/noteTemplateValidation";
import { validateNoteTemplate } from "../../../projection/noteTemplateValidation/validateNoteTemplate";

interface NoteTemplate extends AggregateRoot {
  readonly psId: string;
  readonly title: string;
  readonly content: string;
  readonly category: string | undefined;
}

const writeNoteTemplateHandler: CommandHandlerFunction<WriteNoteTemplate, NoteTemplate> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { psId, title, category, content } = command;

    const { errors }: NoteTemplateValidation = await queryBus(
      validateNoteTemplate({
        noteTemplate: { id: aggregateRoot.aggregateId, psId, title, content, category },
      }),
    );

    if (errors.length > 0) {
      throw new Error("The NoteTemplate is not valid");
    }

    return {
      ...aggregateRoot,
      psId,
      title,
      category,
      content,
      domainEvents: [noteTemplateWritten({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

const removeNoteTemplateHandler: CommandHandlerFunction<RemoveNoteTemplate, NoteTemplate> =
  () =>
  async ({ aggregateRoot }) => ({
    ...aggregateRoot,
    domainEvents: [noteTemplateRemoved({ aggregateId: aggregateRoot.aggregateId })],
  });

export type { NoteTemplate };
export { writeNoteTemplateHandler, removeNoteTemplateHandler };
