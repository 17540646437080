import { FC } from "react";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import { SelectionProductVariantProjection } from "../../../../../core/projection/selection/selection";
import "./selection-header-summary-counter.css";

type SelectionHeaderSummaryCounterProps = {
  readonly candidateProductVariants?: SelectionProductVariantProjection[];
  readonly selectionProductVariants?: SelectionProductVariantProjection[];
};
const SelectionHeaderSummaryCounter: FC<SelectionHeaderSummaryCounterProps> = ({
  candidateProductVariants = [],
  selectionProductVariants = [],
}: SelectionHeaderSummaryCounterProps) => {
  const containsCandidates = candidateProductVariants.length > 0;
  const count = containsCandidates ? (
    <>
      <strong>{candidateProductVariants.length}</strong> / {selectionProductVariants.length}
    </>
  ) : (
    selectionProductVariants.length
  );

  return (
    <div className="selection-header-summary-counter" data-testid="selection-summary-header-counter">
      <Text className="selection-header-summary-counter__text" variant={TextVariant.BODY_SMALL}>
        {count}
      </Text>
    </div>
  );
};

export default SelectionHeaderSummaryCounter;
