import { command, Command } from "@lookiero/messaging";

const MARK_PRODUCT_VARIANT_AS_CANDIDATE = "mark_product_variant_as_candidate";

interface MarkProductVariantAsCandidatePayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
  readonly productVariantId: string;
  readonly boxId: string;
}

interface MarkProductVariantAsCandidate
  extends Command<typeof MARK_PRODUCT_VARIANT_AS_CANDIDATE>,
    MarkProductVariantAsCandidatePayload {}

interface MarkProductVariantAsCandidateFunction {
  (payload: MarkProductVariantAsCandidatePayload): MarkProductVariantAsCandidate;
}

const markProductVariantAsCandidate: MarkProductVariantAsCandidateFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: MARK_PRODUCT_VARIANT_AS_CANDIDATE }),
  ...payload,
});
export type { MarkProductVariantAsCandidate };
export { MARK_PRODUCT_VARIANT_AS_CANDIDATE, markProductVariantAsCandidate };
