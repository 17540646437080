import { useI18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback, useMemo } from "react";
import { PersonalShopperCountryProjection } from "../../../../core/projection/personalShopperCountry/personalShopperCountry";
import Dropdown from "../../../../shared/ui/uiKit/organisms/dropdown/Dropdown";
import FormInputOption from "../../../../shared/ui/uiKit/_common/FormInputOption";
import RealTimeBoxProductionI18n, { REAL_TIME_BOX_PRODUCTION_I18N_PREFIX } from "../../_i18n/RealTimeBoxProductionI18n";

interface PersonalShopperCountriesDropdownProps {
  readonly personalShopperCountries: PersonalShopperCountryProjection[];
  readonly personalShopperCountrySelected?: PersonalShopperCountryProjection;
  readonly onChanged: (personalShopperCountry: PersonalShopperCountryProjection) => void;
}
const PersonalShopperCountriesDropdown: FC<PersonalShopperCountriesDropdownProps> = ({
  personalShopperCountries,
  personalShopperCountrySelected,
  onChanged,
}) => {
  const personalShopperCountriesDropdownLabel = useI18nMessage({
    id: RealTimeBoxProductionI18n.PERSONAL_SHOPPER_COUNTRIES_DROPDOWN_LABEL,
    prefix: REAL_TIME_BOX_PRODUCTION_I18N_PREFIX,
  });

  const personalShopperCountriesOptions: FormInputOption[] = useMemo(
    () =>
      personalShopperCountries.map((personalShopperCountry) => ({
        label: personalShopperCountry,
        value: personalShopperCountry,
      })),
    [personalShopperCountries],
  );

  const handleOnPersonalShopperCountryChanged = useCallback(
    (personalShopperCountry: string | string[] | null | undefined) =>
      onChanged(personalShopperCountry as PersonalShopperCountryProjection),
    [onChanged],
  );

  return (
    <Dropdown
      isSearchable={false}
      label={personalShopperCountriesDropdownLabel}
      options={personalShopperCountriesOptions}
      value={personalShopperCountrySelected}
      onChange={handleOnPersonalShopperCountryChanged}
    />
  );
};

export { PersonalShopperCountriesDropdown };
