import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listShortlistFiltersByBoxNumber } from "../../../../projection/shortlistFilter/listShortlistFiltersByBoxNumber";
import { ShortlistFilterProjection } from "../../../../projection/shortlistFilter/shortlistFilter";

interface UseListShortlistFiltersByBoxNumberFunctionArgs {
  readonly boxNumber?: string;
}

interface UseListShortlistFiltersByBoxNumberFunction {
  (args: UseListShortlistFiltersByBoxNumberFunctionArgs): UseQueryFunctionResult<ShortlistFilterProjection[]>;
}

const useListShortlistFiltersByBoxNumber: UseListShortlistFiltersByBoxNumberFunction = ({ boxNumber }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listShortlistFiltersByBoxNumber({ boxNumber: boxNumber as string }),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(boxNumber),
    },
  });

export { useListShortlistFiltersByBoxNumber };
