import { ProcessManagerFunction } from "@lookiero/messaging";
import { BoxClosed } from "../../box/model/boxClosed";
import { removeSelectionOrder } from "../command/removeSelectionOrder";

interface RemoveSelectionOrderWhenBoxClosedFunction extends ProcessManagerFunction<BoxClosed> {}

const removeSelectionOrderWhenBoxClosed: RemoveSelectionOrderWhenBoxClosedFunction =
  ({ commandBus }) =>
  async ({ aggregateId, boxNumber }) => {
    await commandBus(removeSelectionOrder({ aggregateId, boxNumber }));
  };

export { removeSelectionOrderWhenBoxClosed };
