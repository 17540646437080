import { I18nMessage } from "@lookiero/i18n-react";
import { FC, Fragment } from "react";
import AlertIcon, { AlertIconVariant } from "../../atoms/alertIcon/AlertIcon";
import { SELECTION_I18N_PREFIX } from "../../_i18n/SelectionI18n";
import { SelectionAlertProjection } from "../../../../core/projection/alert/selectionAlert";
import "./selection-product-variant-alerts.css";
import { SelectionProductVariantProjection } from "../../../../core/projection/selection/selection";

type SelectionProductPreviewAlertsProps = {
  readonly productVariant: SelectionProductVariantProjection;
  readonly alerts?: SelectionAlertProjection[];
};
const SelectionProductPreviewAlerts: FC<SelectionProductPreviewAlertsProps> = ({
  productVariant,
  alerts,
}: SelectionProductPreviewAlertsProps) => {
  const productVariantAlerts = alerts?.filter((alert) => alert.productVariants?.includes(productVariant.id)) || [];
  const productVariantAlertsLabel = productVariantAlerts.map((alert) => (
    <Fragment key={alert.key}>
      <I18nMessage
        id={`alerts.${alert.message.placeholder}`}
        prefix={SELECTION_I18N_PREFIX}
        values={{ ...alert.message.params, strong: (chunks) => <strong>{chunks}</strong> }}
      />
      <br />
    </Fragment>
  ));
  const containsBlockingAlerts = productVariantAlerts?.some((alert) => alert.isBlocker);

  return (
    <ul className="selection-product-variant-alerts">
      {productVariantAlerts.length > 0 && (
        <li className="selection-product-variant-alerts__alert">
          <AlertIcon
            iconLabel="has-alert"
            title={productVariantAlertsLabel}
            variant={containsBlockingAlerts ? AlertIconVariant.ERROR : AlertIconVariant.WARNING}
          />
        </li>
      )}
    </ul>
  );
};

export default SelectionProductPreviewAlerts;
