import { useState, useCallback, Children, ReactNode, FC } from "react";
import classNames from "classnames";
import NavItem from "../../molecules/navItem/NavItem";
import "./tabs.css";

type TabItemProps = {
  readonly index: number;
  readonly active: boolean;
  readonly label: ReactNode;
  readonly onClick: (index: number) => void;
};
const TabItem: FC<TabItemProps> = ({ index, active, label, onClick }: TabItemProps) => {
  const handleOnSelected = useCallback(() => onClick(index), [index, onClick]);

  return (
    <li aria-selected={active} className="tabs__list-item" role="tab">
      <NavItem active={active} onSelected={handleOnSelected}>
        {label}
      </NavItem>
    </li>
  );
};

type TabsProps = {
  readonly className?: string;
  readonly labels: ReactNode[];
  readonly children: ReactNode;
};
const Tabs: FC<TabsProps> = ({ className, labels, children }: TabsProps) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className={classNames("tabs", className)}>
      <ul className="tabs__list" role="tablist">
        {labels.map((label, index) => (
          <TabItem
            key={`tab-label-${index}`}
            active={index === tabIndex}
            index={index}
            label={label}
            onClick={setTabIndex}
          />
        ))}
      </ul>
      <div className="tabs__panels">
        {Children.map(children, (child, index) => (
          <div className={classNames("tabs__panel", { "tabs__panel--active": tabIndex === index })} role="tabpanel">
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
