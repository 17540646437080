import { FC, useCallback, useMemo } from "react";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Picture from "../../../../shared/ui/uiKit/atoms/picture/Picture";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import Card from "../../../../shared/ui/uiKit/layouts/card/Card";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import AsyncActionState from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import cdnImageUrl from "../../../_util/cdnImageUrl";
import Price from "../../atoms/price/Price";
import Size from "../../atoms/size/Size";
import ProductPreviewActionButton from "../../molecules/productPreviewActionButton/ProductPreviewActionButton";
import SelectionProductPreviewAlerts from "../selectionProductPreviewAlerts/SelectionProductPreviewAlerts";
import { SelectionAlertProjection } from "../../../../core/projection/alert/selectionAlert";
import {
  SelectionProductProjection,
  SelectionProductVariantProjection,
} from "../../../../core/projection/selection/selection";
import "./selection-summary-product-preview.css";

const SELECTION_SUMMARY_PRODUCT_PREVIEW_IMAGE_WIDTH = 90;

type SelectionSummaryProductPreviewProps = {
  readonly alerts?: SelectionAlertProjection[];
  readonly product: SelectionProductProjection;
  readonly deselectProductVariantState?: AsyncActionState;
  readonly onDeselectProductVariant: (productVariant: SelectionProductVariantProjection) => void;
  readonly onClick: () => void;
};
const SelectionSummaryProductPreview: FC<SelectionSummaryProductPreviewProps> = ({
  alerts,
  product,
  deselectProductVariantState,
  onDeselectProductVariant,
  onClick,
}) => {
  const selectedProductVariant: SelectionProductVariantProjection = useMemo(
    () =>
      product.productVariants.find((productVariant) => productVariant.isSelected) as SelectionProductVariantProjection,
    [product.productVariants],
  );

  const media = useMemo(
    () => (selectedProductVariant.media.length > 0 ? selectedProductVariant.media[0] : undefined),
    [selectedProductVariant.media],
  );

  const src = cdnImageUrl({
    url: media?.url || "",
    width: SELECTION_SUMMARY_PRODUCT_PREVIEW_IMAGE_WIDTH,
  });

  const handleDeselectButtonClick = useCallback(
    () => onDeselectProductVariant(selectedProductVariant),
    [onDeselectProductVariant, selectedProductVariant],
  );

  return selectedProductVariant ? (
    <article
      aria-label="selection-summary-product-preview"
      className="selection-summary-product-preview"
      role="listitem"
      onClick={onClick}
    >
      <Card>
        <Picture aspectRatio={AspectRatio.R_4_5} label="selection-summary-product-preview-image" src={src} lazy />

        <div className="selection-summary-product-preview__data">
          <Price price={selectedProductVariant.price} />
          <Text variant={TextVariant.BODY_BOLD} ellipsis>
            {product.brand.name}
          </Text>
          <Text className="selection-summary-product-preview__title" variant={TextVariant.BODY_SMALL} ellipsis>
            {product.title}
          </Text>
          <Text className="selection-summary-product-preview__group" variant={TextVariant.BODY_SMALL}>
            {selectedProductVariant.season}
            {product.group}
            <Size size={selectedProductVariant.size} />
          </Text>
        </div>

        {alerts && <SelectionProductPreviewAlerts alerts={alerts} productVariant={selectedProductVariant} />}

        <ProductPreviewActionButton
          className="selection-summary-product-preview__deselect"
          icon={IconVariant.MINUS}
          label="selection-summary-product-preview-deselect"
          state={deselectProductVariantState}
          onClick={handleDeselectButtonClick}
        />
      </Card>
    </article>
  ) : null;
};

export { SelectionSummaryProductPreview };
