import { command, Command } from "@lookiero/messaging";

const CLOSE_BOX = "close_box";

interface CloseBoxPayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
}

interface CloseBox extends Command<typeof CLOSE_BOX>, CloseBoxPayload {}

interface CloseBoxFunction {
  (payload: CloseBoxPayload): CloseBox;
}

const closeBox: CloseBoxFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: CLOSE_BOX }),
  ...payload,
});

export type { CloseBox };
export { CLOSE_BOX, closeBox };
