const BOX_PREVIEW_I18N_PREFIX = "labs.box_preview.";

enum BoxPreviewI18n {
  SEND_PREVIEW = "send_preview",
  PREVIEW_IN_PROGRESS = "preview_in_progress",
  PREVIEW_EXPIRED = "preview_expired",
  PREVIEW_SURPRISE_CHOSEN = "preview_surprise_chosen",
  PREVIEW_SENT_NOTIFICATION = "notification.preview_sent",
  SUBMITTED = "preview_submitted",
  SELECTION_NOT_VALID = "selection_not_valid",
  SEND_PREVIEW_CONFIRMATION_TITLE = "send_preview_confirmation_title",
  SEND_PREVIEW_CONFIRMATION = "send_preview_confirmation",
  CANCEL = "cancel",
  SEND = "send",
  SEND_ANYWAY = "send_anyway",
  PREVIEW_DETAIL_TITLE = "preview_detail_title",
  PREVIEW_DETAIL_SENT_PRODUCTS_TITLE = "preview_detail_sent_products_title",
  PREVIEW_DETAIL_CHOSEN_PRODUCTS_TITLE = "preview_detail_chosen_products_title",
  PREVIEW_DETAIL_DISCARDED_PRODUCTS_TITLE = "preview_detail_discarded_products_title",
  PREVIEW_DETAIL_COMMENT_TITLE = "preview_detail_comment_title",
  PREVIEW_DETAIL_NO_COMMENT = "preview_detail_no_comment",
  PREVIEW_NOT_REQUIRED = "preview_not_required",
  OUT_OF_STOCK = "out_of_stock",
  PREVIEW_DETAIL_LOOKS = "looks",
}

export { BOX_PREVIEW_I18N_PREFIX };

export default BoxPreviewI18n;
