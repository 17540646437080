import { FC } from "react";
import HistoryNotesTemplate from "../../componentLibrary/templates/historyNotes/HistoryNotes";
import useBoxSharedContext from "../_behaviors/useBoxSharedContext";
import { useListHistoryNotesByCustomerId } from "../../../core/infrastructure/projection/historyNote/react/useListHistoryNotesByCustomerId";
import "./history-notes.css";

const HistoryNotes: FC = () => {
  const { box } = useBoxSharedContext();
  const [historyNotes] = useListHistoryNotesByCustomerId({ customerId: box.customerId });

  return <HistoryNotesTemplate historyNotes={historyNotes} />;
};

export default HistoryNotes;
