import { ListReturnQuestionsResult, ReturnQuestionsView } from "../../../projection/returnQuestion/listReturnQuestions";
import { ReturnQuestionProjection } from "../../../projection/returnQuestion/returnQuestion";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpReturnQuestionsView extends ReturnQuestionsView {}

interface HttpReturnQuestionsViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpReturnQuestionsViewFunction {
  (args: HttpReturnQuestionsViewFunctionArgs): HttpReturnQuestionsView;
}

interface ListBoxesByCustomerIdResponse {
  readonly result: ReturnQuestionProjection[];
}

const httpReturnQuestionsView: HttpReturnQuestionsViewFunction =
  ({ httpPost }) =>
  async ({ signal }) =>
    await httpPost<ListBoxesByCustomerIdResponse, ListReturnQuestionsResult>({
      endpoint: "/list-return-questions",
      body: {},
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpReturnQuestionsView };
