import { FC, useMemo } from "react";
import { FilteringProjection } from "../../../../core/projection/filtering/filtering";
import { ShortlistFiltersFiltering } from "../shortlistFiltersFiltering/ShortlistFiltersFiltering";
import Tabs from "../../../../shared/ui/uiKit/layouts/tabs/Tabs";
import { useI18nMessage } from "@lookiero/i18n-react";
import ShortlistFiltersI18n, {
  SHORTLIST_FILTERS_I18N_PREFIX,
} from "../../../componentLibrary/_i18n/ShortlistFiltersI18n";
import { ShortlistFiltersPositives } from "../../../componentLibrary/organisms/shortlistFilters/components/shortlistFiltersPositives/ShortlistFiltersPositives";
import ShortlistFilterSearch from "../shortlistFilterSearch/ShortlistFilterSearch";
import { ShortlistFiltersClearButton } from "../../../componentLibrary/organisms/shortlistFilters/components/shortlistFiltersClearButton/ShortlistFiltersClearButton";
import { ShortlistFiltersNegatives } from "../../../componentLibrary/organisms/shortlistFilters/components/shortlistFiltersNegatives/ShortlistFiltersNegatives";
import { useShortlistFilteringPositives } from "../../../componentLibrary/organisms/shortlistFilters/shortlistFilterItem/_behavior/useShortlistFilteringPositives";
import { useShortlistFilteringNegatives } from "../../../componentLibrary/organisms/shortlistFilters/shortlistFilterItem/_behavior/useShortlistFilteringNegatives";
import { countFilters } from "../../../componentLibrary/organisms/shortlistFilters/ShortlistFilterState";
import { ShortlistFilterProjection } from "../../../../core/projection/shortlistFilter/shortlistFilter";
import "./shortlist-filters-tabs.css";

interface ShortlistFiltersTabsProps {
  readonly filters: ShortlistFilterProjection[];
  readonly filtering?: FilteringProjection;
  readonly search: string;
  readonly onSearchChanged: (search: string) => void;
}

const ShortlistFiltersTabs: FC<ShortlistFiltersTabsProps> = ({ filters, filtering, search, onSearchChanged }) => {
  const { state: positiveState } = useShortlistFilteringPositives();
  const { state: negativeState } = useShortlistFilteringNegatives();
  const positivesTabLabel = useI18nMessage({
    id: ShortlistFiltersI18n.FILTERING_POSITIVES,
    prefix: SHORTLIST_FILTERS_I18N_PREFIX,
  });
  const negativesTabLabel = useI18nMessage({
    id: ShortlistFiltersI18n.FILTERING_NEGATIVES,
    prefix: SHORTLIST_FILTERS_I18N_PREFIX,
  });

  const positivesCount = useMemo(() => countFilters({ state: positiveState, filters }), [filters, positiveState]);
  const negativesCount = useMemo(() => countFilters({ state: negativeState, filters }), [filters, negativeState]);

  return (
    <div className="shortlist-filters-tabs" data-testid="shortlist-filters-tabs">
      {filtering && <ShortlistFiltersFiltering filtering={filtering} />}
      <Tabs
        className="shortlist-filters-tabs__tabs"
        labels={[
          <div data-testid="shortlist-filters-tabs-positives">
            {positivesTabLabel}
            {positivesCount !== 0 && <span className="shortlist-filters-tabs__tab-counter">{positivesCount}</span>}
          </div>,
          <div data-testid="shortlist-filters-tabs-negatives">
            {negativesTabLabel}
            {negativesCount !== 0 && <span className="shortlist-filters-tabs__tab-counter">{negativesCount}</span>}
          </div>,
        ]}
      >
        <>
          <ShortlistFiltersPositives filters={filters} />
          <ShortlistFilterSearch value={search} onChange={onSearchChanged} />
          <ShortlistFiltersClearButton />
        </>
        <>
          <ShortlistFiltersNegatives filters={filters} />
        </>
      </Tabs>
    </div>
  );
};

export { ShortlistFiltersTabs };
