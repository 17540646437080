import { isNoteTemplateRemoved } from "../../../../domain/noteTemplate/model/noteTemplateRemoved";
import { HttpNoteTemplatesSaveFunction } from "./httpNoteTemplates";

interface HttpNoteTemplateRemovedFunction extends HttpNoteTemplatesSaveFunction {}

const httpNoteTemplateRemoved: HttpNoteTemplateRemovedFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const noteTemplateRemoved = domainEvents.find(isNoteTemplateRemoved);

    if (!noteTemplateRemoved) {
      return;
    }

    await httpPost({
      endpoint: "/remove-note-template",
      body: { note_template_id: aggregateId },
    });
  };

export { httpNoteTemplateRemoved };
