import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ShortlistFilterProjection } from "./shortlistFilter";

const LIST_SHORTLIST_FILTERS_BY_BOX_NUMBER = "list_shortlist_filters_by_box_number";

interface ListShortlistFiltersByBoxNumberPayload {
  readonly boxNumber: string;
}

interface ListShortlistFiltersByBoxNumber
  extends Query<typeof LIST_SHORTLIST_FILTERS_BY_BOX_NUMBER>,
    ListShortlistFiltersByBoxNumberPayload {}

interface ListShortlistFiltersByBoxNumberFunction {
  (payload: ListShortlistFiltersByBoxNumberPayload): ListShortlistFiltersByBoxNumber;
}

const listShortlistFiltersByBoxNumber: ListShortlistFiltersByBoxNumberFunction = (payload) => ({
  ...query({ name: LIST_SHORTLIST_FILTERS_BY_BOX_NUMBER }),
  ...payload,
});

type ListShortlistFiltersByBoxNumberResult = ShortlistFilterProjection[] | null;

interface ShortlistFiltersByBoxNumberViewArgs extends CancelableQueryViewArgs {
  readonly boxNumber: string;
}

interface ShortlistFiltersByBoxNumberView {
  (args: ShortlistFiltersByBoxNumberViewArgs): Promise<ListShortlistFiltersByBoxNumberResult>;
}

interface ListShortlistFiltersByBoxNumberHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ShortlistFiltersByBoxNumberView;
}

const listShortlistFiltersByBoxNumberHandler: QueryHandlerFunction<
  ListShortlistFiltersByBoxNumber,
  ListShortlistFiltersByBoxNumberResult,
  ListShortlistFiltersByBoxNumberHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxNumber }) =>
    view({ boxNumber, signal });

export type { ShortlistFiltersByBoxNumberView, ListShortlistFiltersByBoxNumber, ListShortlistFiltersByBoxNumberResult };
export {
  LIST_SHORTLIST_FILTERS_BY_BOX_NUMBER,
  listShortlistFiltersByBoxNumber,
  listShortlistFiltersByBoxNumberHandler,
};
