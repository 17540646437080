import { isProductVariantDeselected } from "../../../../domain/selection/model/ProductVariantDeselected";
import { HttpSelectionsSaveFunction } from "./httpSelections";

interface HttpSelectionsDeselectFunction extends HttpSelectionsSaveFunction {}

const httpSelectionsDeselect: HttpSelectionsDeselectFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const productVariantDeselected = domainEvents.find(isProductVariantDeselected);

    if (!productVariantDeselected) {
      return;
    }

    await httpPost({
      endpoint: "/deselect-product-variant",
      body: {
        selection_id: aggregateId,
        product_variant_id: productVariantDeselected.productVariantId,
      },
    });
  };

export { httpSelectionsDeselect };
