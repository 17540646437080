import { FC, ReactNode } from "react";
import classNames from "classnames";
import Text, { TextVariant } from "../text/Text";
import "./badge.css";

enum BadgeVariant {
  BASIC = "basic",
  BASIC_DARK = "basic-dark",
  YELLOW = "yellow",
  YELLOW_DARK = "yellow-dark",
  ORANGE = "orange",
  ORANGE_DARK = "orange-dark",
  RED = "red",
  RED_DARK = "red-dark",
  PURPLE = "purple",
  PURPLE_DARK = "purple-dark",
  BLUE = "blue",
  BLUE_DARK = "blue-dark",
  GREEN = "green",
  GREEN_DARK = "green-dark",
}

interface BadgeProps {
  readonly children?: ReactNode;
  readonly variant?: BadgeVariant;
}

const Badge: FC<BadgeProps> = ({ children, variant = BadgeVariant.BASIC }: BadgeProps) => (
  <Text className={classNames("badge", `badge--${variant}`)} variant={TextVariant.BODY_SMALL}>
    <span className="badge__indicator" />
    {children && <span className="badge__content">{children}</span>}
  </Text>
);

export { BadgeVariant };

export default Badge;
