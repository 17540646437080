import { isNoteTemplateWritten } from "../../../../domain/noteTemplate/model/noteTemplateWritten";
import { HttpNoteTemplatesSaveFunction } from "./httpNoteTemplates";

interface HttpNoteTemplateWrittenFunction extends HttpNoteTemplatesSaveFunction {}

const httpNoteTemplateWritten: HttpNoteTemplateWrittenFunction =
  ({ httpPost }) =>
  async ({ aggregateId, psId, title, content, category, domainEvents }) => {
    const noteTemplateWritten = domainEvents.find(isNoteTemplateWritten);

    if (!noteTemplateWritten) {
      return;
    }

    await httpPost({
      endpoint: "/write-note-template",
      body: {
        id: aggregateId,
        ps_id: psId,
        title,
        content,
        category,
      },
    });
  };

export { httpNoteTemplateWritten };
