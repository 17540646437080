import {
  HistoryNotesByCustomerIdView,
  ListHistoryNotesByCustomerIdResult,
} from "../../../projection/historyNote/listHistoryNotesByCustomerId";

import { HttpPostFunction } from "../../delivery/http/httpClient";
import { HistoryNoteDto, toHistoryNoteProjection } from "./historyNote";

interface HttpHistoryNotesByCustomerIdView extends HistoryNotesByCustomerIdView {}

interface HttpHistoryNotesByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpHistoryNotesByCustomerIdViewFunction {
  (args: HttpHistoryNotesByCustomerIdViewFunctionArgs): HttpHistoryNotesByCustomerIdView;
}

interface ListHistoryNotesByCustomerIdResponse {
  readonly result: HistoryNoteDto[];
}

const httpHistoryNotesByCustomerIdView: HttpHistoryNotesByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<ListHistoryNotesByCustomerIdResponse, ListHistoryNotesByCustomerIdResult>({
      endpoint: "/view-note-history-by-customer-id",
      body: { customer_id: customerId },
      signal,
      result: {
        error: null,
        success: (response) => response.result.map(toHistoryNoteProjection),
      },
    });

export { httpHistoryNotesByCustomerIdView };
