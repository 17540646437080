import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { PersonalShopperProjection } from "./personalShopper";
import { viewPersonalShopper } from "./viewPersonalShopper";
import PersonalShopperRole from "../../domain/personalShopper/model/PersonalShopperRole";

const CAN_PERSONAL_SHOPPER_SEND_BOXES_TO_SUPERVISE = "can_personal_shopper_send_boxes_to_supervise";

interface CanPersonalShopperSendBoxesToSupervise extends Query<typeof CAN_PERSONAL_SHOPPER_SEND_BOXES_TO_SUPERVISE> {}

interface CanPersonalShopperSendBoxesToSuperviseFunction {
  (): CanPersonalShopperSendBoxesToSupervise;
}

const canPersonalShopperSendBoxesToSupervise: CanPersonalShopperSendBoxesToSuperviseFunction = () =>
  query({ name: CAN_PERSONAL_SHOPPER_SEND_BOXES_TO_SUPERVISE });

type CanPersonalShopperSendBoxesToSuperviseResult = boolean;

interface CanPersonalShopperSendBoxesToSuperviseHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const canPersonalShopperSendBoxesToSuperviseHandler: QueryHandlerFunction<
  CanPersonalShopperSendBoxesToSupervise,
  CanPersonalShopperSendBoxesToSuperviseResult,
  CanPersonalShopperSendBoxesToSuperviseHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async () => {
    const personalShopper: PersonalShopperProjection | null = await queryBus(viewPersonalShopper());

    if (!personalShopper) {
      return false;
    }

    return personalShopper.roles.includes(PersonalShopperRole.PS_SUPERVISED);
  };

export type { CanPersonalShopperSendBoxesToSupervise, CanPersonalShopperSendBoxesToSuperviseResult };
export {
  CAN_PERSONAL_SHOPPER_SEND_BOXES_TO_SUPERVISE,
  canPersonalShopperSendBoxesToSupervise,
  canPersonalShopperSendBoxesToSuperviseHandler,
};
