import { FC, memo } from "react";
import { useShortlistFilterItem } from "../../shortlistFilterItem/_behavior/useShortlistFilterItem";
import {
  ShortlistFilterProjection,
  ShortlistFilterType,
} from "../../../../../../core/projection/shortlistFilter/shortlistFilter";

type ShortlistFilterProps = {
  readonly filter: ShortlistFilterProjection;
  readonly level?: number;
};
const ShortlistFilter: FC<ShortlistFilterProps> = ({ filter, level = 0 }: ShortlistFilterProps) => {
  const Item = useShortlistFilterItem({ type: filter.type });

  return filter ? (
    <Item filter={filter} level={level}>
      {filter.children && (
        <>
          {filter.children.map((childFilter: ShortlistFilterProjection) => (
            <ShortlistFilter
              key={childFilter.id}
              filter={childFilter}
              level={filter.type === ShortlistFilterType.HOST_NULL ? level : level + 1}
            />
          ))}
        </>
      )}
    </Item>
  ) : null;
};

export default memo(ShortlistFilter);
