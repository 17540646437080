import {
  StyleProfileByBoxIdView,
  ViewStyleProfileByBoxIdResult,
} from "../../../projection/styleProfile/viewStyleProfileByBoxId";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { StyleProfileDto, toStyleProfileProjection } from "./styleProfile";

interface HttpStyleProfileByBoxIdView extends StyleProfileByBoxIdView {}

interface HttpStyleProfileByBoxIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpStyleProfileByBoxIdViewFunction {
  (args: HttpStyleProfileByBoxIdViewFunctionArgs): HttpStyleProfileByBoxIdView;
}

const httpStyleProfileByBoxIdView: HttpStyleProfileByBoxIdViewFunction =
  ({ httpPost }) =>
  async ({ boxId, signal }) =>
    await httpPost<StyleProfileDto, ViewStyleProfileByBoxIdResult>({
      endpoint: "/view-style-profile-by-box-id",
      body: { boxId },
      signal,
      result: {
        error: null,
        success: (response) => toStyleProfileProjection(response),
      },
    });

export { httpStyleProfileByBoxIdView };
