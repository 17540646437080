import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

const IS_THE_BOX_OPEN = "is_the_box_open";

interface IsTheBoxOpenPayload {
  readonly boxId: string;
}

interface IsTheBoxOpen extends Query<typeof IS_THE_BOX_OPEN>, IsTheBoxOpenPayload {}

interface IsTheBoxOpenFunction {
  (payload: IsTheBoxOpenPayload): IsTheBoxOpen;
}

const isTheBoxOpen: IsTheBoxOpenFunction = (payload) => ({
  ...query({ name: IS_THE_BOX_OPEN }),
  ...payload,
});

type IsTheBoxOpenResult = boolean;

interface IsTheBoxOpenViewArgs extends CancelableQueryViewArgs {
  readonly boxId: string;
}

interface IsTheBoxOpenView {
  (args: IsTheBoxOpenViewArgs): Promise<IsTheBoxOpenResult>;
}

interface IsTheBoxOpenHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: IsTheBoxOpenView;
}

const isTheBoxOpenHandler: QueryHandlerFunction<IsTheBoxOpen, IsTheBoxOpenResult, IsTheBoxOpenHandlerFunctionArgs> =
  ({ view, signal }) =>
  async ({ boxId }) =>
    view({ boxId, signal });

export type { IsTheBoxOpenView, IsTheBoxOpen, IsTheBoxOpenResult };
export { IS_THE_BOX_OPEN, isTheBoxOpen, isTheBoxOpenHandler };
