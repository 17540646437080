import { FC, ReactNode } from "react";
import Locale from "../../../../../core/domain/country/model/Locale";
import Feature from "../../../../../core/projection/feature/model/Feature";
import ProductFeature from "../productFeature/ProductFeature";
import "./product-features-list.css";

type ProductFeaturesListProps = {
  readonly children?: ReactNode;
  readonly features: Feature[];
  readonly locale: Locale;
};
const ProductFeaturesList: FC<ProductFeaturesListProps> = ({
  children,
  features,
  locale,
}: ProductFeaturesListProps) => (
  <section className="product-features-list">
    {children && <header className="product-features-list__header">{children}</header>}
    <ul className="product-features-list__list">
      {features.map((feature) => (
        <li key={feature.id} className="product-features-list__list-item">
          <ProductFeature feature={feature} locale={locale} />
        </li>
      ))}
    </ul>
  </section>
);

export default ProductFeaturesList;
