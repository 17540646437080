import Look from "./Look";

export const MAX_LOOKS = 4;
const VALID_LOOK_LENGTHS = [0, 2, 3, 4];

class LookList {
  public readonly looks: Look[] | undefined;

  public constructor(looks: Look[] | undefined) {
    this.maxLooksExceeded(looks);
    this.areLooksValid(looks);
    this.areDuplicatedLooks(looks);
    this.areProductVariantsDuplicatedWithinALook(looks);

    this.looks = looks;
  }

  private maxLooksExceeded(looks: Look[] | undefined): void | never {
    const exceeded = looks && looks.length > MAX_LOOKS;

    if (exceeded) {
      throw new Error(`Max looks exceeded (${MAX_LOOKS})`);
    }
  }

  private areLooksValid(looks: Look[] | undefined): void | never {
    const invalidLooksLength = looks && looks.some((look) => !VALID_LOOK_LENGTHS.includes(look.length));

    if (invalidLooksLength) {
      throw new Error("Invalid looks length");
    }
  }

  private areDuplicatedLooks(looks: Look[] | undefined): void | never {
    const duplicatedLooks = looks && looks.length !== new Set(looks).size;

    if (duplicatedLooks) {
      throw new Error("Duplicated looks");
    }
  }

  private areProductVariantsDuplicatedWithinALook(looks: Look[] | undefined): void | never {
    const duplicatedProductVariants = looks && looks.some((look) => look.length !== new Set(look).size);

    if (duplicatedProductVariants) {
      throw new Error("Duplicated product variants");
    }
  }
}

export default LookList;
