import { ProcessManagerFunction } from "@lookiero/messaging";
import { ListLooksBySelectionIdResult, listLooksBySelectionId } from "../../../projection/looks/listLooksBySelectionId";
import { ProductVariantReplaced } from "../../selection/model/ProductVariantReplaced";
import { setLooks } from "../command/setLooks";

interface SetLooksWhenProductVariantReplacedFunction extends ProcessManagerFunction<ProductVariantReplaced> {}

const setLooksWhenProductVariantReplaced: SetLooksWhenProductVariantReplacedFunction =
  ({ commandBus, queryBus }) =>
  async ({ aggregateId, deselectedProductVariantId, selectedProductVariantId }) => {
    const looks: ListLooksBySelectionIdResult = await queryBus(listLooksBySelectionId({ selectionId: aggregateId }));

    const looksWithoutProductVariant =
      looks?.map((look) =>
        look.map((productVariantId) =>
          productVariantId === deselectedProductVariantId ? selectedProductVariantId : productVariantId,
        ),
      ) || [];

    await commandBus(setLooks({ aggregateId, looks: looksWithoutProductVariant }));
  };

export { setLooksWhenProductVariantReplaced };
