import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import BoxStatus from "../../../../domain/box/model/BoxStatus";
import { useListBoxesByCustomerId } from "../../box/react/useListBoxesByCustomerId";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { useViewStyleProfileByBoxId } from "./useViewStyleProfileByBoxId";
import { StyleProfileProjection } from "../../../../projection/styleProfile/styleProfile";

interface UseViewPreviousStyleProfileByBoxIdFunctionArgs {
  readonly customerId: string;
  readonly boxId: string;
}

interface UseViewPreviousStyleProfileByBoxIdFunction {
  (args: UseViewPreviousStyleProfileByBoxIdFunctionArgs): UseQueryFunctionResult<StyleProfileProjection>;
}

const useViewPreviousStyleProfileByBoxId: UseViewPreviousStyleProfileByBoxIdFunction = ({ customerId, boxId }) => {
  const [previousBoxId, setPreviousBoxId] = useState<string>();
  const [boxes, boxesStatus] = useListBoxesByCustomerId({ customerId });
  const [styleProfile, styleProfileStatus] = useViewStyleProfileByBoxId({ boxId: previousBoxId });

  useEffect(() => {
    if (!boxes || boxes.length === 1) {
      return;
    }

    // Just take into account FINISHED boxes (and current one)
    const filteredBoxes = boxes.filter((box) => box.id === boxId || box.status === BoxStatus.FINISHED);
    const sortedBoxes = sortBy(filteredBoxes, (box) => box.plannedFor);
    const boxIndex = sortedBoxes.findIndex((box) => box.id === boxId);

    if (boxIndex === -1 || boxIndex === 0) {
      return;
    }

    setPreviousBoxId(sortedBoxes[boxIndex - 1].id);
  }, [boxId, boxes]);

  if (!previousBoxId) {
    return [undefined, boxesStatus];
  }

  return [styleProfile, styleProfileStatus];
};

export { useViewPreviousStyleProfileByBoxId };
