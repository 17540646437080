import { command, Command } from "@lookiero/messaging";

const REMOVE_SELECTION_ORDER = "remove_selection_order";

interface RemoveSelectionOrderPayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
}

interface RemoveSelectionOrder extends Command<typeof REMOVE_SELECTION_ORDER>, RemoveSelectionOrderPayload {}

interface RemoveSelectionOrderFunction {
  (payload: RemoveSelectionOrderPayload): RemoveSelectionOrder;
}

const removeSelectionOrder: RemoveSelectionOrderFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: REMOVE_SELECTION_ORDER }),
  ...payload,
});

export type { RemoveSelectionOrder };
export { REMOVE_SELECTION_ORDER, removeSelectionOrder };
