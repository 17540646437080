import {
  LookAndLikeFeedbacksSearchByCriteriaView,
  SearchLookAndLikeFeedbacksByCriteriaResult,
} from "../../../projection/lookAndLikeFeedback/searchLookAndLikeFeedbacksByCriteria";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { LookAndLikeFeedbackDto, toLookAndLikeFeedbackProjection } from "./lookAndLikeFeedback";

interface HttpLookAndLikeFeedbacksSearchByCriteriaView extends LookAndLikeFeedbacksSearchByCriteriaView {}

interface HttpLookAndLikeFeedbacksSearchByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpLookAndLikeFeedbacksSearchByCriteriaViewFunction {
  (args: HttpLookAndLikeFeedbacksSearchByCriteriaViewFunctionArgs): HttpLookAndLikeFeedbacksSearchByCriteriaView;
}

interface SearchLookAndLikeFeedbacksByCriteriaResponse {
  readonly result: LookAndLikeFeedbackDto[];
}

const httpLookAndLikeFeedbacksSearchByCriteriaView: HttpLookAndLikeFeedbacksSearchByCriteriaViewFunction =
  ({ httpPost }) =>
  async ({ criteria: { customerId, familyIds, response, page, perPage }, signal }) =>
    await httpPost<SearchLookAndLikeFeedbacksByCriteriaResponse, SearchLookAndLikeFeedbacksByCriteriaResult>({
      endpoint: "/search-look-and-like-feedbacks-by-criteria",
      body: {
        customer_id: customerId,
        family_ids: familyIds,
        response,
        page,
        per_page: perPage,
      },
      signal,
      result: {
        error: [],
        success: ({ result }) => result.map(toLookAndLikeFeedbackProjection),
      },
    });

export { httpLookAndLikeFeedbacksSearchByCriteriaView };
