import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { PersonalShopperTeamProjection } from "./personalShopperTeam";

const LIST_PERSONAL_SHOPPER_TEAMS = "list_personal_shopper_teams";

interface ListPersonalShopperTeams extends Query<typeof LIST_PERSONAL_SHOPPER_TEAMS> {}

interface ListPersonalShopperTeamsFunction {
  (): ListPersonalShopperTeams;
}

const listPersonalShopperTeams: ListPersonalShopperTeamsFunction = () => query({ name: LIST_PERSONAL_SHOPPER_TEAMS });

type ListPersonalShopperTeamsResult = PersonalShopperTeamProjection[];

interface PersonalShopperTeamsViewArgs extends CancelableQueryViewArgs {}

interface PersonalShopperTeamsView {
  (args: PersonalShopperTeamsViewArgs): Promise<ListPersonalShopperTeamsResult>;
}

interface ListPersonalShopperTeamsHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PersonalShopperTeamsView;
}

const listPersonalShopperTeamsHandler: QueryHandlerFunction<
  ListPersonalShopperTeams,
  ListPersonalShopperTeamsResult,
  ListPersonalShopperTeamsHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async () =>
    view({ signal });

export type { PersonalShopperTeamsView, ListPersonalShopperTeams, ListPersonalShopperTeamsResult };
export { LIST_PERSONAL_SHOPPER_TEAMS, listPersonalShopperTeams, listPersonalShopperTeamsHandler };
