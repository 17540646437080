import { FC } from "react";
import { Locale } from "@lookiero/i18n";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import { CapacityCalendarProjection } from "../../../../core/projection/capacityCalendar/capacityCalendar";
import "./capacity-list-item.css";

interface CapacityListItemProps {
  readonly capacityCalendar: CapacityCalendarProjection;
  readonly locale: Locale;
}

const CapacityListItem: FC<CapacityListItemProps> = ({ capacityCalendar, locale }) => (
  <>
    <td className="capacity-list-item__col">
      <Text tag="div">{capacityCalendar.date.toLocaleDateString(locale)}</Text>
    </td>
    <td className="capacity-list-item__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        {capacityCalendar.capacity}
      </Text>
    </td>
    <td className="capacity-list-item__col">
      <Text tag="div">{capacityCalendar.boxes.recurrent}</Text>
    </td>
    <td className="capacity-list-item__col">
      <Text tag="div">{capacityCalendar.boxes.new}</Text>
    </td>
    <td className="capacity-list-item__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        {capacityCalendar.boxes.total}
      </Text>
    </td>
    <td className="capacity-list-item__col">
      <Text tag="div">{parseFloat(capacityCalendar.boxes.percentage.toFixed(1))}</Text>
    </td>
    <td className="capacity-list-item__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        {capacityCalendar.boxes.difference}
      </Text>
    </td>
  </>
);

export { CapacityListItem };
