import { FC, useCallback, useMemo } from "react";
import Color from "../../../../core/projection/color/model/Color";
import ColorOption, { ColorOptionState } from "../../../../shared/ui/uiKit/atoms/colorOption/ColorOption";
import "./color-selector.css";

type ColorSelectorOptionProps = {
  readonly color: Color;
  readonly selectedColor: Color;
  readonly compatibleColors: Color[];
  readonly onChange: (color: Color) => void;
};
const ColorSelectorOption: FC<ColorSelectorOptionProps> = ({
  color,
  selectedColor,
  compatibleColors,
  onChange,
}: ColorSelectorOptionProps) => {
  const state = useMemo(
    () =>
      color.code === selectedColor.code
        ? ColorOptionState.SELECTED
        : compatibleColors.some((compatibleColor) => compatibleColor.code === color.code)
        ? ColorOptionState.DEFAULT
        : ColorOptionState.VISUALLY_DISABLED,
    [color.code, compatibleColors, selectedColor.code],
  );
  const handleOnChange = useCallback(() => {
    if (color.code === selectedColor.code) {
      return;
    }

    onChange(color);
  }, [color, onChange, selectedColor.code]);

  return <ColorOption color={color} showTooltipOnFirstMount={false} state={state} onChange={handleOnChange} />;
};

type ColorSelectorProps = {
  readonly colors: Color[];
  readonly compatibleColors: Color[];
  readonly selectedColor: Color;
  readonly onChange: (color: Color) => void;
};
const ColorSelector: FC<ColorSelectorProps> = ({
  colors,
  compatibleColors,
  selectedColor,
  onChange,
}: ColorSelectorProps) => (
  <div className="color-selector">
    {colors.map((color) => (
      <ColorSelectorOption
        key={color.code}
        color={color}
        compatibleColors={compatibleColors}
        selectedColor={selectedColor}
        onChange={onChange}
      />
    ))}
  </div>
);

export default ColorSelector;
