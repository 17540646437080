import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const BOX_SENT_TO_SUPERVISE = "box_sent_to_supervise";

interface BoxSentToSupervisePayload {
  readonly aggregateId: string;
}

interface BoxSentToSupervise extends DomainEvent<typeof BOX_SENT_TO_SUPERVISE>, BoxSentToSupervisePayload {}

interface BoxSentToSuperviseFunction {
  (payload: BoxSentToSupervisePayload): BoxSentToSupervise;
}

const boxSentToSupervise: BoxSentToSuperviseFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: BOX_SENT_TO_SUPERVISE }),
  ...payload,
});

const isBoxSentToSupervise = (event: DomainEvent<MessageName>): event is BoxSentToSupervise =>
  event.name === BOX_SENT_TO_SUPERVISE;

export type { BoxSentToSupervise };
export { BOX_SENT_TO_SUPERVISE, boxSentToSupervise, isBoxSentToSupervise };
