import { FC, useCallback, useMemo } from "react";
import Text, { TextVariant } from "../../../shared/ui/uiKit/atoms/text/Text";
import useBoxSharedContext from "../_behaviors/useBoxSharedContext";
import { useSetLooks } from "../../../core/infrastructure/domain/look/react/useSetLooks";
import LookCreator from "./lookCreator/LookCreator";
import Look from "./look/Look";
import { LookTemplate } from "../../../core/projection/looks/lookTemplate";
import { useListLooksForSelection } from "../../../core/infrastructure/projection/looks/react/useListLooksForSelection";
import { LookProjection } from "../../../core/projection/looks/look";
import { ProductVariantProjection } from "../../../core/projection/product/productVariant";
import { useLogger } from "../../../shared/logging/useLogger";
import "./looks.css";

interface OnSaveFunctionArgs {
  readonly look: LookProjection;
  readonly lookTemplate: LookTemplate;
}
interface OnSaveFunction {
  (args: OnSaveFunctionArgs): void;
}

type LooksProps = {
  readonly creatorVisible: boolean;
  readonly onHideCreator: () => void;
  readonly onSave: OnSaveFunction;
  readonly onChangedTemplate: (lookTemplate: LookTemplate) => void;
};
const Looks: FC<LooksProps> = ({ creatorVisible, onHideCreator, onSave, onChangedTemplate }) => {
  const { selection } = useBoxSharedContext();
  const logger = useLogger();

  const [looks] = useListLooksForSelection({ selection });
  const [setLooks] = useSetLooks({ selectionId: selection?.selectionId, logger });

  const handleOnSaveLook: OnSaveFunction = useCallback(
    ({ look, lookTemplate }) => {
      setLooks({ looks: [...looks, look] });
      onSave({ look, lookTemplate });
    },
    [looks, onSave, setLooks],
  );

  const handleOnRemoveLook = useCallback(
    (look: LookProjection) => () => {
      const updatedLooks = looks.filter((aLook) => aLook !== look);
      setLooks({ looks: updatedLooks });
    },
    [looks, setLooks],
  );

  const selectionProductVariants = useMemo(
    () =>
      selection?.products.map(
        (product) =>
          product.productVariants.find((productVariant) => productVariant.isSelected) as ProductVariantProjection,
      ),
    [selection?.products],
  );

  return (
    <>
      {looks?.length > 0 && (
        <section className="looks">
          <Text variant={TextVariant.SMALL_CAPS}>LOOKS</Text>
          {looks.map((look) => {
            const lookProductVariants = look.map(
              (productVariantId) =>
                selectionProductVariants?.find(
                  (selectionProductVariant) => selectionProductVariant.id === productVariantId,
                ) as ProductVariantProjection,
            );

            return (
              <Look
                key={look.join("-")}
                lookProductVariants={lookProductVariants}
                onRemove={handleOnRemoveLook(look)}
              />
            );
          })}
        </section>
      )}

      {creatorVisible && (
        <LookCreator
          productVariants={selectionProductVariants}
          onCancel={onHideCreator}
          onChangedTemplate={onChangedTemplate}
          onSave={handleOnSaveLook}
        />
      )}
    </>
  );
};

export default Looks;
