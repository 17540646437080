import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BoxTabSection } from "./components/boxTabSection/BoxTabSection";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import { generatePath, useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../../_routing/Routing";
import useBoxSharedContext from "../../_behaviors/useBoxSharedContext";
import { useKey } from "react-use";
import { CustomerPinned } from "../../customer/customerPinned/CustomerPinned";
import { useBoundingClientRect } from "../../../../shared/ui/uiKit/_behaviors/useBoundingClientRect/useBoundingClientRect";
import { BoxTabPinneable } from "./components/boxTabPinneable/BoxTabPinneable";
import { SelectionSummaryProducts } from "../../selectionSummary/selectionSummaryProducts/SelectionSummaryProducts";
import { SelectionSummaryHeader } from "../../selectionSummary/selectionSummaryHeader/SelectionSummaryHeader";
import { CustomerPinnedHeader } from "../../customer/customerPinnedHeader/CustomerPinnedHeader";
import { useIsTheBoxOpen } from "../../../../core/infrastructure/projection/box/react/useIsTheBoxOpen";
import UISettings from "../../../_settings/UISettings";
import { useViewUiSettingByKey } from "../../../../core/infrastructure/projection/uiSetting/react/useViewUiSettingByKey";
import { useAutomaticSelectionTooltipVisible } from "../../_behaviors/useAutomaticSelectionTooltipVisible";
import { AutomaticSelectionStatus, useAutomaticSelection } from "../../_behaviors/useAutomaticSelection";
import "./box-tabs.css";

type BoxRoutes = Routes.BOX_CUSTOMER | Routes.BOX_SHORTLIST | Routes.BOX_SELECTION;

interface BoxTabsProps {
  readonly children: ReactNode;
}

const BoxTabs: FC<BoxTabsProps> = ({ children }) => {
  const { selection, box } = useBoxSharedContext();
  const [isTheBoxOpen = false] = useIsTheBoxOpen({ boxId: box.id });
  const isTheBoxOpenRef = useRef(isTheBoxOpen);
  isTheBoxOpenRef.current = isTheBoxOpen;
  const navigate = useNavigate();
  const location = useLocation();
  const { locale, box: legacyBoxId } = useParams();
  const routeParams = useMemo(() => ({ locale: locale as string, box: legacyBoxId as string }), [legacyBoxId, locale]);
  const customerMatch = useMatch(`${Routes.BOX_CUSTOMER}/*`);
  const shortlistMatch = useMatch(`${Routes.BOX_SHORTLIST}/*`);
  const selectionMatch = useMatch(`${Routes.BOX_SELECTION}/*`);

  const [customerPinned, setCustomerPinned] = useState(false);
  const [selectionPinned, setSelectionPinned] = useState(false);
  const toggleCustomerPinned = useCallback(() => setCustomerPinned((visible) => !visible), []);
  const toggleSelectionPinned = useCallback(() => setSelectionPinned((visible) => !visible), []);

  const customerPinnedVisible = customerPinned && !Boolean(customerMatch);
  const selectionPinnedVisible = selectionPinned && !Boolean(selectionMatch);

  /* Automatic Selection */
  const [automaticSelectionShownSetting] = useViewUiSettingByKey({
    key: `${UISettings.AUTOMATIC_SELECTION_FINISHED_SHOWN}_${box.id}`,
  });

  const automaticSelectionTooltipVisible = useAutomaticSelectionTooltipVisible();
  const showAutomaticSelectionTooltip = Boolean(!selectionMatch && automaticSelectionTooltipVisible);

  const automaticSelectionPinnedTriggered = useRef(false);

  const { automaticSelectionStatus } = useAutomaticSelection();
  const isAutomaticSelectionStarted = useRef(automaticSelectionStatus === AutomaticSelectionStatus.STARTED);
  isAutomaticSelectionStarted.current = automaticSelectionStatus === AutomaticSelectionStatus.STARTED;

  useEffect(() => {
    if (
      automaticSelectionPinnedTriggered.current ||
      automaticSelectionShownSetting?.value ||
      ![AutomaticSelectionStatus.STARTED, AutomaticSelectionStatus.FINISHED].includes(automaticSelectionStatus)
    ) {
      return;
    }

    automaticSelectionPinnedTriggered.current = true;

    if (selection?.products.length === 0) {
      return;
    }

    setSelectionPinned(true);
  }, [automaticSelectionShownSetting?.value, automaticSelectionStatus, selection?.products.length]);

  /* Navigation */
  const prevShortlistRouteRef = useRef<string>();
  prevShortlistRouteRef.current = shortlistMatch
    ? `${generatePath(Routes.BOX_SHORTLIST, routeParams)}${location.search}`
    : prevShortlistRouteRef.current;
  const navigateToRoute = useCallback(
    (route: BoxRoutes) => () => {
      const path =
        route === Routes.BOX_SHORTLIST && prevShortlistRouteRef.current
          ? prevShortlistRouteRef.current
          : generatePath(route, routeParams);

      navigate(path);
    },
    [navigate, routeParams],
  );
  useKey("1", navigateToRoute(Routes.BOX_CUSTOMER));
  useKey("2", () => {
    if (!isTheBoxOpenRef.current || isAutomaticSelectionStarted.current) {
      return;
    }

    navigateToRoute(Routes.BOX_SHORTLIST)();
  });
  useKey("3", navigateToRoute(Routes.BOX_SELECTION));

  /* Hover & Pin */
  const [customerHovered, setCustomerHovered] = useState(false);
  const [selectionHovered, setSelectionHovered] = useState(false);
  const handleOnCustomerHover = useCallback(
    (hovered: boolean) => {
      if (hovered && (customerPinned || Boolean(customerMatch))) {
        return;
      }

      if (hovered) {
        setSelectionHovered(false);
      }

      setCustomerHovered(hovered);
    },
    [customerMatch, customerPinned],
  );
  useEffect(() => {
    (customerPinnedVisible || Boolean(customerMatch)) && setCustomerHovered(false);
  }, [customerMatch, customerPinnedVisible]);
  const handleOnSelectionHover = useCallback(
    (hovered: boolean) => {
      if (hovered && (selectionPinnedVisible || Boolean(selectionMatch))) {
        return;
      }

      if (hovered) {
        setCustomerHovered(false);
      }

      setSelectionHovered(hovered);
    },
    [selectionMatch, selectionPinnedVisible],
  );
  useEffect(() => {
    (selectionPinnedVisible || Boolean(selectionMatch)) && setSelectionHovered(false);
  }, [selectionMatch, selectionPinnedVisible]);

  const handleOnClickLookAndLike = useCallback(() => handleOnCustomerHover(false), [handleOnCustomerHover]);

  const customerComponent = (
    <BoxTabPinneable
      className="box-tabs__customer"
      header={<CustomerPinnedHeader />}
      label="box-tabs-pinneable-customer"
      pinned={customerPinned}
      onPinnedChanged={toggleCustomerPinned}
    >
      <CustomerPinned onClickLookAndLike={handleOnClickLookAndLike} />
    </BoxTabPinneable>
  );
  const selectionComponent = (
    <BoxTabPinneable
      className="box-tabs__selection"
      disableButton={automaticSelectionTooltipVisible}
      label="box-tabs-pinneable-selection"
      pinned={selectionPinned}
      header={
        automaticSelectionStatus === AutomaticSelectionStatus.STARTED ? null : (
          <SelectionSummaryHeader showAutomaticSelectionTooltip={showAutomaticSelectionTooltip} />
        )
      }
      onPinnedChanged={toggleSelectionPinned}
    >
      <SelectionSummaryProducts />
    </BoxTabPinneable>
  );

  const tabsRef = useRef<HTMLDivElement>(null);
  const tabsRect = useBoundingClientRect({ ref: tabsRef });

  return (
    <section aria-label="box-tabs" className="box-tabs">
      <div ref={tabsRef} aria-label="box-tabs-tabs" className="box-tabs__tabs">
        <section className="box-tabs__menu">
          <BoxTabSection
            active={Boolean(customerMatch)}
            hovered={customerHovered}
            icon={IconVariant.USER}
            label="box-tabs-customer"
            viewportRect={tabsRect}
            enabled
            onClick={navigateToRoute(Routes.BOX_CUSTOMER)}
            onHoverChanged={handleOnCustomerHover}
          >
            {customerComponent}
          </BoxTabSection>
          <BoxTabSection
            active={Boolean(shortlistMatch)}
            enabled={isTheBoxOpen && automaticSelectionStatus !== AutomaticSelectionStatus.STARTED}
            icon={IconVariant.LABEL}
            label="box-tabs-shortlist"
            onClick={navigateToRoute(Routes.BOX_SHORTLIST)}
          />
          <BoxTabSection
            active={Boolean(selectionMatch)}
            counter={selection?.products.length}
            hovered={selectionHovered}
            icon={IconVariant.BASKET}
            label="box-tabs-selection"
            viewportRect={tabsRect}
            enabled
            onClick={navigateToRoute(Routes.BOX_SELECTION)}
            onHoverChanged={handleOnSelectionHover}
          >
            {selection?.products.length && !automaticSelectionTooltipVisible ? selectionComponent : null}
          </BoxTabSection>
        </section>
      </div>
      <div aria-label="box-tabs-routes" className="box-tabs__routes">
        <div className="box-tabs__route" style={{ display: customerPinnedVisible ? "flex" : "none" }}>
          {customerComponent}
        </div>
        <div className="box-tabs__route box-tabs__route--main">{children}</div>
        <div className="box-tabs__route" style={{ display: selectionPinnedVisible ? "flex" : "none" }}>
          {selectionComponent}
        </div>
      </div>
    </section>
  );
};

export { BoxTabs };
