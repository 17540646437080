import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const PRODUCT_VARIANT_UNMARKED_AS_CANDIDATE = "product_variant_unmarked_as_candidate";

interface ProductVariantUnmarkedAsCandidatePayload {
  readonly aggregateId: string;
  readonly productVariantId: string;
}

interface ProductVariantUnmarkedAsCandidate
  extends DomainEvent<typeof PRODUCT_VARIANT_UNMARKED_AS_CANDIDATE>,
    ProductVariantUnmarkedAsCandidatePayload {}

interface ProductVariantUnmarkedAsCandidateFunction {
  (payload: ProductVariantUnmarkedAsCandidatePayload): ProductVariantUnmarkedAsCandidate;
}

const productVariantUnmarkedAsCandidate: ProductVariantUnmarkedAsCandidateFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: PRODUCT_VARIANT_UNMARKED_AS_CANDIDATE }),
  ...payload,
});

const isProductVariantUnmarkedAsCandidate = (
  event: DomainEvent<MessageName>,
): event is ProductVariantUnmarkedAsCandidate => event.name === PRODUCT_VARIANT_UNMARKED_AS_CANDIDATE;

export type { ProductVariantUnmarkedAsCandidate };
export {
  PRODUCT_VARIANT_UNMARKED_AS_CANDIDATE,
  productVariantUnmarkedAsCandidate,
  isProductVariantUnmarkedAsCandidate,
};
