import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useScroll } from "react-use";
import { useViewPreviousStyleProfileByBoxId } from "../../../core/infrastructure/projection/styleProfile/react/useViewPreviousStyleProfileByBoxId";
import RootLoader from "../../componentLibrary/atoms/rootLoader/RootLoader";
import useBoxSharedContext from "../_behaviors/useBoxSharedContext";
import { CustomerCard } from "./components/customer/customerCard/CustomerCard";
import { Comment } from "./components/comment/Comment";
import { useParams } from "react-router-dom";
import { Characteristics } from "./components/characteristics/Characteristics";
import { Styles } from "./components/styles/Styles";
import { Preferences } from "./components/preferences/Preferences";
import { LookAndLikeModal } from "../../componentLibrary/organisms/lookAndLike/LookAndLikeModal";
import { History } from "./components/history/History";
import { PhotosModal } from "./components/customer/photosModal/PhotosModal";
import { BackToTopButton } from "../../../shared/ui/uiKit/molecules/backToTopButton/BackToTopButton";
import { BOX_HEADER_HEIGHT } from "../box/boxHeader/BoxHeader";
import { useViewAnnotationByCustomerId } from "../../../core/infrastructure/projection/annotation/react/useViewAnnotationByCustomerId";
import { useUpdateAnnotation } from "../../../core/infrastructure/domain/annotation/react/useUpdateAnnotation";
import { Annotations } from "./components/annotations/Annotations";
import { QueryStatus } from "@lookiero/messaging-react";
import { AnnotationsModal } from "./components/annotations/annotationsModal/AnnotationsModal";
import { useTrackAnnotationsPageView } from "../../../shared/tracking/infrastructure/useTrackAnnotationsPageView";
import { TrackingClick, TrackingPage } from "../../../shared/tracking/Tracker";
import { useViewPersonalShopper } from "../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useTrackClick } from "../../../shared/tracking/infrastructure/useTrackClick";
import { useViewStyleProfileByBoxId } from "../../../core/infrastructure/projection/styleProfile/react/useViewStyleProfileByBoxId";
import { useViewCustomerInsightsByCustomerId } from "../../../core/infrastructure/projection/customerInsight/react/useViewCustomerInsightsByCustomerId";
import { useIsCustomerInsightsEnabled } from "../../_featureToggle/useIsCustomerInsightsEnabled";
import { ProductVariantWithProductInfoModal } from "../productVariantWithProductInfo/ProductVariantWithProductInfoModal";
import "./customer.css";

interface HandleOnUpdateAnnotationsFunctionArgs {
  readonly positive: string;
  readonly negative: string;
}
interface HandleOnUpdateAnnotationsFunction {
  (args: HandleOnUpdateAnnotationsFunctionArgs): void;
}

const Customer: FC = () => {
  const { locale } = useParams();
  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();

  const isCustomerInsightEnabled = useIsCustomerInsightsEnabled();
  const [customerInsights] = useViewCustomerInsightsByCustomerId({
    customerId: box.customerId,
    enabled: isCustomerInsightEnabled,
  });
  const showCustomerInsights = isCustomerInsightEnabled && Boolean(customerInsights?.hasLookAndLikeFeedback);

  const [styleProfile, styleProfileStatus] = useViewStyleProfileByBoxId({ boxId: box.id });
  const [previousStyleProfile] = useViewPreviousStyleProfileByBoxId({
    boxId: box.id,
    customerId: box.customerId,
  });

  const [annotation] = useViewAnnotationByCustomerId({ customerId: box.customerId });
  const [updateAnnotation, updateAnnotationStatus] = useUpdateAnnotation({
    id: annotation?.id,
    customerId: box.customerId,
  });

  const [productVariantId, setProductVariantId] = useState<string>("");
  const [productVariantWithProductInfoModalVisible, setProductVariantWithProductInfoModalVisible] = useState(false);
  const handleOnClickProductVariant = useCallback((selectedProductVariantId: string) => {
    setProductVariantId(selectedProductVariantId);
    setProductVariantWithProductInfoModalVisible(true);
  }, []);
  const handleOnCancelProductVariantWithProductInfoModal = useCallback(
    () => setProductVariantWithProductInfoModalVisible(false),
    [],
  );

  const [familyIds, setFamilyIds] = useState<string[] | undefined>();
  const [lookAndLikeModalVisible, setLookAndLikeModalVisible] = useState(false);
  const handleOnClickLookAndLike = useCallback(() => setLookAndLikeModalVisible(true), []);
  const handleOnCancelLookAndLikeModal = useCallback(() => {
    setLookAndLikeModalVisible(false);
    setFamilyIds(undefined);
  }, []);
  const handleOnClickCustomerInsight = useCallback(
    (selectedFamilyIds: string[]) => {
      setFamilyIds(selectedFamilyIds);
      handleOnClickLookAndLike();
    },
    [handleOnClickLookAndLike],
  );

  const [photosModalVisible, setPhotosModalVisible] = useState(false);
  const handleOnClickPhotos = useCallback(() => setPhotosModalVisible(true), []);
  const handleOnCancelPhotosModal = useCallback(() => setPhotosModalVisible(false), []);

  const trackClick = useTrackClick({
    boxId: box.id,
    userId: box.customerId,
    psId: personalShopper?.id,
    section: TrackingPage.CUSTOMER,
  });
  const trackAnnotationsPageView = useTrackAnnotationsPageView({
    boxId: box.id,
    userId: box.customerId,
    psId: personalShopper?.id,
    section: TrackingPage.CUSTOMER,
  });
  const [annotationsModalVisible, setAnnotationsModalVisible] = useState(false);
  const handleOnClickAddAnnotations = useCallback(() => {
    setAnnotationsModalVisible(true);
    trackAnnotationsPageView();
  }, [trackAnnotationsPageView]);
  const handleOnCancelAnnotationsModal = useCallback(() => setAnnotationsModalVisible(false), []);
  const handleOnUpdateAnnotations: HandleOnUpdateAnnotationsFunction = useCallback(
    async ({ negative, positive }) => {
      await updateAnnotation({ negative, positive });
      trackClick({ clickId: TrackingClick.UPDATE_ANNOTATIONS });
      handleOnCancelAnnotationsModal();
    },
    [handleOnCancelAnnotationsModal, trackClick, updateAnnotation],
  );

  const customerRef = useRef<HTMLDivElement>(null);
  const historyRef = useRef<HTMLDivElement>(null);

  const { y: scrollY } = useScroll(customerRef);

  const [isBackToTopButtonVisible, setIsBackToTopButtonVisible] = useState(false);
  const handleOnBackToTopButtonClick = useCallback(
    () => customerRef.current?.scrollTo({ top: 0, behavior: "smooth" }),
    [],
  );

  useEffect(() => {
    if (!historyRef.current?.offsetTop) {
      return;
    }

    setIsBackToTopButtonVisible(scrollY > historyRef.current.offsetTop - BOX_HEADER_HEIGHT);
  }, [scrollY]);

  const dependenciesLoadedStatuses = [QueryStatus.ERROR, QueryStatus.SUCCESS];
  const dependenciesLoaded = dependenciesLoadedStatuses.includes(styleProfileStatus) && styleProfile;

  if (!dependenciesLoaded) {
    return <RootLoader />;
  }

  return (
    <div ref={customerRef} className="customer">
      <ProductVariantWithProductInfoModal
        productVariantId={productVariantId}
        visible={productVariantWithProductInfoModalVisible}
        onCancel={handleOnCancelProductVariantWithProductInfoModal}
      />

      <LookAndLikeModal
        customerId={box.customerId}
        familyIds={familyIds}
        visible={lookAndLikeModalVisible}
        onCancel={handleOnCancelLookAndLikeModal}
        onClickProductVariant={handleOnClickProductVariant}
      />

      <PhotosModal
        photos={styleProfile.customer.photos}
        visible={photosModalVisible}
        onCancel={handleOnCancelPhotosModal}
      />

      <AnnotationsModal
        locale={locale as string}
        negative={annotation?.negative}
        positive={annotation?.positive}
        updateStatus={updateAnnotationStatus}
        updatedOn={annotation?.updatedOn}
        visible={annotationsModalVisible}
        onCancel={handleOnCancelAnnotationsModal}
        onUpdate={handleOnUpdateAnnotations}
      />

      <div className="customer__grid">
        <div>
          <CustomerCard
            customer={styleProfile.customer}
            customerId={box.customerId}
            isCustomerInsightEnabled={isCustomerInsightEnabled}
            onClickLookAndLike={handleOnClickLookAndLike}
            onClickPhotos={handleOnClickPhotos}
          />

          <Annotations
            negative={annotation?.negative}
            positive={annotation?.positive}
            onAdd={handleOnClickAddAnnotations}
          />
        </div>

        <div>
          <Comment
            comment={styleProfile.comment}
            customerServiceComment={styleProfile.customerServiceComment}
            locale={locale as string}
          />

          <div className="customer__subgrid">
            <div>
              <Characteristics
                characteristics={styleProfile.characteristics}
                previousCharacteristics={previousStyleProfile?.characteristics}
              />

              <Preferences
                experiments={styleProfile?.experiments}
                preferences={styleProfile.preferences}
                previousExperiments={previousStyleProfile?.experiments}
                previousPreferences={previousStyleProfile?.preferences}
              />
            </div>

            <Styles
              customerInsights={customerInsights}
              previousStyles={previousStyleProfile?.styles}
              showCustomerInsights={showCustomerInsights}
              styles={styleProfile.styles}
              onClickCustomerInsight={handleOnClickCustomerInsight}
              onClickLookAndLike={handleOnClickLookAndLike}
              onClickProductVariant={handleOnClickProductVariant}
            />
          </div>
        </div>
      </div>

      <div ref={historyRef} style={{ flex: 1 }}>
        <History customerId={box.customerId} onClickProductVariant={handleOnClickProductVariant} />
      </div>

      <BackToTopButton
        className="customer__back-to-top"
        visible={isBackToTopButtonVisible}
        onClick={handleOnBackToTopButtonClick}
      />
    </div>
  );
};

export { Customer };
