import { FC, ReactNode } from "react";
import { OptionProps, components } from "react-select";
import Icon, { IconVariant } from "../../atoms/icon/Icon";
import FormInputOption from "../../_common/FormInputOption";

const dropdownOption =
  (render: (children: ReactNode, data: FormInputOption) => ReactNode): FC<OptionProps<FormInputOption, boolean>> =>
  ({ isSelected, children, ...props }): JSX.Element =>
    (
      <components.Option isSelected={isSelected} {...props}>
        {render(children, props.data)}
        {isSelected && <Icon variant={IconVariant.CLOSE} />}
      </components.Option>
    );

export default dropdownOption;
