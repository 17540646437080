import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { FavouriteProjection } from "./favourite";

const VIEW_FAVOURITE_BY_ID = "view_favourite_by_id";

interface ViewFavouriteByIdPayload {
  readonly id: string;
}

interface ViewFavouriteById extends Query<typeof VIEW_FAVOURITE_BY_ID>, ViewFavouriteByIdPayload {}

interface ViewFavouriteByIdFunction {
  (payload: ViewFavouriteByIdPayload): ViewFavouriteById;
}

const viewFavouriteById: ViewFavouriteByIdFunction = (payload) => ({
  ...query({ name: VIEW_FAVOURITE_BY_ID }),
  ...payload,
});

type ViewFavouriteByIdResult = FavouriteProjection | null;

interface FavouriteByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface FavouriteByIdView {
  (args: FavouriteByIdViewArgs): Promise<ViewFavouriteByIdResult>;
}

interface ViewFavouriteByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: FavouriteByIdView;
}

const viewFavouriteByIdHandler: QueryHandlerFunction<
  ViewFavouriteById,
  ViewFavouriteByIdResult,
  ViewFavouriteByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type { ViewFavouriteById, FavouriteByIdView, ViewFavouriteByIdResult };
export { VIEW_FAVOURITE_BY_ID, viewFavouriteById, viewFavouriteByIdHandler };
