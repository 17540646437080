import { isEqual } from "lodash";
import IntegrationEvent from "./IntegrationEvent";

const MAX_REGISTRY_ENTRIES = 100;

class IntegrationEventsRegistry {
  private registry: IntegrationEvent[] = [];

  public contains(integrationEvent: IntegrationEvent): boolean {
    return Boolean(this.registry.find((event) => isEqual(event, integrationEvent)));
  }

  public push(integrationEvent: IntegrationEvent): void {
    this.registry = this.registry.slice(-MAX_REGISTRY_ENTRIES).concat(integrationEvent);
  }
}

export default IntegrationEventsRegistry;
