import { FC, useMemo } from "react";
import { StyleProfileCardTitle } from "../../../../componentLibrary/organisms/styleProfileCard/components/Title";
import { StyleProfileCustomer } from "../../../../../core/projection/styleProfile/styleProfile";

const calculateAge = (date: Date) => {
  const diff = new Date(Date.now() - date.getTime());

  return Math.abs(diff.getUTCFullYear() - 1970);
};

interface CustomerNameProps {
  readonly customer?: StyleProfileCustomer;
}

const CustomerName: FC<CustomerNameProps> = ({ customer }) => {
  const customerAge = useMemo(
    () => (customer?.birthdate ? calculateAge(customer.birthdate) : undefined),
    [customer?.birthdate],
  );

  return (
    <StyleProfileCardTitle>
      {customer?.name}
      {customerAge && `, ${customerAge}`}
    </StyleProfileCardTitle>
  );
};

export { CustomerName };
