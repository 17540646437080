import { HttpAnnotationsSaveFunction } from "./httpAnnotations";
import { isAnnotationUpdated } from "../../../../domain/annotation/model/annotationUpdated";

interface HttpAnnotationUpdatedFunction extends HttpAnnotationsSaveFunction {}

const httpAnnotationUpdated: HttpAnnotationUpdatedFunction =
  ({ httpPost }) =>
  async ({ aggregateId, customerId, positive, negative, domainEvents }) => {
    const annotationUpdated = domainEvents.find(isAnnotationUpdated);

    if (!annotationUpdated) {
      return;
    }

    await httpPost({
      endpoint: "/update-annotation",
      body: {
        id: aggregateId,
        customerId,
        positive,
        negative,
      },
    });
  };

export { httpAnnotationUpdated };
