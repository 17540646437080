const ACTIVITY_TIME_I18N_PREFIX = "labs.activity_time.";

enum ActivityTimeI18n {
  DESCRIPTION = "description",
  ACTION_TIME = "action_time",
}

export { ACTIVITY_TIME_I18N_PREFIX };

export default ActivityTimeI18n;
