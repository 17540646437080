abstract class DomainError extends Error {
  public constructor(message?: string) {
    // 'Error' breaks prototype chain here
    super(message);

    // Set the prototype explicitly.
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, DomainError.prototype);
  }

  public abstract payload(): Record<string, unknown>;
}

export default DomainError;
