/* eslint-disable @typescript-eslint/naming-convention */
import Sizing from "../../../domain/sizing/model/Sizing";
import Feature from "../../../projection/feature/model/Feature";
import FeatureType from "../../../domain/feature/model/FeatureType";
import FeatureUnit from "../../../domain/feature/model/FeatureUnit";
import Model from "../../../projection/model/model/Model";
import Size from "../../../projection/size/model/Size";
import Measurement from "../../../projection/measurement/model/Measurement";
import Color from "../../../projection/color/model/Color";
import Media from "../../../projection/media/model/Media";
import Price from "../../../projection/price/model/Price";
import { ProductVariantWithProductInfoProjection } from "../../../projection/productVariantWithProductInfo/productVariantWithProductInfo";
import { ProductAlertProjection, ProductAlertType } from "../../../projection/product/productAlert";

interface ProductFeatureDto {
  readonly id: string;
  readonly multiple: boolean;
  readonly type: string; // "NUMBER" | "STRING" | "BOOLEAN";
  readonly unit: string | null; // "CENTIMETERS" | "PERCENTAGE" | "null";
  readonly translationKey: string;
  readonly values: {
    readonly id: string;
    readonly value: string;
    readonly qualifier?: ProductFeatureDto | null;
    readonly translationKey: string;
  }[];
}

interface ProductAlertDto {
  readonly id: string;
  readonly name: string;
  readonly value: string;
  readonly type: string; // "RECOMMENDATION" | "SIZE" | "GARMENT";
}

interface ModelDto {
  readonly id: string;
  readonly upperSize: Size;
  readonly bottomSize: Size;
  readonly shoeSize: Size;
  readonly braSize: Size;
  readonly weight: Measurement;
  readonly height: Measurement;
  readonly chestOutline: Measurement;
  readonly waistOutline: Measurement;
  readonly hipOutline: Measurement;
  readonly fashionModelTestedSize: Size | null;
}

interface ProductVariantWithProductInfoDto {
  readonly productId: string;
  readonly group: string;
  readonly title: string;
  readonly brand: {
    readonly name: string;
    readonly origin: string;
  };
  readonly sizing: string;
  readonly description: ProductFeatureDto[] | null;
  readonly characteristics: ProductFeatureDto[] | null;
  readonly others: ProductFeatureDto[] | null;
  readonly model?: ModelDto;
  readonly alerts: ProductAlertDto[] | null;
  readonly id: string;
  readonly season: number;
  readonly color: Color;
  readonly size: Size;
  readonly sizeChange: Size;
  readonly price: Price;
  readonly media: Media[];
}

interface ToFeatureProjectionFunction {
  (feature: ProductFeatureDto): Feature;
}

const toFeatureProjection: ToFeatureProjectionFunction = ({ id, multiple, type, unit, translationKey, values }) => ({
  id,
  multiple,
  type: type as FeatureType,
  unit: unit as FeatureUnit,
  translationKey,
  values: values.map(({ id, value, qualifier, translationKey }) => ({
    id,
    value,
    qualifier: qualifier ? toFeatureProjection(qualifier) : null,
    translationKey,
  })),
});

interface ToProductAlertProjectionFunction {
  (alert: ProductAlertDto): ProductAlertProjection;
}

const toProductAlertProjection: ToProductAlertProjectionFunction = ({ id, value, type }) => ({
  id,
  value,
  type: type as ProductAlertType,
});

interface ToModelProjectionFunction {
  (model: ModelDto): Model;
}

const toModelProjection: ToModelProjectionFunction = ({
  id,
  upperSize,
  bottomSize,
  shoeSize,
  braSize,
  weight,
  height,
  chestOutline,
  waistOutline,
  hipOutline,
  fashionModelTestedSize,
}) => ({
  id,
  upperSize,
  bottomSize,
  shoeSize,
  braSize,
  weight,
  height,
  chestOutline,
  waistOutline,
  hipOutline,
  fashionModelTestedSize: fashionModelTestedSize ?? undefined,
});

interface ToProductVariantWithProductInfoProjectionFunction {
  (productVariantWithProductInfoDto: ProductVariantWithProductInfoDto): ProductVariantWithProductInfoProjection;
}

const toProductVariantWithProductInfoProjection: ToProductVariantWithProductInfoProjectionFunction = ({
  id,
  productId,
  group,
  title,
  brand,
  sizing,
  description,
  characteristics,
  others,
  model,
  alerts,
  season,
  color,
  size,
  media,
}) => ({
  id,
  productId,
  group,
  title,
  brand,
  sizing: sizing as Sizing,
  description: description ? description.map(toFeatureProjection) : [],
  characteristics: characteristics ? characteristics.map(toFeatureProjection) : [],
  others: others ? others.map(toFeatureProjection) : [],
  model: model ? toModelProjection(model) : undefined,
  alerts: alerts ? alerts.map(toProductAlertProjection) : [],
  season,
  color,
  size,
  media: media as Media[],
});

export type { ProductVariantWithProductInfoDto };
export { toProductVariantWithProductInfoProjection };
