import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpCapacityCalendarsSynchronized } from "./httpCapacityCalendarsSynchronized";
import {
  CapacityCalendarsGetFunction,
  CapacityCalendarsSaveFunction,
} from "../../../../domain/capacityCalendar/model/capacityCalendars";

interface HttpCapacityCalendarsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpCapacityCalendarsGetFunction extends CapacityCalendarsGetFunction<HttpCapacityCalendarsGetFunctionArgs> {}

const getCapacityCalendar: HttpCapacityCalendarsGetFunction = () => async () => {
  throw new Error("CapacityCalendar not found");
};

interface HttpCapacityCalendarsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCapacityCalendarsSaveFunction
  extends CapacityCalendarsSaveFunction<HttpCapacityCalendarsSaveFunctionArgs> {}

const saveCapacityCalendar: HttpCapacityCalendarsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) =>
    await httpCapacityCalendarsSynchronized({ httpPost })(aggregateRoot);

export type { HttpCapacityCalendarsSaveFunction };
export { getCapacityCalendar, saveCapacityCalendar };
