import { FC, ReactNode } from "react";
import classNames from "classnames";
import "./text.css";

enum TextVariant {
  HEADING_1 = "heading-1",
  HEADING_2 = "heading-2",
  HEADING_3 = "heading-3",
  BODY = "body",
  BODY_SMALL = "body-small",
  BODY_BOLD = "body-bold",
  SMALL_CAPS = "small-caps",
  LINK = "link",
}

interface TextProps {
  readonly children: ReactNode;
  readonly className?: string;
  readonly tag?: keyof JSX.IntrinsicElements;
  readonly variant?: TextVariant;
  readonly ellipsis?: boolean;
}
const Text: FC<TextProps> = ({
  tag: TextTag = "span",
  variant = TextVariant.BODY,
  ellipsis = false,
  className,
  ...rest
}) => (
  <TextTag className={classNames("text", `text--${variant}`, className, { "text--ellipsis": ellipsis })} {...rest} />
);

export { TextVariant };

export default Text;
