import { FC } from "react";

import ProductNotification from "../../molecules/productNotification/ProductNotification";
import { ProductAlertProjection } from "../../../../core/projection/product/productAlert";

type ProductAlertsProps = {
  readonly alerts: ProductAlertProjection[];
};
const ProductAlerts: FC<ProductAlertsProps> = ({ alerts }: ProductAlertsProps) => {
  return (
    <section className="product-alerts">
      {alerts.map((alert) => (
        <ProductNotification key={alert.id} i18nMessageId={alert.value} />
      ))}
    </section>
  );
};

export default ProductAlerts;
