import { FC } from "react";
import PriceProjection from "../../../../../core/projection/price/model/Price";
import Brand from "../../../../../core/projection/brand/model/Brand";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import Price, { PriceVariant } from "../../../atoms/price/Price";
import "./product-data.css";

type ProductDataProps = {
  readonly brand: Brand;
  readonly title: string;
  readonly group: string;
  readonly season: number;
  readonly price?: PriceProjection;
};
const ProductData: FC<ProductDataProps> = ({ brand, title, group, season, price }: ProductDataProps) => (
  <section className="product-data">
    <header className="product-data__header">
      <Text className="product-data__brand" variant={TextVariant.BODY_BOLD} ellipsis>
        {brand.name}
      </Text>
    </header>
    {price && <Price price={price} variant={PriceVariant.DEFAULT} />}
    <div className="product-data__info">
      <Text className="product-data__title" variant={TextVariant.BODY_SMALL} ellipsis>
        {title}
      </Text>
      <Text className="product-data__group" variant={TextVariant.BODY_SMALL}>
        {season}
        {group}
      </Text>
    </div>
  </section>
);

export default ProductData;
