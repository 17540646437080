import { FC } from "react";
import classNames from "classnames";
import Skeleton from "../../../../../shared/ui/uiKit/atoms/Skeleton/Skeleton";
import "./product-data.css";

enum ProductDataSkeletonVariant {
  DEFAULT = "default",
  LARGE = "large",
}

type ProductDataSkeletonProps = {
  readonly variant?: ProductDataSkeletonVariant;
};
const ProductDataSkeleton: FC<ProductDataSkeletonProps> = ({
  variant = ProductDataSkeletonVariant.DEFAULT,
}: ProductDataSkeletonProps) => (
  <section className={classNames("product-data", `product-data--${variant}`, "product-data--skeleton")}>
    <header className="product-data__header">
      <Skeleton className="product-preview__brand" />
    </header>
    <Skeleton className="price" />
    <div className="product-data__info">
      <Skeleton />
      <Skeleton className="product-data__group" />
    </div>
  </section>
);

export { ProductDataSkeletonVariant };

export default ProductDataSkeleton;
