import { I18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { asyncActionStateForCommandStatus } from "../../../shared/ui/uiKit/_common/AsyncActionState";
import RootLoader from "../../componentLibrary/atoms/rootLoader/RootLoader";
import NoteTemplateForm from "../../componentLibrary/templates/noteTemplateForm/NoteTemplateForm";
import NoteTemplateI18n, { NOTE_TEMPLATE_I18N_PREFIX } from "../../componentLibrary/_i18n/NoteTemplateI18n";
import { Routes } from "../../_routing/Routing";
import useSyncLocalValue from "../_behaviors/useSyncLocalValue";
import { useValidateNoteTemplate } from "../../../core/infrastructure/projection/noteTemplateValidation/react/useValidateNoteTemplate";
import { useViewNoteTemplateById } from "../../../core/infrastructure/projection/noteTemplate/react/useViewNoteTemplateById";
import { useWriteNoteTemplate } from "../../../core/infrastructure/domain/noteTemplate/react/useWriteNoteTemplate";
import { CommandStatus, QueryStatus } from "@lookiero/messaging-react";
import { NoteTemplateProjection } from "../../../core/projection/noteTemplate/noteTemplate";
import { generatePath } from "react-router-dom";
import { useViewPersonalShopper } from "../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import "./note-template-detail.css";

const emptyNoteTemplate = {} as NoteTemplateProjection;

const NoteTemplatesDetail: FC = () => {
  const navigate = useNavigate();
  const { locale, box: legacyBoxId, template: noteTemplateId } = useParams();
  const navigateToTemplatesList = useCallback(() => {
    navigate(
      generatePath(Routes.BOX_SELECTION_NOTES_TEMPLATES, { locale: locale as string, box: legacyBoxId as string }),
      {
        replace: true,
      },
    );
  }, [legacyBoxId, locale, navigate]);
  const navigateToTemplateDetail = useCallback(
    (noteTemplateId: string) => {
      navigate(
        generatePath(Routes.BOX_SELECTION_NOTES_TEMPLATES_DETAIL, {
          locale: locale as string,
          box: legacyBoxId as string,
          template: noteTemplateId,
        }),
        { replace: true },
      );
    },
    [legacyBoxId, locale, navigate],
  );

  const [personalShopper] = useViewPersonalShopper();
  const [noteTemplateProjection, noteTemplateProjectionStatus] = useViewNoteTemplateById({ id: noteTemplateId });
  const [noteTemplate, setNoteTemplate] = useSyncLocalValue<NoteTemplateProjection | null | undefined>(
    0,
    noteTemplateId ? noteTemplateProjection : emptyNoteTemplate,
    () => void 0,
  );
  const [noteTemplateValidation] = useValidateNoteTemplate({ noteTemplate });

  const newNoteTemplateId = useMemo(() => uuid(), []);
  const [writeNoteTemplate, writeNoteTemplateStatus] = useWriteNoteTemplate({
    psId: personalShopper?.id,
    noteTemplateId: noteTemplateId || newNoteTemplateId,
  });
  const onSaveHandler = useCallback(
    async ({ title, content, category }: NoteTemplateProjection) =>
      await writeNoteTemplate({ title, content, category }),
    [writeNoteTemplate],
  );

  useEffect(() => {
    if (noteTemplateId || writeNoteTemplateStatus !== CommandStatus.SUCCESS) {
      return;
    }

    navigateToTemplateDetail(newNoteTemplateId);
  }, [navigateToTemplateDetail, newNoteTemplateId, noteTemplateId, writeNoteTemplateStatus]);

  return (
    <>
      <section className="note-template-detail">
        {noteTemplateProjectionStatus === QueryStatus.LOADING ? (
          <RootLoader />
        ) : !noteTemplate && noteTemplateId ? (
          <I18nMessage id={NoteTemplateI18n.TEMPLATES_NOT_FOUND} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
        ) : noteTemplate ? (
          <NoteTemplateForm
            noteTemplate={noteTemplate}
            noteTemplateValidation={noteTemplateValidation}
            onCancel={navigateToTemplatesList}
            onChanged={setNoteTemplate}
            onSave={onSaveHandler}
            onSaveState={asyncActionStateForCommandStatus[writeNoteTemplateStatus]}
          />
        ) : null}
      </section>
    </>
  );
};

export default NoteTemplatesDetail;
