import { command, Command } from "@lookiero/messaging";
import BoxPreviewStatus from "../../boxPreview/model/BoxPreviewStatus";

const REPLACE_PRODUCT_VARIANT = "replace_product_variant";

interface ReplaceProductVariantPayload {
  readonly aggregateId: string;
  readonly boxId: string;
  readonly boxNumber: string;
  readonly selectedProductVariantId: string;
  readonly deselectedProductVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
}

interface ReplaceProductVariant extends Command<typeof REPLACE_PRODUCT_VARIANT>, ReplaceProductVariantPayload {}

interface ReplaceProductVariantFunction {
  (payload: ReplaceProductVariantPayload): ReplaceProductVariant;
}

const replaceProductVariant: ReplaceProductVariantFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: REPLACE_PRODUCT_VARIANT }),
  ...payload,
});

export type { ReplaceProductVariant };
export { REPLACE_PRODUCT_VARIANT, replaceProductVariant };
