import { FC } from "react";
import { useIntl } from "react-intl";
import Locale from "../../../../core/domain/country/model/Locale";
import SizeProjection, { sizeFormat } from "../../../../core/projection/size/model/Size";

type SizeProps = {
  readonly size: SizeProjection;
};
const Size: FC<SizeProps> = ({ size }: SizeProps) => {
  const { locale } = useIntl();

  return <>{sizeFormat({ locale: locale as Locale, size })}</>;
};

export default Size;
