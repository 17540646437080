import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import { ReturnQuestionType } from "../../../../core/projection/returnQuestion/returnQuestion";
import { ReturnQuestionItem } from "../components/ReturnQuestionItem";

type ReturnQuestionItems = Record<ReturnQuestionType, ReturnQuestionItem>;

const ReturnQuestionItemContext = createContext<ReturnQuestionItems>({} as ReturnQuestionItems);

interface ReturnQuestionItemContextProviderProps {
  readonly returnQuestionItems: ReturnQuestionItems;
  readonly children: ReactNode;
}

const ReturnQuestionItemProvider: FC<ReturnQuestionItemContextProviderProps> = ({ returnQuestionItems, children }) => (
  <ReturnQuestionItemContext.Provider value={returnQuestionItems}>{children}</ReturnQuestionItemContext.Provider>
);

interface UseReturnQuestionItemFunctionArgs {
  readonly type: ReturnQuestionType;
}

interface UseReturnQuestionItemFunction {
  (args: UseReturnQuestionItemFunctionArgs): ReturnQuestionItem;
}

const useReturnQuestionItem: UseReturnQuestionItemFunction = ({ type }) => {
  const items = useContext<ReturnQuestionItems>(ReturnQuestionItemContext);
  const item = useMemo(() => items[type], [items, type]);

  invariant(
    items,
    "Your are trying to use the useReturnQuestionItem hook without wrapping your app with the <ReturnQuestionItemProvider>.",
  );

  invariant(item, `The provided ReturnQuestionType (${type}) is NOT SUPPORTED`);

  return item;
};

export type { ReturnQuestionItems };
export { useReturnQuestionItem, ReturnQuestionItemProvider };
