import { command, Command } from "@lookiero/messaging";

const WRITE_NOTE = "write_note";

interface WriteNotePayload {
  readonly aggregateId: string;
  readonly boxId: string;
  readonly content: string;
  readonly legacyBoxId: string;
  readonly psId: string;
}

interface WriteNote extends Command<typeof WRITE_NOTE>, WriteNotePayload {}

interface WriteNoteFunction {
  (payload: WriteNotePayload): WriteNote;
}

const writeNote: WriteNoteFunction = ({ aggregateId, ...payload }) => ({
  ...command({ name: WRITE_NOTE, aggregateId }),
  ...payload,
});

export type { WriteNote };
export { WRITE_NOTE, writeNote };
