import { FC } from "react";
import classNames from "classnames";
import { ReturnQuestionProjection } from "../../../../../core/projection/returnQuestion/returnQuestion";
import Tooltip from "../../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import {
  ReturnQuestionItemProvider,
  ReturnQuestionItems,
} from "../../../../views/historyReturnQuestions/behaviors/useReturnQuestionItem";
import { ReturnQuestionFeedbackIconItem } from "../../../../views/historyReturnQuestions/components/returnQuestionFeedbackIconItem/ReturnQuestionFeedbackIconItem";
import { ReturnQuestions } from "../../../../views/historyReturnQuestions/ReturnQuestions";
import { HistoryProductVariantFeedbackTooltip } from "./HistoryProductVariantFeedbackTooltip";
import { HostDefaultReturnQuestionFeedbackItem } from "../../../../views/historyReturnQuestions/components/hostDefaultReturnQuestionFeedbackItem/HostDefaultReturnQuestionFeedbackItem";
import "./history-product-variant-feedback.css";

const returnQuestionItems: ReturnQuestionItems = {
  HOST_DEFAULT: HostDefaultReturnQuestionFeedbackItem,
  HOST_TEXTAREA: ReturnQuestionFeedbackIconItem,
  HOST_SELECT: ReturnQuestionFeedbackIconItem,
  HOST_STACK: ReturnQuestionFeedbackIconItem,
  TEXTAREA: ReturnQuestionFeedbackIconItem,
  OPTION: ReturnQuestionFeedbackIconItem,
};

interface HistoryProductVariantFeedbackProps {
  readonly returnQuestions: ReturnQuestionProjection[];
  readonly className?: string;
}

const HistoryProductVariantFeedback: FC<HistoryProductVariantFeedbackProps> = ({ returnQuestions, className }) => (
  <Tooltip title={<HistoryProductVariantFeedbackTooltip returnQuestions={returnQuestions} />}>
    <ul className={classNames("history-product-variant-feedback", className)}>
      <ReturnQuestionItemProvider returnQuestionItems={returnQuestionItems}>
        <ReturnQuestions returnQuestions={returnQuestions} />
      </ReturnQuestionItemProvider>
    </ul>
  </Tooltip>
);

export { HistoryProductVariantFeedback };
