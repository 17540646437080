import { I18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback } from "react";
import closedBoxStatuses from "../../../../core/domain/box/model/ClosedBoxStatuses";
import { ButtonVariant } from "../../../../shared/ui/uiKit/atoms/button/Button";
import ActionButton from "../../../../shared/ui/uiKit/molecules/actionButton/ActionButton";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../componentLibrary/_i18n/SelectionI18n";
import useBoxSharedContext from "../../_behaviors/useBoxSharedContext";
import { useCanTheBoxBeSentToSupervise } from "../../../../core/infrastructure/projection/box/react/useCanTheBoxBeSentToSupervise";
import { useCloseBox } from "../../../../core/infrastructure/domain/box/react/useCloseBox";
import { useSendBoxToSupervise } from "../../../../core/infrastructure/domain/box/react/useSendBoxToSupervise";
import { useCanPersonalShopperSendBoxesToSupervise } from "../../../../core/infrastructure/projection/personalShopper/react/useCanPersonalShopperSendBoxesToSupervise";
import { useViewPersonalShopper } from "../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useActivityTime } from "../../box/activityTime/useActivityTime";
import { useCanTheBoxBeClosed } from "../../../../core/infrastructure/projection/box/react/useCanTheBoxBeClosed";
import { asyncActionStateForCommandStatus } from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import "./selection-and-note-actions.css";
import { useLogger } from "../../../../shared/logging/useLogger";

interface SelectionAndNoteActionsProps {
  readonly onCloseBox?: () => void;
}
const SelectionAndNoteActions: FC<SelectionAndNoteActionsProps> = ({ onCloseBox }) => {
  const { box } = useBoxSharedContext();
  const logger = useLogger();

  const [canPersonalShopperSendBoxesToSupervise] = useCanPersonalShopperSendBoxesToSupervise();
  const [canTheBoxBeClosed] = useCanTheBoxBeClosed({ boxNumber: String(box.boxNumber) });
  const [canTheBoxBeSentToSupervise] = useCanTheBoxBeSentToSupervise({ boxNumber: String(box.boxNumber) });

  const [personalShopper] = useViewPersonalShopper();
  const { stop: stopActivityTime } = useActivityTime();

  const [closeBox, closeBoxStatus] = useCloseBox({ boxId: box.id, boxNumber: String(box.boxNumber), logger });
  const handleOnCloseBox = useCallback(async () => {
    await closeBox();
    onCloseBox?.();
    stopActivityTime();
  }, [closeBox, onCloseBox, stopActivityTime]);

  const [sendBoxToSupervise, sendBoxToSuperviseStatus] = useSendBoxToSupervise({
    boxId: box.id,
    boxNumber: String(box.boxNumber),
    personalShopperId: personalShopper?.id,
    logger,
  });

  const isBoxClosed = closedBoxStatuses.includes(box.status);

  return (
    <section className="selection-and-note-actions" data-testid="selection-and-note-actions">
      {canPersonalShopperSendBoxesToSupervise && (
        <ActionButton
          aria-label="send-to-supervise"
          className="selection-and-note-actions__send-to-supervise"
          disabled={!canTheBoxBeSentToSupervise}
          state={asyncActionStateForCommandStatus[sendBoxToSuperviseStatus]}
          variant={ButtonVariant.BASIC}
          onClick={sendBoxToSupervise}
        >
          <I18nMessage id={SelectionI18n.SEND_BOX_TO_SUPERVISE} prefix={SELECTION_I18N_PREFIX} />
        </ActionButton>
      )}
      <ActionButton
        aria-label="close-box"
        className="selection-and-note-actions__close-box"
        disabled={!canTheBoxBeClosed}
        state={asyncActionStateForCommandStatus[closeBoxStatus]}
        variant={isBoxClosed ? ButtonVariant.DESTRUCTIVE : ButtonVariant.PRIMARY}
        onClick={handleOnCloseBox}
      >
        <I18nMessage
          id={isBoxClosed ? SelectionI18n.BOX_CLOSED : SelectionI18n.CLOSE_BOX}
          prefix={SELECTION_I18N_PREFIX}
        />
      </ActionButton>
    </section>
  );
};

export default SelectionAndNoteActions;
