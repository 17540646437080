import { command, Command } from "@lookiero/messaging";

const WRITE_NOTE_TEMPLATE = "write_note_template";

interface WriteNoteTemplatePayload {
  readonly aggregateId: string;
  readonly psId: string;
  readonly title: string;
  readonly category: string | undefined;
  readonly content: string;
}

interface WriteNoteTemplate extends Command<typeof WRITE_NOTE_TEMPLATE>, WriteNoteTemplatePayload {}

interface WriteNoteTemplateFunction {
  (payload: WriteNoteTemplatePayload): WriteNoteTemplate;
}

const writeNoteTemplate: WriteNoteTemplateFunction = ({ aggregateId, ...payload }) => ({
  ...command({ name: WRITE_NOTE_TEMPLATE, aggregateId }),
  ...payload,
});

export type { WriteNoteTemplate };
export { WRITE_NOTE_TEMPLATE, writeNoteTemplate };
