import { useCallback } from "react";
import { useTracker } from "./useTracker";
import { NoteAction, Section, TrackingClick } from "../Tracker";
import { LookProjection } from "../../../core/projection/looks/look";
import { LookTemplate } from "../../../core/projection/looks/lookTemplate";
import { SelectionAlertProjection } from "../../../core/projection/alert/selectionAlert";

interface TrackClickFunctionArgs {
  readonly action?: NoteAction;
  readonly clickId: TrackingClick;
  readonly look?: LookProjection;
  readonly lookTemplate?: LookTemplate;
  readonly position?: number;
  readonly productId?: string;
  readonly productVariantIds?: string[];
  readonly alerts?: SelectionAlertProjection[];
}
interface TrackClickFunction {
  (args: TrackClickFunctionArgs): void;
}

interface UseTrackClickFunctionArgs {
  readonly boxId?: string;
  readonly psId?: string;
  readonly section: Section;
  readonly userId?: string;
}
interface UseTrackClickFunction {
  (args: UseTrackClickFunctionArgs): TrackClickFunction;
}

const useTrackClick: UseTrackClickFunction = ({ section, psId, userId, boxId }) => {
  const tracker = useTracker();

  const trackClick: TrackClickFunction = useCallback(
    ({ clickId, look, lookTemplate, productVariantIds, action, position, productId, alerts }) => {
      if (!(tracker && section && psId && clickId)) {
        return;
      }

      tracker.click({
        action,
        alerts,
        boxId,
        clickId,
        look,
        lookTemplate,
        position,
        productId,
        productVariantIds,
        psId,
        section,
        userId,
      });
    },
    [boxId, psId, section, tracker, userId],
  );

  return trackClick;
};

export { useTrackClick };
