import { isNoteGenerated } from "../../../../domain/note/model/noteGenerated";
import { HttpNotesSaveFunction } from "./httpNotes";

interface HttpNoteGenerateFunction extends HttpNotesSaveFunction {}

const httpNoteGenerate: HttpNoteGenerateFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents, boxId, psId }) => {
    const noteGenerated = domainEvents.find(isNoteGenerated);

    if (!noteGenerated) {
      return;
    }

    await httpPost({
      endpoint: "/generate-note-by-id",
      body: {
        id: aggregateId,
        boxId,
        psId,
      },
    });
  };

export { httpNoteGenerate };
