import { useI18nMessage, useIntl } from "@lookiero/i18n-react";
import Country from "../../../../core/domain/country/model/Country";
import FormInputOption from "../../../../shared/ui/uiKit/_common/FormInputOption";
import DatePicker from "../../../../shared/ui/uiKit/organisms/datePicker/DatePicker";
import { CAPACITY_I18N_PREFIX, CapacityI18n } from "../../../componentLibrary/_i18n/CapacityI18n";
import { FC, useCallback, useMemo } from "react";
import Dropdown from "../../../../shared/ui/uiKit/organisms/dropdown/Dropdown";
import { Segment } from "../../../../core/projection/segment/segment";
import { CountryDropdown } from "../../../componentLibrary/organisms/countryDropdown/CountryDropdown";
import "./capacity-filters.css";

interface CapacityFiltersProps {
  readonly from: Date | undefined;
  readonly to: Date | undefined;
  readonly country: Country[] | undefined;
  readonly segment: Segment[] | undefined;
  readonly onFromChanged: (date: Date) => void;
  readonly onToChanged: (date: Date) => void;
  readonly onCountryChanged: (countries: Country[]) => void;
  readonly onSegmentChanged: (segment: Segment | Segment[]) => void;
}
const CapacityFilters: FC<CapacityFiltersProps> = ({
  from,
  to,
  country,
  segment,
  onFromChanged,
  onToChanged,
  onCountryChanged,
  onSegmentChanged,
}) => {
  const { formatMessage } = useIntl();

  const fromDateLabel = useI18nMessage({ id: CapacityI18n.FROM_DATE_LABEL, prefix: CAPACITY_I18N_PREFIX });
  const toDateLabel = useI18nMessage({ id: CapacityI18n.TO_DATE_LABEL, prefix: CAPACITY_I18N_PREFIX });
  const countryLabel = useI18nMessage({ id: CapacityI18n.COUNTRY_LABEL, prefix: CAPACITY_I18N_PREFIX });
  const segmentLabel = useI18nMessage({ id: CapacityI18n.SEGMENT_LABEL, prefix: CAPACITY_I18N_PREFIX });

  const segmentOptions: FormInputOption[] = useMemo(
    () => [
      {
        label: formatMessage({ id: `${CAPACITY_I18N_PREFIX}${CapacityI18n.SEGMENT_WOMEN_LABEL}` }),
        value: Segment.WOMEN,
      },
      {
        label: formatMessage({ id: `${CAPACITY_I18N_PREFIX}${CapacityI18n.SEGMENT_MEN_LABEL}` }),
        value: Segment.MEN,
      },
    ],
    [formatMessage],
  );

  const handleOnFromChanged = useCallback(
    (date: Date) => onFromChanged(new Date(date.setHours(0, 0, 0, 0))),
    [onFromChanged],
  );

  const handleOnToChanged = useCallback(
    (date: Date) => onToChanged(new Date(date.setHours(0, 0, 0, 0))),
    [onToChanged],
  );

  return (
    <section className="capacity-filters" data-testid="capacity-filters">
      <div className="capacity-filters__filter">
        <DatePicker label={fromDateLabel} value={from} onChange={handleOnFromChanged} />
      </div>
      <div className="capacity-filters__filter">
        <DatePicker label={toDateLabel} value={to} onChange={handleOnToChanged} />
      </div>
      <div className="capacity-filters__filter">
        <CountryDropdown label={countryLabel} selected={country} onChange={onCountryChanged} />
      </div>
      <div className="capacity-filters__filter">
        <Dropdown
          label={segmentLabel}
          options={segmentOptions}
          value={segment}
          isMulti
          onChange={onSegmentChanged as (value?: string | string[] | null | undefined) => void}
        />
      </div>
    </section>
  );
};

export { CapacityFilters };
