import { CSSProperties, FC, forwardRef, ReactNode, Ref } from "react";
import { VariableSizeList } from "react-window";

/**
 * This wrapper component is needed due to the WindowScroller
 * inconsistent behavior.
 *
 * If we don't set this component as the outerElementType
 * prop of the VariableSizeList, the scroller will jump to the
 * top of the list as the user reaches the end of the list.
 */

export type VirtualizedListOuterElementTagName = "table" | "div";

interface VirtualizedListOuterElementProps {
  readonly children: ReactNode;
  readonly style: CSSProperties;
}

interface VirtualizedListOuterElementFunctionArgs {
  readonly tagName?: VirtualizedListOuterElementTagName;
}
interface VirtualizedListOuterElementFunction {
  (args: VirtualizedListOuterElementFunctionArgs): FC<VirtualizedListOuterElementProps>;
}

const virtualizedListOuterElement: VirtualizedListOuterElementFunction = ({ tagName: Element = "div" }) =>
  forwardRef(({ children, style }: VirtualizedListOuterElementProps, ref: Ref<VariableSizeList>) => (
    <Element style={{ ...style, height: "auto", overflow: "hidden" }}>{children}</Element>
  ));

export { virtualizedListOuterElement };
