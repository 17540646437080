import { FC, memo } from "react";
import { ReturnQuestionProjection } from "../../../core/projection/returnQuestion/returnQuestion";
import { useReturnQuestionItem } from "./behaviors/useReturnQuestionItem";

interface ReturnQuestionProps {
  readonly returnQuestionParentId: string;
  readonly returnQuestion: ReturnQuestionProjection;
}

const ReturnQuestion: FC<ReturnQuestionProps> = ({ returnQuestion, returnQuestionParentId }: ReturnQuestionProps) => {
  const Item = useReturnQuestionItem({ type: returnQuestion.type });

  return (
    <Item returnQuestion={returnQuestion} returnQuestionParentId={returnQuestionParentId}>
      <>
        {returnQuestion.children?.map((childReturnQuestion) => (
          <ReturnQuestion
            key={childReturnQuestion.id}
            returnQuestion={childReturnQuestion}
            returnQuestionParentId={returnQuestion.id}
          />
        ))}
      </>
    </Item>
  );
};

export default memo(ReturnQuestion);
