import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { NoteProjection } from "../../../../projection/note/note";
import { viewNoteByBoxId } from "../../../../projection/note/viewNoteByBoxId";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { isNoteWritten } from "../../../../domain/note/model/noteWritten";
import { isNoteGenerated } from "../../../../domain/note/model/noteGenerated";
import { DomainEvent, MessageName } from "@lookiero/messaging";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isNoteWritten(event) || isNoteGenerated(event);

interface UseViewNoteByBoxIdFunctionArgs {
  readonly boxId: string;
}

interface UseViewNoteByBoxIdFunction {
  (args: UseViewNoteByBoxIdFunctionArgs): UseQueryFunctionResult<NoteProjection>;
}

const useViewNoteByBoxId: UseViewNoteByBoxIdFunction = ({ boxId }) =>
  useQuery({
    query: viewNoteByBoxId({ boxId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: { staleTime: Infinity, retry: 0, refetchOnWindowFocus: false },
  });

export { useViewNoteByBoxId };
