import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const STOCK_SURVEY_SUBMITTED = "stock_survey_submitted";

interface StockSurveySubmittedPayload {
  readonly aggregateId: string;
}

interface StockSurveySubmitted extends DomainEvent<typeof STOCK_SURVEY_SUBMITTED> {}

interface StockSurveySubmittedFunction {
  (payload: StockSurveySubmittedPayload): StockSurveySubmitted;
}

const stockSurveySubmitted: StockSurveySubmittedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: STOCK_SURVEY_SUBMITTED });

const isStockSurveySubmitted = (event: DomainEvent<MessageName>): event is StockSurveySubmitted =>
  event.name === STOCK_SURVEY_SUBMITTED;

export type { StockSurveySubmitted };
export { STOCK_SURVEY_SUBMITTED, stockSurveySubmitted, isStockSurveySubmitted };
