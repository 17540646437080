import { useCallback } from "react";
import invariant from "tiny-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { startAutomaticSelection } from "../../../../domain/selection/command/startAutomaticSelection";
import { Logger } from "../../../../../shared/logging/Logger";

interface UseStartAutomaticSelectionArgs {
  readonly logger: Logger;
}

interface StartAutomaticSelectionFunctionArgs {
  readonly selectionId?: string;
  readonly boxId: string;
}

interface StartAutomaticSelectionFunction {
  (args: StartAutomaticSelectionFunctionArgs): Promise<void>;
}

type UseStartAutomaticSelectionResult = [start: StartAutomaticSelectionFunction, status: CommandStatus];

interface UseStartAutomaticSelection {
  (args: UseStartAutomaticSelectionArgs): UseStartAutomaticSelectionResult;
}

const useStartAutomaticSelection: UseStartAutomaticSelection = ({ logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const start: StartAutomaticSelectionFunction = useCallback(
    async ({ selectionId, boxId }) => {
      invariant(selectionId, "selectionId must be defined in order to start automatic selection");

      try {
        await commandBus(startAutomaticSelection({ aggregateId: selectionId, boxId }));
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [commandBus, logger],
  );

  return [start, status];
};

export { useStartAutomaticSelection };
