import { isStockSurveySubmitted } from "../../../../domain/stockSurvey/model/stockSurveySubmitted";
import { HttpStockSurveysSaveFunction } from "./httpStockSurveys";

interface HttpStockSurveySubmitFunction extends HttpStockSurveysSaveFunction {}

const httpStockSurveySubmit: HttpStockSurveySubmitFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents, boxId }) => {
    const stockSurveySubmitted = domainEvents.find(isStockSurveySubmitted);

    if (!stockSurveySubmitted) {
      return;
    }

    await httpPost({
      endpoint: "/submit-stock-survey",
      body: {
        id: aggregateId,
        boxId,
      },
    });
  };

export { httpStockSurveySubmit };
