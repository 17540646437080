import { FC, useCallback } from "react";
import { GroupHeadingProps, components } from "react-select";
import FormInputOption from "../../_common/FormInputOption";
import Text, { TextVariant } from "../../atoms/text/Text";
import Icon, { IconVariant } from "../../atoms/icon/Icon";

const dropdownGroupHeading: (
  onGroupHeadingClick: (label: string) => void,
) => FC<GroupHeadingProps<FormInputOption, boolean>> =
  (onGroupHeadingClick) =>
  ({ children, ...props }): JSX.Element => {
    const handleOnClick = useCallback(() => onGroupHeadingClick(children as string), [children]);

    return (
      <components.GroupHeading {...props}>
        <div className="dropdown__group-heading-content" onClick={handleOnClick}>
          <Text variant={TextVariant.BODY_SMALL}>{children}</Text>
          <Icon variant={IconVariant.PLUS} />
        </div>
      </components.GroupHeading>
    );
  };

export default dropdownGroupHeading;
