import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const ACTIVITY_TIME_PREVIEWED = "activity_time_previewed";

interface ActivityTimePreviewedPayload {
  readonly aggregateId: string;
}

interface ActivityTimePreviewed extends DomainEvent<typeof ACTIVITY_TIME_PREVIEWED>, ActivityTimePreviewedPayload {}

interface ActivityTimePreviewedFunction {
  (payload: ActivityTimePreviewedPayload): ActivityTimePreviewed;
}

const activityTimePreviewed: ActivityTimePreviewedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: ACTIVITY_TIME_PREVIEWED });

const isActivityTimePreviewed = (event: DomainEvent<MessageName>): event is ActivityTimePreviewed =>
  event.name === ACTIVITY_TIME_PREVIEWED;

export type { ActivityTimePreviewed };
export { ACTIVITY_TIME_PREVIEWED, isActivityTimePreviewed, activityTimePreviewed };
