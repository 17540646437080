import { NotificationLevel } from "../../domain/notification/model/notification";

enum NotificationType {
  TOAST = "TOAST",
  MODAL = "MODAL",
}

interface NotificationProjection {
  readonly id: string;
  readonly type: NotificationType;
  readonly bodyI18nKey: string;
  readonly timestamp: number;
}

interface ToastNotificationProjection extends NotificationProjection {
  readonly type: NotificationType.TOAST;
  readonly level: NotificationLevel;
}

interface ModalNotificationProjection extends NotificationProjection {
  readonly type: NotificationType.MODAL;
  readonly titleI18nKey: string;
  readonly acceptI18nKey: string;
}

const isToastNotification = (notification: NotificationProjection): notification is ToastNotificationProjection =>
  notification.type === NotificationType.TOAST;

const isModalNotification = (notification: NotificationProjection): notification is ModalNotificationProjection =>
  notification.type === NotificationType.MODAL;

export type { ModalNotificationProjection, NotificationProjection, ToastNotificationProjection };

export { isModalNotification, isToastNotification };
