import { FC, useMemo } from "react";
import { I18nDate, I18nMessage } from "@lookiero/i18n-react";
import useBoxSharedContext from "../../_behaviors/useBoxSharedContext";
import { BoxHeaderInfo } from "./boxHeaderInfo/BoxHeaderInfo";
import { BOX_I18N_PREFIX, BoxI18n } from "../../../componentLibrary/_i18n/BoxI18n";
import { useViewCustomerById } from "../../../../core/infrastructure/projection/customer/react/useViewCustomerById";
import { CustomerTag } from "./customerTag/CustomerTag";
import { Category } from "../../../../core/projection/customer/category";
import BoxStatus from "../../../../core/domain/box/model/BoxStatus";
import classNames from "classnames";
import { Subscription } from "../../../../core/projection/customer/subscription";
import { ActivityTime } from "../activityTime/ActivityTime";
import "./box-header.css";
import { useListBoxesByCustomerId } from "../../../../core/infrastructure/projection/box/react/useListBoxesByCustomerId";

const BOX_HEADER_HEIGHT = 36;

interface SubscriptionI18nKeyFunctionArgs {
  readonly subscription?: Subscription;
}

interface SubscriptionI18nKeyFunction {
  (args: SubscriptionI18nKeyFunctionArgs): string | undefined;
}

const subscriptionI18nKey: SubscriptionI18nKeyFunction = ({ subscription }) => {
  switch (subscription) {
    case Subscription.ON_DEMAND:
      return BoxI18n.SUBCRIPTION_ON_DEMAND;
    case Subscription.MONTHLY:
      return BoxI18n.SUBCRIPTION_MONTHLY;
    case Subscription.BIMONTHLY:
      return BoxI18n.SUBCRIPTION_BIMONTHLY;
    case Subscription.QUARTERLY:
      return BoxI18n.SUBCRIPTION_QUATERLY;
  }
};

enum HeaderVariant {
  FRAUD = "fraud",
  PS_CHANGE = "ps-change",
  INFLUENCER = "influencer",
  VIP = "vip",
  FACEBOOK = "facebook",
}

interface HeaderVariantFunctionArgs {
  readonly category?: Category;
  readonly boxStatus: BoxStatus;
  readonly askedForPsChange?: boolean;
}

interface HeaderVariantFunction {
  (args: HeaderVariantFunctionArgs): HeaderVariant | undefined;
}

const headerVariant: HeaderVariantFunction = ({ category, boxStatus, askedForPsChange }) => {
  if (boxStatus === BoxStatus.BLOCKED) {
    return HeaderVariant.FRAUD;
  }

  if (askedForPsChange) {
    return HeaderVariant.PS_CHANGE;
  }

  switch (category) {
    case Category.INFLUENCER:
      return HeaderVariant.INFLUENCER;
    case Category.VIP:
      return HeaderVariant.VIP;
    case Category.FACEBOOK:
      return HeaderVariant.FACEBOOK;
  }
};

const BoxHeader: FC = () => {
  const { box } = useBoxSharedContext();
  const [customer] = useViewCustomerById({ id: box.customerId });
  const variant = headerVariant({
    category: customer?.category,
    boxStatus: box.status,
    askedForPsChange: customer?.askedForPsChange,
  });
  const subscriptionI18n = subscriptionI18nKey({ subscription: customer?.subscription });
  const [boxes] = useListBoxesByCustomerId({ customerId: box.customerId });
  const closedOrFinishedBoxes = useMemo(
    () => boxes?.filter((box) => [BoxStatus.FINISHED, BoxStatus.CLOSED].includes(box.status)),
    [boxes],
  );

  return (
    <header className={classNames("box-header", `box-header--${variant}`)}>
      {customer && boxes && (
        <section className="box-header__info">
          <BoxHeaderInfo label="box-number" title={<I18nMessage id={BoxI18n.BOX_NUMBER} prefix={BOX_I18N_PREFIX} />}>
            {box.boxNumber}
          </BoxHeaderInfo>
          <BoxHeaderInfo
            label="preparation-date"
            title={<I18nMessage id={BoxI18n.PREPARATION_DATE} prefix={BOX_I18N_PREFIX} />}
          >
            <I18nDate value={box.prepareFor} />
          </BoxHeaderInfo>
          <BoxHeaderInfo label="subscription" title={<I18nMessage id={BoxI18n.SUBCRIPTION} prefix={BOX_I18N_PREFIX} />}>
            {subscriptionI18n && <I18nMessage id={subscriptionI18n} prefix={BOX_I18N_PREFIX} />}
          </BoxHeaderInfo>
          <BoxHeaderInfo label="boxes-count" title={<I18nMessage id={BoxI18n.BOXES_COUNT} prefix={BOX_I18N_PREFIX} />}>
            {closedOrFinishedBoxes?.length}
          </BoxHeaderInfo>
          <CustomerTag
            askedForPsChange={customer.askedForPsChange}
            boxStatus={box.status}
            category={customer.category}
          />
        </section>
      )}
      <section aria-label="box-header-activity-time" className="box-header__activity-time">
        <ActivityTime boxId={box.id} />
      </section>
    </header>
  );
};

export { BoxHeader, BOX_HEADER_HEIGHT };
