import { useCallback } from "react";
import { Logger } from "../../../../../shared/logging/Logger";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import BoxPreviewStatus from "../../../../domain/boxPreview/model/BoxPreviewStatus";
import { replaceProductVariant } from "../../../../domain/selection/command/replaceProductVariant";
import invariant from "ts-invariant";

interface ReplaceProductVariantFunctionArgs {
  readonly deselectedProductVariantId: string;
  readonly selectedProductVariantId: string;
}

interface ReplaceProductVariantFunction {
  (args: ReplaceProductVariantFunctionArgs): Promise<void>;
}

type UseReplaceProductVariantFunctionResult = [replace: ReplaceProductVariantFunction, status: CommandStatus];

interface UseReplaceProductVariantFunctionArgs {
  readonly selectionId: string | undefined;
  readonly boxId: string;
  readonly boxNumber: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
  readonly logger: Logger;
}

interface UseReplaceProductVariantFunction {
  (args: UseReplaceProductVariantFunctionArgs): UseReplaceProductVariantFunctionResult;
}

const useReplaceProductVariant: UseReplaceProductVariantFunction = ({
  selectionId,
  boxId,
  boxNumber,
  boxPreviewStatus,
  logger,
}) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const replace: ReplaceProductVariantFunction = useCallback(
    async ({ deselectedProductVariantId, selectedProductVariantId }) => {
      invariant(selectionId, "Cannot replace product variant without a selectionId");

      try {
        await commandBus(
          replaceProductVariant({
            aggregateId: selectionId,
            boxId,
            boxNumber,
            boxPreviewStatus,
            deselectedProductVariantId,
            selectedProductVariantId,
          }),
        );
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [boxId, boxNumber, boxPreviewStatus, commandBus, logger, selectionId],
  );

  return [replace, status];
};

export { useReplaceProductVariant };
