import HttpClient from "../../../../../shared/delivery/HttpClient";
import Environment from "../../../../projection/environment/model/Environment";
import EnvironmentFetcher from "../../../../projection/environment/model/EnvironmentFetcher";
import { camelcaseKeys } from "../../../../../shared/delivery/infrastructure/camelcaseKeys";

class HttpEnvironmentFetcher implements EnvironmentFetcher {
  private client: HttpClient;
  private environment!: Environment;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  public async fetch(): Promise<Environment> {
    if (!this.environment) {
      this.environment = camelcaseKeys(await (await this.client.get(`/config.json?${Date.now()}`)).json());
    }

    return this.environment;
  }
}

export default HttpEnvironmentFetcher;
