import jwtDecode from "jwt-decode";
import Country from "../../core/domain/country/model/Country";
import PersonalShopperRole from "../../core/domain/personalShopper/model/PersonalShopperRole";
import { FeatureToggleSettings } from "../../core/projection/environment/model/Environment";
import Storage from "../storage/domain/model/Storage";
import FeatureToggle from "./FeatureToggle";

type AuthCookie = {
  readonly sub: number;
  readonly exp: number;
  readonly displayName: string;
  readonly roles: string;
  readonly iat: number;
  readonly psId: number;
  readonly market: string;
};

class AuthCookieBasedFeatureToggle implements FeatureToggle {
  private static COOKIE_NAME = "authtoken";
  private readonly storage: Storage<string>;
  private readonly featureToggle: FeatureToggleSettings;

  public constructor(storage: Storage<string>, featureToggle: FeatureToggleSettings) {
    this.storage = storage;
    this.featureToggle = featureToggle;
  }

  public async isEnabled(): Promise<boolean> {
    const authCookie = await this.storage.read(AuthCookieBasedFeatureToggle.COOKIE_NAME);

    if (!authCookie) {
      return false;
    }

    const auth: AuthCookie = jwtDecode(authCookie);

    return this.featureToggle.psRole
      ? this.isEnabledForPsRole(auth.roles.split(",").map((role) => role.trim()) as PersonalShopperRole[])
      : this.isEnabledForPsCountry(auth.market as Country) || this.isEnabledForPsID(auth.psId);
  }

  private isEnabledForPsCountry(psCountry: Country): boolean {
    if (psCountry === undefined) {
      return false;
    }

    if (!this.featureToggle || this.featureToggle.country.length === 0) {
      return false;
    }

    return this.featureToggle.country.includes(psCountry);
  }

  private isEnabledForPsID(psId: number): boolean {
    if (psId === undefined) {
      return false;
    }

    if (!this.featureToggle || this.featureToggle.psId.length === 0) {
      return false;
    }

    return this.featureToggle.psId.includes(psId);
  }

  private isEnabledForPsRole(roles: PersonalShopperRole[]): boolean {
    if (roles === undefined) {
      return false;
    }

    if (!this.featureToggle || this.featureToggle.psRole.length === 0) {
      return false;
    }

    return roles.some((role) => this.featureToggle.psRole.includes(role));
  }
}

export default AuthCookieBasedFeatureToggle;
