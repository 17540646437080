import { FC, useCallback } from "react";
import classNames from "classnames";
import { I18nMessage } from "@lookiero/i18n-react";
import Button, { ButtonVariant } from "../../../../shared/ui/uiKit/atoms/button/Button";
import Picture from "../../../../shared/ui/uiKit/atoms/picture/Picture";
import Card from "../../../../shared/ui/uiKit/layouts/card/Card";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import ShortlistI18n from "../../_i18n/ShortlistI18n";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import cdnImageUrl from "../../../_util/cdnImageUrl";
import "./product-variant-comparer-preview.css";
import { ProductVariantProjection } from "../../../../core/projection/product/productVariant";

const PRODUCT_VARIANT_COMPARER_PREVIEW_IMAGE_WIDTH = 120;

interface ProductVariantComparerProductProps {
  readonly productVariant: ProductVariantProjection;
  readonly onRemove: (productVariant: ProductVariantProjection) => void;
}

const ProductVariantComparerProduct: FC<ProductVariantComparerProductProps> = ({ productVariant, onRemove }) => {
  const handleOnRemove = useCallback(() => onRemove(productVariant), [onRemove, productVariant]);
  const productVariantPictureSrc = cdnImageUrl({
    url: productVariant.media[0].url,
    width: PRODUCT_VARIANT_COMPARER_PREVIEW_IMAGE_WIDTH,
  });

  return (
    <div key={productVariant.id} className="product-variant-comparer-preview__product">
      <Picture aspectRatio={AspectRatio.R_4_5} src={productVariantPictureSrc} lazy />
      <Button icon={IconVariant.CLOSE} onClick={handleOnRemove} />
    </div>
  );
};

type ProductVariantComparerPreviewProps = {
  readonly productVariants: [ProductVariantProjection | null, ProductVariantProjection | null];
  readonly onCompare: () => void;
  readonly onRemove: (productVariant: ProductVariantProjection) => void;
  readonly onCancel: () => void;
};
const ProductVariantComparerPreview: FC<ProductVariantComparerPreviewProps> = ({
  productVariants,
  onCompare,
  onRemove,
  onCancel,
}: ProductVariantComparerPreviewProps) => {
  const isVisible = Boolean(productVariants.find((productVariant) => Boolean(productVariant)));
  const isCompareButtonEnabled = Boolean(productVariants.every((productVariant) => Boolean(productVariant)));

  return (
    <Card
      className={classNames("product-variant-comparer-preview", {
        "product-variant-comparer-preview--visible": isVisible,
      })}
    >
      <header className="product-variant-comparer-preview__header">
        <Text variant={TextVariant.BODY_BOLD}>
          <I18nMessage id={ShortlistI18n.COMPARE} />
        </Text>
        <Button icon={IconVariant.CLOSE} onClick={onCancel} />
      </header>
      <div className="product-variant-comparer-preview__products">
        {productVariants.map((productVariant, index) =>
          productVariant ? (
            <ProductVariantComparerProduct
              key={productVariant.id}
              productVariant={productVariant}
              onRemove={onRemove}
            />
          ) : null,
        )}
      </div>
      <div className="product-variant-comparer-preview__actions">
        <Button disabled={!isCompareButtonEnabled} variant={ButtonVariant.PRIMARY} onClick={onCompare}>
          <I18nMessage id={ShortlistI18n.COMPARE} />
        </Button>
      </div>
    </Card>
  );
};

export default ProductVariantComparerPreview;
