import { invariant } from "ts-invariant";
import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { writeNoteTemplate as writeNoteTemplateCommand } from "../../../../domain/noteTemplate/command/writeNoteTemplate";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface WriteNoteTemplateFunctionArgs {
  readonly title: string;
  readonly category: string | undefined;
  readonly content: string;
}

interface WriteNoteTemplateFunction {
  (args: WriteNoteTemplateFunctionArgs): Promise<void>;
}

type UseWriteNoteTemplateReturn = [writeNoteTemplate: WriteNoteTemplateFunction, status: CommandStatus];

interface UseWriteNoteTemplateArgs {
  readonly noteTemplateId: string;
  readonly psId: string | undefined;
}

interface UseWriteNoteTemplate {
  (args: UseWriteNoteTemplateArgs): UseWriteNoteTemplateReturn;
}

const useWriteNoteTemplate: UseWriteNoteTemplate = ({ psId, noteTemplateId }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const writeNoteTemplate: WriteNoteTemplateFunction = useCallback(
    async ({ title, content, category }) => {
      try {
        invariant(psId !== undefined, "There is no personal shopper defined to add a note template");

        await commandBus(
          writeNoteTemplateCommand({
            aggregateId: noteTemplateId,
            psId,
            title,
            content,
            category,
          }),
        );
      } catch (error) {}
    },
    [commandBus, noteTemplateId, psId],
  );

  return [writeNoteTemplate, status];
};

export { useWriteNoteTemplate };
