import { FC, HTMLProps, MouseEventHandler, PropsWithChildren, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import "./draggable.css";

type DraggableProps = PropsWithChildren & HTMLProps<HTMLDivElement>;

const Draggable: FC<DraggableProps> = ({ children, className, onMouseDown, onMouseMove, onMouseUp, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleDragStart: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!ref.current) {
        return;
      }

      const startX = e.pageX - ref.current.offsetLeft;
      const startY = e.pageY - ref.current.offsetTop;
      const scrollLeft = ref.current.scrollLeft;
      const scrollTop = ref.current.scrollTop;

      mouseCoords.current = { startX, startY, scrollLeft, scrollTop };

      setIsMouseDown(true);

      onMouseDown?.(e);
    },
    [onMouseDown],
  );

  const handleDragEnd: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      setIsMouseDown(false);

      onMouseUp?.(e);
    },
    [onMouseUp],
  );

  const handleDrag: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!isMouseDown || !ref.current) {
        return;
      }

      e.preventDefault();

      const x = e.pageX - ref.current.offsetLeft;
      const y = e.pageY - ref.current.offsetTop;
      const walkX = (x - mouseCoords.current.startX) * 1.5;
      const walkY = (y - mouseCoords.current.startY) * 1.5;
      ref.current.scrollLeft = mouseCoords.current.scrollLeft - walkX;
      ref.current.scrollTop = mouseCoords.current.scrollTop - walkY;

      onMouseMove?.(e);
    },
    [isMouseDown, onMouseMove],
  );

  return (
    <div
      {...props}
      ref={ref}
      aria-label="draggable"
      className={classNames("draggable", className)}
      style={{
        cursor: isMouseDown ? "grabbing" : "grab",
      }}
      onMouseDown={handleDragStart}
      onMouseMove={handleDrag}
      onMouseUp={handleDragEnd}
    >
      {children}
    </div>
  );
};

export { Draggable };
