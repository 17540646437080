import camelcaseKeys from "camelcase-keys";
import {
  ActivityTimeByPsIdAndBoxIdView,
  ViewActivityTimeByPsIdAndBoxIdResult,
} from "../../../projection/activityTime/viewActivityTimeByPsIdAndBoxId";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpActivityTimeByPsIdAndBoxIdView extends ActivityTimeByPsIdAndBoxIdView {}

interface HttpActivityTimeByPsIdAndBoxIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpActivityTimeByPsIdAndBoxIdViewFunction {
  (args: HttpActivityTimeByPsIdAndBoxIdViewFunctionArgs): HttpActivityTimeByPsIdAndBoxIdView;
}

interface ViewActivityTimeByPsIdAndBoxIdResponse {
  readonly result: ViewActivityTimeByPsIdAndBoxIdResult;
}

const httpActivityTimeByPsIdAndBoxIdView: HttpActivityTimeByPsIdAndBoxIdViewFunction =
  ({ httpPost }) =>
  async ({ psId, boxId, signal }) =>
    await httpPost<ViewActivityTimeByPsIdAndBoxIdResponse, ViewActivityTimeByPsIdAndBoxIdResult>({
      endpoint: "/view-activity-time-by-ps-id-and-box-id",
      body: { ps_id: psId, box_id: boxId },
      signal,
      result: {
        error: null,
        success: (response) => (response.result ? camelcaseKeys(response.result) : null),
      },
    });

export { httpActivityTimeByPsIdAndBoxIdView };
