import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

const COUNT_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA = "count_look_and_like_feedbacks_by_criteria";

interface CountLookAndLikeFeedbacksCriteria {
  readonly familyIds: string[];
  readonly customerId: string;
  readonly response: boolean;
}

interface CountLookAndLikeFeedbacksByCriteriaPayload {
  readonly criteria: CountLookAndLikeFeedbacksCriteria;
}

interface CountLookAndLikeFeedbacksByCriteria
  extends Query<typeof COUNT_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA>,
    CountLookAndLikeFeedbacksByCriteriaPayload {}

interface CountLookAndLikeFeedbacksByCriteriaFunction {
  (payload: CountLookAndLikeFeedbacksByCriteriaPayload): CountLookAndLikeFeedbacksByCriteria;
}

const countLookAndLikeFeedbacksByCriteria: CountLookAndLikeFeedbacksByCriteriaFunction = (payload) => ({
  ...query({ name: COUNT_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA }),
  ...payload,
});

type CountLookAndLikeFeedbacksByCriteriaResult = number;

interface LookAndLikeFeedbacksCountByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly criteria: CountLookAndLikeFeedbacksCriteria;
}

interface LookAndLikeFeedbacksCountByCriteriaView {
  (args: LookAndLikeFeedbacksCountByCriteriaViewArgs): Promise<CountLookAndLikeFeedbacksByCriteriaResult>;
}

interface CountLookAndLikeFeedbacksByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: LookAndLikeFeedbacksCountByCriteriaView;
}

const countLookAndLikeFeedbacksByCriteriaHandler: QueryHandlerFunction<
  CountLookAndLikeFeedbacksByCriteria,
  CountLookAndLikeFeedbacksByCriteriaResult,
  CountLookAndLikeFeedbacksByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ criteria }) =>
    view({ criteria, signal });

export type {
  CountLookAndLikeFeedbacksCriteria,
  LookAndLikeFeedbacksCountByCriteriaView,
  CountLookAndLikeFeedbacksByCriteria,
  CountLookAndLikeFeedbacksByCriteriaResult,
};
export {
  COUNT_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA,
  countLookAndLikeFeedbacksByCriteriaHandler,
  countLookAndLikeFeedbacksByCriteria,
};
