import { ReactNode } from "react";
import Tooltip from "../../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import classNames from "classnames";
import { StyleProfileAnswer } from "../../../../../core/projection/styleProfile/styleProfile";
import { isArray, isEqual } from "lodash";
import "./answer.css";

interface AnswerProps<Q extends StyleProfileAnswer extends infer K ? K : never> {
  readonly answer: Q;
  readonly previousAnswer: Q | undefined;
  readonly className?: string;
  readonly children: (answer: NonNullable<Q>) => ReactNode;
}

const Answer: <Q extends StyleProfileAnswer>(props: AnswerProps<Q>) => JSX.Element = ({
  answer,
  previousAnswer,
  className,
  children,
}) => (
  <>
    {!answer ||
    isEqual(previousAnswer, answer) ||
    !previousAnswer ||
    (isArray(previousAnswer) && previousAnswer.length === 0) ? (
      answer && children(answer)
    ) : (
      <Tooltip className="answer" contentClassName="answer__content" title={children(previousAnswer)}>
        <span className={classNames("answer__highlight", className)}>{children(answer)}</span>
      </Tooltip>
    )}
  </>
);

export { Answer };
