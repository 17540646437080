import DomainError from "../../DomainError";

class SelectionNotModifiableError extends DomainError {
  private static MESSAGE = "Selection is not modifiable";

  public constructor() {
    super(SelectionNotModifiableError.MESSAGE);

    // Set the prototype explicitly.
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, SelectionNotModifiableError.prototype);
  }

  public payload(): Record<string, unknown> {
    return {};
  }
}

export default SelectionNotModifiableError;
