import { FC, useCallback, useMemo } from "react";
import Size, {
  sizeForLength,
  sizeForMainSize,
  mainSizesForComposedSizes,
  lengthsForComposedSizes,
  sizeAndLength,
} from "../../../../core/projection/size/model/Size";
import ButtonOption, { ButtonOptionState } from "../../../../shared/ui/uiKit/atoms/buttonOption/ButtonOption";
import SizeSelectorProps from "./SizeSelectorProps";
import "./composed-size-selector.css";

type ComposedSizeSelectorMainOptionProps = {
  readonly size: string;
  readonly selectedLength: string;
  readonly selectedSize: string;
  readonly compatibleSizes: Size[];
  readonly onChange: (size: string) => void;
};
const ComposedSizeSelectorMainOption: FC<ComposedSizeSelectorMainOptionProps> = ({
  size,
  selectedLength,
  selectedSize,
  compatibleSizes,
  onChange,
}: ComposedSizeSelectorMainOptionProps) => {
  const state = useMemo(
    () =>
      size === selectedSize
        ? ButtonOptionState.SELECTED
        : compatibleSizes.some(
            (compatibleSize) =>
              sizeAndLength(compatibleSize).size === size && sizeAndLength(compatibleSize).length === selectedLength,
          )
        ? ButtonOptionState.DEFAULT
        : ButtonOptionState.VISUALLY_DISABLED,
    [compatibleSizes, selectedLength, selectedSize, size],
  );
  const handleOnChange = useCallback(() => {
    if (size === selectedSize) {
      return;
    }

    onChange(size);
  }, [onChange, selectedSize, size]);

  return <ButtonOption label={size} state={state} onChange={handleOnChange} />;
};

type ComposedSizeSelectorLengthOptionProps = {
  readonly length: string;
  readonly selectedSize: string;
  readonly selectedLength: string;
  readonly compatibleSizes: Size[];
  readonly onChange: (length: string) => void;
};
const ComposedSizeSelectorLengthOption: FC<ComposedSizeSelectorLengthOptionProps> = ({
  length,
  selectedSize,
  selectedLength,
  compatibleSizes,
  onChange,
}: ComposedSizeSelectorLengthOptionProps) => {
  const state = useMemo(
    () =>
      length === selectedLength
        ? ButtonOptionState.SELECTED
        : compatibleSizes.some(
            (compatibleSize) =>
              sizeAndLength(compatibleSize).length === length && sizeAndLength(compatibleSize).size === selectedSize,
          )
        ? ButtonOptionState.DEFAULT
        : ButtonOptionState.VISUALLY_DISABLED,
    [compatibleSizes, length, selectedLength, selectedSize],
  );

  const handleOnChange = useCallback(() => {
    if (length === selectedLength) {
      return;
    }

    onChange(length);
  }, [length, onChange, selectedLength]);

  return <ButtonOption label={length} state={state} onChange={handleOnChange} />;
};

const ComposedSizeSelector: FC<SizeSelectorProps> = ({
  sizes,
  compatibleSizes,
  selectedSize,
  onChange,
}: SizeSelectorProps) => {
  const { size, length } = useMemo(() => sizeAndLength(selectedSize), [selectedSize]);
  const mainSizes = useMemo(() => mainSizesForComposedSizes(sizes), [sizes]);
  const lengths = useMemo(() => lengthsForComposedSizes(sizes), [sizes]);

  const handleMainSizeChanged = useCallback(
    (size: string) => onChange(sizeForMainSize({ size, length }, sizes)),
    [length, onChange, sizes],
  );
  const handleLengthChanged = useCallback(
    (length: string) => onChange(sizeForLength({ size, length }, sizes)),
    [onChange, size, sizes],
  );

  return (
    <div className="composed-size-selector">
      <div className="composed-size-selector__main">
        {mainSizes.map((aSize) => (
          <ComposedSizeSelectorMainOption
            key={aSize}
            compatibleSizes={compatibleSizes}
            selectedLength={length}
            selectedSize={size}
            size={aSize}
            onChange={handleMainSizeChanged}
          />
        ))}
      </div>
      <div className="composed-size-selector__length">
        {lengths.map((aLength) => (
          <ComposedSizeSelectorLengthOption
            key={aLength}
            compatibleSizes={compatibleSizes}
            length={aLength}
            selectedLength={length}
            selectedSize={size}
            onChange={handleLengthChanged}
          />
        ))}
      </div>
    </div>
  );
};

export default ComposedSizeSelector;
