import classNames from "classnames";
import { FC } from "react";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import ActionButton from "../../../../shared/ui/uiKit/molecules/actionButton/ActionButton";
import AsyncActionState from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import "./product-preview-action-button.css";

type ProductPreviewActionButtonProps = {
  readonly className?: string;
  readonly label?: string;
  readonly icon: IconVariant;
  readonly onClick: () => void;
  readonly state?: AsyncActionState;
};
const ProductPreviewActionButton: FC<ProductPreviewActionButtonProps> = ({
  className,
  label,
  icon,
  onClick,
  state,
}) => (
  <ActionButton
    aria-label={label}
    className={classNames("product-preview-action-button", className)}
    defaultStateTimeout={1000}
    state={state}
    onClick={onClick}
  >
    <Icon className="product-preview-action-button__icon" variant={icon} />
  </ActionButton>
);

export default ProductPreviewActionButton;
