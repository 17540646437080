import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { RemoveSelectionOrder } from "../command/removeSelectionOrder";
import { selectionOrderRemoved } from "./selectionOrderRemoved";
import { SortSelectionOrder } from "../command/sortSelectionOrder";
import { selectionOrderSorted } from "./selectionOrderSorted";

interface SelectionOrder extends AggregateRoot {
  readonly boxNumber: string;
  readonly productVariantIds: string[];
}

const removeSelectionOrderHandler: CommandHandlerFunction<RemoveSelectionOrder, SelectionOrder> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxNumber } = command;

    return {
      ...aggregateRoot,
      boxNumber,
      domainEvents: [selectionOrderRemoved({ aggregateId })],
    };
  };

const sortSelectionOrderHandler: CommandHandlerFunction<SortSelectionOrder, SelectionOrder> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxNumber, productVariantIds } = command;

    return {
      ...aggregateRoot,
      boxNumber,
      productVariantIds,
      domainEvents: [selectionOrderSorted({ aggregateId })],
    };
  };

export type { SelectionOrder };
export { removeSelectionOrderHandler, sortSelectionOrderHandler };
