import classNames from "classnames";
import { FC } from "react";
import "./skeleton.css";

interface SkeletonProps {
  readonly className?: string;
}
const Skeleton: FC<SkeletonProps> = ({ className }: SkeletonProps) => (
  <div className={classNames("skeleton", className)}>
    <span className="skeleton__content" />
  </div>
);

export default Skeleton;
