import { FC } from "react";
import { ReturnQuestionProjection } from "../../../../../core/projection/returnQuestion/returnQuestion";
import {
  ReturnQuestionItemProvider,
  ReturnQuestionItems,
} from "../../../../views/historyReturnQuestions/behaviors/useReturnQuestionItem";
import { HostDefaultReturnQuestionFeedbackItem } from "../../../../views/historyReturnQuestions/components/hostDefaultReturnQuestionFeedbackItem/HostDefaultReturnQuestionFeedbackItem";
import { ReturnQuestionFeedbackItem } from "../../../../views/historyReturnQuestions/components/returnQuestionFeedbackItem/ReturnQuestionFeedbackItem";
import { ReturnQuestions } from "../../../../views/historyReturnQuestions/ReturnQuestions";

const returnQuestionItems: ReturnQuestionItems = {
  HOST_DEFAULT: HostDefaultReturnQuestionFeedbackItem,
  HOST_TEXTAREA: ReturnQuestionFeedbackItem,
  HOST_SELECT: ReturnQuestionFeedbackItem,
  HOST_STACK: ReturnQuestionFeedbackItem,
  TEXTAREA: ReturnQuestionFeedbackItem,
  OPTION: ReturnQuestionFeedbackItem,
};

interface HistoryProductVariantFeedbackTooltipProps {
  readonly returnQuestions: ReturnQuestionProjection[];
}

const HistoryProductVariantFeedbackTooltip: FC<HistoryProductVariantFeedbackTooltipProps> = ({ returnQuestions }) => (
  <ReturnQuestionItemProvider returnQuestionItems={returnQuestionItems}>
    <ReturnQuestions returnQuestions={returnQuestions} />
  </ReturnQuestionItemProvider>
);

export { HistoryProductVariantFeedbackTooltip };
