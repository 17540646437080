import { useCallback } from "react";
import { useTracker } from "./useTracker";
import { Section, TrackingFilteringChangeType } from "../Tracker";

interface TrackFilteringModifiedFunctionArgs {
  readonly changeType: TrackingFilteringChangeType;
}
interface TrackFilteringModifiedFunction {
  (args: TrackFilteringModifiedFunctionArgs): void;
}

interface UseTrackFilteringModifiedFunctionArgs {
  readonly section: Section;
  readonly boxId?: string;
  readonly psId?: string;
  readonly userId?: string;
}
interface UseTrackFilteringModifiedFunction {
  (args: UseTrackFilteringModifiedFunctionArgs): TrackFilteringModifiedFunction;
}

const useTrackFilteringModified: UseTrackFilteringModifiedFunction = ({ section, psId, userId, boxId }) => {
  const tracker = useTracker();

  const trackFilteringModified: TrackFilteringModifiedFunction = useCallback(
    ({ changeType }) => {
      if (!(tracker && section && psId && boxId)) {
        return;
      }

      tracker.filteringModified({
        boxId,
        psId,
        section,
        userId,
        changeType,
      });
    },
    [boxId, psId, section, tracker, userId],
  );

  return trackFilteringModified;
};

export { useTrackFilteringModified };
