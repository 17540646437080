import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { HistoryShopProductVariantProjection } from "./historyShopProductVariant";

const SEARCH_HISTORY_SHOP_BY_CUSTOMER_ID = "search_history_shop_by_customer_id";

interface SearchHistoryShopByCustomerIdPayload {
  readonly customerId: string;
}

interface SearchHistoryShopByCustomerId
  extends Query<typeof SEARCH_HISTORY_SHOP_BY_CUSTOMER_ID>,
    SearchHistoryShopByCustomerIdPayload {}

interface SearchHistoryShopByCustomerIdFunction {
  (payload: SearchHistoryShopByCustomerIdPayload): SearchHistoryShopByCustomerId;
}

const searchHistoryShopByCustomerId: SearchHistoryShopByCustomerIdFunction = (payload) => ({
  ...query({ name: SEARCH_HISTORY_SHOP_BY_CUSTOMER_ID }),
  ...payload,
});

type SearchHistoryShopByCustomerIdResult = HistoryShopProductVariantProjection[] | null;

interface HistoryShopByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface HistoryShopByCustomerIdView {
  (args: HistoryShopByCustomerIdViewArgs): Promise<SearchHistoryShopByCustomerIdResult>;
}

interface SearchHistoryShopByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: HistoryShopByCustomerIdView;
}

const searchHistoryShopByCustomerIdHandler: QueryHandlerFunction<
  SearchHistoryShopByCustomerId,
  SearchHistoryShopByCustomerIdResult,
  SearchHistoryShopByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { HistoryShopByCustomerIdView, SearchHistoryShopByCustomerId, SearchHistoryShopByCustomerIdResult };
export { SEARCH_HISTORY_SHOP_BY_CUSTOMER_ID, searchHistoryShopByCustomerId, searchHistoryShopByCustomerIdHandler };
