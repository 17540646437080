import { FC } from "react";
import { CustomerName } from "../components/customerName/CustomerName";
import useBoxSharedContext from "../../_behaviors/useBoxSharedContext";
import { useViewStyleProfileByBoxId } from "../../../../core/infrastructure/projection/styleProfile/react/useViewStyleProfileByBoxId";

const CustomerPinnedHeader: FC = () => {
  const { box } = useBoxSharedContext();
  const [styleProfile] = useViewStyleProfileByBoxId({ boxId: box.id });

  return <CustomerName customer={styleProfile?.customer} />;
};

export { CustomerPinnedHeader };
