import { FamilyProjection } from "../../../projection/family/family";
import { FamiliesView, ListFamiliesResult } from "../../../projection/family/listFamilies";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpFamiliesView extends FamiliesView {}

interface HttpFamiliesViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpFamiliesViewFunction {
  (args: HttpFamiliesViewFunctionArgs): HttpFamiliesView;
}

interface ViewFamiliesResponse {
  readonly result: FamilyProjection[];
}

const httpFamiliesView: HttpFamiliesViewFunction =
  ({ httpPost }) =>
  async ({ signal }) =>
    await httpPost<ViewFamiliesResponse, ListFamiliesResult>({
      endpoint: "/list-families",
      body: {},
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpFamiliesView };
