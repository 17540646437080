import { SelectionByIdView, ViewSelectionByIdResult } from "../../../projection/selection/viewSelectionById";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { BasicSelectionDto, toBasicSelectionProjection } from "./selection";

interface HttpSelectionByIdView extends SelectionByIdView {}

interface HttpSelectionByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpSelectionByIdViewFunction {
  (args: HttpSelectionByIdViewFunctionArgs): HttpSelectionByIdView;
}

const httpSelectionByIdView: HttpSelectionByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<BasicSelectionDto, ViewSelectionByIdResult>({
      endpoint: "/view-selection-by-id",
      body: { id },
      signal,
      result: {
        error: null,
        success: (response) => toBasicSelectionProjection(response),
      },
    });

export { httpSelectionByIdView };
