import { command, Command } from "@lookiero/messaging";

const SORT_SELECTION_ORDER = "sort_selection_order";

interface SortSelectionOrderPayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
  readonly productVariantIds: string[];
}

interface SortSelectionOrder extends Command<typeof SORT_SELECTION_ORDER>, SortSelectionOrderPayload {}

interface SortSelectionOrderFunction {
  (payload: SortSelectionOrderPayload): SortSelectionOrder;
}

const sortSelectionOrder: SortSelectionOrderFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: SORT_SELECTION_ORDER }),
  ...payload,
});

export type { SortSelectionOrder };
export { SORT_SELECTION_ORDER, sortSelectionOrder };
