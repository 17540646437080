import {
  ForecastByCustomerIdView,
  ViewForecastByCustomerIdResult,
} from "../../../projection/forecast/viewForecastByCustomerId";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpForecastByCustomerIdView extends ForecastByCustomerIdView {}

interface HttpForecastByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpForecastByCustomerIdViewFunction {
  (args: HttpForecastByCustomerIdViewFunctionArgs): HttpForecastByCustomerIdView;
}

const httpForecastByCustomerIdView: HttpForecastByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<ViewForecastByCustomerIdResult>({
      endpoint: "/view-forecast-by-customer-id",
      body: { customerId },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpForecastByCustomerIdView };
