import { ProcessManagerFunction } from "@lookiero/messaging";
import { ProductVariantDeselected } from "../../selection/model/ProductVariantDeselected";
import {
  viewSelectionOrderByBoxNumber,
  ViewSelectionOrderByBoxNumberResult,
} from "../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";
import { sortSelectionOrder } from "../command/sortSelectionOrder";

interface SortSelectionWhenProductVariantDeselectedFunction extends ProcessManagerFunction<ProductVariantDeselected> {}

const sortSelectionWhenProductVariantDeselected: SortSelectionWhenProductVariantDeselectedFunction =
  ({ commandBus, queryBus }) =>
  async ({ aggregateId, boxNumber, productVariantId, productVariantIds }) => {
    const selectionOrder: ViewSelectionOrderByBoxNumberResult = await queryBus(
      viewSelectionOrderByBoxNumber({ boxNumber }),
    );

    const selectionOrderProductVariantIds = selectionOrder ? selectionOrder.productVariantIds : productVariantIds;

    await commandBus(
      sortSelectionOrder({
        aggregateId,
        boxNumber,
        productVariantIds: selectionOrderProductVariantIds.filter((id: string) => id !== productVariantId),
      }),
    );
  };

export { sortSelectionWhenProductVariantDeselected };
