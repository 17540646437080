import loadjs from "loadjs";
import {
  ActivatePredefinedFilterTrackerParams,
  CheckLastBoxesInfoTrackerParams,
  ClickTrackerParams,
  DetailViewParams,
  FilterTrackerParams,
  FilteringModifiedTrackerParams,
  GenerateNoteTrackerParams,
  MarkUnmarkFavouriteTrackerParams,
  PreviewSentTrackerParams,
  SelectDeselectProductVariantTrackerParams,
  ShortlistPageViewTrackerParams,
  Tracker,
} from "../Tracker";

declare global {
  interface BaseDataLayerEvent {
    readonly event:
      | "gtm.js"
      | "click"
      | "detailView"
      | "select-deselect-product-variant"
      | "filters"
      | "check_last_boxes_info"
      | "change_predefined_filter"
      | "activate_predefined_filter"
      | "previewSent"
      | "note_assistant"
      | "shortlist_page_view"
      | "annotations_page_view"
      | "select_favourite";
  }

  type DataLayerEvent = BaseDataLayerEvent & Record<string, unknown>;

  interface Window {
    dataLayer: {
      push: (evt: DataLayerEvent) => void;
    };
  }
}

interface InitFunctionArgs {
  readonly project: string;
  readonly gtmId: string;
}
interface InitFunction {
  (args: InitFunctionArgs): Promise<AsyncGtmTracker>;
}

class AsyncGtmTracker implements Tracker {
  private project: string;

  private constructor(project: string) {
    this.project = project;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  }

  public static init: InitFunction = async ({ project, gtmId }) => {
    try {
      await loadjs(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`, { returnPromise: true });
    } catch (error) {}

    return new AsyncGtmTracker(project);
  };

  private push(event: DataLayerEvent): void {
    if (window.dataLayer) {
      window.dataLayer.push(event);
    }
  }

  public click({
    action,
    alerts,
    boxId,
    clickId,
    look,
    lookTemplate,
    position,
    productId,
    productVariantIds,
    psId,
    section,
    userId,
  }: ClickTrackerParams): void {
    this.push({
      event: "click",
      action,
      alerts,
      boxId,
      clickId,
      look,
      lookTemplate,
      position,
      productId,
      productVariantIds,
      psId,
      section,
      userId,
    });
  }

  public detailView({ section, productId, productVariantId, psId, boxId, userId }: DetailViewParams): void {
    this.push({
      event: "detailView",
      section,
      productId,
      productVariantId,
      psId,
      boxId,
      userId,
    });
  }

  public selectDeselectProductVariant({
    boxId,
    position,
    productVariantId,
    psId,
    section,
    select,
    subsection,
    userId,
  }: SelectDeselectProductVariantTrackerParams): void {
    this.push({
      event: "select-deselect-product-variant",
      boxId,
      position,
      productVariantId,
      psId,
      section,
      select,
      subsection,
      userId,
    });
  }

  public filters({ boxId, filters, psId, section, userId }: FilterTrackerParams): void {
    this.push({
      event: "filters",
      boxId,
      filters,
      psId,
      section,
      userId,
    });
  }

  public checkLastBoxesInfo({
    boxId,
    psId,
    section,
    userId,
    checkedBoxId,
    reviewedInfo,
  }: CheckLastBoxesInfoTrackerParams): void {
    this.push({
      event: "check_last_boxes_info",
      boxId,
      psId,
      section,
      userId,
      checkedBoxId,
      reviewedInfo,
    });
  }

  public filteringModified({ boxId, psId, section, userId, changeType }: FilteringModifiedTrackerParams): void {
    this.push({
      event: "change_predefined_filter",
      boxId,
      psId,
      section,
      userId,
      changeType,
    });
  }

  public activatePredefinedFilter({
    boxId,
    psId,
    section,
    userId,
    enabled,
  }: ActivatePredefinedFilterTrackerParams): void {
    this.push({
      event: "activate_predefined_filter",
      boxId,
      psId,
      section,
      userId,
      enabled,
    });
  }

  public previewSent({ boxId, psId, section, userId, boxNumber }: PreviewSentTrackerParams): void {
    this.push({
      event: "previewSent",
      boxId,
      psId,
      section,
      userId,
      boxNumber,
    });
  }

  public generateNote({ psId, section }: GenerateNoteTrackerParams): void {
    this.push({
      event: "note_assistant",
      psId,
      section,
    });
  }

  public shortlistPageView({
    boxId,
    psId,
    section,
    userId,
    page,
    perPage,
    count,
  }: ShortlistPageViewTrackerParams): void {
    this.push({
      event: "shortlist_page_view",
      boxId,
      psId,
      section,
      userId,
      page,
      perPage,
      count,
    });
  }

  public annotationsPageView({ boxId, psId, section, userId }: ShortlistPageViewTrackerParams): void {
    this.push({
      event: "annotations_page_view",
      boxId,
      psId,
      section,
      userId,
    });
  }

  public markUnmarkFavourite({
    boxId,
    position,
    productId,
    psId,
    section,
    selected,
    userId,
  }: MarkUnmarkFavouriteTrackerParams): void {
    this.push({
      event: "select_favourite",
      boxId,
      position,
      productId,
      psId,
      section,
      selected,
      userId,
    });
  }
}

export { AsyncGtmTracker };
