import { useState, useCallback, useLayoutEffect, useRef, ReactElement, useEffect, FC } from "react";
import classNames from "classnames";
import { useTransition, animated, SpringValue } from "react-spring";
import "./stack.css";

interface StackItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly item: any;
  readonly props: {
    transform: SpringValue<string>;
    opacity: SpringValue<number>;
    height: SpringValue<number>;
  };
  readonly setSize: (key: string, height: number) => void;
}

const StackItem: FC<StackItemProps> = ({ item, props, setSize }: StackItemProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!rootRef.current) {
      return;
    }

    const height = Math.ceil(rootRef.current.getBoundingClientRect().height);
    setSize(item.key, height);
  }, [item.key, setSize]);

  return (
    <animated.div key={item.key} style={props}>
      <div ref={rootRef} className="stack__item">
        {item}
      </div>
    </animated.div>
  );
};

export interface StackElement extends ReactElement {
  readonly key: string;
}

interface StackProps {
  readonly className?: string;
  readonly children: StackElement | StackElement[];
  readonly testID?: string;
}

const Stack: FC<StackProps> = ({ className, children, testID }: StackProps) => {
  const [heights, setHeights] = useState<Record<string, number>>({});
  const setSize = useCallback(
    (key: string, height: number) => setHeights((heights) => ({ ...heights, [key]: height })),
    [],
  );
  const [items, setItems] = useState(
    (children as StackElement[]).map((child) => ({ ...child, height: heights[child.key] || 0 })),
  );

  const transitions = useTransition(items, {
    keys: (item) => `${item.key}-${item.height}`,
    from: { transform: "translate3d(0,0,0)", opacity: 0, height: 0 },
    enter: (item) => ({ transform: "translate3d(0,0,0)", opacity: 1, height: item.height }),
    leave: { transform: "translate3d(0,100%,0)", opacity: 0, height: 0 },
  });

  useEffect(() => {
    setItems((children as StackElement[]).map((child) => ({ ...child, height: heights[child.key] || 0 })));
  }, [children, heights]);

  return (
    <div className={classNames("stack", className)} data-testid={testID}>
      {transitions((props, item) => (
        <StackItem item={item} props={props} setSize={setSize} />
      ))}
    </div>
  );
};

export default Stack;
