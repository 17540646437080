import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { EditableShortlistFilterProjection } from "./editableShortlistFilter";

const LIST_EDITABLE_SHORTLIST_FILTERS = "list_editable_shortlist_filters";

interface ListEditableShortlistFiltersPayload {
  readonly boxId: string;
}

interface ListEditableShortlistFilters
  extends Query<typeof LIST_EDITABLE_SHORTLIST_FILTERS>,
    ListEditableShortlistFiltersPayload {}

interface ListEditableShortlistFiltersFunction {
  (payload: ListEditableShortlistFiltersPayload): ListEditableShortlistFilters;
}

const listEditableShortlistFilters: ListEditableShortlistFiltersFunction = (payload) => ({
  ...query({ name: LIST_EDITABLE_SHORTLIST_FILTERS }),
  ...payload,
});

type ListEditableShortlistFiltersResult = EditableShortlistFilterProjection | null;

interface EditableShortlistFiltersViewArgs extends CancelableQueryViewArgs {
  readonly boxId: string;
}

interface EditableShortlistFiltersView {
  (args: EditableShortlistFiltersViewArgs): Promise<ListEditableShortlistFiltersResult>;
}

interface ListEditableShortlistFiltersHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: EditableShortlistFiltersView;
}

const listEditableShortlistFiltersHandler: QueryHandlerFunction<
  ListEditableShortlistFilters,
  ListEditableShortlistFiltersResult,
  ListEditableShortlistFiltersHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxId }) =>
    view({ boxId, signal });

export type { ListEditableShortlistFilters, EditableShortlistFiltersView, ListEditableShortlistFiltersResult };
export { LIST_EDITABLE_SHORTLIST_FILTERS, listEditableShortlistFilters, listEditableShortlistFiltersHandler };
