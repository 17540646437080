import {
  ListPersonalShopperCountriesResult,
  PersonalShopperCountriesView,
} from "../../../projection/personalShopperCountry/listPersonalShopperCountries";
import { HttpGetFunction } from "../../delivery/http/httpClient";
import { PersonalShopperCountryDto, toPersonalShopperCountryProjection } from "./personalShopperCountry";

interface HttpPersonalShopperCountriesView extends PersonalShopperCountriesView {}

interface HttpPersonalShopperCountriesViewFunctionArgs {
  readonly httpGet: HttpGetFunction;
}

interface HttpPersonalShopperCountriesViewFunction {
  (args: HttpPersonalShopperCountriesViewFunctionArgs): HttpPersonalShopperCountriesView;
}

interface ListPersonalShopperCountriesResponse {
  readonly result: PersonalShopperCountryDto[];
}

const httpPersonalShopperCountriesView: HttpPersonalShopperCountriesViewFunction =
  ({ httpGet }) =>
  async ({ signal }) =>
    await httpGet<ListPersonalShopperCountriesResponse, ListPersonalShopperCountriesResult>({
      endpoint: "/list-ps-countries",
      signal,
      result: {
        error: [],
        success: (response) => response.result.map(toPersonalShopperCountryProjection),
      },
    });

export { httpPersonalShopperCountriesView };
