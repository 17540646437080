const CAPACITY_I18N_PREFIX = "labs.capacity.";

enum CapacityI18n {
  FROM_DATE_LABEL = "from_date.label",
  TO_DATE_LABEL = "to_date.label",
  COUNTRY_LABEL = "country.label",
  SEGMENT_LABEL = "segment.label",
  SEGMENT_WOMEN_LABEL = "segment.women.label",
  SEGMENT_MEN_LABEL = "segment.men.label",
  SYNC_BUTTON = "sync.button",
  TABLE_HEADER_DATE = "table_header_date",
  TABLE_HEADER_CAPACITY = "table_header_capacity",
  TABLE_HEADER_RECURRENT = "table_header_recurrent",
  TABLE_HEADER_NEW = "table_header_new",
  TABLE_HEADER_TOTAL = "table_header_total",
  TABLE_HEADER_PERCENTAGE = "table_header_percentage",
  TABLE_HEADER_DIFFERENCE = "table_header_difference",
  SYNC_NOTIFICATION_SUCCESS = "sync.notification.success",
  SYNC_NOTIFICATION_ERROR = "sync.notification.error",
}

export { CAPACITY_I18N_PREFIX, CapacityI18n };
