const HISTORY_I18N_PREFIX = "labs.history.";

enum HistoryI18n {
  HISTORY_TITLE = "title",
  HISTORY_FILTER_BOXES = "filter.boxes",
  HISTORY_FILTER_SHOP = "filter.shop",
  RETURN_REASON_PRICE = "return_reason.price",
  RETURN_REASON_QUALITY = "return_reason.quality",
  RETURN_REASON_STYLE = "return_reason.style",
  RETURN_REASON_CUT = "return_reason.cut",
  RETURN_REASON_SMALL = "return_reason.small",
  RETURN_REASON_BIG = "return_reason.big",
  POSITIVE_FEEDBACK_STYLE = "positive_feedback.style",
  PRODUCT_VARIANT_SENT_SIZE = "product_variant.sent_size",
  PRODUCT_VARIANT_SIZE_CHANGE = "product_variant.size_change",
  BOX_ASSIGNED_PS = "box.assigned_ps",
  BOX_VIEW_PREVIEW = "box.view_preview",
  BOX_PREVIEW_EXPIRED = "box.preview_expired",
  BOX = "box.title",
  FEEDBACK_ITEM_DIFFERENT_DESCRIPTION = "feedback.item_different_description",
  FEEDBACK_ITEM_DIFFERENT_IMAGE = "feedback.item_different_image",
  FEEDBACK_ITEM_DIFFERENT_ITEM = "feedback.item_different_item",
  FEEDBACK_ITEM_QUALITY_WORSE = "feedback.item_quality_worse",
  FEEDBACK_ITEM_SHAPE_DONT_LIKE = "feedback.item_shape_dont_like",
  FFEDBACK_ITEM_SHAPE_RIGHT = "feedback.item_shape_right",
  FEEDBACK_ITEM_SIZE_BIG = "feedback.item_size_big",
  FEEDBACK_ITEM_SIZE_SMALL = "feedback.item_size_small",
}

export { HISTORY_I18N_PREFIX };

export default HistoryI18n;
