import { isEqual } from "lodash";
import {
  CSSProperties,
  FC,
  forwardRef,
  memo,
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useMeasure } from "react-use";
import classNames from "classnames";
import { VirtualizedListWithSizeMapContext } from "./VirtualizedListWithSizeMap";

export type VirtualizedListRowTagName = "div" | "tr";

type VirtualizedListRowChildrenProps = {
  readonly rowData: [number, unknown | Record<string, never> | null][];
  readonly children: (index: number, data: unknown) => ReactNode;
  readonly style: CSSProperties;
  readonly className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly styleFn?: (data: any) => CSSProperties;
  readonly tagName?: VirtualizedListRowTagName;
  readonly onClick?: (index: number, data: unknown) => void;
};

const areEqual = (
  { children, rowData }: VirtualizedListRowChildrenProps,
  { children: nextChildren, rowData: nextRowData }: VirtualizedListRowChildrenProps,
): boolean => children === nextChildren && isEqual(rowData, nextRowData);

const VirtualizedListRowChildren = memo(
  forwardRef(
    (
      {
        rowData,
        children,
        tagName: Element = "div",
        className,
        styleFn,
        style,
        onClick,
      }: VirtualizedListRowChildrenProps,
      ref: Ref<HTMLTableRowElement>,
    ) => {
      const handleOnClick = useCallback(
        () => rowData.map(([childIndex, data]) => onClick?.(childIndex, data)),
        [onClick, rowData],
      );

      const elementStyle = useMemo(() => (Element === "tr" ? style : undefined), [Element, style]);

      return (
        <Element
          ref={ref}
          aria-label="row"
          className={classNames("virtualized-list__row", className)}
          role="list-row"
          style={{ ...elementStyle, ...styleFn?.(rowData) }}
          onClick={handleOnClick}
        >
          {rowData.map(([childIndex, data]) => children(childIndex, data))}
        </Element>
      );
    },
  ),
  areEqual,
);

type VirtualizedListRowProps = {
  readonly index: number;
  readonly itemCount: number;
  readonly itemsPerRow: number;
  readonly perPage: number;
  readonly children: (index: number, data: unknown) => ReactNode;
  readonly data: Record<string, unknown[]>;
  readonly style: CSSProperties;
  readonly tagName?: VirtualizedListRowTagName;
  readonly className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly styleFn?: (data: any) => CSSProperties;
  readonly onClick?: (index: number, data: unknown) => void;
};
const VirtualizedListRow: FC<VirtualizedListRowProps> = ({
  index,
  itemCount,
  itemsPerRow,
  perPage,
  children,
  data,
  tagName,
  className,
  styleFn,
  style,
  onClick,
}: VirtualizedListRowProps) => {
  const { setItemSize } = useContext(VirtualizedListWithSizeMapContext);

  const [rootRef, { height }] = useMeasure<HTMLTableRowElement>();

  useEffect(() => {
    // Skip initial height setting.
    // It will cause a re-render (and a UI "jump") otherwise
    if (height === 0) {
      return;
    }

    if (tagName === "tr") {
      return;
    }

    setItemSize(index, height);
  }, [height, index, setItemSize, tagName]);

  const rowData: [number, unknown | Record<string, never> | null][] = useMemo(
    () =>
      Array(itemsPerRow)
        .fill(1)
        .map((item, rowItemIndex) => {
          const childIndex = index * itemsPerRow + rowItemIndex;

          if (childIndex >= itemCount) {
            return [childIndex, null];
          }

          const pageForIndex = Math.floor(childIndex / perPage) + 1;
          const itemData = (data && data[pageForIndex] && data[pageForIndex][childIndex % perPage]) || {};

          return [childIndex, itemData];
        }),
    [data, index, itemCount, itemsPerRow, perPage],
  );

  return (
    <VirtualizedListRowChildren
      ref={rootRef}
      className={className}
      rowData={rowData}
      style={style}
      styleFn={styleFn}
      tagName={tagName}
      onClick={onClick}
    >
      {children}
    </VirtualizedListRowChildren>
  );
};

export default VirtualizedListRow;
