import {
  CapacityCalendarCountByCriteriaView,
  CountCapacityCalendarByCriteriaResult,
} from "../../../projection/capacityCalendar/countCapacityCalendarByCriteria";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpCapacityCalendarCountByCriteriaView extends CapacityCalendarCountByCriteriaView {}

interface HttpCapacityCalendarCountByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCapacityCalendarCountByCriteriaViewFunction {
  (args: HttpCapacityCalendarCountByCriteriaViewFunctionArgs): HttpCapacityCalendarCountByCriteriaView;
}

const httpCapacityCalendarCountByCriteriaView: HttpCapacityCalendarCountByCriteriaViewFunction =
  ({ httpPost }) =>
  async ({ from, markets, segments, to, signal }) =>
    await httpPost<CountCapacityCalendarByCriteriaResult>({
      endpoint: "/count-capacity-calendar-by-criteria",
      body: {
        from,
        markets,
        segments,
        to,
      },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpCapacityCalendarCountByCriteriaView };
