import { ReturnQuestionProjection } from "../../../../projection/returnQuestion/returnQuestion";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listReturnQuestions } from "../../../../projection/returnQuestion/listReturnQuestions";

interface UseListReturnQuestionsFunction {
  (): UseQueryFunctionResult<ReturnQuestionProjection[]>;
}

const useListReturnQuestions: UseListReturnQuestionsFunction = () =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listReturnQuestions(),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useListReturnQuestions };
