import { useI18nMessage } from "@lookiero/i18n-react";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import { useReturnQuestionFeedback } from "../../behaviors/useReturnQuestionFeedback";
import { feedbackForReturnQuestion } from "../../util/returnQuestionFeedback";
import { ReturnQuestionItem, ReturnQuestionItemProps } from "../ReturnQuestionItem";
import "./return-question-feedback-item.css";

const ReturnQuestionFeedbackItem: ReturnQuestionItem = ({ returnQuestion }: ReturnQuestionItemProps) => {
  const titleText = useI18nMessage({ id: returnQuestion.name });

  const returnQuestionFeedback = useReturnQuestionFeedback();

  const intl = useIntl();
  const translate = useCallback(
    (returnQuestionName: string) => intl.formatMessage({ id: returnQuestionName, defaultMessage: returnQuestionName }),
    [intl],
  );
  const feedback = useMemo(
    () => feedbackForReturnQuestion({ feedback: returnQuestionFeedback, returnQuestion, translate }).join(" / "),
    [returnQuestion, returnQuestionFeedback, translate],
  );

  return feedback ? (
    <Text className="return-question-feedback-item" tag="p" variant={TextVariant.BODY_SMALL}>
      {titleText}: <strong>{feedback}</strong>
    </Text>
  ) : null;
};

export { ReturnQuestionFeedbackItem };
