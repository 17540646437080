import { uniqBy } from "lodash";
import {
  isHostFilter,
  ShortlistFilterProjection,
  ShortlistFilterType,
} from "../../../../core/projection/shortlistFilter/shortlistFilter";

interface StateFilteringValue {
  readonly id: string;
  readonly value?: string;
}

interface TreeNode<T> {
  readonly children?: T[];
}

interface LeafNodesFunctionArgs<T> {
  readonly nodes: T[];
  readonly result?: T[];
}

interface LeafNodesFunction {
  <T extends TreeNode<T>>(args: LeafNodesFunctionArgs<T>): T[];
}

const leafNodes: LeafNodesFunction = ({ nodes, result = [] }) => {
  for (let i = 0, length = nodes.length; i < length; i++) {
    if (!nodes[i].children || nodes[i].children?.length === 0) {
      result.push(nodes[i]);
    } else {
      result = leafNodes({ nodes: nodes[i].children || [], result });
    }
  }

  return result;
};

interface TypedTreeNode<T> extends TreeNode<T> {
  readonly type: ShortlistFilterType;
}
interface NoHostsLeafNodesFunction {
  <T extends TypedTreeNode<T>>(args: LeafNodesFunctionArgs<T>): T[];
}
const noHostsLeafNodes: NoHostsLeafNodesFunction = ({ nodes, result = [] }) => {
  for (let i = 0, length = nodes.length; i < length; i++) {
    if (!nodes[i].children || nodes[i].children?.length === 0) {
      result.push(nodes[i]);
    } else {
      const noHostNodes = !isHostFilter(nodes[i].type)
        ? nodes[i].children?.filter((filter) => !isHostFilter(filter.type))
        : [];
      result = noHostsLeafNodes({ nodes: noHostNodes || [], result });
    }
  }

  return result;
};

const isAnyLeafActive = ({
  filter,
  state,
}: {
  filter: ShortlistFilterProjection;
  state: StateFilteringValue[];
}): boolean => {
  const filterLeafNodes = leafNodes({ nodes: [filter] });

  return [filter, ...filterLeafNodes].some(({ id: leafId }) => state.find(({ id: stateId }) => leafId === stateId));
};

const isAnyLeafFilteringActive = ({
  filter,
  state,
  filtering,
}: {
  filter: ShortlistFilterProjection;
  state: StateFilteringValue[];
  filtering?: { readonly id: string }[];
}): boolean => {
  const filterLeafNodes = leafNodes({ nodes: [filter] });

  return [filter, ...filterLeafNodes]
    .filter(({ id: leafId }) => state.some(({ id: stateId }) => leafId === stateId))
    .every(({ id: leafId }) => filtering?.some(({ id: stateId }) => leafId === stateId));
};

const areAllLeavesActive = ({
  filter,
  state,
}: {
  filter: ShortlistFilterProjection;
  state: StateFilteringValue[];
}): boolean => {
  const filterLeafNodes = leafNodes({ nodes: [filter] });

  return filterLeafNodes.every(({ id: leafId }) => state.find(({ id: stateId }) => leafId === stateId));
};

interface ActivateFunctionArgs {
  readonly filter: ShortlistFilterProjection;
  readonly state: StateFilteringValue[];
}
interface ActivateFunction {
  (args: ActivateFunctionArgs): StateFilteringValue[];
}
const activate: ActivateFunction = ({ filter, state }) => {
  const filterLeafNodes = filter.children
    ? noHostsLeafNodes({ nodes: [filter] }).map(({ id }) => ({ id }))
    : [{ id: filter.id }];

  return [...state, ...filterLeafNodes];
};

interface DeactivateFunctionArgs {
  readonly filter: ShortlistFilterProjection;
  readonly state: StateFilteringValue[];
}
interface DeactivateFunction {
  (args: DeactivateFunctionArgs): StateFilteringValue[];
}
const deactivate: DeactivateFunction = ({ filter, state }) => {
  const filterLeafNodes = leafNodes({ nodes: [filter] }).map(({ id }) => ({ id }));

  return state.filter(
    ({ id: stateId }) => stateId !== filter.id && !filterLeafNodes.find(({ id: leafId }) => stateId === leafId),
  );
};

interface FindParentNodeFunctionArgs {
  readonly filters: ShortlistFilterProjection[];
  readonly id: string;
}
interface FindParentNodeFunction {
  (args: FindParentNodeFunctionArgs): ShortlistFilterProjection | null;
}
const findParentNode: FindParentNodeFunction = ({ filters, id }) => {
  if (!filters) {
    return null;
  }

  return filters.reduce((acc, filter) => {
    if (acc || !filter.children) {
      return acc;
    }

    if (filter.children?.find(({ id: filterId }) => filterId === id)) {
      return filter;
    }

    return findParentNode({ filters: filter.children, id }) || null;
  }, null as ShortlistFilterProjection | null);
};

interface CountFiltersFunctionArgs {
  readonly state: StateFilteringValue[];
  readonly filters: ShortlistFilterProjection[];
}
interface CountFiltersFunction {
  (args: CountFiltersFunctionArgs): number;
}
const countFilters: CountFiltersFunction = ({ state, filters }) => {
  const parentNodes: ShortlistFilterProjection[] = state
    .map(({ id: stateId }) => findParentNode({ filters, id: stateId }))
    .filter((node) => node) as ShortlistFilterProjection[];

  const { hostRangeFilters, nonHostRangeFilters } = parentNodes.reduce(
    (acc, node) => ({
      hostRangeFilters: [...acc.hostRangeFilters, ...(node.type === ShortlistFilterType.HOST_RANGE ? [node] : [])],
      nonHostRangeFilters: [
        ...acc.nonHostRangeFilters,
        ...(node.type !== ShortlistFilterType.HOST_RANGE ? [node] : []),
      ],
    }),
    {
      hostRangeFilters: [] as ShortlistFilterProjection[],
      nonHostRangeFilters: [] as ShortlistFilterProjection[],
    },
  );

  return uniqBy(hostRangeFilters, "id").length + nonHostRangeFilters.length;
};

export type { StateFilteringValue };
export { activate, deactivate, leafNodes, isAnyLeafActive, areAllLeavesActive, countFilters, isAnyLeafFilteringActive };
