enum ReturnReason {
  PRICE = "price",
  QUALITY = "quality",
  STYLE = "style",
  CUT = "cut",
  SMALL = "small",
  BIG = "big",
}

export default ReturnReason;
