import { QueryStatus } from "@lookiero/messaging-react";
import { SelectionProjection } from "../../../../projection/selection/selection";
import { useEffect, useRef, useState } from "react";
import { MessageName, Query } from "@lookiero/messaging";
import { viewSelectionByBoxNumber } from "../../../../projection/selection/viewSelectionByBoxNumber";
import { useViewSelectionByBoxNumber } from "./useViewSelectionByBoxNumber";
import { listSelectionAlertsByBoxNumber } from "../../../../projection/alert/listSelectionAlertsByBoxNumber";
import { useListSelectionAlertsByBoxNumber } from "../../alert/react/useListSelectionAlertsByBoxNumber";
import { SelectionAlertProjection } from "../../../../projection/alert/selectionAlert";

const POOL_INTERVAL = 2000;

interface PoolQueryIdentifierFunctionArgs {
  readonly query: Query<MessageName>;
  readonly timestamp: number;
}

interface PoolQueryIdentifierFunction {
  (args: PoolQueryIdentifierFunctionArgs): string[];
}

const poolQueryIdentifier: PoolQueryIdentifierFunction = ({ query, timestamp }) => {
  const { messageId, occurredOn, ...queryPayload } = query;

  return [query.name, JSON.stringify(queryPayload), String(timestamp)];
};

interface UsePoolSelectionByBoxNumberFunctionResult {
  readonly selection: SelectionProjection | undefined;
  readonly selectionStatus: QueryStatus;
  readonly selectionAlerts: SelectionAlertProjection[] | undefined;
  readonly selectionAlertsStatus: QueryStatus;
}

interface UsePoolSelectionByBoxNumberFunctionArgs {
  readonly boxNumber: string;
  readonly poolInterval?: number;
}

interface UsePoolSelectionByBoxNumberFunction {
  (args: UsePoolSelectionByBoxNumberFunctionArgs): UsePoolSelectionByBoxNumberFunctionResult;
}

const usePoolSelectionByBoxNumber: UsePoolSelectionByBoxNumberFunction = ({
  boxNumber,
  poolInterval = POOL_INTERVAL,
}) => {
  const interval = useRef<NodeJS.Timeout | null>(null);

  const [selectionPoolTimestamp, setSelectionPoolTimestamp] = useState(Date.now());
  const selectionQueryId = poolQueryIdentifier({
    query: viewSelectionByBoxNumber({ boxNumber }),
    timestamp: selectionPoolTimestamp,
  });
  const [selection, selectionStatus] = useViewSelectionByBoxNumber({ boxNumber, queryId: selectionQueryId });

  const [selectionAlertsPoolTimestamp, setSelectionAlertsPoolTimestamp] = useState(Date.now());
  const selectionAlertsQueryId = poolQueryIdentifier({
    query: listSelectionAlertsByBoxNumber({ boxNumber }),
    timestamp: selectionAlertsPoolTimestamp,
  });
  const [selectionAlerts, selectionAlertsStatus] = useListSelectionAlertsByBoxNumber({
    boxNumber,
    queryId: selectionAlertsQueryId,
  });

  useEffect(() => {
    if (!selection) {
      return;
    }

    if (selection.automaticSelectionStartedOn !== null && selection.automaticSelectionFinishedOn === null) {
      interval.current = setInterval(() => setSelectionPoolTimestamp(Date.now()), poolInterval);
    } else if (
      selection.automaticSelectionStartedOn !== null &&
      selection.automaticSelectionFinishedOn !== null &&
      interval.current
    ) {
      setSelectionAlertsPoolTimestamp(Date.now());
      clearInterval(interval.current);
    }

    return () => {
      if (!interval.current) {
        return;
      }

      clearInterval(interval.current);
    };
  }, [selection, poolInterval]);

  return {
    selection,
    selectionStatus,
    selectionAlerts,
    selectionAlertsStatus,
  };
};

export { usePoolSelectionByBoxNumber };
