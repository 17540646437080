import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { EditableShortlistFilterProjection } from "../../../../projection/editableShortlistFilter/editableShortlistFilter";
import { listEditableShortlistFilters } from "../../../../projection/editableShortlistFilter/listEditableShortlistFilters";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface UseListEditableShortlistFiltersFunctionArgs {
  readonly boxId: string;
}

interface UseListEditableShortlistFiltersFunction {
  (args: UseListEditableShortlistFiltersFunctionArgs): UseQueryFunctionResult<EditableShortlistFilterProjection>;
}

const useListEditableShortlistFilters: UseListEditableShortlistFiltersFunction = ({ boxId }) =>
  useQuery({
    query: listEditableShortlistFilters({ boxId }),
    contextId: MESSAGING_CONTEXT_ID,
    options: { staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useListEditableShortlistFilters };
