import { HistoryNoteProjection } from "../../../projection/historyNote/historyNote";

interface HistoryNoteDto {
  readonly id: string;
  readonly box_number: number;
  readonly box_date_planned: string;
  readonly content: string;
}

const toHistoryNoteProjection = ({
  id,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  box_number,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  box_date_planned,
  content,
}: HistoryNoteDto): HistoryNoteProjection => ({
  id,
  boxNumber: box_number,
  boxPlannedFor: new Date(box_date_planned),
  content,
});

export type { HistoryNoteDto };
export { toHistoryNoteProjection };
