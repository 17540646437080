import { I18nMessage } from "@lookiero/i18n-react";
import { FC, ReactNode, useMemo } from "react";
import { LookProductVariantProjection } from "../../../../core/projection/looks/lookProductVariant";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import LookTemplate from "../lookTemplate/LookTemplate";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../_i18n/BoxPreviewI18n";
import { BoxPreviewProductVariant } from "../boxPreviewProductVariant/BoxPreviewProductVariant";
import { lookTemplateForItems } from "../../../../core/projection/looks/lookTemplate";
import Picture from "../../../../shared/ui/uiKit/atoms/picture/Picture";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import Media, { mainTransparentImageFromMedia } from "../../../../core/projection/media/model/Media";
import cdnImageUrl from "../../../_util/cdnImageUrl";
import BoxPreviewSummary from "../boxPreviewSummary/BoxPreviewSummary";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import BoxPreviewStatus from "../../../../core/domain/boxPreview/model/BoxPreviewStatus";
import Color from "../../../../core/projection/color/model/Color";
import { BoxPreview, ReplacedFor } from "../../../../core/projection/boxPreview/boxPreview";
import "./box-preview-detail.css";

const LOOK_PRODUCT_VARIANT_IMAGE_WIDTH = 100;

const imageSrc = (media: Media[]) =>
  cdnImageUrl({
    url: mainTransparentImageFromMedia(media).url,
    width: LOOK_PRODUCT_VARIANT_IMAGE_WIDTH,
  });

interface LookProductVariantProps {
  readonly lookProductVariant: LookProductVariantProjection;
}

const LookProductVariant: FC<LookProductVariantProps> = ({ lookProductVariant }) => (
  <Picture aspectRatio={AspectRatio.R_4_5} label="look-item-picture" src={imageSrc(lookProductVariant.media)} lazy />
);

interface BoxPreviewDetailSectionProps {
  readonly children: ReactNode;
  readonly title: string;
  readonly headerIcon?: IconVariant;
}
const BoxPreviewDetailSection: FC<BoxPreviewDetailSectionProps> = ({ children, title, headerIcon }) => (
  <div className="box-preview-detail__section">
    <header className="box-preview-detail__section-header">
      {headerIcon && <Icon variant={headerIcon} />}
      <Text variant={TextVariant.BODY_SMALL}>
        <I18nMessage id={title} prefix={BOX_PREVIEW_I18N_PREFIX} />
      </Text>
    </header>
    {children}
  </div>
);

interface BoxPreviewDetailSectionListProductVariant {
  readonly id: string;
  readonly originalItemId: string;
  readonly color: Color;
  readonly media: Media[];
  readonly chosen: boolean | null;
}

interface BoxPreviewDetailSectionListProps {
  readonly title: string;
  readonly items: BoxPreviewDetailSectionListProductVariant[];
  readonly ariaLabel: string;
  readonly notReplacedProductVariants?: Record<string, string>;
  readonly replacedFor?: Record<string, ReplacedFor>;
  readonly onClick?: (productVariantId: string) => void;
}

const BoxPreviewDetailSectionList: FC<BoxPreviewDetailSectionListProps> = ({
  title,
  items,
  ariaLabel,
  notReplacedProductVariants,
  replacedFor,
  onClick,
}) => (
  <BoxPreviewDetailSection title={title}>
    <ul aria-label={ariaLabel} className="box-preview-detail__products-list">
      {items.map((item) => (
        <li key={item.id}>
          <BoxPreviewProductVariant
            couldNotBeenReplaced={Boolean(notReplacedProductVariants?.[item.originalItemId])}
            productVariant={item}
            replacedFor={replacedFor?.[item.id]}
            onClick={onClick}
          />
        </li>
      ))}
    </ul>
  </BoxPreviewDetailSection>
);

interface BoxPreviewDetailProps {
  readonly boxPreview: BoxPreview;
  readonly onClick?: (productVariantId: string) => void;
}

const BoxPreviewDetail: FC<BoxPreviewDetailProps> = ({ boxPreview, onClick }) => {
  const boxPreviewSubmitted = boxPreview.status === BoxPreviewStatus.SUBMITTED;

  const chosenProducts = useMemo(
    () =>
      boxPreview.productVariants
        .filter((productVariant) => boxPreviewSubmitted && productVariant.chosen === true)
        .map((productVariant) =>
          Boolean(boxPreview.replacedFor[productVariant.id])
            ? { ...boxPreview.replacedFor[productVariant.id], chosen: true, originalItemId: productVariant.id }
            : { ...productVariant, originalItemId: productVariant.id },
        ),
    [boxPreview.productVariants, boxPreview.replacedFor, boxPreviewSubmitted],
  );
  const discardedProducts = useMemo(
    () =>
      boxPreview.productVariants
        .filter(
          (productVariant) =>
            boxPreviewSubmitted &&
            (productVariant.chosen === false ||
              (productVariant.chosen === true && Boolean(boxPreview.replacedFor[productVariant.id]))),
        )
        .map((productVariant) => ({ ...productVariant, chosen: false, originalItemId: productVariant.id })),
    [boxPreview.productVariants, boxPreview.replacedFor, boxPreviewSubmitted],
  );

  return (
    <section className="box-preview-detail">
      <BoxPreviewSummary expiration={boxPreview.expiration} status={boxPreview.status} />

      <BoxPreviewDetailSection
        headerIcon={!boxPreview.comment ? IconVariant.ALERT_WARNING : undefined}
        title={BoxPreviewI18n.PREVIEW_DETAIL_COMMENT_TITLE}
      >
        <Text aria-label="box-preview-detail-comment" variant={TextVariant.BODY_SMALL}>
          {boxPreview.comment ? (
            boxPreview.comment
          ) : (
            <I18nMessage id={BoxPreviewI18n.PREVIEW_DETAIL_NO_COMMENT} prefix={BOX_PREVIEW_I18N_PREFIX} />
          )}
        </Text>
      </BoxPreviewDetailSection>

      {!boxPreviewSubmitted && (
        <BoxPreviewDetailSectionList
          ariaLabel="box-preview-detail-sent-products"
          title={BoxPreviewI18n.PREVIEW_DETAIL_SENT_PRODUCTS_TITLE}
          items={boxPreview.productVariants.map((productVariant) => ({
            ...productVariant,
            chosen: true,
            originalItemId: productVariant.id,
          }))}
          onClick={onClick}
        />
      )}

      {chosenProducts.length > 0 && (
        <BoxPreviewDetailSectionList
          ariaLabel="box-preview-detail-chosen-products"
          items={chosenProducts}
          notReplacedProductVariants={boxPreview.notReplacedProductVariants}
          replacedFor={boxPreview.replacedFor}
          title={BoxPreviewI18n.PREVIEW_DETAIL_CHOSEN_PRODUCTS_TITLE}
          onClick={onClick}
        />
      )}

      {discardedProducts.length > 0 && (
        <BoxPreviewDetailSectionList
          ariaLabel="box-preview-detail-discarded-products"
          items={discardedProducts}
          title={BoxPreviewI18n.PREVIEW_DETAIL_DISCARDED_PRODUCTS_TITLE}
          onClick={onClick}
        />
      )}

      {boxPreview.looks && boxPreview.looks.length > 0 && (
        <BoxPreviewDetailSection title={BoxPreviewI18n.PREVIEW_DETAIL_LOOKS}>
          <div className="box-preview-detail__looks-list">
            {boxPreview.looks?.map((look) => {
              const lookProductVariants = look.map(
                (productVariantId: string) =>
                  boxPreview.productVariants?.find(
                    (selectionProductVariant) => selectionProductVariant.id === productVariantId,
                  ) as LookProductVariantProjection,
              );

              return (
                <article key={look.join("")} aria-label="box-preview-look" className="box-preview-detail__look">
                  <LookTemplate
                    lookProductVariants={lookProductVariants}
                    variant={lookTemplateForItems(lookProductVariants.length)}
                  >
                    {(productVariant: LookProductVariantProjection | undefined) =>
                      productVariant && <LookProductVariant lookProductVariant={productVariant} />
                    }
                  </LookTemplate>
                </article>
              );
            })}
          </div>
        </BoxPreviewDetailSection>
      )}
    </section>
  );
};

export { BoxPreviewDetail };
