import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { PendingBoxProjection } from "./pendingBox";

const VIEW_PENDING_BOXES = "view_pending_boxes";

interface ViewPendingBoxes extends Query<typeof VIEW_PENDING_BOXES> {}

interface ViewPendingBoxesFunction {
  (): ViewPendingBoxes;
}

const viewPendingBoxes: ViewPendingBoxesFunction = () => query({ name: VIEW_PENDING_BOXES });

type ViewPendingBoxesResult = PendingBoxProjection[] | null;

interface PendingBoxesViewArgs extends CancelableQueryViewArgs {}

interface PendingBoxesView {
  (args: PendingBoxesViewArgs): Promise<ViewPendingBoxesResult>;
}

interface ViewPendingBoxesHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PendingBoxesView;
}

const viewPendingBoxesHandler: QueryHandlerFunction<
  ViewPendingBoxes,
  ViewPendingBoxesResult,
  ViewPendingBoxesHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async () =>
    view({ signal });

export type { ViewPendingBoxes, PendingBoxesView, ViewPendingBoxesResult };
export { VIEW_PENDING_BOXES, viewPendingBoxes, viewPendingBoxesHandler };
