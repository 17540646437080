import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { SetPersonalShopperFiltering } from "../command/setPersonalShopperFiltering";
import { personalShopperFilteringSet } from "./personalShopperFilteringSet";

interface FilteringValue {
  readonly id: string;
  readonly value?: string;
}

interface PersonalShopperFiltering extends AggregateRoot {
  readonly boxId: string;
  readonly customerId: string;
  readonly psId: string;
  readonly positives: FilteringValue[];
  readonly negatives: FilteringValue[];
}

const setPersonalShopperFilteringHandler: CommandHandlerFunction<
  SetPersonalShopperFiltering,
  PersonalShopperFiltering
> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, psId, customerId, boxId, positives, negatives } = command;

    return {
      ...aggregateRoot,
      boxId,
      psId,
      customerId,
      positives,
      negatives,
      domainEvents: [personalShopperFilteringSet({ aggregateId })],
    };
  };

export type { FilteringValue, PersonalShopperFiltering };
export { setPersonalShopperFilteringHandler };
