import { NotificationType } from "../../../domain/notification/model/notification";
import {
  ModalNotificationProjection,
  ToastNotificationProjection,
} from "../../../projection/notification/notification";
import { NotificationDto } from "../../persistence/notificationDto";

interface ToNotificationProjectionFunction {
  (notificationDto: NotificationDto): ToastNotificationProjection | ModalNotificationProjection;
}

const toNotificationProjection: ToNotificationProjectionFunction = ({
  aggregateId,
  level,
  type,
  acceptI18nKey,
  bodyI18nKey,
  titleI18nKey,
  timestamp,
}) =>
  type === NotificationType.TOAST
    ? {
        id: aggregateId,
        type,
        level,
        bodyI18nKey,
        timestamp,
      }
    : {
        id: aggregateId,
        type,
        titleI18nKey: titleI18nKey as string,
        bodyI18nKey,
        acceptI18nKey: acceptI18nKey as string,
        timestamp,
      };

export { toNotificationProjection };
