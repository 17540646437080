import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ShortlistProductProjection } from "./shortlistProduct";
import { ShortlistProductCountByShortlistFilters } from "./countShortlistProductsByShortlistFilters";

interface ShortlistProductSearchByShortlistFilters extends ShortlistProductCountByShortlistFilters {
  readonly page: number;
  readonly perPage: number;
}

const SEARCH_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS = "search_shortlist_products_by_shortlist_filters";

interface SearchShortlistProductsByShortlistFiltersPayload {
  readonly criteria: ShortlistProductSearchByShortlistFilters;
}

interface SearchShortlistProductsByShortlistFilters
  extends Query<typeof SEARCH_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS>,
    SearchShortlistProductsByShortlistFiltersPayload {}

interface SearchShortlistProductsByShortlistFiltersFunction {
  (payload: SearchShortlistProductsByShortlistFiltersPayload): SearchShortlistProductsByShortlistFilters;
}

const searchShortlistProductsByShortlistFilters: SearchShortlistProductsByShortlistFiltersFunction = (payload) => ({
  ...query({ name: SEARCH_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS }),
  ...payload,
});

type SearchShortlistProductsByShortlistFiltersResult = ShortlistProductProjection[];

interface ShortlistProductsSearchByShortlistFiltersViewArgs extends CancelableQueryViewArgs {
  readonly criteria: ShortlistProductSearchByShortlistFilters;
}

interface ShortlistProductsSearchByShortlistFiltersView {
  (args: ShortlistProductsSearchByShortlistFiltersViewArgs): Promise<SearchShortlistProductsByShortlistFiltersResult>;
}

interface SearchShortlistProductsByShortlistFiltersHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ShortlistProductsSearchByShortlistFiltersView;
}

const searchShortlistProductsByShortlistFiltersHandler: QueryHandlerFunction<
  SearchShortlistProductsByShortlistFilters,
  SearchShortlistProductsByShortlistFiltersResult,
  SearchShortlistProductsByShortlistFiltersHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ criteria }) =>
    view({ criteria, signal });

export type {
  SearchShortlistProductsByShortlistFilters,
  ShortlistProductsSearchByShortlistFiltersView,
  SearchShortlistProductsByShortlistFiltersResult,
};
export {
  SEARCH_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS,
  searchShortlistProductsByShortlistFilters,
  searchShortlistProductsByShortlistFiltersHandler,
};
