const BOX_I18N_PREFIX = "labs.box.";

enum BoxI18n {
  BOX_NUMBER = "box_number",
  PREPARATION_DATE = "preparation_date",
  SUBCRIPTION = "subscription.title",
  BOXES_COUNT = "boxes_count",
  SUBCRIPTION_ON_DEMAND = "subscription.on_demand",
  SUBCRIPTION_MONTHLY = "subscription.monthly",
  SUBCRIPTION_BIMONTHLY = "subscription.bimonthly",
  SUBCRIPTION_QUATERLY = "subscription.quaterly",
  TAG_FRAUD = "tag_fraud",
  TAG_INFLUENCER = "tag_influencer",
  TAG_VIP = "tag_vip",
  TAG_FACEBOOK = "tag_facebook",
  TAG_PS_CHANGE = "tag_ps_change",
}

export { BOX_I18N_PREFIX, BoxI18n };
