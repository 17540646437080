import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { WriteNote } from "../command/writeNote";
import { noteWritten } from "./noteWritten";
import { GenerateNote } from "../command/generateNote";
import { noteGenerated } from "./noteGenerated";
import { canPersonalShopperWriteNote } from "../../../projection/personalShopper/canPersonalShopperWriteNote";

interface Note extends AggregateRoot {
  readonly boxId: string;
  readonly content: string;
  readonly psId: string;
}

const writeNoteHandler: CommandHandlerFunction<WriteNote, Note> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { boxId, content, legacyBoxId, psId } = command;

    const canWriteNote = await queryBus(canPersonalShopperWriteNote({ boxId }));

    if (!canWriteNote) {
      throw new Error("The note cannot be written");
    }

    return {
      ...aggregateRoot,
      boxId,
      content,
      legacyBoxId,
      psId,
      domainEvents: [noteWritten({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

const generateNoteHandler: CommandHandlerFunction<GenerateNote, Note> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { boxId, psId } = command;

    return {
      ...aggregateRoot,
      boxId,
      psId,
      domainEvents: [noteGenerated({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

export type { Note };
export { writeNoteHandler, generateNoteHandler };
