import { I18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import { SHORTLIST_I18N_PREFIX } from "../../_i18n/ShortlistI18n";
import "./product-notification.css";

type ProductNotificationProps = {
  readonly i18nMessageId: string;
  readonly i18nValues?: Record<string, string | ((chunks: JSX.Element) => JSX.Element)>;
};
const ProductNotification: FC<ProductNotificationProps> = ({ i18nMessageId, i18nValues }: ProductNotificationProps) => (
  <section className="product-notification">
    <div className="product-notification__item">
      <Icon variant={IconVariant.INFO} />

      <Text variant={TextVariant.BODY_SMALL}>
        <I18nMessage id={i18nMessageId} prefix={SHORTLIST_I18N_PREFIX} values={i18nValues} />
      </Text>
    </div>
  </section>
);

export default ProductNotification;
