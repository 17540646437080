import { FC } from "react";
import { Link as RouterLink, NavLink, LinkProps as RouterLinkProps, NavLinkProps } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface LinkProps extends RouterLinkProps, NavLinkProps {
  readonly isNav?: boolean;
}

const Link: FC<LinkProps> = ({ isNav = false, ...props }: LinkProps) =>
  isNav ? <NavLink {...props} /> : <RouterLink {...props} />;

export default Link;
