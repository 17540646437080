import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import {
  NoteTemplatesGetFunction,
  NoteTemplatesSaveFunction,
} from "../../../../domain/noteTemplate/model/noteTemplates";
import { httpNoteTemplateWritten } from "./httpNoteTemplateWritten";
import { httpNoteTemplateRemoved } from "./httpNoteTemplateRemoved";
import { NoteTemplate } from "../../../../domain/noteTemplate/model/noteTemplate";
import invariant from "ts-invariant";
import {
  ViewNoteTemplateById,
  ViewNoteTemplateByIdResult,
  viewNoteTemplateById,
} from "../../../../projection/noteTemplate/viewNoteTemplateById";
import { NoteTemplateProjection } from "../../../../projection/noteTemplate/noteTemplate";

interface ToDomainFunctionArgs {
  readonly noteTemplate: NoteTemplateProjection | null;
}

interface ToDomainFunction {
  (args: ToDomainFunctionArgs): NoteTemplate;
}

const toDomain: ToDomainFunction = ({ noteTemplate }) => {
  invariant(noteTemplate, "NoteTemplate does not exist");

  return {
    aggregateId: noteTemplate.id,
    psId: noteTemplate.psId,
    title: noteTemplate.title,
    content: noteTemplate.content,
    category: noteTemplate.category,
    domainEvents: [],
  };
};

interface HttpNoteTemplatesGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpNoteTemplatesGetFunction extends NoteTemplatesGetFunction<HttpNoteTemplatesGetFunctionArgs> {}

const getNoteTemplate: HttpNoteTemplatesGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain({
      noteTemplate: await queryBus<ViewNoteTemplateById, ViewNoteTemplateByIdResult>(
        viewNoteTemplateById({ id: aggregateId }),
      ),
    });

interface HttpNoteTemplatesSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpNoteTemplatesSaveFunction extends NoteTemplatesSaveFunction<HttpNoteTemplatesSaveFunctionArgs> {}

const saveNoteTemplate: HttpNoteTemplatesSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([
      await httpNoteTemplateWritten({ httpPost })(aggregateRoot),
      await httpNoteTemplateRemoved({ httpPost })(aggregateRoot),
    ]);
  };

export type { HttpNoteTemplatesSaveFunction };
export { getNoteTemplate, saveNoteTemplate };
