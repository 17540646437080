interface ValidationError {
  readonly id: string;
  readonly message: string;
}

enum LookValidationErrorId {
  NOT_VALID_FOR_TEMPLATE = "NOT_VALID_FOR_TEMPLATE",
  DUPLICATED_PRODUCT_VARIANTS = "DUPLICATED_PRODUCT_VARIANTS",
  EMPTY_PRODUCT_VARIANT = "EMPTY_PRODUCT_VARIANT",
}

interface LookValidation {
  readonly errors: ValidationError[];
}

const ValidationErrors: Record<string, ValidationError> = {
  NOT_VALID_FOR_TEMPLATE: { id: LookValidationErrorId.NOT_VALID_FOR_TEMPLATE, message: "" },
  DUPLICATED_PRODUCT_VARIANTS: { id: LookValidationErrorId.DUPLICATED_PRODUCT_VARIANTS, message: "" },
  EMPTY_PRODUCT_VARIANT: { id: LookValidationErrorId.EMPTY_PRODUCT_VARIANT, message: "" },
};

export type { LookValidation as LookValidationProjection, ValidationError };
export { LookValidationErrorId, ValidationErrors };
