import { FC } from "react";
import "./image-question-item.css";

interface ImageQuestionItemProps {
  readonly src: string;
  readonly alt: string;
  readonly onClick?: () => void;
}
const ImageQuestionItem: FC<ImageQuestionItemProps> = ({ src, alt, onClick }) => (
  <img
    alt={alt}
    aria-label="image-question-item"
    className="image-question-item"
    src={src}
    style={{ cursor: onClick ? "pointer" : "auto" }}
    onClick={onClick}
  />
);

export { ImageQuestionItem };
