import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { CreateModalNotification } from "../command/createModalNotification";
import { CreateToastNotification } from "../command/createToastNotification";
import { RemoveNotification } from "../command/removeNotification";
import { notificationCreated } from "./notificationCreated";
import { notificationRemoved } from "./notificationRemoved";

enum NotificationType {
  TOAST = "TOAST",
  MODAL = "MODAL",
}

enum NotificationLevel {
  INFO = "INFO",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

interface Notification extends AggregateRoot {
  readonly type: NotificationType;
  readonly level: NotificationLevel;
  readonly bodyI18nKey: string;
  readonly titleI18nKey: string | undefined;
  readonly acceptI18nKey: string | undefined;
  readonly timestamp: number;
}

const createToastNotificationHandler: CommandHandlerFunction<CreateToastNotification, Notification> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, type, level, bodyI18nKey } = command;

    return {
      ...aggregateRoot,
      type,
      level,
      bodyI18nKey,
      timestamp: Date.now(),
      domainEvents: [notificationCreated({ aggregateId })],
    };
  };

const createModalNotificationHandler: CommandHandlerFunction<CreateModalNotification, Notification> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, type, titleI18nKey, bodyI18nKey, acceptI18nKey } = command;

    return {
      ...aggregateRoot,
      type,
      titleI18nKey,
      bodyI18nKey,
      acceptI18nKey,
      timestamp: Date.now(),
      domainEvents: [notificationCreated({ aggregateId })],
    };
  };

const removeNotificationHandler: CommandHandlerFunction<RemoveNotification, Notification> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;

    return {
      ...aggregateRoot,
      domainEvents: [notificationRemoved({ aggregateId })],
    };
  };

export type { Notification };
export {
  NotificationLevel,
  NotificationType,
  createToastNotificationHandler,
  createModalNotificationHandler,
  removeNotificationHandler,
};
