import { useCallback } from "react";
import { useTracker } from "./useTracker";
import { Section } from "../Tracker";

interface TrackShortlistPageViewFunctionArgs {
  readonly page: number;
  readonly count: number;
}

interface TrackShortlistPageViewFunction {
  (args: TrackShortlistPageViewFunctionArgs): void;
}

interface UseTrackShortlistPageViewFunctionArgs {
  readonly boxId: string;
  readonly psId?: string;
  readonly section: Section;
  readonly userId: string;
  readonly perPage: number;
}

interface UseTrackShortlistPageViewFunction {
  (args: UseTrackShortlistPageViewFunctionArgs): TrackShortlistPageViewFunction;
}

const useTrackShortlistPageView: UseTrackShortlistPageViewFunction = ({ section, boxId, psId, userId, perPage }) => {
  const tracker = useTracker();

  const trackShortlistPageView: TrackShortlistPageViewFunction = useCallback(
    ({ page, count }) => {
      if (!(tracker && psId)) {
        return;
      }

      tracker.shortlistPageView({
        section,
        boxId,
        psId,
        userId,
        page,
        perPage,
        count,
      });
    },
    [boxId, perPage, psId, section, tracker, userId],
  );

  return trackShortlistPageView;
};

export { useTrackShortlistPageView };
