import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { HistoryProductVariantFeedbackProjection } from "../../../../core/projection/historyBox/historyBox";

interface OnChangeReturnQuestionFeedbackFunctionArgs {
  readonly returnQuestionId: string;
  readonly returnQuestionFeedback: string | undefined; // uuid | free-text
}

interface OnChangeReturnQuestionFeedbackFunction {
  (args: OnChangeReturnQuestionFeedbackFunctionArgs): void;
}

interface ReturnQuestionFeedbackContextShape {
  readonly feedback: HistoryProductVariantFeedbackProjection;
  readonly onChange: OnChangeReturnQuestionFeedbackFunction;
}

const ReturnQuestionFeedbackContext = createContext<ReturnQuestionFeedbackContextShape>(
  null as unknown as ReturnQuestionFeedbackContextShape,
);

interface ReturnQuestionFeedbackContextProviderProps {
  readonly feedback: HistoryProductVariantFeedbackProjection | undefined;
  readonly children: ReactNode;
}

const ReturnQuestionFeedbackProvider: FC<ReturnQuestionFeedbackContextProviderProps> = ({
  feedback = {},
  children,
}) => {
  const [contextFeedback, setContextFeedback] = useState<HistoryProductVariantFeedbackProjection>(feedback);
  const onChange = useCallback<OnChangeReturnQuestionFeedbackFunction>(
    ({ returnQuestionId, returnQuestionFeedback }) =>
      setContextFeedback((feedback) =>
        returnQuestionFeedback
          ? { ...feedback, [returnQuestionId]: returnQuestionFeedback }
          : Object.entries(feedback).reduce(
              (acc, [id, feedback]) => (id !== returnQuestionId ? { ...acc, [id]: feedback } : acc),
              {},
            ),
      ),
    [],
  );

  useEffect(() => setContextFeedback(feedback), [feedback]);

  const value = useMemo(
    () => ({
      feedback: contextFeedback,
      onChange,
    }),
    [contextFeedback, onChange],
  );

  return <ReturnQuestionFeedbackContext.Provider value={value}>{children}</ReturnQuestionFeedbackContext.Provider>;
};

interface UseReturnQuestionFeedbackForIdFunctionArgs {
  readonly id: string;
}

interface UseReturnQuestionFeedbackForIdFunction {
  (args: UseReturnQuestionFeedbackForIdFunctionArgs): {
    readonly feedback: string | undefined;
    readonly onChange: OnChangeReturnQuestionFeedbackFunction;
  };
}

const useReturnQuestionFeedbackForId: UseReturnQuestionFeedbackForIdFunction = ({ id }) => {
  const returnQuestionFeedbackContext = useContext<ReturnQuestionFeedbackContextShape>(ReturnQuestionFeedbackContext);

  invariant(
    returnQuestionFeedbackContext,
    "Your are trying to use the useReturnQuestionFeedbackForId hook without wrapping your app with the <ReturnQuestionFeedbackProvider>.",
  );

  const { feedback, onChange } = returnQuestionFeedbackContext;

  return { feedback: feedback[id], onChange };
};

interface UseReturnQuestionFeedbackFunction {
  (): HistoryProductVariantFeedbackProjection;
}

const useReturnQuestionFeedback: UseReturnQuestionFeedbackFunction = () => {
  const returnQuestionFeedbackContext = useContext<ReturnQuestionFeedbackContextShape>(ReturnQuestionFeedbackContext);

  invariant(
    returnQuestionFeedbackContext,
    "Your are trying to use the useReturnQuestionFeedback hook without wrapping your app with the <ReturnQuestionFeedbackProvider>.",
  );

  const { feedback } = returnQuestionFeedbackContext;

  return feedback;
};

export { useReturnQuestionFeedbackForId, useReturnQuestionFeedback, ReturnQuestionFeedbackProvider };
