import { I18nMessage } from "@lookiero/i18n-react";
import classNames from "classnames";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MAX_LOOKS } from "../../../../core/domain/boxPreview/model/LookList";
import { LookProjection } from "../../../../core/projection/looks/look";
import { ButtonVariant } from "../../../../shared/ui/uiKit/atoms/button/Button";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import ActionButton from "../../../../shared/ui/uiKit/molecules/actionButton/ActionButton";
import AsyncActionState from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../_i18n/BoxPreviewI18n";
import SelectionI18n from "../../_i18n/SelectionI18n";
import AlertsPopup from "../alertsPopup/AlertsPopup";
import BoxPreviewSenderConfirmationModal from "../boxPreviewSenderConfirmationModal/BoxPreviewSenderConfirmationModal";
import { SelectionAlertProjection } from "../../../../core/projection/alert/selectionAlert";
import { AlertContext } from "../../../../core/projection/alert/alert";
import "./box-preview-sender.css";

const maxLooksAlert: SelectionAlertProjection = {
  context: [AlertContext.SELECTION],
  key: SelectionI18n.MAX_LOOKS_EXCEEDED,
  message: {
    placeholder: SelectionI18n.MAX_LOOKS_EXCEEDED,
    params: { maxLooks: `${MAX_LOOKS}` },
  },
  isBlocker: true,
};

const mandatoryLooksAlert: SelectionAlertProjection = {
  context: [AlertContext.SELECTION],
  key: SelectionI18n.MANDATORY_LOOKS,
  message: {
    placeholder: SelectionI18n.MANDATORY_LOOKS,
  },
  isBlocker: true,
};

interface OnClickFunctionArgs {
  readonly alerts: SelectionAlertProjection[];
}
interface OnClickFunction {
  (args: OnClickFunctionArgs): void;
}

type BoxPreviewSenderProps = {
  readonly sendBoxPreviewState: AsyncActionState;
  readonly previewAlerts?: SelectionAlertProjection[];
  readonly looks: LookProjection[];
  readonly enabled: boolean;
  readonly onSendBoxPreview: () => void;
  readonly onClick?: OnClickFunction;
};
const BoxPreviewSender: FC<BoxPreviewSenderProps> = ({
  sendBoxPreviewState,
  previewAlerts,
  looks,
  enabled,
  onSendBoxPreview,
  onClick,
}: BoxPreviewSenderProps) => {
  const previewBlockingAlerts = useMemo(() => previewAlerts?.filter((alert) => alert.isBlocker) || [], [previewAlerts]);
  const blockingAlerts = useMemo(
    () => [
      ...previewBlockingAlerts,
      ...(looks.length > MAX_LOOKS ? [maxLooksAlert] : []),
      ...(looks.length === 0 ? [mandatoryLooksAlert] : []),
    ],
    [looks, previewBlockingAlerts],
  );
  const containsBlockingAlerts = blockingAlerts.length > 0;

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handleOnToggle = useCallback(() => setIsPopupVisible((isPopupVisible) => !isPopupVisible), []);
  const handleOnClose = useCallback(() => setIsPopupVisible(false), []);

  const handleOnSendBoxPreviewClick = useCallback(() => setConfirmationModalVisible(true), []);

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const handleOnCancelClick = useCallback(() => setConfirmationModalVisible(false), []);
  const handleOnSendBoxPreviewConfirmed = useCallback(() => {
    setConfirmationModalVisible(false);
    onSendBoxPreview();
  }, [onSendBoxPreview]);

  const handleOnClick = useCallback(() => {
    if (containsBlockingAlerts) {
      handleOnToggle();
    } else {
      handleOnSendBoxPreviewClick();
    }

    onClick?.({ alerts: blockingAlerts });
  }, [blockingAlerts, containsBlockingAlerts, handleOnSendBoxPreviewClick, handleOnToggle, onClick]);

  useEffect(() => setConfirmationModalVisible(false), [containsBlockingAlerts]);

  const actionButton = useMemo(
    () => (
      <ActionButton
        aria-label="box-preview-sender-submit"
        disabled={!enabled}
        icon={IconVariant.EYE}
        state={sendBoxPreviewState}
        variant={ButtonVariant.BASIC}
        onClick={enabled ? handleOnClick : undefined}
      >
        <I18nMessage id={BoxPreviewI18n.SEND_PREVIEW} prefix={BOX_PREVIEW_I18N_PREFIX} />
      </ActionButton>
    ),
    [enabled, handleOnClick, sendBoxPreviewState],
  );

  return (
    <div aria-label="box-preview-sender" className="box-preview-sender">
      <BoxPreviewSenderConfirmationModal
        visible={confirmationModalVisible}
        onCancel={handleOnCancelClick}
        onConfirm={handleOnSendBoxPreviewConfirmed}
      />

      {!enabled ? (
        <Tooltip title={<I18nMessage id={BoxPreviewI18n.PREVIEW_NOT_REQUIRED} prefix={BOX_PREVIEW_I18N_PREFIX} />}>
          <div className="box-preview-sender__not-required">{actionButton}</div>
        </Tooltip>
      ) : (
        actionButton
      )}

      {blockingAlerts.length > 0 && (
        <span
          className={classNames("box-preview-sender__alerts-indicator", {
            "box-preview-sender__alerts-indicator--blocker": containsBlockingAlerts,
          })}
        />
      )}

      <div className="box-preview-sender__alerts-popup">
        <AlertsPopup
          alerts={blockingAlerts}
          label="box-preview-sender-alerts-popup"
          visible={isPopupVisible}
          onClose={handleOnClose}
        />
      </div>
    </div>
  );
};

export default BoxPreviewSender;
