import { FC, useCallback, useMemo } from "react";
import ShortlistFilterItemRoot from "../../../componentLibrary/organisms/shortlistFilters/shortlistFilterItem/components/shortlistFilterItemRoot/ShortlistFilterItemRoot";
import ShortlistFiltersI18n, {
  SHORTLIST_FILTERS_I18N_PREFIX,
} from "../../../componentLibrary/_i18n/ShortlistFiltersI18n";
import ShortlistFilterHeader from "../../../componentLibrary/organisms/shortlistFilters/shortlistFilterItem/components/shortlistFilterHeader/ShortlistFilterHeader";
import ButtonOption, { ButtonOptionState } from "../../../../shared/ui/uiKit/atoms/buttonOption/ButtonOption";
import { useShortlistFilteringPositives } from "../../../componentLibrary/organisms/shortlistFilters/shortlistFilterItem/_behavior/useShortlistFilteringPositives";
import { FilteringProjection } from "../../../../core/projection/filtering/filtering";
import { I18nMessage, useI18nMessage, useIntl } from "@lookiero/i18n-react";
import { ButtonVariant } from "../../../../shared/ui/uiKit/atoms/button/Button";
import { useCanSaveFilterings } from "./behaviors/useCanSaveFilterings";
import { useSetPersonalShopperFiltering } from "../../../../core/infrastructure/domain/personalShopperFiltering/react/useSetPersonalShopperFiltering";
import useBoxSharedContext from "../../_behaviors/useBoxSharedContext";
import ActionButton from "../../../../shared/ui/uiKit/molecules/actionButton/ActionButton";
import { asyncActionStateForCommandStatus } from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import { useListEditableShortlistFilters } from "../../../../core/infrastructure/projection/editableShortlistFilter/react/useListEditableShortlistFilters";
import { useShortlistFilteringNegatives } from "../../../componentLibrary/organisms/shortlistFilters/shortlistFilterItem/_behavior/useShortlistFilteringNegatives";
import { StateFilteringValue } from "../../../componentLibrary/organisms/shortlistFilters/ShortlistFilterState";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import { useTrackFilteringModified } from "../../../../shared/tracking/infrastructure/useTrackFilteringModified";
import { TrackingFilteringChangeType, TrackingPage } from "../../../../shared/tracking/Tracker";
import { hasFilteringChanged } from "./behaviors/hasFilteringChanged";
import { useTrackActivatePredefinedFilter } from "../../../../shared/tracking/infrastructure/useTrackActivatePredefinedFilter";
import { useViewPersonalShopper } from "../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useCreateToastNotification } from "../../../../shared/notifications/infrastructure/domain/notification/react/useCreateToastNotification";
import { MESSAGING_CONTEXT_ID } from "../../../../core/container/bootstrap";
import { useLogger } from "../../../../shared/logging/useLogger";
import { NotificationLevel } from "../../../../shared/notifications/domain/notification/model/notification";
import "./shortlist-filters-filtering.css";
import { ShortlistFilterProjection } from "../../../../core/projection/shortlistFilter/shortlistFilter";

interface LastModifiedOnFunctionArgs {
  readonly filtering: FilteringProjection;
}
interface LastModifiedOnFunction {
  (args: LastModifiedOnFunctionArgs): Date | null;
}

const lastModifiedOn: LastModifiedOnFunction = ({ filtering }) =>
  [...filtering.positives, ...filtering.negatives].length === 0
    ? null
    : [...filtering.positives, ...filtering.negatives].reduce(
        (lastModifiedOn, filteringValue) =>
          filteringValue.modifiedOn > lastModifiedOn ? filteringValue.modifiedOn : lastModifiedOn,
        [...filtering.positives, ...filtering.negatives][0].modifiedOn,
      );

interface AreAllFilteringsActiveFunctionArgs {
  readonly filtering: FilteringProjection;
  readonly positives: StateFilteringValue[];
  readonly negatives: StateFilteringValue[];
}
interface AreAllFilteringsActiveFunction {
  (args: AreAllFilteringsActiveFunctionArgs): boolean;
}
const areAllFiteringsActive: AreAllFilteringsActiveFunction = ({ filtering, positives, negatives }) =>
  positives.length === filtering.positives.length &&
  negatives.length === filtering.negatives.length &&
  positives.every(({ id: stateId }) => filtering.positives.find(({ id: filteringId }) => stateId === filteringId)) &&
  negatives.every(({ id: stateId }) => filtering.negatives.find(({ id: filteringId }) => stateId === filteringId));

interface ShortlistFiltersFilteringProps {
  readonly filtering: FilteringProjection;
}

const ShortlistFiltersFiltering: FC<ShortlistFiltersFilteringProps> = ({ filtering }) => {
  const logger = useLogger();
  const { state: positives, onChange: positiveOnChange } = useShortlistFilteringPositives();
  const { state: negatives, onChange: negativeOnChange } = useShortlistFilteringNegatives();
  const [personalShopper] = useViewPersonalShopper();
  const { box } = useBoxSharedContext();
  const [editableFilters] = useListEditableShortlistFilters({ boxId: box.id });
  const canSaveFiltering = useCanSaveFilterings({ positives, negatives, filtering, editableFilters });
  const [setPersonalShopperFiltering, setPersonalShopperFilteringStatus] = useSetPersonalShopperFiltering({
    boxId: box.id,
    psId: personalShopper?.id as string,
    customerId: box.customerId,
  });
  const trackFilteringModified = useTrackFilteringModified({
    section: TrackingPage.CATALOG,
    boxId: box.id,
    psId: personalShopper?.id,
    userId: box.customerId,
  });

  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });
  const errorMessage = useI18nMessage({
    id: ShortlistFiltersI18n.FILTERING_SAVE_ERROR,
    prefix: SHORTLIST_FILTERS_I18N_PREFIX,
  });
  const filteringModifiedChangeType = useMemo(() => {
    const positivesChanged = hasFilteringChanged({ filtering: positives, previousFiltering: filtering.positives });
    const negativesChanged = hasFilteringChanged({ filtering: negatives, previousFiltering: filtering.negatives });

    return positivesChanged && negativesChanged
      ? TrackingFilteringChangeType.BOTH
      : positivesChanged
      ? TrackingFilteringChangeType.INCLUDE
      : negativesChanged
      ? TrackingFilteringChangeType.EXCLUDE
      : undefined;
  }, [filtering.negatives, filtering.positives, negatives, positives]);

  const trackActivatePredefinedFilter = useTrackActivatePredefinedFilter({
    section: TrackingPage.CATALOG,
    boxId: box.id,
    psId: personalShopper?.id,
    userId: box.customerId,
  });

  const handleOnSave = useCallback(async () => {
    try {
      await setPersonalShopperFiltering({
        positives,
        negatives,
        personalShopperFilteringId: filtering.personalShopperFilteringId,
      });

      if (filteringModifiedChangeType) {
        trackFilteringModified({ changeType: filteringModifiedChangeType });
      }
    } catch (e) {
      createNotification({
        aggregateId: "set-personal-shopper-filtering-error",
        level: NotificationLevel.ERROR,
        bodyI18nKey: errorMessage,
      });
    }
  }, [
    createNotification,
    errorMessage,
    filtering.personalShopperFilteringId,
    filteringModifiedChangeType,
    negatives,
    positives,
    setPersonalShopperFiltering,
    trackFilteringModified,
  ]);

  const handleOnChange = useCallback(
    (checked: boolean) => {
      trackActivatePredefinedFilter({
        enabled: checked,
      });

      positiveOnChange({ state: checked ? filtering.positives.map(({ id }) => ({ id })) : [] });
      negativeOnChange({ state: checked ? filtering.negatives.map(({ id }) => ({ id })) : [] });
    },
    [filtering.negatives, filtering.positives, negativeOnChange, positiveOnChange, trackActivatePredefinedFilter],
  );
  const optionState = useMemo(
    () =>
      areAllFiteringsActive({ filtering, positives, negatives })
        ? ButtonOptionState.SELECTED
        : ButtonOptionState.DEFAULT,
    [filtering, negatives, positives],
  );

  const { locale } = useIntl();
  const modifiedOn = useMemo(() => lastModifiedOn({ filtering }), [filtering]);

  const filter = useMemo(() => ({} as ShortlistFilterProjection), []);
  const headerFilter: ShortlistFilterProjection = useMemo(
    () =>
      ({
        translationKey: `${SHORTLIST_FILTERS_I18N_PREFIX}${ShortlistFiltersI18n.FILTERING}`,
      } as ShortlistFilterProjection),
    [],
  );

  const ShortlistFilterItemRootComponent = useMemo(
    () =>
      ShortlistFilterItemRoot({
        useShortlistFiltering: () => ({ state: [], onChange: () => void 0 }),
        testId: "shortlist-filters-filtering",
      }),
    [],
  );

  return (
    <ShortlistFilterItemRootComponent
      className="shortlist-filters-filtering"
      filter={filter}
      level={0}
      item={
        <ShortlistFilterHeader filter={headerFilter} level={0}>
          <ActionButton
            aria-label={ShortlistFiltersI18n.FILTERING_SAVE_BUTTON}
            className="shortlist-filters-filtering__save-button"
            disabled={!canSaveFiltering}
            state={asyncActionStateForCommandStatus[setPersonalShopperFilteringStatus]}
            variant={ButtonVariant.PLAIN}
            onClick={handleOnSave}
          >
            <I18nMessage id={ShortlistFiltersI18n.FILTERING_SAVE_BUTTON} prefix={SHORTLIST_FILTERS_I18N_PREFIX} />
          </ActionButton>
        </ShortlistFilterHeader>
      }
    >
      <>
        {modifiedOn && (
          <Text className="shortlist-filters-filtering__modified-on" variant={TextVariant.BODY_SMALL}>
            <I18nMessage
              id={ShortlistFiltersI18n.FILTERING_LAST_UPDATE}
              prefix={SHORTLIST_FILTERS_I18N_PREFIX}
              values={{ lastModifiedOn: modifiedOn.toLocaleDateString(locale, { month: "short", day: "numeric" }) }}
            />
          </Text>
        )}
        <ButtonOption
          className="button-option-shortlist-filter-item"
          state={optionState}
          label={
            <I18nMessage id={ShortlistFiltersI18n.FILTERING_CUSTOMER_BUTTON} prefix={SHORTLIST_FILTERS_I18N_PREFIX} />
          }
          onChange={handleOnChange}
        />
      </>
    </ShortlistFilterItemRootComponent>
  );
};

export { ShortlistFiltersFiltering };
