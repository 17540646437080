import { FC, memo } from "react";
import NullShortlistFilterItem from "../../shortlistFilterItem/components/nullShortlistFilterItem/NullShortlistFilterItem";
import PillShortlistFilterItem from "../../shortlistFilterItem/components/pillShortlistFilterItem/PillShortlistFilterItem";
import RangePillShortlistFilterItem from "../../shortlistFilterItem/components/rangePillShortlistFilterItem/RangePillShortlistFilterItem";
import {
  ShortlistFilterItemProvider,
  ShortlistFilterItems,
} from "../../shortlistFilterItem/_behavior/useShortlistFilterItem";
import ShortlistFilters from "../../ShortlistFilters";
import {
  ShortlistFilterProjection,
  ShortlistFilterType,
} from "../../../../../../core/projection/shortlistFilter/shortlistFilter";
import "./shortlist-filters-summary.css";

const SHORTLIST_FILTER_SUMMARY_ITEMS: ShortlistFilterItems = {
  [ShortlistFilterType.HOST_NULL]: PillShortlistFilterItem,
  [ShortlistFilterType.HOST_DEFAULT]: PillShortlistFilterItem,
  [ShortlistFilterType.HOST_SEARCH]: PillShortlistFilterItem,
  [ShortlistFilterType.HOST_RANGE]: RangePillShortlistFilterItem,
  [ShortlistFilterType.CHECKBOX]: PillShortlistFilterItem,
  [ShortlistFilterType.OPTION]: PillShortlistFilterItem,
  [ShortlistFilterType.COLOR]: PillShortlistFilterItem,
  [ShortlistFilterType.RANGE]: NullShortlistFilterItem,
  [ShortlistFilterType.ICON]: PillShortlistFilterItem,
  [ShortlistFilterType.NULL]: PillShortlistFilterItem,
};

type ShortlistFiltersSummaryProps = {
  readonly filters: ShortlistFilterProjection[];
};
const ShortlistFiltersSummary: FC<ShortlistFiltersSummaryProps> = ({ filters }: ShortlistFiltersSummaryProps) => (
  <section className="shortlist-filters-summary">
    <ShortlistFilterItemProvider shortlistFilterItems={SHORTLIST_FILTER_SUMMARY_ITEMS}>
      <ShortlistFilters filters={filters} />
    </ShortlistFilterItemProvider>
  </section>
);

export default memo(ShortlistFiltersSummary);
