import { useMemo } from "react";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import useBoxSharedContext from "../../../../../ui/views/_behaviors/useBoxSharedContext";
import {
  CanPersonalShopperGenerateNoteResult,
  canGenerateNote,
} from "../../../../projection/personalShopper/canPersonalShopperGenerateNote";
import { useCanPersonalShopperWriteNote } from "./useCanPersonalShopperWriteNote";

interface UseCanPersonalShopperGenerateNoteFunctionArgs {
  readonly boxId: string;
}
interface UseCanPersonalShopperGenerateNoteFunction {
  (args: UseCanPersonalShopperGenerateNoteFunctionArgs): UseQueryFunctionResult<CanPersonalShopperGenerateNoteResult>;
}

const useCanPersonalShopperGenerateNote: UseCanPersonalShopperGenerateNoteFunction = ({ boxId }) => {
  const { selection } = useBoxSharedContext();
  const [canPersonalShopperGenerateNote, canPersonalShopperGenerateNoteStatus] = useCanPersonalShopperWriteNote({
    boxId,
  });

  const result: UseQueryFunctionResult<CanPersonalShopperGenerateNoteResult> = useMemo(
    () => [
      selection && canPersonalShopperGenerateNote !== undefined
        ? canPersonalShopperGenerateNote && canGenerateNote({ selection })
        : undefined,
      canPersonalShopperGenerateNoteStatus,
    ],
    [canPersonalShopperGenerateNote, canPersonalShopperGenerateNoteStatus, selection],
  );

  return result;
};
// useQuery({
//   query: canPersonalShopperGenerateNote({ boxId }),
//   contextId: MESSAGING_CONTEXT_ID,
//   invalidation: (event: DomainEvent<MessageName>) =>
//     event instanceof BoxClosed ||
//     event instanceof ProductVariantSelected ||
//     event instanceof ProductVariantDeselected ||
//     event instanceof ProductVariantReplaced ||
//     event instanceof ProductVariantMarkedAsCandidate ||
//     event instanceof ProductVariantUnmarkedAsCandidate,
//   options: { staleTime: Infinity },
// });

export { useCanPersonalShopperGenerateNote };
