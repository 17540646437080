import { FC, useMemo } from "react";
import classNames from "classnames";
import Icon, { IconVariant } from "../../atoms/icon/Icon";
import Text from "../../atoms/text/Text";
import "./notification.css";

enum NotificationVariant {
  DEFAULT = "default",
  ERROR = "error",
  SUCCESS = "success",
}

interface UseNotificationIconAndModifierResult {
  readonly icon: IconVariant;
  readonly modifier: "default" | "error" | "success";
}
interface UseNotificationIconAndModifierFunctionArgs {
  readonly variant: NotificationVariant;
}
interface UseNotificationIconAndModifierFunction {
  (args: UseNotificationIconAndModifierFunctionArgs): UseNotificationIconAndModifierResult;
}
const useNotificationIconAndModifier: UseNotificationIconAndModifierFunction = ({ variant }) => {
  const { icon, modifier }: UseNotificationIconAndModifierResult = useMemo(() => {
    switch (variant) {
      case NotificationVariant.ERROR:
        return { icon: IconVariant.REMOVE_FILLED, modifier: "error" };
      case NotificationVariant.SUCCESS:
        return { icon: IconVariant.BASIC_CHECK, modifier: "success" };
      default:
        return { icon: IconVariant.INFO, modifier: "default" };
    }
  }, [variant]);

  return { icon, modifier };
};

interface NotificationProps {
  readonly className?: string;
  readonly children: string;
  readonly variant?: NotificationVariant;
  readonly onCancel?: () => void;
  readonly testID?: string;
}

const Notification: FC<NotificationProps> = ({
  className,
  variant = NotificationVariant.DEFAULT,
  children,
  onCancel,
  testID,
}) => {
  const { icon, modifier } = useNotificationIconAndModifier({ variant });

  return (
    <div className={classNames("notification", `notification--${modifier}`, className)} data-testid={testID}>
      <span className="notification__icon">
        <Icon variant={icon} />
      </span>
      <Text className="notification__content">
        <div dangerouslySetInnerHTML={{ __html: children }} />
      </Text>
      {onCancel && (
        <span className="notification__cancel" role="button" onClick={onCancel}>
          <Icon variant={IconVariant.CLOSE} />
        </span>
      )}
    </div>
  );
};

export { Notification, NotificationVariant };
