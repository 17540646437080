import { FC } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../_i18n/NoteI18n";
import HistoryNote from "../../organisms/historyNote/HistoryNote";
import Text from "../../../../shared/ui/uiKit/atoms/text/Text";
import { HistoryNoteProjection } from "../../../../core/projection/historyNote/historyNote";
import "./history-notes.css";

interface HistoryNotesProps {
  readonly historyNotes?: HistoryNoteProjection[];
}

const HistoryNotes: FC<HistoryNotesProps> = ({ historyNotes }) => {
  return (
    <section className="history-notes" data-testid="history-notes">
      {historyNotes && historyNotes.length > 0 ? (
        <ul aria-label="history-notes-list" className="history-notes__list">
          {historyNotes.map((historyNote) => (
            <li key={historyNote.id} className="history-notes__list-item">
              <HistoryNote historyNote={historyNote} />
            </li>
          ))}
        </ul>
      ) : (
        <Text className="history-notes__empty-notes">
          <I18nMessage id={NoteI18n.NO_HISTORY_NOTES} prefix={NOTE_I18N_PREFIX} />
        </Text>
      )}
    </section>
  );
};

export default HistoryNotes;
