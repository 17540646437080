const SHORTLIST_FILTERS_I18N_PREFIX = "labs.shortlist.filters.";

enum ShortlistFiltersI18n {
  CLEAR = "clear",
  SEARCH = "search",
  MAIN_SEARCH = "main_search",
  MAIN_SEARCH_PLACEHOLDER = "main_search_placeholder",
  VIEW_ALL = "view_all",
  NO_OPTIONS = "no_options",
  NO_PRODUCT_RESULTS = "no_product_results",
  FILTERING = "filtering",
  FILTERING_CUSTOMER_BUTTON = "filtering.customer_button",
  FILTERING_SAVE_BUTTON = "filtering.save_button",
  FILTERING_POSITIVES = "filtering.tab_positives",
  FILTERING_NEGATIVES = "filtering.tab_negatives",
  FILTERING_LAST_UPDATE = "filtering.last_update",
  FILTERING_SAVE_ERROR = "filtering.save_error",
}

export { SHORTLIST_FILTERS_I18N_PREFIX };

export default ShortlistFiltersI18n;
