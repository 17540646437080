const cdnImageUrl = ({
  url,
  width,
  dpi = window.devicePixelRatio,
}: {
  url: string;
  width: number;
  dpi?: number;
}): string => (url ? `${url}?w=${Math.ceil(width * (dpi || 1))}&f=auto` : url);

export default cdnImageUrl;
