import { FC } from "react";
import BoughtOn from "../../../molecules/boughtOn/BoughtOn";
import Feedback from "../../../molecules/feedback/Feedback";
import PositiveFeedback from "../../../molecules/positiveFeedback/PositiveFeedback";
import ReturnReason from "../../../molecules/returnReason/ReturnReason";
import { HistoryShopProductVariantProjection } from "../../../../../core/projection/historyShop/historyShopProductVariant";
import "./history-shop-product-variant-feedback.css";

interface HistoryShopProductVariantFeedbackProps {
  readonly productVariant: HistoryShopProductVariantProjection;
}

const HistoryShopProductVariantFeedback: FC<HistoryShopProductVariantFeedbackProps> = ({ productVariant }) => (
  <ul className="history-shop-product-variant-feedback">
    <li>
      <BoughtOn date={productVariant.boughtOn} />
    </li>
    {productVariant.feedback &&
      productVariant.feedback.answer.map((feedbackAnswer) => (
        <li key={feedbackAnswer}>
          <Feedback feedbackAnswer={feedbackAnswer} />
        </li>
      ))}
    {productVariant.positiveFeedback?.map((feedback) => (
      <li key={feedback}>
        <PositiveFeedback positiveFeedback={feedback} />
      </li>
    ))}
    {productVariant.returnReasons?.map((returnReason) => (
      <li key={returnReason}>
        <ReturnReason returnReason={returnReason} />
      </li>
    ))}
  </ul>
);

export { HistoryShopProductVariantFeedback };
