import { useI18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import RealTimeBoxProductionI18n, { REAL_TIME_BOX_PRODUCTION_I18N_PREFIX } from "../../_i18n/RealTimeBoxProductionI18n";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import { PendingBoxProjection } from "../../../../core/projection/pendingBox/pendingBox";
import "./pending-boxes.css";

interface PendingBoxesProps {
  readonly pendingBoxes: PendingBoxProjection[];
}
const PendingBoxes: FC<PendingBoxesProps> = ({ pendingBoxes }) => {
  const pendingBoxesTitle = useI18nMessage({
    id: RealTimeBoxProductionI18n.PENDING_BOXES_TITLE,
    prefix: REAL_TIME_BOX_PRODUCTION_I18N_PREFIX,
  });

  return (
    <div className="pending-boxes">
      <Text variant={TextVariant.BODY_BOLD}>{pendingBoxesTitle}:</Text>

      <div>
        {pendingBoxes.map((pendingBox) => (
          <div key={pendingBox.country}>
            <Text>
              {pendingBox.country}: {pendingBox.pendingBoxes}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export { PendingBoxes };
