const TYPES = {
  MessagingRootComponent: Symbol.for("MessagingRootComponent"),
  QueryBus: Symbol.for("QueryBus"),
  CommandBus: Symbol.for("CommandBus"),
  DomainEventBus: Symbol.for("DomainEventBus"),
  UIEventBus: Symbol.for("UIEventBus"),

  MessagingQueryBus: Symbol.for("MessagingQueryBus"),
  MessagingCommandBus: Symbol.for("MessagingCommandBus"),

  I18nRootComponent: Symbol.for("I18nRootComponent"),

  Logger: Symbol.for("Logger"),
  Environment: Symbol.for("Environment"),
  CookieStorage: Symbol.for("CookieStorage"),
  HttpClient: Symbol.for("HttpClient"),
  Navigator: Symbol.for("Navigator"),
  Tracker: Symbol.for("Tracker"),

  WindowsMessenger: Symbol.for("WindowsMessenger"),
  LabsFrontLegacyWindowsMessenger: Symbol.for("LabsFrontLegacyWindowsMessenger"),
  CustomerWindowsMessenger: Symbol.for("CustomerWindowsMessenger"),
  RealTimeBoxProductionAuthCookieBasedFeatureToggle: Symbol.for("RealTimeBoxProductionAuthCookieBasedFeatureToggle"),
  RealTimeBoxProductionRouteFirewall: Symbol.for("RealTimeBoxProductionRouteFirewall"),
  PlanningAuthCookieBasedFeatureToggle: Symbol.for("PlanningAuthCookieBasedFeatureToggle"),
  CapacityAuthCookieBasedFeatureToggle: Symbol.for("CapacityAuthCookieBasedFeatureToggle"),
  StockSurveyTypeformAuthCookieBasedFeatureToggle: Symbol.for("StockSurveyTypeformAuthCookieBasedFeatureToggle"),
  AutomaticSelectionTypeformAuthCookieBasedFeatureToggle: Symbol.for(
    "AutomaticSelectionTypeformAuthCookieBasedFeatureToggle",
  ),
  CustomerInsightsAuthCookieBasedFeatureToggle: Symbol.for("CustomerInsightsAuthCookieBasedFeatureToggle"),
  PlanningRouteFirewall: Symbol.for("PlanningRouteFirewall"),
  CapacityRouteFirewall: Symbol.for("CapacityRouteFirewall"),
  ToLegacy: Symbol.for("ToLegacy"),

  BoxPreviews: Symbol.for("BoxPreviews"),
  SendBoxPreviewHandler: Symbol.for("SendBoxPreviewHandler"),
  SetLooksWhenBoxPreviewSent: Symbol.for("SetLooksWhenBoxPreviewSent"),
  PrefetchMediaWhenBoxPreviewSent: Symbol.for("PrefetchMediaWhenBoxPreviewSent"),
  CreateNotificationWhenBoxPreviewSent: Symbol.for("CreateNotificationWhenBoxPreviewSent"),

  PendingBoxView: Symbol.for("PendingBoxView"),
};

export { TYPES };
