enum UISettings {
  GRID_SIZE = "GRID_SIZE",
  TOGGLE_NOTE = "TOGGLE_NOTE",
  NOTE_ASSISTANT_TYPEFORM = "NOTE_ASSISTANT_TYPEFORM",
  BOX_ROUTE_PATH = "BOX_ROUTE_PATH",
  AUTOMATIC_SELECTION_FINISHED_SHOWN = "AUTOMATIC_SELECTION_FINISHED_SHOWN",
  AUTOMATIC_SELECTION_TYPEFORM = "AUTOMATIC_SELECTION_TYPEFORM",
}

enum UISettingsToggleNote {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}

export { UISettingsToggleNote };

export default UISettings;
