const ONE_STONE_KGS = 6.35029;
const ONE_POUND_KGS = 0.45359237;
const ONE_FOOT_CMS = 0.3048 * 100;
const ONE_INCH_CMS = 0.0254 * 100;
const ONE_KG_POUNDS = 2.2046226218487757;
const ONE_STONE_POUNDS = 14;
const ONE_CMS_INCHES = 0.3937007874015748;
const ONE_FOOT_INCHES = 12;

interface ImperialWeight {
  readonly stones: number;
  readonly pounds: number;
}

interface ImperialHeight {
  readonly feet: number;
  readonly inches: number;
}

interface ImperialWeightToKgsFunction {
  (args: ImperialWeight): number;
}

const imperialWeightToKgs: ImperialWeightToKgsFunction = ({ stones, pounds }) =>
  Math.round((stones * ONE_STONE_KGS + pounds * ONE_POUND_KGS) * 100) / 100;

interface ImperialHeightToCmsFunction {
  (args: ImperialHeight): number;
}

const imperialHeightToCms: ImperialHeightToCmsFunction = ({ feet, inches }) =>
  Math.round((feet * ONE_FOOT_CMS + inches * ONE_INCH_CMS) * 100) / 100;

interface CmsToImperialHeightFunctionArgs {
  readonly cms: number;
}
interface CmsToImperialHeightFunction {
  (args: CmsToImperialHeightFunctionArgs): ImperialHeight;
}
const cmsToImperialHeight: CmsToImperialHeightFunction = ({ cms }) => {
  const totalInches = cms * ONE_CMS_INCHES;
  let feet = Math.floor(totalInches / ONE_FOOT_INCHES);
  let inches = Math.round(totalInches % ONE_FOOT_INCHES);

  if (inches === ONE_FOOT_INCHES) {
    feet = feet + 1;
    inches = 0;
  }

  return {
    feet,
    inches,
  };
};

interface KgsToImperialWeightFunctionArgs {
  readonly kgs: number;
}
interface KgsToImperialWeightFunction {
  (args: KgsToImperialWeightFunctionArgs): ImperialWeight;
}
const kgsToImperialWeight: KgsToImperialWeightFunction = ({ kgs }) => {
  const totalPound = kgs * ONE_KG_POUNDS;
  let stones = Math.floor(totalPound / ONE_STONE_POUNDS);
  let pounds = Math.round(totalPound % ONE_STONE_POUNDS);

  if (pounds === ONE_STONE_POUNDS) {
    stones = stones + 1;
    pounds = 0;
  }

  return {
    pounds,
    stones,
  };
};

export { imperialWeightToKgs, imperialHeightToCms, kgsToImperialWeight, cmsToImperialHeight };
