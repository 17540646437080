import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CapacityCalendarProjection } from "./capacityCalendar";
import Country from "../../domain/country/model/Country";
import { Segment } from "../segment/segment";

const VIEW_CAPACITY_CALENDAR_BY_CRITERIA = "view_capacity_calendar_by_criteria";

interface ViewCapacityCalendarByCriteriaPayload {
  readonly from: Date;
  readonly to: Date;
  readonly markets: Country[];
  readonly segments: Segment[];
  readonly page: number;
  readonly perPage: number;
}

interface ViewCapacityCalendarByCriteria
  extends Query<typeof VIEW_CAPACITY_CALENDAR_BY_CRITERIA>,
    ViewCapacityCalendarByCriteriaPayload {}

interface ViewCapacityCalendarByCriteriaFunction {
  (payload: ViewCapacityCalendarByCriteriaPayload): ViewCapacityCalendarByCriteria;
}

const viewCapacityCalendarByCriteria: ViewCapacityCalendarByCriteriaFunction = (payload) => ({
  ...query({ name: VIEW_CAPACITY_CALENDAR_BY_CRITERIA }),
  ...payload,
});

type ViewCapacityCalendarByCriteriaResult = CapacityCalendarProjection[] | null;

interface CapacityCalendarByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly from: Date;
  readonly to: Date;
  readonly markets: Country[];
  readonly segments: Segment[];
  readonly page: number;
  readonly perPage: number;
}

interface CapacityCalendarByCriteriaView {
  (args: CapacityCalendarByCriteriaViewArgs): Promise<ViewCapacityCalendarByCriteriaResult>;
}

interface ViewCapacityCalendarByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CapacityCalendarByCriteriaView;
}

const viewCapacityCalendarByCriteriaHandler: QueryHandlerFunction<
  ViewCapacityCalendarByCriteria,
  ViewCapacityCalendarByCriteriaResult,
  ViewCapacityCalendarByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ from, markets, page, perPage, segments, to }) =>
    view({ from, markets, page, perPage, segments, to, signal });

export type { ViewCapacityCalendarByCriteria, CapacityCalendarByCriteriaView, ViewCapacityCalendarByCriteriaResult };
export { VIEW_CAPACITY_CALENDAR_BY_CRITERIA, viewCapacityCalendarByCriteria, viewCapacityCalendarByCriteriaHandler };
