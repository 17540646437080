enum NoteTemplateValidationErrorId {
  TITLE = "TITLE",
  CATEGORY = "CATEGORY",
  CONTENT = "CONTENT",
}

interface NoteTemplateValidationError {
  readonly id: NoteTemplateValidationErrorId;
  readonly message?: string;
}

interface NoteTemplateValidation {
  readonly errors: NoteTemplateValidationError[];
}

export type { NoteTemplateValidation, NoteTemplateValidationError };
export { NoteTemplateValidationErrorId };
