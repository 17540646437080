import { BoxesByCustomerIdView, ListBoxesByCustomerIdResult } from "../../../projection/box/listBoxesByCustomerId";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { BasicBoxDto, toBasicBoxProjection } from "./box";

interface HttpBoxesByCustomerIdView extends BoxesByCustomerIdView {}

interface HttpBoxesByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxesByCustomerIdViewFunction {
  (args: HttpBoxesByCustomerIdViewFunctionArgs): HttpBoxesByCustomerIdView;
}

const httpBoxesByCustomerIdView: HttpBoxesByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<BasicBoxDto[], ListBoxesByCustomerIdResult>({
      endpoint: "/list-boxes-by-customer-id",
      body: { customerId },
      signal,
      result: {
        error: null,
        success: (response) => response.map(toBasicBoxProjection),
      },
    });

export { httpBoxesByCustomerIdView };
