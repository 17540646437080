import { UIEvent, useCallback } from "react";

const useCaptureAndStopEvent = <E extends UIEvent>(callback: () => void): ((event: E) => void) =>
  useCallback(
    (event: E): void => {
      event.stopPropagation();
      event.preventDefault();

      callback();
    },
    [callback],
  );

export default useCaptureAndStopEvent;
