import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import {
  ActivityTimesGetFunction,
  ActivityTimesSaveFunction,
} from "../../../../domain/activityTime/model/activityTimes";
import { httpOrNavigatorActivityTimesRegister } from "./httpOrNavigatorActivityTimesRegister";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpActivityTimesRegisterPreviewedWatermark } from "./httpActivityTimesRegisterPreviewedWatermark";
import { NavigatorSendBeaconFunction } from "../../../delivery/navigator/navigator";

interface HttpActivityTimesGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpActivityTimesGetFunction extends ActivityTimesGetFunction<HttpActivityTimesGetFunctionArgs> {}

const getActivityTime: HttpActivityTimesGetFunction = () => async () => {
  throw new Error("ActivityTime not found");
};

interface HttpOrNavigatorActivityTimesSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
  readonly sendBeacon: NavigatorSendBeaconFunction;
}

interface HttpOrNavigatorActivityTimesSaveFunction
  extends ActivityTimesSaveFunction<HttpOrNavigatorActivityTimesSaveFunctionArgs> {}

const saveActivityTime: HttpOrNavigatorActivityTimesSaveFunction =
  ({ httpPost, sendBeacon }) =>
  async (aggregateRoot) => {
    await Promise.all([
      await httpOrNavigatorActivityTimesRegister({ httpPost, sendBeacon })(aggregateRoot),
      await httpActivityTimesRegisterPreviewedWatermark({ httpPost, sendBeacon })(aggregateRoot),
    ]);
  };

export type { HttpOrNavigatorActivityTimesSaveFunction };
export { getActivityTime, saveActivityTime };
