import { FC, useCallback, useState } from "react";
import Icon, { IconVariant } from "../../../../../shared/ui/uiKit/atoms/icon/Icon";
import Picture from "../../../../../shared/ui/uiKit/atoms/picture/Picture";
import Modal from "../../../../../shared/ui/uiKit/layouts/modal/Modal";
import AspectRatio from "../../../../../shared/ui/uiKit/_common/AspectRatio";
import Media, { mainTransparentImageFromMedia } from "../../../../../core/projection/media/model/Media";
import cdnImageUrl from "../../../../_util/cdnImageUrl";
import { LookProductVariantProjection } from "../../../../../core/projection/looks/lookProductVariant";
import "./look-product-variant-selector.css";

const LOOK_PRODUCT_VARIANT_SELECTOR_IMAGE_WIDTH = 200;

const imageSrc = (media: Media[]) =>
  cdnImageUrl({
    url: mainTransparentImageFromMedia(media).url,
    width: LOOK_PRODUCT_VARIANT_SELECTOR_IMAGE_WIDTH,
  });

type LookProductVariantSelectorProps = {
  readonly productVariants: LookProductVariantProjection[] | undefined;
  readonly productVariant: LookProductVariantProjection | undefined;
  readonly onChanged: (productVariant: LookProductVariantProjection) => void;
};
const LookProductVariantSelector: FC<LookProductVariantSelectorProps> = ({
  productVariants,
  productVariant,
  onChanged,
}) => {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const showSelector = useCallback(() => setSelectorVisible(true), []);
  const hideSelector = useCallback(() => setSelectorVisible(false), []);

  const handleOnProductVariantSelected = useCallback(
    (productVariant: LookProductVariantProjection) => () => {
      hideSelector();
      onChanged(productVariant);
    },
    [hideSelector, onChanged],
  );

  return (
    <section aria-label="look-product-variant-selector" className="look-product-variant-selector">
      <div
        aria-label="look-product-variant-selector-item"
        className="look-product-variant-selector__item"
        role="button"
        onClick={showSelector}
      >
        {productVariant ? (
          <Picture
            aspectRatio={AspectRatio.R_4_5}
            label="look-product-variant-selector-item-picture"
            src={imageSrc(productVariant.media)}
            lazy
          />
        ) : (
          <Icon label="look-product-variant-selector-icon" variant={IconVariant.PLUS} />
        )}
      </div>
      <Modal className="look-product-variant-selector__modal" visible={selectorVisible} onHide={hideSelector}>
        <div className="look-product-variant-selector__modal-content">
          {productVariants?.map((productVariant) => (
            <div
              key={productVariant.id}
              aria-label="look-product-variant-selector-modal-item"
              className="look-product-variant-selector__modal-content-item"
              role="button"
              onClick={handleOnProductVariantSelected(productVariant)}
            >
              <Picture
                aspectRatio={AspectRatio.R_4_5}
                label="look-product-variant-selector-picture"
                src={imageSrc(productVariant.media)}
                lazy
              />
            </div>
          ))}
        </div>
      </Modal>
    </section>
  );
};

export default LookProductVariantSelector;
