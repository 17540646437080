import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { SelectionProjection } from "../../../../projection/selection/selection";
import { viewSelectionByBoxNumber } from "../../../../projection/selection/viewSelectionByBoxNumber";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { isAutomaticSelectionStarted } from "../../../../domain/selection/model/automaticSelectionStarted";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isProductVariantDeselected } from "../../../../domain/selection/model/ProductVariantDeselected";
import { isProductVariantReplaced } from "../../../../domain/selection/model/ProductVariantReplaced";
import { isProductVariantSelected } from "../../../../domain/selection/model/ProductVariantSelected";
import { isProductVariantUnmarkedAsCandidate } from "../../../../domain/selection/model/productVariantUnmarkedAsCandidate";
import { isProductVariantMarkedAsCandidate } from "../../../../domain/selection/model/productVariantMarkedAsCandidate";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isAutomaticSelectionStarted(event) ||
  isProductVariantDeselected(event) ||
  isProductVariantReplaced(event) ||
  isProductVariantSelected(event) ||
  isProductVariantUnmarkedAsCandidate(event) ||
  isProductVariantMarkedAsCandidate(event);

interface UseViewSelectionByBoxNumberFunctionArgs {
  readonly boxNumber: string;
  readonly queryId?: string | string[];
}

interface UseViewSelectionByBoxNumberFunction {
  (args: UseViewSelectionByBoxNumberFunctionArgs): UseQueryFunctionResult<SelectionProjection>;
}

const useViewSelectionByBoxNumber: UseViewSelectionByBoxNumberFunction = ({ boxNumber, queryId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    id: queryId,
    query: viewSelectionByBoxNumber({ boxNumber }),
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useViewSelectionByBoxNumber };
