import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { SetLooks } from "../command/setLooks";
import { LookDto } from "../../../infrastructure/persistence/lookDto";
import { looksSet } from "./looksSet";

const VALID_LOOK_LENGTHS = [0, 2, 3, 4];

interface Look extends AggregateRoot {
  readonly looks: LookDto[];
}

const setLooksHandler: CommandHandlerFunction<SetLooks, Look> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { looks } = command;

    if (looks.some((look) => !VALID_LOOK_LENGTHS.includes(look.length))) {
      throw new Error("Invalid looks length");
    }

    if (looks.some((look) => look.some((productVariant) => !Boolean(productVariant)))) {
      throw new Error("Looks cannot contain empty product variant");
    }

    if (looks.some((look) => look.length !== new Set(look).size)) {
      throw new Error("Looks cannot contain duplicate product variant");
    }

    return {
      ...aggregateRoot,
      looks,
      domainEvents: [looksSet({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

export type { Look };
export { setLooksHandler };
