import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { BoxProjection } from "./box";

const VIEW_BOX_BY_BOX_NUMBER = "view_box_by_box_number";

interface ViewBoxByBoxNumberPayload {
  readonly boxNumber: string;
}

interface ViewBoxByBoxNumber extends Query<typeof VIEW_BOX_BY_BOX_NUMBER>, ViewBoxByBoxNumberPayload {}

interface ViewBoxByBoxNumberFunction {
  (payload: ViewBoxByBoxNumberPayload): ViewBoxByBoxNumber;
}

const viewBoxByBoxNumber: ViewBoxByBoxNumberFunction = (payload) => ({
  ...query({ name: VIEW_BOX_BY_BOX_NUMBER }),
  ...payload,
});

type ViewBoxByBoxNumberResult = BoxProjection | null;

interface BoxByBoxNumberViewArgs extends CancelableQueryViewArgs {
  readonly boxNumber: string;
}

interface BoxByBoxNumberView {
  (args: BoxByBoxNumberViewArgs): Promise<ViewBoxByBoxNumberResult>;
}

interface ViewBoxByBoxNumberHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: BoxByBoxNumberView;
}

const viewBoxByBoxNumberHandler: QueryHandlerFunction<
  ViewBoxByBoxNumber,
  ViewBoxByBoxNumberResult,
  ViewBoxByBoxNumberHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxNumber }) =>
    view({ boxNumber, signal });

export type { BoxByBoxNumberView, ViewBoxByBoxNumber, ViewBoxByBoxNumberResult };
export { VIEW_BOX_BY_BOX_NUMBER, viewBoxByBoxNumber, viewBoxByBoxNumberHandler };
