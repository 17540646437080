import { isBoxSentToSupervise } from "../../../../domain/box/model/boxSentToSupervise";
import { HttpBoxesSaveFunction } from "./httpBoxes";

interface HttpBoxesSendToSuperviseFunction extends HttpBoxesSaveFunction {}

const httpBoxesSendToSupervise: HttpBoxesSendToSuperviseFunction =
  ({ httpPost }) =>
  async ({ aggregateId, personalShopperId, domainEvents }) => {
    const boxSentToSupervise = domainEvents.find(isBoxSentToSupervise);

    if (!boxSentToSupervise) {
      return;
    }

    await httpPost({
      endpoint: "/send-box-to-supervise",
      body: {
        box_id: aggregateId,
        logged_in_ps_id: personalShopperId,
      },
    });
  };

export { httpBoxesSendToSupervise };
