import { FC } from "react";
import { ValueContainerProps, components } from "react-select";
import FormInputOption from "../../_common/FormInputOption";
import Text from "../../atoms/text/Text";

const dropdownValueContainer: ({
  label,
  noValueLabel,
}: {
  label?: string;
  noValueLabel: string;
}) => FC<ValueContainerProps<FormInputOption, boolean>> =
  ({ label, noValueLabel }) =>
  ({ children, ...props }): JSX.Element => {
    const { hasValue } = props;
    const labelComponent = (
      <>
        {label && <Text className="dropdown__label" ellipsis>{`${label}: `}</Text>}
        {!hasValue && <Text className="dropdown__label-default">{noValueLabel}</Text>}
      </>
    );

    return (
      <components.ValueContainer {...props}>
        <>
          {labelComponent}
          {children}
        </>
      </components.ValueContainer>
    );
  };

export default dropdownValueContainer;
