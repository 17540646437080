enum QueryParams {
  FAVOURITES = "favourites",
  SEARCH = "search",
  FILTERS = "filters",
  PAGE = "page",
  PERPAGE = "perPage",
  CATEGORIES = "categories",
  BOX_NUMBER = "boxNumber",
  COUNTRY = "country",
  PLANNED_FOR = "plannedFor",
  PS_NUMBER = "psNumber",
  ASSIGNMENT = "assignment",
  TAG = "tag",
  FROM = "from",
  TO = "to",
  SEGMENT = "segment",
}

export default QueryParams;
