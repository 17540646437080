import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

type ShortlistFilters = { id: string }[];

interface ShortlistProductCountByShortlistFilters {
  readonly boxNumber: string;
  readonly psId: string;
  readonly search?: string;
  readonly favourites?: boolean;
  readonly shortlistFilters: ShortlistFilters;
  readonly negativeFilters: ShortlistFilters;
}

const COUNT_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS = "count_shortlist_products_by_shortlist_filters";

interface CountShortlistProductsByShortlistFiltersPayload {
  readonly criteria: ShortlistProductCountByShortlistFilters;
}

interface CountShortlistProductsByShortlistFilters
  extends Query<typeof COUNT_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS>,
    CountShortlistProductsByShortlistFiltersPayload {}

interface CountShortlistProductsByShortlistFiltersFunction {
  (payload: CountShortlistProductsByShortlistFiltersPayload): CountShortlistProductsByShortlistFilters;
}

const countShortlistProductsByShortlistFilters: CountShortlistProductsByShortlistFiltersFunction = (payload) => ({
  ...query({ name: COUNT_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS }),
  ...payload,
});

type CountShortlistProductsByShortlistFiltersResult = number;

interface ShortlistProductsCountByShortlistFiltersViewArgs extends CancelableQueryViewArgs {
  readonly criteria: ShortlistProductCountByShortlistFilters;
}

interface ShortlistProductsCountByShortlistFiltersView {
  (args: ShortlistProductsCountByShortlistFiltersViewArgs): Promise<CountShortlistProductsByShortlistFiltersResult>;
}

interface CountShortlistProductsByShortlistFiltersHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ShortlistProductsCountByShortlistFiltersView;
}

const countShortlistProductsByShortlistFiltersHandler: QueryHandlerFunction<
  CountShortlistProductsByShortlistFilters,
  CountShortlistProductsByShortlistFiltersResult,
  CountShortlistProductsByShortlistFiltersHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ criteria }) =>
    view({ criteria, signal });

export type {
  CountShortlistProductsByShortlistFilters,
  ShortlistProductsCountByShortlistFiltersView,
  CountShortlistProductsByShortlistFiltersResult,
  ShortlistProductCountByShortlistFilters,
  ShortlistFilters,
};
export {
  COUNT_SHORTLIST_PRODUCTS_BY_SHORTLIST_FILTERS,
  countShortlistProductsByShortlistFilters,
  countShortlistProductsByShortlistFiltersHandler,
};
