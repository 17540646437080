const NOTE_TEMPLATE_I18N_PREFIX = "labs.note.template.";

enum NoteTemplateI18n {
  TEMPLATES_TITLE = "templates_title",
  TEMPLATES_NOT_AVAILABLE = "templates_not_available",
  TEMPLATES_FILTER = "templates_filter",
  TEMPLATES_NO_FILTER = "templates_no_filter",
  TEMPLATES_CREATE = "templates_create",
  TEMPLATES_SAVE = "templates_save",
  TEMPLATES_CANCEL = "templates_cancel",
  TEMPLATES_FORM_FIELD_TITLE = "templates_form_field_title",
  TEMPLATES_FORM_FIELD_CATEGORY = "templates_form_field_category",
  USE_TEMPLATE_BUTTON = "use_template_button",
  REMOVE_TEMPLATE_BUTTON = "remove_template_button",
  EDIT_TEMPLATE_BUTTON = "edit_template_button",
  CANCEL_REMOVE_TEMPLATE_BUTTON = "cancel_remove_template_button",
  CONFIRM_REMOVE_TEMPLATE_BUTTON = "confirm_remove_template_button",
  TEMPLATES_NOT_FOUND = "templates_not_found",
}

export { NOTE_TEMPLATE_I18N_PREFIX };

export default NoteTemplateI18n;
