import { FC, useCallback } from "react";
import { useTrackDetailView } from "../../../../../shared/tracking/infrastructure/useTrackDetailView";
import { TrackingPage } from "../../../../../shared/tracking/Tracker";
import { PersonalShopperProjection } from "../../../../../core/projection/personalShopper/personalShopper";
import Card from "../../../../../shared/ui/uiKit/layouts/card/Card";
import {
  HistoryProductVariant,
  OnClickHistoryProductVariant,
} from "../../../organisms/historyProductVariant/HistoryProductVariant";
import { HistoryShopProductVariantProjection } from "../../../../../core/projection/historyShop/historyShopProductVariant";
import "./history-shop.css";

interface HistoryShopProps {
  readonly customerId: string;
  readonly historyShop: HistoryShopProductVariantProjection[];
  readonly personalShopper: PersonalShopperProjection;
  readonly onClickProductVariant: (productVariantId: string) => void;
}
const HistoryShop: FC<HistoryShopProps> = ({ customerId, historyShop, personalShopper, onClickProductVariant }) => {
  const trackDetailView = useTrackDetailView({
    section: TrackingPage.HISTORY_SHOP,
    psId: personalShopper.id,
    userId: customerId,
  });

  const handleOnClickProductVariant: OnClickHistoryProductVariant = useCallback(
    ({ productId, productVariantId }) => {
      trackDetailView({ productId, productVariantId });
      onClickProductVariant(productVariantId);
    },
    [onClickProductVariant, trackDetailView],
  );

  return (
    <Card className="history-shop" label="history-shop">
      {historyShop.map((historyShopProductVariant) => (
        <HistoryProductVariant
          key={historyShopProductVariant.productVariantId}
          productVariant={historyShopProductVariant}
          onClick={handleOnClickProductVariant}
        />
      ))}
    </Card>
  );
};

export { HistoryShop };
