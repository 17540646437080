import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { BasicSelectionProjection } from "./selection";

const VIEW_SELECTION_BY_ID = "view_selection_by_id";

interface ViewSelectionByIdPayload {
  readonly id: string;
}

interface ViewSelectionById extends Query<typeof VIEW_SELECTION_BY_ID>, ViewSelectionByIdPayload {}

interface ViewSelectionByIdFunction {
  (payload: ViewSelectionByIdPayload): ViewSelectionById;
}

const viewSelectionById: ViewSelectionByIdFunction = (payload) => ({
  ...query({ name: VIEW_SELECTION_BY_ID }),
  ...payload,
});

type ViewSelectionByIdResult = BasicSelectionProjection | null;

interface SelectionByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface SelectionByIdView {
  (args: SelectionByIdViewArgs): Promise<ViewSelectionByIdResult>;
}

interface ViewSelectionByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: SelectionByIdView;
}

const viewSelectionByIdHandler: QueryHandlerFunction<
  ViewSelectionById,
  ViewSelectionByIdResult,
  ViewSelectionByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type { SelectionByIdView, ViewSelectionById, ViewSelectionByIdResult };
export { VIEW_SELECTION_BY_ID, viewSelectionById, viewSelectionByIdHandler };
