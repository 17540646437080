enum LookTemplate {
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
}

const itemsPerLookTemplate = {
  [LookTemplate.TWO]: 2,
  [LookTemplate.THREE]: 3,
  [LookTemplate.FOUR]: 4,
};

const lookTemplateForItems = (size: number): LookTemplate | undefined => {
  switch (size) {
    case 2:
      return LookTemplate.TWO;
    case 3:
      return LookTemplate.THREE;
    case 4:
      return LookTemplate.FOUR;
  }
};

export { LookTemplate, itemsPerLookTemplate, lookTemplateForItems };
