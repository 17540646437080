import { FC, ReactNode } from "react";
import classNames from "classnames";
import Text, { TextVariant } from "../text/Text";
import "./tag.css";

enum TagVariant {
  PRIMARY = "primary",
  YELLOW = "yellow",
  ORANGE = "orange",
  RED = "red",
  PURPLE = "purple",
  BLUE = "blue",
  GREEN = "green",
}

interface TagProps {
  readonly variant?: TagVariant;
  readonly children: ReactNode;
}

const Tag: FC<TagProps> = ({ children, variant = TagVariant.PRIMARY }) => (
  <Text className={classNames("tag", `tag--${variant}`)} variant={TextVariant.BODY_SMALL}>
    {children}
  </Text>
);

export { TagVariant, Tag };
