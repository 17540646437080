import { AggregateRoot } from "@lookiero/messaging.js";
import BoxPreviewSent from "../event/BoxPreviewSent";
import Look from "./Look";
import LookList from "./LookList";

type BoxPreviewParameters = {
  readonly boxPreviewId: string;
  readonly boxId: string;
  readonly looks: Look[] | undefined;
};

type SendBoxPreviewParameters = BoxPreviewParameters & {
  readonly selectionId: string;
};

class BoxPreview extends AggregateRoot {
  public readonly boxPreviewId: string;
  public readonly boxId: string;
  public readonly looks: LookList;

  public constructor({ boxPreviewId, boxId, looks }: BoxPreviewParameters) {
    super();

    this.boxPreviewId = boxPreviewId;
    this.boxId = boxId;
    this.looks = new LookList(looks);
  }

  public static send({ boxPreviewId, boxId, selectionId, looks }: SendBoxPreviewParameters): BoxPreview {
    const instance = new BoxPreview({ boxPreviewId, boxId, looks });
    instance.record(new BoxPreviewSent({ boxPreviewId, boxId, selectionId }));

    return instance;
  }
}

export default BoxPreview;
