const CUSTOMER_I18N_PREFIX = "labs.customer.";
const PRICE_EXPERIMENT_I18N_PREFIX = `${CUSTOMER_I18N_PREFIX}ideal.prices.`;

enum CustomerI18n {
  WORK = "work",
  COMMENT_GENERAL_CURRENT = "comment.general.current",
  COMMENT_GENERAL_PREVIOUS = "comment.general.previous",
  COMMENT_CHECKOUT = "comment.checkout",
  COMMENT_CUSTOMER_SERVICE = "comment.atc",
  CHARACTERISTICS_TITLE = "characteristics.title",
  MEASUREMENTS = "measurements",
  SIZES_UP_BO_FOOT = "sizes_up_bo_foot",
  BRA = "braSize",
  SILHOUETTE = "silhouette.title",
  STYLE_TITLE = "style.title",
  STYLE_DEFINED = "style.defined",
  STYLE_ADD = "style.add",
  LEISURE_WORK_MODAL_TITLE = "leisure_work.modal.title",
  STYLE_MODAL_TITLE = "style.modal.title",
  FOCUS = "focus",
  IDEAL_FOCUS_WORK = "ideal.focus.work",
  IDEAL_FOCUS_LEISURE = "ideal.focus.leisure",
  IDEAL_FOCUS_EVENING = "ideal.focus.evening",
  PREFERENCES_TITLE = "preferences.title",
  PRICE = "price",
  AVOID = "avoid",
  FOOTWEAR_EXTRA_INFO = "footwearExtraInfo",
  FORECAST_TEXT = "forecast.text",
  PHOTOS_MODAL_TITLE = "photos.modal.title",
  SHOW_LOOK_AND_LIKE_BUTTON = "show_look_and_like.button",
}

export { CUSTOMER_I18N_PREFIX, PRICE_EXPERIMENT_I18N_PREFIX, CustomerI18n };
