import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { BasicBoxProjection } from "./box";

const LIST_BOXES_BY_CUSTOMER_ID = "list_boxes_by_customer_id";

interface ListBoxesByCustomerIdPayload {
  readonly customerId: string;
}

interface ListBoxesByCustomerId extends Query<typeof LIST_BOXES_BY_CUSTOMER_ID>, ListBoxesByCustomerIdPayload {}

interface ListBoxesByCustomerIdFunction {
  (payload: ListBoxesByCustomerIdPayload): ListBoxesByCustomerId;
}

const listBoxesByCustomerId: ListBoxesByCustomerIdFunction = (payload) => ({
  ...query({ name: LIST_BOXES_BY_CUSTOMER_ID }),
  ...payload,
});

type ListBoxesByCustomerIdResult = BasicBoxProjection[] | null;

interface BoxesByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface BoxesByCustomerIdView {
  (args: BoxesByCustomerIdViewArgs): Promise<ListBoxesByCustomerIdResult>;
}

interface ListBoxesByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: BoxesByCustomerIdView;
}

const listBoxesByCustomerIdHandler: QueryHandlerFunction<
  ListBoxesByCustomerId,
  ListBoxesByCustomerIdResult,
  ListBoxesByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { BoxesByCustomerIdView, ListBoxesByCustomerId, ListBoxesByCustomerIdResult };
export { LIST_BOXES_BY_CUSTOMER_ID, listBoxesByCustomerId, listBoxesByCustomerIdHandler };
