import { Language } from "../../../../domain/country/model/language";
import Locale, { DEFAULT_LOCALE_FOR_LANGUAGE, isValidLocale, LOCALE } from "../../../../domain/country/model/Locale";

type TranslationProject = "labs" | "inventory-catalog" | "checkout" | "style-profile";

interface EndpointFunction {
  (locale: string): string;
}

interface TranslationExternalEndpointFunctionArgs {
  readonly translationsUrl: string;
  readonly projects: [project: TranslationProject, filter?: string][];
  readonly defaultLocale: Locale;
}

interface TranslationExternalEndpointFunction {
  (args: TranslationExternalEndpointFunctionArgs): EndpointFunction;
}

const translationExternalEndpoint: TranslationExternalEndpointFunction =
  ({ translationsUrl, projects, defaultLocale }) =>
  (locale) => {
    const projectsQueryParam = projects
      .map(([project, filter]) => `projectFilter=${project}${filter ? `:${filter}` : ""}`)
      .join("&");

    const newLocale: Locale = !isValidLocale(locale as Locale)
      ? DEFAULT_LOCALE_FOR_LANGUAGE[locale as Language] || defaultLocale
      : (locale as Locale);

    const [language, country] = LOCALE[newLocale];

    return `${translationsUrl}/${language}/${country}?${projectsQueryParam}`;
  };

export type { TranslationProject };
export { translationExternalEndpoint };
