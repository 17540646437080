import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isNoteTemplateWritten } from "../../../../domain/noteTemplate/model/noteTemplateWritten";
import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import {
  ViewNoteTemplateByIdResult,
  viewNoteTemplateById,
} from "../../../../projection/noteTemplate/viewNoteTemplateById";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { isNoteTemplateRemoved } from "../../../../domain/noteTemplate/model/noteTemplateRemoved";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isNoteTemplateWritten(event) || isNoteTemplateRemoved(event);

interface UseViewNoteTemplateByIdFunctionArgs {
  readonly id: string | undefined;
}

interface UseViewNoteTemplateByIdFunction {
  (args: UseViewNoteTemplateByIdFunctionArgs): UseQueryFunctionResult<ViewNoteTemplateByIdResult>;
}

const useViewNoteTemplateById: UseViewNoteTemplateByIdFunction = ({ id }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewNoteTemplateById({ id: id as string }),
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: id !== undefined,
    },
  });

export { useViewNoteTemplateById };
