import { FC } from "react";
import { ProductVariantWithProductInfo as ProductVariantWithProductInfoTemplate } from "../../componentLibrary/organisms/productVariantWithProductInfo/ProductVariantWithProductInfo";
import { ProductVariantWithProductInfoSkeleton } from "../../componentLibrary/organisms/productVariantWithProductInfo/ProductVariantWithProductInfoSkeleton";
import { useViewProductVariantWithProductInfoById } from "../../../core/infrastructure/projection/productVariantWithProductInfo/react/useViewProductVariantWithProductInfoById";

interface ProductVariantWithProductInfoProps {
  readonly productVariantId?: string;
}
const ProductVariantWithProductInfo: FC<ProductVariantWithProductInfoProps> = ({ productVariantId }) => {
  const [productVariantWithProductInfo] = useViewProductVariantWithProductInfoById({ productVariantId });

  if (!productVariantWithProductInfo) {
    return <ProductVariantWithProductInfoSkeleton />;
  }

  return <ProductVariantWithProductInfoTemplate productVariantWithProductInfo={productVariantWithProductInfo} />;
};

export { ProductVariantWithProductInfo };
