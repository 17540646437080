import { validate } from "uuid";
import {
  ReturnQuestionProjection,
  ReturnQuestionIcon,
} from "../../../../core/projection/returnQuestion/returnQuestion";
import { HistoryProductVariantFeedbackProjection } from "../../../../core/projection/historyBox/historyBox";

type Uuid = string;

const isUuid = (value: string): value is Uuid => validate(value);

interface RecursiveFeedbackForReturnQuestionFunctionArgs {
  readonly feedback: HistoryProductVariantFeedbackProjection;
  readonly returnQuestion: ReturnQuestionProjection;
  readonly translate: (i18nString: string) => string;
  readonly acc?: string[];
}

interface RecursiveFeedbackForReturnQuestionFunction {
  (args: RecursiveFeedbackForReturnQuestionFunctionArgs): string[];
}

const feedbackForReturnQuestion: RecursiveFeedbackForReturnQuestionFunction = ({
  feedback,
  returnQuestion,
  translate,
  acc = [],
}) => {
  const returnQuestionId = Object.keys(feedback).find((id) => id === returnQuestion.id);

  if (returnQuestionId) {
    const returnQuestionFeedback = feedback[returnQuestionId];

    if (isUuid(returnQuestionFeedback as string)) {
      const feebackReturnQuestionChild = returnQuestion.children?.find(
        (childReturnQuestion) => childReturnQuestion.id === returnQuestionFeedback,
      );

      if (feebackReturnQuestionChild) {
        return [
          ...acc,
          translate(feebackReturnQuestionChild?.name),
          ...feedbackForReturnQuestion({ feedback, returnQuestion: feebackReturnQuestionChild, translate, acc }),
        ];
      }

      return acc;
    }

    return [...acc, returnQuestionFeedback as string];
  }

  return (
    returnQuestion.children?.reduce(
      (acc, childReturnQuestion) => [
        ...acc,
        ...feedbackForReturnQuestion({ feedback, returnQuestion: childReturnQuestion, translate, acc }),
      ],
      [] as string[],
    ) || []
  );
};

interface RecursiveIconForReturnQuestionFunctionArgs {
  readonly feedback: HistoryProductVariantFeedbackProjection;
  readonly returnQuestion: ReturnQuestionProjection;
  readonly icon?: ReturnQuestionIcon;
}

interface RecursiveIconForReturnQuestionFunction {
  (args: RecursiveIconForReturnQuestionFunctionArgs): ReturnQuestionIcon | undefined;
}

const iconForReturnQuestion: RecursiveIconForReturnQuestionFunction = ({ feedback, returnQuestion, icon }) => {
  if (icon) {
    return icon;
  }

  const returnQuestionId = Object.keys(feedback).find((id) => id === returnQuestion.id);

  if (returnQuestionId) {
    const returnQuestionFeedback = feedback[returnQuestionId];

    if (isUuid(returnQuestionFeedback as string)) {
      const feebackReturnQuestionChild = returnQuestion.children?.find(
        (childReturnQuestion) => childReturnQuestion.id === returnQuestionFeedback,
      );

      if (feebackReturnQuestionChild) {
        return (
          feebackReturnQuestionChild.icon ||
          iconForReturnQuestion({ feedback, returnQuestion: feebackReturnQuestionChild, icon })
        );
      }

      return returnQuestion.icon;
    }

    return returnQuestion.icon;
  }

  return returnQuestion.children?.reduce((icon: ReturnQuestionIcon | undefined, childReturnQuestion) => {
    return icon || iconForReturnQuestion({ feedback, returnQuestion: childReturnQuestion, icon });
  }, undefined);
};

export { feedbackForReturnQuestion, iconForReturnQuestion };
