import { I18nMessage } from "@lookiero/i18n-react";
import { FC, Fragment } from "react";
import Locale from "../../../../../core/domain/country/model/Locale";
import FeatureUnit from "../../../../../core/domain/feature/model/FeatureUnit";
import Feature from "../../../../../core/projection/feature/model/Feature";
import FeatureValue from "../../../../../core/projection/feature/model/FeatureValue";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import ProductFeatureUnit from "./ProductFeatureUnit";
import "./product-feature.css";

const cmToInches = (cm: number): number => Math.round(cm * 0.3937 * 100) / 100;

type ProductFeatureValueProps = {
  readonly unit: FeatureUnit | null;
  readonly value: FeatureValue;
  readonly locale: Locale;
};
const ProductFeatureValue: FC<ProductFeatureValueProps> = ({ unit, value, locale }: ProductFeatureValueProps) => (
  <Text className="product-feature-value" variant={TextVariant.BODY_BOLD}>
    {unit === FeatureUnit.CENTIMETERS && locale === Locale.en_GB ? (
      cmToInches(parseFloat(value.value))
    ) : (
      <I18nMessage id={value.translationKey} />
    )}
    {unit && <ProductFeatureUnit locale={locale as Locale} unit={unit} />}
  </Text>
);

type ProductFeatureQualifierProps = {
  readonly qualifier?: Feature | null;
  readonly locale: Locale;
};
const ProductFeatureQualifier: FC<ProductFeatureQualifierProps> = ({
  qualifier,
  locale,
}: ProductFeatureQualifierProps) =>
  qualifier ? (
    <>
      &nbsp;
      {"("}
      {qualifier.values.map((value, i) => (
        <Fragment key={value.id}>
          {i > 0 && ", "}
          <ProductFeatureValue locale={locale} unit={qualifier.unit} value={value} />
        </Fragment>
      ))}
      {")"}
    </>
  ) : null;

type ProductFeatureProps = {
  readonly feature: Feature;
  readonly locale: Locale;
};
const ProductFeature: FC<ProductFeatureProps> = ({ feature, locale }: ProductFeatureProps) => (
  <>
    <Text variant={TextVariant.BODY}>
      <I18nMessage id={feature.translationKey} />
      :&nbsp;
    </Text>
    {feature.values.map((value, i) => (
      <Fragment key={value.id}>
        {i > 0 && ", "}
        <ProductFeatureValue locale={locale} unit={feature.unit} value={value} />
        <ProductFeatureQualifier locale={locale} qualifier={value.qualifier} />
      </Fragment>
    ))}
  </>
);

export default ProductFeature;
