import { FC, useCallback, useMemo } from "react";
import classNames from "classnames";
import Picture from "../../../../shared/ui/uiKit/atoms/picture/Picture";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import HistoryProductVariantSize from "../../molecules/historyProductVariantSize/HistoryProductVariantSize";
import Price from "../../atoms/price/Price";
import Sizing from "../../atoms/sizing/Sizing";
import cdnImageUrl from "../../../_util/cdnImageUrl";
import { mainImageFromMedia } from "../../../../core/projection/media/model/Media";
import { HistoryShopProductVariantFeedback } from "./historyProductVariantFeedback/HistoryShopProductVariantFeedback";
import { HistoryProductVariantFeedback } from "./historyProductVariantFeedback/HistoryProductVariantFeedback";
import { ReturnQuestionProjection } from "../../../../core/projection/returnQuestion/returnQuestion";
import { ReturnQuestionFeedbackProvider } from "../../../views/historyReturnQuestions/behaviors/useReturnQuestionFeedback";
import { HistoryProductVariantProjection } from "../../../../core/projection/historyBox/historyBox";
import { HistoryShopProductVariantProjection } from "../../../../core/projection/historyShop/historyShopProductVariant";
import "./history-product-variant.css";

const HISTORY_PRODUCT_VARIANT_WIDTH = 180;

const isHistoryShopProductVariant = (
  productVariant: HistoryProductVariantProjection | HistoryShopProductVariantProjection,
): productVariant is HistoryShopProductVariantProjection => productVariant.hasOwnProperty("boughtOn");

interface OnClickArgs {
  readonly productId: string;
  readonly productVariantId: string;
}
interface OnClick {
  (args: OnClickArgs): void;
}

interface HistoryProductVariantProps {
  readonly productVariant: HistoryProductVariantProjection | HistoryShopProductVariantProjection;
  readonly returnQuestions?: ReturnQuestionProjection[];
  readonly onClick: OnClick;
}

const HistoryProductVariant: FC<HistoryProductVariantProps> = ({ productVariant, returnQuestions, onClick }) => {
  const productVariantPictureSrc = cdnImageUrl({
    url: mainImageFromMedia(productVariant.media).url,
    width: HISTORY_PRODUCT_VARIANT_WIDTH,
  });

  const { stripReturnQuestions, bottomReturnQuestions } = useMemo(
    () =>
      returnQuestions
        ? returnQuestions.reduce(
            (acc, returnQuestion) => ({
              stripReturnQuestions: [
                ...acc.stripReturnQuestions,
                ...(returnQuestion.section === "strip" ? [returnQuestion] : []),
              ],
              bottomReturnQuestions: [
                ...acc.bottomReturnQuestions,
                ...(returnQuestion.section === "bottom" ? [returnQuestion] : []),
              ],
            }),
            {
              stripReturnQuestions: [] as ReturnQuestionProjection[],
              bottomReturnQuestions: [] as ReturnQuestionProjection[],
            },
          )
        : { stripReturnQuestions: [], bottomReturnQuestions: [] },
    [returnQuestions],
  );

  const handleOnClick = useCallback(
    () =>
      onClick?.({
        productId: productVariant.productId,
        productVariantId: productVariant.productVariantId,
      }),
    [onClick, productVariant.productId, productVariant.productVariantId],
  );

  return (
    <ReturnQuestionFeedbackProvider
      feedback={isHistoryShopProductVariant(productVariant) ? {} : productVariant.feedbacks || {}}
    >
      <article
        aria-label="history-product-variant"
        role="listitem"
        className={classNames("history-product-variant", {
          "history-product-variant--returned": !productVariant.kept,
        })}
        onClick={handleOnClick}
      >
        <section className="history-product-variant__image">
          <Picture
            aspectRatio={AspectRatio.R_4_5}
            className="history-product-variant__picture"
            label={productVariant.productId}
            src={productVariantPictureSrc}
            lazy
          />

          <div className="history-product-variant__iconography">
            {isHistoryShopProductVariant(productVariant) ? (
              <HistoryShopProductVariantFeedback productVariant={productVariant} />
            ) : (
              <HistoryProductVariantFeedback returnQuestions={stripReturnQuestions} />
            )}
          </div>
          <Price price={productVariant.price} />
          {!productVariant.kept && (
            <svg className="history-product-variant__returned-stroke" preserveAspectRatio="none" viewBox="0 0 100 100">
              <line vectorEffect="non-scaling-stroke" x1="0" x2="100" y1="0" y2="100" />
            </svg>
          )}
        </section>
        <section className="history-product-variant__details">
          <div className="history-product-variant__size">
            {productVariant.size && (
              <HistoryProductVariantSize
                size={productVariant.size}
                sizeChange={!isHistoryShopProductVariant(productVariant) ? productVariant.sizeChange : undefined}
              />
            )}
            <Sizing sizing={productVariant.sizing} />
          </div>
          {isHistoryShopProductVariant(productVariant) ? (
            productVariant.feedback &&
            productVariant.feedback.comment && (
              <div className="history-product-variant__comment">
                {productVariant.feedback.comment && (
                  <Tooltip title={productVariant.feedback.comment}>
                    <Icon variant={IconVariant.COMMENT} />
                  </Tooltip>
                )}
              </div>
            )
          ) : (
            <HistoryProductVariantFeedback
              className="history-product-variant__bottom-feedback"
              returnQuestions={bottomReturnQuestions}
            />
          )}
        </section>
      </article>
    </ReturnQuestionFeedbackProvider>
  );
};

export type { OnClick as OnClickHistoryProductVariant };
export { HistoryProductVariant };
