import { PersonalShopperFilteringProjection } from "../../../../projection/personalShopperFiltering/personalShopperFiltering";

interface FilteringValueDto {
  readonly id: string;
  readonly value?: string;
}

interface PersonalShopperFilteringDto {
  readonly id: string;
  readonly boxId: string;
  readonly customerId: string;
  readonly positives: FilteringValueDto[];
  readonly negatives: FilteringValueDto[];
  readonly personalShopperId: string;
}

interface ToPersonalShopperFilteringFunction {
  (personalShopperFilteringDto: PersonalShopperFilteringDto): PersonalShopperFilteringProjection;
}

const toPersonalShopperFiltering: ToPersonalShopperFilteringFunction = ({
  id,
  boxId,
  customerId,
  positives,
  negatives,
  personalShopperId,
}) => ({
  id,
  boxId,
  customerId,
  positives,
  negatives,
  psId: personalShopperId,
});

export type { PersonalShopperFilteringDto };
export { toPersonalShopperFiltering };
