import { command, Command } from "@lookiero/messaging";

const GENERATE_NOTE = "generate_note";

interface GenerateNotePayload {
  readonly aggregateId: string;
  readonly boxId: string;
  readonly psId: string;
}

interface GenerateNote extends Command<typeof GENERATE_NOTE>, GenerateNotePayload {}

interface GenerateNoteFunction {
  (payload: GenerateNotePayload): GenerateNote;
}

const generateNote: GenerateNoteFunction = ({ aggregateId, ...payload }) => ({
  ...command({ name: GENERATE_NOTE, aggregateId }),
  ...payload,
});

export type { GenerateNote };
export { GENERATE_NOTE, generateNote };
