import { FC, useState } from "react";
import useEnvironment from "../../../views/_behaviors/useEnvironment";
import { useListFamilies } from "../../../../core/infrastructure/projection/family/react/useListFamilies";
import LookAndLikeFeedbacksTemplate from "../../templates/lookAndLikeFeedbacks/LookAndLikeFeedbacks";
import { useCountLookAndLikeFeedbacksByCriteria } from "../../../../core/infrastructure/projection/lookAndLikeFeedback/react/useCountLookAndLikeFeedbacksByCriteria";
import { useSearchLookAndLikeFeedbacksByCriteria } from "../../../../core/infrastructure/projection/lookAndLikeFeedback/react/useSearchLookAndLikeFeedbacksByCriteria";

const normalizeFamilyIds = (familyIds: string | string[] | null | undefined): string[] =>
  familyIds ? (Array.isArray(familyIds) ? familyIds : [familyIds]) : [];

interface LookAndLikeProps {
  readonly customerId: string;
  readonly familyIds?: string[];
  readonly onClickProductVariant: (productVariantId: string) => void;
}

const LookAndLike: FC<LookAndLikeProps> = ({ customerId, familyIds: familyIdsFilter, onClickProductVariant }) => {
  const {
    lookAndLikeFeedback: { perPage },
  } = useEnvironment();
  const [families] = useListFamilies();

  const [likesPage, setLikesPage] = useState(1);
  const [dislikesPage, setDislikesPage] = useState(1);

  const [familyIds, setFamilyIds] = useState<string | string[] | null | undefined>(familyIdsFilter);
  const normalizedFamilyIds = normalizeFamilyIds(familyIds);

  const [likesCount] = useCountLookAndLikeFeedbacksByCriteria({
    itemCountWhileQuerying: perPage,
    customerId,
    familyIds: normalizedFamilyIds,
    response: true,
  });

  const [likes] = useSearchLookAndLikeFeedbacksByCriteria({
    customerId,
    familyIds: normalizedFamilyIds,
    response: true,
    page: likesPage,
    perPage,
  });

  const [dislikesCount] = useCountLookAndLikeFeedbacksByCriteria({
    itemCountWhileQuerying: perPage,
    customerId,
    familyIds: normalizedFamilyIds,
    response: false,
  });

  const [dislikes] = useSearchLookAndLikeFeedbacksByCriteria({
    customerId,
    familyIds: normalizedFamilyIds,
    response: false,
    page: dislikesPage,
    perPage,
  });

  return (
    <LookAndLikeFeedbacksTemplate
      dislikes={dislikes}
      dislikesCount={dislikesCount}
      dislikesPage={dislikesPage}
      dislikesPerPage={perPage}
      families={families}
      familyIds={familyIds}
      likes={likes}
      likesCount={likesCount}
      likesPage={likesPage}
      likesPerPage={perPage}
      onClickProductVariant={onClickProductVariant}
      onDislikesPageChanged={setDislikesPage}
      onFamilyIdsChanged={setFamilyIds}
      onLikesPageChanged={setLikesPage}
    />
  );
};

export { LookAndLike };
