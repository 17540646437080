import { FC, ReactNode } from "react";
import TooltipComponent, { TooltipProps as CustomTooltipComponentProps } from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import Text, { TextVariant } from "../text/Text";
import classNames from "classnames";
import useCaptureAndStopEvent from "../../_behaviors/useCaptureAndStopEvent/useCaptureAndStopEvent";
import "./tooltip.css";

const CustomTooltipComponent = withStyles(() => ({
  tooltip: {
    backgroundColor: "#0c0f1c",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 3,
  },
  arrow: {
    color: "#0c0f1c",
  },
}))(TooltipComponent);

interface TooltipProps extends Omit<CustomTooltipComponentProps, "title" | "children"> {
  readonly title?: ReactNode;
  readonly children: ReactNode;
  readonly className?: string;
  readonly titleClassName?: string;
  readonly contentClassName?: string;
}

const Tooltip: FC<TooltipProps> = ({
  title,
  children,
  className,
  titleClassName,
  contentClassName,
  placement = "bottom",
  ...props
}) => {
  const handleOnClick = useCaptureAndStopEvent(() => void 0);

  return (
    <div className={classNames("tooltip", className)} onClick={handleOnClick}>
      <CustomTooltipComponent
        {...props}
        placement={placement}
        title={
          <Text className={classNames("tooltip__title", titleClassName)} variant={TextVariant.BODY_SMALL}>
            {title}
          </Text>
        }
        interactive
      >
        <div className={classNames("tooltip__content", contentClassName)}>{children}</div>
      </CustomTooltipComponent>
    </div>
  );
};

export default Tooltip;
