import { NavigatorSendBeaconFunction } from "./navigator";

interface WindowNavigatorSendBeaconFunctionArgs {
  readonly apiUrl: () => string;
}

interface WindowNavigatorSendBeaconFunction {
  (args: WindowNavigatorSendBeaconFunctionArgs): NavigatorSendBeaconFunction;
}

const windowNavigatorSendBeacon: WindowNavigatorSendBeaconFunction =
  ({ apiUrl }) =>
  ({ endpoint, body }) =>
    window.navigator.sendBeacon(`${apiUrl()}${endpoint}`, JSON.stringify(body));

export { windowNavigatorSendBeacon };
