import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import LookAndLikeFeedbacksList from "./lookAndLikeFeedbacksList/LookAndLikeFeedbacksList";
import LookAndLikeFeedbackItem from "./lookAndLikeFeedbackItem/LookAndLikeFeedbackItem";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import Dropdown from "../../../../shared/ui/uiKit/organisms/dropdown/Dropdown";
import LookAndLikeFeedbackItemSkeleton from "./lookAndLikeFeedbackItem/LookAndLikeFeedbackItemSkeleton";
import LookAndLikeFeedbacks18n, { LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX } from "../../_i18n/LookAndLikeFeedbacksI18n";
import { FamilyProjection } from "../../../../core/projection/family/family";
import { LookAndLikeFeedbackProjection } from "../../../../core/projection/lookAndLikeFeedback/lookAndLikeFeedback";
import "./look-and-like-feedbacks.css";

interface LookAndLikeFeedbacksProps {
  readonly families: FamilyProjection[] | undefined;
  readonly familyIds: string | string[] | null | undefined;
  readonly onFamilyIdsChanged: (familyIds: string | string[] | null | undefined) => void;
  readonly likes: Record<number, LookAndLikeFeedbackProjection[]>;
  readonly likesCount: number;
  readonly likesPage: number;
  readonly likesPerPage: number;
  readonly onLikesPageChanged: (page: number) => void;
  readonly dislikes: Record<number, LookAndLikeFeedbackProjection[]>;
  readonly dislikesCount: number;
  readonly dislikesPage: number;
  readonly dislikesPerPage: number;
  readonly onDislikesPageChanged: (page: number) => void;
  readonly onClickProductVariant: (productVariantId: string) => void;
}

const LookAndLikeFeedbacks: FC<LookAndLikeFeedbacksProps> = ({
  families,
  familyIds,
  onFamilyIdsChanged,
  likes,
  likesCount,
  likesPage,
  likesPerPage,
  dislikes,
  dislikesCount,
  dislikesPage,
  dislikesPerPage,
  onLikesPageChanged,
  onDislikesPageChanged,
  onClickProductVariant,
}) => {
  const feedbackItem = useMemo(
    () =>
      (index: number, feedback: LookAndLikeFeedbackProjection | null): ReactNode =>
        feedback ? (
          feedback.id ? (
            <LookAndLikeFeedbackItem key={feedback.id} feedback={feedback} onClick={onClickProductVariant} />
          ) : (
            <LookAndLikeFeedbackItemSkeleton key={index} />
          )
        ) : (
          <div key={index} className="look-and-like-feedbacks__empty-placeholder" />
        ),
    [onClickProductVariant],
  );

  const familyOptions = useMemo(
    () => (families ? families.map((family) => ({ value: family.id, label: family.name })) : []),
    [families],
  );

  const feedbacksRef = useRef<HTMLDivElement>(null);
  const [scrollElement, setScrollElement] = useState<HTMLDivElement>();
  useEffect(() => {
    if (!feedbacksRef.current) {
      return;
    }

    setScrollElement(feedbacksRef.current);
  }, []);

  return (
    <section aria-label="look-and-like-feedbacks" className="look-and-like-feedbacks">
      <header className="look-and-like-feedbacks__header">
        <Text className="look-and-like-feedbacks__title" variant={TextVariant.HEADING_3}>
          <I18nMessage id={LookAndLikeFeedbacks18n.HEADER_TITLE} prefix={LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX} />
        </Text>
        <Dropdown label="Familia" options={familyOptions} value={familyIds} isMulti onChange={onFamilyIdsChanged} />
      </header>
      <div ref={feedbacksRef} className="look-and-like-feedbacks__summary">
        <div aria-label="look-and-like-feedbacks-like" className="look-and-like-feedbacks__list" role="list">
          {/* We have to defer mounting the VirtualizedList until the ref is properly bound 
          due to a bug in the undelying library's _registerScrollListener_ (multiple lists bound to the same ref) */}
          {likesCount > 0 && scrollElement ? (
            <LookAndLikeFeedbacksList
              feedbacks={likes}
              itemCount={likesCount}
              page={likesPage}
              perPage={likesPerPage}
              scrollElement={scrollElement}
              title={
                <I18nMessage id={LookAndLikeFeedbacks18n.LIKES_TITLE} prefix={LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX} />
              }
              onPageChanged={onLikesPageChanged}
            >
              {feedbackItem}
            </LookAndLikeFeedbacksList>
          ) : (
            <>
              <Text variant={TextVariant.HEADING_3}>
                <I18nMessage id={LookAndLikeFeedbacks18n.LIKES_TITLE} prefix={LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX} />
              </Text>
              <p>
                <I18nMessage id={LookAndLikeFeedbacks18n.NO_FEEDBACK} prefix={LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX} />
              </p>
            </>
          )}
        </div>
        <div aria-label="look-and-like-feedbacks-dislike" className="look-and-like-feedbacks__list" role="list">
          {/* We have to defer mounting the VirtualizedList until the ref is properly bound 
          due to a bug in the undelying library's _registerScrollListener_ (multiple lists bound to the same ref) */}
          {dislikesCount > 0 && scrollElement ? (
            <LookAndLikeFeedbacksList
              feedbacks={dislikes}
              itemCount={dislikesCount}
              page={dislikesPage}
              perPage={dislikesPerPage}
              scrollElement={scrollElement}
              title={
                <I18nMessage id={LookAndLikeFeedbacks18n.DISLIKES_TITLE} prefix={LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX} />
              }
              onPageChanged={onDislikesPageChanged}
            >
              {feedbackItem}
            </LookAndLikeFeedbacksList>
          ) : (
            <>
              <Text variant={TextVariant.HEADING_3}>
                <I18nMessage id={LookAndLikeFeedbacks18n.DISLIKES_TITLE} prefix={LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX} />
              </Text>
              <p>
                <I18nMessage id={LookAndLikeFeedbacks18n.NO_FEEDBACK} prefix={LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX} />
              </p>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default LookAndLikeFeedbacks;
