import { FC } from "react";
import { useIntl } from "react-intl";
import { I18nMessage } from "@lookiero/i18n-react";
import Locale from "../../../../core/domain/country/model/Locale";
import Feature from "../../../../core/projection/feature/model/Feature";
import Model from "../../../../core/projection/model/model/Model";
import ShortlistI18n, { SHORTLIST_I18N_PREFIX } from "../../_i18n/ShortlistI18n";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import ProductFeaturesList from "./productFeaturesList/ProductFeaturesList";
import ProductModelMorphology from "./productModelMorphology/ProductModelMorphology";
import "./product-features.css";

type ProductFeaturesProps = {
  readonly description: Feature[];
  readonly characteristics: Feature[];
  readonly others: Feature[];
  readonly model?: Model;
};
const ProductFeatures: FC<ProductFeaturesProps> = ({
  description,
  characteristics,
  others,
  model,
}: ProductFeaturesProps) => {
  const { locale } = useIntl();

  return (
    <section className="product-features">
      <ProductFeaturesList features={description} locale={locale as Locale}>
        <Text aria-label="product-description-content" variant={TextVariant.BODY_BOLD}>
          <I18nMessage id={ShortlistI18n.PRODUCT_DESCRIPTION} prefix={SHORTLIST_I18N_PREFIX} />
        </Text>
      </ProductFeaturesList>
      <ProductFeaturesList features={characteristics} locale={locale as Locale}>
        <Text aria-label="product-characteristics-content" variant={TextVariant.BODY_BOLD}>
          <I18nMessage id={ShortlistI18n.PRODUCT_CHARACTERISTICS} prefix={SHORTLIST_I18N_PREFIX} />
        </Text>
      </ProductFeaturesList>
      <ProductModelMorphology model={model}>
        <Text aria-label="model-morphology-content" variant={TextVariant.BODY_BOLD}>
          <I18nMessage id={ShortlistI18n.MODEL_MORPHOLOGY} prefix={SHORTLIST_I18N_PREFIX} />
        </Text>
      </ProductModelMorphology>
      <ProductFeaturesList features={others} locale={locale as Locale}>
        <Text aria-label="product-others-content" variant={TextVariant.BODY_BOLD}>
          <I18nMessage id={ShortlistI18n.PRODUCT_OTHERS} prefix={SHORTLIST_I18N_PREFIX} />
        </Text>
      </ProductFeaturesList>
    </section>
  );
};

export default ProductFeatures;
