import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import {
  ListNoteAlertsByBoxNumberResult,
  NoteAlertsByBoxNumberView,
} from "../../../projection/alert/listNoteAlertsByBoxNumber";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { NoteAlertDto } from "./alert";

interface HttpNoteAlertsByBoxNumberView extends NoteAlertsByBoxNumberView {}

interface HttpNoteAlertsByBoxNumberViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpNoteAlertsByBoxNumberViewFunction {
  (args: HttpNoteAlertsByBoxNumberViewFunctionArgs): HttpNoteAlertsByBoxNumberView;
}

interface ViewNoteAlertsByBoxNumberResponse {
  readonly result: NoteAlertDto[];
}

const httpNoteAlertsByBoxNumberView: HttpNoteAlertsByBoxNumberViewFunction =
  ({ httpPost }) =>
  async ({ boxNumber, signal }) =>
    await httpPost<ViewNoteAlertsByBoxNumberResponse, ListNoteAlertsByBoxNumberResult>({
      endpoint: "/list-note-alerts-by-box-number",
      body: { box_number: boxNumber },
      signal,
      result: {
        error: null,
        success: (response) => camelcaseKeys(response.result),
      },
    });

export { httpNoteAlertsByBoxNumberView };
