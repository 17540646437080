import { useLayoutEffect } from "react";

const useLockBodyScroll = (active: boolean): void => {
  useLayoutEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    }

    return (): void => {
      document.body.style.overflow = "auto";
    };
  }, [active]);
};

export default useLockBodyScroll;
