import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { UpdateUiSetting } from "../command/updateUiSetting";
import { uiSettingUpdated } from "./uiSettingUpdated";

interface UiSetting extends AggregateRoot {
  readonly key: string;
  readonly value: unknown;
}

const updateUiSettingHandler: CommandHandlerFunction<UpdateUiSetting, UiSetting> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, key, value } = command;

    return {
      ...aggregateRoot,
      key,
      value,
      domainEvents: [uiSettingUpdated({ aggregateId, key })],
    };
  };

export type { UiSetting };
export { updateUiSettingHandler };
