import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import {
  BoxPreviewByBoxIdView,
  ViewBoxPreviewByBoxIdResult,
} from "../../../projection/boxPreview/viewBoxPreviewByBoxId";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { BoxPreviewDto, toBoxPreviewProjection } from "./boxPreview";

interface HttpBoxPreviewByBoxIdView extends BoxPreviewByBoxIdView {}

interface HttpBoxPreviewByBoxIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxPreviewByBoxIdViewFunction {
  (args: HttpBoxPreviewByBoxIdViewFunctionArgs): HttpBoxPreviewByBoxIdView;
}

interface ViewBoxPreviewByBoxIdResponse {
  readonly result: BoxPreviewDto;
}

const httpBoxPreviewByBoxIdView: HttpBoxPreviewByBoxIdViewFunction =
  ({ httpPost }) =>
  async ({ boxId, signal }) =>
    await httpPost<ViewBoxPreviewByBoxIdResponse, ViewBoxPreviewByBoxIdResult>({
      endpoint: "/view-box-preview-by-box-id",
      body: { box_id: boxId },
      signal,
      result: {
        error: null,
        success: (response) => toBoxPreviewProjection(camelcaseKeys(response.result)),
      },
    });

export { httpBoxPreviewByBoxIdView };
