import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { viewPersonalShopper } from "./viewPersonalShopper";
import { PersonalShopperProjection } from "./personalShopper";
import PersonalShopperRole from "../../domain/personalShopper/model/PersonalShopperRole";
import {
  nonNoteWritableBoxStatuses,
  nonNoteWritableBoxStatusesForEditorRole,
} from "../../domain/box/model/NonNoteWritableBoxStatuses";
import { viewBoxById } from "../box/viewBoxById";
import { BoxProjection } from "../box/box";
import BoxStatus from "../../domain/box/model/BoxStatus";

interface CanWriteNoteFunctionArgs {
  readonly roles: PersonalShopperRole[];
  readonly status: BoxStatus;
}
interface CanWriteNoteFunction {
  (args: CanWriteNoteFunctionArgs): boolean;
}
const canWriteNote: CanWriteNoteFunction = ({ roles, status }) => {
  const nonValidStatuses = roles.includes(PersonalShopperRole.CAN_EDIT_NOTE)
    ? nonNoteWritableBoxStatusesForEditorRole
    : nonNoteWritableBoxStatuses;

  return !nonValidStatuses.includes(status);
};

const CAN_PERSONAL_SHOPPER_WRITE_NOTE = "can_personal_shopper_write_note";

interface CanPersonalShopperWriteNotePayload {
  readonly boxId: string;
}

interface CanPersonalShopperWriteNote
  extends Query<typeof CAN_PERSONAL_SHOPPER_WRITE_NOTE>,
    CanPersonalShopperWriteNotePayload {}

interface CanPersonalShopperWriteNoteFunction {
  (payload: CanPersonalShopperWriteNotePayload): CanPersonalShopperWriteNote;
}

const canPersonalShopperWriteNote: CanPersonalShopperWriteNoteFunction = (payload) => ({
  ...query({ name: CAN_PERSONAL_SHOPPER_WRITE_NOTE }),
  ...payload,
});

type CanPersonalShopperWriteNoteResult = boolean;

interface CanPersonalShopperWriteNoteHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const canPersonalShopperWriteNoteHandler: QueryHandlerFunction<
  CanPersonalShopperWriteNote,
  CanPersonalShopperWriteNoteResult,
  CanPersonalShopperWriteNoteHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ boxId }) => {
    const box: BoxProjection | null = await queryBus(viewBoxById({ id: boxId }));

    if (!box) {
      return false;
    }

    const personalShopper: PersonalShopperProjection | null = await queryBus(viewPersonalShopper());

    if (!personalShopper) {
      return false;
    }

    return canWriteNote({ roles: personalShopper.roles, status: box.status });
  };

export type { CanPersonalShopperWriteNote, CanPersonalShopperWriteNoteResult };
export {
  CAN_PERSONAL_SHOPPER_WRITE_NOTE,
  canPersonalShopperWriteNote,
  canPersonalShopperWriteNoteHandler,
  canWriteNote,
};
