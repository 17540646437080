import { isProductVariantMarkedAsCandidate } from "../../../../domain/selection/model/productVariantMarkedAsCandidate";
import { HttpSelectionsSaveFunction } from "./httpSelections";

interface HttpSelectionsMarkProductVariantAsCandidateFunction extends HttpSelectionsSaveFunction {}

const httpSelectionsMarkProductVariantAsCandidate: HttpSelectionsMarkProductVariantAsCandidateFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const productVariantMarkedAsCandidate = domainEvents.find(isProductVariantMarkedAsCandidate);

    if (!productVariantMarkedAsCandidate) {
      return;
    }

    await httpPost({
      endpoint: "/mark-product-variant-as-candidate",
      body: {
        selection_id: aggregateId,
        product_variant_id: productVariantMarkedAsCandidate.productVariantId,
      },
    });
  };

export { httpSelectionsMarkProductVariantAsCandidate };
