enum Country {
  GB = "GB",
  ES = "ES",
  PT = "PT",
  FR = "FR",
  BE = "BE",
  LU = "LU",
  IT = "IT",
  NL = "NL",
  DE = "DE",
  AT = "AT",
  SE = "SE",
  CH = "CH",
}

export default Country;
