import React, { createContext, FC, ReactNode, useContext } from "react";
import { invariant } from "ts-invariant";
import { Tracker } from "../Tracker";

const TrackerContext = createContext<Tracker | undefined>(null as unknown as Tracker);

interface TrackerContextProviderProps {
  readonly tracker?: Tracker;
  readonly children: ReactNode;
}

const TrackerProvider: FC<TrackerContextProviderProps> = ({ tracker, children }) => (
  <TrackerContext.Provider value={tracker}>{children}</TrackerContext.Provider>
);

const useTracker = (): Tracker | undefined => {
  const tracker = useContext<Tracker | undefined>(TrackerContext);

  invariant(
    tracker !== null,
    "Your are trying to use the useTracker hook without wrapping your app with the <TrackerProvider>.",
  );

  return tracker;
};

export { useTracker, TrackerProvider };
