import Country from "../../../domain/country/model/Country";
import { PendingBoxProjection } from "../../../projection/pendingBox/pendingBox";

interface PendingBoxDto {
  readonly country: string;
  readonly pending_boxes: number;
}

interface ToPendingBoxProjectionFunction {
  (pendingBoxDto: PendingBoxDto): PendingBoxProjection;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const toPendingBoxProjection: ToPendingBoxProjectionFunction = ({ country, pending_boxes }) => ({
  country: country as Country,
  pendingBoxes: pending_boxes,
});

export type { PendingBoxDto };
export { toPendingBoxProjection };
