import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { FavouritesGetFunction, FavouritesSaveFunction } from "../../../../domain/favourite/model/favourites";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpProductMarkedAsFavourite } from "./httpProductMarkedAsFavourite";
import { httpProductUnmarkedAsFavourite } from "./httpProductUnmarkedAsFavourite";
import { FavouriteProjection } from "../../../../projection/favourite/favourite";
import { Favourite } from "../../../../domain/favourite/model/favourite";
import {
  ViewFavouriteById,
  ViewFavouriteByIdResult,
  viewFavouriteById,
} from "../../../../projection/favourite/viewFavouriteById";
import invariant from "ts-invariant";

interface ToDomainFunctionArgs {
  readonly favourite: FavouriteProjection | null;
}

interface ToDomainFunction {
  (args: ToDomainFunctionArgs): Favourite;
}

const toDomain: ToDomainFunction = ({ favourite }) => {
  invariant(favourite, "Favourite does not exist");

  return {
    aggregateId: favourite.id,
    psId: favourite.psId,
    productId: favourite.productId,
    isEnabled: favourite.isEnabled,
    domainEvents: [],
  };
};

interface HttpFavouritesGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpFavouritesGetFunction extends FavouritesGetFunction<HttpFavouritesGetFunctionArgs> {}

const getFavourite: HttpFavouritesGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain({
      favourite: await queryBus<ViewFavouriteById, ViewFavouriteByIdResult>(viewFavouriteById({ id: aggregateId })),
    });

interface HttpFavouritesSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpFavouritesSaveFunction extends FavouritesSaveFunction<HttpFavouritesSaveFunctionArgs> {}

const saveFavourite: HttpFavouritesSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([
      await httpProductMarkedAsFavourite({ httpPost })(aggregateRoot),
      await httpProductUnmarkedAsFavourite({ httpPost })(aggregateRoot),
    ]);
  };

export type { HttpFavouritesSaveFunction };
export { getFavourite, saveFavourite };
