import { FC } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../_i18n/HistoryI18n";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Text from "../../../../shared/ui/uiKit/atoms/text/Text";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import "./assigned-ps.css";

type AssignedPsProps = {
  readonly legacyId: number;
};
const AssignedPs: FC<AssignedPsProps> = ({ legacyId }: AssignedPsProps) => {
  const assignedPsMessage = useI18nMessage({ id: HistoryI18n.BOX_ASSIGNED_PS, prefix: HISTORY_I18N_PREFIX });

  return (
    <div className="assigned-ps">
      <Tooltip title={assignedPsMessage}>
        <div className="assigned-ps__content">
          <Icon variant={IconVariant.USER} />
          <Text>{legacyId}</Text>
        </div>
      </Tooltip>
    </div>
  );
};

export default AssignedPs;
