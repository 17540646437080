import { ProcessManagerFunction } from "@lookiero/messaging";
import {
  ViewSelectionOrderByBoxNumberResult,
  viewSelectionOrderByBoxNumber,
} from "../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";
import { ProductVariantReplaced } from "../../selection/model/ProductVariantReplaced";
import { sortSelectionOrder } from "../command/sortSelectionOrder";

interface SortSelectionWhenProductVariantReplacedFunction extends ProcessManagerFunction<ProductVariantReplaced> {}

const sortSelectionWhenProductVariantReplaced: SortSelectionWhenProductVariantReplacedFunction =
  ({ commandBus, queryBus }) =>
  async ({ aggregateId, boxNumber, productVariantIds, selectedProductVariantId, deselectedProductVariantId }) => {
    const selectionOrder: ViewSelectionOrderByBoxNumberResult = await queryBus(
      viewSelectionOrderByBoxNumber({ boxNumber }),
    );

    await commandBus(
      sortSelectionOrder({
        aggregateId,
        boxNumber,
        productVariantIds: selectionOrder
          ? selectionOrder.productVariantIds.map((productVariantId: string) =>
              productVariantId === deselectedProductVariantId ? selectedProductVariantId : productVariantId,
            )
          : productVariantIds,
      }),
    );
  };

export { sortSelectionWhenProductVariantReplaced };
