import Cookies from "js-cookie";
import Storage from "../../../../storage/domain/model/Storage";

class CookieStorage implements Storage<string> {
  public async read(key: string): Promise<string | undefined> {
    return await Cookies.get(key);
  }

  public async write(
    key: string,
    value: string,
    { expires, domain }: { expires: number; domain: string },
  ): Promise<void> {
    Cookies.set(key, value, { expires, domain });
  }

  public async remove(key: string): Promise<void> {
    Cookies.remove(key);
  }
}

export default CookieStorage;
