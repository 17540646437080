import { useCallback, useEffect, useRef } from "react";

type UsePageVisibilityParameters = {
  readonly onChanged: (isVisible: boolean) => void;
};
const usePageVisibility = ({ onChanged }: UsePageVisibilityParameters): void => {
  const onChangedRef = useRef(onChanged);
  onChangedRef.current = onChanged;
  const previousIsVisibleRef = useRef(!Boolean(document.hidden));

  const notifyVisibilityChanged = useCallback((isVisible: boolean) => {
    if (previousIsVisibleRef.current === isVisible) {
      return;
    }

    previousIsVisibleRef.current = isVisible;
    onChangedRef.current(isVisible);
  }, []);

  const handleVisibilityChange = useCallback(
    () => notifyVisibilityChanged(!Boolean(document.hidden)),
    [notifyVisibilityChanged],
  );
  const handleOnBeforeUnload = useCallback(() => notifyVisibilityChanged(false), [notifyVisibilityChanged]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange, false);
    window.addEventListener("beforeunload", handleOnBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleOnBeforeUnload);
    };
  }, [handleOnBeforeUnload, handleVisibilityChange]);
};

export { usePageVisibility };
