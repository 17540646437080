import {
  CountShortlistProductsByShortlistFiltersResult,
  ShortlistProductsCountByShortlistFiltersView,
} from "../../../projection/shortlist/countShortlistProductsByShortlistFilters";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpShortlistProductsCountByShortlistFiltersView extends ShortlistProductsCountByShortlistFiltersView {}

interface HttpShortlistProductsCountByShortlistFiltersViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpShortlistProductsCountByShortlistFiltersViewFunction {
  (
    args: HttpShortlistProductsCountByShortlistFiltersViewFunctionArgs,
  ): HttpShortlistProductsCountByShortlistFiltersView;
}

interface CountShortlistProductsByShortlistFiltersResponse {
  readonly result: number;
}

const httpShortlistProductsCountByShortlistFiltersView: HttpShortlistProductsCountByShortlistFiltersViewFunction =
  ({ httpPost }) =>
  async ({ criteria: { psId, boxNumber, search, favourites, shortlistFilters, negativeFilters }, signal }) =>
    await httpPost<CountShortlistProductsByShortlistFiltersResponse, CountShortlistProductsByShortlistFiltersResult>({
      endpoint: "/count-shortlist-products-by-shortlist-filters",
      body: {
        psId,
        boxNumber,
        search,
        favourites,
        filters: shortlistFilters,
        negativeFilters,
      },
      signal,
      result: {
        error: 0,
        success: ({ result }) => result,
      },
    });

export { httpShortlistProductsCountByShortlistFiltersView };
