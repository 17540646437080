import { FC, ReactNode } from "react";
import classNames from "classnames";
import Text, { TextVariant } from "../../atoms/text/Text";
import Button from "../../atoms/button/Button";
import { IconVariant } from "../../atoms/icon/Icon";
import { useLayer } from "../../_behaviors/useLayers/useLayers";
import "./modal.css";

interface ModalProps {
  readonly visible: boolean;
  readonly children: ReactNode;
  readonly onHide: () => void;
  readonly className?: string;
  readonly label?: string;
  readonly title?: ReactNode;
}
const Modal: FC<ModalProps> = ({ className, label, visible, title, children, onHide }: ModalProps) => {
  const { Layer, viewRef } = useLayer({ onHide });

  return (
    <Layer visible={visible}>
      <div aria-label={label} className={classNames("modal", className)} role="alert">
        <div ref={viewRef} className="modal__modal">
          <header className={classNames("modal__modal-header", { "modal__modal-header--has-title": Boolean(title) })}>
            {title && (
              <Text className="modal__modal-title" variant={TextVariant.BODY_BOLD}>
                {title}
              </Text>
            )}
            {onHide && (
              <Button
                aria-label="modal-close-button"
                className="modal__close-button"
                icon={IconVariant.CLOSE}
                onClick={onHide}
              />
            )}
          </header>
          <main className="modal__modal-main">{children}</main>
        </div>
      </div>
    </Layer>
  );
};

export default Modal;
