import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const BOX_CLOSED = "box_closed";

interface BoxClosedPayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
}

interface BoxClosed extends DomainEvent<typeof BOX_CLOSED>, BoxClosedPayload {}

interface BoxClosedFunction {
  (payload: BoxClosedPayload): BoxClosed;
}

const boxClosed: BoxClosedFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: BOX_CLOSED }),
  ...payload,
});

const isBoxClosed = (event: DomainEvent<MessageName>): event is BoxClosed => event.name === BOX_CLOSED;

export type { BoxClosed };
export { BOX_CLOSED, boxClosed, isBoxClosed };
