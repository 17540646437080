import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { FilteringProjection } from "./filtering";

const VIEW_FILTERING_BY_CUSTOMER_ID = "view_filtering_by_customer_id";

interface ViewFilteringByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewFilteringByCustomerId
  extends Query<typeof VIEW_FILTERING_BY_CUSTOMER_ID>,
    ViewFilteringByCustomerIdPayload {}

interface ViewFilteringByCustomerIdFunction {
  (payload: ViewFilteringByCustomerIdPayload): ViewFilteringByCustomerId;
}

const viewFilteringByCustomerId: ViewFilteringByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_FILTERING_BY_CUSTOMER_ID }),
  ...payload,
});

type ViewFilteringByCustomerIdResult = FilteringProjection | null;

interface FilteringByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface FilteringByCustomerIdView {
  (args: FilteringByCustomerIdViewArgs): Promise<ViewFilteringByCustomerIdResult>;
}

interface ViewFilteringByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: FilteringByCustomerIdView;
}

const viewFilteringByCustomerIdHandler: QueryHandlerFunction<
  ViewFilteringByCustomerId,
  ViewFilteringByCustomerIdResult,
  ViewFilteringByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { ViewFilteringByCustomerId, FilteringByCustomerIdView, ViewFilteringByCustomerIdResult };
export { VIEW_FILTERING_BY_CUSTOMER_ID, viewFilteringByCustomerId, viewFilteringByCustomerIdHandler };
