import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import {
  AutomaticSelectionsGetFunction,
  AutomaticSelectionsSaveFunction,
} from "../../../../domain/selection/model/automaticSelections";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpAutomaticSelectionsStart } from "./httpAutomaticSelectionsStart";

interface HttpAutomaticSelectionsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpAutomaticSelectionsGetFunction
  extends AutomaticSelectionsGetFunction<HttpAutomaticSelectionsGetFunctionArgs> {}

const getAutomaticSelection: HttpAutomaticSelectionsGetFunction = () => async () => {
  throw new Error("There is no equivalent AggregateRoot in the backend");
};

interface HttpAutomaticSelectionsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpAutomaticSelectionsSaveFunction
  extends AutomaticSelectionsSaveFunction<HttpAutomaticSelectionsSaveFunctionArgs> {}

const saveAutomaticSelection: HttpAutomaticSelectionsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) =>
    await httpAutomaticSelectionsStart({ httpPost })(aggregateRoot);

export type { HttpAutomaticSelectionsSaveFunction };
export { getAutomaticSelection, saveAutomaticSelection };
