import { useMatch } from "react-router-dom";
import { ActivityTimeSection } from "../../../../../core/domain/activityTime/model/activityTime";
import { Routes } from "../../../../_routing/Routing";

interface UseActivityTimeSectionFunction {
  (): ActivityTimeSection;
}

const useActivityTimeSection: UseActivityTimeSectionFunction = () => {
  const customerMatch = useMatch(`${Routes.BOX_CUSTOMER}/*`);
  const shortlistMatch = useMatch(`${Routes.BOX_SHORTLIST}/*`);
  const selectionMatch = useMatch(`${Routes.BOX_SELECTION}/*`);

  let section: ActivityTimeSection = ActivityTimeSection.NONE;

  if (customerMatch) {
    section = ActivityTimeSection.CUSTOMER;
  } else if (shortlistMatch) {
    section = ActivityTimeSection.SHORTLIST;
  } else if (selectionMatch) {
    section = ActivityTimeSection.SELECTION;
  }

  return section;
};

export { useActivityTimeSection };
