import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { SelectionProjection } from "./selection";

const VIEW_SELECTION_BY_BOX_NUMBER = "view_selection_by_box_number";

interface ViewSelectionByBoxNumberPayload {
  readonly boxNumber: string;
}

interface ViewSelectionByBoxNumber
  extends Query<typeof VIEW_SELECTION_BY_BOX_NUMBER>,
    ViewSelectionByBoxNumberPayload {}

interface ViewSelectionByBoxNumberFunction {
  (payload: ViewSelectionByBoxNumberPayload): ViewSelectionByBoxNumber;
}

const viewSelectionByBoxNumber: ViewSelectionByBoxNumberFunction = (payload) => ({
  ...query({ name: VIEW_SELECTION_BY_BOX_NUMBER }),
  ...payload,
});

type ViewSelectionByBoxNumberResult = SelectionProjection | null;

interface SelectionByBoxNumberViewArgs extends CancelableQueryViewArgs {
  readonly boxNumber: string;
}

interface SelectionByBoxNumberView {
  (args: SelectionByBoxNumberViewArgs): Promise<ViewSelectionByBoxNumberResult>;
}

interface ViewSelectionByBoxNumberHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: SelectionByBoxNumberView;
}

const viewSelectionByBoxNumberHandler: QueryHandlerFunction<
  ViewSelectionByBoxNumber,
  ViewSelectionByBoxNumberResult,
  ViewSelectionByBoxNumberHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxNumber }) =>
    view({ boxNumber, signal });

export type { SelectionByBoxNumberView, ViewSelectionByBoxNumber, ViewSelectionByBoxNumberResult };
export { VIEW_SELECTION_BY_BOX_NUMBER, viewSelectionByBoxNumber, viewSelectionByBoxNumberHandler };
