import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { NoteProjection } from "./note";

const VIEW_NOTE_BY_ID = "view_note_by_id";

interface ViewNoteByIdPayload {
  readonly id: string;
}

interface ViewNoteById extends Query<typeof VIEW_NOTE_BY_ID>, ViewNoteByIdPayload {}

interface ViewNoteByIdFunction {
  (payload: ViewNoteByIdPayload): ViewNoteById;
}

const viewNoteById: ViewNoteByIdFunction = (payload) => ({
  ...query({ name: VIEW_NOTE_BY_ID }),
  ...payload,
});

type ViewNoteByIdResult = NoteProjection | null;

interface NoteByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface NoteByIdView {
  (args: NoteByIdViewArgs): Promise<ViewNoteByIdResult>;
}

interface ViewNoteByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: NoteByIdView;
}

const viewNoteByIdHandler: QueryHandlerFunction<ViewNoteById, ViewNoteByIdResult, ViewNoteByIdHandlerFunctionArgs> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type { NoteByIdView, ViewNoteById, ViewNoteByIdResult };
export { VIEW_NOTE_BY_ID, viewNoteById, viewNoteByIdHandler };
