import { init, captureException } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Logger } from "./Logger";

enum ErrorsToBeIgnored {
  RESIZE_OBSERVER_LOOP_LIMIT = "ResizeObserver loop limit exceeded",
  RESIZE_OBSERVER_UNDELIVERED_NOTIFICATIONS = "ResizeObserver loop completed with undelivered notifications.",
  I18N_COULD_NOT_FETCH_THE_TRANSLATION_FOR_THE_LOCALE = "Could not fetch the translation for the locale",
}

type SentryLoggerParameters = {
  readonly environment: string;
  readonly release: string;
  readonly publicKey: string;
  readonly project: string;
};
class SentryLogger implements Logger {
  public constructor({ environment, release, project, publicKey }: SentryLoggerParameters) {
    init({
      environment,
      release,
      dsn: `https://${publicKey}@o179049.ingest.sentry.io/${project}`,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.5,
      ignoreErrors: [
        ErrorsToBeIgnored.RESIZE_OBSERVER_LOOP_LIMIT,
        ErrorsToBeIgnored.RESIZE_OBSERVER_UNDELIVERED_NOTIFICATIONS,
        ErrorsToBeIgnored.I18N_COULD_NOT_FETCH_THE_TRANSLATION_FOR_THE_LOCALE,
        // This configuration is obtained from
        // https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        "Script error.",
        // Avast extension error
        "_avast_submit",
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }

  public captureException(error: Error): void {
    captureException(error);
  }
}

export default SentryLogger;
