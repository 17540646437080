import { FC, ReactNode } from "react";
import classNames from "classnames";
import AspectRatio from "../../_common/AspectRatio";
import Picture from "../../atoms/picture/Picture";
import Icon, { IconVariant } from "../../atoms/icon/Icon";
import Text, { TextVariant } from "../../atoms/text/Text";
import "./picture-list-item.css";

interface PictureListItemProps {
  readonly className?: string;
  readonly children: ReactNode;
  readonly src?: string;
  readonly alt: string;
  readonly familyName?: string;
  readonly selected?: boolean;
}

const PictureListItem: FC<PictureListItemProps> = ({
  src,
  alt,
  familyName,
  children,
  className,
  selected = false,
}: PictureListItemProps) => (
  <div className={classNames("picture-list-item", className, { "picture-list-item--selected": selected })}>
    {src ? (
      <Picture aspectRatio={AspectRatio.R_4_5} label={alt} src={src} />
    ) : (
      <div className="picture-list-item__placeholder">
        <Icon variant={IconVariant.STYLE} />
        {familyName && (
          <Text variant={TextVariant.BODY_SMALL} ellipsis>
            {familyName}
          </Text>
        )}
      </div>
    )}
    <div className="picture-list-item__content">{children}</div>
  </div>
);

export default PictureListItem;
