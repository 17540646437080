import { HttpFavouritesSaveFunction } from "./httpFavourites";
import { isProductUnmarkedAsFavourite } from "../../../../domain/favourite/model/productUnmarkedAsFavourite";

interface HttpProductUnmarkedAsFavouriteFunction extends HttpFavouritesSaveFunction {}

const httpProductUnmarkedAsFavourite: HttpProductUnmarkedAsFavouriteFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const personalShopperFilteringSet = domainEvents.find(isProductUnmarkedAsFavourite);

    if (!personalShopperFilteringSet) {
      return;
    }

    await httpPost({
      endpoint: "/unmark-product-as-favourite",
      body: {
        favourite_id: aggregateId,
      },
    });
  };

export { httpProductUnmarkedAsFavourite };
