import {
  CountLookAndLikeFeedbacksByCriteriaResult,
  LookAndLikeFeedbacksCountByCriteriaView,
} from "../../../projection/lookAndLikeFeedback/countLookAndLikeFeedbacksByCriteria";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpLookAndLikeFeedbacksCountByCriteriaView extends LookAndLikeFeedbacksCountByCriteriaView {}

interface HttpLookAndLikeFeedbacksCountByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpLookAndLikeFeedbacksCountByCriteriaViewFunction {
  (args: HttpLookAndLikeFeedbacksCountByCriteriaViewFunctionArgs): HttpLookAndLikeFeedbacksCountByCriteriaView;
}

interface CountLookAndLikeFeedbacksByCriteriaResponse {
  readonly result: number;
}

const httpLookAndLikeFeedbacksCountByCriteriaView: HttpLookAndLikeFeedbacksCountByCriteriaViewFunction =
  ({ httpPost }) =>
  async ({ criteria: { customerId, familyIds, response }, signal }) =>
    await httpPost<CountLookAndLikeFeedbacksByCriteriaResponse, CountLookAndLikeFeedbacksByCriteriaResult>({
      endpoint: "/count-look-and-like-feedbacks-by-criteria",
      body: {
        customer_id: customerId,
        family_ids: familyIds,
        response,
      },
      signal,
      result: {
        error: 0,
        success: ({ result }) => result,
      },
    });

export { httpLookAndLikeFeedbacksCountByCriteriaView };
