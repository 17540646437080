import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { CloseBox } from "../command/closeBox";
import { canTheBoxBeClosed, CanTheBoxBeClosedResult } from "../../../projection/box/canTheBoxBeClosed";
import { boxClosed } from "./boxClosed";
import { SendBoxToSupervise } from "../command/sendBoxToSupervise";
import {
  canTheBoxSentToSupervise,
  CanTheBoxSentToSuperviseResult,
} from "../../../projection/box/canTheBoxSentToSupervise";
import { boxSentToSupervise } from "./boxSentToSupervise";
import BoxStatus from "./BoxStatus";

interface Box extends AggregateRoot {
  readonly boxNumber: string;
  readonly personalShopperId: string | null;
  status: BoxStatus;
}

const closeBoxHandler: CommandHandlerFunction<CloseBox, Box> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { boxNumber } = command;

    const canTheBoxBeClosedResult: CanTheBoxBeClosedResult = await queryBus(canTheBoxBeClosed({ boxNumber }));

    if (!canTheBoxBeClosedResult) {
      throw new Error("Can not close the box.");
    }

    return {
      ...aggregateRoot,
      boxNumber,
      status: BoxStatus.CLOSED,
      domainEvents: [boxClosed({ aggregateId: aggregateRoot.aggregateId, boxNumber })],
    };
  };

const sendBoxToSuperviseHandler: CommandHandlerFunction<SendBoxToSupervise, Box> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { boxNumber, personalShopperId } = command;

    const canTheBoxBeSentToSuperviseResult: CanTheBoxSentToSuperviseResult = await queryBus(
      canTheBoxSentToSupervise({ boxNumber }),
    );

    if (!canTheBoxBeSentToSuperviseResult) {
      throw new Error("Cannot send the box to supervise.");
    }

    return {
      ...aggregateRoot,
      boxNumber,
      status: BoxStatus.SUPERVISING,
      personalShopperId,
      domainEvents: [boxSentToSupervise({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

export type { Box };
export { closeBoxHandler, sendBoxToSuperviseHandler };
