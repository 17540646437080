import {
  NoteTemplatesSearchByCriteriaView,
  SearchNoteTemplatesByCriteriaResult,
} from "../../../projection/noteTemplate/searchNoteTemplatesByCriteria";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { NoteTemplateProjection } from "../../../projection/noteTemplate/noteTemplate";
import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";

interface HttpNoteTemplatesSearchByCriteriaView extends NoteTemplatesSearchByCriteriaView {}

interface NoteTemplatesSearchByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface NoteTemplatesSearchByCriteriaViewFunction {
  (args: NoteTemplatesSearchByCriteriaViewFunctionArgs): HttpNoteTemplatesSearchByCriteriaView;
}

interface SearchNoteTemplatesByCriteriaResponse {
  readonly result: NoteTemplateProjection[];
}

const httpNoteTemplatesSearchByCriteriaView: NoteTemplatesSearchByCriteriaViewFunction =
  ({ httpPost }) =>
  async ({ criteria, signal }) =>
    await httpPost<SearchNoteTemplatesByCriteriaResponse, SearchNoteTemplatesByCriteriaResult>({
      endpoint: "/search-note-templates-by-ps-id-and-criteria",
      body: { ps_id: criteria.psId, page: criteria.page, per_page: criteria.perPage, categories: criteria.categories },
      signal,
      result: {
        error: [],
        success: ({ result }) => camelcaseKeys(result),
      },
    });

export { httpNoteTemplatesSearchByCriteriaView };
