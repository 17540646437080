import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { SelectProductVariant } from "../command/selectProductVariant";
import { productVariantSelected } from "./ProductVariantSelected";
import { productVariantDeselected } from "./ProductVariantDeselected";
import { DeselectProductVariant } from "../command/deselectProductVariant";
import { ReplaceProductVariant } from "../command/replaceProductVariant";
import { productVariantReplaced } from "./ProductVariantReplaced";
import BoxPreviewStatus from "../../boxPreview/model/BoxPreviewStatus";
import boxPreviewFinalStatuses from "../../boxPreview/model/BoxPreviewFinalStatuses";
import SelectionNotModifiableError from "./SelectionNotModifiableError";
import { selectionNotModifiable, SelectionNotModifiableActions } from "./SelectionNotModifiable";
import { UnmarkProductVariantAsCandidate } from "../command/unmarkProductVariantAsCandidate";
import {
  canMarkOrUnmarkProductVariantAsCandidate,
  CanMarkOrUnmarkProductVariantAsCandidateResult,
} from "../../../projection/selection/canMarkOrUnmarkProductVariantAsCandidate";
import { productVariantUnmarkedAsCandidate } from "./productVariantUnmarkedAsCandidate";
import { MarkProductVariantAsCandidate } from "../command/markProductVariantAsCandidate";
import { productVariantMarkedAsCandidate } from "./productVariantMarkedAsCandidate";

interface IsSelectionModifiableFunctionArgs {
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
  readonly automaticSelectionStartedOn: Date | null;
  readonly automaticSelectionFinishedOn: Date | null;
}

interface IsSelectionModifiableFunction {
  (args: IsSelectionModifiableFunctionArgs): void;
}

const isSelectionModifiable: IsSelectionModifiableFunction = ({
  automaticSelectionFinishedOn,
  automaticSelectionStartedOn,
  boxPreviewStatus,
}) => {
  if (boxPreviewStatus && !boxPreviewFinalStatuses.includes(boxPreviewStatus)) {
    throw new SelectionNotModifiableError();
  }

  if (automaticSelectionStartedOn !== null && automaticSelectionFinishedOn === null) {
    throw new SelectionNotModifiableError();
  }
};

interface IsProductVariantAlreadySelectedFunctionArgs {
  readonly productVariantId: string;
  readonly productVariantIds: string[];
}

interface IsProductVariantAlreadySelectedFunction {
  (args: IsProductVariantAlreadySelectedFunctionArgs): void;
}

const isProductVariantAlreadySelected: IsProductVariantAlreadySelectedFunction = ({
  productVariantId,
  productVariantIds,
}) => {
  if (productVariantIds.find((selectionProductVariantId) => selectionProductVariantId === productVariantId)) {
    throw new Error("Product variant already exists in the Selection");
  }
};

interface IsProductVariantNotSelectedFunctionArgs {
  readonly productVariantId: string;
  readonly productVariantIds: string[];
}

interface IsProductVariantNotSelectedFunction {
  (args: IsProductVariantNotSelectedFunctionArgs): void;
}

const isProductVariantNotSelected: IsProductVariantNotSelectedFunction = ({ productVariantId, productVariantIds }) => {
  if (!productVariantIds.find((selectionProductVariantId) => selectionProductVariantId === productVariantId)) {
    throw new Error("Product variant does not exist in the Selection");
  }
};

interface AddProductVariantFunctionArgs {
  readonly productVariantId: string;
  readonly productVariantIds: string[];
}

interface AddProductVariantFunction {
  (args: AddProductVariantFunctionArgs): string[];
}

const addProductVariant: AddProductVariantFunction = ({ productVariantId, productVariantIds }) => [
  ...productVariantIds,
  productVariantId,
];

interface RemoveProductVariantFunctionArgs {
  readonly productVariantId: string;
  readonly productVariantIds: string[];
}

interface RemoveProductVariantFunction {
  (args: RemoveProductVariantFunctionArgs): string[];
}

const removeProductVariant: RemoveProductVariantFunction = ({ productVariantId, productVariantIds }) =>
  productVariantIds.filter((selectionProductVariantId) => selectionProductVariantId !== productVariantId);

interface Selection extends AggregateRoot {
  readonly boxId: string | null;
  readonly boxNumber: string | null;
  readonly automaticSelectionStartedOn: Date | null;
  readonly automaticSelectionFinishedOn: Date | null;
  readonly productVariantIds: string[];
}

const selectProductVariantHandler: CommandHandlerFunction<SelectProductVariant, Selection> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxId, boxNumber, productVariantId, boxPreviewStatus } = command;

    try {
      isSelectionModifiable({
        boxPreviewStatus,
        automaticSelectionStartedOn: aggregateRoot.automaticSelectionStartedOn,
        automaticSelectionFinishedOn: aggregateRoot.automaticSelectionFinishedOn,
      });

      isProductVariantAlreadySelected({
        productVariantId,
        productVariantIds: aggregateRoot.productVariantIds,
      });

      const productVariantIds = addProductVariant({
        productVariantId,
        productVariantIds: aggregateRoot.productVariantIds,
      });

      return {
        ...aggregateRoot,
        boxId,
        boxNumber,
        productVariantIds,
        domainEvents: [
          productVariantSelected({
            aggregateId,
            boxNumber,
            productVariantId,
            productVariantIds,
          }),
        ],
      };
    } catch (error) {
      return {
        ...aggregateRoot,
        domainEvents: [selectionNotModifiable({ aggregateId, action: SelectionNotModifiableActions.SELECT })],
      };
    }
  };

const deselectProductVariantHandler: CommandHandlerFunction<DeselectProductVariant, Selection> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxNumber, productVariantId, boxPreviewStatus } = command;

    try {
      isSelectionModifiable({
        boxPreviewStatus,
        automaticSelectionStartedOn: aggregateRoot.automaticSelectionStartedOn,
        automaticSelectionFinishedOn: aggregateRoot.automaticSelectionFinishedOn,
      });

      isProductVariantNotSelected({
        productVariantId,
        productVariantIds: aggregateRoot.productVariantIds,
      });

      const productVariantIds = removeProductVariant({
        productVariantId,
        productVariantIds: aggregateRoot.productVariantIds,
      });

      return {
        ...aggregateRoot,
        boxNumber,
        productVariantIds,
        domainEvents: [
          productVariantDeselected({
            aggregateId,
            boxNumber,
            productVariantId,
            productVariantIds,
          }),
        ],
      };
    } catch (error) {
      return {
        ...aggregateRoot,
        domainEvents: [selectionNotModifiable({ aggregateId, action: SelectionNotModifiableActions.DESELECT })],
      };
    }
  };

const replaceProductVariantHandler: CommandHandlerFunction<ReplaceProductVariant, Selection> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxId, boxNumber, boxPreviewStatus, selectedProductVariantId, deselectedProductVariantId } =
      command;

    try {
      isSelectionModifiable({
        boxPreviewStatus,
        automaticSelectionStartedOn: aggregateRoot.automaticSelectionStartedOn,
        automaticSelectionFinishedOn: aggregateRoot.automaticSelectionFinishedOn,
      });

      isProductVariantNotSelected({
        productVariantId: deselectedProductVariantId,
        productVariantIds: aggregateRoot.productVariantIds,
      });

      isProductVariantAlreadySelected({
        productVariantId: selectedProductVariantId,
        productVariantIds: aggregateRoot.productVariantIds,
      });

      const productVariantIds = addProductVariant({
        productVariantId: selectedProductVariantId,
        productVariantIds: removeProductVariant({
          productVariantId: deselectedProductVariantId,
          productVariantIds: aggregateRoot.productVariantIds,
        }),
      });

      return {
        ...aggregateRoot,
        boxId,
        boxNumber,
        productVariantIds,
        domainEvents: [
          productVariantReplaced({
            aggregateId,
            boxNumber,
            productVariantIds,
            deselectedProductVariantId,
            selectedProductVariantId,
          }),
        ],
      };
    } catch (error) {
      return {
        ...aggregateRoot,
        domainEvents: [selectionNotModifiable({ aggregateId, action: SelectionNotModifiableActions.REPLACE })],
      };
    }
  };

const unmarkProductVariantAsCandidateHandler: CommandHandlerFunction<UnmarkProductVariantAsCandidate, Selection> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxId, boxNumber, productVariantId } = command;

    const canMarkOrUnmarkProductVariantAsCandidateResult: CanMarkOrUnmarkProductVariantAsCandidateResult =
      await queryBus(canMarkOrUnmarkProductVariantAsCandidate({ boxNumber, boxId }));

    if (!canMarkOrUnmarkProductVariantAsCandidateResult) {
      throw new Error(`Cannot unmark product variant as candidate: ${productVariantId}`);
    }

    isProductVariantNotSelected({
      productVariantId,
      productVariantIds: aggregateRoot.productVariantIds,
    });

    return {
      ...aggregateRoot,
      boxId,
      boxNumber,
      domainEvents: [productVariantUnmarkedAsCandidate({ aggregateId, productVariantId })],
    };
  };

const markProductVariantAsCandidateHandler: CommandHandlerFunction<MarkProductVariantAsCandidate, Selection> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxId, boxNumber, productVariantId } = command;

    const canMarkOrUnmarkProductVariantAsCandidateResult: CanMarkOrUnmarkProductVariantAsCandidateResult =
      await queryBus(canMarkOrUnmarkProductVariantAsCandidate({ boxNumber, boxId }));

    if (!canMarkOrUnmarkProductVariantAsCandidateResult) {
      throw new Error(`Cannot mark product variant as candidate: ${productVariantId}`);
    }

    isProductVariantNotSelected({
      productVariantId,
      productVariantIds: aggregateRoot.productVariantIds,
    });

    return {
      ...aggregateRoot,
      boxId,
      boxNumber,
      domainEvents: [productVariantMarkedAsCandidate({ aggregateId, productVariantId })],
    };
  };

export type { Selection };
export {
  selectProductVariantHandler,
  deselectProductVariantHandler,
  replaceProductVariantHandler,
  unmarkProductVariantAsCandidateHandler,
  markProductVariantAsCandidateHandler,
};
