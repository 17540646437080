import { DomainEvent, MessageName } from "@lookiero/messaging";
import {
  PERSONAL_SHOPPER_FILTERING_SET,
  PersonalShopperFilteringSet,
} from "../../../../domain/personalShopperFiltering/model/personalShopperFilteringSet";
import { HttpPersonalShopperFilteringsSaveFunction } from "./httpPersonalShopperFilterings";

interface HttpPersonalShopperFilteringsSetFunction extends HttpPersonalShopperFilteringsSaveFunction {}

const isPersonalShopperFilteringSet = (event: DomainEvent<MessageName>): event is PersonalShopperFilteringSet =>
  event.name === PERSONAL_SHOPPER_FILTERING_SET;

const httpPersonalShopperFilteringsSet: HttpPersonalShopperFilteringsSetFunction =
  ({ httpPost }) =>
  async ({ aggregateId, customerId, positives, negatives, psId, boxId, domainEvents }) => {
    const personalShopperFilteringSet = domainEvents.find(isPersonalShopperFilteringSet);

    if (!personalShopperFilteringSet) {
      return;
    }

    await httpPost({
      endpoint: "/set-personal-shopper-filtering",
      body: {
        id: aggregateId,
        customer_id: customerId,
        personal_shopper_id: psId,
        box_id: boxId,
        positives,
        negatives,
      },
    });
  };

export { httpPersonalShopperFilteringsSet };
