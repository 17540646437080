import { command, Command } from "@lookiero/messaging";

const MARK_PRODUCT_AS_FAVOURITE = "mark_product_as_favourite";

interface MarkProductAsFavouritePayload {
  readonly aggregateId: string;
  readonly psId: string;
  readonly productId: string;
}

interface MarkProductAsFavourite extends Command<typeof MARK_PRODUCT_AS_FAVOURITE>, MarkProductAsFavouritePayload {}

interface MarkProductAsFavouriteFunction {
  (payload: MarkProductAsFavouritePayload): MarkProductAsFavourite;
}

const markProductAsFavourite: MarkProductAsFavouriteFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: MARK_PRODUCT_AS_FAVOURITE }),
  ...payload,
});

export type { MarkProductAsFavourite };
export { MARK_PRODUCT_AS_FAVOURITE, markProductAsFavourite };
