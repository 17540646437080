import BoxStatus from "./BoxStatus";

const nonSupervisableBoxStatuses: Readonly<BoxStatus[]> = [
  BoxStatus.CLOSED,
  BoxStatus.FINISHED,
  BoxStatus.BLOCKED,
  BoxStatus.CANCELLED,
] as const;

export default nonSupervisableBoxStatuses;
