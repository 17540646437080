import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const ACTIVITY_TIME_REGISTERED = "activity_time_registered";

interface ActivityTimeRegisteredPayload {
  readonly aggregateId: string;
}

interface ActivityTimeRegistered extends DomainEvent<typeof ACTIVITY_TIME_REGISTERED>, ActivityTimeRegisteredPayload {}

interface ActivityTimeRegisteredFunction {
  (payload: ActivityTimeRegisteredPayload): ActivityTimeRegistered;
}

const activityTimeRegistered: ActivityTimeRegisteredFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: ACTIVITY_TIME_REGISTERED });

const isActivityTimeRegistered = (event: DomainEvent<MessageName>): event is ActivityTimeRegistered =>
  event.name === ACTIVITY_TIME_REGISTERED;

export type { ActivityTimeRegistered };
export { ACTIVITY_TIME_REGISTERED, isActivityTimeRegistered, activityTimeRegistered };
