import { FC, ReactNode, useCallback } from "react";
import NoteTemplateActions from "../../componentLibrary/organisms/noteTemplate/noteTemplateActionButtons/NoteTemplateActions";
import QueryParams from "../../_routing/queryParams/config";
import useQueryParameters from "../../_routing/queryParams/useQueryParameters";
import { Routes } from "../../_routing/Routing";
import { useEditorSharedContext } from "../_behaviors/useEditorSharedContext";
import useEnvironment from "../_behaviors/useEnvironment";
import NoteTemplatesTemplate from "./../../componentLibrary/templates/noteTemplates/NoteTemplates";
import { useCountNoteTemplatesByCriteria } from "../../../core/infrastructure/projection/noteTemplate/react/useCountNoteTemplatesByCriteria";
import { useSearchNoteTemplatesByCriteria } from "../../../core/infrastructure/projection/noteTemplate/react/useSearchNoteTemplatesByCriteria";
import { useRemoveNoteTemplate } from "../../../core/infrastructure/domain/noteTemplate/react/useRemoveNoteTemplate";
import { NoteTemplateProjection } from "../../../core/projection/noteTemplate/noteTemplate";
import { asyncActionStateForCommandStatus } from "../../../shared/ui/uiKit/_common/AsyncActionState";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useViewPersonalShopper } from "../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";

interface NoteTemplatesProps {
  readonly children?: ReactNode;
}

const NoteTemplates: FC<NoteTemplatesProps> = ({ children }) => {
  const {
    noteTemplates: { perPage },
  } = useEnvironment();
  const navigate = useNavigate();
  const { locale, box: legacyBoxId } = useParams();
  const [personalShopper] = useViewPersonalShopper();
  const { injectTemplate } = useEditorSharedContext();

  const { params, setParams } = useQueryParameters();
  const categoriesParams = params[QueryParams.CATEGORIES];
  const categories = categoriesParams ? JSON.parse(categoriesParams as string) : [];
  const page = parseInt(params[QueryParams.PAGE] as string) || 1;
  const onPageChanged = useCallback(
    (page: number): void => setParams({ [QueryParams.PAGE]: page === 1 ? null : String(page) }),
    [setParams],
  );
  const navigateToTemplateDetail = useCallback(
    (noteTemplateId: string) => {
      navigate(
        generatePath(Routes.BOX_SELECTION_NOTES_TEMPLATES_DETAIL, {
          locale: locale as string,
          box: legacyBoxId as string,
          template: noteTemplateId,
        }),
        { replace: true },
      );
    },
    [legacyBoxId, locale, navigate],
  );

  const [countNoteTemplates] = useCountNoteTemplatesByCriteria({
    psId: personalShopper?.id,
    categories,
    itemCountWhileQuerying: perPage,
  });

  const [noteTemplates] = useSearchNoteTemplatesByCriteria({
    psId: personalShopper?.id,
    page,
    perPage,
    categories,
  });

  const [removeNoteTemplate, removeNoteTemplateStatus] = useRemoveNoteTemplate();

  const useNoteTemplate = useCallback(
    (noteTemplateContent: string) => {
      injectTemplate(noteTemplateContent);
      navigate(generatePath(Routes.BOX_SELECTION, { locale: locale as string, box: legacyBoxId as string }));
    },
    [injectTemplate, navigate, locale, legacyBoxId],
  );

  const noteTemplateActions = useCallback(
    (noteTemplate: NoteTemplateProjection) => (
      <NoteTemplateActions
        noteTemplate={noteTemplate}
        removeState={asyncActionStateForCommandStatus[removeNoteTemplateStatus]}
        onEdit={navigateToTemplateDetail}
        onRemove={removeNoteTemplate}
        onUse={useNoteTemplate}
      />
    ),
    [navigateToTemplateDetail, removeNoteTemplate, removeNoteTemplateStatus, useNoteTemplate],
  );

  return (
    <>
      <NoteTemplatesTemplate
        itemCount={countNoteTemplates as number}
        noteTemplateActions={noteTemplateActions}
        noteTemplates={noteTemplates}
        page={page}
        perPage={perPage}
        onPageChanged={onPageChanged}
      />
      {children}
    </>
  );
};

export default NoteTemplates;
