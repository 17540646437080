import { FC, useCallback } from "react";
import { generatePath, useLocation, useMatch, useParams } from "react-router-dom";
import { Routes } from "../../_routing/Routing";
import { useNavigateBackWithFallback } from "../../../shared/ui/uiKit/_behaviors/useNavigateBackWithFallback/useNavigateBackWithFallback";
import { ProductDetailModal } from "../../componentLibrary/organisms/productDetail/ProductDetailModal";
import { useTrackSelectDeselectProductVariant } from "../../../shared/tracking/infrastructure/useTrackSelectDeselectProductVariant";
import { Subsection, TrackingPage } from "../../../shared/tracking/Tracker";
import useBoxSharedContext from "../_behaviors/useBoxSharedContext";
import { useViewPersonalShopper } from "../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";

const ProductDetail: FC = () => {
  const { search } = useLocation();
  const { locale, box: legacyBoxId, product: productId } = useParams();

  const isShortlistRoute = Boolean(useMatch(`${Routes.BOX_SHORTLIST}/*`));
  const fallbackRoute = isShortlistRoute ? Routes.BOX_SHORTLIST : Routes.BOX_SELECTION;
  const previousLocation = `${generatePath(fallbackRoute, {
    locale: locale as string,
    box: legacyBoxId as string,
  })}${search}`;

  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();

  const navigateBack = useNavigateBackWithFallback({ fallback: previousLocation });

  const trackSelectDeselectProductVariant = useTrackSelectDeselectProductVariant({
    boxId: box.id,
    psId: personalShopper?.id,
    section: isShortlistRoute ? TrackingPage.CATALOG : TrackingPage.SELECTION,
    subsection: Subsection.ITEM,
    userId: box.customerId,
  });

  const handleOnSelectProductVariant = useCallback(
    (productVariantId: string) => {
      trackSelectDeselectProductVariant({ productVariantId, select: true });
    },
    [trackSelectDeselectProductVariant],
  );

  const handleOnDeselectProductVariant = useCallback(
    (productVariantId: string) => {
      trackSelectDeselectProductVariant({ productVariantId, select: false });
    },
    [trackSelectDeselectProductVariant],
  );

  return (
    <ProductDetailModal
      productId={productId as string}
      visible
      onDeselectProductVariant={handleOnDeselectProductVariant}
      onHide={navigateBack}
      onSelectProductVariant={handleOnSelectProductVariant}
    />
  );
};

export { ProductDetail };
