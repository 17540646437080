import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { HistoryShopProductVariantProjection } from "../../../../projection/historyShop/historyShopProductVariant";
import { searchHistoryShopByCustomerId } from "../../../../projection/historyShop/searchHistoryShopByCustomerId";

interface UseSearchHistoryShopByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseSearchHistoryShopByCustomerIdFunction {
  (args: UseSearchHistoryShopByCustomerIdFunctionArgs): UseQueryFunctionResult<HistoryShopProductVariantProjection[]>;
}

const useSearchHistoryShopByCustomerId: UseSearchHistoryShopByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: searchHistoryShopByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    options: { staleTime: 10 * 60 * 1000, retry: 0 },
  });

export { useSearchHistoryShopByCustomerId };
