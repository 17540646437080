import { CapacityCalendarProjection } from "../../../projection/capacityCalendar/capacityCalendar";

interface CapacityCalendarDto {
  readonly date: string;
  readonly capacity: number;
  readonly boxes: {
    readonly total: number;
    readonly percentage: number;
    readonly recurrent: number;
    readonly new: number;
    readonly difference: number;
  };
}

interface ToCapacityCalendarProjectionFunction {
  (capacityCalendarDto: CapacityCalendarDto): CapacityCalendarProjection;
}

const toCapacityCalendarProjection: ToCapacityCalendarProjectionFunction = (capacityCalendarDto) => ({
  ...capacityCalendarDto,
  date: new Date(capacityCalendarDto.date),
});

export type { CapacityCalendarDto };
export { toCapacityCalendarProjection };
