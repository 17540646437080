import { useTracker } from "./useTracker";
import { Section } from "../Tracker";
import { useCallback } from "react";

interface TrackGenerateNoteFunction {
  (): void;
}

interface UseTrackGenerateNoteFunctionArgs {
  readonly section: Section;
  readonly psId?: string;
}

interface UseTrackGenerateNoteFunction {
  (args: UseTrackGenerateNoteFunctionArgs): TrackGenerateNoteFunction;
}

const useTrackGenerateNote: UseTrackGenerateNoteFunction = ({ section, psId }) => {
  const tracker = useTracker();

  const generateNote: TrackGenerateNoteFunction = useCallback(() => {
    if (!(tracker && section && psId)) {
      return;
    }

    tracker.generateNote({ section, psId });
  }, [psId, section, tracker]);

  return generateNote;
};

export { useTrackGenerateNote };
