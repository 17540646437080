import { useMemo } from "react";
import { ReturnQuestionIcon } from "../../../../../core/projection/returnQuestion/returnQuestion";
import Icon, { IconVariant } from "../../../../../shared/ui/uiKit/atoms/icon/Icon";
import { useReturnQuestionFeedback } from "../../behaviors/useReturnQuestionFeedback";
import { iconForReturnQuestion } from "../../util/returnQuestionFeedback";
import { ReturnQuestionItem, ReturnQuestionItemProps } from "../ReturnQuestionItem";
import "./return-question-feedback-icon-item.css";

const IconVariantForReturnQuestionIconName: Record<ReturnQuestionIcon, IconVariant> = {
  "return_question.icon.filled_heart": IconVariant.HEART_FILLED,
  "return_question.icon.crossed_heart": IconVariant.HEART_CROSSED,
  "return_question.icon.quality": IconVariant.STAMP,
  "return_question.icon.price": IconVariant.LABEL,
  "return_question.icon.cut": IconVariant.SCISSORS,
  "return_question.icon.others": IconVariant.ACTIONS,
  "return_question.icon.size_small": IconVariant.SIZING_SMALL,
  "return_question.icon.size_big": IconVariant.SIZING_LARGE,
  "return_question.icon.size_correct": IconVariant.SIZING_CORRECT,
  "return_question.icon.shoes_comfortable": IconVariant.SHOES_COMFORTABLE,
  "return_question.icon.shoes_uncomfortable": IconVariant.SHOES_UNCOMFORTABLE,
  "return_question.icon.comment": IconVariant.COMMENT,
};

const ReturnQuestionFeedbackIconItem: ReturnQuestionItem = ({ returnQuestion }: ReturnQuestionItemProps) => {
  const returnQuestionFeedback = useReturnQuestionFeedback();
  const icon = useMemo(
    () => iconForReturnQuestion({ feedback: returnQuestionFeedback, returnQuestion }),
    [returnQuestion, returnQuestionFeedback],
  );

  return icon ? (
    <Icon
      className="return-question-feedback-icon-item"
      variant={IconVariantForReturnQuestionIconName[icon] || IconVariant.ALERT_WARNING}
    />
  ) : null;
};

export { ReturnQuestionFeedbackIconItem };
