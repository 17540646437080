import { PendingBoxesView, ViewPendingBoxesResult } from "../../../projection/pendingBox/viewPendingBoxes";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { PendingBoxDto, toPendingBoxProjection } from "./pendingBox";

interface HttpPendingBoxesView extends PendingBoxesView {}

interface HttpPendingBoxesViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpPendingBoxesViewFunction {
  (args: HttpPendingBoxesViewFunctionArgs): HttpPendingBoxesView;
}

interface ViewPendingBoxesResponse {
  readonly result: PendingBoxDto[];
}

const httpPendingBoxesView: HttpPendingBoxesViewFunction =
  ({ httpPost }) =>
  async ({ signal }) =>
    await httpPost<ViewPendingBoxesResponse, ViewPendingBoxesResult>({
      endpoint: "/view-pending-boxes",
      body: {},
      signal,
      result: {
        error: null,
        success: (response) => response.result.map(toPendingBoxProjection),
      },
    });

export { httpPendingBoxesView };
