import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { FavouriteProjection } from "./favourite";

const SEARCH_FAVOURITES_BY_PS_ID = "search_favourites_by_ps_id";

interface SearchFavouritesByPsIdPayload {
  readonly psId: string;
}

interface SearchFavouritesByPsId extends Query<typeof SEARCH_FAVOURITES_BY_PS_ID>, SearchFavouritesByPsIdPayload {}

interface SearchFavouritesByPsIdFunction {
  (payload: SearchFavouritesByPsIdPayload): SearchFavouritesByPsId;
}

const searchFavouritesByPsId: SearchFavouritesByPsIdFunction = (payload) => ({
  ...query({ name: SEARCH_FAVOURITES_BY_PS_ID }),
  ...payload,
});

type SearchFavouritesByPsIdResult = Record<string, FavouriteProjection> | null;

interface FavouritesByPsIdViewArgs extends CancelableQueryViewArgs {
  readonly psId: string;
}

interface FavouritesByPsIdView {
  (args: FavouritesByPsIdViewArgs): Promise<SearchFavouritesByPsIdResult>;
}

interface SearchFavouritesByPsIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: FavouritesByPsIdView;
}

const searchFavouritesByPsIdHandler: QueryHandlerFunction<
  SearchFavouritesByPsId,
  SearchFavouritesByPsIdResult,
  SearchFavouritesByPsIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ psId }) =>
    view({ psId, signal });

export type { SearchFavouritesByPsId, FavouritesByPsIdView, SearchFavouritesByPsIdResult };
export { SEARCH_FAVOURITES_BY_PS_ID, searchFavouritesByPsId, searchFavouritesByPsIdHandler };
