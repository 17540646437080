import { FC, ReactNode, useCallback } from "react";
import VirtualizedList from "../../../../shared/ui/uiKit/layouts/virtualizedList/VirtualizedList";
import { ShortlistProductProjection } from "../../../../core/projection/shortlist/shortlistProduct";
import "./shortlist-products-list.css";

const ITEM_MIN_WIDTH = 185;
const ITEM_ASPECT_RATIO = 2;

type ShortlistProductsListProps = {
  readonly children: (index: number, product: ShortlistProductProjection | null) => ReactNode;
  readonly products: Record<number, ShortlistProductProjection[]>;
  readonly itemCount: number;
  readonly page: number;
  readonly perPage: number;
  readonly onPageChanged: (page: number) => void;
  readonly scrollElement: Element | null;
};

const ShortlistProductsList: FC<ShortlistProductsListProps> = ({
  children,
  products,
  itemCount,
  page,
  perPage,
  onPageChanged,
  scrollElement,
}: ShortlistProductsListProps) => {
  const itemsPerRow = useCallback((listWidth: number): number => Math.floor(listWidth / ITEM_MIN_WIDTH) || 1, []);
  const estimatedItemHeight = useCallback(
    (listWidth: number): number => (listWidth * ITEM_ASPECT_RATIO) / itemsPerRow(listWidth),
    [itemsPerRow],
  );

  return (
    <section aria-label="shortlist-products-list" className="shortlist-products-list" role="list">
      <VirtualizedList
        data={products}
        estimatedItemHeight={estimatedItemHeight}
        itemCount={itemCount}
        itemsPerRow={itemsPerRow}
        page={page}
        perPage={perPage}
        scrollElement={scrollElement}
        onPageChanged={onPageChanged}
      >
        {children}
      </VirtualizedList>
    </section>
  );
};

export default ShortlistProductsList;
