import { FC } from "react";
import { PersonalShopperCountryProjection } from "../../../../core/projection/personalShopperCountry/personalShopperCountry";
import { PersonalShopperTeamsDropdown } from "../../organisms/personalShopperTeamsDropdown/PersonalShopperTeamsDropdown";
import { PersonalShopperCountriesDropdown } from "../../organisms/personalShopperCountriesDropdown/PersonalShopperCountriesDropdown";
import { PendingBoxes } from "../../organisms/pendingBoxes/PendingBoxes";
import { RealTimeBoxProductionChart } from "../../organisms/realTimeBoxProductionChart/RealTimeBoxProductionChart";
import { PendingBoxProjection } from "../../../../core/projection/pendingBox/pendingBox";
import { PersonalShopperProductionProjection } from "../../../../core/projection/personalShopperProduction/personalShopperProduction";
import { PersonalShopperTeamProjection } from "../../../../core/projection/personalShopperTeam/personalShopperTeam";
import "./real-time-box-production.css";

interface RealTimeBoxProductionProps {
  readonly personalShopperTeams: PersonalShopperTeamProjection[] | undefined;
  readonly personalShopperCountries: PersonalShopperCountryProjection[] | undefined;
  readonly personalShopperProduction: PersonalShopperProductionProjection[] | undefined;
  readonly pendingBoxes: PendingBoxProjection[] | undefined;
  readonly personalShopperTeamSelected?: string;
  readonly personalShopperCountrySelected?: PersonalShopperCountryProjection;
  readonly onPersonalShopperTeamSelectedChanged: (personalShopperTeamSelected: string) => void;
  readonly onPersonalShopperCountrySelectedChanged: (
    personalShopperCountrySelected: PersonalShopperCountryProjection,
  ) => void;
}
const RealTimeBoxProduction: FC<RealTimeBoxProductionProps> = ({
  personalShopperTeams = [],
  personalShopperCountries = [],
  personalShopperProduction = [],
  pendingBoxes = [],
  personalShopperTeamSelected,
  personalShopperCountrySelected,
  onPersonalShopperTeamSelectedChanged,
  onPersonalShopperCountrySelectedChanged,
}) => (
  <div className="real-time-box-production">
    <header className="real-time-box-production__header">
      <PersonalShopperTeamsDropdown
        personalShopperTeamSelected={personalShopperTeamSelected}
        personalShopperTeams={personalShopperTeams}
        onChanged={onPersonalShopperTeamSelectedChanged}
      />
      <PersonalShopperCountriesDropdown
        personalShopperCountries={personalShopperCountries}
        personalShopperCountrySelected={personalShopperCountrySelected}
        onChanged={onPersonalShopperCountrySelectedChanged}
      />
    </header>

    <div className="real-time-box-production__content">
      {personalShopperProduction.length > 0 && (
        <RealTimeBoxProductionChart personalShopperProduction={personalShopperProduction} />
      )}
      <PendingBoxes pendingBoxes={pendingBoxes} />
    </div>
  </div>
);

export { RealTimeBoxProduction };
