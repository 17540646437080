import { useRef, useCallback, useState, FC, MouseEventHandler } from "react";
import { useClickAway } from "react-use";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { NavbarElementProps } from "react-day-picker/types/Props";
import classNames from "classnames";
import "react-day-picker/lib/style.css";
import Icon, { IconVariant } from "../../atoms/icon/Icon";
import Text from "../../atoms/text/Text";
import Button from "../../atoms/button/Button";
import Error from "../../atoms/error/Error";
import "./date-picker.css";

const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const WEEKDAYS_LONG = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
const WEEKDAYS_SHORT = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"];

const NavBar: FC<NavbarElementProps> = ({ onPreviousClick, onNextClick }: NavbarElementProps) => {
  const handlePreviousClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      onPreviousClick();
    },
    [onPreviousClick],
  );
  const handleNextClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      onNextClick();
    },
    [onNextClick],
  );
  return (
    <div className="date-picker__navbar">
      <Button icon={IconVariant.CHEVRON_LEFT} type="button" onClick={handlePreviousClick} />
      <Button icon={IconVariant.CHEVRON_RIGHT} type="button" onClick={handleNextClick} />
    </div>
  );
};

interface DatePickerProps {
  readonly minDate?: Date;
  readonly value?: Date;
  readonly label?: string;
  readonly readOnly?: boolean;
  readonly onChange: (date: Date) => void;
  readonly error?: string;
  readonly format?: (date: Date, dateFormat?: string) => string;
  readonly parse?: (date: string, dateFormat?: string) => Date;
}
const DatePicker: FC<DatePickerProps> = ({
  label,
  value,
  readOnly = false,
  minDate,
  onChange,
  error,
  format,
  parse,
}: DatePickerProps) => {
  const datePicker = useRef(null);
  const dayPicker = useRef<DayPickerInput>(null);
  const showPicker = useCallback(() => {
    if (dayPicker.current === null) {
      return;
    }

    dayPicker.current.showDayPicker();
  }, [dayPicker]);
  const hidePicker = useCallback(() => {
    if (dayPicker.current === null) {
      return;
    }

    dayPicker.current.hideDayPicker();
  }, [dayPicker]);

  const [shown, setShown] = useState(false);
  const handleOnDayPickerShow = useCallback(() => setShown(true), []);
  const handleOnDayPickerHide = useCallback(() => setShown(false), []);
  const handleOnClick = useCallback(() => {
    if (readOnly) {
      return;
    }

    if (shown) {
      hidePicker();
    } else {
      showPicker();
    }
  }, [hidePicker, showPicker, shown, readOnly]);

  useClickAway(datePicker, hidePicker);

  const disabledDays = minDate ? { before: minDate as Date } : undefined;

  return (
    <div className={classNames("date-picker-container", { "date-picker-container--error": Boolean(error) })}>
      <div
        ref={datePicker}
        className={classNames("date-picker", { "date-picker--shown": shown }, { "date-picker--readonly": readOnly })}
        onClick={handleOnClick}
      >
        <div className="date-picker__input">
          {label && <Text className="date-picker__label">{`${label}: `}</Text>}
          <DayPickerInput
            ref={dayPicker}
            formatDate={format}
            inputProps={{ readOnly: readOnly }}
            keepFocus={false}
            parseDate={parse}
            placeholder=""
            value={value}
            dayPickerProps={{
              showOutsideDays: true,
              containerProps: { className: "date-picker__calendar" },
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              firstDayOfWeek: 1,
              navbarElement: NavBar,
              disabledDays,
            }}
            onDayChange={onChange}
            onDayPickerHide={handleOnDayPickerHide}
            onDayPickerShow={handleOnDayPickerShow}
          />
        </div>
        <div className="date-picker__indicator">
          <Icon variant={IconVariant.MINOR_DROPDOWN} />
        </div>
      </div>

      <Error error={error} />
    </div>
  );
};

export default DatePicker;
