import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { MarkProductAsFavourite } from "../command/markProductAsFavourite";
import { productMarkedAsFavourite } from "./productMarkedAsFavourite";
import { productUnmarkedAsFavourite } from "./productUnmarkedAsFavourite";
import { UnmarkProductAsFavourite } from "../command/unmarkProductAsFavourite";

interface Favourite extends AggregateRoot {
  readonly psId: string;
  readonly productId: string;
  readonly isEnabled: boolean;
}

const markProductAsFavouriteHandler: CommandHandlerFunction<MarkProductAsFavourite, Favourite> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, psId, productId } = command;

    return {
      ...aggregateRoot,
      psId,
      productId,
      isEnabled: true,
      domainEvents: [productMarkedAsFavourite({ aggregateId })],
    };
  };

const unmarkProductAsFavouriteHandler: CommandHandlerFunction<UnmarkProductAsFavourite, Favourite> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;

    return {
      ...aggregateRoot,
      isEnabled: false,
      domainEvents: [productUnmarkedAsFavourite({ aggregateId })],
    };
  };

export type { Favourite };
export { markProductAsFavouriteHandler, unmarkProductAsFavouriteHandler };
