import Country from "../../domain/country/model/Country";

enum PlannedBoxCategoryTag {
  POSSIBLE_FRAUD = "posible_fraude",
  CONFIRMED_FRAUD = "fraude_confirmado",
  INFORMATION_PENDING = "pendiente_informacion",
}

enum PlannedBoxLabel {
  ERP_NOK = "erp_nok",
  ERP_SERVIDO = "erp_servido",
  CANCELADO = "cancelado",
  PS_DONE = "ps_done",
  PAY2_NOK = "pay2_nok",
  ERP_ANULADO = "erp_anulado",
  ERP_OK = "erp_ok",
  ERP_EN_RETORNO = "erp_en_retorno",
  ERP_TERMINADO = "erp_terminado",
  PAY2_OK = "pay2_ok",
  PICKING_OK = "picking_ok",
  PICKING_NOK = "picking_nok",
  TRACK_ENTREGADO = "track_entregado",
  PS_TO_SUPERVISE = "ps_to_supervise",
  REOPENED = "box_reopened",
  DELIVERED = "entregado",
  FIRST_ORDER = "users_first_order",
  GOING = "en_marcha",
  PREPARING = "en_preparacion",
}

enum PlannedBoxTag {
  READY = "READY",
  POSSIBLE_FRAUD = "POSSIBLE_FRAUD",
  PREVIEW_SENT = "PREVIEW_SENT",
  PREVIEW_SUBMITTED = "PREVIEW_SUBMITTED",
  COMPLETED = "COMPLETED",
}

enum PlannedBoxesAssignment {
  ASSIGNED = "assigned",
  NON_ASSIGNED = "non_assigned",
}

interface Customer {
  readonly fullName: string;
  readonly boxCount: number;
}

interface PlannedBox {
  readonly boxNumber: string;
  readonly psNumber: string | null;
  readonly customer: Customer;
  readonly plannedFor: Date;
  readonly country: Country;
  readonly statusLabel: PlannedBoxLabel;
  readonly tag: PlannedBoxTag;
}

export { PlannedBoxTag, PlannedBoxLabel, PlannedBoxCategoryTag, PlannedBoxesAssignment };
export type { PlannedBox };
