import { FC } from "react";
import { ReturnQuestionProjection } from "../../../core/projection/returnQuestion/returnQuestion";
import ReturnQuestion from "./ReturnQuestion";

interface ReturnQuestionsProps {
  readonly returnQuestions: ReturnQuestionProjection[];
}

const ReturnQuestions: FC<ReturnQuestionsProps> = ({ returnQuestions }) => (
  <>
    {returnQuestions.map((returnQuestion: ReturnQuestionProjection) => (
      <ReturnQuestion key={returnQuestion.id} returnQuestion={returnQuestion} returnQuestionParentId="" />
    ))}
  </>
);

export { ReturnQuestions };
