import { BootstrapDefinition } from "@lookiero/messaging";
import { BuildBootstrapFunctionReturn, bootstrap as messagingReactBootstrap } from "@lookiero/messaging-react";
import { CREATE_MODAL_NOTIFICATION } from "../../domain/notification/command/createModalNotification";
import { CREATE_TOAST_NOTIFICATION } from "../../domain/notification/command/createToastNotification";
import { REMOVE_NOTIFICATION } from "../../domain/notification/command/removeNotification";
import {
  createModalNotificationHandler,
  createToastNotificationHandler,
  removeNotificationHandler,
} from "../../domain/notification/model/notification";
import { LIST_NOTIFICATIONS, listNotificationsHandler } from "../../projection/notification/listNotifications";
import { getNotification, saveNotification } from "../domain/notification/model/storageNotifications";
import { NotificationDto } from "../persistence/notificationDto";
import { storageListNotificationsView } from "../projection/notification/storageListNotificationsView";
import { Storage } from "../../../../core/infrastructure/persistence/storage";

const NOTIFICATIONS_MESSAGING_CONTEXT_ID = "notifications";

interface BootstrapFunctionArgs {
  readonly storage: Storage<NotificationDto>;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): BuildBootstrapFunctionReturn;
}

const bootstrap: BootstrapFunction = ({ storage }) =>
  messagingReactBootstrap({ id: NOTIFICATIONS_MESSAGING_CONTEXT_ID })
    .query(LIST_NOTIFICATIONS, listNotificationsHandler, { view: storageListNotificationsView({ storage }) })
    .command(CREATE_TOAST_NOTIFICATION, createToastNotificationHandler)(getNotification, saveNotification, { storage })
    .command(CREATE_MODAL_NOTIFICATION, createModalNotificationHandler)(getNotification, saveNotification, { storage })
    .command(REMOVE_NOTIFICATION, removeNotificationHandler)(getNotification, saveNotification, { storage })
    .build();

interface BootstrapWithBuilderFunctionArgs extends BootstrapFunctionArgs {
  readonly messaging: BootstrapDefinition<BuildBootstrapFunctionReturn>;
}
interface BootstrapWithBuilderFunction {
  (args: BootstrapWithBuilderFunctionArgs): BootstrapDefinition<BuildBootstrapFunctionReturn>;
}

const bootstrapWithBuilder: BootstrapWithBuilderFunction = ({ storage, messaging }) =>
  messaging
    .query(LIST_NOTIFICATIONS, listNotificationsHandler, { view: storageListNotificationsView({ storage }) })
    .command(CREATE_TOAST_NOTIFICATION, createToastNotificationHandler)(getNotification, saveNotification, { storage })
    .command(CREATE_MODAL_NOTIFICATION, createModalNotificationHandler)(getNotification, saveNotification, { storage })
    .command(REMOVE_NOTIFICATION, removeNotificationHandler)(getNotification, saveNotification, { storage });

export { NOTIFICATIONS_MESSAGING_CONTEXT_ID, bootstrap, bootstrapWithBuilder };
export type { BootstrapFunctionArgs };
