import {
  IsAutomaticSelectionEnabledResult,
  IsAutomaticSelectionEnabledView,
} from "../../../projection/selection/isAutomaticSelectionEnabled";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpIsAutomaticSelectionEnabledView extends IsAutomaticSelectionEnabledView {}

interface HttpIsAutomaticSelectionEnabledViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpIsAutomaticSelectionEnabledViewFunction {
  (args: HttpIsAutomaticSelectionEnabledViewFunctionArgs): HttpIsAutomaticSelectionEnabledView;
}

const httpIsAutomaticSelectionEnabledView: HttpIsAutomaticSelectionEnabledViewFunction =
  ({ httpPost }) =>
  async ({ psId, signal }) =>
    await httpPost<IsAutomaticSelectionEnabledResult>({
      endpoint: "/is-automatic-selection-enabled",
      body: { psId },
      signal,
      result: {
        error: false,
        success: (response) => response,
      },
    });

export { httpIsAutomaticSelectionEnabledView };
