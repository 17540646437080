import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ProductVariantWithProductInfoProjection } from "./productVariantWithProductInfo";

const VIEW_PRODUCT_VARIANT_WITH_PRODUCT_INFO_BY_ID = "view_product_variant_with_product_info_by_id";

interface ViewProductVariantWithProductInfoByIdPayload {
  readonly productVariantId: string;
}

interface ViewProductVariantWithProductInfoById
  extends Query<typeof VIEW_PRODUCT_VARIANT_WITH_PRODUCT_INFO_BY_ID>,
    ViewProductVariantWithProductInfoByIdPayload {}

interface ViewProductVariantWithProductInfoByIdFunction {
  (payload: ViewProductVariantWithProductInfoByIdPayload): ViewProductVariantWithProductInfoById;
}

const viewProductVariantWithProductInfoById: ViewProductVariantWithProductInfoByIdFunction = (payload) => ({
  ...query({ name: VIEW_PRODUCT_VARIANT_WITH_PRODUCT_INFO_BY_ID }),
  ...payload,
});

type ViewProductVariantWithProductInfoByIdResult = ProductVariantWithProductInfoProjection | null;

interface ProductVariantWithProductInfoByIdViewArgs extends CancelableQueryViewArgs {
  readonly productVariantId: string;
}

interface ProductVariantWithProductInfoByIdView {
  (args: ProductVariantWithProductInfoByIdViewArgs): Promise<ViewProductVariantWithProductInfoByIdResult>;
}

interface ViewProductVariantWithProductInfoByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ProductVariantWithProductInfoByIdView;
}

const viewProductVariantWithProductInfoByIdHandler: QueryHandlerFunction<
  ViewProductVariantWithProductInfoById,
  ViewProductVariantWithProductInfoByIdResult,
  ViewProductVariantWithProductInfoByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ productVariantId }) =>
    view({ productVariantId, signal });

export type {
  ViewProductVariantWithProductInfoById,
  ProductVariantWithProductInfoByIdView,
  ViewProductVariantWithProductInfoByIdResult,
};
export {
  VIEW_PRODUCT_VARIANT_WITH_PRODUCT_INFO_BY_ID,
  viewProductVariantWithProductInfoById,
  viewProductVariantWithProductInfoByIdHandler,
};
