import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { PersonalShopperFilteringProjection } from "./personalShopperFiltering";

const VIEW_PERSONAL_SHOPPER_FILTERING_BY_ID = "view_personal_shopper_filtering_by_id";

interface ViewPersonalShopperFilteringByIdPayload {
  readonly id: string;
}

interface ViewPersonalShopperFilteringById
  extends Query<typeof VIEW_PERSONAL_SHOPPER_FILTERING_BY_ID>,
    ViewPersonalShopperFilteringByIdPayload {}

interface ViewPersonalShopperFilteringByIdFunction {
  (payload: ViewPersonalShopperFilteringByIdPayload): ViewPersonalShopperFilteringById;
}

const viewPersonalShopperFilteringById: ViewPersonalShopperFilteringByIdFunction = (payload) => ({
  ...query({ name: VIEW_PERSONAL_SHOPPER_FILTERING_BY_ID }),
  ...payload,
});

type ViewPersonalShopperFilteringByIdResult = PersonalShopperFilteringProjection | null;

interface PersonalShopperFilteringByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface PersonalShopperFilteringByIdView {
  (args: PersonalShopperFilteringByIdViewArgs): Promise<ViewPersonalShopperFilteringByIdResult>;
}

interface ViewPersonalShopperFilteringByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PersonalShopperFilteringByIdView;
}

const viewPersonalShopperFilteringByIdHandler: QueryHandlerFunction<
  ViewPersonalShopperFilteringById,
  ViewPersonalShopperFilteringByIdResult,
  ViewPersonalShopperFilteringByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type {
  ViewPersonalShopperFilteringById,
  PersonalShopperFilteringByIdView,
  ViewPersonalShopperFilteringByIdResult,
};
export {
  VIEW_PERSONAL_SHOPPER_FILTERING_BY_ID,
  viewPersonalShopperFilteringById,
  viewPersonalShopperFilteringByIdHandler,
};
