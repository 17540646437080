import { FC, useState } from "react";
import type { StyleProfileCustomer } from "../../../../../../core/projection/styleProfile/styleProfile";
import { StyleProfileCard } from "../../../../../componentLibrary/organisms/styleProfileCard/StyleProfileCard";
import { StyleProfileQuestion } from "../../../../../componentLibrary/organisms/styleProfileQuestion/StyleProfileQuestion";
import { TextQuestionItem } from "../../../../../componentLibrary/organisms/styleProfileQuestion/components/textQuestionItem/TextQuestionItem";
import Pill from "../../../../../../shared/ui/uiKit/molecules/pill/Pill";
import { SocialNetworksQuestionItem } from "../../../../../componentLibrary/organisms/styleProfileQuestion/components/socialNetworksQuestionItem/SocialNetworksQuestionItem";
import { I18nMessage } from "@lookiero/i18n-react";
import { CustomerI18n } from "../../../../../componentLibrary/_i18n/CustomerI18n";
import { Forecast } from "../../forecast/Forecast";
import { IconVariant } from "../../../../../../shared/ui/uiKit/atoms/icon/Icon";
import Button from "../../../../../../shared/ui/uiKit/atoms/button/Button";
import { CustomerName } from "../../customerName/CustomerName";
import { CustomerCarousel } from "../customerCarousel/CustomerCarousel";
import "./customer-card.css";

interface CustomerCardProps {
  readonly customerId: string;
  readonly customer: StyleProfileCustomer;
  readonly isCustomerInsightEnabled?: boolean;
  readonly onClickLookAndLike: () => void;
  readonly onClickPhotos: () => void;
}
const CustomerCard: FC<CustomerCardProps> = ({
  customerId,
  customer,
  isCustomerInsightEnabled = false,
  onClickLookAndLike,
  onClickPhotos,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <StyleProfileCard className="customer-card" label="customer-card">
      <CustomerCarousel
        activeIndex={activeIndex}
        photos={customer.photos}
        onChange={setActiveIndex}
        onClick={onClickPhotos}
      />

      <div className="customer-card__banner">
        <Pill>
          <I18nMessage id={customer.segment.translationKey} />
        </Pill>

        <div className="customer-card__banner-icons">
          <SocialNetworksQuestionItem socialNetworks={customer.socialNetworks} />

          {!isCustomerInsightEnabled && (
            <Button
              aria-label="look-and-like"
              className="customer-card__look-and-like"
              icon={IconVariant.NEWSFEED}
              type="button"
              onClick={onClickLookAndLike}
            />
          )}
        </div>
      </div>

      <div className="customer-card__container">
        <CustomerName customer={customer} />

        {customer.employment && (
          <StyleProfileQuestion direction="row" title={CustomerI18n.WORK}>
            <TextQuestionItem>{customer.employment}</TextQuestionItem>
          </StyleProfileQuestion>
        )}

        <Forecast city={customer.address.city} customerId={customerId} region={customer.address.region} />
      </div>
    </StyleProfileCard>
  );
};

export { CustomerCard };
