import Country from "../../../../../core/domain/country/model/Country";
import { ACTION_TIME, DEFAULT_ACTION_TIME } from "./actionTimes";

interface ActionTimeForCountryByPreviewSelectionRateFunctionArgs {
  readonly country: Country | undefined;
  readonly selectionRate: number | null | undefined;
}

interface ActionTimeForCountryByPreviewSelectionRateFunction {
  (args: ActionTimeForCountryByPreviewSelectionRateFunctionArgs): number | undefined; // seconds
}

const actionTimeForCountryByPreviewSelectionRate: ActionTimeForCountryByPreviewSelectionRateFunction = ({
  selectionRate,
  country,
}) => {
  if (!country) {
    return undefined;
  }

  if (selectionRate !== null && selectionRate !== undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ACTION_TIME[selectionRate][country] * 60;
  }

  return DEFAULT_ACTION_TIME[country] * 60;
};

export { actionTimeForCountryByPreviewSelectionRate };
