import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const PRODUCT_VARIANT_REPLACED = "product_variant_replaced";

interface ProductVariantReplacedPayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
  readonly selectedProductVariantId: string;
  readonly deselectedProductVariantId: string;
  readonly productVariantIds: string[];
}

interface ProductVariantReplaced extends DomainEvent<typeof PRODUCT_VARIANT_REPLACED>, ProductVariantReplacedPayload {}

interface ProductVariantReplacedFunction {
  (payload: ProductVariantReplacedPayload): ProductVariantReplaced;
}

const productVariantReplaced: ProductVariantReplacedFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: PRODUCT_VARIANT_REPLACED }),
  ...payload,
});

const isProductVariantReplaced = (event: DomainEvent<MessageName>): event is ProductVariantReplaced =>
  event.name === PRODUCT_VARIANT_REPLACED;

export type { ProductVariantReplaced };
export { PRODUCT_VARIANT_REPLACED, productVariantReplaced, isProductVariantReplaced };
