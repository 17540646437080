import Country from "../../../../../core/domain/country/model/Country";
import { ActionTime, ActionTimeByCountry } from "./actionTime";

const DEFAULT_ACTION_TIME: ActionTimeByCountry = {
  [Country.FR]: 2.5,
  [Country.IT]: 2.5,
  [Country.BE]: 2.5,
  [Country.LU]: 2.5,
  [Country.DE]: 2.5,
  [Country.GB]: 2.5,
  [Country.AT]: 2.5,
  [Country.NL]: 2.5,
  [Country.ES]: 2.5,
  [Country.SE]: 2.5,
  [Country.PT]: 2.5,
  [Country.CH]: 2.5,
};

const ACTION_TIME: ActionTime = {
  /* eslint-disable @typescript-eslint/naming-convention */
  5: {
    [Country.FR]: 2.5,
    [Country.IT]: 2.5,
    [Country.BE]: 2.5,
    [Country.LU]: 2.5,
    [Country.DE]: 2.5,
    [Country.GB]: 2.5,
    [Country.AT]: 2.5,
    [Country.NL]: 2.5,
    [Country.ES]: 2.5,
    [Country.SE]: 2.5,
    [Country.PT]: 2.5,
    [Country.CH]: 2.5,
  },
  4: {
    [Country.FR]: 4,
    [Country.IT]: 4,
    [Country.BE]: 4,
    [Country.LU]: 4,
    [Country.DE]: 4,
    [Country.GB]: 4,
    [Country.AT]: 4,
    [Country.NL]: 4,
    [Country.ES]: 4,
    [Country.SE]: 4,
    [Country.PT]: 4,
    [Country.CH]: 4,
  },
  3: {
    [Country.FR]: 5,
    [Country.IT]: 5,
    [Country.BE]: 5,
    [Country.LU]: 5,
    [Country.DE]: 5,
    [Country.GB]: 5,
    [Country.AT]: 5,
    [Country.NL]: 5,
    [Country.ES]: 5,
    [Country.SE]: 5,
    [Country.PT]: 5,
    [Country.CH]: 5,
  },
  2: {
    [Country.FR]: 6.5,
    [Country.IT]: 6.5,
    [Country.BE]: 6.5,
    [Country.LU]: 6.5,
    [Country.DE]: 6.5,
    [Country.GB]: 7.5,
    [Country.AT]: 6.5,
    [Country.NL]: 6.5,
    [Country.ES]: 6.5,
    [Country.SE]: 6.5,
    [Country.PT]: 6.5,
    [Country.CH]: 6.5,
  },
  1: {
    [Country.FR]: 7.5,
    [Country.IT]: 7.5,
    [Country.BE]: 7.5,
    [Country.LU]: 7.5,
    [Country.DE]: 7.5,
    [Country.GB]: 8.5,
    [Country.AT]: 7.5,
    [Country.NL]: 7.5,
    [Country.ES]: 7.5,
    [Country.SE]: 7.5,
    [Country.PT]: 7.5,
    [Country.CH]: 7.5,
  },
  0: {
    [Country.FR]: 10.5,
    [Country.IT]: 10.5,
    [Country.BE]: 10.5,
    [Country.LU]: 10.5,
    [Country.DE]: 10.5,
    [Country.GB]: 10.5,
    [Country.AT]: 10.5,
    [Country.NL]: 10.5,
    [Country.ES]: 10.5,
    [Country.SE]: 10.5,
    [Country.PT]: 10.5,
    [Country.CH]: 10.5,
  },
  /* eslint-enable @typescript-eslint/naming-convention */
};

export { DEFAULT_ACTION_TIME, ACTION_TIME };
