import { useI18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import PositiveFeedbackEnum from "../../../../core/domain/positiveFeedback/model/PositiveFeedback";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../_i18n/HistoryI18n";
import "./positive-feedback.css";

const iconAndLabelForPositiveFeedback = (
  positiveFeedback: PositiveFeedbackEnum,
): { icon: IconVariant; label: string } => {
  switch (positiveFeedback) {
    case PositiveFeedbackEnum.STYLE:
      return {
        icon: IconVariant.HEART_FILLED,
        label: HistoryI18n.POSITIVE_FEEDBACK_STYLE,
      };
  }
};

type PositiveFeedbackProps = {
  readonly positiveFeedback: PositiveFeedbackEnum;
};
const PositiveFeedback: FC<PositiveFeedbackProps> = ({ positiveFeedback }: PositiveFeedbackProps) => {
  const { icon, label } = iconAndLabelForPositiveFeedback(positiveFeedback);
  const tooltipLabel = useI18nMessage({ id: label, prefix: HISTORY_I18N_PREFIX });

  return (
    <div className="positive-feedback">
      <Tooltip title={tooltipLabel}>
        <Icon variant={icon} />
      </Tooltip>
    </div>
  );
};

export default PositiveFeedback;
