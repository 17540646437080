import { DomainEvent } from "@lookiero/messaging.js";

type BoxPreviewSentParameters = {
  readonly boxPreviewId: string;
  readonly boxId: string;
  readonly selectionId: string;
};
class BoxPreviewSent extends DomainEvent {
  public readonly boxPreviewId: string;
  public readonly boxId: string;
  public readonly selectionId: string;

  public constructor({ boxPreviewId, boxId, selectionId }: BoxPreviewSentParameters) {
    super();

    this.boxPreviewId = boxPreviewId;
    this.boxId = boxId;
    this.selectionId = selectionId;
  }

  public messageName(): string {
    return "BoxPreviewSent";
  }
}

export default BoxPreviewSent;
