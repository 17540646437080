import { FC } from "react";
import { ShortlistFiltersPositives } from "../../../componentLibrary/organisms/shortlistFilters/components/shortlistFiltersPositives/ShortlistFiltersPositives";
import ShortlistFilterSearch from "../shortlistFilterSearch/ShortlistFilterSearch";
import { ShortlistFiltersClearButton } from "../../../componentLibrary/organisms/shortlistFilters/components/shortlistFiltersClearButton/ShortlistFiltersClearButton";
import { ShortlistFilterProjection } from "../../../../core/projection/shortlistFilter/shortlistFilter";
import "./shortlist-filters.css";

interface ShortlistFiltersProps {
  readonly filters: ShortlistFilterProjection[];
  readonly search: string;
  readonly onSearchChanged: (search: string) => void;
}

const ShortlistFilters: FC<ShortlistFiltersProps> = ({ search, filters, onSearchChanged }) => (
  <div className="shortlist-filters" data-testid="shortlist-filters">
    <ShortlistFilterSearch value={search} onChange={onSearchChanged} />
    <ShortlistFiltersPositives filters={filters} />
    <ShortlistFiltersClearButton />
  </div>
);

export { ShortlistFilters };
