import { Storage } from "./storage";

interface InMemoryStorageFunction {
  <T>(): Storage<T>;
}

const inMemoryStorage: InMemoryStorageFunction = <T>() => {
  const storage: Record<string, T> = {};

  const read = async (key: string) => storage[key] || null;

  const list = async () => Object.values(storage);

  const write = async (key: string, value: T) => {
    storage[key] = value;
  };

  const remove = async (key: string) => {
    delete storage[key];
  };

  return { read, list, write, remove };
};

export { inMemoryStorage };
