import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import {
  isAutomaticSelectionEnabled,
  IsAutomaticSelectionEnabledResult,
} from "../../../../projection/selection/isAutomaticSelectionEnabled";

interface UseIsAutomaticSelectionEnabledFunctionArgs {
  readonly psId: string | undefined;
}

interface UseIsAutomaticSelectionEnabledFunction {
  (args: UseIsAutomaticSelectionEnabledFunctionArgs): UseQueryFunctionResult<IsAutomaticSelectionEnabledResult>;
}

const useIsAutomaticSelectionEnabled: UseIsAutomaticSelectionEnabledFunction = ({ psId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: isAutomaticSelectionEnabled({ psId: psId as string }),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(psId),
    },
  });

export { useIsAutomaticSelectionEnabled };
