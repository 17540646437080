import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Locale } from "@lookiero/i18n";
import { I18nMessage, useI18nMessage } from "@lookiero/i18n-react";
import useEnvironment from "../_behaviors/useEnvironment";
import { useCountCapacityCalendarByCriteria } from "../../../core/infrastructure/projection/capacityCalendar/react/useCountCapacityCalendarByCriteria";
import { Segment } from "../../../core/projection/segment/segment";
import { useViewCapacityCalendarByCriteria } from "../../../core/infrastructure/projection/capacityCalendar/react/useViewCapacityCalendarByCriteria";
import Country from "../../../core/domain/country/model/Country";
import useQueryParameters from "../../_routing/queryParams/useQueryParameters";
import QueryParams from "../../_routing/queryParams/config";
import Button, { ButtonVariant } from "../../../shared/ui/uiKit/atoms/button/Button";
import { IconVariant } from "../../../shared/ui/uiKit/atoms/icon/Icon";
import { CAPACITY_I18N_PREFIX, CapacityI18n } from "../../componentLibrary/_i18n/CapacityI18n";
import { CapacityFilters } from "./capacityFilters/CapacityFilters";
import { CapacityListHeader } from "./capacityListHeader/CapacityListHeader";
import { CapacityList } from "./capacityList/CapacityList";
import { useSynchronizeCapacityCalendar } from "../../../core/infrastructure/domain/capacityCalendar/react/useSynchronizeCapacityCalendar";
import { CommandStatus } from "@lookiero/messaging-react";
import { useCreateToastNotification } from "../../../shared/notifications/infrastructure/domain/notification/react/useCreateToastNotification";
import { MESSAGING_CONTEXT_ID } from "../../../core/container/bootstrap";
import { useLogger } from "../../../shared/logging/useLogger";
import { NotificationLevel } from "../../../shared/notifications/domain/notification/model/notification";
import "./capacity.css";

const today = new Date();
const DEFAULT_FROM_DATE = today;
const DEFAULT_TO_DATE = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 29);

const Capacity: FC = () => {
  const logger = useLogger();
  const {
    capacity: { perPage },
  } = useEnvironment();
  const { locale } = useParams();
  const { params, setParams } = useQueryParameters();

  const page = parseInt(params[QueryParams.PAGE] as string) || 1;
  const from = params[QueryParams.FROM] ? new Date(params[QueryParams.FROM] as string) : DEFAULT_FROM_DATE;
  const to = params[QueryParams.TO] ? new Date(params[QueryParams.TO] as string) : DEFAULT_TO_DATE;
  const countryParams = params[QueryParams.COUNTRY];
  const country = countryParams
    ? Array.isArray(countryParams)
      ? (countryParams as Country[])
      : ([countryParams] as Country[])
    : [];
  const segmentParams = params[QueryParams.SEGMENT];
  const segment = segmentParams
    ? Array.isArray(segmentParams)
      ? (segmentParams as Segment[])
      : ([segmentParams] as Segment[])
    : [];

  const handleCountryChanged = useCallback(
    (country: Country[]) => setParams({ [QueryParams.COUNTRY]: country || null }),
    [setParams],
  );

  const handleFromChanged = useCallback(
    (from: Date) => {
      const date = from
        ? new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(from)
        : null;

      setParams({ [QueryParams.FROM]: date });
    },
    [setParams],
  );

  const handleToChanged = useCallback(
    (to: Date) => {
      const date = to
        ? new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(to)
        : null;

      setParams({ [QueryParams.TO]: date });
    },
    [setParams],
  );

  const handleOnSegmentChanged = useCallback(
    (segment: Segment | Segment[]) => setParams({ [QueryParams.SEGMENT]: segment || null }),
    [setParams],
  );

  const handleOnPageChanged = useCallback(
    (page: number): void => setParams({ [QueryParams.PAGE]: page === 1 ? null : String(page) }),
    [setParams],
  );

  const [countCapacityCalendar] = useCountCapacityCalendarByCriteria({
    from,
    to,
    markets: country,
    segments: segment,
    itemCountWhileQuerying: perPage,
  });
  const [capacityCalendar] = useViewCapacityCalendarByCriteria({
    from,
    to,
    markets: country,
    segments: segment,
    page,
    perPage,
  });
  const [synchronizeCapacityCalendar, synchronizeCapacityCalendarStatus] = useSynchronizeCapacityCalendar({
    segments: segment,
  });

  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });
  const synchronizeCapacityCalendarSuccessMessage = useI18nMessage({
    id: CapacityI18n.SYNC_NOTIFICATION_SUCCESS,
    prefix: CAPACITY_I18N_PREFIX,
  });
  const synchronizeCapacityCalendarErrorMessage = useI18nMessage({
    id: CapacityI18n.SYNC_NOTIFICATION_ERROR,
    prefix: CAPACITY_I18N_PREFIX,
  });

  const handleOnPress = useCallback(async () => {
    try {
      await synchronizeCapacityCalendar();

      createNotification({
        aggregateId: "sync-calendar-capacity-success",
        level: NotificationLevel.SUCCESS,
        bodyI18nKey: synchronizeCapacityCalendarSuccessMessage,
      });
    } catch (error) {
      createNotification({
        aggregateId: "sync-calendar-capacity-error",
        level: NotificationLevel.ERROR,
        bodyI18nKey: synchronizeCapacityCalendarErrorMessage,
      });
    }
  }, [
    createNotification,
    synchronizeCapacityCalendarErrorMessage,
    synchronizeCapacityCalendarSuccessMessage,
    synchronizeCapacityCalendar,
  ]);

  return (
    <section className="capacity">
      <header className="capacity__header">
        <div className="capacity__header-content">
          <div className="capacity__header-content__row">
            <CapacityFilters
              country={country}
              from={from}
              segment={segment}
              to={to}
              onCountryChanged={handleCountryChanged}
              onFromChanged={handleFromChanged}
              onSegmentChanged={handleOnSegmentChanged}
              onToChanged={handleToChanged}
            />

            <div>
              <Button
                disabled={synchronizeCapacityCalendarStatus === CommandStatus.LOADING}
                icon={IconVariant.SYNC}
                variant={ButtonVariant.PRIMARY}
                onClick={handleOnPress}
              >
                <I18nMessage id={CapacityI18n.SYNC_BUTTON} prefix={CAPACITY_I18N_PREFIX} />
              </Button>
            </div>
          </div>

          <CapacityListHeader />
        </div>
      </header>

      <div className="capacity__content">
        <CapacityList
          capacityCalendar={capacityCalendar}
          countCapacityCalendar={countCapacityCalendar}
          locale={locale as Locale}
          page={page}
          perPage={perPage}
          onPageChanged={handleOnPageChanged}
        />
      </div>
    </section>
  );
};

export { Capacity };
