import { FC, useEffect, useState } from "react";
import Cookies from "js-cookie";
import AspectRatio from "../../../../../../shared/ui/uiKit/_common/AspectRatio";
import Picture from "../../../../../../shared/ui/uiKit/atoms/picture/Picture";
import useEnvironment from "../../../../_behaviors/useEnvironment";
import "./customer-image.css";

interface CustomerImageProps {
  readonly id: string;
}
const CustomerImage: FC<CustomerImageProps> = ({ id }) => {
  const { labsBackStyleProfileApi } = useEnvironment();
  const [image, setImage] = useState<string>();
  const authToken = Cookies.get("authtoken");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`${labsBackStyleProfileApi}/view-style-profile-image-by-id/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const blob = await response.blob();

        setImage(URL.createObjectURL(blob));
      } catch (error) {
        console.error(error);
      }
    };

    fetchImage();
  }, [authToken, id, labsBackStyleProfileApi]);

  return <Picture aspectRatio={AspectRatio.R_1_1} className="customer-image" label="customer-image" src={image} lazy />;
};

export { CustomerImage };
