import { SelectionAlertProjection } from "../../core/projection/alert/selectionAlert";
import { LookProjection } from "../../core/projection/looks/look";
import { LookTemplate } from "../../core/projection/looks/lookTemplate";

enum TrackingPage {
  CUSTOMER = "customer",
  CATALOG = "catalog",
  SELECTION = "selection",
  SELECTION_SUMMARY = "selection-summary",
  HISTORY_BOXES = "history-boxes",
  HISTORY_SHOP = "history-shop",
}

enum TrackingClick {
  CLEAR_FILTERS = "clear-filters",
  CREATE_LOOK = "create-look",
  ACCESS_CREATE_LOOK = "access-create-look",
  SEND_PREVIEW = "send-preview",
  CHANGE_LOOK_TEMPLATE = "change-look-template",
  COMPARE = "compare",
  CLOSE_BOX = "close-box",
  NOTE_ACTION = "note-action",
  ITEM = "item",
  UPDATE_ANNOTATIONS = "update-annotations",
}

enum TrackingReviewedInfo {
  CHECKOUT_COMMENT = "checkout_comment",
  ORDER_COMMENT = "order_comment",
  PREVIEW = "preview",
}

enum TrackingFilteringChangeType {
  INCLUDE = "include",
  EXCLUDE = "exclude",
  BOTH = "both",
}

enum Subsection {
  CATALOG = "catalog",
  SELECTION = "selection",
  ITEM = "item",
}

type Section = TrackingPage;

interface BaseTrackerParams {
  readonly section: Section;
  readonly psId: string;
  readonly boxId?: string;
  readonly userId?: string;
}

type NoteAction =
  | "bold"
  | "undo"
  | "redo"
  | "preview-note"
  | "view-history-notes"
  | "view-note-templates"
  | "note_assistant";

interface ClickTrackerParams extends BaseTrackerParams {
  readonly clickId: TrackingClick;
  readonly look?: LookProjection;
  readonly lookTemplate?: LookTemplate;
  readonly productVariantIds?: string[];
  readonly action?: NoteAction;
  readonly position?: number;
  readonly productId?: string;
  readonly alerts?: SelectionAlertProjection[];
}

interface DetailViewParams extends BaseTrackerParams {
  readonly productId: string;
  readonly productVariantId?: string;
}

interface SelectDeselectProductVariantTrackerParams extends BaseTrackerParams {
  readonly position?: number;
  readonly productVariantId: string;
  readonly select: boolean;
  readonly subsection: Subsection;
}

interface FilterTrackerParams extends BaseTrackerParams {
  readonly filters: string;
}

interface CheckLastBoxesInfoTrackerParams extends BaseTrackerParams {
  readonly checkedBoxId?: string;
  readonly reviewedInfo: TrackingReviewedInfo;
}

interface FilteringModifiedTrackerParams extends BaseTrackerParams {
  readonly changeType: TrackingFilteringChangeType;
}

interface ActivatePredefinedFilterTrackerParams extends BaseTrackerParams {
  readonly enabled: boolean;
}

interface BoxClosedTrackerParams extends BaseTrackerParams {
  readonly boxNumber: string;
}

interface PreviewSentTrackerParams extends BaseTrackerParams {
  readonly boxNumber: string;
}

interface GenerateNoteTrackerParams extends BaseTrackerParams {}

interface ShortlistPageViewTrackerParams extends BaseTrackerParams {
  readonly page: number;
  readonly perPage: number;
  readonly count: number;
}

interface AnnotationsPageViewTrackerParams extends BaseTrackerParams {}

interface MarkUnmarkFavouriteTrackerParams extends BaseTrackerParams {
  readonly position?: number;
  readonly productId: string;
  readonly selected: boolean;
  readonly boxNumber: string;
}

interface Tracker {
  click(params: ClickTrackerParams): void;
  detailView(params: DetailViewParams): void;
  selectDeselectProductVariant(params: SelectDeselectProductVariantTrackerParams): void;
  filters(params: FilterTrackerParams): void;
  checkLastBoxesInfo(params: CheckLastBoxesInfoTrackerParams): void;
  filteringModified(params: FilteringModifiedTrackerParams): void;
  activatePredefinedFilter(params: ActivatePredefinedFilterTrackerParams): void;
  previewSent(params: PreviewSentTrackerParams): void;
  generateNote(params: GenerateNoteTrackerParams): void;
  shortlistPageView(params: ShortlistPageViewTrackerParams): void;
  annotationsPageView(params: AnnotationsPageViewTrackerParams): void;
  markUnmarkFavourite(params: MarkUnmarkFavouriteTrackerParams): void;
}

export type {
  ActivatePredefinedFilterTrackerParams,
  BaseTrackerParams,
  BoxClosedTrackerParams,
  PreviewSentTrackerParams,
  CheckLastBoxesInfoTrackerParams,
  ClickTrackerParams,
  DetailViewParams,
  FilterTrackerParams,
  NoteAction,
  Section,
  SelectDeselectProductVariantTrackerParams,
  FilteringModifiedTrackerParams,
  Tracker,
  GenerateNoteTrackerParams,
  ShortlistPageViewTrackerParams,
  MarkUnmarkFavouriteTrackerParams,
};
export { TrackingClick, TrackingPage, TrackingReviewedInfo, TrackingFilteringChangeType, Subsection };
