import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import {
  SearchShortlistProductsByShortlistFiltersResult,
  ShortlistProductsSearchByShortlistFiltersView,
} from "../../../projection/shortlist/searchShortlistProductsByShortlistFilters";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { ShortlistProductDto, toShortlistProductProjection } from "./shortlist";

interface HttpShortlistProductsSearchByShortlistFiltersView extends ShortlistProductsSearchByShortlistFiltersView {}

interface HttpShortlistProductsSearchByShortlistFiltersViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpShortlistProductsSearchByShortlistFiltersViewFunction {
  (
    args: HttpShortlistProductsSearchByShortlistFiltersViewFunctionArgs,
  ): HttpShortlistProductsSearchByShortlistFiltersView;
}

interface SearchShortlistProductsByShortlistFiltersResponse {
  readonly result: string | ShortlistProductDto[];
}

const httpShortlistProductsSearchByShortlistFiltersView: HttpShortlistProductsSearchByShortlistFiltersViewFunction =
  ({ httpPost }) =>
  async ({
    criteria: { psId, boxNumber, search, favourites, shortlistFilters, negativeFilters, page, perPage },
    signal,
  }) =>
    await httpPost<SearchShortlistProductsByShortlistFiltersResponse, SearchShortlistProductsByShortlistFiltersResult>({
      endpoint: "/search-shortlist-products-by-shortlist-filters",
      body: {
        psId,
        box_number: boxNumber,
        search,
        favourites,
        filters: shortlistFilters,
        negativeFilters,
        page,
        perPage,
      },
      signal,
      result: {
        error: [],
        success: ({ result }) =>
          camelcaseKeys(typeof result === "string" ? (JSON.parse(result) as ShortlistProductDto[]) : result)
            .filter((product) => !!product)
            .map((product) => toShortlistProductProjection({ product })),
      },
    });

export { httpShortlistProductsSearchByShortlistFiltersView };
