import { EndpointFunction, fetchFetchTranslation, FetchTranslationFuction } from "@lookiero/i18n";

interface FetchTranslationsFuntionArgs {
  readonly translations: EndpointFunction[];
}

interface FetchTranslationsFuntion {
  (args: FetchTranslationsFuntionArgs): FetchTranslationFuction;
}

const fetchTranslations: FetchTranslationsFuntion =
  ({ translations }) =>
  async ({ locale }) => {
    const translationsMessages = await Promise.all(
      translations.map((endpoint) => fetchFetchTranslation({ endpoint })({ locale })),
    );

    return translationsMessages.reduce(
      (acc, translationMessages) => ({
        ...acc,
        ...translationMessages,
      }),
      {},
    );
  };

export { fetchTranslations };
