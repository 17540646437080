import QueryParams from "./config";

export type QueryParameterValue = undefined | null | string | string[] | boolean;

const read = (searchParams: URLSearchParams): Partial<Record<QueryParams, string | string[]>> =>
  Array.from(searchParams.keys()).reduce((acc, key) => {
    const value = searchParams.get(key);

    return {
      ...acc,
      ...(value && { [key]: value.includes(",") ? value.split(",") : value }),
    };
  }, {});

const produce = (
  currentParams: Partial<Record<QueryParams, string | string[]>>,
  paramsToUpdate: Partial<Record<QueryParams, QueryParameterValue>>,
): Partial<Record<QueryParams, string>> => {
  if (Object.keys(paramsToUpdate).length === 0) {
    throw new Error("You cannot provide an empty object for producing the queryParams");
  }

  const updatedParams: Partial<Record<QueryParams, QueryParameterValue>> = {
    ...currentParams,
    ...paramsToUpdate,
  };

  return Object.keys(updatedParams).reduce((acc: Partial<Record<QueryParams, string>>, name: string) => {
    const value = updatedParams[name as QueryParams];

    return {
      ...acc,
      ...(!(value === null || value === undefined || (Array.isArray(value) && value.length === 0)) && {
        [name]: Array.isArray(value) ? value.join(",") : value,
      }),
    };
  }, {} as Partial<Record<QueryParams, string>>);
};

export { QueryParams, produce, read };
