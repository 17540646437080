import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { BoxPreview } from "../../../../projection/boxPreview/boxPreview";
import { viewBoxPreviewByBoxId } from "../../../../projection/boxPreview/viewBoxPreviewByBoxId";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { useMessagingJsBridgeQuery } from "../../_messagingJsBridge/useMessagingJsBridgeQuery";
import { DomainEvent } from "@lookiero/messaging.js";
import BoxPreviewSent from "../../../../domain/boxPreview/event/BoxPreviewSent";

interface UseViewBoxPreviewByBoxIdFunctionArgs {
  readonly boxId: string | undefined;
}

interface UseViewBoxPreviewByBoxIdFunction {
  (args: UseViewBoxPreviewByBoxIdFunctionArgs): UseQueryFunctionResult<BoxPreview>;
}

const useViewBoxPreviewByBoxId: UseViewBoxPreviewByBoxIdFunction = ({ boxId }) =>
  useMessagingJsBridgeQuery({
    query: viewBoxPreviewByBoxId({ boxId: boxId as string }),
    contextId: MESSAGING_CONTEXT_ID,
    messagingJsInvalidation: (event: DomainEvent) => event instanceof BoxPreviewSent,
    options: { staleTime: Infinity, retry: 0, refetchOnWindowFocus: false, enabled: Boolean(boxId) },
  });

export { useViewBoxPreviewByBoxId };
