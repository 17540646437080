import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const PRODUCT_VARIANT_SELECTED = "product_variant_selected";

interface ProductVariantSelectedPayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
  readonly productVariantId: string;
  readonly productVariantIds: string[];
}

interface ProductVariantSelected extends DomainEvent<typeof PRODUCT_VARIANT_SELECTED>, ProductVariantSelectedPayload {}

interface ProductVariantSelectedFunction {
  (payload: ProductVariantSelectedPayload): ProductVariantSelected;
}

const productVariantSelected: ProductVariantSelectedFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: PRODUCT_VARIANT_SELECTED }),
  ...payload,
});

const isProductVariantSelected = (event: DomainEvent<MessageName>): event is ProductVariantSelected =>
  event.name === PRODUCT_VARIANT_SELECTED;

export type { ProductVariantSelected };
export { PRODUCT_VARIANT_SELECTED, productVariantSelected, isProductVariantSelected };
