import { FC } from "react";
import Brand from "../../../../core/projection/brand/model/Brand";
import PriceProjection from "../../../../core/projection/price/model/Price";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import Price, { PriceVariant } from "../../atoms/price/Price";

import "./product-detail-data.css";

interface ProductDetailDataProps {
  readonly brand: Brand;
  readonly title: string;
  readonly group: string;
  readonly season: number;
  readonly price?: PriceProjection;
}

const ProductDetailData: FC<ProductDetailDataProps> = ({ brand, title, group, season, price }) => {
  return (
    <section className="product-detail-data">
      <header className="product-detail-data__header">
        <Text className="product-detail-data__brand" variant={TextVariant.HEADING_2} ellipsis>
          {brand.name}
        </Text>
        {price && <Price price={price} variant={PriceVariant.LARGE} />}
      </header>
      <div className="product-detail-data__info">
        <Text className="product-detail-data__title" variant={TextVariant.BODY} ellipsis>
          {title}
        </Text>
        <Text variant={TextVariant.BODY}>&nbsp;-&nbsp;</Text>
        <Text className="product-detail-data__group" variant={TextVariant.BODY}>
          {season}
          {group}
        </Text>
      </div>
    </section>
  );
};

export { ProductDetailData };
