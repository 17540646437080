import React, { FC } from "react";
import { ToastNotificationProjection } from "../../../../projection/notification/notification";
import { ToastNotificationItem } from "./ToastNotificationItem";
import Stack, { StackElement } from "../../../../../ui/uiKit/layouts/stack/Stack";
import "./toast-notifications.css";

const NOTIFICATIONS_AUTOHIDE_TIMEOUT = 10000;

interface ToastNotificationsProps {
  readonly notifications?: ToastNotificationProjection[];
  readonly autoHideTimeout?: number;
  readonly onRemove: (notificationId: string) => void;
}

const ToastNotifications: FC<ToastNotificationsProps> = ({
  notifications = [],
  autoHideTimeout = NOTIFICATIONS_AUTOHIDE_TIMEOUT,
  onRemove,
}) => (
  <Stack className="toast-notifications" testID="toast-notifications">
    {
      notifications.map((notification) => (
        <ToastNotificationItem
          key={notification.id}
          autoHideTimeout={autoHideTimeout}
          className="toast-notification"
          notification={notification}
          testID={notification.id}
          onRemove={onRemove}
        />
      )) as unknown as StackElement[]
    }
  </Stack>
);

export { ToastNotifications };
