import { PersonalShopperCountryProjection } from "../../../projection/personalShopperCountry/personalShopperCountry";

type PersonalShopperCountryDto = string;

interface ToPersonalShopperCountryProjectionFunction {
  (personalShopperCountryDto: PersonalShopperCountryDto): PersonalShopperCountryProjection;
}

const toPersonalShopperCountryProjection: ToPersonalShopperCountryProjectionFunction = (personalShopperCountryDto) =>
  personalShopperCountryDto as PersonalShopperCountryProjection;

export type { PersonalShopperCountryDto };
export { toPersonalShopperCountryProjection };
