import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { listSelectionAlertsByBoxNumber, ListSelectionAlertsByBoxNumberResult } from "./listSelectionAlertsByBoxNumber";
import { listNoteAlertsByBoxNumber, ListNoteAlertsByBoxNumberResult } from "./listNoteAlertsByBoxNumber";
import { AlertContext, AlertProjection } from "./alert";
import { isSelectionAlert } from "./selectionAlert";

const CONTAINS_BLOCKING_ALERTS_BY_BOX_NUMBER = "contains_blocking_alerts_by_box_number";

interface ContainsBlockingAlertsByBoxNumberPayload {
  readonly boxNumber: string;
}

interface ContainsBlockingAlertsByBoxNumber
  extends Query<typeof CONTAINS_BLOCKING_ALERTS_BY_BOX_NUMBER>,
    ContainsBlockingAlertsByBoxNumberPayload {}

interface ContainsBlockingAlertsByBoxNumberFunction {
  (payload: ContainsBlockingAlertsByBoxNumberPayload): ContainsBlockingAlertsByBoxNumber;
}

const containsBlockingAlertsByBoxNumber: ContainsBlockingAlertsByBoxNumberFunction = (payload) => ({
  ...query({ name: CONTAINS_BLOCKING_ALERTS_BY_BOX_NUMBER }),
  ...payload,
});

type ContainsBlockingAlertsByBoxNumberResult = boolean;

interface ContainsBlockingAlertsByBoxNumberHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const containsBlockingAlertsByBoxNumberHandler: QueryHandlerFunction<
  ContainsBlockingAlertsByBoxNumber,
  ContainsBlockingAlertsByBoxNumberResult,
  ContainsBlockingAlertsByBoxNumberHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ boxNumber }) => {
    const selectionAlerts: ListSelectionAlertsByBoxNumberResult = await queryBus(
      listSelectionAlertsByBoxNumber({ boxNumber }),
    );

    const noteAlerts: ListNoteAlertsByBoxNumberResult = await queryBus(listNoteAlertsByBoxNumber({ boxNumber }));

    const alerts = [...(selectionAlerts || []), ...(noteAlerts || [])];

    return alerts.some(
      (alert: AlertProjection) =>
        alert.isBlocker && (isSelectionAlert(alert) ? alert.context.includes(AlertContext.SELECTION) : true),
    );
  };

export type { ContainsBlockingAlertsByBoxNumber, ContainsBlockingAlertsByBoxNumberResult };
export {
  CONTAINS_BLOCKING_ALERTS_BY_BOX_NUMBER,
  containsBlockingAlertsByBoxNumber,
  containsBlockingAlertsByBoxNumberHandler,
};
