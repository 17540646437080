import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import SliderComponent, { Mark } from "@material-ui/core/Slider";
import classNames from "classnames";
import "./slider.css";

type SliderProps = {
  readonly className?: string;
  readonly marks: Mark[];
  readonly value: [number, number];
  readonly onChange: (value: [number, number]) => void;
  readonly valueLabelFormat?: (value: number) => string;
};
const Slider: FC<SliderProps> = ({ className, marks, value, onChange, valueLabelFormat }: SliderProps) => {
  const [state, setState] = useState(value);
  const handleOnChanged = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_event: ChangeEvent<any>, value: number | number[]) => setState(value as [number, number]),
    [],
  );
  useEffect(() => setState(value), [value]);

  const handleOnChangeCommited = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_event: ChangeEvent<any>, value: number | number[]) => onChange(value as [number, number]),
    [onChange],
  );

  const sortedMarks = useMemo(() => marks.sort((markA, markB) => markA.value - markB.value), [marks]);
  const min = sortedMarks[0]?.value || 0;
  const max = sortedMarks[sortedMarks.length - 1]?.value || 0;

  return (
    <SliderComponent
      className={classNames("slider", className)}
      marks={sortedMarks}
      max={max}
      min={min}
      step={null}
      value={state}
      valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
      classes={{
        root: "slider",
        rail: "slider__rail",
        track: "slider__track",
        thumb: "slider__thumb",
        active: "slider__thumb--active",
        valueLabel: "slider__value-label",
        mark: "slider__mark",
      }}
      onChange={handleOnChanged}
      onChangeCommitted={handleOnChangeCommited}
    />
  );
};

export default Slider;
