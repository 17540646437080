import { I18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback } from "react";
import Country from "../../../../core/domain/country/model/Country";
import { useViewPlannedBoxesSummaryByCriteria } from "../../../../core/infrastructure/projection/plannedBox/react/useSearchPlannedBoxesByCriteria";
import { PlannedBoxTag, PlannedBoxesAssignment } from "../../../../core/projection/plannedBox/plannedBox";
import Badge, { BadgeVariant } from "../../../../shared/ui/uiKit/atoms/badge/Badge";
import { PlanningI18n, PLANNING_I18N_PREFIX } from "../../../componentLibrary/_i18n/PlanningI18n";
import "./planned-boxes-summary.css";

interface PlannedBoxesSummaryProps {
  readonly plannedFor: Date;
  readonly country: Country[] | undefined;
  readonly psNumber: string | undefined;
  readonly boxNumber: string | undefined;
  readonly assignment: PlannedBoxesAssignment | undefined;
  readonly tag: PlannedBoxTag[] | undefined;
  readonly onTagChanged: (tag: PlannedBoxTag[] | undefined) => void;
}

const PlannedBoxesSummary: FC<PlannedBoxesSummaryProps> = ({
  plannedFor,
  country,
  psNumber,
  boxNumber,
  assignment,
  tag,
  onTagChanged,
}) => {
  const summary = useViewPlannedBoxesSummaryByCriteria({
    plannedFor,
    country,
    psNumber,
    boxNumber,
    assignment,
    tag,
  });

  const handleTagClicked = useCallback(
    (plannedBoxTag: PlannedBoxTag) => () => {
      const tags = tag?.includes(plannedBoxTag)
        ? tag.filter((item) => item !== plannedBoxTag)
        : [...new Set([...(tag || []), plannedBoxTag])];

      onTagChanged(tags);
    },
    [onTagChanged, tag],
  );

  return (
    <section className="planned-boxes-summary" data-testid="planned-boxes-summary">
      <button data-testid={PlannedBoxTag.READY} onClick={handleTagClicked(PlannedBoxTag.READY)}>
        <Badge variant={tag?.includes(PlannedBoxTag.READY) ? BadgeVariant.BASIC_DARK : BadgeVariant.BASIC}>
          <I18nMessage id={PlanningI18n.SUMMARY_READY_BOXES} prefix={PLANNING_I18N_PREFIX} /> (
          {summary[PlannedBoxTag.READY]})
        </Badge>
      </button>
      <button data-testid={PlannedBoxTag.POSSIBLE_FRAUD} onClick={handleTagClicked(PlannedBoxTag.POSSIBLE_FRAUD)}>
        <Badge variant={tag?.includes(PlannedBoxTag.POSSIBLE_FRAUD) ? BadgeVariant.ORANGE_DARK : BadgeVariant.ORANGE}>
          <I18nMessage id={PlanningI18n.SUMMARY_POSSIBLE_FRAUD_BOXES} prefix={PLANNING_I18N_PREFIX} /> (
          {summary[PlannedBoxTag.POSSIBLE_FRAUD]})
        </Badge>
      </button>
      <button data-testid={PlannedBoxTag.PREVIEW_SENT} onClick={handleTagClicked(PlannedBoxTag.PREVIEW_SENT)}>
        <Badge variant={tag?.includes(PlannedBoxTag.PREVIEW_SENT) ? BadgeVariant.BLUE_DARK : BadgeVariant.BLUE}>
          <I18nMessage id={PlanningI18n.SUMMARY_SENT_PREVIEW_BOXES} prefix={PLANNING_I18N_PREFIX} /> (
          {summary[PlannedBoxTag.PREVIEW_SENT]})
        </Badge>
      </button>
      <button data-testid={PlannedBoxTag.PREVIEW_SUBMITTED} onClick={handleTagClicked(PlannedBoxTag.PREVIEW_SUBMITTED)}>
        <Badge
          variant={tag?.includes(PlannedBoxTag.PREVIEW_SUBMITTED) ? BadgeVariant.PURPLE_DARK : BadgeVariant.PURPLE}
        >
          <I18nMessage id={PlanningI18n.SUMMARY_COMPLETED_PREVIEW_BOXES} prefix={PLANNING_I18N_PREFIX} /> (
          {summary[PlannedBoxTag.PREVIEW_SUBMITTED]})
        </Badge>
      </button>
      <button data-testid={PlannedBoxTag.COMPLETED} onClick={handleTagClicked(PlannedBoxTag.COMPLETED)}>
        <Badge variant={tag?.includes(PlannedBoxTag.COMPLETED) ? BadgeVariant.GREEN_DARK : BadgeVariant.GREEN}>
          <I18nMessage id={PlanningI18n.SUMMARY_COMPLETED_BOXES} prefix={PLANNING_I18N_PREFIX} /> (
          {summary[PlannedBoxTag.COMPLETED]})
        </Badge>
      </button>
    </section>
  );
};

export { PlannedBoxesSummary };
