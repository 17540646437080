import { BoxProjection } from "../../../../projection/box/box";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { viewBoxByBoxNumber } from "../../../../projection/box/viewBoxByBoxNumber";
import { isBoxClosed } from "../../../../domain/box/model/boxClosed";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isBoxSentToSupervise } from "../../../../domain/box/model/boxSentToSupervise";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isBoxClosed(event) || isBoxSentToSupervise(event);

interface UseViewBoxByBoxNumberFunctionArgs {
  readonly boxNumber: string;
}

interface UseViewBoxByBoxNumberFunction {
  (args: UseViewBoxByBoxNumberFunctionArgs): UseQueryFunctionResult<BoxProjection>;
}

const useViewBoxByBoxNumber: UseViewBoxByBoxNumberFunction = ({ boxNumber }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewBoxByBoxNumber({ boxNumber }),
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
    invalidation: shouldInvalidate,
  });

export { useViewBoxByBoxNumber };
