import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

enum SelectionNotModifiableActions {
  SELECT = "select",
  DESELECT = "deselect",
  REPLACE = "replace",
}

const SELECTION_NOT_MODIFIABLE = "selection_not_modifiable";

interface SelectionNotModifiablePayload {
  readonly aggregateId: string;
  readonly action: SelectionNotModifiableActions;
}

interface SelectionNotModifiable extends DomainEvent<typeof SELECTION_NOT_MODIFIABLE>, SelectionNotModifiablePayload {}

interface SelectionNotModifiableFunction {
  (payload: SelectionNotModifiablePayload): SelectionNotModifiable;
}

const selectionNotModifiable: SelectionNotModifiableFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: SELECTION_NOT_MODIFIABLE }),
  ...payload,
});

const isSelectionNotModifiable = (event: DomainEvent<MessageName>): event is SelectionNotModifiable =>
  event.name === SELECTION_NOT_MODIFIABLE;

export type { SelectionNotModifiable };
export { SelectionNotModifiableActions, SELECTION_NOT_MODIFIABLE, selectionNotModifiable, isSelectionNotModifiable };
