import { command, Command } from "@lookiero/messaging";

const SEND_BOX_TO_SUPERVISE = "send_box_to_supervise";

interface SendBoxToSupervisePayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
  readonly personalShopperId: string;
}

interface SendBoxToSupervise extends Command<typeof SEND_BOX_TO_SUPERVISE>, SendBoxToSupervisePayload {}

interface SendBoxToSuperviseFunction {
  (payload: SendBoxToSupervisePayload): SendBoxToSupervise;
}

const sendBoxToSupervise: SendBoxToSuperviseFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: SEND_BOX_TO_SUPERVISE }),
  ...payload,
});

export type { SendBoxToSupervise };
export { SEND_BOX_TO_SUPERVISE, sendBoxToSupervise };
