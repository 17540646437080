import { EditableShortlistFilterProjection } from "../../../../../core/projection/editableShortlistFilter/editableShortlistFilter";
import { FilteringProjection } from "../../../../../core/projection/filtering/filtering";
import { StateFilteringValue } from "../../../../componentLibrary/organisms/shortlistFilters/ShortlistFilterState";
import { hasFilteringChanged } from "./hasFilteringChanged";

interface AllFiltersAreEditableFunctionArgs {
  readonly positives: StateFilteringValue[];
  readonly negatives: StateFilteringValue[];
  readonly editableFilters?: EditableShortlistFilterProjection;
}

interface AllFiltersAreEditableFunction {
  (args: AllFiltersAreEditableFunctionArgs): boolean;
}

const allFiltersAreEditable: AllFiltersAreEditableFunction = ({ positives, negatives, editableFilters }) =>
  positives.every(({ id: stateId }) =>
    editableFilters?.positives.find((editableFilterId) => editableFilterId === stateId),
  ) &&
  negatives.every(({ id: stateId }) =>
    editableFilters?.negatives.find((editableFilterId) => editableFilterId === stateId),
  );

interface IsDifferentFromPreviousFilteringFunctionArgs {
  readonly positives: StateFilteringValue[];
  readonly negatives: StateFilteringValue[];
  readonly filtering: FilteringProjection;
}

interface IsDifferentFromPreviousFilteringFunction {
  (args: IsDifferentFromPreviousFilteringFunctionArgs): boolean;
}

const isDifferentFromPreviousFiltering: IsDifferentFromPreviousFilteringFunction = ({
  positives,
  negatives,
  filtering,
}) =>
  hasFilteringChanged({ filtering: positives, previousFiltering: filtering.positives }) ||
  hasFilteringChanged({ filtering: negatives, previousFiltering: filtering.negatives });

interface UseCanSaveFilteringsFunctionArgs {
  readonly positives: StateFilteringValue[];
  readonly negatives: StateFilteringValue[];
  readonly filtering: FilteringProjection;
  readonly editableFilters?: EditableShortlistFilterProjection;
}

interface UseCanSaveFilteringsFunction {
  (args: UseCanSaveFilteringsFunctionArgs): boolean;
}

const useCanSaveFilterings: UseCanSaveFilteringsFunction = ({ positives, negatives, filtering, editableFilters }) =>
  (positives.length > 0 || negatives.length > 0) &&
  isDifferentFromPreviousFiltering({ positives, negatives, filtering }) &&
  allFiltersAreEditable({ positives, negatives, editableFilters });
export { useCanSaveFilterings };
