import {
  NoteTemplateByIdView,
  ViewNoteTemplateByIdResult,
} from "../../../projection/noteTemplate/viewNoteTemplateById";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { NoteTemplateProjection } from "../../../projection/noteTemplate/noteTemplate";
import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";

interface HttpNoteTemplateByIdView extends NoteTemplateByIdView {}

interface HttpNoteTemplateByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpNoteTemplateByIdViewFunction {
  (args: HttpNoteTemplateByIdViewFunctionArgs): HttpNoteTemplateByIdView;
}

interface ViewNoteTemplateByIdResponse {
  readonly result: NoteTemplateProjection;
}

const httpNoteTemplateByIdView: HttpNoteTemplateByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<ViewNoteTemplateByIdResponse, ViewNoteTemplateByIdResult>({
      endpoint: "/view-note-template-by-id",
      body: { note_template_id: id },
      signal,
      result: {
        error: null,
        success: ({ result }) => camelcaseKeys(result),
      },
    });

export { httpNoteTemplateByIdView };
