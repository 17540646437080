import { useCallback } from "react";
import { useTracker } from "./useTracker";
import { Section } from "../Tracker";

interface TrackAnnotationsPageViewFunction {
  (): void;
}

interface UseTrackAnnotationsPageViewFunctionArgs {
  readonly boxId: string;
  readonly psId?: string;
  readonly section: Section;
  readonly userId: string;
}

interface UseTrackAnnotationsPageViewFunction {
  (args: UseTrackAnnotationsPageViewFunctionArgs): TrackAnnotationsPageViewFunction;
}

const useTrackAnnotationsPageView: UseTrackAnnotationsPageViewFunction = ({ section, boxId, psId, userId }) => {
  const tracker = useTracker();

  const trackAnnotationsPageView: TrackAnnotationsPageViewFunction = useCallback(() => {
    if (!(tracker && psId)) {
      return;
    }

    tracker.annotationsPageView({
      section,
      boxId,
      psId,
      userId,
    });
  }, [boxId, psId, section, tracker, userId]);

  return trackAnnotationsPageView;
};

export { useTrackAnnotationsPageView };
