import { command, Command } from "@lookiero/messaging";
import { NotificationType } from "../model/notification";

const CREATE_MODAL_NOTIFICATION = "create_modal_notification";

interface CreateModalNotificationPayload {
  readonly aggregateId: string;
  readonly bodyI18nKey: string;
  readonly titleI18nKey: string;
  readonly acceptI18nKey: string;
}

interface CreateModalNotification extends Command<typeof CREATE_MODAL_NOTIFICATION>, CreateModalNotificationPayload {
  readonly type: NotificationType.MODAL;
}

interface CreateModalNotificationFunction {
  (payload: CreateModalNotificationPayload): CreateModalNotification;
}

const createModalNotification: CreateModalNotificationFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: CREATE_MODAL_NOTIFICATION }),
  ...payload,
  type: NotificationType.MODAL,
});

export type { CreateModalNotification };
export { CREATE_MODAL_NOTIFICATION, createModalNotification };
