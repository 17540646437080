import { FC, useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { I18nMessage } from "@lookiero/i18n-react";
import EditorToolbarButton from "../../../componentLibrary/organisms/editor/editorToolbarButton/EditorToolbarButton";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../../componentLibrary/_i18n/NoteI18n";
import { GenerateNoteConfirmationModal } from "../generateNoteConfirmationModal/GenerateNoteConfirmationModal";
import { useEditorSharedContext } from "../../_behaviors/useEditorSharedContext";
import { useTrackGenerateNote } from "../../../../shared/tracking/infrastructure/useTrackGenerateNote";
import { TrackingPage } from "../../../../shared/tracking/Tracker";
import { useViewPersonalShopper } from "../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useCanPersonalShopperGenerateNote } from "../../../../core/infrastructure/projection/personalShopper/react/useCanPersonalShopperGenerateNote";
import { CommandStatus } from "@lookiero/messaging-react";
import "./note-assistant.css";

const useStylesBootstrap = makeStyles(() => ({
  tooltip: {
    backgroundColor: "#0c0f1c",
    padding: 16,
    maxWidth: 140,
    borderRadius: 8,
  },
  arrow: {
    color: "#0c0f1c",
  },
}));

interface NoteAssistantProps {
  readonly boxId: string;
  readonly content: string | undefined;
  readonly status: CommandStatus;
  readonly noteGenerated?: boolean;
  readonly isPlaceholder?: boolean;
  readonly onGenerate: () => void;
}
const NoteAssistant: FC<NoteAssistantProps> = ({
  boxId,
  content,
  status,
  noteGenerated = false,
  isPlaceholder = false,
  onGenerate,
}) => {
  const classes = useStylesBootstrap();

  const [canPersonalShopperGenerateNote] = useCanPersonalShopperGenerateNote({ boxId });

  const noteGeneratedRef = useRef(noteGenerated);
  const disabled = noteGenerated || status === CommandStatus.LOADING || !canPersonalShopperGenerateNote;

  const [tooltipVisible, setTooltipVisible] = useState(true);
  const handleOnOpenTooltip = useCallback(() => setTooltipVisible(true), []);
  const handleOnCloseTooltip = useCallback(() => setTooltipVisible(false), []);

  const onboarding = useRef(true);
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (onboarding.current) {
      timeout = setTimeout(() => {
        handleOnCloseTooltip();
        onboarding.current = false;
      }, 5000);
    }

    return (): void => clearTimeout(timeout);
  }, [handleOnCloseTooltip]);

  const [personalShopper] = useViewPersonalShopper();
  const trackGenerateNote = useTrackGenerateNote({
    section: TrackingPage.SELECTION,
    psId: personalShopper?.id,
  });

  const generateNote = useCallback(() => {
    onGenerate();
    trackGenerateNote();
  }, [onGenerate, trackGenerateNote]);

  const [generateNoteConfirmationVisible, setGenerateNoteConfirmationVisible] = useState(false);
  const showGenerateNoteConfirmationModal = useCallback(() => setGenerateNoteConfirmationVisible(true), []);
  const hideDeselectConfirmationModal = useCallback(() => setGenerateNoteConfirmationVisible(false), []);
  const handleGenerateNoteConfirmed = useCallback(async () => {
    hideDeselectConfirmationModal();
    generateNote();
  }, [generateNote, hideDeselectConfirmationModal]);

  const handleOnClick = useCallback(() => {
    if (!isPlaceholder) {
      showGenerateNoteConfirmationModal();
      return;
    }

    generateNote();
  }, [generateNote, isPlaceholder, showGenerateNoteConfirmationModal]);

  const { replaceContent } = useEditorSharedContext();
  useEffect(() => {
    if (content !== undefined && noteGenerated && !noteGeneratedRef.current) {
      replaceContent(content);
      noteGeneratedRef.current = true;
    }
  }, [content, noteGenerated, replaceContent]);

  return (
    <>
      <EditorToolbarButton
        disabled={disabled}
        icon={IconVariant.ASSISTANT}
        label="note-assistant-button"
        title={<I18nMessage id={NoteI18n.GENERATE_NOTE_TOOLTIP} prefix={NOTE_I18N_PREFIX} />}
        tooltip={{
          arrow: true,
          classes,
          className: "note-assistant__tooltip",
          placement: "left",
          open: tooltipVisible && !disabled,
          onOpen: handleOnOpenTooltip,
          onClose: handleOnCloseTooltip,
        }}
        onClick={handleOnClick}
      />

      <GenerateNoteConfirmationModal
        visible={generateNoteConfirmationVisible}
        onCancel={hideDeselectConfirmationModal}
        onConfirm={handleGenerateNoteConfirmed}
      />
    </>
  );
};

export { NoteAssistant };
