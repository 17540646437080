import { Locale } from "@lookiero/i18n";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import Country from "../../../core/domain/country/model/Country";
import QueryParams from "../../_routing/queryParams/config";
import useQueryParameters from "../../_routing/queryParams/useQueryParameters";
import { PlannedBoxes } from "./plannedBoxes/PlannedBoxes";
import { PlannedBoxesFilters } from "./plannedBoxesFilters/PlannedBoxesFilters";
import { PlannedBoxesListHeader } from "./plannedBoxesHeader/PlannedBoxesListHeader";
import { PlannedBoxesSummary } from "./plannedBoxesSummary/PlannedBoxesSummary";
import { nextBizDay } from "./util/date";
import { PlannedBoxTag, PlannedBoxesAssignment } from "../../../core/projection/plannedBox/plannedBox";
import Button, { ButtonVariant } from "../../../shared/ui/uiKit/atoms/button/Button";
import { IconVariant } from "../../../shared/ui/uiKit/atoms/icon/Icon";
import { PLANNING_I18N_PREFIX, PlanningI18n } from "../../componentLibrary/_i18n/PlanningI18n";
import { I18nMessage } from "@lookiero/i18n-react";
import { useCSVPlanningBoxes } from "./hooks/useCSVPlanningBoxes";
import "./planning.css";

const Planning: FC = () => {
  const { locale } = useParams();
  const { params, setParams } = useQueryParameters();

  const page = parseInt(params[QueryParams.PAGE] as string) || 1;
  const boxNumber = params[QueryParams.BOX_NUMBER] as string;
  const countryParams = params[QueryParams.COUNTRY];
  const country = countryParams
    ? Array.isArray(countryParams)
      ? (countryParams as Country[])
      : ([countryParams] as Country[])
    : undefined;
  const plannedFor = params[QueryParams.PLANNED_FOR]
    ? new Date(params[QueryParams.PLANNED_FOR] as string)
    : nextBizDay();
  const psNumber = params[QueryParams.PS_NUMBER] as string;
  const assignment = params[QueryParams.ASSIGNMENT] as PlannedBoxesAssignment;
  const tagParams = params[QueryParams.TAG];
  const tag = tagParams
    ? Array.isArray(tagParams)
      ? (tagParams as PlannedBoxTag[])
      : ([tagParams] as PlannedBoxTag[])
    : undefined;

  const handleOnPageChanged = useCallback(
    (page: number): void => setParams({ [QueryParams.PAGE]: page === 1 ? null : String(page) }),
    [setParams],
  );

  const handleBoxNumberChanged = useCallback(
    (boxNumber: string) => setParams({ [QueryParams.BOX_NUMBER]: boxNumber === "" ? null : boxNumber }),
    [setParams],
  );
  const handleCountryChanged = useCallback(
    (country: Country[]) => setParams({ [QueryParams.COUNTRY]: country || null }),
    [setParams],
  );
  const handlePsNumberChanged = useCallback(
    (psNumber: string) => setParams({ [QueryParams.PS_NUMBER]: psNumber === "" ? null : psNumber }),
    [setParams],
  );
  const handlePlannedForChanged = useCallback(
    (plannedFor: Date) => {
      const date = plannedFor
        ? new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(plannedFor)
        : null;

      setParams({ [QueryParams.PLANNED_FOR]: date });
    },
    [setParams],
  );
  const handleOnAssignmentChanged = useCallback(
    (assignment: PlannedBoxesAssignment) => setParams({ [QueryParams.ASSIGNMENT]: assignment || null }),
    [setParams],
  );
  const handleTagChanged = useCallback(
    (tag: PlannedBoxTag[] | undefined) => setParams({ [QueryParams.TAG]: tag ?? null }),
    [setParams],
  );

  const { data, headers } = useCSVPlanningBoxes({
    assignment,
    boxNumber,
    country,
    locale: locale as Locale,
    plannedFor,
    psNumber,
    tag,
  });

  return (
    <section className="planning">
      <header className="planning__header">
        <div className="planning__header-content">
          <PlannedBoxesSummary
            assignment={assignment}
            boxNumber={boxNumber}
            country={country}
            plannedFor={plannedFor}
            psNumber={psNumber}
            tag={tag}
            onTagChanged={handleTagChanged}
          />
          <div className="planning__header-content__row">
            <PlannedBoxesFilters
              assignment={assignment}
              boxNumber={boxNumber}
              country={country}
              plannedFor={plannedFor}
              psNumber={psNumber}
              onAssignmentChanged={handleOnAssignmentChanged}
              onBoxNumberChanged={handleBoxNumberChanged}
              onCountryChanged={handleCountryChanged}
              onPlannedForChanged={handlePlannedForChanged}
              onPsNumberChanged={handlePsNumberChanged}
            />
            <CSVLink data={data} filename="labs-planning" headers={headers}>
              <Button icon={IconVariant.EXPORT} variant={ButtonVariant.PRIMARY}>
                <I18nMessage id={PlanningI18n.CSV_EXPORT} prefix={PLANNING_I18N_PREFIX} />
              </Button>
            </CSVLink>
          </div>
          <PlannedBoxesListHeader />
        </div>
      </header>
      <div className="planning__content">
        <PlannedBoxes
          assignment={assignment}
          boxNumber={boxNumber}
          country={country}
          locale={locale as Locale}
          page={page}
          plannedFor={plannedFor}
          psNumber={psNumber}
          tag={tag}
          onPageChanged={handleOnPageChanged}
        />
      </div>
    </section>
  );
};

export { Planning };
