import { useCallback, useMemo } from "react";
import { useIntl } from "@lookiero/i18n-react";
import Pill from "../../../../../../../shared/ui/uiKit/molecules/pill/Pill";
import { ShortlistFilterItem } from "../../ShortlistFilterItem";
import { useShortlistFilteringPositives } from "../../_behavior/useShortlistFilteringPositives";
import { deactivate } from "../../../ShortlistFilterState";
import { useShortlistFilteringNegatives } from "../../_behavior/useShortlistFilteringNegatives";
import "./range-pill-shortlist-filter-item.css";

const RangePillShortlistFilterItem: ShortlistFilterItem = ({ filter }) => {
  const { formatMessage } = useIntl();
  const { state: positiveState, onChange: positiveOnChange } = useShortlistFilteringPositives();
  const { state: negativeState } = useShortlistFilteringNegatives();
  const handleOnPositiveRemove = useCallback(
    () => positiveOnChange({ state: deactivate({ filter, state: positiveState }) }),
    [filter, positiveOnChange, positiveState],
  );

  const positiveFiltersState = useMemo(
    () =>
      filter.children?.filter(({ id: childFilterId }) => positiveState.find(({ id }) => id === childFilterId)) || [],
    [filter.children, positiveState],
  );
  const negativeFiltersState = useMemo(
    () =>
      filter.children?.filter(({ id: childFilterId }) => negativeState.find(({ id }) => id === childFilterId)) || [],
    [filter.children, negativeState],
  );

  return (
    <>
      {positiveFiltersState.length > 0 ? (
        <Pill className="range-pill-shortlist-filter-item--positive" onRemove={handleOnPositiveRemove}>
          {positiveFiltersState.map(({ translationKey }) => formatMessage({ id: translationKey })).join(" - ")}
        </Pill>
      ) : null}
      {negativeFiltersState.length > 0 ? (
        <Pill className="range-pill-shortlist-filter-item--negative">
          {negativeFiltersState.map(({ translationKey }) => formatMessage({ id: translationKey })).join(" - ")}
        </Pill>
      ) : null}
    </>
  );
};

export default RangePillShortlistFilterItem;
