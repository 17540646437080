import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const AUTOMATIC_SELECTION_STARTED = "automatic_selection_started";

interface AutomaticSelectionStartedPayload {
  readonly aggregateId: string;
}

interface AutomaticSelectionStarted
  extends DomainEvent<typeof AUTOMATIC_SELECTION_STARTED>,
    AutomaticSelectionStartedPayload {}

interface AutomaticSelectionStartedFunction {
  (payload: AutomaticSelectionStartedPayload): AutomaticSelectionStarted;
}

const automaticSelectionStarted: AutomaticSelectionStartedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: AUTOMATIC_SELECTION_STARTED });

const isAutomaticSelectionStarted = (event: DomainEvent<MessageName>): event is AutomaticSelectionStarted =>
  event.name === AUTOMATIC_SELECTION_STARTED;

export type { AutomaticSelectionStarted };
export { AUTOMATIC_SELECTION_STARTED, automaticSelectionStarted, isAutomaticSelectionStarted };
