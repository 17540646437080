import { useI18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import { useIntl } from "react-intl";
import Locale from "../../../../core/domain/country/model/Locale";
import SizeProjection from "../../../../core/projection/size/model/Size";
import ButtonOption, { ButtonOptionState } from "../../../../shared/ui/uiKit/atoms/buttonOption/ButtonOption";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../_i18n/HistoryI18n";
import "./history-product-variant-size.css";

type HistoryProductVariantSizeProps = {
  readonly size: SizeProjection;
  readonly sizeChange?: SizeProjection;
};
const HistoryProductVariantSize: FC<HistoryProductVariantSizeProps> = ({
  size,
  sizeChange,
}: HistoryProductVariantSizeProps) => {
  const { locale } = useIntl();
  const sentSizeMessage = useI18nMessage({
    id: HistoryI18n.PRODUCT_VARIANT_SENT_SIZE,
    prefix: HISTORY_I18N_PREFIX,
  });
  const sizeChangeMessage = useI18nMessage({
    id: HistoryI18n.PRODUCT_VARIANT_SIZE_CHANGE,
    prefix: HISTORY_I18N_PREFIX,
  });

  const formattedSentSize = locale === Locale.en_GB ? size.uk.format : size.lookiero.format;
  const formattedSizeChange = locale === Locale.en_GB ? sizeChange?.uk.format : sizeChange?.lookiero.format;

  return (
    <div className="history-product-variant-size">
      {sizeChange && (
        <Tooltip title={`${sizeChangeMessage}: ${formattedSizeChange}`}>
          <ButtonOption label={<>{formattedSizeChange}</>} state={ButtonOptionState.SELECTED} />
        </Tooltip>
      )}
      <Tooltip title={`${sentSizeMessage}: ${formattedSentSize}`}>
        <ButtonOption
          label={<>{formattedSentSize}</>}
          state={sizeChange ? ButtonOptionState.VISUALLY_DISABLED : ButtonOptionState.SELECTED}
        />
      </Tooltip>
    </div>
  );
};

export default HistoryProductVariantSize;
