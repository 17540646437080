import { ProcessManagerFunction } from "@lookiero/messaging";
import { ListLooksBySelectionIdResult, listLooksBySelectionId } from "../../../projection/looks/listLooksBySelectionId";
import { ProductVariantDeselected } from "../../selection/model/ProductVariantDeselected";
import { setLooks } from "../command/setLooks";

interface SetLooksWhenProductVariantDeselectedFunction extends ProcessManagerFunction<ProductVariantDeselected> {}

const setLooksWhenProductVariantDeselected: SetLooksWhenProductVariantDeselectedFunction =
  ({ commandBus, queryBus }) =>
  async ({ aggregateId, productVariantId }) => {
    const looks: ListLooksBySelectionIdResult = await queryBus(listLooksBySelectionId({ selectionId: aggregateId }));

    const looksWithoutProductVariant = looks?.filter((look) => !look.includes(productVariantId)) || [];

    await commandBus(setLooks({ aggregateId, looks: looksWithoutProductVariant }));
  };

export { setLooksWhenProductVariantDeselected };
