import { v4 as uuid } from "uuid";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../../ui/componentLibrary/_i18n/SelectionI18n";
import { createToastNotification } from "../../../../shared/notifications/domain/notification/command/createToastNotification";
import { NotificationLevel } from "../../../../shared/notifications/domain/notification/model/notification";
import { SelectionNotModifiable, SelectionNotModifiableActions } from "../model/SelectionNotModifiable";
import { ProcessManagerFunction } from "@lookiero/messaging";

const selectionNotModifiableActionsNotification = {
  [SelectionNotModifiableActions.SELECT]: SelectionI18n.CAN_NOT_SELECT_PRODUCT_VARIANT_NOTIFICATION,
  [SelectionNotModifiableActions.DESELECT]: SelectionI18n.CAN_NOT_DESELECT_PRODUCT_VARIANT_NOTIFICATION,
  [SelectionNotModifiableActions.REPLACE]: SelectionI18n.CAN_NOT_REPLACE_PRODUCT_VARIANT_NOTIFICATION,
};

interface CreateNotificationWhenSelectionNotModifiableFunction extends ProcessManagerFunction<SelectionNotModifiable> {}

const createNotificationWhenSelectionNotModifiable: CreateNotificationWhenSelectionNotModifiableFunction =
  ({ commandBus }) =>
  async ({ action }) => {
    await commandBus(
      createToastNotification({
        aggregateId: uuid(),
        bodyI18nKey: `${SELECTION_I18N_PREFIX}${selectionNotModifiableActionsNotification[action]}`,
        level: NotificationLevel.ERROR,
      }),
    );
  };

export { createNotificationWhenSelectionNotModifiable };
