import { DomainEvent } from "@lookiero/messaging.js";
import UIEventBus from "@lookiero/messaging.js/dist/infrastructure/domain/event/UIEventBus";
import ToggleFeatureEnablementEventEmitter from "./ToggleFeatureEnablementEventEmitter";
import WindowsMessenger from "./WindowsMessenger";
import IntegrationFeatureToggle from "./IntegrationFeatureToggle";
import IntegrationEvent, { integrationEventRegistry } from "./IntegrationEvent";
import IntegrationEventsRegistry from "./IntegrationEventsRegistry";
import { WindowsMessengerChild } from "@lookiero/windows-messenger.js";

type LabsFrontLegacyWindowsMessengerChildEvents = {
  [ToggleFeatureName in IntegrationFeatureToggle as `${ToggleFeatureName}FeatureSupported`]: boolean;
} & {
  integrationEvent: IntegrationEvent; // outgoing events
};
type LabsFrontLegacyWindowsMessengerParentEvents = {
  integrationEvent: IntegrationEvent; // incoming events
};

class LabsFrontLegacyWindowsMessenger
  extends WindowsMessenger<LabsFrontLegacyWindowsMessengerChildEvents, LabsFrontLegacyWindowsMessengerParentEvents>
  implements ToggleFeatureEnablementEventEmitter
{
  private _eventBus!: UIEventBus;
  private integrationEventsRegistry: IntegrationEventsRegistry;

  public constructor(
    child: WindowsMessengerChild<
      LabsFrontLegacyWindowsMessengerChildEvents,
      LabsFrontLegacyWindowsMessengerParentEvents
    >,
  ) {
    super(child);

    this.integrationEventsRegistry = new IntegrationEventsRegistry();
  }

  public set eventBus(bus: UIEventBus) {
    this._eventBus = bus;

    // outgoing events
    this._eventBus.subscribe((domainEvent: DomainEvent) => {
      const eventName: string =
        typeof domainEvent.name === "string" ? (domainEvent.name as unknown as string) : domainEvent.name();
      const eventOwner: string = domainEvent.owner?.() || "";
      const name = eventName.replace(`${eventOwner}.`, "");

      if (!(name in integrationEventRegistry)) {
        return;
      }

      const integrationEvent = {
        ...domainEvent,
        name,
      };

      if (this.integrationEventsRegistry.contains(integrationEvent)) {
        return;
      }

      this.integrationEventsRegistry.push(integrationEvent);

      this.emitEvent("integrationEvent", integrationEvent);
    });
  }

  protected initializeListeners(): void {
    // incoming events
    this.onEvent("integrationEvent", (integrationEvent: IntegrationEvent) => {
      if (this.integrationEventsRegistry.contains(integrationEvent)) {
        return;
      }

      const { name, ...payload } = integrationEvent;

      if (!(name in integrationEventRegistry)) {
        return;
      }

      const Event = integrationEventRegistry[name];

      const event = Object.assign(new Event(payload), { _id: payload._id, _occurredOn: payload._occurredOn });

      this.integrationEventsRegistry.push(integrationEvent);

      this._eventBus.publish([event]);
    });
  }

  public async emit(isSupported: boolean, toggleFeatureName: IntegrationFeatureToggle): Promise<void> {
    await this.emitEvent(`${toggleFeatureName}FeatureSupported`, isSupported);
  }
}

export default LabsFrontLegacyWindowsMessenger;
