import { FC } from "react";
import classNames from "classnames";
import Checkbox, { CheckboxState } from "../checkbox/Checkbox";
import Text, { TextVariant } from "../../atoms/text/Text";
import "./color-checkbox.css";

type Color = {
  readonly id: string;
  readonly code: string;
  readonly hexadecimal: string;
  readonly name: string;
  readonly parent: string | null;
};

type ColorCheckboxProps = {
  readonly color: Color;
  readonly checked: boolean;
  readonly onChange: (checked: boolean) => void;
};

const ColorCheckbox: FC<ColorCheckboxProps> = ({ color, checked, onChange }: ColorCheckboxProps) => (
  <div className={classNames("color-checkbox", { "color-checkbox--checked": checked })}>
    <Checkbox state={checked ? CheckboxState.CHECKED : CheckboxState.DEFAULT} onChange={onChange}>
      <div className="color-checkbox__color" style={{ backgroundColor: `#${color.hexadecimal}` }} />
    </Checkbox>
    <Text className="color-checkbox__label" variant={TextVariant.BODY_SMALL} ellipsis>
      {color.name} {color.code}
    </Text>
  </div>
);

export default ColorCheckbox;
