import { FC } from "react";
import useEnvironment from "../../_behaviors/useEnvironment";
import useFeatureToggle from "../../../_featureToggle/useFeatureToggle";
import useIsFeatureToggleEnabled from "../../../../shared/featureToggle/ui/useIsFeatureToggleEnabled";
import { Sidetab } from "@typeform/embed-react";
import FeatureToggles from "../../../_featureToggle/FeatureToggles";
import { useI18nMessage } from "@lookiero/i18n-react";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../componentLibrary/_i18n/SelectionI18n";
import { useIsStockSurveySubmittedByBoxId } from "../../../../core/infrastructure/projection/stockSurvey/react/useIsStockSurveySubmittedByBoxId";
import { useSubmitStockSurvey } from "../../../../core/infrastructure/domain/stockSurvey/react/useSubmitStockSurvey";
import { QueryStatus } from "@lookiero/messaging-react";

interface StockSurveyTypeformProps {
  readonly boxId: string;
  readonly personalShopperId: string;
}
const StockSurveyTypeform: FC<StockSurveyTypeformProps> = ({ boxId, personalShopperId }) => {
  const buttonText = useI18nMessage({
    id: SelectionI18n.STOCK_SURVEY_TYPEFORM_BUTTON,
    prefix: SELECTION_I18N_PREFIX,
  });

  const {
    typeform: { stockSurveyTypeformId },
  } = useEnvironment();

  const [isStockSurveySubmittedByBoxId, isStockSurveySubmittedByBoxIdStatus] = useIsStockSurveySubmittedByBoxId({
    boxId,
  });
  const [submitStockSurvey] = useSubmitStockSurvey({ boxId });

  const selectionStockSurveyTypeformFeatureToggle = useFeatureToggle(FeatureToggles.STOCK_SURVEY_TYPEFORM);
  const selectionStockSurveyTypeformFeatureToggleEnabled = useIsFeatureToggleEnabled({
    featureToggle: selectionStockSurveyTypeformFeatureToggle,
  });

  const typeformActive =
    !isStockSurveySubmittedByBoxId &&
    isStockSurveySubmittedByBoxIdStatus !== QueryStatus.LOADING &&
    selectionStockSurveyTypeformFeatureToggleEnabled;

  if (!typeformActive) {
    return null;
  }

  return (
    <Sidetab
      buttonColor="black"
      buttonText={buttonText}
      id={stockSurveyTypeformId}
      open="load"
      tracking={{
        box_id: boxId,
        ps_id: personalShopperId,
      }}
      autoClose
      keepSession
      onSubmit={submitStockSurvey}
    />
  );
};

export { StockSurveyTypeform };
