import classNames from "classnames";
import { FC, ReactNode } from "react";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import "./alert-icon.css";

enum AlertIconVariant {
  ERROR = "error",
  WARNING = "warning",
}

type AlertIconProps = {
  readonly title: ReactNode;
  readonly iconLabel?: string;
  readonly variant?: AlertIconVariant;
};
const AlertIcon: FC<AlertIconProps> = ({ title, iconLabel, variant = AlertIconVariant.WARNING }: AlertIconProps) => (
  <div className={classNames("alert-icon", `alert-icon--${variant}`)}>
    <Tooltip title={title}>
      <Icon label={iconLabel} variant={IconVariant.ALERT_WARNING} />
    </Tooltip>
  </div>
);

export { AlertIconVariant };

export default AlertIcon;
