import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { NoteAlertProjection } from "./noteAlert";

const LIST_NOTE_ALERTS_BY_BOX_NUMBER = "list_note_alerts_by_box_number";

interface ListNoteAlertsByBoxNumberPayload {
  readonly boxNumber: string;
}

interface ListNoteAlertsByBoxNumber
  extends Query<typeof LIST_NOTE_ALERTS_BY_BOX_NUMBER>,
    ListNoteAlertsByBoxNumberPayload {}

interface ListNoteAlertsByBoxNumberFunction {
  (payload: ListNoteAlertsByBoxNumberPayload): ListNoteAlertsByBoxNumber;
}

const listNoteAlertsByBoxNumber: ListNoteAlertsByBoxNumberFunction = (payload) => ({
  ...query({ name: LIST_NOTE_ALERTS_BY_BOX_NUMBER }),
  ...payload,
});

type ListNoteAlertsByBoxNumberResult = NoteAlertProjection[] | null;

interface NoteAlertsByBoxNumberViewArgs extends CancelableQueryViewArgs {
  readonly boxNumber: string;
}

interface NoteAlertsByBoxNumberView {
  (args: NoteAlertsByBoxNumberViewArgs): Promise<ListNoteAlertsByBoxNumberResult>;
}

interface ListNoteAlertsByBoxNumberHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: NoteAlertsByBoxNumberView;
}

const listNoteAlertsByBoxNumberHandler: QueryHandlerFunction<
  ListNoteAlertsByBoxNumber,
  ListNoteAlertsByBoxNumberResult,
  ListNoteAlertsByBoxNumberHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxNumber }) =>
    view({ boxNumber, signal });

export type { NoteAlertsByBoxNumberView, ListNoteAlertsByBoxNumber, ListNoteAlertsByBoxNumberResult };
export { LIST_NOTE_ALERTS_BY_BOX_NUMBER, listNoteAlertsByBoxNumber, listNoteAlertsByBoxNumberHandler };
