import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { AnnotationProjection } from "./annotation";

const VIEW_ANNOTATION_BY_CUSTOMER_ID = "view_annotation_by_customer_id";

interface ViewAnnotationByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewAnnotationByCustomerId
  extends Query<typeof VIEW_ANNOTATION_BY_CUSTOMER_ID>,
    ViewAnnotationByCustomerIdPayload {}

interface ViewAnnotationByCustomerIdFunction {
  (payload: ViewAnnotationByCustomerIdPayload): ViewAnnotationByCustomerId;
}

const viewAnnotationByCustomerId: ViewAnnotationByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_ANNOTATION_BY_CUSTOMER_ID }),
  ...payload,
});

type ViewAnnotationByCustomerIdResult = AnnotationProjection | null;

interface AnnotationByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface AnnotationByCustomerIdView {
  (args: AnnotationByCustomerIdViewArgs): Promise<ViewAnnotationByCustomerIdResult>;
}

interface ViewAnnotationByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: AnnotationByCustomerIdView;
}

const viewAnnotationByCustomerIdHandler: QueryHandlerFunction<
  ViewAnnotationByCustomerId,
  ViewAnnotationByCustomerIdResult,
  ViewAnnotationByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { AnnotationByCustomerIdView, ViewAnnotationByCustomerIdResult };
export { VIEW_ANNOTATION_BY_CUSTOMER_ID, viewAnnotationByCustomerId, viewAnnotationByCustomerIdHandler };
