import Sizing from "../../../domain/sizing/model/Sizing";
import Color from "../../../projection/color/model/Color";
import Media from "../../../projection/media/model/Media";
import Price from "../../../projection/price/model/Price";
import {
  ShortlistProductProjection,
  ShortlistProductVariantProjection,
} from "../../../projection/shortlist/shortlistProduct";
import Size from "../../../projection/size/model/Size";

interface ShortlistProductVariantDto {
  readonly id: string;
  readonly season: number;
  readonly recommended: boolean;
  readonly matchesFilters?: boolean;
  readonly score: number;
  readonly color: Color;
  readonly size: Size;
  readonly price: Price;
  readonly media: Media[];
}

interface ShortlistProductDto {
  readonly id: string;
  readonly group: string;
  readonly title: string;
  readonly brand: {
    readonly name: string;
    readonly origin: string;
  };
  readonly sizing: string;
  readonly productVariants: ShortlistProductVariantDto[];
}

interface ToShortlistProductVariantProjectionFunctionArgs {
  readonly productVariant: ShortlistProductVariantDto;
}

interface ToShortlistProductVariantProjectionFunction {
  (args: ToShortlistProductVariantProjectionFunctionArgs): ShortlistProductVariantProjection;
}

const toShortlistProductVariantProjection: ToShortlistProductVariantProjectionFunction = ({
  productVariant: { id, season, recommended, matchesFilters, score, color, size, price, media },
}) => ({
  id,
  season,
  recommended,
  matchesFilters: matchesFilters as boolean,
  score,
  color,
  size,
  price,
  media: media as Media[],
});

interface ToShortlistProductProjectionFunctionArgs {
  readonly product: ShortlistProductDto;
}

interface ToShortlistProductProjectionFunction {
  (args: ToShortlistProductProjectionFunctionArgs): ShortlistProductProjection;
}

const toShortlistProductProjection: ToShortlistProductProjectionFunction = ({
  product: { id, group, title, brand, sizing, productVariants },
}) => ({
  id,
  group,
  title,
  brand,
  sizing: sizing as Sizing,
  productVariants: productVariants.map((productVariant) => toShortlistProductVariantProjection({ productVariant })),
});

export type { ShortlistProductDto };
export { toShortlistProductProjection };
