import { LookAndLikeFeedbackProjection } from "../../../projection/lookAndLikeFeedback/lookAndLikeFeedback";
import Media from "../../../projection/media/model/Media";

interface LookAndLikeFeedbackDto {
  readonly id: string;
  readonly product_variant: {
    readonly id: string;
    readonly media: Media[];
  };
  readonly response: boolean;
}

interface ToLookAndLikeFeedbackProjectionFunction {
  (lookAndLikeFeedbackDto: LookAndLikeFeedbackDto): LookAndLikeFeedbackProjection;
}

const toLookAndLikeFeedbackProjection: ToLookAndLikeFeedbackProjectionFunction = ({
  id,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  product_variant,
  response,
}) => ({
  id,
  productVariant: {
    id: product_variant.id,
    media: product_variant.media,
  },
  response,
});

export type { LookAndLikeFeedbackDto };
export { toLookAndLikeFeedbackProjection };
