import { FC } from "react";
import Text, { TextVariant } from "../text/Text";
import "./error.css";

interface ErrorProps {
  readonly error?: string;
}
const Error: FC<ErrorProps> = ({ error }: ErrorProps) =>
  Boolean(error) ? (
    <Text className="error" variant={TextVariant.BODY_SMALL}>
      {error}
    </Text>
  ) : null;

export default Error;
