import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const NOTIFICATION_REMOVED = "notification_removed";

interface NotificationRemovedPayload {
  readonly aggregateId: string;
}

interface NotificationRemoved extends DomainEvent<typeof NOTIFICATION_REMOVED>, NotificationRemovedPayload {}

interface NotificationRemovedFunction {
  (payload: NotificationRemovedPayload): NotificationRemoved;
}

const notificationRemoved: NotificationRemovedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: NOTIFICATION_REMOVED });

const isNotificationRemoved = (event: DomainEvent<MessageName>): event is NotificationRemoved =>
  event.name === NOTIFICATION_REMOVED;

export type { NotificationRemoved };
export { NOTIFICATION_REMOVED, notificationRemoved, isNotificationRemoved };
