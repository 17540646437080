import { useContext, useEffect } from "react";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { DomainEvent as DomainEventJs } from "@lookiero/messaging.js";
import { getContext } from "@lookiero/messaging-react/bootstrap";
import UIEventBus from "@lookiero/messaging.js/dist/infrastructure/domain/event/UIEventBus";
import { MESSAGING_CONTEXT_ID } from "../../../core/container/bootstrap";

interface UseMessagingBridgeFunctionArgs {
  readonly uiEventBus: UIEventBus;
}
interface UseMessagingBridgeFunction {
  (args: UseMessagingBridgeFunctionArgs): void;
}
const useMessagingBridge: UseMessagingBridgeFunction = ({ uiEventBus }) => {
  const { messaging } = getContext({ id: MESSAGING_CONTEXT_ID });
  const { subscribe, unsubscribe } = useContext(messaging);

  useEffect(() => {
    const subscriber = (event: DomainEvent<MessageName>) => uiEventBus.publish([event as unknown as DomainEventJs]);

    subscribe(subscriber);

    return () => {
      unsubscribe(subscriber);
    };
  }, [uiEventBus, subscribe, unsubscribe]);
};

export { useMessagingBridge };
