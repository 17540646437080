import { CSSProperties, FC, Fragment, ReactNode, useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import VirtualizedListRow from "./VirtualizedListRow";
import { VirtualizedListRowTagName } from "./VirtualizedListRow";

interface DivParentProps {
  readonly children: ReactNode;
  readonly style?: CSSProperties;
}
const DivParent: FC<DivParentProps> = ({ children, style }) => <div style={style}>{children}</div>;

interface VirtualizedListRowRendererFunctionArgs {
  readonly className?: string;
  readonly tagName?: VirtualizedListRowTagName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly styleFn?: (data: any) => CSSProperties;
  readonly onClickListRow?: (index: number, data: unknown) => void;
}
interface VirtualizedListRowRendererFunction {
  (args: VirtualizedListRowRendererFunctionArgs): FC<ListChildComponentProps>;
}
const virtualizedListRowRenderer: VirtualizedListRowRendererFunction =
  ({ tagName, className, styleFn, onClickListRow }) =>
  ({ data, index, style }) => {
    const Parent = useMemo(() => (tagName === "tr" ? Fragment : DivParent), []);

    const elementStyle = useMemo(() => (tagName === "tr" ? undefined : { style }), [style]);

    return (
      <Parent {...elementStyle}>
        <VirtualizedListRow
          {...data}
          className={className}
          index={index}
          style={style}
          styleFn={styleFn}
          tagName={tagName}
          onClick={onClickListRow}
        />
      </Parent>
    );
  };

export { virtualizedListRowRenderer };
