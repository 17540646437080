import { FC, ReactNode, useCallback, useState } from "react";
import AlertsToggle from "../../../componentLibrary/molecules/alertsToggle/AlertsToggle";
import AlertsPopup from "../../../componentLibrary/organisms/alertsPopup/AlertsPopup";
import SelectionHeaderSummary from "../../../componentLibrary/molecules/selectionHeaderSummary/SelectionHeaderSummary";
import BoxPreviewStatus from "../../../../core/domain/boxPreview/model/BoxPreviewStatus";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Button from "../../../../shared/ui/uiKit/atoms/button/Button";
import useClickAway from "../../../../shared/ui/uiKit/_behaviors/useClickAway/useClickAway";
import { AlertContext, AlertProjection } from "../../../../core/projection/alert/alert";
import { isSelectionAlert } from "../../../../core/projection/alert/selectionAlert";
import { SelectionProductProjection } from "../../../../core/projection/selection/selection";
import { useMatch } from "react-router-dom";
import { Routes } from "../../../_routing/Routing";
import { useAutomaticSelectionTooltipVisible } from "../../_behaviors/useAutomaticSelectionTooltipVisible";
import "./selection-header.css";

type SelectionHeaderProps = {
  readonly products?: SelectionProductProjection[];
  readonly alerts?: AlertProjection[];
  readonly isNoteCollapsed: boolean;
  readonly onToggleNote?: () => void;
  readonly boxPreviewStatus?: BoxPreviewStatus;
  readonly children?: ReactNode;
};
const SelectionHeader: FC<SelectionHeaderProps> = ({
  products,
  alerts,
  isNoteCollapsed,
  onToggleNote,
  boxPreviewStatus,
  children,
}) => {
  const selectionMatch = useMatch(`${Routes.BOX_SELECTION}/*`);
  const automaticSelectionTooltipVisible = useAutomaticSelectionTooltipVisible();
  const showAutomaticSelectionTooltip = Boolean(selectionMatch && automaticSelectionTooltipVisible);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handleOnToggle = useCallback(() => setIsPopupVisible((isPopupVisible) => !isPopupVisible), []);
  const handleOnClose = useCallback(() => setIsPopupVisible(false), []);

  const selectionAndNoteAlerts = alerts?.filter((alert) =>
    isSelectionAlert(alert) ? alert.context.includes(AlertContext.SELECTION) : true,
  );

  const { viewRef } = useClickAway<HTMLDivElement>({ active: isPopupVisible, action: handleOnClose });

  return (
    <header className="selection-header">
      <div className="selection-header__section">{children}</div>
      <div className="selection-header__section">
        <AlertsToggle alerts={selectionAndNoteAlerts} onToggle={handleOnToggle} />
        <SelectionHeaderSummary
          boxPreviewStatus={boxPreviewStatus}
          products={products}
          showAutomaticSelectionTooltip={showAutomaticSelectionTooltip}
          showCandidatesPrice
        />
        {isNoteCollapsed && (
          <Button
            aria-label="selection-header-expand-note-button"
            icon={IconVariant.CHEVRON_LEFT}
            onClick={onToggleNote}
          />
        )}
      </div>

      <div ref={viewRef} className="selection-header__selection-alerts-popup">
        <AlertsPopup alerts={selectionAndNoteAlerts} visible={isPopupVisible} onClose={handleOnClose} />
      </div>
    </header>
  );
};

export default SelectionHeader;
