enum Language {
  ES = "es",
  FR = "fr",
  EN = "en",
  IT = "it",
  PT = "pt",
  DE = "de",
  NL = "nl",
  SV = "sv",
}

export { Language };
