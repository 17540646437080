import { FC } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import { CapacityI18n, CAPACITY_I18N_PREFIX } from "../../../componentLibrary/_i18n/CapacityI18n";
import "./capacity-list-header.css";

const CapacityListHeader: FC = () => (
  <header className="capacity-list-header">
    <section className="capacity-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={CapacityI18n.TABLE_HEADER_DATE} prefix={CAPACITY_I18N_PREFIX} />
      </Text>
    </section>
    <section className="capacity-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD} ellipsis>
        <I18nMessage id={CapacityI18n.TABLE_HEADER_CAPACITY} prefix={CAPACITY_I18N_PREFIX} />
      </Text>
    </section>
    <section className="capacity-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={CapacityI18n.TABLE_HEADER_RECURRENT} prefix={CAPACITY_I18N_PREFIX} />
      </Text>
    </section>
    <section className="capacity-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={CapacityI18n.TABLE_HEADER_NEW} prefix={CAPACITY_I18N_PREFIX} />
      </Text>
    </section>
    <section className="capacity-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={CapacityI18n.TABLE_HEADER_TOTAL} prefix={CAPACITY_I18N_PREFIX} />
      </Text>
    </section>
    <section className="capacity-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={CapacityI18n.TABLE_HEADER_PERCENTAGE} prefix={CAPACITY_I18N_PREFIX} />
      </Text>
    </section>
    <section className="capacity-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={CapacityI18n.TABLE_HEADER_DIFFERENCE} prefix={CAPACITY_I18N_PREFIX} />
      </Text>
    </section>
  </header>
);

export { CapacityListHeader };
