enum MediaPerspective {
  BACK = "BACK",
  BOTTOM_UP = "BOTTOM_UP",
  COLLAGE = "COLLAGE",
  DETAIL = "DETAIL",
  FLAT_BACK = "FLAT_BACK",
  FRONT = "FRONT",
  FRONT_FAR = "FRONT_FAR",
  LABEL = "LABEL",
  MAIN = "MAIN",
  MAIN_TRANSPARENT = "MAIN_TRANSPARENT",
  OTHER = "OTHER",
  SIDE = "SIDE",
}

export default MediaPerspective;
