import { FC, useCallback, useState } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import Modal from "../../../../shared/ui/uiKit/layouts/modal/Modal";
import LookTemplateSelector from "./lookTemplateSelector/LookTemplateSelector";
import { LookProjection } from "../../../../core/projection/looks/look";
import { LookTemplate as LookTemplateEnum, itemsPerLookTemplate } from "../../../../core/projection/looks/lookTemplate";
import LookTemplate from "../../../componentLibrary/organisms/lookTemplate/LookTemplate";
import Button, { ButtonVariant } from "../../../../shared/ui/uiKit/atoms/button/Button";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../componentLibrary/_i18n/SelectionI18n";
import LookProductVariantSelector from "./lookProductVariantSelector/LookProductVariantSelector";
import { LookProductVariantProjection } from "../../../../core/projection/looks/lookProductVariant";
import "./look-creator.css";
import { useListLookValidationErrors } from "../../../../core/infrastructure/projection/lookValidation/react/useListLookValidationErrors";
import { ProductVariantProjection } from "../../../../core/projection/product/productVariant";

interface OnSaveFunctionArgs {
  readonly look: LookProjection;
  readonly lookTemplate: LookTemplateEnum;
}
interface OnSaveFunction {
  (args: OnSaveFunctionArgs): void;
}

const lookForTemplate = (look: LookProjection, template: LookTemplateEnum): LookProjection =>
  look.slice(0, itemsPerLookTemplate[template]) as unknown as LookProjection;

interface LookCreatorProps {
  readonly productVariants: ProductVariantProjection[] | undefined;
  readonly look?: LookProjection;
  readonly onCancel: () => void;
  readonly onSave: OnSaveFunction;
  readonly onChangedTemplate: (lookTemplate: LookTemplateEnum) => void;
}

const LookCreator: FC<LookCreatorProps> = ({
  productVariants,
  look = ["", "", "", ""],
  onCancel,
  onSave,
  onChangedTemplate,
}) => {
  const [draftLook, setDraftLook] = useState<LookProjection>(look);
  const [template, setTemplate] = useState<LookTemplateEnum>(LookTemplateEnum.THREE);
  const handleOnChangedTemplate = useCallback(
    (lookTemplate: LookTemplateEnum) => {
      setTemplate(lookTemplate);
      onChangedTemplate(lookTemplate);
    },
    [onChangedTemplate],
  );

  const handleOnSave = useCallback(
    () => onSave({ look: lookForTemplate(draftLook, template), lookTemplate: template }),
    [draftLook, onSave, template],
  );
  const handleOnProductVariantChanged = useCallback(
    (index: number) => (productVariant: LookProductVariantProjection) => {
      const look = [...draftLook.slice(0, index), productVariant.id, ...draftLook.slice(++index)];
      setDraftLook(look);
    },
    [draftLook],
  );

  const [validationErrors] = useListLookValidationErrors({
    look: lookForTemplate(draftLook, template),
    lookTemplate: template,
  });
  const isValid = validationErrors && validationErrors.errors.length === 0;

  const draftLookProductVariants = draftLook.map((productVariantId) =>
    productVariants?.find((productVariant) => productVariant.id === productVariantId),
  );

  return (
    <Modal
      className="look-creator"
      title={<LookTemplateSelector value={template} onChanged={handleOnChangedTemplate} />}
      visible
      onHide={onCancel}
    >
      <LookTemplate lookProductVariants={draftLookProductVariants} variant={template}>
        {(productVariant: LookProductVariantProjection | undefined, index: number) => (
          <LookProductVariantSelector
            productVariant={productVariant}
            productVariants={productVariants}
            onChanged={handleOnProductVariantChanged(index)}
          />
        )}
      </LookTemplate>
      <footer className="look-creator__footer">
        <Button aria-label="look-creator-cancel" onClick={onCancel}>
          <I18nMessage id={SelectionI18n.CANCEL_LOOK} prefix={SELECTION_I18N_PREFIX} />
        </Button>
        <Button
          aria-label="look-creator-save"
          disabled={!isValid}
          variant={ButtonVariant.PRIMARY}
          onClick={handleOnSave}
        >
          <I18nMessage id={SelectionI18n.SAVE_LOOK} prefix={SELECTION_I18N_PREFIX} />
        </Button>
      </footer>
    </Modal>
  );
};

export default LookCreator;
