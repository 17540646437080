import { command, Command } from "@lookiero/messaging";

const REMOVE_NOTE_TEMPLATE = "remove_note_template";

interface RemoveNoteTemplatePayload {
  readonly aggregateId: string;
}

interface RemoveNoteTemplate extends Command<typeof REMOVE_NOTE_TEMPLATE>, RemoveNoteTemplatePayload {}

interface RemoveNoteTemplateFunction {
  (payload: RemoveNoteTemplatePayload): RemoveNoteTemplate;
}

const removeNoteTemplate: RemoveNoteTemplateFunction = ({ aggregateId }) =>
  command({ name: REMOVE_NOTE_TEMPLATE, aggregateId });

export type { RemoveNoteTemplate };
export { REMOVE_NOTE_TEMPLATE, removeNoteTemplate };
