import { useMemo, useCallback, FC, createContext, useRef, CSSProperties } from "react";
import { ListOnItemsRenderedProps, VariableSizeList } from "react-window";
import { WindowScroller } from "react-virtualized";
import { Size } from "react-virtualized-auto-sizer";
import { throttle } from "lodash";
import pageForRange from "../util/pageForRange";
import VirtualizedListProps from "./VirtualizedListProps";
import { VirtualizedListOuterElementTagName, virtualizedListOuterElement } from "./VirtualizedListOuterElement";
import { VirtualizedListRowTagName } from "./VirtualizedListRow";
import { virtualizedListRowRenderer } from "./VirtualizedListRowRenderer";

/**
 * By default the VariableSizeList's scrollElement maps to the 'window' so the height is always defined.
 * But if we explicitly specify the scrollElement on the initial render the height is undefined.
 */
const DEFAULT_HEIGHT = 1000;

type VirtualizedListWithSizeMapContextValue = {
  readonly setItemSize: (index: number, size: number) => void;
};
export const VirtualizedListWithSizeMapContext = createContext<VirtualizedListWithSizeMapContextValue>(
  {} as VirtualizedListWithSizeMapContextValue,
);

export interface VirtualizedListWithSizeMapTagName {
  readonly listRow: VirtualizedListRowTagName;
  readonly innerElementTagName: "tbody" | "div";
  readonly outerElementTagName: VirtualizedListOuterElementTagName;
}

type VirtualizedListWithSizeMapProps = VirtualizedListProps & {
  readonly size: Size;
  readonly listRowClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly listRowStyleFn?: (data: any) => CSSProperties;
  readonly tagName?: Partial<VirtualizedListWithSizeMapTagName>;
  readonly onClickListRow?: (index: number, data: unknown) => void;
};
const VirtualizedListWithSizeMap: FC<VirtualizedListWithSizeMapProps> = ({
  size: { width },
  tagName,
  listRowClassName,
  listRowStyleFn,
  paginated = false,
  itemsPerRow,
  itemCount,
  estimatedItemHeight,
  children,
  page,
  perPage,
  data,
  onPageChanged,
  scrollElement,
  onClickListRow,
}: VirtualizedListWithSizeMapProps) => {
  const itemsPerRowForWidth = itemsPerRow(width);
  const totalRows = Math.ceil(itemCount / itemsPerRowForWidth);

  const listRef = useRef<VariableSizeList | null>(null);
  const sizeMap = useRef<{ [key: string]: number }>({});
  const setItemSize = useCallback((index: number, size: number) => {
    if (sizeMap.current[index] === size) {
      return;
    }

    sizeMap.current = { ...sizeMap.current, [index]: size };

    if (listRef.current) {
      listRef.current.resetAfterIndex(index);
    }
  }, []);
  const getItemSize = useCallback(
    (index: number) => sizeMap.current[index] || estimatedItemHeight(width),
    [estimatedItemHeight, width],
  );
  const contextValue = useMemo(() => ({ setItemSize }), [setItemSize]);

  /**
   * Page change callback based on the visible items
   */
  const pageRef = useRef(page);
  pageRef.current = page;
  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }: ListOnItemsRenderedProps): void => {
      const indexPage = pageForRange([visibleStartIndex, visibleStopIndex], itemsPerRowForWidth, perPage, paginated);

      if (indexPage === pageRef.current) {
        return;
      }

      onPageChanged(indexPage);
    },
    [itemsPerRowForWidth, onPageChanged, paginated, perPage],
  );

  /**
   * Sync window-scroller's scroll with the actual list's scroll
   */
  const handleWindowScrollerScroll = useMemo(
    () => throttle(({ scrollTop }) => listRef.current !== null && listRef.current.scrollTo(scrollTop), 16),
    [],
  );

  /**
   * Actual list data. This will trigger a re-render if any of its dependencies change
   */
  const listItemData = useMemo(
    () => ({ itemCount, itemsPerRow: itemsPerRowForWidth, perPage, children, data }),
    [children, data, itemCount, itemsPerRowForWidth, perPage],
  );

  const VirtualizedListOuterElement = useMemo(
    () => virtualizedListOuterElement({ tagName: tagName?.outerElementTagName }),
    [tagName?.outerElementTagName],
  );

  const VirtualizedListRowRenderer = useMemo(
    () =>
      virtualizedListRowRenderer({
        className: listRowClassName,
        styleFn: listRowStyleFn,
        tagName: tagName?.listRow,
        onClickListRow,
      }),
    [listRowClassName, listRowStyleFn, onClickListRow, tagName?.listRow],
  );

  return itemCount ? (
    <VirtualizedListWithSizeMapContext.Provider value={contextValue}>
      <WindowScroller
        scrollElement={scrollElement ? scrollElement : undefined}
        scrollingResetTimeInterval={300}
        onScroll={handleWindowScrollerScroll}
      >
        {({ height = DEFAULT_HEIGHT }) => (
          <VariableSizeList
            ref={listRef}
            estimatedItemSize={estimatedItemHeight(width)}
            height={height}
            innerElementType={tagName?.innerElementTagName}
            itemCount={totalRows}
            itemData={listItemData}
            itemSize={getItemSize}
            outerElementType={VirtualizedListOuterElement}
            overscanCount={2}
            width={width}
            onItemsRendered={handleItemsRendered}
          >
            {VirtualizedListRowRenderer}
          </VariableSizeList>
        )}
      </WindowScroller>
    </VirtualizedListWithSizeMapContext.Provider>
  ) : (
    <div />
  );
};

export default VirtualizedListWithSizeMap;
