import { DomainEvent, MessageName } from "@lookiero/messaging";
import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { isNoteTemplateWritten } from "../../../../domain/noteTemplate/model/noteTemplateWritten";
import { isNoteTemplateRemoved } from "../../../../domain/noteTemplate/model/noteTemplateRemoved";
import {
  ListNoteTemplateCategoriesByPsIdResult,
  listNoteTemplateCategoriesByPsId,
} from "../../../../projection/noteTemplateCategory/listNoteTemplateCategoriesByPsId";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isNoteTemplateWritten(event) || isNoteTemplateRemoved(event);

interface UseListNoteTemplateCategoriesByPsIdFunctionArgs {
  readonly personalShopperId: string | undefined;
}

interface UseListNoteTemplateCategoriesByPsIdFunction {
  (
    args: UseListNoteTemplateCategoriesByPsIdFunctionArgs,
  ): UseQueryFunctionResult<ListNoteTemplateCategoriesByPsIdResult>;
}

const useListNoteTemplateCategoriesByPsId: UseListNoteTemplateCategoriesByPsIdFunction = ({ personalShopperId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listNoteTemplateCategoriesByPsId({ personalShopperId: personalShopperId as string }),
    invalidation: shouldInvalidate,
    options: { staleTime: 5 * 60 * 1000, enabled: personalShopperId !== undefined },
  });

export { useListNoteTemplateCategoriesByPsId };
