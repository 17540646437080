import { command, Command } from "@lookiero/messaging";
import { FilteringValue } from "../model/personalShopperFiltering";

const SET_PERSONAL_SHOPPER_FILTERING = "set_personal_shopper_filtering";

interface SetPersonalShopperFilteringPayload {
  readonly aggregateId: string;
  readonly boxId: string;
  readonly customerId: string;
  readonly psId: string;
  readonly positives: FilteringValue[];
  readonly negatives: FilteringValue[];
}

interface SetPersonalShopperFiltering
  extends Command<typeof SET_PERSONAL_SHOPPER_FILTERING>,
    SetPersonalShopperFilteringPayload {}

interface SetPersonalShopperFilteringFunction {
  (payload: SetPersonalShopperFilteringPayload): SetPersonalShopperFiltering;
}

const setPersonalShopperFiltering: SetPersonalShopperFilteringFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: SET_PERSONAL_SHOPPER_FILTERING }),
  ...payload,
});

export type { SetPersonalShopperFiltering };
export { SET_PERSONAL_SHOPPER_FILTERING, setPersonalShopperFiltering };
