import MediaPerspective from "../../../domain/media/model/MediaPerspective";

type Media = {
  readonly url: string;
  readonly perspective: MediaPerspective;
};

const mainTransparentImageFromMedia = (media: Media[]): Media =>
  media.find((media) => media.perspective === MediaPerspective.MAIN_TRANSPARENT) || mainImageFromMedia(media);

const mainImageFromMedia = (media: Media[]): Media =>
  media.find((media) => media.perspective === MediaPerspective.MAIN) || mediaFallback(media);

const mediaFallback = (media: Media[]): Media => media[0] || { perspective: MediaPerspective.MAIN, url: "" };

export { mainTransparentImageFromMedia, mainImageFromMedia };

export default Media;
