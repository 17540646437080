import { FC, ReactNode } from "react";
import classNames from "classnames";
import Text from "../../atoms/text/Text";
import "./nav-item.css";

interface NavItemProps {
  readonly children: ReactNode;
  readonly active: boolean;
  readonly onSelected?: () => void;
}

const NavItem: FC<NavItemProps> = ({ children, active, onSelected }: NavItemProps) => (
  <div className={classNames("nav-item", { "nav-item--active": active })} onClick={onSelected}>
    <Text>{children}</Text>
  </div>
);

export default NavItem;
