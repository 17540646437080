import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { sendBoxToSupervise } from "../../../../domain/box/command/sendBoxToSupervise";
import invariant from "ts-invariant";
import { Logger } from "../../../../../shared/logging/Logger";

interface SendBoxToSuperviseFunction {
  (): Promise<void> | undefined;
}

interface UseSendBoxToSuperviseFunctionArgs {
  readonly boxId: string;
  readonly boxNumber: string;
  readonly personalShopperId: string | undefined;
  readonly logger: Logger;
}

type UseSendBoxToSuperviseResult = [
  sendBoxToSupervise: SendBoxToSuperviseFunction,
  sendBoxToSuperviseStatus: CommandStatus,
];

interface UseSendBoxToSuperviseFunction {
  (args: UseSendBoxToSuperviseFunctionArgs): UseSendBoxToSuperviseResult;
}

const useSendBoxToSupervise: UseSendBoxToSuperviseFunction = ({ boxId, boxNumber, personalShopperId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const sendToSupervise: SendBoxToSuperviseFunction = useCallback(async () => {
    invariant(personalShopperId, "personalShopperId must be defined in order to send box to supervise");

    try {
      await commandBus(sendBoxToSupervise({ aggregateId: boxId, boxNumber, personalShopperId }));
    } catch (error) {
      logger.captureException(error as Error);
    }
  }, [boxId, boxNumber, commandBus, logger, personalShopperId]);

  return [sendToSupervise, status];
};

export { useSendBoxToSupervise };
