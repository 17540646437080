import { useCallback, useMemo } from "react";
import ButtonOption, { ButtonOptionState } from "../../../../../../../shared/ui/uiKit/atoms/buttonOption/ButtonOption";
import { IconVariant } from "../../../../../../../shared/ui/uiKit/atoms/icon/Icon";
import { activate, deactivate, isAnyLeafActive } from "../../../ShortlistFilterState";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import classNames from "classnames";
import "./icon-option-shortlist-filter-item.css";

const IconOptionShortlistFilterItem: ShortlistFilterItemHOC = ({ variant, useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function IconOptionShortlistFilterItemComponent({ filter, children }) {
    const { state, onChange } = useShortlistFiltering();

    const optionState = useMemo(
      () => (isAnyLeafActive({ filter, state }) ? ButtonOptionState.SELECTED : ButtonOptionState.DEFAULT),
      [filter, state],
    );

    const handleOnChange = useCallback(
      (checked: boolean) => onChange({ state: checked ? activate({ filter, state }) : deactivate({ filter, state }) }),
      [filter, onChange, state],
    );

    return (
      <>
        <ButtonOption
          className={classNames("icon-option-shortlist-filter-item", `icon-option-shortlist-filter-item--${variant}`)}
          icon={filter.alias as IconVariant}
          state={optionState}
          onChange={handleOnChange}
        />
        {children}
      </>
    );
  };

export default IconOptionShortlistFilterItem;
