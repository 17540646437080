import Navigator from "../Navigator";

class WindowNavigator implements Navigator {
  private apiUrl: string;

  public constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  public async sendBeacon(url: string | URL, data?: BodyInit | null): Promise<boolean> {
    return window.navigator.sendBeacon(`${this.apiUrl}${url}`, data);
  }
}

export default WindowNavigator;
