import { command, Command } from "@lookiero/messaging";
import BoxPreviewStatus from "../../boxPreview/model/BoxPreviewStatus";

const DESELECT_PRODUCT_VARIANT = "deselect_product_variant";

interface DeselectProductVariantPayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
  readonly productVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
}

interface DeselectProductVariant extends Command<typeof DESELECT_PRODUCT_VARIANT>, DeselectProductVariantPayload {}

interface DeselectProductVariantFunction {
  (payload: DeselectProductVariantPayload): DeselectProductVariant;
}

const deselectProductVariant: DeselectProductVariantFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: DESELECT_PRODUCT_VARIANT }),
  ...payload,
});

export type { DeselectProductVariant };
export { DESELECT_PRODUCT_VARIANT, deselectProductVariant };
