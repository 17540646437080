import { CommandHandler } from "@lookiero/messaging.js";
import BoxPreview from "../../model/BoxPreview";
import BoxPreviews from "../../model/BoxPreviews";
import SendBoxPreview from "./SendBoxPreview";

class SendBoxPreviewHandler implements CommandHandler<SendBoxPreview> {
  private readonly boxPreviews: BoxPreviews;

  public constructor(boxPreviews: BoxPreviews) {
    this.boxPreviews = boxPreviews;
  }

  public async execute({
    boxPreviewId,
    boxId,
    selectionProductVariantIds,
    looks,
    selectionId,
  }: SendBoxPreview): Promise<void> {
    let boxPreview: BoxPreview | undefined = await this.boxPreviews.byBoxId(boxId);

    if (boxPreview) {
      throw new Error("There is a BoxPreview already sent for this box");
    }

    if (!looks || looks?.length === 0) {
      throw new Error("Looks are not created for this BoxPreview");
    }

    const looksAreNotIncludedInSelection = looks?.some((look) =>
      look.some((productVariantId) => !selectionProductVariantIds.includes(productVariantId)),
    );

    if (looksAreNotIncludedInSelection) {
      throw new Error("Looks' productVariants are not included in the Selection");
    }

    boxPreview = BoxPreview.send({ boxPreviewId, boxId, looks, selectionId });

    await this.boxPreviews.send(boxPreview, selectionId);
  }
}

export default SendBoxPreviewHandler;
