import { isBoxClosed } from "../../../../domain/box/model/boxClosed";
import { HttpBoxesSaveFunction } from "./httpBoxes";

interface HttpBoxesCloseFunction extends HttpBoxesSaveFunction {}

const httpBoxesClose: HttpBoxesCloseFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const boxClosed = domainEvents.find(isBoxClosed);

    if (!boxClosed) {
      return;
    }

    await httpPost({
      endpoint: "/close-box",
      body: {
        box_id: aggregateId,
      },
    });
  };

export { httpBoxesClose };
