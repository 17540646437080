import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { AnnotationProjection } from "./annotation";

const VIEW_ANNOTATION_BY_ID = "view_annotation_by_id";

interface ViewAnnotationByIdPayload {
  readonly id: string;
}

interface ViewAnnotationById extends Query<typeof VIEW_ANNOTATION_BY_ID>, ViewAnnotationByIdPayload {}

interface ViewAnnotationByIdFunction {
  (payload: ViewAnnotationByIdPayload): ViewAnnotationById;
}

const viewAnnotationById: ViewAnnotationByIdFunction = (payload) => ({
  ...query({ name: VIEW_ANNOTATION_BY_ID }),
  ...payload,
});

type ViewAnnotationByIdResult = AnnotationProjection | null;

interface AnnotationByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface AnnotationByIdView {
  (args: AnnotationByIdViewArgs): Promise<ViewAnnotationByIdResult>;
}

interface ViewAnnotationByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: AnnotationByIdView;
}

const viewAnnotationByIdHandler: QueryHandlerFunction<
  ViewAnnotationById,
  ViewAnnotationByIdResult,
  ViewAnnotationByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type { AnnotationByIdView, ViewAnnotationById, ViewAnnotationByIdResult };
export { VIEW_ANNOTATION_BY_ID, viewAnnotationById, viewAnnotationByIdHandler };
