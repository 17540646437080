import { localStorageStorage } from "./localStorageStorage";
import { SelectionOrderDto } from "./selectionOrderDto";
import { Storage } from "./storage";

const SELECTION_ORDER_KEY = "SELECTION_ORDERS";

interface LocalStorageSelectionOrdersFunction {
  (): Storage<SelectionOrderDto>;
}

const localStorageSelectionOrders: LocalStorageSelectionOrdersFunction = () => {
  const { list, read, remove, write } = localStorageStorage<SelectionOrderDto>();

  return {
    list,
    read: (key: string) => read(`${SELECTION_ORDER_KEY}_ ${key}`),
    remove: (key: string) => remove(`${SELECTION_ORDER_KEY}_ ${key}`),
    write: (key: string, value: SelectionOrderDto) => write(`${SELECTION_ORDER_KEY}_ ${key}`, value),
  };
};

export { localStorageSelectionOrders };
