import { NoteProjection } from "../../../../projection/note/note";
import { NoteByIdView, ViewNoteByIdResult } from "../../../../projection/note/viewNoteById";
import { HttpPostFunction } from "../../../delivery/http/httpClient";

interface HttpNoteByIdView extends NoteByIdView {}

interface HttpNoteByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpNoteByIdViewFunction {
  (args: HttpNoteByIdViewFunctionArgs): HttpNoteByIdView;
}

const httpNoteByIdView: HttpNoteByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<NoteProjection, ViewNoteByIdResult>({
      endpoint: "/view-note-by-id",
      body: { id },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpNoteByIdView };
