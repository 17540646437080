import { CustomerInsightProjection } from "../../../projection/customerInsight/customerInsight";
import {
  CustomerInsightsByCustomerIdView,
  ViewCustomerInsightsByCustomerIdResult,
} from "../../../projection/customerInsight/viewCustomerInsightsByCustomerId";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpCustomerInsightsByCustomerIdView extends CustomerInsightsByCustomerIdView {}

interface HttpCustomerInsightsByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCustomerInsightsByCustomerIdViewFunction {
  (args: HttpCustomerInsightsByCustomerIdViewFunctionArgs): HttpCustomerInsightsByCustomerIdView;
}

const httpCustomerInsightsByCustomerIdView: HttpCustomerInsightsByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<CustomerInsightProjection, ViewCustomerInsightsByCustomerIdResult>({
      endpoint: "/view-customer-insights-by-customer-id",
      body: { customerId },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpCustomerInsightsByCustomerIdView };
