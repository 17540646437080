import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";

interface UiSettingProjection {
  readonly id: string;
  readonly key: string;
  readonly value: unknown;
}

const VIEW_UI_SETTING_BY_KEY = "view_ui_setting_by_key";

interface ViewUiSettingByKeyPayload {
  readonly key: string;
}

interface ViewUiSettingByKey extends Query<typeof VIEW_UI_SETTING_BY_KEY>, ViewUiSettingByKeyPayload {}

interface ViewUiSettingByKeyFunction {
  (payload: ViewUiSettingByKeyPayload): ViewUiSettingByKey;
}

const viewUiSettingByKey: ViewUiSettingByKeyFunction = (payload) => ({
  ...query({ name: VIEW_UI_SETTING_BY_KEY }),
  ...payload,
});

type ViewUiSettingByKeyResult = UiSettingProjection | null;

interface UiSettingByKeyViewArgs {
  readonly key: string;
}

interface UiSettingByKeyView {
  (args: UiSettingByKeyViewArgs): Promise<ViewUiSettingByKeyResult>;
}

interface ViewUiSettingByKeyHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: UiSettingByKeyView;
}

const viewUiSettingByKeyHandler: QueryHandlerFunction<
  ViewUiSettingByKey,
  ViewUiSettingByKeyResult,
  ViewUiSettingByKeyHandlerFunctionArgs
> =
  ({ view }) =>
  async ({ key }) =>
    view({ key });

export type { UiSettingProjection, UiSettingByKeyView };
export { VIEW_UI_SETTING_BY_KEY, viewUiSettingByKey, viewUiSettingByKeyHandler };
