import { FC, useCallback } from "react";
import { Bullets } from "../../../../../componentLibrary/layouts/carousel/Bullets";
import {
  RenderBulletsFunction,
  RenderItemFunction,
  Carousel,
} from "../../../../../componentLibrary/layouts/carousel/Carousel";
import { CustomerImage } from "../customerImage/CustomerImage";

interface CustomerCarouselProps {
  readonly activeIndex: number;
  readonly photos: string[];
  readonly keyboardControlled?: boolean;
  readonly onClick?: () => void;
  readonly itemWrapper?: (picture: JSX.Element) => JSX.Element;
  readonly onChange: (index: number) => void;
}
const CustomerCarousel: FC<CustomerCarouselProps> = ({
  activeIndex,
  photos,
  keyboardControlled,
  itemWrapper,
  onClick,
  onChange,
}) => {
  const renderBullets: RenderBulletsFunction = useCallback(
    ({ activeIndex, count, onChange }) => <Bullets activeIndex={activeIndex} count={count} onChange={onChange} />,
    [],
  );

  const renderItem: RenderItemFunction<string> = useCallback(
    ({ item }) => (itemWrapper ? itemWrapper(<CustomerImage id={item} />) : <CustomerImage id={item} />),
    [itemWrapper],
  );

  return (
    <Carousel
      activeIndex={activeIndex}
      bullets={renderBullets}
      data={photos}
      keyboardControlled={keyboardControlled}
      onChange={onChange}
      onClick={onClick}
    >
      {renderItem}
    </Carousel>
  );
};

export { CustomerCarousel };
