import { command, Command } from "@lookiero/messaging";
import { LookDto } from "../../../infrastructure/persistence/lookDto";

const SET_LOOKS = "set_looks";

interface SetLooksPayload {
  readonly aggregateId: string;
  readonly looks: LookDto[];
}

interface SetLooks extends Command<typeof SET_LOOKS>, SetLooksPayload {}

interface SetLooksFunction {
  (payload: SetLooksPayload): SetLooks;
}

const setLooks: SetLooksFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: SET_LOOKS }),
  ...payload,
});

export type { SetLooks };
export { SET_LOOKS, setLooks };
