import { command, Command } from "@lookiero/messaging";
import { NotificationLevel, NotificationType } from "../model/notification";

const CREATE_TOAST_NOTIFICATION = "create_toast_notification";

interface CreateToastNotificationPayload {
  readonly aggregateId: string;
  readonly level: NotificationLevel;
  readonly bodyI18nKey: string;
}

interface CreateToastNotification extends Command<typeof CREATE_TOAST_NOTIFICATION>, CreateToastNotificationPayload {
  readonly type: NotificationType.TOAST;
}

interface CreateToastNotificationFunction {
  (payload: CreateToastNotificationPayload): CreateToastNotification;
}

const createToastNotification: CreateToastNotificationFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: CREATE_TOAST_NOTIFICATION }),
  ...payload,
  type: NotificationType.TOAST,
});

export type { CreateToastNotification };
export { CREATE_TOAST_NOTIFICATION, createToastNotification };
