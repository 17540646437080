import { FC, ReactNode, useCallback } from "react";
import { EditorState, RichUtils } from "draft-js";
import { I18nMessage } from "@lookiero/i18n-react";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../../componentLibrary/_i18n/NoteI18n";
import EditorToolbarButton from "../../../componentLibrary/organisms/editor/editorToolbarButton/EditorToolbarButton";
import { useTrackClick } from "../../../../shared/tracking/infrastructure/useTrackClick";
import { TrackingClick, TrackingPage } from "../../../../shared/tracking/Tracker";
import useBoxSharedContext from "../../../views/_behaviors/useBoxSharedContext";
import { useViewPersonalShopper } from "../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import "./note-editor-toolbar.css";

const INLINE_STYLES = [
  {
    icon: IconVariant.BOLD,
    title: <I18nMessage id={NoteI18n.EDITOR_STYLE_BOLD} prefix={NOTE_I18N_PREFIX} />,
    style: "BOLD",
    label: "bold",
  },
];

type NoteEditorToolbarProps = {
  readonly children?: ReactNode;
  readonly state: EditorState;
  readonly extraActions?: ReactNode;
  readonly onChange: (state: EditorState) => void;
};
const NoteEditorToolbar: FC<NoteEditorToolbarProps> = ({ children, state, extraActions, onChange }) => {
  const currentStyle = state.getCurrentInlineStyle();
  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();

  const trackClick = useTrackClick({
    section: TrackingPage.SELECTION,
    psId: personalShopper?.id,
    boxId: box.id,
    userId: box.customerId,
  });

  const toggleInlineStyle = useCallback(
    (inlineStyle: string) => {
      onChange(RichUtils.toggleInlineStyle(state, inlineStyle));
      trackClick({
        clickId: TrackingClick.NOTE_ACTION,
        action: "bold",
      });
    },
    [onChange, state, trackClick],
  );

  const undo = useCallback(() => {
    onChange(EditorState.undo(state));
    trackClick({
      clickId: TrackingClick.NOTE_ACTION,
      action: "undo",
    });
  }, [onChange, state, trackClick]);

  const redo = useCallback(() => {
    onChange(EditorState.redo(state));
    trackClick({
      clickId: TrackingClick.NOTE_ACTION,
      action: "redo",
    });
  }, [onChange, state, trackClick]);

  return (
    <header aria-label="note-editor-toolbar" className="note-editor-toolbar" role="toolbar">
      <div className="note-editor-toolbar__actions">
        {INLINE_STYLES.map((type) => (
          <EditorToolbarButton
            key={type.style}
            active={currentStyle.has(type.style)}
            icon={type.icon}
            label={`${type.label}-toolbar-button`}
            title={type.title}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => toggleInlineStyle(type.style)}
          />
        ))}
        <span className="note-editor-toolbar__separator" />
        <EditorToolbarButton
          icon={IconVariant.UNDO}
          label="undo-toolbar-button"
          title={<I18nMessage id={NoteI18n.EDITOR_UNDO} prefix={NOTE_I18N_PREFIX} />}
          onClick={undo}
        />
        <EditorToolbarButton
          icon={IconVariant.REDO}
          label="redo-toolbar-button"
          title={<I18nMessage id={NoteI18n.EDITOR_REDO} prefix={NOTE_I18N_PREFIX} />}
          onClick={redo}
        />
        {extraActions && (
          <>
            <span className="note-editor-toolbar__separator" />
            {extraActions}
          </>
        )}
      </div>
      {children}
    </header>
  );
};

export default NoteEditorToolbar;
