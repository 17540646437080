import { DomainEvent, MessageName } from "@lookiero/messaging";
import {
  AutomaticSelectionStarted,
  AUTOMATIC_SELECTION_STARTED,
} from "../../../../domain/selection/model/automaticSelectionStarted";
import { HttpAutomaticSelectionsSaveFunction } from "./httpAutomaticSelections";

interface HttpAutomaticSelectionsStartFunction extends HttpAutomaticSelectionsSaveFunction {}

const isAutomaticStarted = (event: DomainEvent<MessageName>): event is AutomaticSelectionStarted =>
  event.name === AUTOMATIC_SELECTION_STARTED;

const httpAutomaticSelectionsStart: HttpAutomaticSelectionsStartFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents, boxId }) => {
    const automaticSelectionStarted = domainEvents.find(isAutomaticStarted);

    if (!automaticSelectionStarted) {
      return;
    }

    await httpPost({
      endpoint: "/start-automatic-selection",
      body: { selectionId: aggregateId, boxId },
    });
  };

export { httpAutomaticSelectionsStart };
