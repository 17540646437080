import { FilteringProjection } from "../../../../projection/filtering/filtering";

interface FilteringValueDto {
  readonly id: string;
  readonly modifiedOn: string;
}

interface FilteringDto {
  readonly personalShopperFilteringId: string;
  readonly positives: FilteringValueDto[];
  readonly negatives: FilteringValueDto[];
}

interface ToFilteringProjectionFunctionArgs {
  readonly filtering: FilteringDto;
}

interface ToFilteringProjectionFunction {
  (args: ToFilteringProjectionFunctionArgs): FilteringProjection;
}

const toFilteringProjection: ToFilteringProjectionFunction = ({ filtering }) => ({
  personalShopperFilteringId: filtering.personalShopperFilteringId,
  positives: filtering.positives.map((positive) => ({
    id: positive.id,
    modifiedOn: new Date(positive.modifiedOn),
  })),
  negatives: filtering.negatives.map((negative) => ({
    id: negative.id,
    modifiedOn: new Date(negative.modifiedOn),
  })),
});

export type { FilteringDto };
export { toFilteringProjection };
