import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ReturnQuestionProjection } from "./returnQuestion";

const LIST_RETURN_QUESTIONS = "list_return_questions";

interface ListReturnQuestions extends Query<typeof LIST_RETURN_QUESTIONS> {}

interface ListReturnQuestionsFunction {
  (): ListReturnQuestions;
}

const listReturnQuestions: ListReturnQuestionsFunction = () => query({ name: LIST_RETURN_QUESTIONS });

type ListReturnQuestionsResult = ReturnQuestionProjection[] | null;

interface ReturnQuestionsViewArgs extends CancelableQueryViewArgs {}

interface ReturnQuestionsView {
  (args: ReturnQuestionsViewArgs): Promise<ListReturnQuestionsResult>;
}

interface ListReturnQuestionsHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ReturnQuestionsView;
}

const listReturnQuestionsHandler: QueryHandlerFunction<
  ListReturnQuestions,
  ListReturnQuestionsResult,
  ListReturnQuestionsHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async () =>
    view({ signal });

export type { ReturnQuestionsView, ListReturnQuestions, ListReturnQuestionsResult };
export { LIST_RETURN_QUESTIONS, listReturnQuestions, listReturnQuestionsHandler };
