import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { SynchronizeCapacityCalendar } from "../command/synchronizeCapacityCalendar";
import { capacityCalendarSynchronized } from "./capacityCalendarSynchronized";
import { Segment } from "../../../projection/segment/segment";

interface CapacityCalendar extends AggregateRoot {
  readonly segments: Segment[];
}

const synchronizeCapacityCalendarHandler: CommandHandlerFunction<SynchronizeCapacityCalendar, CapacityCalendar> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, segments } = command;

    return {
      ...aggregateRoot,
      segments,
      domainEvents: [capacityCalendarSynchronized({ aggregateId })],
    };
  };

export type { CapacityCalendar };
export { synchronizeCapacityCalendarHandler };
