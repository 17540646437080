import { FC, ReactNode, useMemo } from "react";
import AspectRatioContainer from "../../../../shared/ui/uiKit/layouts/aspectRatioContainer/AspectRatioContainer";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import { LookTemplate as LookTemplateEnum, itemsPerLookTemplate } from "../../../../core/projection/looks/lookTemplate";
import { LookProductVariantProjection } from "../../../../core/projection/looks/lookProductVariant";
import "./look-template.css";

type LookTemplateItemProps = {
  readonly children: ReactNode;
};
const LookTemplateItem: FC<LookTemplateItemProps> = ({ children }) => (
  <AspectRatioContainer aspectRatio={AspectRatio.R_4_5} className="look-template__item">
    <div className="look-template__item-wrapper">{children}</div>
  </AspectRatioContainer>
);

type LookTemplateProps = {
  readonly variant?: LookTemplateEnum;
  readonly lookProductVariants: (LookProductVariantProjection | undefined)[];
  readonly children: (productVariant: LookProductVariantProjection | undefined, index: number) => ReactNode;
};
const LookTemplate: FC<LookTemplateProps> = ({ variant = LookTemplateEnum.TWO, lookProductVariants, children }) => {
  const templateEven = useMemo(
    () => (
      <div className={`look-template look-template--even`}>
        {Array(itemsPerLookTemplate[variant])
          .fill(1)
          .map((item, index) => (
            <LookTemplateItem key={index}>{children(lookProductVariants?.[index], index)}</LookTemplateItem>
          ))}
      </div>
    ),
    [children, lookProductVariants, variant],
  );

  const template3 = useMemo(
    () => (
      <div className={`look-template look-template--three`}>
        <LookTemplateItem>{children(lookProductVariants?.[0], 0)}</LookTemplateItem>
        <div className="look-template__column">
          <LookTemplateItem>{children(lookProductVariants?.[1], 1)}</LookTemplateItem>
          <LookTemplateItem>{children(lookProductVariants?.[2], 2)}</LookTemplateItem>
        </div>
      </div>
    ),
    [children, lookProductVariants],
  );

  return variant === LookTemplateEnum.THREE ? template3 : templateEven;
};

export default LookTemplate;
