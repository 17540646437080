import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isUiSettingUpdated } from "../../../../domain/uiSetting/model/uiSettingUpdated";
import { UiSettingProjection, viewUiSettingByKey } from "../../../../projection/uiSetting/viewUiSettingByKey";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface UseViewUiSettingByKeyFunctionArgs {
  readonly key: string;
}

interface UseViewUiSettingByKeyFunction {
  (args: UseViewUiSettingByKeyFunctionArgs): UseQueryFunctionResult<UiSettingProjection>;
}

const useViewUiSettingByKey: UseViewUiSettingByKeyFunction = ({ key }) =>
  useQuery({
    query: viewUiSettingByKey({ key }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: isUiSettingUpdated,
    options: { refetchOnMount: "always", staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useViewUiSettingByKey };
