import { FC, useCallback } from "react";
import { SelectionSummaryProductPreview as SelectionSummaryProductPreviewOrganism } from "../../../componentLibrary/organisms/selectionSummaryProductPreview/SelectionSummaryProductPreview";
import { useViewBoxPreviewByBoxId } from "../../../../core/infrastructure/projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { SelectionAlertProjection } from "../../../../core/projection/alert/selectionAlert";
import {
  SelectionProductProjection,
  SelectionProductVariantProjection,
} from "../../../../core/projection/selection/selection";
import { useDeselectProductVariant } from "../../../../core/infrastructure/domain/selection/react/useDeselectProductVariant";
import { useLogger } from "../../../../shared/logging/useLogger";
import { asyncActionStateForCommandStatus } from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import useBoxSharedContext from "../../_behaviors/useBoxSharedContext";

type SelectionSummaryProductPreviewProps = {
  readonly alerts?: SelectionAlertProjection[];
  readonly product: SelectionProductProjection;
  readonly legacyBoxId: string;
  readonly boxId: string;
  readonly onDeselectProductVariant?: (productVariantId: string) => void;
  readonly onClick: (productId: string) => void;
};
const SelectionSummaryProductPreview: FC<SelectionSummaryProductPreviewProps> = ({
  alerts,
  product,
  legacyBoxId,
  boxId,
  onDeselectProductVariant,
  onClick,
}) => {
  const { selection } = useBoxSharedContext();
  const logger = useLogger();

  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId });

  const [deselectProductVariant, deselectProductVariantStatus] = useDeselectProductVariant({
    selectionId: selection?.selectionId,
    boxNumber: legacyBoxId,
    boxPreviewStatus: boxPreview?.status,
    logger,
  });

  const handleOnDeselectProductVariant = useCallback(
    (productVariant: SelectionProductVariantProjection) => {
      deselectProductVariant({ productVariantId: productVariant.id });
      onDeselectProductVariant?.(productVariant.id);
    },
    [deselectProductVariant, onDeselectProductVariant],
  );

  const handleOnClick = useCallback(() => {
    onClick(product.id);
  }, [onClick, product.id]);

  return (
    <SelectionSummaryProductPreviewOrganism
      alerts={alerts}
      deselectProductVariantState={asyncActionStateForCommandStatus[deselectProductVariantStatus]}
      product={product}
      onClick={handleOnClick}
      onDeselectProductVariant={handleOnDeselectProductVariant}
    />
  );
};

export { SelectionSummaryProductPreview };
