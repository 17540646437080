import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const NOTE_GENERATED = "note_generated";

interface NoteGeneratedPayload {
  readonly aggregateId: string;
}

interface NoteGenerated extends DomainEvent<typeof NOTE_GENERATED> {}

interface NoteGeneratedFunction {
  (payload: NoteGeneratedPayload): NoteGenerated;
}

const noteGenerated: NoteGeneratedFunction = ({ aggregateId }) => domainEvent({ aggregateId, name: NOTE_GENERATED });

const isNoteGenerated = (event: DomainEvent<MessageName>): event is NoteGenerated => event.name === NOTE_GENERATED;

export type { NoteGenerated };
export { NOTE_GENERATED, noteGenerated, isNoteGenerated };
