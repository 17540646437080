import PositiveFeedback from "../../../domain/positiveFeedback/model/PositiveFeedback";
import ReturnReason from "../../../domain/returnReason/model/ReturnReason";
import Sizing from "../../../domain/sizing/model/Sizing";
import { HistoryShopProductVariantProjection } from "../../../projection/historyShop/historyShopProductVariant";
import Media from "../../../projection/media/model/Media";
import Price from "../../../projection/price/model/Price";
import Size from "../../../projection/size/model/Size";
import { FeedbackResponseDTO, feedbackFromResponse } from "../feedback/model/feedback";

interface HistoryShopProductVariantDto {
  readonly productId: string;
  readonly productVariantId: string;
  readonly media: Media[];
  readonly kept: boolean;
  readonly returnReasons?: ReturnReason[];
  readonly positiveFeedback?: PositiveFeedback[];
  readonly comment?: string;
  readonly size: Size;
  readonly boughtOn: string;
  readonly price: Price;
  readonly sizing: Sizing;
  readonly feedback: FeedbackResponseDTO;
}

const toHistoryShopProductVariantProjection = ({
  productId,
  productVariantId,
  media,
  kept,
  returnReasons,
  positiveFeedback,
  comment,
  size,
  boughtOn,
  price,
  sizing,
  feedback,
}: HistoryShopProductVariantDto): HistoryShopProductVariantProjection => ({
  productId,
  productVariantId,
  media: media as Media[],
  kept,
  returnReasons,
  positiveFeedback,
  comment,
  size,
  boughtOn: new Date(boughtOn),
  price,
  sizing,
  feedback: feedbackFromResponse(feedback),
});

export type { HistoryShopProductVariantDto };
export { toHistoryShopProductVariantProjection };
