interface StateFilteringValue {
  readonly id: string;
  readonly value?: string;
}

interface HasFilteringChangedFunctionArgs {
  readonly previousFiltering: StateFilteringValue[];
  readonly filtering: StateFilteringValue[];
}

interface HasFilteringChangedFunction {
  (args: HasFilteringChangedFunctionArgs): boolean;
}

const hasFilteringChanged: HasFilteringChangedFunction = ({ previousFiltering, filtering }) =>
  filtering.length !== previousFiltering.length ||
  filtering.some(
    ({ id: filteringId }) =>
      !previousFiltering.find(({ id: previousFilteringId }) => filteringId === previousFilteringId),
  );

export { hasFilteringChanged };
