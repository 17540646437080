import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { HistoryBoxProjection } from "./historyBox";

const SEARCH_HISTORY_BOXES_BY_CRITERIA = "search_history_boxes_by_criteria";

interface SearchHistoryBoxesByCriteriaPayload {
  readonly customerId: string;
}

interface SearchHistoryBoxesByCriteria
  extends Query<typeof SEARCH_HISTORY_BOXES_BY_CRITERIA>,
    SearchHistoryBoxesByCriteriaPayload {}

interface SearchHistoryBoxesByCriteriaFunction {
  (payload: SearchHistoryBoxesByCriteriaPayload): SearchHistoryBoxesByCriteria;
}

const searchHistoryBoxesByCriteria: SearchHistoryBoxesByCriteriaFunction = (payload) => ({
  ...query({ name: SEARCH_HISTORY_BOXES_BY_CRITERIA }),
  ...payload,
});

type SearchHistoryBoxesByCriteriaResult = HistoryBoxProjection[] | null;

interface HistoryBoxesByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface HistoryBoxesByCriteriaView {
  (args: HistoryBoxesByCriteriaViewArgs): Promise<SearchHistoryBoxesByCriteriaResult>;
}

interface SearchHistoryBoxesByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: HistoryBoxesByCriteriaView;
}

const searchHistoryBoxesByCriteriaHandler: QueryHandlerFunction<
  SearchHistoryBoxesByCriteria,
  SearchHistoryBoxesByCriteriaResult,
  SearchHistoryBoxesByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { HistoryBoxesByCriteriaView, SearchHistoryBoxesByCriteria, SearchHistoryBoxesByCriteriaResult };
export { SEARCH_HISTORY_BOXES_BY_CRITERIA, searchHistoryBoxesByCriteria, searchHistoryBoxesByCriteriaHandler };
