import MeasuredSize from "../measured/MeasuredSize";
import Layout from "./Layout";

type DataId = string;

type RowHeightForIndexParameters<T> = {
  readonly row: number;
  readonly itemsPerRowForWidth: number;
  readonly itemSizes: Record<DataId, MeasuredSize | undefined>;
  readonly data: T[];
};
const rowHeight = <T extends { readonly id: DataId }>({
  row,
  itemsPerRowForWidth,
  itemSizes,
  data,
}: RowHeightForIndexParameters<T>) => {
  const previousRowItems = data.slice(row * itemsPerRowForWidth, (row + 1) * itemsPerRowForWidth);

  return previousRowItems.reduce((acc, item) => Math.max(itemSizes[item.id]?.height || 0, acc), 0);
};

type LayoutParameters<T> = {
  readonly data: T[];
  readonly itemsPerRowForWidth: number;
  readonly itemSizes: Record<DataId, MeasuredSize | undefined>;
  readonly itemWidth: number;
};
const build = <T extends { readonly id: DataId }>({
  data,
  itemsPerRowForWidth,
  itemSizes,
  itemWidth,
}: LayoutParameters<T>): {
  readonly layout: Layout<DataId>;
  readonly height: number;
} => {
  const { layout, accY } = data.reduce(
    (acc, item, index) => {
      const isFirstItemInRow = index !== 0 && Math.floor(index % itemsPerRowForWidth) === 0;

      let previousRowHeight = 0;
      if (isFirstItemInRow) {
        const row = Math.floor(index / itemsPerRowForWidth);
        previousRowHeight = rowHeight({ row: row - 1, itemsPerRowForWidth, itemSizes, data });
      }

      const x = Math.floor(index % itemsPerRowForWidth) * itemWidth;
      const y = acc.accY + previousRowHeight;

      return {
        layout: { ...acc.layout, [item.id]: { x, y } },
        accY: y,
      };
    },
    { layout: {}, accY: 0 },
  );

  const lastRow = Math.floor((data.length - 1) / itemsPerRowForWidth);
  const lastRowHeight = rowHeight({ row: lastRow, itemsPerRowForWidth, itemSizes, data });

  return {
    layout,
    height: accY + lastRowHeight,
  };
};

const clamp = (value: number, [min, max]: [number, number]) => Math.min(Math.max(value, min), max);

/**
 * TODO this function should be improved.
 *
 * This is an approximated method for retrieving the index for some given coordinates.
 * Instead of using the itemWidth and itemHeight, it should use the measured sizes (layout).
 */
type IndexForCoordinatesParameters = {
  readonly x: number;
  readonly y: number;
  readonly itemWidth: number;
  readonly itemHeight: number;
  readonly itemsPerRowForWidth: number;
  readonly rowsForDataAndWidth: number;
};
const indexForCoordinates = ({
  x,
  y,
  itemWidth,
  itemHeight,
  itemsPerRowForWidth,
  rowsForDataAndWidth,
}: IndexForCoordinatesParameters): number => {
  const column = clamp(Math.floor((x + itemWidth / 2) / itemWidth), [0, itemsPerRowForWidth]);
  const row = clamp(Math.floor((y + itemHeight / 2) / itemHeight), [0, rowsForDataAndWidth]);

  return row * itemsPerRowForWidth + column;
};

export { build, indexForCoordinates };
