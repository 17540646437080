import {
  CountNoteTemplatesByCriteriaResult,
  NoteTemplatesCountByCriteriaView,
} from "../../../projection/noteTemplate/countNoteTemplatesByCriteria";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpNoteTemplatesCountByCriteriaView extends NoteTemplatesCountByCriteriaView {}

interface NoteTemplatesCountByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface NoteTemplatesCountByCriteriaViewFunction {
  (args: NoteTemplatesCountByCriteriaViewFunctionArgs): HttpNoteTemplatesCountByCriteriaView;
}

interface CountNoteTemplatesByCriteriaResponse {
  readonly result: number;
}

const httpNoteTemplatesCountByCriteriaView: NoteTemplatesCountByCriteriaViewFunction =
  ({ httpPost }) =>
  async ({ criteria, signal }) =>
    await httpPost<CountNoteTemplatesByCriteriaResponse, CountNoteTemplatesByCriteriaResult>({
      endpoint: "/count-note-templates-by-ps-id-and-criteria",
      body: { ps_id: criteria.psId, categories: criteria.categories },
      signal,
      result: {
        error: 0,
        success: (response) => response.result,
      },
    });

export { httpNoteTemplatesCountByCriteriaView };
