import { AlertContext, AlertProjection } from "./alert";

interface SelectionAlert extends AlertProjection {
  readonly productVariants?: string[];
  readonly context: AlertContext[];
}

const isSelectionAlert = (alert: AlertProjection): alert is SelectionAlert =>
  (alert as SelectionAlert).context !== undefined;

export { isSelectionAlert };

export type { SelectionAlert as SelectionAlertProjection };
