import { FC, ReactNode, useCallback } from "react";
import classNames from "classnames";
import useCaptureAndStopEvent from "../../_behaviors/useCaptureAndStopEvent/useCaptureAndStopEvent";
import Button from "../button/Button";
import { IconVariant } from "../icon/Icon";
import "./button-option.css";

enum ButtonOptionState {
  DEFAULT = "default",
  SELECTED = "selected",
  VISUALLY_DISABLED = "visually-disabled",
}

type ButtonOptionProps = {
  readonly className?: string;
  readonly state?: ButtonOptionState;
  readonly label?: ReactNode;
  readonly icon?: IconVariant;
  readonly onChange?: (selected: boolean) => void;
};
const ButtonOption: FC<ButtonOptionProps> = ({
  className,
  state = ButtonOptionState.DEFAULT,
  label,
  icon,
  onChange,
}: ButtonOptionProps) => {
  const onChangeCallback = useCallback(
    () => onChange && onChange(state !== ButtonOptionState.SELECTED),
    [onChange, state],
  );
  const onClick = useCaptureAndStopEvent(onChangeCallback);

  return (
    <Button
      aria-selected={state === ButtonOptionState.SELECTED}
      className={classNames("button-option", `button-option--${state}`, className)}
      icon={icon}
      role="option"
      onClick={onChange && onClick}
    >
      {label}
    </Button>
  );
};

export { ButtonOptionState };

export default ButtonOption;
