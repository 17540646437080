import { I18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback, useState } from "react";
import Button, { ButtonVariant } from "../../../../../shared/ui/uiKit/atoms/button/Button";
import ActionButton from "../../../../../shared/ui/uiKit/molecules/actionButton/ActionButton";
import AsyncActionState from "../../../../../shared/ui/uiKit/_common/AsyncActionState";
import NoteTemplateI18n, { NOTE_TEMPLATE_I18N_PREFIX } from "../../../_i18n/NoteTemplateI18n";
import "./note-template-actions.css";
import { NoteTemplateProjection } from "../../../../../core/projection/noteTemplate/noteTemplate";

type NoteTemplateActionsProps = {
  readonly noteTemplate: NoteTemplateProjection;
  readonly removeState?: AsyncActionState;
  readonly onRemove: ({ id }: { id: string }) => void;
  readonly onEdit: (noteTemplateId: string) => void;
  readonly onUse: (noteTemplateId: string) => void;
};
const NoteTemplateActions: FC<NoteTemplateActionsProps> = ({
  noteTemplate,
  removeState = AsyncActionState.DEFAULT,
  onRemove,
  onEdit,
  onUse,
}) => {
  const [isRemoveClicked, setIsRemoveClicked] = useState(false);
  const handleOnRemove = useCallback(() => setIsRemoveClicked(true), []);
  const handleOnCancelRemove = useCallback(() => setIsRemoveClicked(false), []);

  const handleOnRemoveClick = useCallback(() => onRemove({ id: noteTemplate.id }), [noteTemplate.id, onRemove]);
  const handleOnEditClick = useCallback(() => onEdit(noteTemplate.id), [noteTemplate.id, onEdit]);
  const handleOnUseClick = useCallback(() => onUse(noteTemplate.content), [noteTemplate.content, onUse]);

  return (
    <div aria-label="note-template-actions" className="note-template-actions" role="group">
      {isRemoveClicked ? (
        <>
          {(removeState === AsyncActionState.DEFAULT || removeState === AsyncActionState.ERROR) && (
            <Button onClick={handleOnCancelRemove}>
              <I18nMessage id={NoteTemplateI18n.CANCEL_REMOVE_TEMPLATE_BUTTON} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
            </Button>
          )}
          <ActionButton
            defaultStateTimeout={2000}
            state={removeState}
            variant={ButtonVariant.DESTRUCTIVE}
            onClick={handleOnRemoveClick}
          >
            <I18nMessage id={NoteTemplateI18n.CONFIRM_REMOVE_TEMPLATE_BUTTON} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
          </ActionButton>
        </>
      ) : (
        <>
          <Button variant={ButtonVariant.PLAIN} onClick={handleOnRemove}>
            <I18nMessage id={NoteTemplateI18n.REMOVE_TEMPLATE_BUTTON} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
          </Button>
          <Button variant={ButtonVariant.PLAIN} onClick={handleOnEditClick}>
            <I18nMessage id={NoteTemplateI18n.EDIT_TEMPLATE_BUTTON} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
          </Button>
          <Button variant={ButtonVariant.BASIC} onClick={handleOnUseClick}>
            <I18nMessage id={NoteTemplateI18n.USE_TEMPLATE_BUTTON} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
          </Button>
        </>
      )}
    </div>
  );
};

export default NoteTemplateActions;
