import { FC } from "react";
import Button from "../../atoms/button/Button";
import { IconVariant } from "../../atoms/icon/Icon";
import classNames from "classnames";
import "./back-to-top-button.css";

interface BackToTopButtonProps {
  readonly className?: string;
  readonly visible: boolean;
  readonly onClick: () => void;
}

const BackToTopButton: FC<BackToTopButtonProps> = ({ className, visible, onClick }) => (
  <Button
    aria-label="back-to-top-button"
    className={classNames("back-to-top-button", { "back-to-top-button--visible": visible }, className)}
    icon={IconVariant.ARROW_UP}
    onClick={onClick}
  />
);

export { BackToTopButton };
