import { NoteProjection } from "../../../../projection/note/note";
import { NoteByBoxIdView, ViewNoteByBoxIdResult } from "../../../../projection/note/viewNoteByBoxId";
import { HttpPostFunction } from "../../../delivery/http/httpClient";

interface HttpNoteByBoxIdView extends NoteByBoxIdView {}

interface HttpNoteByBoxIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpNoteByBoxIdViewFunction {
  (args: HttpNoteByBoxIdViewFunctionArgs): HttpNoteByBoxIdView;
}

const httpNoteByBoxIdView: HttpNoteByBoxIdViewFunction =
  ({ httpPost }) =>
  async ({ boxId, signal }) =>
    await httpPost<NoteProjection, ViewNoteByBoxIdResult>({
      endpoint: "/view-note-by-box-id",
      body: { boxId },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpNoteByBoxIdView };
