import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import {
  SearchFavouritesByPsIdResult,
  FavouritesByPsIdView,
} from "../../../projection/favourite/searchFavouritesByPsId";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { FavouriteDto, toFavouritesProjection } from "./favourite";

interface HttpFavouritesByPsIdView extends FavouritesByPsIdView {}

interface HttpFavouritesByPsIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpFavouritesByPsIdViewFunction {
  (args: HttpFavouritesByPsIdViewFunctionArgs): HttpFavouritesByPsIdView;
}

interface SearchFavouritesByPsIdResponse {
  readonly result: FavouriteDto[];
}

const httpFavouritesByPsIdView: HttpFavouritesByPsIdViewFunction =
  ({ httpPost }) =>
  async ({ psId, signal }) =>
    await httpPost<SearchFavouritesByPsIdResponse, SearchFavouritesByPsIdResult>({
      endpoint: "/search-favourites-by-ps-id",
      body: { ps_id: psId },
      signal,
      result: {
        error: null,
        success: (response) => toFavouritesProjection({ favourites: camelcaseKeys(response.result) }),
      },
    });

export { httpFavouritesByPsIdView };
