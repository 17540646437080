import { v4 as uuid } from "uuid";
import { ProcessManager } from "@lookiero/messaging.js";
import BoxPreviewSent from "./BoxPreviewSent";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../../../ui/componentLibrary/_i18n/BoxPreviewI18n";
import { createToastNotification } from "../../../../shared/notifications/domain/notification/command/createToastNotification";
import { CommandBus as MessagingCommandBus } from "@lookiero/messaging";
import { NotificationLevel } from "../../../../shared/notifications/domain/notification/model/notification";

class CreateNotificationWhenBoxPreviewSent implements ProcessManager<BoxPreviewSent> {
  private readonly messagingCommandBus!: MessagingCommandBus;

  public async process(boxPreview: BoxPreviewSent): Promise<void> {
    this.messagingCommandBus(
      createToastNotification({
        aggregateId: uuid(),
        bodyI18nKey: `${BOX_PREVIEW_I18N_PREFIX}${BoxPreviewI18n.PREVIEW_SENT_NOTIFICATION}`,
        level: NotificationLevel.SUCCESS,
      }),
    );
  }
}

export default CreateNotificationWhenBoxPreviewSent;
