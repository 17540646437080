import { I18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import ActionConfirmationModal from "../../../../shared/ui/uiKit/organisms/actionConfirmationModal/ActionConfirmationModal";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../_i18n/SelectionI18n";
import "./deselect-product-variant-confirmation-modal.css";

type DeselectProductVariantConfirmationModalProps = {
  readonly visible: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};
const DeselectProductVariantConfirmationModal: FC<DeselectProductVariantConfirmationModalProps> = ({
  visible,
  onCancel,
  onConfirm,
}) => (
  <ActionConfirmationModal
    className="deselect-product-variant-confirmation-modal"
    visible={visible}
    cancelButtonText={
      <I18nMessage
        id={SelectionI18n.DESELECT_PRODUCT_VARIANT_WITHIN_LOOK_CONFIRMATION_CANCEL}
        prefix={SELECTION_I18N_PREFIX}
      />
    }
    confirmButtonText={
      <I18nMessage
        id={SelectionI18n.DESELECT_PRODUCT_VARIANT_WITHIN_LOOK_CONFIRMATION_CONFIRM}
        prefix={SELECTION_I18N_PREFIX}
      />
    }
    title={
      <header className="deselect-product-variant-confirmation-modal__header">
        <Icon variant={IconVariant.ALERT_WARNING} />
        <I18nMessage
          id={SelectionI18n.DESELECT_PRODUCT_VARIANT_WITHIN_LOOK_CONFIRMATION_TITLE}
          prefix={SELECTION_I18N_PREFIX}
        />
      </header>
    }
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <I18nMessage
      id={SelectionI18n.DESELECT_PRODUCT_VARIANT_WITHIN_LOOK_CONFIRMATION_DESCRIPTION}
      prefix={SELECTION_I18N_PREFIX}
    />
  </ActionConfirmationModal>
);

export default DeselectProductVariantConfirmationModal;
