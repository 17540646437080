import { I18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback } from "react";
import Locale from "../../../core/domain/country/model/Locale";
import Button, { ButtonVariant } from "../../../shared/ui/uiKit/atoms/button/Button";
import NoteTemplateI18n, { NOTE_TEMPLATE_I18N_PREFIX } from "../../componentLibrary/_i18n/NoteTemplateI18n";
import { Routes } from "../../_routing/Routing";
import { generatePath, useNavigate } from "react-router-dom";

type NoteTemplateCreateProps = {
  readonly locale: Locale;
  readonly legacyBoxId: string;
  readonly disabled: boolean;
};
const NoteTemplateCreate: FC<NoteTemplateCreateProps> = ({
  locale,
  legacyBoxId,
  disabled,
}: NoteTemplateCreateProps) => {
  const navigate = useNavigate();

  const navigateToNewNoteTemplate = useCallback(() => {
    navigate(generatePath(Routes.BOX_SELECTION_NOTES_TEMPLATES_NEW, { locale, box: legacyBoxId }), { replace: true });
  }, [legacyBoxId, locale, navigate]);

  return (
    <Button disabled={disabled} type="button" variant={ButtonVariant.PRIMARY} onClick={navigateToNewNoteTemplate}>
      <I18nMessage id={NoteTemplateI18n.TEMPLATES_CREATE} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
    </Button>
  );
};

export default NoteTemplateCreate;
