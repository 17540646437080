import { memo, ReactNode, useCallback } from "react";
import { UseMeasureRect } from "react-use/lib/useMeasure";
import Measured from "../measured/Measured";
import MeasuredSize from "../measured/MeasuredSize";

type DataId = string;

type SortableGridItemProps<T> = {
  readonly index: number;
  readonly item: T;
  readonly children: (item: T, index: number) => ReactNode;
  readonly onMeasured: (id: DataId, size: MeasuredSize) => void;
};
const SortableGridItem = <T extends { readonly id: DataId }>({
  index,
  item,
  children,
  onMeasured,
}: SortableGridItemProps<T>): JSX.Element => {
  const handleOnItemMeasured = useCallback(
    ({ width, height }: UseMeasureRect) => onMeasured(item.id, { width, height }),
    [item.id, onMeasured],
  );

  return <Measured onChanged={handleOnItemMeasured}>{children(item, index)}</Measured>;
};

export default memo(SortableGridItem);
