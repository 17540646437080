import { FC, ReactNode, useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import { useFirstMountState, useKey } from "react-use";
import useCaptureAndStopEvent from "../../../../shared/ui/uiKit/_behaviors/useCaptureAndStopEvent/useCaptureAndStopEvent";
import { isVideoSrc } from "../../../_util/srcType";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import Picture from "../../../../shared/ui/uiKit/atoms/picture/Picture";
import BasicPicture from "../../../../shared/ui/uiKit/atoms/picture/BasicPicture";
import Button from "../../../../shared/ui/uiKit/atoms/button/Button";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Video from "../../../../shared/ui/uiKit/atoms/video/Video";
import "./product-variant-gallery.css";

interface ProductVariantGalleryProps {
  readonly className?: string;
  readonly media: string[];
  readonly index: number;
  readonly onChange: (itemIndex: number) => void;
  readonly isActive?: boolean;
  readonly aspectRatio?: AspectRatio;
  readonly itemWrapper?: (picture: ReactNode) => ReactNode;
  readonly itemLabel?: string;
  readonly showPictureOnFirstMount?: boolean;
  readonly keyboardControlled?: boolean;
}

const ProductVariantGallery: FC<ProductVariantGalleryProps> = ({
  className,
  media,
  index,
  onChange,
  isActive = false,
  aspectRatio = AspectRatio.R_4_5,
  itemWrapper,
  itemLabel,
  showPictureOnFirstMount = true,
  keyboardControlled,
}) => {
  const indexRef = useRef<number>(index);
  indexRef.current = index;

  const goPreviousCallback = useCallback(
    () => onChange(indexRef.current - 1 < 0 ? media.length - 1 : indexRef.current - 1),
    [media.length, onChange],
  );
  const goPrevious = useCaptureAndStopEvent(goPreviousCallback);
  const goNextCallback = useCallback(
    () => onChange(indexRef.current + 1 >= media.length ? 0 : indexRef.current + 1),
    [media.length, onChange],
  );
  const goNext = useCaptureAndStopEvent(goNextCallback);

  const handleOnArrowLeftPressed = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      keyboardControlled && goPreviousCallback();
    },
    [goPreviousCallback, keyboardControlled],
  );

  const handleOnArrowRightPressed = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      keyboardControlled && goNextCallback();
    },
    [goNextCallback, keyboardControlled],
  );
  useKey("ArrowLeft", handleOnArrowLeftPressed);
  useKey("ArrowRight", handleOnArrowRightPressed);

  const displayControls = media.length > 1;

  const isFirstMount = useFirstMountState();
  const PictureComponent = isFirstMount && !showPictureOnFirstMount ? BasicPicture : Picture;
  const src = media[index];

  const item = useMemo(
    () =>
      !isVideoSrc(src) ? (
        <PictureComponent
          aspectRatio={aspectRatio}
          className={"product-variant-gallery__picture"}
          label={itemLabel}
          src={src}
          lazy
        />
      ) : (
        <Video
          aspectRatio={aspectRatio}
          className="product-variant-gallery__video"
          label={itemLabel}
          src={src}
          autoPlay
          controls
          loop
          muted
        />
      ),
    /**
     * Removed PictureComponent from dependencies to prevent a re-render in the Shortlist
     * while loading new pages/products
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aspectRatio, itemLabel, src],
  );

  return (
    <section
      className={classNames("product-variant-gallery", { "product-variant-gallery--active": isActive }, className)}
    >
      {itemWrapper ? itemWrapper(item) : item}
      {displayControls && (
        <>
          <Button
            className="product-variant-gallery__button product-variant-gallery__button--previous"
            icon={IconVariant.MINOR_DROPDOWN}
            onClick={goPrevious}
          />
          <Button
            className="product-variant-gallery__button product-variant-gallery__button--next"
            icon={IconVariant.MINOR_DROPDOWN}
            onClick={goNext}
          />
        </>
      )}
    </section>
  );
};

export default ProductVariantGallery;
