import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { BoxPreview } from "./boxPreview";

const VIEW_BOX_PREVIEW_BY_BOX_ID = "view_box_preview_by_box_id";

interface ViewBoxPreviewByBoxIdPayload {
  readonly boxId: string;
}

interface ViewBoxPreviewByBoxId extends Query<typeof VIEW_BOX_PREVIEW_BY_BOX_ID>, ViewBoxPreviewByBoxIdPayload {}

interface ViewBoxPreviewByBoxIdFunction {
  (payload: ViewBoxPreviewByBoxIdPayload): ViewBoxPreviewByBoxId;
}

const viewBoxPreviewByBoxId: ViewBoxPreviewByBoxIdFunction = (payload) => ({
  ...query({ name: VIEW_BOX_PREVIEW_BY_BOX_ID }),
  ...payload,
});

type ViewBoxPreviewByBoxIdResult = BoxPreview | null;

interface BoxPreviewByBoxIdViewArgs extends CancelableQueryViewArgs {
  readonly boxId: string;
}

interface BoxPreviewByBoxIdView {
  (args: BoxPreviewByBoxIdViewArgs): Promise<ViewBoxPreviewByBoxIdResult>;
}

interface ViewBoxPreviewByBoxIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: BoxPreviewByBoxIdView;
}

const viewBoxPreviewByBoxIdHandler: QueryHandlerFunction<
  ViewBoxPreviewByBoxId,
  ViewBoxPreviewByBoxIdResult,
  ViewBoxPreviewByBoxIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxId }) =>
    view({ boxId, signal });

export type { BoxPreviewByBoxIdView, ViewBoxPreviewByBoxId, ViewBoxPreviewByBoxIdResult };
export { VIEW_BOX_PREVIEW_BY_BOX_ID, viewBoxPreviewByBoxId, viewBoxPreviewByBoxIdHandler };
