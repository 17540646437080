import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { viewSelectionByBoxNumber, ViewSelectionByBoxNumberResult } from "./viewSelectionByBoxNumber";
import { SelectionProductVariantProjection } from "./selection";

const SUPERVISABLE_SELECTION_PRODUCT_VARIANTS_COUNT = [5, 10];

const CONTAINS_EXACT_SUPERVISABLE_PRODUCT_VARIANTS_COUNT = "contains_exact_supervisable_product_variants_count";

interface ContainsExactSupervisableProductVariantsCountPayload {
  readonly boxNumber: string;
}

interface ContainsExactSupervisableProductVariantsCount
  extends Query<typeof CONTAINS_EXACT_SUPERVISABLE_PRODUCT_VARIANTS_COUNT>,
    ContainsExactSupervisableProductVariantsCountPayload {}

interface ContainsExactSupervisableProductVariantsCountFunction {
  (payload: ContainsExactSupervisableProductVariantsCountPayload): ContainsExactSupervisableProductVariantsCount;
}

const containsExactSupervisableProductVariantsCount: ContainsExactSupervisableProductVariantsCountFunction = (
  payload,
) => ({
  ...query({ name: CONTAINS_EXACT_SUPERVISABLE_PRODUCT_VARIANTS_COUNT }),
  ...payload,
});

type ContainsExactSupervisableProductVariantsCountResult = boolean;

interface ContainsExactSupervisableProductVariantsCountHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const containsExactSupervisableProductVariantsCountHandler: QueryHandlerFunction<
  ContainsExactSupervisableProductVariantsCount,
  ContainsExactSupervisableProductVariantsCountResult,
  ContainsExactSupervisableProductVariantsCountHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ boxNumber }) => {
    const selection: ViewSelectionByBoxNumberResult = await queryBus(viewSelectionByBoxNumber({ boxNumber }));

    if (!selection) {
      return false;
    }

    const selectionProductVariants: SelectionProductVariantProjection[] = selection.products.map((product) =>
      product.productVariants.find((productVariant) => productVariant.isSelected),
    ) as SelectionProductVariantProjection[];

    return SUPERVISABLE_SELECTION_PRODUCT_VARIANTS_COUNT.includes(selectionProductVariants.length);
  };

export type { ContainsExactSupervisableProductVariantsCount, ContainsExactSupervisableProductVariantsCountResult };
export {
  CONTAINS_EXACT_SUPERVISABLE_PRODUCT_VARIANTS_COUNT,
  containsExactSupervisableProductVariantsCount,
  containsExactSupervisableProductVariantsCountHandler,
};
