import { isActivityTimePreviewed } from "../../../../domain/activityTime/model/activityTimePreviewed";
import { HttpOrNavigatorActivityTimesSaveFunction } from "./httpOrNavigatorActivityTimes";

interface HttpActivityTimesRegisterPreviewedWatermarkFunction extends HttpOrNavigatorActivityTimesSaveFunction {}

const httpActivityTimesRegisterPreviewedWatermark: HttpActivityTimesRegisterPreviewedWatermarkFunction =
  ({ httpPost }) =>
  async ({ boxId, psId, watermark, domainEvents }) => {
    const activityTimePreviewed = domainEvents.find(isActivityTimePreviewed);

    if (!activityTimePreviewed) {
      return;
    }

    await httpPost({
      endpoint: "/register-previewed-watermark",
      body: { ps_id: psId, box_id: boxId, watermark },
    });
  };

export { httpActivityTimesRegisterPreviewedWatermark };
