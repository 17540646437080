import { RefObject, useEffect, useState } from "react";

interface UseBoundingClientRectFunctionArgs {
  readonly ref: RefObject<HTMLElement>;
}
interface UseBoundingClientRectFunction {
  (args: UseBoundingClientRectFunctionArgs): DOMRect | undefined;
}

const useBoundingClientRect: UseBoundingClientRectFunction = ({ ref }) => {
  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    const handleWindowResize = () => {
      if (ref.current) {
        setRect(ref.current.getBoundingClientRect());
      }
    };

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [ref, setRect]);

  return rect;
};

export { useBoundingClientRect };
