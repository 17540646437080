import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const NOTE_WRITTEN = "note_written";

interface NoteWrittenPayload {
  readonly aggregateId: string;
}

interface NoteWritten extends DomainEvent<typeof NOTE_WRITTEN> {}

interface NoteWrittenFunction {
  (payload: NoteWrittenPayload): NoteWritten;
}

const noteWritten: NoteWrittenFunction = ({ aggregateId }) => domainEvent({ aggregateId, name: NOTE_WRITTEN });

const isNoteWritten = (event: DomainEvent<MessageName>): event is NoteWritten => event.name === NOTE_WRITTEN;

export type { NoteWritten };
export { NOTE_WRITTEN, noteWritten, isNoteWritten };
