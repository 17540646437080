import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const SELECTION_ORDER_SORTED = "selection_order_sorted";

interface SelectionOrderSortedPayload {
  readonly aggregateId: string;
}

interface SelectionOrderSorted extends DomainEvent<typeof SELECTION_ORDER_SORTED> {}

interface SelectionOrderSortedFunction {
  (payload: SelectionOrderSortedPayload): SelectionOrderSorted;
}

const selectionOrderSorted: SelectionOrderSortedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: SELECTION_ORDER_SORTED });

const isSelectionOrderSorted = (event: DomainEvent<MessageName>): event is SelectionOrderSorted =>
  event.name === SELECTION_ORDER_SORTED;

export type { SelectionOrderSorted };
export { SELECTION_ORDER_SORTED, selectionOrderSorted, isSelectionOrderSorted };
