import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { FamilyProjection } from "./family";

const LIST_FAMILIES = "list_families";

interface ListFamilies extends Query<typeof LIST_FAMILIES> {}

interface ListFamiliesFunction {
  (): ListFamilies;
}

const listFamilies: ListFamiliesFunction = () => query({ name: LIST_FAMILIES });

type ListFamiliesResult = FamilyProjection[] | null;

interface FamiliesViewArgs extends CancelableQueryViewArgs {}

interface FamiliesView {
  (args: FamiliesViewArgs): Promise<ListFamiliesResult>;
}

interface ListFamiliesHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: FamiliesView;
}

const listFamiliesHandler: QueryHandlerFunction<ListFamilies, ListFamiliesResult, ListFamiliesHandlerFunctionArgs> =
  ({ view, signal }) =>
  async () =>
    view({ signal });

export type { ListFamilies, FamiliesView, ListFamiliesResult };
export { LIST_FAMILIES, listFamilies, listFamiliesHandler };
