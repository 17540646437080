import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import {
  ShortlistFilters,
  countShortlistProductsByShortlistFilters,
} from "../../../../projection/shortlist/countShortlistProductsByShortlistFilters";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isProductMarkedAsFavourite } from "../../../../domain/favourite/model/productMarkedAsFavourite";
import { isProductUnmarkedAsFavourite } from "../../../../domain/favourite/model/productUnmarkedAsFavourite";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { isPersonalShopperFilteringSet } from "../../../../domain/personalShopperFiltering/model/personalShopperFilteringSet";
import { FilteringValue } from "../../../../projection/filtering/filtering";

interface UseCountShortlistProductsByShortlistFiltersFunctionArgs {
  readonly psId: string | undefined;
  readonly boxNumber: string;
  readonly search?: string;
  readonly favourites?: boolean;
  readonly positives: ShortlistFilters;
  readonly negatives: FilteringValue[];
  readonly itemCountWhileQuerying: number;
}

interface UseCountShortlistProductsByShortlistFiltersFunction {
  (args: UseCountShortlistProductsByShortlistFiltersFunctionArgs): UseQueryFunctionResult<number>;
}

const useCountShortlistProductsByShortlistFilters: UseCountShortlistProductsByShortlistFiltersFunction = ({
  psId,
  boxNumber,
  search,
  favourites,
  positives,
  negatives,
  itemCountWhileQuerying,
}) => {
  const queryId = [
    "CountShortlistProductsByShortlistFilters",
    psId,
    boxNumber,
    search,
    favourites,
    positives,
    negatives,
  ];

  const [count, status] = useQuery<number, Error>({
    id: queryId,
    invalidationRefetchType: "active",
    query: countShortlistProductsByShortlistFilters({
      criteria: {
        psId: psId as string,
        boxNumber,
        shortlistFilters: positives,
        negativeFilters: negatives.map(({ id }) => ({ id })),
        search,
        favourites,
      },
    }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: (event: DomainEvent<MessageName>) =>
      isProductMarkedAsFavourite(event) || isProductUnmarkedAsFavourite(event) || isPersonalShopperFilteringSet(event),
    options: {
      staleTime: favourites ? 0 : 5 * 60 * 1000,
      enabled: psId !== undefined,
      refetchOnWindowFocus: false,
    },
  });

  return [count ?? itemCountWhileQuerying, status];
};

export { useCountShortlistProductsByShortlistFilters };
