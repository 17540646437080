import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { SubmitStockSurvey } from "../command/submitStockSurvey";
import { stockSurveySubmitted } from "./stockSurveySubmitted";

interface StockSurvey extends AggregateRoot {
  readonly boxId: string;
}

const submitStockSurveyHandler: CommandHandlerFunction<SubmitStockSurvey, StockSurvey> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { boxId } = command;

    return {
      ...aggregateRoot,
      boxId,
      domainEvents: [stockSurveySubmitted({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

export type { StockSurvey };
export { submitStockSurveyHandler };
