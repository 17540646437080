import { FC, useMemo } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import ShortlistFiltersI18n, {
  SHORTLIST_FILTERS_I18N_PREFIX,
} from "../../../componentLibrary/_i18n/ShortlistFiltersI18n";
import Input from "../../../../shared/ui/uiKit/molecules/input/Input";
import { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import ShortlistFilterItemRoot from "../../../componentLibrary/organisms/shortlistFilters/shortlistFilterItem/components/shortlistFilterItemRoot/ShortlistFilterItemRoot";
import ShortlistFilterHeader from "../../../componentLibrary/organisms/shortlistFilters/shortlistFilterItem/components/shortlistFilterHeader/ShortlistFilterHeader";
import useSyncLocalValue from "../../_behaviors/useSyncLocalValue";
import { ShortlistFilterProjection } from "../../../../core/projection/shortlistFilter/shortlistFilter";
import "./shortlist-filter-search.css";

type ShortlistFilterSearchProps = {
  readonly value?: string;
  readonly onChange: (value: string) => void;
};
const ShortlistFilterSearch: FC<ShortlistFilterSearchProps> = ({
  value = "",
  onChange,
}: ShortlistFilterSearchProps) => {
  const [text, setText] = useSyncLocalValue(300, value, onChange);
  const filter = useMemo(() => ({ collapsed: !Boolean(value) } as ShortlistFilterProjection), [value]);
  const placeholder = useI18nMessage({
    id: ShortlistFiltersI18n.MAIN_SEARCH_PLACEHOLDER,
    prefix: SHORTLIST_FILTERS_I18N_PREFIX,
  });
  const headerFilter: ShortlistFilterProjection = useMemo(
    () =>
      ({
        translationKey: `${SHORTLIST_FILTERS_I18N_PREFIX}${ShortlistFiltersI18n.MAIN_SEARCH}`,
      } as ShortlistFilterProjection),
    [],
  );

  const ShortlistFilterItemRootComponent = useMemo(
    () =>
      ShortlistFilterItemRoot({
        useShortlistFiltering: () => ({ state: [], onChange: () => void 0 }),
        testId: "shortlist-filter-search",
      }),
    [],
  );

  return (
    <ShortlistFilterItemRootComponent
      className="shortlist-filter-search"
      filter={filter}
      item={<ShortlistFilterHeader filter={headerFilter} level={0} />}
      level={0}
    >
      <Input
        className="shortlist-filter-search"
        icon={IconVariant.SEARCH}
        placeholder={placeholder}
        value={text}
        onChange={setText}
      />
    </ShortlistFilterItemRootComponent>
  );
};

export default ShortlistFilterSearch;
