import { FC } from "react";
import Picture from "../../../../../shared/ui/uiKit/atoms/picture/Picture";
import AspectRatio from "../../../../../shared/ui/uiKit/_common/AspectRatio";
import "./look-and-like-feedback-item.css";

const LookAndLikeFeedbackItemSkeleton: FC = () => (
  <div
    aria-label="look-and-like-feedback-item-skeleton"
    className="look-and-like-feedback-item look-and-like-feedback-item--skeleton"
    role="listitem"
  >
    <Picture aspectRatio={AspectRatio.R_4_5} />
  </div>
);

export default LookAndLikeFeedbackItemSkeleton;
