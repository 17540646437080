enum BoxStatus {
  PLACED = "PLACED",
  PLANNED = "PLANNED",
  READY = "READY",
  PREVIEWING = "PREVIEWING",
  SUPERVISING = "SUPERVISING",
  CLOSED = "CLOSED",
  BLOCKED = "BLOCKED",
  CANCELLED = "CANCELLED",
  FINISHED = "FINISHED",
}

export default BoxStatus;
