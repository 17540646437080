import { SelectionOrderByBoxNumberView } from "../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";
import { SelectionOrderDto } from "../../persistence/selectionOrderDto";
import { Storage } from "../../persistence/storage";

interface StorageSelectionOrderByBoxNumberView extends SelectionOrderByBoxNumberView {}

interface StorageSelectionOrderByBoxNumberViewFunctionArgs {
  readonly storage: Storage<SelectionOrderDto>;
}

interface StorageSelectionOrderByBoxNumberViewFunction {
  (args: StorageSelectionOrderByBoxNumberViewFunctionArgs): StorageSelectionOrderByBoxNumberView;
}

const storageSelectionOrderByBoxNumberView: StorageSelectionOrderByBoxNumberViewFunction =
  ({ storage: { read } }) =>
  async ({ boxNumber }) => {
    try {
      return await read(boxNumber);
    } catch (ignored) {
      throw new Error("Could not fetch selectionOrder");
    }
  };

export { storageSelectionOrderByBoxNumberView };
