import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { viewBoxByBoxNumber, ViewBoxByBoxNumberResult } from "./viewBoxByBoxNumber";
import nonClosableBoxStatuses from "../../domain/box/model/NonClosableBoxStatuses";
import { containsBlockingAlertsByBoxNumber } from "../alert/containsBlockingAlertsByBoxNumber";

const CAN_THE_BOX_BE_CLOSED = "can_the_box_be_closed";

interface CanTheBoxBeClosedPayload {
  readonly boxNumber: string;
}

interface CanTheBoxBeClosed extends Query<typeof CAN_THE_BOX_BE_CLOSED>, CanTheBoxBeClosedPayload {}

interface CanTheBoxBeClosedFunction {
  (payload: CanTheBoxBeClosedPayload): CanTheBoxBeClosed;
}

const canTheBoxBeClosed: CanTheBoxBeClosedFunction = (payload) => ({
  ...query({ name: CAN_THE_BOX_BE_CLOSED }),
  ...payload,
});

type CanTheBoxBeClosedResult = boolean;

interface CanTheBoxBeClosedHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const canTheBoxBeClosedHandler: QueryHandlerFunction<
  CanTheBoxBeClosed,
  CanTheBoxBeClosedResult,
  CanTheBoxBeClosedHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ boxNumber }) => {
    const containsBlockingAlerts = await queryBus(containsBlockingAlertsByBoxNumber({ boxNumber }));

    if (containsBlockingAlerts) {
      return false;
    }

    const box: ViewBoxByBoxNumberResult = await queryBus(viewBoxByBoxNumber({ boxNumber }));

    if (!box) {
      return false;
    }

    const isBoxStatusValid = !nonClosableBoxStatuses.includes(box.status);

    if (!isBoxStatusValid) {
      return false;
    }

    return true;
  };

export type { CanTheBoxBeClosed, CanTheBoxBeClosedResult };
export { CAN_THE_BOX_BE_CLOSED, canTheBoxBeClosed, canTheBoxBeClosedHandler };
