import { FC, useCallback, useMemo } from "react";
import Size from "../../../../core/projection/size/model/Size";
import ButtonOption, { ButtonOptionState } from "../../../../shared/ui/uiKit/atoms/buttonOption/ButtonOption";
import SizeSelectorProps from "./SizeSelectorProps";

type BasicSizeSelectorItemProps = {
  readonly size: Size;
  readonly selectedSize: Size;
  readonly compatibleSizes: Size[];
  readonly onChange: (size: Size) => void;
};
const BasicSizeSelectorItem: FC<BasicSizeSelectorItemProps> = ({
  size,
  selectedSize,
  compatibleSizes,
  onChange,
}: BasicSizeSelectorItemProps) => {
  const state = useMemo(
    () =>
      size.lookiero.size === selectedSize.lookiero.size
        ? ButtonOptionState.SELECTED
        : compatibleSizes.some((compatibleSize) => compatibleSize.lookiero.size === size.lookiero.size)
        ? ButtonOptionState.DEFAULT
        : ButtonOptionState.VISUALLY_DISABLED,
    [compatibleSizes, selectedSize.lookiero.size, size.lookiero.size],
  );
  const handleOnChange = useCallback(() => {
    if (size.lookiero.size === selectedSize.lookiero.size) {
      return;
    }

    onChange(size);
  }, [onChange, selectedSize.lookiero.size, size]);

  return <ButtonOption label={size.lookiero.size} state={state} onChange={handleOnChange} />;
};

const BasicSizeSelector: FC<SizeSelectorProps> = ({
  sizes,
  selectedSize,
  compatibleSizes,
  onChange,
}: SizeSelectorProps) => (
  <>
    {sizes.map((size) => (
      <BasicSizeSelectorItem
        key={size.lookiero.size}
        compatibleSizes={compatibleSizes}
        selectedSize={selectedSize}
        size={size}
        onChange={onChange}
      />
    ))}
  </>
);

export default BasicSizeSelector;
