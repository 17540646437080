import { HttpFavouritesSaveFunction } from "./httpFavourites";
import { isProductMarkedAsFavourite } from "../../../../domain/favourite/model/productMarkedAsFavourite";

interface HttpProductMarkedAsFavouriteFunction extends HttpFavouritesSaveFunction {}

const httpProductMarkedAsFavourite: HttpProductMarkedAsFavouriteFunction =
  ({ httpPost }) =>
  async ({ aggregateId, psId, productId, domainEvents }) => {
    const personalShopperFilteringSet = domainEvents.find(isProductMarkedAsFavourite);

    if (!personalShopperFilteringSet) {
      return;
    }

    await httpPost({
      endpoint: "/mark-product-as-favourite",
      body: {
        favourite_id: aggregateId,
        ps_id: psId,
        product_id: productId,
      },
    });
  };

export { httpProductMarkedAsFavourite };
