import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { NoteProjection } from "./note";

const VIEW_NOTE_BY_BOX_ID = "view_note_by_box_id";

interface ViewNoteByBoxIdPayload {
  readonly boxId: string;
}

interface ViewNoteByBoxId extends Query<typeof VIEW_NOTE_BY_BOX_ID>, ViewNoteByBoxIdPayload {}

interface ViewNoteByBoxIdFunction {
  (payload: ViewNoteByBoxIdPayload): ViewNoteByBoxId;
}

const viewNoteByBoxId: ViewNoteByBoxIdFunction = (payload) => ({
  ...query({ name: VIEW_NOTE_BY_BOX_ID }),
  ...payload,
});

type ViewNoteByBoxIdResult = NoteProjection | null;

interface NoteByBoxIdViewArgs extends CancelableQueryViewArgs {
  readonly boxId: string;
}

interface NoteByBoxIdView {
  (args: NoteByBoxIdViewArgs): Promise<ViewNoteByBoxIdResult>;
}

interface ViewNoteByBoxIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: NoteByBoxIdView;
}

const viewNoteByBoxIdHandler: QueryHandlerFunction<
  ViewNoteByBoxId,
  ViewNoteByBoxIdResult,
  ViewNoteByBoxIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxId }) =>
    view({ boxId, signal });

export type { NoteByBoxIdView, ViewNoteByBoxId, ViewNoteByBoxIdResult };
export { VIEW_NOTE_BY_BOX_ID, viewNoteByBoxId, viewNoteByBoxIdHandler };
