import { command, Command } from "@lookiero/messaging";

const REGISTER_PREVIEWED_WATERMARK = "register_previewed_watermark";

interface RegisterPreviewedWatermarkPayload {
  readonly psId: string;
  readonly boxId: string;
  readonly watermark: number; // timestamp
}

interface RegisterPreviewedWatermark
  extends Command<typeof REGISTER_PREVIEWED_WATERMARK>,
    RegisterPreviewedWatermarkPayload {}

interface RegisterPreviewedWatermarkFunction {
  (payload: RegisterPreviewedWatermarkPayload): RegisterPreviewedWatermark;
}

const registerPreviewedWatermark: RegisterPreviewedWatermarkFunction = ({ ...payload }) => ({
  ...command({ name: REGISTER_PREVIEWED_WATERMARK }),
  ...payload,
});

export type { RegisterPreviewedWatermark };
export { REGISTER_PREVIEWED_WATERMARK, registerPreviewedWatermark };
