import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { removeNotification } from "../../../../domain/notification/command/removeNotification";
import { Logger } from "../../../../../logging/Logger";

interface RemoveNotificationFunctionArgs {
  readonly aggregateId: string;
}

interface RemoveNotificationFunction {
  (args: RemoveNotificationFunctionArgs): Promise<void>;
}

interface UseRemoveNotificationFunctionArgs {
  readonly contextId: string;
  readonly logger: Logger;
}

interface UseRemoveNotificationFunction {
  (args: UseRemoveNotificationFunctionArgs): [remove: RemoveNotificationFunction, status: CommandStatus];
}

const useRemoveNotification: UseRemoveNotificationFunction = ({ contextId, logger }) => {
  const [commandBus, status] = useCommand({ contextId });

  const remove: RemoveNotificationFunction = useCallback(
    async ({ aggregateId }) => {
      try {
        await commandBus(removeNotification({ aggregateId }));
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [commandBus, logger],
  );

  return [remove, status];
};

export { useRemoveNotification };
