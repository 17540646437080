import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import { ProductByIdView, ViewProductByIdResult } from "../../../projection/product/viewProductById";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { ProductDetailDto, toProductDetailProjection } from "./productDetail";

interface HttpProductByIdView extends ProductByIdView {}

interface HttpProductByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpProductByIdViewFunction {
  (args: HttpProductByIdViewFunctionArgs): HttpProductByIdView;
}

interface ViewProductByIdResponse {
  readonly result: ProductDetailDto;
}

const httpProductByIdView: HttpProductByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, boxId, signal }) =>
    await httpPost<ViewProductByIdResponse, ViewProductByIdResult>({
      endpoint: "/view-product-by-id",
      body: { product_id: id, box_id: boxId },
      signal,
      result: {
        error: null,
        success: (response) => toProductDetailProjection({ productDetail: camelcaseKeys(response.result) }),
      },
    });

export { httpProductByIdView };
