import { FC, ReactNode } from "react";
import { BoxTabs } from "../boxTabs/BoxTabs";
import { BoxHeader } from "../boxHeader/BoxHeader";
import "./unified-box.css";

interface UnifiedBoxProps {
  readonly children: ReactNode;
}

const UnifiedBox: FC<UnifiedBoxProps> = ({ children }) => {
  return (
    <div aria-label="unified-box" className="unified-box">
      <BoxHeader />
      <div className="unified-box__tabs">
        <BoxTabs>{children}</BoxTabs>
      </div>
    </div>
  );
};

export { UnifiedBox };
