import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { viewActivityTimeByPsIdAndBoxId } from "../../../../projection/activityTime/viewActivityTimeByPsIdAndBoxId";
import { ActivityTime } from "../../../../projection/activityTime/activityTime";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isActivityTimeRegistered } from "../../../../domain/activityTime/model/activityTimeRegistered";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isActivityTimeRegistered(event);

interface UseViewActivityTimeByPsIdAndBoxIdFunctionArgs {
  readonly psId?: string;
  readonly boxId?: string;
}

interface UseViewActivityTimeByPsIdAndBoxIdFunction {
  (args: UseViewActivityTimeByPsIdAndBoxIdFunctionArgs): UseQueryFunctionResult<ActivityTime>;
}

const useViewActivityTimeByPsIdAndBoxId: UseViewActivityTimeByPsIdAndBoxIdFunction = ({ psId, boxId }) =>
  useQuery({
    query: viewActivityTimeByPsIdAndBoxId({ psId: psId as string, boxId: boxId as string }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: "always", // This is mandatory for the timer to properly work
      enabled: psId !== undefined && boxId !== undefined,
    },
  });

export { useViewActivityTimeByPsIdAndBoxId };
