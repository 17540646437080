import { Command } from "@lookiero/messaging.js";
import Look from "../../model/Look";

type SendBoxPreviewParameters = {
  readonly boxId: string;
  readonly boxPreviewId: string;
  readonly looks: Look[] | undefined;
  readonly selectionProductVariantIds: string[];
  readonly selectionId: string;
};

class SendBoxPreview extends Command {
  public readonly boxId: string;
  public readonly boxPreviewId: string;
  public readonly looks: Look[] | undefined;
  public readonly selectionProductVariantIds: string[];
  public readonly selectionId: string;

  public constructor({
    boxPreviewId,
    boxId,
    selectionProductVariantIds,
    looks,
    selectionId,
  }: SendBoxPreviewParameters) {
    super();
    this.boxId = boxId;
    this.boxPreviewId = boxPreviewId;
    this.selectionProductVariantIds = selectionProductVariantIds;
    this.looks = looks;
    this.selectionId = selectionId;
  }

  protected messageName(): string {
    return "SendBoxPreview";
  }
}

export default SendBoxPreview;
