import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { closeBox } from "../../../../domain/box/command/closeBox";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { Logger } from "../../../../../shared/logging/Logger";

interface CloseBoxFunction {
  (): Promise<void> | undefined;
}

interface UseCloseBoxFunctionArgs {
  readonly boxId: string;
  readonly boxNumber: string;
  readonly logger: Logger;
}

type UseCloseBoxFunctionResult = [closeBox: CloseBoxFunction, closeBoxStatus: CommandStatus];

interface UseCloseBoxFunction {
  (args: UseCloseBoxFunctionArgs): UseCloseBoxFunctionResult;
}

const useCloseBox: UseCloseBoxFunction = ({ boxId, boxNumber, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const close: CloseBoxFunction = useCallback(async () => {
    try {
      await commandBus(closeBox({ aggregateId: boxId, boxNumber }));
    } catch (error) {
      logger.captureException(error as Error);
    }
  }, [boxId, boxNumber, commandBus, logger]);

  return [close, status];
};

export { useCloseBox };
