import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const PRODUCT_VARIANT_DESELECTED = "product_variant_deselected";

interface ProductVariantDeselectedPayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
  readonly productVariantId: string;
  readonly productVariantIds: string[];
}

interface ProductVariantDeselected
  extends DomainEvent<typeof PRODUCT_VARIANT_DESELECTED>,
    ProductVariantDeselectedPayload {}

interface ProductVariantDeselectedFunction {
  (payload: ProductVariantDeselectedPayload): ProductVariantDeselected;
}

const productVariantDeselected: ProductVariantDeselectedFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: PRODUCT_VARIANT_DESELECTED }),
  ...payload,
});

const isProductVariantDeselected = (event: DomainEvent<MessageName>): event is ProductVariantDeselected =>
  event.name === PRODUCT_VARIANT_DESELECTED;

export type { ProductVariantDeselected };
export { PRODUCT_VARIANT_DESELECTED, productVariantDeselected, isProductVariantDeselected };
