import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { UpdateAnnotation } from "../command/updateAnnotation";
import { annotationUpdated } from "./annotationUpdated";

interface Annotation extends AggregateRoot {
  readonly customerId: string;
  readonly positive: string;
  readonly negative: string;
}

const updateAnnotationHandler: CommandHandlerFunction<UpdateAnnotation, Annotation> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { customerId, positive, negative } = command;

    return {
      ...aggregateRoot,
      customerId,
      positive,
      negative,
      domainEvents: [annotationUpdated({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

export type { Annotation };
export { updateAnnotationHandler };
