import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import {
  ListShortlistFiltersByBoxNumberResult,
  ShortlistFiltersByBoxNumberView,
} from "../../../projection/shortlistFilter/listShortlistFiltersByBoxNumber";
import { ShortlistFilterProjection } from "../../../projection/shortlistFilter/shortlistFilter";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpShortlistFiltersByBoxNumberView extends ShortlistFiltersByBoxNumberView {}

interface HttpShortlistFiltersByBoxNumberViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpShortlistFiltersByBoxNumberViewFunction {
  (args: HttpShortlistFiltersByBoxNumberViewFunctionArgs): HttpShortlistFiltersByBoxNumberView;
}

interface ListShortlistFiltersByBoxNumberResponse {
  readonly result: ShortlistFilterProjection[];
}

const httpShortlistFiltersByBoxNumberView: HttpShortlistFiltersByBoxNumberViewFunction =
  ({ httpPost }) =>
  async ({ boxNumber, signal }) =>
    await httpPost<ListShortlistFiltersByBoxNumberResponse, ListShortlistFiltersByBoxNumberResult>({
      endpoint: "/list-shortlist-filters-by-box-number",
      body: { boxNumber },
      signal,
      result: {
        error: null,
        success: (response) => camelcaseKeys(response.result),
      },
    });

export { httpShortlistFiltersByBoxNumberView };
