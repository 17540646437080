import { FC } from "react";
import Loader from "../../../../shared/ui/uiKit/atoms/loader/Loader";
import "./root-loader.css";

const RootLoader: FC = () => (
  <div aria-busy="true" aria-label="loader" className="root-loader" role="alert">
    <Loader />
  </div>
);

export default RootLoader;
