import { command, Command } from "@lookiero/messaging";

const START_AUTOMATIC_SELECTION = "start_automatic_selection";

interface StartAutomaticSelectionPayload {
  readonly aggregateId: string;
  readonly boxId: string;
}

interface StartAutomaticSelection extends Command<typeof START_AUTOMATIC_SELECTION>, StartAutomaticSelectionPayload {}

interface StartAutomaticSelectionFunction {
  (payload: StartAutomaticSelectionPayload): StartAutomaticSelection;
}

const startAutomaticSelection: StartAutomaticSelectionFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: START_AUTOMATIC_SELECTION }),
  ...payload,
});

export type { StartAutomaticSelection };
export { START_AUTOMATIC_SELECTION, startAutomaticSelection };
