import Country from "../../../../domain/country/model/Country";
import PersonalShopperRole from "../../../../domain/personalShopper/model/PersonalShopperRole";
import { PersonalShopperProjection } from "../../../../projection/personalShopper/personalShopper";
import { PersonalShopperView } from "../../../../projection/personalShopper/viewPersonalShopper";
import { ReadFunction } from "../../../persistence/storage";

const STORAGE_KEY = "authtoken";

interface TokenPersonalShopper {
  readonly uuid: string;
  readonly psId: number;
  readonly roles: string;
  readonly market: Country;
}

type Decode = (token: string) => TokenPersonalShopper;

interface ToPersonalShopperProjectionFunctionArgs {
  readonly decode: Decode;
  readonly token: string;
}
interface ToPersonalShopperProjectionFunction {
  (args: ToPersonalShopperProjectionFunctionArgs): PersonalShopperProjection;
}
const toPersonalShopperProjection: ToPersonalShopperProjectionFunction = ({ decode, token }) => {
  const decodedStoragePersonalShopper = decode(token);

  return {
    id: decodedStoragePersonalShopper.uuid,
    psNumber: decodedStoragePersonalShopper.psId,
    roles: decodedStoragePersonalShopper.roles.split(",").map((role) => role.trim()) as PersonalShopperRole[],
    market: decodedStoragePersonalShopper.market,
  };
};

interface CookieStoragePersonalShopperView extends PersonalShopperView {}

interface CookieStoragePersonalShopperViewFunctionArgs {
  readonly read: ReadFunction<string>;
  readonly decode: Decode;
}
interface CookieStoragePersonalShopperViewFunction {
  (args: CookieStoragePersonalShopperViewFunctionArgs): CookieStoragePersonalShopperView;
}
const cookieStoragePersonalShopperView: CookieStoragePersonalShopperViewFunction =
  ({ read, decode }) =>
  async () => {
    try {
      const storagePersonalShopper = await read(STORAGE_KEY);

      return storagePersonalShopper ? toPersonalShopperProjection({ decode, token: storagePersonalShopper }) : null;
    } catch (ignored) {
      throw new Error("Could not get the cookie");
    }
  };

export { cookieStoragePersonalShopperView };
