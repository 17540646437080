import BoxPreview from "../../../../domain/boxPreview/model/BoxPreview";
import Look from "../../../../domain/boxPreview/model/Look";

type BoxPreviewResponse = {
  readonly id: string;
  readonly boxId: string;
  readonly looks: Look[];
};

const toBoxPreview = ({ id, boxId, looks }: BoxPreviewResponse): BoxPreview =>
  new BoxPreview({ boxPreviewId: id, boxId, looks });

export { toBoxPreview };
