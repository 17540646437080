import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { NoteTemplateProjection } from "./noteTemplate";

const VIEW_NOTE_TEMPLATE_BY_ID = "view_note_template_by_id";

interface ViewNoteTemplateByIdPayload {
  readonly id: string;
}

interface ViewNoteTemplateById extends Query<typeof VIEW_NOTE_TEMPLATE_BY_ID>, ViewNoteTemplateByIdPayload {}

interface ViewNoteTemplateByIdFunction {
  (payload: ViewNoteTemplateByIdPayload): ViewNoteTemplateById;
}

const viewNoteTemplateById: ViewNoteTemplateByIdFunction = (payload) => ({
  ...query({ name: VIEW_NOTE_TEMPLATE_BY_ID }),
  ...payload,
});

type ViewNoteTemplateByIdResult = NoteTemplateProjection | null;

interface NoteTemplateByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface NoteTemplateByIdView {
  (args: NoteTemplateByIdViewArgs): Promise<ViewNoteTemplateByIdResult>;
}

interface ViewNoteTemplateByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: NoteTemplateByIdView;
}

const viewNoteTemplateByIdHandler: QueryHandlerFunction<
  ViewNoteTemplateById,
  ViewNoteTemplateByIdResult,
  ViewNoteTemplateByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type { ViewNoteTemplateById, NoteTemplateByIdView, ViewNoteTemplateByIdResult };
export { VIEW_NOTE_TEMPLATE_BY_ID, viewNoteTemplateById, viewNoteTemplateByIdHandler };
