import { useCallback, ChangeEvent, FC, KeyboardEvent } from "react";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import Error from "../../atoms/error/Error";
import "./textarea.css";

interface TextAreaProps {
  readonly placeholder: string;
  readonly minRows?: number;
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly onBlur?: () => void;
  readonly error?: string;
}
const TextArea: FC<TextAreaProps> = ({ placeholder, minRows = 4, value, onChange, onBlur, error }: TextAreaProps) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.currentTarget.value),
    [onChange],
  );
  const handleOnKeyDown = useCallback((event: KeyboardEvent<HTMLTextAreaElement>) => event.stopPropagation(), []);

  return (
    <div className={classNames("textarea", { "textarea--error": Boolean(error) })}>
      <div className="textarea__container">
        <TextareaAutosize
          aria-label="textarea-autosize"
          className="textarea__text"
          minRows={minRows}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
        />
        <span className="textarea__indicator" />
      </div>
      <Error error={error} />
    </div>
  );
};

export default TextArea;
