import {
  ListPersonalShopperTeamsResult,
  PersonalShopperTeamsView,
} from "../../../projection/personalShopperTeam/listPersonalShopperTeams";
import { PersonalShopperTeamProjection } from "../../../projection/personalShopperTeam/personalShopperTeam";
import { HttpGetFunction } from "../../delivery/http/httpClient";

interface HttpPersonalShopperTeamsView extends PersonalShopperTeamsView {}

interface HttpPersonalShopperTeamsViewFunctionArgs {
  readonly httpGet: HttpGetFunction;
}

interface HttpPersonalShopperTeamsViewFunction {
  (args: HttpPersonalShopperTeamsViewFunctionArgs): HttpPersonalShopperTeamsView;
}

interface ListPersonalShopperTeamsResponse {
  readonly result: PersonalShopperTeamProjection[];
}

const httpPersonalShopperTeamsView: HttpPersonalShopperTeamsViewFunction =
  ({ httpGet }) =>
  async ({ signal }) =>
    await httpGet<ListPersonalShopperTeamsResponse, ListPersonalShopperTeamsResult>({
      endpoint: "/list-ps-teams",
      signal,
      result: {
        error: [],
        success: (response) => response.result,
      },
    });

export { httpPersonalShopperTeamsView };
