import { FC, ReactNode, useEffect, useRef } from "react";
import { useMeasure } from "react-use";
import { UseMeasureRect } from "react-use/lib/useMeasure";

type MeasuredProps = {
  readonly className?: string;
  readonly children: ReactNode;
  readonly onChanged: (rect: UseMeasureRect) => void;
};
const Measured: FC<MeasuredProps> = ({ className, children, onChanged }) => {
  const [ref, rect] = useMeasure<HTMLDivElement>();

  const onChangedRef = useRef(onChanged);
  onChangedRef.current = onChanged;

  useEffect(() => {
    if (rect.width === 0 && rect.height === 0) {
      return;
    }

    onChangedRef.current(rect);
  }, [rect]);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};

export default Measured;
