import { Locale } from "@lookiero/i18n";
import { I18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback } from "react";
import { PlannedBox as PlannedBoxProjection } from "../../../../core/projection/plannedBox/plannedBox";
import { PlanningI18n, PLANNING_I18N_PREFIX } from "../../../componentLibrary/_i18n/PlanningI18n";
import Text from "../../../../shared/ui/uiKit/atoms/text/Text";
import "./planned-box.css";

interface PlannedBoxProps {
  readonly plannedBox: PlannedBoxProjection;
  readonly locale: Locale;
  readonly onNavigateToBox: (boxNumber: string) => void;
}

const PlannedBox: FC<PlannedBoxProps> = ({ plannedBox, locale, onNavigateToBox }) => {
  const handleOnCustomerClick = useCallback(
    () => onNavigateToBox(plannedBox.boxNumber),
    [onNavigateToBox, plannedBox.boxNumber],
  );

  return (
    <>
      <td className="planned-box__col">
        <Text tag="div">{plannedBox.boxNumber}</Text>
      </td>
      <td className="planned-box__col">
        <Text tag="div" ellipsis>
          {plannedBox.psNumber || <I18nMessage id={PlanningI18n.NOT_ASSIGNED_PS} prefix={PLANNING_I18N_PREFIX} />}
        </Text>
      </td>
      <td className="planned-box__col planned-box__col--customer" onClick={handleOnCustomerClick}>
        <Text tag="div" ellipsis>
          {plannedBox.customer.fullName}
        </Text>
      </td>
      <td className="planned-box__col">
        <Text tag="div">{plannedBox.country}</Text>
      </td>
      <td className="planned-box__col">
        <Text tag="div">{plannedBox.plannedFor.toLocaleDateString(locale)}</Text>
      </td>
      <td className="planned-box__col">
        <Text tag="div">{plannedBox.statusLabel}</Text>
      </td>
      <td className="planned-box__col">
        <Text className="planned-box__box-count" tag="div">
          {plannedBox.customer.boxCount}
        </Text>
      </td>
    </>
  );
};

export { PlannedBox };
