import { ListFunction, ReadFunction, RemoveFunction, Storage, WriteFunction } from "./storage";

interface LocalStorageStorageFunction {
  <T>(): Storage<T>;
}

const localStorageStorage: LocalStorageStorageFunction = <T>() => {
  const read: ReadFunction<T> = async (key) => {
    const value = await localStorage.getItem(key);

    return value ? JSON.parse(value) : null;
  };

  const list: ListFunction<T> = async () => {
    const value = await localStorage.getAllKeys();

    return value as T[];
  };

  const write: WriteFunction<T> = async (key, value) => localStorage.setItem(key, JSON.stringify(value));

  const remove: RemoveFunction = async (key) => localStorage.removeItem(key);

  return { read, list, write, remove };
};

export type { LocalStorageStorageFunction };
export { localStorageStorage };
