const LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX = "labs.look_and_like_feedbacks.";

enum LookAndLikeFeedbacks18n {
  HEADER_TITLE = "header_title",
  LIKES_TITLE = "likes_title",
  DISLIKES_TITLE = "dislikes_title",
  NO_FEEDBACK = "no_feedback",
}

export { LOOK_AND_LIKE_FEEDBACKS_I18N_PREFIX };

export default LookAndLikeFeedbacks18n;
