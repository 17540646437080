import { FC } from "react";
import { includesAnyComposedSize } from "../../../../core/projection/size/model/Size";
import SizeSelectorProps from "./SizeSelectorProps";
import BasicSizeSelector from "./BasicSizeSelector";
import ComposedSizeSelector from "./ComposedSizeSelector";
import "./size-selector.css";

const SizeSelector: FC<SizeSelectorProps> = ({ sizes, compatibleSizes, selectedSize, onChange }: SizeSelectorProps) => {
  const Selector: FC<SizeSelectorProps> = includesAnyComposedSize(sizes) ? ComposedSizeSelector : BasicSizeSelector;

  return (
    <div className="size-selector">
      <Selector compatibleSizes={compatibleSizes} selectedSize={selectedSize} sizes={sizes} onChange={onChange} />
    </div>
  );
};

export default SizeSelector;
