import { useCallback } from "react";
import { useTracker } from "./useTracker";
import { Section, TrackingReviewedInfo } from "../Tracker";

interface TrackCheckLastBoxesInfoFunctionArgs {
  readonly checkedBoxId?: string;
  readonly reviewedInfo: TrackingReviewedInfo;
}
interface TrackCheckLastBoxesInfoFunction {
  (args: TrackCheckLastBoxesInfoFunctionArgs): void;
}

interface UseTrackCheckLastBoxesInfoFunctionArgs {
  readonly section: Section;
  readonly boxId?: string;
  readonly psId?: string;
  readonly userId?: string;
}
interface UseTrackCheckLastBoxesInfoFunction {
  (args: UseTrackCheckLastBoxesInfoFunctionArgs): TrackCheckLastBoxesInfoFunction;
}

const useTrackCheckLastBoxesInfo: UseTrackCheckLastBoxesInfoFunction = ({ section, psId, userId, boxId }) => {
  const tracker = useTracker();

  const trackCheckLastBoxesInfo: TrackCheckLastBoxesInfoFunction = useCallback(
    ({ checkedBoxId, reviewedInfo }) => {
      if (!(tracker && section && psId)) {
        return;
      }

      tracker.checkLastBoxesInfo({
        boxId,
        psId,
        section,
        userId,
        checkedBoxId,
        reviewedInfo,
      });
    },
    [boxId, psId, section, tracker, userId],
  );

  return trackCheckLastBoxesInfo;
};

export { useTrackCheckLastBoxesInfo };
