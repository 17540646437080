import HttpClient from "../HttpClient";

class FetchHttpClient implements HttpClient {
  private apiUrl: string;
  private authHeaders: Record<string, string>;

  public constructor(apiUrl = "", authHeaders: Record<string, string> = {}) {
    this.apiUrl = apiUrl;
    this.authHeaders = authHeaders;
  }

  public async get(endpoint: string): Promise<Response> {
    /**
     * Credentials: "include" and mode: "no-cors" are required for the browser's cache to work properly.
     */
    return await fetch(`${this.apiUrl}${endpoint}`, {
      method: "GET",
      credentials: "include",
      // mode: "no-cors",
      headers: this.authHeaders,
      referrer: "",
    });
  }

  public async post(endpoint: string, body: Record<string, unknown>): Promise<Response> {
    return await fetch(`${this.apiUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...this.authHeaders,
      },
      body: JSON.stringify(body),
    });
  }

  public async delete(endpoint: string): Promise<Response> {
    return await fetch(`${this.apiUrl}${endpoint}`, {
      method: "DELETE",
      headers: this.authHeaders,
    });
  }
}

export default FetchHttpClient;
