import { EditableShortlistFilterProjection } from "../../../../projection/editableShortlistFilter/editableShortlistFilter";
import {
  EditableShortlistFiltersView,
  ListEditableShortlistFiltersResult,
} from "../../../../projection/editableShortlistFilter/listEditableShortlistFilters";
import { HttpPostFunction } from "../../../delivery/http/httpClient";

interface HttpEditableShortlistFiltersView extends EditableShortlistFiltersView {}

interface HttpEditableShortlistFiltersViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpEditableShortlistFiltersViewFunction {
  (args: HttpEditableShortlistFiltersViewFunctionArgs): HttpEditableShortlistFiltersView;
}

interface ListEditableShortlistFiltersResponse {
  readonly result: EditableShortlistFilterProjection;
}

const httpEditableShortlistFiltersView: HttpEditableShortlistFiltersViewFunction =
  ({ httpPost }) =>
  async ({ boxId, signal }) =>
    await httpPost<ListEditableShortlistFiltersResponse, ListEditableShortlistFiltersResult>({
      endpoint: "/list-editable-shortlist-filters",
      body: {
        box_id: boxId,
      },
      signal,
      result: {
        error: null,
        success: (editableShortlistFilter) => editableShortlistFilter.result,
      },
    });

export { httpEditableShortlistFiltersView };
