import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { NoteTemplateValidation, NoteTemplateValidationError } from "./noteTemplateValidation";
import { NoteTemplateValidationErrorId } from "./noteTemplateValidation";
import { NoteTemplateProjection } from "../noteTemplate/noteTemplate";

const requiredMessageKey = "labs.note.template.validation.field_required";
const ValidationErrors: Record<string, NoteTemplateValidationError> = {
  TITLE: { id: NoteTemplateValidationErrorId.TITLE, message: requiredMessageKey },
  CATEGORY: { id: NoteTemplateValidationErrorId.CATEGORY, message: requiredMessageKey },
  CONTENT: { id: NoteTemplateValidationErrorId.CONTENT, message: requiredMessageKey },
};

const VALIDATE_NOTE_TEMPLATE = "validate_note_template";

interface ValidateNoteTemplatePayload {
  readonly noteTemplate: NoteTemplateProjection;
}

interface ValidateNoteTemplate extends Query<typeof VALIDATE_NOTE_TEMPLATE>, ValidateNoteTemplatePayload {}

interface ValidateNoteTemplateFunction {
  (payload: ValidateNoteTemplatePayload): ValidateNoteTemplate;
}

const validateNoteTemplate: ValidateNoteTemplateFunction = (payload) => ({
  ...query({ name: VALIDATE_NOTE_TEMPLATE }),
  ...payload,
});

type ValidateNoteTemplateResult = NoteTemplateValidation;

interface ValidateNoteTemplateHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const validateNoteTemplateHandler: QueryHandlerFunction<
  ValidateNoteTemplate,
  ValidateNoteTemplateResult,
  ValidateNoteTemplateHandlerFunctionArgs
> =
  () =>
  async ({ noteTemplate }) => {
    const noteTemplateTitle = (noteTemplate.title || "").trim();
    const noteTemplateCategory = (noteTemplate.category || "").trim();
    const noteTemplateContent = (noteTemplate.content || "").replace(/(<([^>]+)>)/gi, "").trim();

    return {
      errors: [
        ...(!Boolean(noteTemplateTitle) ? [ValidationErrors.TITLE] : []),
        ...(!Boolean(noteTemplateCategory) ? [ValidationErrors.CATEGORY] : []),
        ...(!Boolean(noteTemplateContent) ? [ValidationErrors.CONTENT] : []),
      ],
    };
  };

export type { ValidateNoteTemplate, ValidateNoteTemplateResult };
export { ValidationErrors, VALIDATE_NOTE_TEMPLATE, validateNoteTemplate, validateNoteTemplateHandler };
