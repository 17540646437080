import { Look } from "../../../../domain/look/model/Look";
import { isLooksSet } from "../../../../domain/look/model/looksSet";
import { LookDto } from "../../../persistence/lookDto";
import { StorageLooksSaveFunction } from "./storageLooks";

interface FromDomainFunction {
  (look: Look): LookDto[];
}

const fromDomain: FromDomainFunction = ({ looks }) => looks;

interface StorageLooksSetFunction extends StorageLooksSaveFunction {}

const storageLooksSet: StorageLooksSetFunction =
  ({ storage: { write, remove } }) =>
  async (aggregateRoot) => {
    const looksSet = aggregateRoot.domainEvents.find(isLooksSet);

    if (!looksSet) {
      return;
    }

    if (aggregateRoot.looks.length === 0) {
      await remove(aggregateRoot.aggregateId);
    } else {
      await write(aggregateRoot.aggregateId, fromDomain(aggregateRoot));
    }
  };

export { storageLooksSet };
