import { FC } from "react";
import RootLoader from "../../componentLibrary/atoms/rootLoader/RootLoader";
import { BoxPreviewDetail as BoxPreviewDetailTemplate } from "../../componentLibrary/organisms/boxPreviewDetail/BoxPreviewDetail";
import { useViewBoxPreviewByBoxId } from "../../../core/infrastructure/projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { QueryStatus } from "@lookiero/messaging-react";
import "./box-preview-detail.css";

const BoxPreviewDetailLoader: FC = () => (
  <div className="box-preview-detail__loader">
    <RootLoader />
  </div>
);

type BoxPreviewDetailProps = {
  readonly boxId?: string;
};
const BoxPreviewDetail: FC<BoxPreviewDetailProps> = ({ boxId }: BoxPreviewDetailProps) => {
  const [boxPreview, boxPreviewStatus] = useViewBoxPreviewByBoxId({ boxId });

  return boxPreviewStatus === QueryStatus.LOADING ? (
    <BoxPreviewDetailLoader />
  ) : boxPreview ? (
    <BoxPreviewDetailTemplate boxPreview={boxPreview} />
  ) : null;
};

export { BoxPreviewDetail };
