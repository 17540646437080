import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { HistoryNoteProjection } from "../../../../projection/historyNote/historyNote";
import { listHistoryNotesByCustomerId } from "../../../../projection/historyNote/listHistoryNotesByCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface UseListHistoryNotesByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseListHistoryNotesByCustomerIdFunction {
  (args: UseListHistoryNotesByCustomerIdFunctionArgs): UseQueryFunctionResult<HistoryNoteProjection[]>;
}

const useListHistoryNotesByCustomerId: UseListHistoryNotesByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listHistoryNotesByCustomerId({ customerId }),
    options: { staleTime: Infinity, retry: 0, refetchOnWindowFocus: false },
  });

export { useListHistoryNotesByCustomerId };
