import { I18nMessage } from "@lookiero/i18n-react";
import { FC, ReactNode, useCallback, useRef } from "react";
import Text from "../../../../shared/ui/uiKit/atoms/text/Text";
import NoteTemplateI18n, { NOTE_TEMPLATE_I18N_PREFIX } from "../../_i18n/NoteTemplateI18n";
import VirtualizedList from "../../../../shared/ui/uiKit/layouts/virtualizedList/VirtualizedList";
import NoteTemplateSkeleton from "../../organisms/noteTemplate/NoteTemplateSkeleton";
import NoteTemplate from "../../organisms/noteTemplate/NoteTemplate";
import { NoteTemplateProjection } from "../../../../core/projection/noteTemplate/noteTemplate";
import "./note-templates.css";

type NoteTemplatesProps = {
  readonly noteTemplates: Record<number, NoteTemplateProjection[]>;
  readonly itemCount: number;
  readonly page: number;
  readonly perPage: number;
  readonly onPageChanged: (page: number) => void;
  readonly noteTemplateActions: (noteTemplate: NoteTemplateProjection) => ReactNode;
};

const estimatedItemHeight = () => 200;
const itemsPerRow = () => 1;

const NoteTemplates: FC<NoteTemplatesProps> = ({
  noteTemplates,
  itemCount,
  page,
  perPage,
  onPageChanged,
  noteTemplateActions,
}) => {
  const noteTemplateItem = useCallback(
    (index: number, noteTemplate: NoteTemplateProjection | null): ReactNode =>
      noteTemplate ? (
        noteTemplate.id ? (
          <NoteTemplate key={noteTemplate.id} actions={noteTemplateActions(noteTemplate)} noteTemplate={noteTemplate} />
        ) : (
          <NoteTemplateSkeleton key={index} />
        )
      ) : (
        // Empty placeholder to fill the space
        <div key={index} />
      ),
    [noteTemplateActions],
  );

  const noteTemplatesRef = useRef(null);

  return (
    <section ref={noteTemplatesRef} aria-label="note-templates" className="note-templates" role="list">
      {itemCount > 0 ? (
        <div aria-label="note-templates-list" className="note-templates__list" role="list">
          <VirtualizedList
            data={noteTemplates}
            estimatedItemHeight={estimatedItemHeight}
            itemCount={itemCount}
            itemsPerRow={itemsPerRow}
            page={page}
            perPage={perPage}
            scrollElement={noteTemplatesRef.current}
            onPageChanged={onPageChanged}
          >
            {noteTemplateItem}
          </VirtualizedList>
        </div>
      ) : (
        <Text className="note-templates__empty-notes">
          <I18nMessage id={NoteTemplateI18n.TEMPLATES_NOT_AVAILABLE} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
        </Text>
      )}
    </section>
  );
};

export default NoteTemplates;
