import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const PRODUCT_VARIANT_MARKED_AS_CANDIDATE = "product_variant_marked_as_candidate";

interface ProductVariantMarkedAsCandidatePayload {
  readonly aggregateId: string;
  readonly productVariantId: string;
}

interface ProductVariantMarkedAsCandidate
  extends DomainEvent<typeof PRODUCT_VARIANT_MARKED_AS_CANDIDATE>,
    ProductVariantMarkedAsCandidatePayload {}

interface ProductVariantMarkedAsCandidateFunction {
  (payload: ProductVariantMarkedAsCandidatePayload): ProductVariantMarkedAsCandidate;
}

const productVariantMarkedAsCandidate: ProductVariantMarkedAsCandidateFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: PRODUCT_VARIANT_MARKED_AS_CANDIDATE }),
  ...payload,
});

const isProductVariantMarkedAsCandidate = (event: DomainEvent<MessageName>): event is ProductVariantMarkedAsCandidate =>
  event.name === PRODUCT_VARIANT_MARKED_AS_CANDIDATE;

export type { ProductVariantMarkedAsCandidate };
export { PRODUCT_VARIANT_MARKED_AS_CANDIDATE, productVariantMarkedAsCandidate, isProductVariantMarkedAsCandidate };
