import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import classNames from "classnames";
import Text, { TextVariant } from "../text/Text";
import Icon, { IconVariant } from "../icon/Icon";
import "./button.css";

enum ButtonVariant {
  BASIC = "basic",
  PRIMARY = "primary",
  DESTRUCTIVE = "destructive",
  PLAIN = "plain",
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly children?: ReactNode;
  readonly variant?: ButtonVariant;
  readonly icon?: IconVariant;
  readonly className?: string;
}
const Button: FC<ButtonProps> = ({
  variant = ButtonVariant.BASIC,
  icon,
  children,
  className,
  ...rest
}: ButtonProps) => (
  <Text
    tag="button"
    variant={TextVariant.BODY}
    className={classNames(
      "button",
      `button--${variant}`,
      { "button--with-icon": children && icon, "button--just-icon": !children && icon },
      className,
    )}
    {...rest}
  >
    {icon && <Icon variant={icon} />}
    {children}
  </Text>
);

export { ButtonVariant };

export default Button;
