import { LooksBySelectionIdView } from "../../../projection/looks/listLooksBySelectionId";
import { LookDto } from "../../persistence/lookDto";
import { Storage } from "../../persistence/storage";

interface StorageLooksBySelectionIdView extends LooksBySelectionIdView {}

interface StorageLooksBySelectionIdViewFunctionArgs {
  readonly storage: Storage<LookDto[]>;
}

interface StorageLooksBySelectionIdViewFunction {
  (args: StorageLooksBySelectionIdViewFunctionArgs): StorageLooksBySelectionIdView;
}

const storageLooksBySelectionIdView: StorageLooksBySelectionIdViewFunction =
  ({ storage: { read } }) =>
  async ({ selectionId }) => {
    try {
      const looks = await read(selectionId);

      return looks ?? [];
    } catch (ignored) {
      throw new Error("Could not fetch looks");
    }
  };

export { storageLooksBySelectionIdView };
