import { I18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback, useState } from "react";
import { LookProductVariantProjection } from "../../../../core/projection/looks/lookProductVariant";
import { lookTemplateForItems } from "../../../../core/projection/looks/lookTemplate";
import Media, { mainTransparentImageFromMedia } from "../../../../core/projection/media/model/Media";
import Button from "../../../../shared/ui/uiKit/atoms/button/Button";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Picture from "../../../../shared/ui/uiKit/atoms/picture/Picture";
import ActionConfirmationModal from "../../../../shared/ui/uiKit/organisms/actionConfirmationModal/ActionConfirmationModal";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../componentLibrary/_i18n/SelectionI18n";
import cdnImageUrl from "../../../_util/cdnImageUrl";
import LookTemplate from "../../../componentLibrary/organisms/lookTemplate/LookTemplate";
import "./look.css";

const LOOK_PRODUCT_VARIANT_IMAGE_WIDTH = 200;

const imageSrc = (media: Media[]) =>
  cdnImageUrl({
    url: mainTransparentImageFromMedia(media).url,
    width: LOOK_PRODUCT_VARIANT_IMAGE_WIDTH,
  });

type LookProps = {
  readonly lookProductVariants: LookProductVariantProjection[];
  readonly onRemove?: () => void;
};
const Look: FC<LookProps> = ({ lookProductVariants, onRemove }) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const showConfirmationModal = useCallback(() => setConfirmationVisible(true), []);
  const hideConfirmationModal = useCallback(() => setConfirmationVisible(false), []);
  const handleOnRemoveConfirmed = useCallback(() => {
    hideConfirmationModal();
    onRemove && onRemove();
  }, [hideConfirmationModal, onRemove]);

  return (
    <article className="look">
      <LookTemplate
        lookProductVariants={lookProductVariants}
        variant={lookTemplateForItems(lookProductVariants.length)}
      >
        {(productVariant: LookProductVariantProjection | undefined) =>
          productVariant ? (
            <Picture
              aspectRatio={AspectRatio.R_4_5}
              label="look-item-picture"
              src={imageSrc(productVariant.media)}
              lazy
            />
          ) : null
        }
      </LookTemplate>
      {onRemove && (
        <Button
          aria-label="look-remove"
          className="look__remove"
          icon={IconVariant.CLOSE}
          onClick={showConfirmationModal}
        />
      )}
      {onRemove && (
        <ActionConfirmationModal
          className="look__remove-confirmation"
          label="look-remove-confirmation"
          visible={confirmationVisible}
          cancelButtonText={
            <I18nMessage id={SelectionI18n.REMOVE_LOOK_CONFIRMATION_CANCEL} prefix={SELECTION_I18N_PREFIX} />
          }
          confirmButtonText={
            <I18nMessage id={SelectionI18n.REMOVE_LOOK_CONFIRMATION_CONFIRM} prefix={SELECTION_I18N_PREFIX} />
          }
          title={
            <header className="look__remove-confirmation-header">
              <Icon variant={IconVariant.ALERT_WARNING} />
              <I18nMessage id={SelectionI18n.REMOVE_LOOK_CONFIRMATION_TITLE} prefix={SELECTION_I18N_PREFIX} />
            </header>
          }
          onCancel={hideConfirmationModal}
          onConfirm={handleOnRemoveConfirmed}
        >
          <I18nMessage id={SelectionI18n.REMOVE_LOOK_CONFIRMATION_DESCRIPTION} prefix={SELECTION_I18N_PREFIX} />
        </ActionConfirmationModal>
      )}
    </article>
  );
};

export default Look;
