import { FC } from "react";
import Modal from "../../../../shared/ui/uiKit/layouts/modal/Modal";
import { LookAndLike } from "./LookAndLike";
import "./look-and-like-modal.css";

interface LooAndLikeModalProps {
  readonly customerId: string;
  readonly visible: boolean;
  readonly familyIds?: string[];
  readonly onCancel: () => void;
  readonly onClickProductVariant: (productVariantId: string) => void;
}

const LookAndLikeModal: FC<LooAndLikeModalProps> = ({
  customerId,
  visible,
  familyIds,
  onCancel,
  onClickProductVariant,
}) => (
  <Modal className="look-and-like-modal" label="look-and-like-modal" visible={visible} onHide={onCancel}>
    <LookAndLike customerId={customerId} familyIds={familyIds} onClickProductVariant={onClickProductVariant} />
  </Modal>
);

export { LookAndLikeModal };
