import { I18nMessage } from "@lookiero/i18n-react";
import { FC, ReactNode } from "react";
import Model from "../../../../../core/projection/model/model/Model";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import Measurement from "../../../atoms/measurement/Measurement";
import Size from "../../../atoms/size/Size";
import ShortlistI18n, { SHORTLIST_I18N_PREFIX } from "../../../_i18n/ShortlistI18n";
import "./product-model-morphology.css";

type ProductModelMorphologyItemProps = {
  readonly id: string;
  readonly children: ReactNode;
};
const ProductModelMorphologyItem: FC<ProductModelMorphologyItemProps> = ({
  id,
  children,
}: ProductModelMorphologyItemProps) => (
  <li key={id} className="product-model-morphology__list-item">
    <Text variant={TextVariant.BODY}>
      <I18nMessage id={id} prefix={SHORTLIST_I18N_PREFIX} />
      :&nbsp;
    </Text>
    <Text variant={TextVariant.BODY_BOLD}>{children}</Text>
  </li>
);

type ProductModelMorphologyProps = {
  readonly children?: ReactNode;
  readonly model?: Model;
};
const ProductModelMorphology: FC<ProductModelMorphologyProps> = ({
  model = {} as Model,
  children,
}: ProductModelMorphologyProps) => {
  const notInformed = <I18nMessage id={ShortlistI18n.NOT_INFORMED} prefix={SHORTLIST_I18N_PREFIX} />;

  return (
    <section className="product-model-morphology">
      {children && <header className="product-model-morphology__header">{children}</header>}
      <ul className="product-model-morphology__list">
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_WEIGHT}>
          {model.weight ? <Measurement measurement={model.weight} /> : notInformed}
        </ProductModelMorphologyItem>
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_HEIGHT}>
          {model.height ? <Measurement measurement={model.height} /> : notInformed}
        </ProductModelMorphologyItem>
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_TOP_SIZE}>
          {model.upperSize ? <Size size={model.upperSize} /> : notInformed}
        </ProductModelMorphologyItem>
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_BOTTOM_SIZE}>
          {model.bottomSize ? <Size size={model.bottomSize} /> : notInformed}
        </ProductModelMorphologyItem>
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_SHOE_SIZE}>
          {model.shoeSize ? <Size size={model.shoeSize} /> : notInformed}
        </ProductModelMorphologyItem>
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_BRA_SIZE}>
          {model.braSize ? <Size size={model.braSize} /> : notInformed}
        </ProductModelMorphologyItem>
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_CHEST_CONTOUR}>
          {model.chestOutline ? <Measurement measurement={model.chestOutline} /> : notInformed}
        </ProductModelMorphologyItem>
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_WAISTLINE}>
          {model.waistOutline ? <Measurement measurement={model.waistOutline} /> : notInformed}
        </ProductModelMorphologyItem>
        <ProductModelMorphologyItem id={ShortlistI18n.MODEL_HIP_CONTOUR}>
          {model.hipOutline ? <Measurement measurement={model.hipOutline} /> : notInformed}
        </ProductModelMorphologyItem>
      </ul>
    </section>
  );
};

export default ProductModelMorphology;
