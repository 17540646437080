import { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { useFirstMountState } from "react-use";
import classNames from "classnames";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import ProductVariantGallery from "../productVariantGallery/ProductVariantGallery";
import ProductVariantSelector, {
  ProductVariantSelectorVariant,
} from "../productVariantSelector/ProductVariantSelector";
import Card from "../../../../shared/ui/uiKit/layouts/card/Card";
import ProductData from "./productData/ProductData";
import SalesTag from "../../atoms/salesTag/SalesTag";
import Sizing from "../../atoms/sizing/Sizing";
import { isVideoSrc } from "../../../_util/srcType";
import cdnImageUrl from "../../../_util/cdnImageUrl";
import MediaPerspective from "../../../../core/domain/media/model/MediaPerspective";
import { ProductPreviewColor } from "./productPreviewColor/ProductPreviewColor";
import "./product-preview.css";
import { ReplacedFor } from "../../../../core/projection/boxPreview/boxPreview";
import { ProductVariantProjection } from "../../../../core/projection/product/productVariant";
import { ProductProjection } from "../../../../core/projection/product/product";

const PRODUCT_PREVIEW_IMAGE_WIDTH = 200;

type ProductPreviewProps<PV extends ProductVariantProjection, P extends ProductProjection<PV>> = {
  readonly product: P;
  readonly productVariant: PV;
  readonly onProductVariantChanged: (productVariant: PV) => void;
  readonly disabled?: boolean;
  readonly actions?: ReactNode;
  readonly alerts?: ReactNode;
  readonly candidate?: ReactNode;
  readonly replacedFor?: ReplacedFor | undefined;
};
const ProductPreview: <PV extends ProductVariantProjection, P extends ProductProjection<PV>>(
  props: ProductPreviewProps<PV, P>,
) => ReactElement = <PV extends ProductVariantProjection, P extends ProductProjection<PV>>({
  product,
  productVariant,
  onProductVariantChanged,
  disabled = false,
  actions,
  alerts,
  candidate,
  replacedFor,
}: ProductPreviewProps<PV, P>) => {
  const galleryMedia = productVariant.media
    .filter((media) => media.perspective !== MediaPerspective.MAIN_TRANSPARENT)
    .map((media) =>
      isVideoSrc(media.url)
        ? media.url
        : cdnImageUrl({
            url: media.url,
            width: PRODUCT_PREVIEW_IMAGE_WIDTH,
          }),
    );

  const [isActive, setIsActive] = useState(false);
  const handleMouseEnter = useCallback(() => setIsActive(true), []);
  const handleMouseLeave = useCallback(() => setIsActive(false), []);

  const [galleryItemIndex, setGalleryItemIndex] = useState(0);
  useEffect(() => setGalleryItemIndex(0), [productVariant]);

  const isUnrecommended = productVariant.recommended === false;

  const isFirstMount = useFirstMountState();

  return (
    <article
      aria-label="product-preview"
      role="listitem"
      className={classNames("product-preview", {
        "product-preview--disabled": disabled,
        "product-preview--unrecommended": isUnrecommended,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={isFirstMount ? undefined : handleMouseLeave}
    >
      <Card>
        <div className="product-preview__gallery">
          <ProductVariantGallery
            aspectRatio={AspectRatio.R_4_5}
            index={galleryItemIndex}
            isActive={isActive}
            media={galleryMedia}
            showPictureOnFirstMount={false}
            onChange={setGalleryItemIndex}
          />
          <div className="product-preview__top-container">
            {alerts && (
              <div aria-label="product-preview-alerts" role="list">
                {alerts}
              </div>
            )}
            {replacedFor && (
              <div aria-label="product-preview-replaced-for">
                <ProductPreviewColor clientColor={replacedFor.color} psColor={productVariant.color} />
              </div>
            )}
          </div>
          {candidate && (
            <div aria-label="product-preview-candidate" className="product-preview__candidate">
              {candidate}
            </div>
          )}
          <SalesTag price={productVariant.price} />
        </div>
        {actions && (
          <div aria-label="product-preview-actions" className="product-preview__actions" role="list">
            {actions}
          </div>
        )}
        <Sizing sizing={product.sizing} />
        <ProductData
          brand={product.brand}
          group={product.group}
          price={productVariant.price}
          season={productVariant.season}
          title={product.title}
        />
        <ProductVariantSelector
          productVariants={product.productVariants}
          selectedProductVariant={productVariant}
          variant={ProductVariantSelectorVariant.SMALL}
          onChange={onProductVariantChanged}
        />
      </Card>
    </article>
  );
};

export default ProductPreview;
