import { CustomerByIdView, ViewCustomerByIdResult } from "../../../projection/customer/viewCustomerById";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpCustomerByIdView extends CustomerByIdView {}

interface HttpCustomerByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCustomerByIdViewFunction {
  (args: HttpCustomerByIdViewFunctionArgs): HttpCustomerByIdView;
}

const httpCustomerByIdView: HttpCustomerByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<ViewCustomerByIdResult>({
      endpoint: "/view-customer-by-id",
      body: { id },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpCustomerByIdView };
