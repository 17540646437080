import camelcaseKeys from "camelcase-keys";

type CamelCaseFunction = typeof camelcaseKeys;

const camelcase: CamelCaseFunction = (p, options) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  camelcaseKeys(p, {
    deep: options?.deep || true,
    exclude: options?.exclude || [/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/],
    pascalCase: options?.pascalCase,
    stopPaths: options?.stopPaths,
  });

export { camelcase as camelcaseKeys };
