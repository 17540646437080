import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const UI_SETTING_UPDATED = "ui_setting_updated";

interface UiSettingUpdatedPayload {
  readonly aggregateId: string;
  readonly key: string;
}

interface UiSettingUpdated extends DomainEvent<typeof UI_SETTING_UPDATED>, UiSettingUpdatedPayload {}

interface UiSettingUpdatedFunction {
  (payload: UiSettingUpdatedPayload): UiSettingUpdated;
}

const uiSettingUpdated: UiSettingUpdatedFunction = ({ aggregateId, key }) => ({
  ...domainEvent({ aggregateId, name: UI_SETTING_UPDATED }),
  key,
});

const isUiSettingUpdated = (event: DomainEvent<MessageName>): event is UiSettingUpdated =>
  event.name === UI_SETTING_UPDATED;

export type { UiSettingUpdated };
export { UI_SETTING_UPDATED, uiSettingUpdated, isUiSettingUpdated };
