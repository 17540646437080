import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { StateFilteringValue } from "../../ShortlistFilterState";
import { useFirstMountState } from "react-use";

interface OnChangeFunctionArgs {
  readonly state: StateFilteringValue[];
}

interface OnChangeFunction {
  (args: OnChangeFunctionArgs): void;
}

interface ShortlistFilteringNegativesContextShape {
  readonly state: StateFilteringValue[];
  readonly filtering: { readonly id: string }[];
  readonly onChange: OnChangeFunction;
}

const ShortlistFilteringNegativesContext = createContext<ShortlistFilteringNegativesContextShape>(
  null as unknown as ShortlistFilteringNegativesContextShape,
);

interface ShortlistFilteringNegativesProviderProps {
  readonly filtering: { readonly id: string }[];
  readonly children: ReactNode;
}

const ShortlistFilteringNegativesProvider: FC<ShortlistFilteringNegativesProviderProps> = ({ filtering, children }) => {
  const [state, setState] = useState<StateFilteringValue[]>([]);

  const isFirstMount = useFirstMountState();
  useEffect(() => {
    if (!isFirstMount) {
      return;
    }

    setState(filtering);
  }, [filtering, isFirstMount]);

  const onChange = useCallback<OnChangeFunction>(({ state }) => setState(state), []);

  const value = useMemo(() => ({ state, onChange, filtering }), [state, onChange, filtering]);

  return (
    <ShortlistFilteringNegativesContext.Provider value={value}>{children}</ShortlistFilteringNegativesContext.Provider>
  );
};

interface UseShortlistFilteringNegativesFunction {
  (): ShortlistFilteringNegativesContextShape;
}

const useShortlistFilteringNegatives: UseShortlistFilteringNegativesFunction = () => {
  const shortlistFilteringNegatives = useContext<ShortlistFilteringNegativesContextShape>(
    ShortlistFilteringNegativesContext,
  );

  invariant(
    shortlistFilteringNegatives,
    "Your are trying to use the useShortlistFilteringNegatives hook without wrapping your app with the <ShortlistFilteringNegativesContext>.",
  );

  return shortlistFilteringNegatives;
};

export { useShortlistFilteringNegatives, ShortlistFilteringNegativesProvider };
