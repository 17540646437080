import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const PRODUCT_MARKED_AS_FAVOURITE = "product_marked_as_favourite";

interface ProductMarkedAsFavouritePayload {
  readonly aggregateId: string;
}

interface ProductMarkedAsFavourite extends DomainEvent<typeof PRODUCT_MARKED_AS_FAVOURITE> {}

interface ProductMarkedAsFavouriteFunction {
  (payload: ProductMarkedAsFavouritePayload): ProductMarkedAsFavourite;
}

const productMarkedAsFavourite: ProductMarkedAsFavouriteFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: PRODUCT_MARKED_AS_FAVOURITE });

const isProductMarkedAsFavourite = (event: DomainEvent<MessageName>): event is ProductMarkedAsFavourite =>
  event.name === PRODUCT_MARKED_AS_FAVOURITE;

export type { ProductMarkedAsFavourite };
export { PRODUCT_MARKED_AS_FAVOURITE, productMarkedAsFavourite, isProductMarkedAsFavourite };
