import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { PersonalShopperCountryProjection } from "./personalShopperCountry";

const LIST_PERSONAL_SHOPPER_COUNTRIES = "list_personal_shopper_countries";

interface ListPersonalShopperCountries extends Query<typeof LIST_PERSONAL_SHOPPER_COUNTRIES> {}

interface ListPersonalShopperCountriesFunction {
  (): ListPersonalShopperCountries;
}

const listPersonalShopperCountries: ListPersonalShopperCountriesFunction = () =>
  query({ name: LIST_PERSONAL_SHOPPER_COUNTRIES });

type ListPersonalShopperCountriesResult = PersonalShopperCountryProjection[];

interface PersonalShopperCountriesViewArgs extends CancelableQueryViewArgs {}

interface PersonalShopperCountriesView {
  (args: PersonalShopperCountriesViewArgs): Promise<ListPersonalShopperCountriesResult>;
}

interface ListPersonalShopperCountriesHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PersonalShopperCountriesView;
}

const listPersonalShopperCountriesHandler: QueryHandlerFunction<
  ListPersonalShopperCountries,
  ListPersonalShopperCountriesResult,
  ListPersonalShopperCountriesHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async () =>
    view({ signal });

export type { PersonalShopperCountriesView, ListPersonalShopperCountries, ListPersonalShopperCountriesResult };
export { LIST_PERSONAL_SHOPPER_COUNTRIES, listPersonalShopperCountries, listPersonalShopperCountriesHandler };
