import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { viewAnnotationByCustomerId } from "../../../../projection/annotation/viewAnnotationByCustomerId";
import { isAnnotationUpdated } from "../../../../domain/annotation/model/annotationUpdated";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { AnnotationProjection } from "../../../../projection/annotation/annotation";

interface UseViewAnnotationByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseViewAnnotationByCustomerIdFunction {
  (args: UseViewAnnotationByCustomerIdFunctionArgs): UseQueryFunctionResult<AnnotationProjection>;
}

const useViewAnnotationByCustomerId: UseViewAnnotationByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: viewAnnotationByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: isAnnotationUpdated,
    options: { staleTime: Infinity, retry: 0, refetchOnWindowFocus: false },
  });

export { useViewAnnotationByCustomerId };
