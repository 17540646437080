export enum FeedbackAnswer {
  ITEM_SIZE_BIG = "item_size_big",
  ITEM_SIZE_SMALL = "item_size_small",
  ITEM_SHAPE_DONT_LIKE = "item_shape_dont_like",
  ITEM_SHAPE_RIGHT = "item_shape_right",
  ITEM_QUALITY_WORSE = "item_quality_worse",
  ITEM_DIFFERENT_IMAGE = "item_different_image",
  ITEM_DIFFERENT_DESCRIPTION = "item_different_description",
  ITEM_DIFFERENT_ITEM = "item_different_item",
}

type Feedback = {
  readonly answer: FeedbackAnswer[];
  readonly comment?: string;
};

export default Feedback;
