import {
  ReactNode,
  useContext,
  useState,
  useCallback,
  useEffect,
  createContext,
  FC,
  useMemo,
  useRef,
  RefObject,
} from "react";
import { invariant } from "ts-invariant";
import { createPortal } from "react-dom";
import { v4 as uuid } from "uuid";
import { useClickAway, useKey } from "react-use";
import useLockBodyScroll from "../useLockBodyScroll/useLockBodyScroll";

interface UnstackLayerFunction {
  (): void;
}

interface StackLayerFunctionArgs {
  readonly id: string;
  readonly visible: boolean;
}

interface StackLayerFunction {
  (args: StackLayerFunctionArgs): UnstackLayerFunction;
}

interface LayerIndexFunctionArgs {
  readonly id: string;
}

interface LayerIndexFunction {
  (args: LayerIndexFunctionArgs): number;
}

interface LayersContextApi {
  readonly stackLayer: StackLayerFunction;
  readonly layerIndex: LayerIndexFunction;
  readonly activeId: string;
  readonly baseZIndex: number;
}

const LayersContext = createContext<LayersContextApi>({} as LayersContextApi);

interface LayersProviderProps {
  readonly children: ReactNode;
  readonly baseZIndex?: number;
}

const LayersProvider: FC<LayersProviderProps> = ({ children, baseZIndex = 10000 }) => {
  const [layerIds, setLayerIds] = useState<string[]>([]);
  const layerIdsRef = useRef(layerIds);
  layerIdsRef.current = layerIds;
  const activeId = layerIds[layerIds.length - 1];

  const stackLayer: StackLayerFunction = useCallback(({ id, visible }) => {
    setLayerIds((layerIds) => [...layerIds.filter((layerId) => layerId !== id), ...(visible ? [id] : [])]);

    return () => setLayerIds((layerIds) => layerIds.filter((layerId) => layerId !== id));
  }, []);

  const layerIndex: LayerIndexFunction = useCallback(
    ({ id }) => layerIdsRef.current.findIndex((layerId) => layerId === id),
    [],
  );

  const value = useMemo(
    () => ({ stackLayer, layerIndex, activeId, baseZIndex }),
    [activeId, baseZIndex, layerIndex, stackLayer],
  );

  useLockBodyScroll(Boolean(activeId));

  return <LayersContext.Provider value={value}>{children}</LayersContext.Provider>;
};

interface LayerProps {
  readonly visible: boolean;
  readonly children: JSX.Element;
}

interface UseLayerFunctionReturn {
  readonly Layer: FC<LayerProps>;
  readonly viewRef: RefObject<HTMLDivElement>;
}

interface UseLayerFunctionArgs {
  readonly onHide: () => void;
  readonly hideOnClickAway?: boolean;
  readonly hideOnEscapeKey?: boolean;
}

interface UseLayerFunction {
  (args: UseLayerFunctionArgs): UseLayerFunctionReturn;
}

const useLayer: UseLayerFunction = ({ onHide, hideOnClickAway = true, hideOnEscapeKey = true }) => {
  const { stackLayer, layerIndex, activeId, baseZIndex } = useContext(LayersContext);
  invariant(stackLayer, "You are trying to use the useLayer hook without wrapping your app with the <LayersProvider>.");

  const idRef = useRef(uuid());
  const viewRef = useRef(null);
  const activeIdRef = useRef(activeId);
  activeIdRef.current = activeId;
  const onHideRef = useRef(onHide);
  onHideRef.current = onHide;

  const onHideLayer = useCallback(() => {
    if (activeIdRef.current === idRef.current) {
      onHideRef.current();
    }
  }, []);

  const handleClickAway = useCallback(() => hideOnClickAway && onHideLayer(), [hideOnClickAway, onHideLayer]);
  useClickAway(viewRef, handleClickAway);

  const handleEscapeKey = useCallback(() => hideOnEscapeKey && onHideLayer(), [hideOnEscapeKey, onHideLayer]);
  useKey("Escape", handleEscapeKey);

  const Layer: FC<LayerProps> = useCallback(
    ({ children, visible }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => stackLayer({ id: idRef.current, visible }), [visible]);

      const index = layerIndex({ id: idRef.current });

      const layer = createPortal(
        visible ? (
          <div id={idRef.current} style={{ zIndex: baseZIndex + index, position: "fixed", top: 0, left: 0 }}>
            {children}
          </div>
        ) : null,
        document.body,
        `layer-${idRef.current}`,
      );

      return layer;
    },
    [baseZIndex, layerIndex, stackLayer],
  );

  return { Layer, viewRef };
};

export { LayersProvider, useLayer };
