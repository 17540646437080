const NOTE_I18N_PREFIX = "labs.note.";

enum NoteI18n {
  HEADER_TITLE = "header_title",
  EDITOR_UNDO = "editor_undo",
  EDITOR_REDO = "editor_redo",
  EDITOR_STYLE_BOLD = "editor_style_bold",
  EDITOR_WRITE_SUCCESS = "editor_write_success",
  EDITOR_WRITE_ERROR = "editor_write_error",
  EDITOR_PREVIEW = "editor_preview",
  PREVIEW_TITLE = "preview_title",
  HISTORY_NOTES = "history_notes",
  NO_HISTORY_NOTES = "no_history_notes",
  SENT_NOTES = "sent_notes",
  BOX = "box",
  GENERATE_NOTE_TOOLTIP = "assistant.generation.tooltip",
  GENERATE_NOTE_CONFIRMATION_TITTLE = "assistant.generation_confirmation.tittle",
  GENERATE_NOTE_CONFIRMATION_DESCRIPTION = "assistant.generation_confirmation.text",
  GENERATE_NOTE_CONFIRMATION_CONFIRM = "assistant.generation_confirmation.confirm",
  GENERATE_NOTE_CONFIRMATION_CANCEL = "assistant.generation_confirmation.cancel",
  GENERATE_NOTE_ERROR = "assistant.generation.error",
}

export { NOTE_I18N_PREFIX };

export default NoteI18n;
