import { ComponentType } from "react";
import { Routes } from "../Routing";
import { Navigate, generatePath, useParams } from "react-router-dom";

const developmentOnlyProtected =
  <P extends Record<string, unknown>>(Component: ComponentType<P>) =>
  (props: P): JSX.Element => {
    const { locale } = useParams();

    return process.env.NODE_ENV !== "development" ? (
      <Navigate to={generatePath(Routes.HOME, { locale: locale as string })} />
    ) : (
      <Component {...props} />
    );
  };

export default developmentOnlyProtected;
