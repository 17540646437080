import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { PersonalShopperCountryProjection } from "../../../../projection/personalShopperCountry/personalShopperCountry";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listPersonalShopperCountries } from "../../../../projection/personalShopperCountry/listPersonalShopperCountries";

interface UseListPersonalShopperCountriesFunction {
  (): UseQueryFunctionResult<PersonalShopperCountryProjection[]>;
}

const useListPersonalShopperCountries: UseListPersonalShopperCountriesFunction = () =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listPersonalShopperCountries(),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useListPersonalShopperCountries };
