import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { canPersonalShopperWriteNote } from "./canPersonalShopperWriteNote";
import { SelectionProductVariantProjection, SelectionProjection } from "../selection/selection";

const SELECTION_PRODUCT_VARIANTS_COUNT = 5;

interface CanPersonalShopperWriteNoteFunctionArgs {
  readonly selection: SelectionProjection;
}
interface CanPersonalShopperWriteNoteFunction {
  (args: CanPersonalShopperWriteNoteFunctionArgs): boolean;
}
const canGenerateNote: CanPersonalShopperWriteNoteFunction = ({ selection }) => {
  const selectionProductVariants: SelectionProductVariantProjection[] = selection.products.map((product) =>
    product.productVariants.find((productVariant) => productVariant.isSelected),
  ) as SelectionProductVariantProjection[];

  return selectionProductVariants.length === SELECTION_PRODUCT_VARIANTS_COUNT;
};

const CAN_PERSONAL_SHOPPER_GENERATE_NOTE = "can_personal_shopper_generate_note";

interface CanPersonalShopperGenerateNotePayload {
  readonly boxId: string;
}

interface CanPersonalShopperGenerateNote
  extends Query<typeof CAN_PERSONAL_SHOPPER_GENERATE_NOTE>,
    CanPersonalShopperGenerateNotePayload {}

interface CanPersonalShopperGenerateNoteFunction {
  (payload: CanPersonalShopperGenerateNotePayload): CanPersonalShopperGenerateNote;
}

const canPersonalShopperGenerateNote: CanPersonalShopperGenerateNoteFunction = (payload) => ({
  ...query({ name: CAN_PERSONAL_SHOPPER_GENERATE_NOTE }),
  ...payload,
});

type CanPersonalShopperGenerateNoteResult = boolean;

interface CanPersonalShopperGenerateNoteHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const canPersonalShopperGenerateNoteHandler: QueryHandlerFunction<
  CanPersonalShopperGenerateNote,
  CanPersonalShopperGenerateNoteResult,
  CanPersonalShopperGenerateNoteHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ boxId }) => {
    // const selection: Selection | null = await queryBus(viewSelectionByLegacyBoxId(boxId));

    // if (!selection) {
    //   return false;
    // }

    // return canWriteNote && canGenerateNote({ selection });
    return await queryBus(canPersonalShopperWriteNote({ boxId }));
  };

export type { CanPersonalShopperGenerateNote, CanPersonalShopperGenerateNoteResult };
export {
  CAN_PERSONAL_SHOPPER_GENERATE_NOTE,
  canPersonalShopperGenerateNote,
  canPersonalShopperGenerateNoteHandler,
  canGenerateNote,
};
