import { DomainEvent } from "@lookiero/messaging.js";
import Class from "@lookiero/messaging.js/dist/Class";
import BoxPreviewSent from "../../domain/boxPreview/event/BoxPreviewSent";

const integrationEventRegistry: Record<string, Class<DomainEvent>> = {
  BoxPreviewSent,
};

type IntegrationEvent = {
  readonly name: string;
  readonly [x: string]: unknown;
};

export { integrationEventRegistry };

export default IntegrationEvent;
