import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { PersonalShopperProjection } from "./personalShopper";

const VIEW_PERSONAL_SHOPPER = "view_personal_shopper";

interface ViewPersonalShopper extends Query<typeof VIEW_PERSONAL_SHOPPER> {}

interface ViewPersonalShopperFunction {
  (): ViewPersonalShopper;
}

const viewPersonalShopper: ViewPersonalShopperFunction = () => query({ name: VIEW_PERSONAL_SHOPPER });

type ViewPersonalShopperResult = PersonalShopperProjection | null;

interface PersonalShopperViewArgs extends CancelableQueryViewArgs {}

interface PersonalShopperView {
  (args: PersonalShopperViewArgs): Promise<ViewPersonalShopperResult>;
}

interface ViewPersonalShopperHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PersonalShopperView;
}

const viewPersonalShopperHandler: QueryHandlerFunction<
  ViewPersonalShopper,
  ViewPersonalShopperResult,
  ViewPersonalShopperHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async () =>
    view({ signal });

export type { PersonalShopperView, ViewPersonalShopper, ViewPersonalShopperResult };
export { VIEW_PERSONAL_SHOPPER, viewPersonalShopper, viewPersonalShopperHandler };
