import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ForecastProjection } from "./forecast";

const VIEW_FORECAST_BY_CUSTOMER_ID = "view_forecast_by_customer_id";

interface ViewForecastByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewForecastByCustomerId
  extends Query<typeof VIEW_FORECAST_BY_CUSTOMER_ID>,
    ViewForecastByCustomerIdPayload {}

interface ViewForecastByCustomerIdFunction {
  (payload: ViewForecastByCustomerIdPayload): ViewForecastByCustomerId;
}

const viewForecastByCustomerId: ViewForecastByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_FORECAST_BY_CUSTOMER_ID }),
  ...payload,
});

type ViewForecastByCustomerIdResult = ForecastProjection | null;

interface ForecastByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface ForecastByCustomerIdView {
  (args: ForecastByCustomerIdViewArgs): Promise<ViewForecastByCustomerIdResult>;
}

interface ViewForecastByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ForecastByCustomerIdView;
}

const viewForecastByCustomerIdHandler: QueryHandlerFunction<
  ViewForecastByCustomerId,
  ViewForecastByCustomerIdResult,
  ViewForecastByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { ViewForecastByCustomerId, ForecastByCustomerIdView, ViewForecastByCustomerIdResult };
export { VIEW_FORECAST_BY_CUSTOMER_ID, viewForecastByCustomerId, viewForecastByCustomerIdHandler };
