import classNames from "classnames";
import { CSSProperties, FC } from "react";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import VirtualizedListProps from "./components/VirtualizedListProps";
import VirtualizedListWithSizeMap, { VirtualizedListWithSizeMapTagName } from "./components/VirtualizedListWithSizeMap";
import "./virtualized-list.css";

interface VirtualizedListClassname {
  readonly virtualizedList: string;
  readonly listRow: string;
}

type Props = VirtualizedListProps & {
  readonly className?: Partial<VirtualizedListClassname>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly listRowStyleFn?: (data: any) => CSSProperties;
  readonly tagName?: Partial<VirtualizedListWithSizeMapTagName>;
  readonly onClickListRow?: (index: number, data: unknown) => void;
};
const VirtualizedList: FC<Props> = ({ className, listRowStyleFn, tagName, onClickListRow, ...props }: Props) => (
  <div className={classNames("virtualized-list", className?.virtualizedList)}>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <AutoSizer className="virtualized-list__list" disableHeight>
      {(size: Size): JSX.Element => (
        <VirtualizedListWithSizeMap
          {...props}
          listRowClassName={className?.listRow}
          listRowStyleFn={listRowStyleFn}
          size={size}
          tagName={tagName}
          onClickListRow={onClickListRow}
        />
      )}
    </AutoSizer>
  </div>
);

export default VirtualizedList;
