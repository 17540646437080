import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import nonClosableBoxStatuses from "../../domain/box/model/NonClosableBoxStatuses";
import { ViewBoxByBoxNumberResult, viewBoxByBoxNumber } from "../box/viewBoxByBoxNumber";
import { viewBoxPreviewByBoxId, ViewBoxPreviewByBoxIdResult } from "../boxPreview/viewBoxPreviewByBoxId";

const CAN_MARK_OR_UNMARK_PRODUCT_VARIANT_AS_CANDIDATE = "can_mark_or_unmark_product_variant_as_candidate";

interface CanMarkOrUnmarkProductVariantAsCandidatePayload {
  readonly boxNumber: string;
  readonly boxId: string;
}

interface CanMarkOrUnmarkProductVariantAsCandidate
  extends Query<typeof CAN_MARK_OR_UNMARK_PRODUCT_VARIANT_AS_CANDIDATE>,
    CanMarkOrUnmarkProductVariantAsCandidatePayload {}

interface CanMarkOrUnmarkProductVariantAsCandidateFunction {
  (payload: CanMarkOrUnmarkProductVariantAsCandidatePayload): CanMarkOrUnmarkProductVariantAsCandidate;
}

const canMarkOrUnmarkProductVariantAsCandidate: CanMarkOrUnmarkProductVariantAsCandidateFunction = (payload) => ({
  ...query({ name: CAN_MARK_OR_UNMARK_PRODUCT_VARIANT_AS_CANDIDATE }),
  ...payload,
});

type CanMarkOrUnmarkProductVariantAsCandidateResult = boolean;

interface CanMarkOrUnmarkProductVariantAsCandidateHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const canMarkOrUnmarkProductVariantAsCandidateHandler: QueryHandlerFunction<
  CanMarkOrUnmarkProductVariantAsCandidate,
  CanMarkOrUnmarkProductVariantAsCandidateResult,
  CanMarkOrUnmarkProductVariantAsCandidateHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ boxNumber, boxId }) => {
    const box: ViewBoxByBoxNumberResult = await queryBus(viewBoxByBoxNumber({ boxNumber }));

    if (!box) {
      return false;
    }

    const isBoxStatusValid = !nonClosableBoxStatuses.includes(box.status);

    if (!isBoxStatusValid) {
      return false;
    }

    const boxPreview: ViewBoxPreviewByBoxIdResult = await queryBus(viewBoxPreviewByBoxId({ boxId }));

    return !Boolean(boxPreview);
  };

export type { CanMarkOrUnmarkProductVariantAsCandidate, CanMarkOrUnmarkProductVariantAsCandidateResult };
export {
  CAN_MARK_OR_UNMARK_PRODUCT_VARIANT_AS_CANDIDATE,
  canMarkOrUnmarkProductVariantAsCandidate,
  canMarkOrUnmarkProductVariantAsCandidateHandler,
};
