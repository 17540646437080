import Color from "../color/model/Color";
import Media from "../media/model/Media";
import Price from "../price/model/Price";
import Size from "../size/model/Size";

interface ProductVariant {
  readonly id: string;
  readonly season: number;
  readonly recommended: boolean;
  readonly score: number;
  readonly color: Color;
  readonly size: Size;
  readonly price: Price;
  readonly media: Media[];
}

const sortProductVariantsByScoreAndSeason = <PV extends ProductVariant>(productVariants: PV[]): PV[] =>
  [...productVariants].sort((aProductVariant, anotherProductVariant) => {
    const scoreCompared = anotherProductVariant.score - aProductVariant.score;
    const seasonCompared = aProductVariant.season - anotherProductVariant.season;

    return scoreCompared === 0 ? seasonCompared : scoreCompared;
  });

const bestProductVariantByScoreAndSeason = <PV extends ProductVariant>(productVariants: PV[]): PV =>
  sortProductVariantsByScoreAndSeason(productVariants)[0];

const bestProductVariantForSize = <PV extends ProductVariant>(size: Size, color: Color, productVariants: PV[]): PV => {
  const compatibleProductVariantsForSize = productVariants.filter(
    // TODO update size.lookiero.format for the size.id once the endpoint properly returns the expected id property
    (productVariant) => productVariant.size.lookiero.format === size.lookiero.format,
  );
  const compatibleProductVariantsForSizeAndColor = compatibleProductVariantsForSize.filter(
    (productVariant) => productVariant.color.code === color.code,
  );
  const compatibleProductVariants =
    compatibleProductVariantsForSizeAndColor.length > 0
      ? compatibleProductVariantsForSizeAndColor
      : compatibleProductVariantsForSize;

  return bestProductVariantByScoreAndSeason(compatibleProductVariants);
};

const bestProductVariantForColor = <PV extends ProductVariant>(color: Color, size: Size, productVariants: PV[]): PV => {
  const compatibleProductVariantsForColor = productVariants.filter(
    (productVariant) => productVariant.color.code === color.code,
  );
  const compatibleProductVariantsForColorAndSize = compatibleProductVariantsForColor.filter(
    (productVariant) => productVariant.size.lookiero.size === size.lookiero.size,
  );
  const compatibleProductVariants =
    compatibleProductVariantsForColorAndSize.length > 0
      ? compatibleProductVariantsForColorAndSize
      : compatibleProductVariantsForColor;

  return bestProductVariantByScoreAndSeason(compatibleProductVariants);
};

export { bestProductVariantByScoreAndSeason, bestProductVariantForSize, bestProductVariantForColor };

export type { ProductVariant as ProductVariantProjection };
