import { FC, ReactNode } from "react";
import classNames from "classnames";
import "./style-profile-card.css";
import Card from "../../../../shared/ui/uiKit/layouts/card/Card";

interface StyleProfileCardProps {
  readonly children: ReactNode;
  readonly className?: string;
  readonly label?: string;
}
const StyleProfileCard: FC<StyleProfileCardProps> = ({ children, className, label = "style-profile-card" }) => (
  <Card className={classNames("style-profile-card", className)} label={label}>
    {children}
  </Card>
);

export { StyleProfileCard };
