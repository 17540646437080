import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CustomerInsightProjection } from "./customerInsight";

const VIEW_CUSTOMER_INSIGHTS_BY_CUSTOMER_ID = "view_customer_insights_by_customer_id";

interface ViewCustomerInsightsByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewCustomerInsightsByCustomerId
  extends Query<typeof VIEW_CUSTOMER_INSIGHTS_BY_CUSTOMER_ID>,
    ViewCustomerInsightsByCustomerIdPayload {}

interface ViewCustomerInsightsByCustomerIdFunction {
  (payload: ViewCustomerInsightsByCustomerIdPayload): ViewCustomerInsightsByCustomerId;
}

const viewCustomerInsightsByCustomerId: ViewCustomerInsightsByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_CUSTOMER_INSIGHTS_BY_CUSTOMER_ID }),
  ...payload,
});

type ViewCustomerInsightsByCustomerIdResult = CustomerInsightProjection | null;

interface CustomerInsightsByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface CustomerInsightsByCustomerIdView {
  (args: CustomerInsightsByCustomerIdViewArgs): Promise<ViewCustomerInsightsByCustomerIdResult>;
}

interface ViewCustomerInsightsByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CustomerInsightsByCustomerIdView;
}

const viewCustomerInsightsByCustomerIdHandler: QueryHandlerFunction<
  ViewCustomerInsightsByCustomerId,
  ViewCustomerInsightsByCustomerIdResult,
  ViewCustomerInsightsByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { CustomerInsightsByCustomerIdView, ViewCustomerInsightsByCustomerIdResult };
export {
  VIEW_CUSTOMER_INSIGHTS_BY_CUSTOMER_ID,
  viewCustomerInsightsByCustomerId,
  viewCustomerInsightsByCustomerIdHandler,
};
