import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listBoxesByCustomerId } from "../../../../projection/box/listBoxesByCustomerId";
import { BasicBoxProjection } from "../../../../projection/box/box";

interface UseListBoxesByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseListBoxesByCustomerIdFunction {
  (args: UseListBoxesByCustomerIdFunctionArgs): UseQueryFunctionResult<BasicBoxProjection[]>;
}

const useListBoxesByCustomerId: UseListBoxesByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listBoxesByCustomerId({ customerId }),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useListBoxesByCustomerId };
