import Feedback, { FeedbackAnswer } from "../../../../projection/feedback/model/Feedback";

export type FeedbackResponseDTO = {
  readonly answer: string[];
};

const feedbackFromResponse = (feedback: FeedbackResponseDTO): Feedback => ({
  answer: feedback.answer as FeedbackAnswer[],
});

export { feedbackFromResponse };
