import invariant from "ts-invariant";
import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpNoteWrite } from "./httpNoteWrite";
import { NotesGetFunction, NotesSaveFunction } from "../../../../domain/note/model/notes";
import { Note } from "../../../../domain/note/model/note";
import { ViewNoteById, ViewNoteByIdResult, viewNoteById } from "../../../../projection/note/viewNoteById";
import { httpNoteGenerate } from "./httpNoteGenerate";

interface HttpNotesGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpNotesGetFunction extends NotesGetFunction<HttpNotesGetFunctionArgs> {}

interface ToDomainFunction {
  (note: ViewNoteByIdResult): Note | never;
}

const toDomain: ToDomainFunction = (note) => {
  invariant(note, "No note found!");

  return {
    aggregateId: note.id,
    boxId: note.boxId,
    content: note.content,
    psId: "",
    domainEvents: [],
  };
};

const getNote: HttpNotesGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(await queryBus<ViewNoteById, ViewNoteByIdResult>(viewNoteById({ id: aggregateId })));

interface HttpNotesSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpNotesSaveFunction extends NotesSaveFunction<HttpNotesSaveFunctionArgs> {}

const saveNote: HttpNotesSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([httpNoteWrite({ httpPost })(aggregateRoot), httpNoteGenerate({ httpPost })(aggregateRoot)]);
  };

export type { HttpNotesSaveFunction };
export { getNote, saveNote };
