import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const useSyncLocalValue = <K>(
  debounceTime: number,
  value: K,
  onChanged: (value: K) => void,
): [K, (value: K) => void] => {
  const [localValue, setLocalValue] = useState(value);
  const modifiedOn = useRef(0);
  const syncedOn = useRef(0);

  useEffect(() => {
    if (syncedOn.current < modifiedOn.current) {
      return;
    }

    setLocalValue(value);
  }, [value]);

  const syncValue = useMemo(
    () =>
      debounce((newValue: K) => {
        syncedOn.current = Date.now();
        onChanged(newValue);
      }, debounceTime),
    [onChanged, debounceTime],
  );

  const handleOnLocalValueChanged = useCallback(
    (value: K) => {
      modifiedOn.current = Date.now();
      setLocalValue(value);
      syncValue(value);
    },
    [syncValue],
  );

  return [localValue, handleOnLocalValueChanged];
};

export default useSyncLocalValue;
