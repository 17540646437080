import { FC, useCallback, useState } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import Modal from "../../../../shared/ui/uiKit/layouts/modal/Modal";
import ProductVariantDetailGallery from "../productVariantDetailGallery/ProductVariantDetailGallery";
import ShortlistI18n from "../../_i18n/ShortlistI18n";
import "./product-variant-comparer-detail.css";
import { ProductVariantProjection } from "../../../../core/projection/product/productVariant";

type ProductVariantComparerDetailProps = {
  readonly visible: boolean;
  readonly productVariants: [ProductVariantProjection | null, ProductVariantProjection | null];
  readonly onCancel: () => void;
};
const ProductVariantComparerDetail: FC<ProductVariantComparerDetailProps> = ({
  visible,
  productVariants,
  onCancel,
}: ProductVariantComparerDetailProps) => {
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const [secondItemIndex, setSecondItemIndex] = useState(0);

  const handleOnCancel = useCallback(() => {
    onCancel();

    setFirstItemIndex(0);
    setSecondItemIndex(0);
  }, [onCancel]);

  return (
    <Modal
      className="product-variant-comparer-detail"
      title={<I18nMessage id={ShortlistI18n.COMPARE} />}
      visible={visible}
      onHide={handleOnCancel}
    >
      <div className="product-variant-comparer-detail__products">
        {productVariants[0] ? (
          <ProductVariantDetailGallery
            key={productVariants[0].id}
            media={productVariants[0].media}
            selectedItemIndex={firstItemIndex}
            onChange={setFirstItemIndex}
          />
        ) : null}
        {productVariants[1] ? (
          <ProductVariantDetailGallery
            key={productVariants[1].id}
            media={productVariants[1].media}
            selectedItemIndex={secondItemIndex}
            onChange={setSecondItemIndex}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default ProductVariantComparerDetail;
