import { FC, useState, useCallback } from "react";
import { ProductVariantWithProductInfoProjection } from "../../../../core/projection/productVariantWithProductInfo/productVariantWithProductInfo";
import ColorOption, { ColorOptionState } from "../../../../shared/ui/uiKit/atoms/colorOption/ColorOption";
import Sizing from "../../atoms/sizing/Sizing";
import HistoryProductVariantSize from "../../molecules/historyProductVariantSize/HistoryProductVariantSize";
import ProductModelNotification from "../../molecules/productModelNotification/ProductModelNotification";
import ProductFeatures from "../productFeatures/ProductFeatures";
import ProductVariantDetailGallery from "../productVariantDetailGallery/ProductVariantDetailGallery";
import ProductVariantZoomGallery from "../productVariantZoomGallery/ProductVariantZoomGallery";
import { ProductDetailData } from "../productDetailData/ProductDetailData";
import ProductAlerts from "../productAlerts/ProductAlerts";
import Modal from "../../../../shared/ui/uiKit/layouts/modal/Modal";
import "./product-variant-with-product-info.css";

interface ProductVariantWithProductInfoProps {
  readonly productVariantWithProductInfo: ProductVariantWithProductInfoProjection;
}
const ProductVariantWithProductInfo: FC<ProductVariantWithProductInfoProps> = ({ productVariantWithProductInfo }) => {
  const [zoomed, setZoomed] = useState(false);
  const handleOnOpenZoom = useCallback(() => setZoomed(true), []);
  const handleOnCloseZoom = useCallback(() => setZoomed(false), []);

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  return (
    <article aria-label="product-variant-with-product-info" className="product-variant-with-product-info">
      <ProductVariantDetailGallery
        keyboardControlled={!zoomed}
        media={productVariantWithProductInfo.media}
        selectedItemIndex={selectedItemIndex}
        onChange={setSelectedItemIndex}
        onZoomed={handleOnOpenZoom}
      />
      <div className="product-variant-with-product-info__alerts">
        {productVariantWithProductInfo.model?.fashionModelTestedSize && (
          <ProductModelNotification testedSize={productVariantWithProductInfo.model?.fashionModelTestedSize} />
        )}
        <ProductAlerts alerts={productVariantWithProductInfo.alerts} />
      </div>

      <section className="product-variant-with-product-info__details">
        <header className="product-variant-with-product-info__header">
          <div className="product-variant-with-product-info__header-main">
            <ProductDetailData
              brand={productVariantWithProductInfo.brand}
              group={productVariantWithProductInfo.group}
              season={productVariantWithProductInfo.season}
              title={productVariantWithProductInfo.title}
            />
            <section className="product-variant-with-product-info__color-and-size">
              <ColorOption color={productVariantWithProductInfo.color} state={ColorOptionState.SELECTED} />
              <div className="product-variant-with-product-info__size">
                <HistoryProductVariantSize size={productVariantWithProductInfo.size} />
                <Sizing sizing={productVariantWithProductInfo.sizing} />
              </div>
            </section>
          </div>
        </header>

        <ProductFeatures
          characteristics={productVariantWithProductInfo.characteristics}
          description={productVariantWithProductInfo.description}
          model={productVariantWithProductInfo.model}
          others={productVariantWithProductInfo.others}
        />
      </section>

      <Modal visible={zoomed} onHide={handleOnCloseZoom}>
        <ProductVariantZoomGallery
          media={productVariantWithProductInfo.media}
          selectedItemIndex={selectedItemIndex}
          onChange={setSelectedItemIndex}
          onZoomClose={handleOnCloseZoom}
        />
      </Modal>
    </article>
  );
};

export { ProductVariantWithProductInfo };
