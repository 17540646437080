import { QueryStatus, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import Country from "../../../../domain/country/model/Country";
import { Segment } from "../../../../projection/segment/segment";
import { countCapacityCalendarByCriteria } from "../../../../projection/capacityCalendar/countCapacityCalendarByCriteria";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isCapacityCalendarSynchronized } from "../../../../domain/capacityCalendar/model/capacityCalendarSynchronized";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isCapacityCalendarSynchronized(event);

interface UseCountCapacityCalendarByCriteriaFunctionArgs {
  readonly from: Date;
  readonly to: Date;
  readonly markets: Country[];
  readonly segments: Segment[];
  readonly itemCountWhileQuerying: number;
}

interface UseCountCapacityCalendarByCriteriaFunction {
  (args: UseCountCapacityCalendarByCriteriaFunctionArgs): [number, QueryStatus];
}

const useCountCapacityCalendarByCriteria: UseCountCapacityCalendarByCriteriaFunction = ({
  from,
  markets,
  segments,
  to,
  itemCountWhileQuerying,
}) => {
  const [count, status] = useQuery<number, Error>({
    contextId: MESSAGING_CONTEXT_ID,
    query: countCapacityCalendarByCriteria({ from, markets, segments, to }),
    invalidation: shouldInvalidate,
    options: { staleTime: 60 * 60 * 1000 },
  });

  return [count ?? itemCountWhileQuerying, status];
};

export { useCountCapacityCalendarByCriteria };
