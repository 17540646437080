import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CAPACITY_CALENDAR_SYNCHRONIZED = "capacity_calendar_synchronized";

interface CapacityCalendarSynchronizedPayload {
  readonly aggregateId: string;
}

interface CapacityCalendarSynchronized extends DomainEvent<typeof CAPACITY_CALENDAR_SYNCHRONIZED> {}

interface CapacityCalendarSynchronizedFunction {
  (payload: CapacityCalendarSynchronizedPayload): CapacityCalendarSynchronized;
}

const capacityCalendarSynchronized: CapacityCalendarSynchronizedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CAPACITY_CALENDAR_SYNCHRONIZED });

const isCapacityCalendarSynchronized = (event: DomainEvent<MessageName>): event is CapacityCalendarSynchronized =>
  event.name === CAPACITY_CALENDAR_SYNCHRONIZED;

export type { CapacityCalendarSynchronized };
export { CAPACITY_CALENDAR_SYNCHRONIZED, capacityCalendarSynchronized, isCapacityCalendarSynchronized };
