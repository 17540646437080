import { localStorageStorage } from "./localStorageStorage";
import { LookDto } from "./lookDto";
import { Storage } from "./storage";

const LOOKS_KEY = "LOOKS";

interface LocalStorageLooksFunction {
  (): Storage<LookDto[]>;
}

const localStorageLooks: LocalStorageLooksFunction = () => {
  const { list, read, remove, write } = localStorageStorage<LookDto[]>();

  return {
    list,
    read: (key: string) => read(`${LOOKS_KEY}_ ${key}`),
    remove: (key: string) => remove(`${LOOKS_KEY}_ ${key}`),
    write: (key: string, value: LookDto[]) => write(`${LOOKS_KEY}_ ${key}`, value),
  };
};

export { localStorageLooks };
