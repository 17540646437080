import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const NOTIFICATION_CREATED = "notification_created";

interface NotificationCreatedPayload {
  readonly aggregateId: string;
}

interface NotificationCreated extends DomainEvent<typeof NOTIFICATION_CREATED>, NotificationCreatedPayload {}

interface NotificationCreatedFunction {
  (payload: NotificationCreatedPayload): NotificationCreated;
}

const notificationCreated: NotificationCreatedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: NOTIFICATION_CREATED });

const isNotificationCreated = (event: DomainEvent<MessageName>): event is NotificationCreated =>
  event.name === NOTIFICATION_CREATED;

export type { NotificationCreated };
export { NOTIFICATION_CREATED, notificationCreated, isNotificationCreated };
