import { FavouriteByIdView, ViewFavouriteByIdResult } from "../../../projection/favourite/viewFavouriteById";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { FavouriteDto } from "./favourite";
import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";

interface HttpFavouriteByIdView extends FavouriteByIdView {}

interface HttpFavouriteByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpFavouriteByIdViewFunction {
  (args: HttpFavouriteByIdViewFunctionArgs): HttpFavouriteByIdView;
}

interface ViewFavouriteByIdResponse {
  readonly result: FavouriteDto;
}

const httpFavouriteByIdView: HttpFavouriteByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<ViewFavouriteByIdResponse, ViewFavouriteByIdResult>({
      endpoint: "/view-favourite-by-id",
      body: { favourite_id: id },
      signal,
      result: {
        error: null,
        success: (response) => camelcaseKeys(response.result),
      },
    });

export { httpFavouriteByIdView };
