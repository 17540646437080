import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { AnnotationProjection } from "../../../../projection/annotation/annotation";
import { AnnotationByIdView, ViewAnnotationByIdResult } from "../../../../projection/annotation/viewAnnotationById";

interface HttpAnnotationByIdView extends AnnotationByIdView {}

interface HttpAnnotationByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpAnnotationByIdViewFunction {
  (args: HttpAnnotationByIdViewFunctionArgs): HttpAnnotationByIdView;
}

const httpAnnotationByIdView: HttpAnnotationByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<AnnotationProjection, ViewAnnotationByIdResult>({
      endpoint: "/view-annotation-by-id",
      body: { id },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpAnnotationByIdView };
