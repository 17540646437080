enum PersonalShopperRole {
  PS_SUPERVISED = "PS_SUPERVISED",
  CAN_EDIT_NOTE = "PS_CAN_EDIT_NOTE",
  PS_HEAD = "PS_HEAD",
  PS_EMPLOYEE = "PS_EMPLOYEE",
  PS_SENIOR = "PS_SENIOR",
  PS_CAN_EDIT_NOTE = "PS_CAN_EDIT_NOTE",
  PS_SUPERVISOR = "PS_SUPERVISOR",
}

export default PersonalShopperRole;
