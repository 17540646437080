import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const LOOKS_SET = "looks_set";

interface LooksSetPayload {
  readonly aggregateId: string;
}

interface LooksSet extends DomainEvent<typeof LOOKS_SET>, LooksSetPayload {}

interface LooksSetFunction {
  (payload: LooksSetPayload): LooksSet;
}

const looksSet: LooksSetFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: LOOKS_SET }),
  ...payload,
});

const isLooksSet = (event: DomainEvent<MessageName>): event is LooksSet => event.name === LOOKS_SET;

export type { LooksSet };
export { LOOKS_SET, looksSet, isLooksSet };
