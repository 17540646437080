import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { Customer } from "./customer";

const VIEW_CUSTOMER_BY_ID = "view_customer_by_id";

interface ViewCustomerByIdPayload {
  readonly id: string;
}

interface ViewCustomerById extends Query<typeof VIEW_CUSTOMER_BY_ID>, ViewCustomerByIdPayload {}

interface ViewCustomerByIdFunction {
  (payload: ViewCustomerByIdPayload): ViewCustomerById;
}

const viewCustomerById: ViewCustomerByIdFunction = (payload) => ({
  ...query({ name: VIEW_CUSTOMER_BY_ID }),
  ...payload,
});

type ViewCustomerByIdResult = Customer | null;

interface CustomerByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface CustomerByIdView {
  (args: CustomerByIdViewArgs): Promise<ViewCustomerByIdResult>;
}

interface ViewCustomerByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CustomerByIdView;
}

const viewCustomerByIdHandler: QueryHandlerFunction<
  ViewCustomerById,
  ViewCustomerByIdResult,
  ViewCustomerByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export { VIEW_CUSTOMER_BY_ID, viewCustomerById, viewCustomerByIdHandler };
export type { CustomerByIdView, ViewCustomerByIdResult };
