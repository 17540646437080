import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { Storage } from "../../../persistence/storage";
import { LookDto } from "../../../persistence/lookDto";
import { LooksGetFunction, LooksSaveFunction } from "../../../../domain/look/model/Looks";
import { storageLooksSet } from "./storageLooksSet";
import { Look } from "../../../../domain/look/model/Look";

interface ToDomainFunctionArgs {
  readonly aggregateId: string;
  readonly lookDto: LookDto[];
}

interface ToDomainFunction {
  (args: ToDomainFunctionArgs): Look;
}

const toDomain: ToDomainFunction = ({ aggregateId, lookDto }): Look => ({
  aggregateId,
  looks: lookDto,
  domainEvents: [],
});

interface StorageLooksGetFunctionArgs extends RepositoryGetFunctionArgs {
  readonly storage: Storage<LookDto[]>;
}

interface StorageLooksGetFunction extends LooksGetFunction<StorageLooksGetFunctionArgs> {}

const getLook: StorageLooksGetFunction =
  ({ storage: { read } }) =>
  async (aggregateId) => {
    const lookDto = await read(aggregateId);

    if (!lookDto) {
      throw new Error(`Look not found: ${aggregateId}`);
    }

    return toDomain({ aggregateId, lookDto });
  };

interface StorageLooksSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly storage: Storage<LookDto[]>;
}

interface StorageLooksSaveFunction extends LooksSaveFunction<StorageLooksSaveFunctionArgs> {}

const saveLook: StorageLooksSaveFunction =
  ({ storage }) =>
  async (aggregateRoot) => {
    await storageLooksSet({ storage })(aggregateRoot);
  };

export type { StorageLooksSaveFunction };
export { getLook, saveLook };
