import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import { BoxByBoxNumberView, ViewBoxByBoxNumberResult } from "../../../projection/box/viewBoxByBoxNumber";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { BoxDto, toBoxProjection } from "./box";

interface HttpBoxByBoxNumberView extends BoxByBoxNumberView {}

interface HttpBoxByBoxNumberViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxByBoxNumberViewFunction {
  (args: HttpBoxByBoxNumberViewFunctionArgs): HttpBoxByBoxNumberView;
}

interface ViewBoxByBoxNumberResponse {
  readonly result: BoxDto;
}

const httpBoxByBoxNumberView: HttpBoxByBoxNumberViewFunction =
  ({ httpPost }) =>
  async ({ boxNumber, signal }) =>
    await httpPost<ViewBoxByBoxNumberResponse, ViewBoxByBoxNumberResult>({
      endpoint: "/view-box-by-box-number",
      body: { box_number: boxNumber },
      signal,
      result: {
        error: null,
        success: (response) => toBoxProjection(camelcaseKeys(response.result)),
      },
    });

export { httpBoxByBoxNumberView };
