interface Message {
  readonly placeholder: string;
  readonly params?: Record<string, string>;
}

interface Alert {
  readonly key: string;
  readonly message: Message;
  readonly isBlocker: boolean;
}

enum AlertContext {
  PREVIEW = "PREVIEW",
  SELECTION = "SELECTION",
}

export type { Alert as AlertProjection };
export { AlertContext };
