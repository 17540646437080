import { FC } from "react";
import Modal from "../../../shared/ui/uiKit/layouts/modal/Modal";
import { ProductVariantWithProductInfo } from "./ProductVariantWithProductInfo";
import "./product-variant-with-product-info-modal.css";

interface ProductVariantWithProductInfoModalProps {
  readonly productVariantId: string;
  readonly visible: boolean;
  readonly onCancel: () => void;
}

const ProductVariantWithProductInfoModal: FC<ProductVariantWithProductInfoModalProps> = ({
  productVariantId,
  visible,
  onCancel,
}) => (
  <Modal
    className="product-variant-with-product-info-modal"
    label="product-variant-with-product-info-modal"
    visible={visible}
    onHide={onCancel}
  >
    <ProductVariantWithProductInfo productVariantId={productVariantId} />
  </Modal>
);

export { ProductVariantWithProductInfoModal };
