import { FC, useCallback } from "react";
import Button, { ButtonVariant } from "../../../../../../shared/ui/uiKit/atoms/button/Button";
import ShortlistFiltersI18n, { SHORTLIST_FILTERS_I18N_PREFIX } from "../../../../_i18n/ShortlistFiltersI18n";
import { I18nMessage } from "@lookiero/i18n-react";
import { TrackingClick, TrackingPage } from "../../../../../../shared/tracking/Tracker";
import { useTrackClick } from "../../../../../../shared/tracking/infrastructure/useTrackClick";
import useBoxSharedContext from "../../../../../views/_behaviors/useBoxSharedContext";
import { useShortlistFilteringPositives } from "../../shortlistFilterItem/_behavior/useShortlistFilteringPositives";
import { useViewPersonalShopper } from "../../../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import "./shortlist-filters-clear-button.css";

const ShortlistFiltersClearButton: FC = () => {
  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();
  const { state, filtering, onChange } = useShortlistFilteringPositives();
  const canClearSelection = state && state.length > 0;

  const trackClick = useTrackClick({
    boxId: box.id,
    psId: personalShopper?.id,
    section: TrackingPage.CATALOG,
    userId: box.customerId,
  });

  const handleOnClear = useCallback(() => {
    trackClick({ clickId: TrackingClick.CLEAR_FILTERS });

    const filteringPositivesFiltered = state.filter(({ id: stateId }) =>
      filtering.some(({ id: positiveId }) => positiveId === stateId),
    );
    onChange({ state: filteringPositivesFiltered });
  }, [filtering, onChange, state, trackClick]);

  return (
    <div className="shortlist-filters-clear-button" data-testid="shortlist-filters-clear-button">
      <Button
        className="shortlist-filters-clear-button__button"
        disabled={!canClearSelection}
        variant={canClearSelection ? ButtonVariant.PRIMARY : ButtonVariant.BASIC}
        onClick={handleOnClear}
      >
        <I18nMessage id={ShortlistFiltersI18n.CLEAR} prefix={SHORTLIST_FILTERS_I18N_PREFIX} />
      </Button>
    </div>
  );
};

export { ShortlistFiltersClearButton };
