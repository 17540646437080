import {
  CapacityCalendarByCriteriaView,
  ViewCapacityCalendarByCriteriaResult,
} from "../../../projection/capacityCalendar/viewCapacityCalendarByCriteria";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { CapacityCalendarDto, toCapacityCalendarProjection } from "./capacityCalendar";

interface HttpCapacityCalendarByCriteriaView extends CapacityCalendarByCriteriaView {}

interface HttpCapacityCalendarByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCapacityCalendarByCriteriaViewFunction {
  (args: HttpCapacityCalendarByCriteriaViewFunctionArgs): HttpCapacityCalendarByCriteriaView;
}

const httpCapacityCalendarByCriteriaView: HttpCapacityCalendarByCriteriaViewFunction =
  ({ httpPost }) =>
  async ({ from, markets, page, perPage, segments, to, signal }) =>
    await httpPost<CapacityCalendarDto[], ViewCapacityCalendarByCriteriaResult>({
      endpoint: "/view-capacity-calendar-by-criteria",
      body: {
        from,
        markets,
        page,
        perPage,
        segments,
        to,
      },
      signal,
      result: {
        error: null,
        success: (response) => response.map(toCapacityCalendarProjection),
      },
    });

export { httpCapacityCalendarByCriteriaView };
