import { command, Command } from "@lookiero/messaging";
import { Segment } from "../../../projection/segment/segment";

const SYNCHRONIZE_CAPACITY_CALENDAR = "synchronize_capacity_calendar";

interface SynchronizeCapacityCalendarPayload {
  readonly aggregateId: string;
  readonly segments: Segment[];
}

interface SynchronizeCapacityCalendar
  extends Command<typeof SYNCHRONIZE_CAPACITY_CALENDAR>,
    SynchronizeCapacityCalendarPayload {}

interface SynchronizeCapacityCalendarFunction {
  (payload: SynchronizeCapacityCalendarPayload): SynchronizeCapacityCalendar;
}

const synchronizeCapacityCalendar: SynchronizeCapacityCalendarFunction = ({ aggregateId, ...payload }) => ({
  ...command({ name: SYNCHRONIZE_CAPACITY_CALENDAR, aggregateId }),
  ...payload,
});

export type { SynchronizeCapacityCalendar };
export { SYNCHRONIZE_CAPACITY_CALENDAR, synchronizeCapacityCalendar };
