import { ProcessManagerFunction } from "@lookiero/messaging";
import { ProductVariantSelected } from "../../selection/model/ProductVariantSelected";
import {
  ViewSelectionOrderByBoxNumberResult,
  viewSelectionOrderByBoxNumber,
} from "../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";
import { sortSelectionOrder } from "../command/sortSelectionOrder";

interface SortSelectionWhenProductVariantSelectedFunction extends ProcessManagerFunction<ProductVariantSelected> {}

const sortSelectionWhenProductVariantSelected: SortSelectionWhenProductVariantSelectedFunction =
  ({ commandBus, queryBus }) =>
  async ({ aggregateId, boxNumber, productVariantId, productVariantIds }) => {
    const selectionOrder: ViewSelectionOrderByBoxNumberResult = await queryBus(
      viewSelectionOrderByBoxNumber({ boxNumber }),
    );

    await commandBus(
      sortSelectionOrder({
        aggregateId,
        boxNumber,
        productVariantIds: selectionOrder ? [...selectionOrder.productVariantIds, productVariantId] : productVariantIds,
      }),
    );
  };

export { sortSelectionWhenProductVariantSelected };
