import { FC } from "react";
import { ActivityTimeCounter } from "../../../componentLibrary/templates/activityTimeCounter/ActivityTimeCounter";
import { useActivityTime } from "./useActivityTime";
import { useViewBoxPreviewSelectionRateByBoxId } from "./behaviors/useViewBoxPreviewSelectionRateByBoxId";
import { actionTimeForCountryByPreviewSelectionRate } from "./actionTime/actionTimeForCountryByPreviewSelectionRate";
import { useViewPersonalShopper } from "../../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";

interface ActivityTimeProps {
  readonly boxId: string;
}

const ActivityTime: FC<ActivityTimeProps> = ({ boxId }) => {
  const { elapsedTime, previewedElapsedTime, enabled } = useActivityTime();
  const [personalShopper] = useViewPersonalShopper();
  const selectionRate = useViewBoxPreviewSelectionRateByBoxId({ boxId });
  const actionTime = actionTimeForCountryByPreviewSelectionRate({
    country: personalShopper?.market,
    selectionRate,
  });

  return (
    <ActivityTimeCounter
      actionTime={actionTime}
      elapsedTime={elapsedTime}
      enabled={enabled}
      previewedElapsedTime={previewedElapsedTime}
    />
  );
};

export { ActivityTime };
