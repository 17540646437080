import { isSelectionOrderSorted } from "../../../../domain/selectionOrder/model/selectionOrderSorted";
import { SelectionOrder } from "../../../../domain/selectionOrder/model/SelectionOrder";
import { SelectionOrderDto } from "../../../persistence/selectionOrderDto";
import { StorageSelectionOrdersSaveFunction } from "./storageSelectionOrders";

interface FromDomainFunction {
  (selectionOrder: SelectionOrder): SelectionOrderDto;
}

const fromDomain: FromDomainFunction = ({ aggregateId, boxNumber, productVariantIds }) => ({
  selectionId: aggregateId,
  boxNumber,
  productVariantIds,
});

interface StorageSelectionOrdersSortFunction extends StorageSelectionOrdersSaveFunction {}

const storageSelectionOrdersSort: StorageSelectionOrdersSortFunction =
  ({ storage: { write } }) =>
  async (aggregateRoot) => {
    const selectionOrderSorted = aggregateRoot.domainEvents.find(isSelectionOrderSorted);

    if (!selectionOrderSorted) {
      return;
    }

    await write(aggregateRoot.boxNumber, fromDomain(aggregateRoot));
  };

export { storageSelectionOrdersSort };
