import classNames from "classnames";
import { FC, ReactNode, useCallback } from "react";
import Button, { ButtonVariant } from "../../atoms/button/Button";
import "./option-selector.css";

type OptionSelectorOptionProps = {
  readonly value: string;
  readonly children: ReactNode;
  readonly selected: boolean;
  readonly onChange: (value: string) => void;
};
const OptionSelectorOption: FC<OptionSelectorOptionProps> = ({
  value,
  children,
  selected,
  onChange,
}: OptionSelectorOptionProps) => {
  const handleOnClick = useCallback(() => onChange(value), [onChange, value]);

  return (
    <li className="option-selector__item">
      <Button
        aria-label={value}
        type="button"
        variant={selected ? ButtonVariant.PRIMARY : ButtonVariant.BASIC}
        onClick={handleOnClick}
      >
        {children}
      </Button>
    </li>
  );
};

type OptionSelectorProps = {
  readonly className?: string;
  readonly options: {
    readonly value: string;
    readonly children: ReactNode;
  }[];
  readonly value: string;
  readonly onChange: (value: string) => void;
};
const OptionSelector: FC<OptionSelectorProps> = ({ className, options, value, onChange }) => (
  <ul className={classNames("option-selector", className)}>
    {options.map((option) => (
      <OptionSelectorOption
        key={option.value}
        selected={option.value === value}
        value={option.value}
        onChange={onChange}
      >
        {option.children}
      </OptionSelectorOption>
    ))}
  </ul>
);

export default OptionSelector;
