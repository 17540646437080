import { isCapacityCalendarSynchronized } from "../../../../domain/capacityCalendar/model/capacityCalendarSynchronized";
import { HttpCapacityCalendarsSaveFunction } from "./httpCapacityCalendars";

interface HttpCapacityCalendarsSynchronizedFunction extends HttpCapacityCalendarsSaveFunction {}

const httpCapacityCalendarsSynchronized: HttpCapacityCalendarsSynchronizedFunction =
  ({ httpPost }) =>
  async ({ domainEvents, segments }) => {
    const capacityCalendarSynchronized = domainEvents.find(isCapacityCalendarSynchronized);

    if (!capacityCalendarSynchronized) {
      return;
    }

    await httpPost({
      endpoint: "/synchronize-capacity-calendar",
      body: {
        segments,
      },
    });
  };

export { httpCapacityCalendarsSynchronized };
