const today = () => new Date(new Date().toISOString().replace(/T.*/, ""));

interface NextBizDayFunction {
  (date?: Date): Date;
}

const nextBizDay: NextBizDayFunction = (date = today()) => {
  const offsetDays = date.getDay() >= 5 ? 8 - date.getDay() : 1;

  return new Date(date.getTime() + offsetDays * 1000 * 60 * 60 * 24);
};

export { nextBizDay };
