import { command, Command } from "@lookiero/messaging";

const SUBMIT_STOCK_SURVEY = "submit_stock_survey";

interface SubmitStockSurveyPayload {
  readonly aggregateId: string;
  readonly boxId: string;
}

interface SubmitStockSurvey extends Command<typeof SUBMIT_STOCK_SURVEY>, SubmitStockSurveyPayload {}

interface SubmitStockSurveyFunction {
  (payload: SubmitStockSurveyPayload): SubmitStockSurvey;
}

const submitStockSurvey: SubmitStockSurveyFunction = ({ aggregateId, ...payload }) => ({
  ...command({ name: SUBMIT_STOCK_SURVEY, aggregateId }),
  ...payload,
});

export type { SubmitStockSurvey };
export { SUBMIT_STOCK_SURVEY, submitStockSurvey };
