import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { HistoryNoteProjection } from "./historyNote";

const LIST_HISTORY_NOTES_BY_CUSTOMER_ID = "list_history_notes_by_customer_id";

interface ListHistoryNotesByCustomerIdPayload {
  readonly customerId: string;
}

interface ListHistoryNotesByCustomerId
  extends Query<typeof LIST_HISTORY_NOTES_BY_CUSTOMER_ID>,
    ListHistoryNotesByCustomerIdPayload {}

interface ListHistoryNotesByCustomerIdFunction {
  (payload: ListHistoryNotesByCustomerIdPayload): ListHistoryNotesByCustomerId;
}

const listHistoryNotesByCustomerId: ListHistoryNotesByCustomerIdFunction = (payload) => ({
  ...query({ name: LIST_HISTORY_NOTES_BY_CUSTOMER_ID }),
  ...payload,
});

type ListHistoryNotesByCustomerIdResult = HistoryNoteProjection[] | null;

interface HistoryNotesByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface HistoryNotesByCustomerIdView {
  (args: HistoryNotesByCustomerIdViewArgs): Promise<ListHistoryNotesByCustomerIdResult>;
}

interface ListHistoryNotesByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: HistoryNotesByCustomerIdView;
}

const listHistoryNotesByCustomerIdHandler: QueryHandlerFunction<
  ListHistoryNotesByCustomerId,
  ListHistoryNotesByCustomerIdResult,
  ListHistoryNotesByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { HistoryNotesByCustomerIdView, ListHistoryNotesByCustomerId, ListHistoryNotesByCustomerIdResult };
export { LIST_HISTORY_NOTES_BY_CUSTOMER_ID, listHistoryNotesByCustomerId, listHistoryNotesByCustomerIdHandler };
