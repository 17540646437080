import { FC, ReactNode, useCallback, useMemo } from "react";
import classNames from "classnames";
import { Selection } from "../selection/Selection";
import Note from "../note/Note";
import SelectionAndNoteActions from "./selectionAndNoteActions/SelectionAndNoteActions";
import UISettings, { UISettingsToggleNote } from "../../_settings/UISettings";
import { ProductVariantSelectionProvider } from "../_behaviors/useProductVariantSelection";
import { useTrackClick } from "../../../shared/tracking/infrastructure/useTrackClick";
import { NoteAction, TrackingClick, TrackingPage } from "../../../shared/tracking/Tracker";
import useBoxSharedContext from "../_behaviors/useBoxSharedContext";
import { useViewUiSettingByKey } from "../../../core/infrastructure/projection/uiSetting/react/useViewUiSettingByKey";
import { useUpdateUiSetting } from "../../../core/infrastructure/domain/uiSetting/react/useUpdateUiSetting";
import { useViewPersonalShopper } from "../../../core/infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import closedBoxStatuses from "../../../core/domain/box/model/ClosedBoxStatuses";
import { StockSurveyTypeform } from "./stockSurveyTypeform/StockSurveyTypeform";
import { AutomaticSelectionTypeform } from "./automaticSelectionTypeform/AutomaticSelectionTypeform";
import { useIsAutomaticSelectionTypeformActive } from "./automaticSelectionTypeform/useIsAutomaticSelectionTypeformActive";
import { useViewBoxPreviewByBoxId } from "../../../core/infrastructure/projection/boxPreview/react/useViewBoxPreviewByBoxId";
import "./selection-and-note.css";

interface SelectionAndNoteProps {
  readonly children?: ReactNode;
}

const SelectionAndNote: FC<SelectionAndNoteProps> = ({ children }) => {
  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();

  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId: box.id });
  const isBoxClosed = closedBoxStatuses.includes(box.status);

  const automaticSelectionUiSettingKey = useMemo(
    () => `${UISettings.AUTOMATIC_SELECTION_TYPEFORM}_${box.id}`,
    [box.id],
  );
  const isAutomaticSelectionTypeformActive = useIsAutomaticSelectionTypeformActive({
    personalShopperId: personalShopper?.id,
    uiSettingKey: automaticSelectionUiSettingKey,
  });

  const [toggleNoteUiSetting] = useViewUiSettingByKey({ key: UISettings.TOGGLE_NOTE });
  const [updateUiSetting] = useUpdateUiSetting();

  const isNoteCollapsed = toggleNoteUiSetting?.value === UISettingsToggleNote.CLOSE;
  const handleOnToggleNote = useCallback(
    () =>
      updateUiSetting({
        key: UISettings.TOGGLE_NOTE,
        value: isNoteCollapsed ? UISettingsToggleNote.OPEN : UISettingsToggleNote.CLOSE,
      }),
    [isNoteCollapsed, updateUiSetting],
  );

  const trackClick = useTrackClick({
    section: TrackingPage.SELECTION,
    psId: personalShopper?.id,
    boxId: box.id,
    userId: box.customerId,
  });
  const handleOnCloseBox = useCallback(() => {
    trackClick({
      clickId: TrackingClick.CLOSE_BOX,
    });
  }, [trackClick]);
  const handleOnClickNoteEditorToolbarExtraActions = useCallback(
    (action: NoteAction) => trackClick({ clickId: TrackingClick.NOTE_ACTION, action }),
    [trackClick],
  );

  return (
    <section
      aria-label="selection-and-note"
      className={classNames("selection-and-note", { "selection-and-note--expanded": isNoteCollapsed })}
    >
      <ProductVariantSelectionProvider>
        <Selection isNoteCollapsed={isNoteCollapsed} onToggleNote={handleOnToggleNote} />
        <section className="selection-and-note__note-and-actions">
          <Note
            onClickNoteEditorToolbarExtraActions={handleOnClickNoteEditorToolbarExtraActions}
            onToggle={handleOnToggleNote}
          />
          <SelectionAndNoteActions onCloseBox={handleOnCloseBox} />
        </section>
        <section className="selection-and-note__routes">{children}</section>

        {personalShopper?.id &&
          !isBoxClosed &&
          boxPreview &&
          (isAutomaticSelectionTypeformActive ? (
            <AutomaticSelectionTypeform
              boxId={box.id}
              personalShopperId={personalShopper.id}
              uiSettingKey={automaticSelectionUiSettingKey}
            />
          ) : (
            <StockSurveyTypeform boxId={box.id} personalShopperId={personalShopper.id} />
          ))}
      </ProductVariantSelectionProvider>
    </section>
  );
};

export { SelectionAndNote };
