const ANNOTATIONS_I18N_PREFIX = "labs.annotations.";

enum AnnotationsI18n {
  TITLE = "title",
  POSITIVES = "positives",
  NEGATIVES = "negatives",
  SAVE = "save",
  UPDATED_ON = "updated_on",
  ADD = "add",
}

export { ANNOTATIONS_I18N_PREFIX };

export default AnnotationsI18n;
