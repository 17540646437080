import { command, Command } from "@lookiero/messaging";

const UPDATE_UI_SETTING = "update_ui_setting";

interface UpdateUiSettingPayload {
  readonly aggregateId: string;
  readonly key: string;
  readonly value: unknown;
}

interface UpdateUiSetting extends Command<typeof UPDATE_UI_SETTING>, UpdateUiSettingPayload {}

interface UpdateUiSettingFunction {
  (payload: UpdateUiSettingPayload): UpdateUiSetting;
}

const updateUiSetting: UpdateUiSettingFunction = ({ aggregateId, ...payload }) => ({
  ...command({
    aggregateId,
    name: UPDATE_UI_SETTING,
  }),
  ...payload,
});

export type { UpdateUiSetting };
export { UPDATE_UI_SETTING, updateUiSetting };
