import Country from "../../domain/country/model/Country";

import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { PlannedBox, PlannedBoxesAssignment, PlannedBoxTag } from "./plannedBox";

const SEARCH_PLANNED_BOXES_BY_CRITERIA = "search_planned_boxes_by_criteria";

interface SearchPlannedBoxesByCriteriaPayload {
  readonly plannedFor: Date;
  readonly country: Country | Country[] | undefined;
  readonly psNumber: string | undefined;
  readonly boxNumber: string | undefined;
  readonly tag: PlannedBoxTag[] | undefined;
  readonly assignment: PlannedBoxesAssignment | PlannedBoxesAssignment[] | undefined;
  readonly page: number;
  readonly perPage: number;
}

interface SearchPlannedBoxesByCriteria
  extends Query<typeof SEARCH_PLANNED_BOXES_BY_CRITERIA>,
    SearchPlannedBoxesByCriteriaPayload {}

interface SearchPlannedBoxesByCriteriaFunction {
  (payload: SearchPlannedBoxesByCriteriaPayload): SearchPlannedBoxesByCriteria;
}

const searchPlannedBoxesByCriteria: SearchPlannedBoxesByCriteriaFunction = (payload) => ({
  ...query({ name: SEARCH_PLANNED_BOXES_BY_CRITERIA }),
  ...payload,
});

type SearchPlannedBoxesByCriteriaResult = PlannedBox[];

interface PlannedBoxesByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly plannedFor: Date;
  readonly country: Country | Country[] | undefined;
  readonly psNumber: string | undefined;
  readonly boxNumber: string | undefined;
  readonly tag: PlannedBoxTag[] | undefined;
  readonly assignment: PlannedBoxesAssignment | PlannedBoxesAssignment[] | undefined;
  readonly page: number;
  readonly perPage: number;
}

interface PlannedBoxesByCriteriaView {
  (args: PlannedBoxesByCriteriaViewArgs): Promise<SearchPlannedBoxesByCriteriaResult>;
}

interface SearchPlannedBoxesByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PlannedBoxesByCriteriaView;
}

const searchPlannedBoxesByCriteriaHandler: QueryHandlerFunction<
  SearchPlannedBoxesByCriteria,
  SearchPlannedBoxesByCriteriaResult,
  SearchPlannedBoxesByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ plannedFor, country, psNumber, boxNumber, assignment, tag, page, perPage }) =>
    view({ plannedFor, country, psNumber, boxNumber, assignment, tag, page, perPage, signal });

export type { SearchPlannedBoxesByCriteria, PlannedBoxesByCriteriaView, SearchPlannedBoxesByCriteriaResult };
export { SEARCH_PLANNED_BOXES_BY_CRITERIA, searchPlannedBoxesByCriteria, searchPlannedBoxesByCriteriaHandler };
