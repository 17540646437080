import { useCallback, ChangeEvent, ReactNode, FC, MouseEventHandler } from "react";
import classNames from "classnames";
import Icon, { IconVariant } from "../../atoms/icon/Icon";
import Text from "../../atoms/text/Text";
import Error from "../../atoms/error/Error";
import "./checkbox.css";

enum CheckboxState {
  DEFAULT = "default",
  CHECKED = "checked",
  INDETERMINATE = "indeterminate",
}

interface CheckboxProps {
  readonly className?: string;
  readonly children?: ReactNode;
  readonly label?: ReactNode;
  readonly state?: CheckboxState;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
  readonly onChange: (checked: boolean) => void;
  readonly error?: string;
}
const Checkbox: FC<CheckboxProps> = ({
  className,
  children = null,
  label,
  state = CheckboxState.DEFAULT,
  disabled = false,
  readOnly = false,
  onChange,
  error,
}: CheckboxProps) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => !readOnly && onChange(event.currentTarget.checked),
    [readOnly, onChange],
  );
  const handleOnClick: MouseEventHandler<HTMLLabelElement> = useCallback((event) => event.stopPropagation(), []);
  const iconVariant = state === CheckboxState.INDETERMINATE ? IconVariant.DASH : IconVariant.BASIC_CHECK;

  return (
    <label
      className={classNames(
        "checkbox",
        `checkbox--${state}`,
        { "checkbox--disabled": disabled, "checkbox--readonly": readOnly },
        className,
      )}
      onClick={handleOnClick}
    >
      <input
        checked={state === CheckboxState.CHECKED || state === CheckboxState.INDETERMINATE}
        className="checkbox__check"
        disabled={disabled}
        readOnly={readOnly}
        type="checkbox"
        onChange={handleOnChange}
      />
      <span className="checkbox__custom">
        {children}
        <Icon variant={iconVariant} />
      </span>
      {label && <Text className="checkbox__label">{label}</Text>}
      <Error error={error} />
    </label>
  );
};

export { CheckboxState };

export default Checkbox;
