import classNames from "classnames";
import { FC, ReactNode } from "react";
import Link from "../../atoms/link/Link";
import "./router-tabs.css";

type LinkParam = {
  readonly url: string;
  readonly label: ReactNode;
};

type RouterTabsProps = {
  readonly className?: string;
  readonly links: LinkParam[];
  readonly actions: ReactNode;
  readonly children: ReactNode;
};

const RouterTabs: FC<RouterTabsProps> = ({ className, links, actions, children }: RouterTabsProps) => {
  return (
    <div className={classNames("router-tabs", className)}>
      <div className="router-tabs__header">
        <ul aria-label="router-tabs-list" className="router-tabs__list" role="tablist">
          {links.map((link) => (
            <Link key={link.url} className="router-tabs__list-item" to={link.url} isNav replace>
              {link.label}
            </Link>
          ))}
        </ul>
        {actions}
      </div>
      <div className="router-tabs__panels">{children}</div>
    </div>
  );
};

export default RouterTabs;
