const SELECTION_I18N_PREFIX = "labs.selection.";

enum SelectionI18n {
  ALERTS_TITLE = "alerts.title",
  CLOSE_BOX = "close_box",
  BOX_CLOSED = "box_closed",
  SEND_BOX_TO_SUPERVISE = "send_box_to_supervise",
  CANDIDATE_LABEL = "candidate.label",
  MAX_LOOKS_EXCEEDED = "max_looks_exceeded",
  MANDATORY_LOOKS = "mandatory_looks",
  CREATE_LOOK = "look.create",
  CANCEL_LOOK = "look.cancel",
  SAVE_LOOK = "look.save",
  REMOVE_LOOK_CONFIRMATION_TITLE = "look.remove_confirmation_title",
  REMOVE_LOOK_CONFIRMATION_DESCRIPTION = "look.remove_confirmation_description",
  REMOVE_LOOK_CONFIRMATION_CONFIRM = "look.remove_confirmation_confirm",
  REMOVE_LOOK_CONFIRMATION_CANCEL = "look.remove_confirmation_cancel",
  DESELECT_PRODUCT_VARIANT_WITHIN_LOOK_CONFIRMATION_TITLE = "deselect_product_variant_within_look_confirmation_title",
  DESELECT_PRODUCT_VARIANT_WITHIN_LOOK_CONFIRMATION_DESCRIPTION = "deselect_product_variant_within_look_confirmation_description",
  DESELECT_PRODUCT_VARIANT_WITHIN_LOOK_CONFIRMATION_CONFIRM = "deselect_product_variant_within_look_confirmation_confirm",
  DESELECT_PRODUCT_VARIANT_WITHIN_LOOK_CONFIRMATION_CANCEL = "deselect_product_variant_within_look_confirmation_cancel",
  CAN_NOT_SELECT_PRODUCT_VARIANT_NOTIFICATION = "notification.can_not_select_product_variant",
  CAN_NOT_DESELECT_PRODUCT_VARIANT_NOTIFICATION = "notification.can_not_deselect_product_variant",
  CAN_NOT_REPLACE_PRODUCT_VARIANT_NOTIFICATION = "notification.can_not_replace_product_variant",
  PRODUCT_ADDED_TO_SELECTION_NOTIFICATION = "notification.product_added_to_selection",
  COLOR_CHANGED = "color-changed",
  STOCK_SURVEY_TYPEFORM_BUTTON = "typeform.stock_survey.button",
  NOTE_ASSISTANT_TYPEFORM_BUTTON = "typeform.note_assistant.button",
  AUTOMATIC_SELECTION_FINISHED_TOOLTIP = "automatic_selection.finished_tooltip",
  AUTOMATIC_SELECTION_TYPEFORM_BUTTON = "typeform.automatic_selection.button",
}

export { SELECTION_I18N_PREFIX };

export default SelectionI18n;
