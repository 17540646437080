import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface UseNavigateBackWithFallbackFunctionArgs {
  readonly fallback: string;
}

interface UseNavigateBackWithFallbackFunction {
  (args: UseNavigateBackWithFallbackFunctionArgs): () => void;
}

const useNavigateBackWithFallback: UseNavigateBackWithFallbackFunction = ({ fallback }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBack = useCallback(() => {
    if (location.key) {
      navigate(-1);
    } else {
      navigate(fallback);
    }
  }, [fallback, location.key, navigate]);

  return navigateBack;
};

export { useNavigateBackWithFallback };
