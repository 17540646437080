import { CommandStatus } from "@lookiero/messaging-react";

enum AsyncActionState {
  DEFAULT = "default",
  PENDING = "pending",
  ERROR = "error",
  SUCCESS = "success",
}

const asyncActionStateForCommandStatus: Record<CommandStatus, AsyncActionState> = {
  [CommandStatus.ERROR]: AsyncActionState.ERROR,
  [CommandStatus.IDLE]: AsyncActionState.DEFAULT,
  [CommandStatus.LOADING]: AsyncActionState.PENDING,
  [CommandStatus.SUCCESS]: AsyncActionState.SUCCESS,
};

export { asyncActionStateForCommandStatus };
export default AsyncActionState;
