import { DomainEvent, MessageName, Query } from "@lookiero/messaging";
import { MessagingContextId, getContext } from "@lookiero/messaging-react/bootstrap";
import { useQuery } from "@lookiero/messaging-react/useQuery";
import { QueryStatus } from "@lookiero/messaging-react/useQuery";
import { DomainEvent as DomainEventJs } from "@lookiero/messaging.js";
import useEvent from "@lookiero/messaging.js/dist/infrastructure/delivery/ui/react/useEvent";
import { UseQueryOptions, useQueryClient } from "@tanstack/react-query";

/**
 * import { useQuery } from "@lookiero/messaging-react/useQuery";
 * import { QueryStatus } from "@lookiero/messaging-react/useQuery";
 *
 * Modules are imported directly from their module files because
 * JEST is loading the cjs file on test-time otherwise
 *
 */

type QueryKey = string | unknown[];

interface GetConformantIdFunction {
  (queryKey: QueryKey): unknown[];
}

const getConformantId: GetConformantIdFunction = (queryKey) => (typeof queryKey === "string" ? [queryKey] : queryKey);

interface GetDefaultQueryIdentifierFunction {
  (query: Query<MessageName>): string[];
}

const getDefaultQueryIdentifier: GetDefaultQueryIdentifierFunction = (query) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { messageId, occurredOn, ...queryPayload } = query;

  return [query.name, JSON.stringify(queryPayload)];
};

interface UseMessagingJsBridgeQueryFunctionArgs<R, E> {
  readonly id?: QueryKey;
  readonly query: Query<MessageName>;
  readonly contextId?: MessagingContextId;
  readonly messagingJsInvalidation: (event: DomainEventJs) => boolean;
  readonly invalidation?: (event: DomainEvent<MessageName>) => boolean;
  readonly invalidationId?: QueryKey;
  readonly invalidationDelay?: number;
  readonly invalidationRefetchType?: "all" | "active" | "inactive" | "none";
  readonly options?: UseQueryOptions<R, E, R, unknown[]>;
}

type UseMessagingJsBridgeQueryFunctionResult<R> = [projection: R | undefined, status: QueryStatus];

interface UseMessagingJsBridgeQueryFunction {
  <R, E = Error>(args: UseMessagingJsBridgeQueryFunctionArgs<R, E>): UseMessagingJsBridgeQueryFunctionResult<R>;
}

const useMessagingJsBridgeQuery: UseMessagingJsBridgeQueryFunction = ({
  id,
  query,
  contextId,
  messagingJsInvalidation,
  invalidation,
  invalidationId,
  invalidationDelay,
  invalidationRefetchType,
  options,
}) => {
  const { queryClient: queryClientContext } = getContext({ id: contextId });
  const queryClient = useQueryClient({ context: queryClientContext });

  const queryId = id ? getConformantId(id) : getDefaultQueryIdentifier(query);
  const invalidateQueriesId = invalidationId ? getConformantId(invalidationId) : queryId;

  useEvent({
    callback: () => queryClient.invalidateQueries(invalidateQueriesId, { refetchType: invalidationRefetchType }),
    filter: messagingJsInvalidation,
    delay: invalidationDelay,
  });

  return useQuery({
    id: queryId,
    invalidationId: invalidateQueriesId,
    query,
    contextId,
    invalidation,
    options,
  });
};

export { useMessagingJsBridgeQuery };
