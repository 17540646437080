import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { RegisterActivityTime } from "../command/registerActivityTime";
import { activityTimeRegistered } from "./activityTimeRegistered";
import { RegisterPreviewedWatermark } from "../command/registerPreviewedWatermark";
import { activityTimePreviewed } from "./activityTimePreviewed";

enum ActivityTimeSection {
  CUSTOMER = "customer",
  SHORTLIST = "shortlist",
  SELECTION = "selection",
  NONE = "none",
}

interface ActivityTime extends AggregateRoot {
  readonly psId: string;
  readonly boxId: string;
  readonly init?: number;
  readonly end?: number;
  readonly section?: ActivityTimeSection;
  readonly watermark?: number;
  readonly userIsActive?: boolean;
}

const registerActivityTimeHandler: CommandHandlerFunction<RegisterActivityTime, ActivityTime> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxId, psId, init, end, section, userIsActive } = command;

    return {
      ...aggregateRoot,
      boxId,
      psId,
      init,
      end,
      section,
      userIsActive,
      domainEvents: [activityTimeRegistered({ aggregateId })],
    };
  };

const registerPreviewedWatermarkHandler: CommandHandlerFunction<RegisterPreviewedWatermark, ActivityTime> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, boxId, psId, watermark } = command;

    return {
      ...aggregateRoot,
      boxId,
      psId,
      watermark,
      domainEvents: [activityTimePreviewed({ aggregateId })],
    };
  };

export { registerActivityTimeHandler, registerPreviewedWatermarkHandler, ActivityTimeSection };
export type { ActivityTime };
