import { EditorState, ContentState, ContentBlock, BlockMap } from "draft-js";
import emojiRegex from "emoji-regex";
import { filterEditorState } from "draftjs-filters";

const emojiRegExp = emojiRegex();

const removeText = (editorState: EditorState, regex: RegExp): EditorState => {
  const content: ContentState = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const blocks = blockMap.map((block: ContentBlock | undefined) => {
    if (!block) {
      return block;
    }

    const text = block.getText();
    const newText = text.replace(regex, "");

    return text !== newText ? block.set("text", newText) : block;
  });

  const newContent = content.merge({
    blockMap: blockMap.merge(blocks as BlockMap),
  });

  return EditorState.set(editorState, { currentContent: newContent });
};

const filterNoteContent = (state: EditorState): EditorState =>
  removeText(
    filterEditorState(
      {
        blocks: ["paragraph"],
        styles: ["BOLD"],
        entities: [],
        maxNesting: 0,
        whitespacedCharacters: [],
      },
      state,
    ),
    emojiRegExp,
  );

export default filterNoteContent;
