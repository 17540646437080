import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

const IS_AUTOMATIC_SELECTION_ENABLED = "is_automatic_selection_enabled";

interface IsAutomaticSelectionEnabledPayload {
  readonly psId: string;
}

interface IsAutomaticSelectionEnabled
  extends Query<typeof IS_AUTOMATIC_SELECTION_ENABLED>,
    IsAutomaticSelectionEnabledPayload {}

interface IsAutomaticSelectionEnabledFunction {
  (payload: IsAutomaticSelectionEnabledPayload): IsAutomaticSelectionEnabled;
}

const isAutomaticSelectionEnabled: IsAutomaticSelectionEnabledFunction = (payload) => ({
  ...query({ name: IS_AUTOMATIC_SELECTION_ENABLED }),
  ...payload,
});

type IsAutomaticSelectionEnabledResult = boolean;

interface IsAutomaticSelectionEnabledViewArgs extends CancelableQueryViewArgs {
  readonly psId: string;
}

interface IsAutomaticSelectionEnabledView {
  (args: IsAutomaticSelectionEnabledViewArgs): Promise<IsAutomaticSelectionEnabledResult>;
}

interface IsAutomaticSelectionEnabledHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: IsAutomaticSelectionEnabledView;
}

const isAutomaticSelectionEnabledHandler: QueryHandlerFunction<
  IsAutomaticSelectionEnabled,
  IsAutomaticSelectionEnabledResult,
  IsAutomaticSelectionEnabledHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ psId }) =>
    view({ psId, signal });

export type { IsAutomaticSelectionEnabledView, IsAutomaticSelectionEnabled, IsAutomaticSelectionEnabledResult };
export { IS_AUTOMATIC_SELECTION_ENABLED, isAutomaticSelectionEnabled, isAutomaticSelectionEnabledHandler };
