import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { NoteTemplateCategory } from "./noteTemplateCategory";

const LIST_NOTE_TEMPLATE_CATEGORIES_BY_PS_ID = "list_note_template_categories_by_ps_id";

interface ListNoteTemplateCategoriesByPsIdPayload {
  readonly personalShopperId: string;
}

interface ListNoteTemplateCategoriesByPsId
  extends Query<typeof LIST_NOTE_TEMPLATE_CATEGORIES_BY_PS_ID>,
    ListNoteTemplateCategoriesByPsIdPayload {}

interface ListNoteTemplateCategoriesByPsIdFunction {
  (payload: ListNoteTemplateCategoriesByPsIdPayload): ListNoteTemplateCategoriesByPsId;
}

const listNoteTemplateCategoriesByPsId: ListNoteTemplateCategoriesByPsIdFunction = (payload) => ({
  ...query({ name: LIST_NOTE_TEMPLATE_CATEGORIES_BY_PS_ID }),
  ...payload,
});

type ListNoteTemplateCategoriesByPsIdResult = NoteTemplateCategory[];

interface NoteTemplateCategoriesByPsIdViewArgs extends CancelableQueryViewArgs {
  readonly personalShopperId: string;
}

interface NoteTemplateCategoriesByPsIdView {
  (args: NoteTemplateCategoriesByPsIdViewArgs): Promise<ListNoteTemplateCategoriesByPsIdResult>;
}

interface ListNoteTemplateCategoriesByPsIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: NoteTemplateCategoriesByPsIdView;
}

const listNoteTemplateCategoriesByPsIdHandler: QueryHandlerFunction<
  ListNoteTemplateCategoriesByPsId,
  ListNoteTemplateCategoriesByPsIdResult,
  ListNoteTemplateCategoriesByPsIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ personalShopperId }) =>
    view({ personalShopperId, signal });

export type {
  ListNoteTemplateCategoriesByPsId,
  NoteTemplateCategoriesByPsIdView,
  ListNoteTemplateCategoriesByPsIdResult,
};
export {
  LIST_NOTE_TEMPLATE_CATEGORIES_BY_PS_ID,
  listNoteTemplateCategoriesByPsId,
  listNoteTemplateCategoriesByPsIdHandler,
};
