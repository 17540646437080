import { FC, useCallback, useState } from "react";
import Modal from "../../../../../shared/ui/uiKit/layouts/modal/Modal";
import { useTrackDetailView } from "../../../../../shared/tracking/infrastructure/useTrackDetailView";
import { useTrackCheckLastBoxesInfo } from "../../../../../shared/tracking/infrastructure/useTrackCheckLastBoxesInfo";
import { TrackingPage, TrackingReviewedInfo } from "../../../../../shared/tracking/Tracker";
import { BoxPreviewDetail } from "../../../../views/boxPreviewDetail/BoxPreviewDetail";
import { ReturnQuestionProjection } from "../../../../../core/projection/returnQuestion/returnQuestion";
import { PersonalShopperProjection } from "../../../../../core/projection/personalShopper/personalShopper";
import { HistoryBoxProjection } from "../../../../../core/projection/historyBox/historyBox";
import { HistoryBox } from "../../historyBox/HistoryBox";
import { OnClickHistoryProductVariant } from "../../historyProductVariant/HistoryProductVariant";
import "./history-boxes.css";

interface HistoryBoxesProps {
  readonly customerId: string;
  readonly historyBoxes: HistoryBoxProjection[];
  readonly returnQuestions: ReturnQuestionProjection[];
  readonly personalShopper: PersonalShopperProjection;
  readonly onClickProductVariant: (productVariantId: string) => void;
}
const HistoryBoxes: FC<HistoryBoxesProps> = ({
  customerId,
  historyBoxes,
  returnQuestions,
  personalShopper,
  onClickProductVariant,
}) => {
  const [boxId, setBoxId] = useState<string>();

  const trackDetailView = useTrackDetailView({
    section: TrackingPage.HISTORY_BOXES,
    psId: personalShopper.id,
    boxId: boxId,
    userId: customerId,
  });

  const trackCheckLastBoxesInfo = useTrackCheckLastBoxesInfo({
    section: TrackingPage.HISTORY_BOXES,
    psId: personalShopper.id,
    userId: customerId,
  });

  const handleOnClickProductVariant: OnClickHistoryProductVariant = useCallback(
    ({ productId, productVariantId }) => {
      trackDetailView({ productId, productVariantId });
      onClickProductVariant(productVariantId);
    },
    [onClickProductVariant, trackDetailView],
  );

  const handleOnClickBoxPreview = useCallback(
    (boxId: string) => {
      setBoxId(boxId);
      setBoxPreviewModalOpened(true);
      trackCheckLastBoxesInfo({
        reviewedInfo: TrackingReviewedInfo.PREVIEW,
        checkedBoxId: boxId,
      });
    },
    [trackCheckLastBoxesInfo],
  );

  const [boxPreviewModalOpened, setBoxPreviewModalOpened] = useState(false);
  const closeBoxPreviewModal = useCallback(() => setBoxPreviewModalOpened(false), []);

  return (
    <>
      <Modal
        className="history-boxes__modal"
        title="Preview"
        visible={boxPreviewModalOpened}
        onHide={closeBoxPreviewModal}
      >
        <BoxPreviewDetail boxId={boxId} />
      </Modal>

      <div aria-label="history-boxes" className="history-boxes">
        {historyBoxes.map((box) => (
          <HistoryBox
            key={box.legacyId}
            box={box}
            returnQuestions={returnQuestions}
            onClickBoxPreview={handleOnClickBoxPreview}
            onClickProductVariant={handleOnClickProductVariant}
          />
        ))}
      </div>
    </>
  );
};

export { HistoryBoxes };
