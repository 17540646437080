import { I18nMessage } from "@lookiero/i18n-react";
import { useCallback, useMemo } from "react";
import Pill from "../../../../../../../shared/ui/uiKit/molecules/pill/Pill";
import { ShortlistFilterItem } from "../../ShortlistFilterItem";
import { useShortlistFilteringPositives } from "../../_behavior/useShortlistFilteringPositives";
import { deactivate } from "../../../ShortlistFilterState";
import { useShortlistFilteringNegatives } from "../../_behavior/useShortlistFilteringNegatives";
import "./pill-shortlist-filter-item.css";

const PillShortlistFilterItem: ShortlistFilterItem = ({ filter, children }) => {
  const { state: positiveState, onChange: positiveOnChange } = useShortlistFilteringPositives();
  const { state: negativeState } = useShortlistFilteringNegatives();
  const handleOnPositiveRemove = useCallback(
    () => positiveOnChange({ state: deactivate({ filter, state: positiveState }) }),
    [filter, positiveOnChange, positiveState],
  );

  const positiveFilterState = useMemo(
    () => positiveState.find(({ id }) => id === filter.id),
    [filter.id, positiveState],
  );
  const negativeFilterState = useMemo(
    () => negativeState.find(({ id }) => id === filter.id),
    [filter.id, negativeState],
  );

  return (
    <>
      {positiveFilterState ? (
        <Pill className="pill-shortlist-filter-item--positive" onRemove={handleOnPositiveRemove}>
          <I18nMessage id={filter.translationKey} />
        </Pill>
      ) : null}
      {negativeFilterState ? (
        <Pill className="pill-shortlist-filter-item--negative">
          <I18nMessage id={filter.translationKey} />
        </Pill>
      ) : null}
      {children}
    </>
  );
};

export default PillShortlistFilterItem;
