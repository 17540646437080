import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import invariant from "ts-invariant";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { BoxesGetFunction, BoxesSaveFunction } from "../../../../domain/box/model/boxes";
import { viewBoxById, ViewBoxById, ViewBoxByIdResult } from "../../../../projection/box/viewBoxById";
import { Box } from "../../../../domain/box/model/box";
import { httpBoxesClose } from "./httpBoxesClose";
import { httpBoxesSendToSupervise } from "./httpBoxesSendToSupervise";

interface ToDomainFunction {
  (box: ViewBoxByIdResult): Box;
}

const toDomain: ToDomainFunction = (box) => {
  invariant(box, "Box does not exist");

  return {
    aggregateId: box.id,
    boxNumber: String(box.boxNumber),
    personalShopperId: null,
    status: box.status,
    domainEvents: [],
  };
};

interface HttpBoxesGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpBoxesGetFunction extends BoxesGetFunction<HttpBoxesGetFunctionArgs> {}

const getBox: HttpBoxesGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(await queryBus<ViewBoxById, ViewBoxByIdResult>(viewBoxById({ id: aggregateId })));

interface HttpBoxesSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxesSaveFunction extends BoxesSaveFunction<HttpBoxesSaveFunctionArgs> {}

const saveBox: HttpBoxesSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([
      await httpBoxesClose({ httpPost })(aggregateRoot),
      await httpBoxesSendToSupervise({ httpPost })(aggregateRoot),
    ]);
  };

export type { HttpBoxesSaveFunction };
export { getBox, saveBox };
