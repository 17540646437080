import { command, Command } from "@lookiero/messaging";
import { ActivityTimeSection } from "../model/activityTime";

const REGISTER_ACTIVITY_TIME = "register_activity_time";

interface RegisterActivityTimePayload {
  readonly psId: string;
  readonly boxId: string;
  readonly init: number;
  readonly end: number;
  readonly section: ActivityTimeSection;
  readonly userIsActive: boolean;
}

interface RegisterActivityTime extends Command<typeof REGISTER_ACTIVITY_TIME>, RegisterActivityTimePayload {}

interface RegisterActivityTimeFunction {
  (payload: RegisterActivityTimePayload): RegisterActivityTime;
}

const registerActivityTime: RegisterActivityTimeFunction = ({ ...payload }) => ({
  ...command({ name: REGISTER_ACTIVITY_TIME }),
  ...payload,
});

export type { RegisterActivityTime };
export { REGISTER_ACTIVITY_TIME, registerActivityTime };
