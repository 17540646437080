import React, { FC, useCallback, useEffect, useRef } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import { ModalNotificationProjection } from "../../../../projection/notification/notification";
import Modal from "../../../../../ui/uiKit/layouts/modal/Modal";
import Text from "../../../../../ui/uiKit/atoms/text/Text";
import Button from "../../../../../ui/uiKit/atoms/button/Button";
import "./modal-notification-item.css";

interface ModalNotificationItemProps {
  readonly notification: ModalNotificationProjection;
  readonly onRemove: (notificationId: string) => void;
  readonly testID?: string;
}

const ModalNotificationItem: FC<ModalNotificationItemProps> = ({ notification, onRemove, testID }) => {
  const titleText = useI18nMessage({ id: notification.titleI18nKey });
  const bodyText = useI18nMessage({ id: notification.bodyI18nKey });
  const acceptText = useI18nMessage({ id: notification.acceptI18nKey });

  const modalNotificationClosedRef = useRef(false);
  const handleOnClose = useCallback(() => {
    onRemove(notification.id);
    modalNotificationClosedRef.current = true;
  }, [notification.id, onRemove]);

  /**
   * Remove modal notification when this view is unmounted
   */
  useEffect(
    () => () => {
      if (!modalNotificationClosedRef.current) {
        onRemove(notification.id);
      }
    },
    [notification.id, onRemove],
  );

  return (
    <Modal visible onHide={handleOnClose}>
      <div className="modal-notification-item" data-testid={testID}>
        <Text className="modal-notification-item__title">{titleText}</Text>
        <Text className="modal-notification-item__description">{bodyText}</Text>
        <Button onClick={handleOnClose}>{acceptText}</Button>
      </div>
    </Modal>
  );
};

export { ModalNotificationItem };
