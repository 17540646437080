import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import Country from "../../domain/country/model/Country";
import { Segment } from "../segment/segment";

const COUNT_CAPACITY_CALENDAR_BY_CRITERIA = "count_capacity_calendar_by_criteria";

interface CountCapacityCalendarByCriteriaPayload {
  readonly from: Date;
  readonly to: Date;
  readonly markets: Country[];
  readonly segments: Segment[];
}

interface CountCapacityCalendarByCriteria
  extends Query<typeof COUNT_CAPACITY_CALENDAR_BY_CRITERIA>,
    CountCapacityCalendarByCriteriaPayload {}

interface CountCapacityCalendarByCriteriaFunction {
  (payload: CountCapacityCalendarByCriteriaPayload): CountCapacityCalendarByCriteria;
}

const countCapacityCalendarByCriteria: CountCapacityCalendarByCriteriaFunction = (payload) => ({
  ...query({ name: COUNT_CAPACITY_CALENDAR_BY_CRITERIA }),
  ...payload,
});

type CountCapacityCalendarByCriteriaResult = number | null;

interface CapacityCalendarCountByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly from: Date;
  readonly to: Date;
  readonly markets: Country[];
  readonly segments: Segment[];
}

interface CapacityCalendarCountByCriteriaView {
  (args: CapacityCalendarCountByCriteriaViewArgs): Promise<CountCapacityCalendarByCriteriaResult>;
}

interface CountCapacityCalendarByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CapacityCalendarCountByCriteriaView;
}

const countCapacityCalendarByCriteriaHandler: QueryHandlerFunction<
  CountCapacityCalendarByCriteria,
  CountCapacityCalendarByCriteriaResult,
  CountCapacityCalendarByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ from, markets, segments, to }) =>
    view({ from, markets, segments, to, signal });

export { COUNT_CAPACITY_CALENDAR_BY_CRITERIA, countCapacityCalendarByCriteria, countCapacityCalendarByCriteriaHandler };
export type { CapacityCalendarCountByCriteriaView, CountCapacityCalendarByCriteriaResult };
