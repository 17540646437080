import BoxStatus from "../../../domain/box/model/BoxStatus";
import Country from "../../../domain/country/model/Country";
import Currency from "../../../domain/country/model/Currency";
import Locale from "../../../domain/country/model/Locale";
import { BasicBoxProjection, BoxProjection } from "../../../projection/box/box";

interface BoxDto {
  readonly id: string;
  readonly boxNumber: number;
  readonly status: string;
  readonly legacyId: number;
  readonly market: {
    readonly id: string;
    readonly currency: string;
    readonly name: string;
    readonly locale: string;
    readonly country: string;
  };
  readonly customerId: string;
  readonly closedAt: string;
  readonly orderId: string;
  readonly plannedFor: string;
  readonly prepareFor: string;
  readonly experiments: {
    readonly name: string;
    readonly variant: string;
  }[];
  readonly psNumber: number;
  readonly frequentPsNumber: number;
}

interface ToBoxProjectionFunction {
  (BoxDto: BoxDto): BoxProjection;
}

const toBoxProjection: ToBoxProjectionFunction = (boxDto) => ({
  ...boxDto,
  status: boxDto.status as BoxStatus,
  market: {
    ...boxDto.market,
    currency: boxDto.market.currency as Currency,
    locale: boxDto.market.locale as Locale,
    country: boxDto.market.country as Country,
  },
  closedAt: new Date(boxDto.closedAt),
  plannedFor: new Date(boxDto.plannedFor),
  prepareFor: new Date(boxDto.prepareFor),
});

type BasicBoxDto = Pick<BoxDto, "id" | "boxNumber" | "plannedFor" | "status">;

interface ToBasicBoxProjectionFunction {
  (basicBoxDto: BasicBoxDto): BasicBoxProjection;
}
const toBasicBoxProjection: ToBasicBoxProjectionFunction = ({ boxNumber, id, plannedFor, status }) => ({
  id,
  boxNumber,
  plannedFor: new Date(plannedFor),
  status: status as BoxStatus,
});

export type { BoxDto, BasicBoxDto };
export { toBoxProjection, toBasicBoxProjection };
