import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import {
  CanPersonalShopperWriteNoteResult,
  canWriteNote,
} from "../../../../projection/personalShopper/canPersonalShopperWriteNote";
import useBoxSharedContext from "../../../../../ui/views/_behaviors/useBoxSharedContext";
import { useViewPersonalShopper } from "./useViewPersonalShopper";
import { useMemo } from "react";

interface UseCanPersonalShopperWriteNoteFunctionArgs {
  readonly boxId: string;
}
interface UseCanPersonalShopperWriteNoteFunction {
  (args: UseCanPersonalShopperWriteNoteFunctionArgs): UseQueryFunctionResult<CanPersonalShopperWriteNoteResult>;
}

const useCanPersonalShopperWriteNote: UseCanPersonalShopperWriteNoteFunction = () => {
  const { box } = useBoxSharedContext();
  const [personalShopper, personalShopperStatus] = useViewPersonalShopper();

  const result: UseQueryFunctionResult<CanPersonalShopperWriteNoteResult> = useMemo(
    () => [
      personalShopper ? canWriteNote({ roles: personalShopper.roles, status: box.status }) : undefined,
      personalShopperStatus,
    ],
    [box, personalShopper, personalShopperStatus],
  );

  return result;
};
// useQuery({
//   query: canPersonalShopperWriteNote({ boxId }),
//   contextId: MESSAGING_CONTEXT_ID,
//   invalidation: (event: DomainEvent<MessageName>) => event instanceof BoxClosed,
//   options: { staleTime: Infinity },
// });

export { useCanPersonalShopperWriteNote };
