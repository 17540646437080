import { useRef, useCallback, Ref } from "react";
import { useClickAway as clickAway, useKey } from "react-use";
import useLockBodyScroll from "../useLockBodyScroll/useLockBodyScroll";

interface UseClickAwayParameters {
  readonly active: boolean;
  readonly action: () => void;
}

const useClickAway = <T extends HTMLElement>({ active, action }: UseClickAwayParameters): { viewRef: Ref<T> } => {
  const viewRef = useRef(null);
  const activeRef = useRef(active);
  activeRef.current = active;

  const handleAway = useCallback(() => {
    if (activeRef.current) {
      action();
    }
  }, [action]);

  clickAway(viewRef, handleAway);

  useKey("Escape", handleAway);

  useLockBodyScroll(active);

  return { viewRef };
};

export default useClickAway;
