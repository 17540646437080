import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { updateUiSetting } from "../../../../domain/uiSetting/command/updateUiSetting";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";

interface UpdateFunctionArgs {
  readonly key: string;
  readonly value: unknown;
}

interface UpdateFunction {
  (args: UpdateFunctionArgs): Promise<void>;
}

type UseUpdateUiSetting = [update: UpdateFunction, status: CommandStatus];

interface UseUpdateUiSettingFunction {
  (): UseUpdateUiSetting;
}

const useUpdateUiSetting: UseUpdateUiSettingFunction = () => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const update: UpdateFunction = useCallback(
    async ({ key, value }) => {
      try {
        await commandBus(
          updateUiSetting({
            aggregateId: uuid(),
            key,
            value,
          }),
        );
      } catch (error) {
        console.log(error);
      }
    },
    [commandBus],
  );

  return [update, status];
};

export { useUpdateUiSetting };
