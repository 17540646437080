import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import { PersonalShopperProductionProjection } from "../../../projection/personalShopperProduction/personalShopperProduction";
import {
  PersonalShopperProductionByCriteriaView,
  ViewPersonalShopperProductionByCriteriaResult,
} from "../../../projection/personalShopperProduction/viewPersonalShopperProductionByCriteria";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpPersonalShopperProductionByCriteriaView extends PersonalShopperProductionByCriteriaView {}

interface HttpPersonalShopperProductionByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpPersonalShopperProductionByCriteriaViewFunction {
  (args: HttpPersonalShopperProductionByCriteriaViewFunctionArgs): HttpPersonalShopperProductionByCriteriaView;
}

interface ViewPersonalShopperProductionByCriteriaResponse {
  readonly result: PersonalShopperProductionProjection[];
}

const httpPersonalShopperProductionByCriteriaView: HttpPersonalShopperProductionByCriteriaViewFunction =
  ({ httpPost }) =>
  async ({ country, team, signal }) =>
    await httpPost<ViewPersonalShopperProductionByCriteriaResponse, ViewPersonalShopperProductionByCriteriaResult>({
      endpoint: "/view-production-by-criteria",
      body: {
        country,
        team,
      },
      signal,
      result: {
        error: null,
        success: (response) => camelcaseKeys(response.result),
      },
    });

export { httpPersonalShopperProductionByCriteriaView };
