import {
  PersonalShopperFilteringByIdView,
  ViewPersonalShopperFilteringByIdResult,
} from "../../../../projection/personalShopperFiltering/viewPersonalShopperFilteringById";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { PersonalShopperFilteringDto, toPersonalShopperFiltering } from "./personalShopperFiltering";

interface HttpPersonalShopperFilteringByIdView extends PersonalShopperFilteringByIdView {}

interface HttpPersonalShopperFilteringByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpPersonalShopperFilteringByIdViewFunction {
  (args: HttpPersonalShopperFilteringByIdViewFunctionArgs): HttpPersonalShopperFilteringByIdView;
}

interface ViewPersonalShopperFilteringByIdResponse {
  readonly result: PersonalShopperFilteringDto;
}

const httpPersonalShopperFilteringByIdView: HttpPersonalShopperFilteringByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<ViewPersonalShopperFilteringByIdResponse, ViewPersonalShopperFilteringByIdResult>({
      endpoint: "/view-personal-shopper-filtering-by-id",
      body: { id },
      signal,
      result: {
        error: null,
        success: (personalShopperFiltering) => toPersonalShopperFiltering(personalShopperFiltering.result),
      },
    });

export { httpPersonalShopperFilteringByIdView };
