import { v4 as uuid } from "uuid";
import { useDispatch } from "@lookiero/messaging.js";
import { useCallback, useState } from "react";
import AsyncActionState from "../../../shared/ui/uiKit/_common/AsyncActionState";
import SendBoxPreview from "../../../core/domain/boxPreview/command/sendBoxPreview/SendBoxPreview";
import Look from "../../../core/domain/boxPreview/model/Look";
import invariant from "ts-invariant";

type UseSendBoxPreviewParameters = {
  readonly selectionId?: string;
  readonly boxId: string;
  readonly selectionProductVariantIds: string[];
  readonly looks?: Look[];
};
type UseSendBoxPreviewReturn = {
  readonly state: AsyncActionState;
  readonly sendBoxPreview: () => Promise<void>;
};
const useSendBoxPreview = ({
  selectionId,
  boxId,
  selectionProductVariantIds,
  looks,
}: UseSendBoxPreviewParameters): UseSendBoxPreviewReturn => {
  const dispatch = useDispatch();
  const [state, setState] = useState<AsyncActionState>(AsyncActionState.DEFAULT);

  const sendBoxPreview = useCallback(async () => {
    invariant(selectionId, "Cannot send the box preview without a selectionId");

    try {
      setState(AsyncActionState.PENDING);

      await dispatch(
        new SendBoxPreview({
          selectionId,
          boxId,
          boxPreviewId: uuid(),
          selectionProductVariantIds,
          looks,
        }),
      );

      setState(AsyncActionState.SUCCESS);
    } catch (error) {
      console.log(error);
      setState(AsyncActionState.ERROR);
    }
  }, [boxId, dispatch, selectionId, looks, selectionProductVariantIds]);

  return { state, sendBoxPreview };
};

export default useSendBoxPreview;
