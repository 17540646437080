import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { NoteTemplateCategory } from "../noteTemplateCategory/noteTemplateCategory";

interface NoteTemplatesCountByCriteria {
  readonly psId: string;
  readonly categories: NoteTemplateCategory[];
}

const COUNT_NOTE_TEMPLATES_BY_CRITERIA = "count_note_templates_by_criteria";

interface CountNoteTemplatesByCriteriaPayload {
  readonly criteria: NoteTemplatesCountByCriteria;
}

interface CountNoteTemplatesByCriteria
  extends Query<typeof COUNT_NOTE_TEMPLATES_BY_CRITERIA>,
    CountNoteTemplatesByCriteriaPayload {}

interface CountNoteTemplatesByCriteriaFunction {
  (payload: CountNoteTemplatesByCriteriaPayload): CountNoteTemplatesByCriteria;
}

const countNoteTemplatesByCriteria: CountNoteTemplatesByCriteriaFunction = (payload) => ({
  ...query({ name: COUNT_NOTE_TEMPLATES_BY_CRITERIA }),
  ...payload,
});

type CountNoteTemplatesByCriteriaResult = number;

interface NoteTemplatesCountByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly criteria: NoteTemplatesCountByCriteria;
}

interface NoteTemplatesCountByCriteriaView {
  (args: NoteTemplatesCountByCriteriaViewArgs): Promise<CountNoteTemplatesByCriteriaResult>;
}

interface CountNoteTemplatesByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: NoteTemplatesCountByCriteriaView;
}

const countNoteTemplatesByCriteriaHandler: QueryHandlerFunction<
  CountNoteTemplatesByCriteria,
  CountNoteTemplatesByCriteriaResult,
  CountNoteTemplatesByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ criteria }) =>
    view({ criteria, signal });

export type { NoteTemplatesCountByCriteriaView, CountNoteTemplatesByCriteriaResult, NoteTemplatesCountByCriteria };
export { COUNT_NOTE_TEMPLATES_BY_CRITERIA, countNoteTemplatesByCriteria, countNoteTemplatesByCriteriaHandler };
