import { FC } from "react";
import { Route, useParams } from "react-router-dom";
import { Routes } from "../../_routing/Routing";
import { Navigate, generatePath } from "react-router-dom";

const RedirectToNotesTemplates: FC = () => {
  const { locale, box } = useParams();

  return (
    <Navigate
      to={generatePath(Routes.BOX_SELECTION_NOTES_TEMPLATES, { locale: locale as string, box: box as string })}
    />
  );
};

const RedirectToNotesTemplatesRoute: FC = () => (
  <>
    <Route element={<RedirectToNotesTemplates />} path={Routes.BOX_SELECTION_NOTES_TEMPLATES_NEW} />
    <Route element={<RedirectToNotesTemplates />} path={Routes.BOX_SELECTION_NOTES_TEMPLATES_DETAIL} />
  </>
);

export default RedirectToNotesTemplatesRoute;
