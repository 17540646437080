import { ProcessManager } from "@lookiero/messaging.js";
import { CommandBus as MessagingCommandBus } from "@lookiero/messaging";
import BoxPreviewSent from "../../boxPreview/event/BoxPreviewSent";
import { setLooks } from "../command/setLooks";

class SetLooksWhenBoxPreviewSent implements ProcessManager<BoxPreviewSent> {
  private readonly messagingCommandBus!: MessagingCommandBus;

  public async process({ selectionId }: BoxPreviewSent): Promise<void> {
    await this.messagingCommandBus(setLooks({ aggregateId: selectionId, looks: [] }));
  }
}

export default SetLooksWhenBoxPreviewSent;
