import {
  ListNoteTemplateCategoriesByPsIdResult,
  NoteTemplateCategoriesByPsIdView,
} from "../../../projection/noteTemplateCategory/listNoteTemplateCategoriesByPsId";
import { NoteTemplateCategory } from "../../../projection/noteTemplateCategory/noteTemplateCategory";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpNoteTemplateCategoriesByPsIdView extends NoteTemplateCategoriesByPsIdView {}

interface HttpNoteTemplateCategoriesByPsIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpNoteTemplateCategoriesByPsIdViewFunction {
  (args: HttpNoteTemplateCategoriesByPsIdViewFunctionArgs): HttpNoteTemplateCategoriesByPsIdView;
}

interface ListNoteTemplateCategoriesByPsIdResponse {
  readonly result: NoteTemplateCategory[];
}

const httpNoteTemplateCategoriesByPsIdView: HttpNoteTemplateCategoriesByPsIdViewFunction =
  ({ httpPost }) =>
  async ({ personalShopperId, signal }) =>
    await httpPost<ListNoteTemplateCategoriesByPsIdResponse, ListNoteTemplateCategoriesByPsIdResult>({
      endpoint: "/list-note-template-categories-by-ps-id",
      body: { ps_id: personalShopperId },
      signal,
      result: {
        error: [],
        success: (response) => response.result,
      },
    });

export { httpNoteTemplateCategoriesByPsIdView };
