import { isActivityTimeRegistered } from "../../../../domain/activityTime/model/activityTimeRegistered";
import { HttpOrNavigatorActivityTimesSaveFunction } from "./httpOrNavigatorActivityTimes";

interface HttpActivityTimesRegisterPreviewedWatermarkFunction extends HttpOrNavigatorActivityTimesSaveFunction {}

const httpOrNavigatorActivityTimesRegister: HttpActivityTimesRegisterPreviewedWatermarkFunction =
  ({ httpPost, sendBeacon }) =>
  async ({ boxId, psId, init, end, section, userIsActive, domainEvents }) => {
    const activityTimeRegistered = domainEvents.find(isActivityTimeRegistered);

    if (!activityTimeRegistered) {
      return;
    }

    const payload = {
      endpoint: "/register-activity-time",
      body: { ps_id: psId, box_id: boxId, init, end, section },
    };

    userIsActive ? await httpPost(payload) : sendBeacon(payload);
  };

export { httpOrNavigatorActivityTimesRegister };
