import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const NOTE_TEMPLATE_WRITTEN = "note_template_written";

interface NoteTemplateWrittenPayload {
  readonly aggregateId: string;
}

interface NoteTemplateWritten extends DomainEvent<typeof NOTE_TEMPLATE_WRITTEN> {}

interface NoteTemplateWrittenFunction {
  (payload: NoteTemplateWrittenPayload): NoteTemplateWritten;
}

const noteTemplateWritten: NoteTemplateWrittenFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: NOTE_TEMPLATE_WRITTEN });

const isNoteTemplateWritten = (event: DomainEvent<MessageName>): event is NoteTemplateWritten =>
  event.name === NOTE_TEMPLATE_WRITTEN;

export type { NoteTemplateWritten };
export { NOTE_TEMPLATE_WRITTEN, noteTemplateWritten, isNoteTemplateWritten };
