import {
  IsStockSurveySubmittedByBoxIdResult,
  StockSurveySubmittedByBoxIdView,
} from "../../../projection/stockSurvey/isStockSurveySubmittedByBoxId";

import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpIsStockSurveySubmittedByBoxIdView extends StockSurveySubmittedByBoxIdView {}

interface HttpIsStockSurveySubmittedByBoxIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpIsStockSurveySubmittedByBoxIdViewFunction {
  (args: HttpIsStockSurveySubmittedByBoxIdViewFunctionArgs): HttpIsStockSurveySubmittedByBoxIdView;
}

const httpIsStockSurveySubmittedByBoxIdView: HttpIsStockSurveySubmittedByBoxIdViewFunction =
  ({ httpPost }) =>
  async ({ boxId, signal }) =>
    await httpPost<IsStockSurveySubmittedByBoxIdResult>({
      endpoint: "/is-stock-survey-submitted-by-box-id",
      body: { boxId },
      signal,
      result: {
        error: false,
        success: (response) => response,
      },
    });

export { httpIsStockSurveySubmittedByBoxIdView };
