import { I18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/atoms/text/Text";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../../componentLibrary/_i18n/BoxPreviewI18n";
import useBoxSharedContext from "../../_behaviors/useBoxSharedContext";
import useEnvironment from "../../_behaviors/useEnvironment";
import "./shortlist-no-preview-indicator.css";

const ShortlistNoPreviewIndicator: FC = () => {
  const { box } = useBoxSharedContext();
  const {
    experiments: { stopSendingPreviews, stopSendingPreviewsToCustomersWithoutGivingFeedback },
  } = useEnvironment();
  const stopSendingPreviewsExperiment = box.experiments.find(
    (experiment) => experiment.name === stopSendingPreviews.name,
  );
  const stopSendingPreviewsToCustomersWithoutGivingFeedbackExperiment = box.experiments.find(
    (experiment) => experiment.name === stopSendingPreviewsToCustomersWithoutGivingFeedback.name,
  );
  const previewDisabled =
    stopSendingPreviews.variant === stopSendingPreviewsExperiment?.variant ||
    stopSendingPreviewsToCustomersWithoutGivingFeedback.variant ===
      stopSendingPreviewsToCustomersWithoutGivingFeedbackExperiment?.variant;

  return previewDisabled ? (
    <div className="shortlist-no-preview-indicator">
      <Icon variant={IconVariant.EYE_STROKE} />
      <Text variant={TextVariant.BODY}>
        <I18nMessage id={BoxPreviewI18n.PREVIEW_NOT_REQUIRED} prefix={BOX_PREVIEW_I18N_PREFIX} />
      </Text>
    </div>
  ) : null;
};

export { ShortlistNoPreviewIndicator };
