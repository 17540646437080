import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

const IS_STOCK_SURVEY_SUBMITTED_BY_BOX_ID = "is_stock_survey_submitted_by_box_id";

interface IsStockSurveySubmittedByBoxIdPayload {
  readonly boxId: string;
}

interface IsStockSurveySubmittedByBoxId
  extends Query<typeof IS_STOCK_SURVEY_SUBMITTED_BY_BOX_ID>,
    IsStockSurveySubmittedByBoxIdPayload {}

interface IsStockSurveySubmittedByBoxIdFunction {
  (payload: IsStockSurveySubmittedByBoxIdPayload): IsStockSurveySubmittedByBoxId;
}

const isStockSurveySubmittedByBoxId: IsStockSurveySubmittedByBoxIdFunction = (payload) => ({
  ...query({ name: IS_STOCK_SURVEY_SUBMITTED_BY_BOX_ID }),
  ...payload,
});

type IsStockSurveySubmittedByBoxIdResult = boolean;

interface StockSurveySubmittedByBoxIdViewArgs extends CancelableQueryViewArgs {
  readonly boxId: string;
}

interface StockSurveySubmittedByBoxIdView {
  (args: StockSurveySubmittedByBoxIdViewArgs): Promise<IsStockSurveySubmittedByBoxIdResult>;
}

interface IsStockSurveySubmittedByBoxIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: StockSurveySubmittedByBoxIdView;
}

const isStockSurveySubmittedByBoxIdHandler: QueryHandlerFunction<
  IsStockSurveySubmittedByBoxId,
  IsStockSurveySubmittedByBoxIdResult,
  IsStockSurveySubmittedByBoxIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxId }) =>
    view({ boxId, signal });

export type { StockSurveySubmittedByBoxIdView, IsStockSurveySubmittedByBoxId, IsStockSurveySubmittedByBoxIdResult };
export { IS_STOCK_SURVEY_SUBMITTED_BY_BOX_ID, isStockSurveySubmittedByBoxId, isStockSurveySubmittedByBoxIdHandler };
