import { I18nMessage } from "@lookiero/i18n-react";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import Loader from "../../../../shared/ui/uiKit/atoms/loader/Loader";
import Tooltip from "../../../../shared/ui/uiKit/atoms/tooltip/Tooltip";
import AsyncActionState from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../../componentLibrary/_i18n/NoteI18n";
import "./note-editor-status.css";

type NoteEditorStatusProps = {
  readonly state?: AsyncActionState;
};
const NoteEditorStatus: FC<NoteEditorStatusProps> = ({ state }) => {
  const [currentState, setCurrentState] = useState(state);

  useEffect(() => {
    setCurrentState(state);
    const timeout = setTimeout(() => setCurrentState(AsyncActionState.DEFAULT), 3000);

    return (): void => clearTimeout(timeout);
  }, [setCurrentState, state]);

  return (
    <div className={classNames("note-editor-status", `note-editor-status--${currentState}`)}>
      {state === AsyncActionState.PENDING && <Loader />}
      {state === AsyncActionState.SUCCESS && (
        <Tooltip title={<I18nMessage id={NoteI18n.EDITOR_WRITE_SUCCESS} prefix={NOTE_I18N_PREFIX} />}>
          <Icon variant={IconVariant.CHECK_FILLED} />
        </Tooltip>
      )}
      {state === AsyncActionState.ERROR && (
        <Tooltip title={<I18nMessage id={NoteI18n.EDITOR_WRITE_ERROR} prefix={NOTE_I18N_PREFIX} />}>
          <Icon variant={IconVariant.REMOVE_FILLED} />
        </Tooltip>
      )}
    </div>
  );
};

export default NoteEditorStatus;
