const PLANNING_I18N_PREFIX = "labs.planning.";

enum PlanningI18n {
  NOT_ASSIGNED_PS = "not_assigned_ps",
  PLANNED_FOR_DATE_LABEL = "planned_for_date_label",
  COUNTRY_LABEL = "country_label",
  PS_NUMBER_LABEL = "ps_number_label",
  BOX_NUMBER_LABEL = "box_number_label",
  ASSIGNMENT_LABEL = "assignment_label",
  ASSIGNED = "assigned",
  NON_ASSIGNED = "non_assigned",
  TABLE_HEADER_BOX = "table_header_box",
  TABLE_HEADER_PS = "table_header_ps",
  TABLE_HEADER_NAME = "table_header_name",
  TABLE_HEADER_COUNTRY = "table_header_country",
  TABLE_HEADER_PREPARE_FOR = "table_header_prepare_for",
  TABLE_HEADER_STATUS = "table_header_status",
  TABLE_HEADER_BOX_COUNT = "table_header_box_count",
  SUMMARY_READY_BOXES = "summary_ready_boxes",
  SUMMARY_POSSIBLE_FRAUD_BOXES = "summary_possible_fraud_boxes",
  SUMMARY_SENT_PREVIEW_BOXES = "summary_sent_preview_boxes",
  SUMMARY_COMPLETED_PREVIEW_BOXES = "summary_completed_preview_boxes",
  SUMMARY_COMPLETED_BOXES = "summary_completed_boxes",
  CSV_EXPORT = "csv_export",
}

export { PLANNING_I18N_PREFIX, PlanningI18n };
