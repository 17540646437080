import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { SelectionOrderDto } from "../../../persistence/selectionOrderDto";
import { Storage } from "../../../persistence/storage";
import {
  SelectionOrdersGetFunction,
  SelectionOrdersSaveFunction,
} from "../../../../domain/selectionOrder/model/SelectionOrders";
import { storageSelectionOrdersRemove } from "./storageSelectionOrdersRemove";
import { SelectionOrder } from "../../../../domain/selectionOrder/model/SelectionOrder";
import { storageSelectionOrdersSort } from "./storageSelectionOrdersSort";

const toDomain = (selectionOrderDto: SelectionOrderDto): SelectionOrder => ({
  aggregateId: selectionOrderDto.selectionId,
  boxNumber: selectionOrderDto.boxNumber,
  productVariantIds: selectionOrderDto.productVariantIds,
  domainEvents: [],
});

interface StorageSelectionOrdersGetFunctionArgs extends RepositoryGetFunctionArgs {
  readonly storage: Storage<SelectionOrderDto>;
}

interface StorageSelectionOrdersGetFunction extends SelectionOrdersGetFunction<StorageSelectionOrdersGetFunctionArgs> {}

const getSelectionOrder: StorageSelectionOrdersGetFunction =
  ({ storage: { read } }) =>
  async (boxNumber) => {
    const selectionOrderDto = await read(boxNumber);

    if (!selectionOrderDto) {
      throw new Error(`SelectionOrder not found: ${boxNumber}`);
    }

    return toDomain(selectionOrderDto);
  };

interface StorageSelectionOrdersSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly storage: Storage<SelectionOrderDto>;
}

interface StorageSelectionOrdersSaveFunction
  extends SelectionOrdersSaveFunction<StorageSelectionOrdersSaveFunctionArgs> {}

const saveSelectionOrder: StorageSelectionOrdersSaveFunction =
  ({ storage }) =>
  async (aggregateRoot) => {
    await Promise.all([
      storageSelectionOrdersRemove({ storage })(aggregateRoot),
      storageSelectionOrdersSort({ storage })(aggregateRoot),
    ]);
  };

export type { StorageSelectionOrdersSaveFunction };
export { getSelectionOrder, saveSelectionOrder };
