import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CountLookAndLikeFeedbacksCriteria } from "./countLookAndLikeFeedbacksByCriteria";
import { LookAndLikeFeedbackProjection } from "./lookAndLikeFeedback";

const SEARCH_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA = "search_look_and_like_feedbacks_by_criteria";

interface SearchLookAndLikeFeedbacksCriteria extends CountLookAndLikeFeedbacksCriteria {
  readonly page: number;
  readonly perPage: number;
}

interface SearchLookAndLikeFeedbacksByCriteriaPayload {
  readonly criteria: SearchLookAndLikeFeedbacksCriteria;
}

interface SearchLookAndLikeFeedbacksByCriteria
  extends Query<typeof SEARCH_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA>,
    SearchLookAndLikeFeedbacksByCriteriaPayload {}

interface SearchLookAndLikeFeedbacksByCriteriaFunction {
  (payload: SearchLookAndLikeFeedbacksByCriteriaPayload): SearchLookAndLikeFeedbacksByCriteria;
}

const searchLookAndLikeFeedbacksByCriteria: SearchLookAndLikeFeedbacksByCriteriaFunction = (payload) => ({
  ...query({ name: SEARCH_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA }),
  ...payload,
});

type SearchLookAndLikeFeedbacksByCriteriaResult = LookAndLikeFeedbackProjection[] | null;

interface LookAndLikeFeedbacksSearchByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly criteria: SearchLookAndLikeFeedbacksCriteria;
}

interface LookAndLikeFeedbacksSearchByCriteriaView {
  (args: LookAndLikeFeedbacksSearchByCriteriaViewArgs): Promise<SearchLookAndLikeFeedbacksByCriteriaResult>;
}

interface SearchLookAndLikeFeedbacksByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: LookAndLikeFeedbacksSearchByCriteriaView;
}

const searchLookAndLikeFeedbacksByCriteriaHandler: QueryHandlerFunction<
  SearchLookAndLikeFeedbacksByCriteria,
  SearchLookAndLikeFeedbacksByCriteriaResult,
  SearchLookAndLikeFeedbacksByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ criteria }) =>
    view({ criteria, signal });

export type {
  SearchLookAndLikeFeedbacksCriteria,
  LookAndLikeFeedbacksSearchByCriteriaView,
  SearchLookAndLikeFeedbacksByCriteria,
  SearchLookAndLikeFeedbacksByCriteriaResult,
};
export {
  SEARCH_LOOK_AND_LIKE_FEEDBACKS_BY_CRITERIA,
  searchLookAndLikeFeedbacksByCriteria,
  searchLookAndLikeFeedbacksByCriteriaHandler,
};
