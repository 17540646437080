import { command, Command } from "@lookiero/messaging";

const UNMARK_PRODUCT_VARIANT_AS_CANDIDATE = "unmark_product_variant_as_candidate";

interface UnmarkProductVariantAsCandidatePayload {
  readonly aggregateId: string;
  readonly boxNumber: string;
  readonly productVariantId: string;
  readonly boxId: string;
}

interface UnmarkProductVariantAsCandidate
  extends Command<typeof UNMARK_PRODUCT_VARIANT_AS_CANDIDATE>,
    UnmarkProductVariantAsCandidatePayload {}

interface UnmarkProductVariantAsCandidateFunction {
  (payload: UnmarkProductVariantAsCandidatePayload): UnmarkProductVariantAsCandidate;
}

const unmarkProductVariantAsCandidate: UnmarkProductVariantAsCandidateFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: UNMARK_PRODUCT_VARIANT_AS_CANDIDATE }),
  ...payload,
});
export type { UnmarkProductVariantAsCandidate };
export { UNMARK_PRODUCT_VARIANT_AS_CANDIDATE, unmarkProductVariantAsCandidate };
