import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { LookValidationProjection } from "../../../../projection/lookValidation/lookValidation";
import { LookProjection } from "../../../../projection/looks/look";
import { LookTemplate } from "../../../../projection/looks/lookTemplate";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listLookValidationErrors } from "../../../../projection/lookValidation/listLookValidationErrors";

interface UseListLookValidationErrorsFunctionArgs {
  readonly look: LookProjection;
  readonly lookTemplate: LookTemplate;
}

interface UseListLookValidationErrorsFunction {
  (args: UseListLookValidationErrorsFunctionArgs): UseQueryFunctionResult<LookValidationProjection>;
}

const useListLookValidationErrors: UseListLookValidationErrorsFunction = ({ look, lookTemplate }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listLookValidationErrors({ look, lookTemplate }),
    options: {
      keepPreviousData: true,
    },
  });

export { useListLookValidationErrors };
