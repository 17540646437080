import React, { FC } from "react";
import ActionConfirmationModal from "../../../../shared/ui/uiKit/organisms/actionConfirmationModal/ActionConfirmationModal";
import { I18nMessage } from "@lookiero/i18n-react";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/atoms/icon/Icon";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../../componentLibrary/_i18n/NoteI18n";
import "./generate-note-confirmation-modal.css";

type GenerateNoteConfirmationModalProps = {
  readonly visible: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};
const GenerateNoteConfirmationModal: FC<GenerateNoteConfirmationModalProps> = ({ visible, onCancel, onConfirm }) => (
  <ActionConfirmationModal
    cancelButtonText={<I18nMessage id={NoteI18n.GENERATE_NOTE_CONFIRMATION_CANCEL} prefix={NOTE_I18N_PREFIX} />}
    className="generate-note-confirmation-modal"
    confirmButtonText={<I18nMessage id={NoteI18n.GENERATE_NOTE_CONFIRMATION_CONFIRM} prefix={NOTE_I18N_PREFIX} />}
    visible={visible}
    title={
      <header className="generate-note-confirmation-modal__header">
        <Icon variant={IconVariant.ALERT_WARNING} />
        <I18nMessage id={NoteI18n.GENERATE_NOTE_CONFIRMATION_TITTLE} prefix={NOTE_I18N_PREFIX} />
      </header>
    }
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <I18nMessage id={NoteI18n.GENERATE_NOTE_CONFIRMATION_DESCRIPTION} prefix={NOTE_I18N_PREFIX} />
  </ActionConfirmationModal>
);

export { GenerateNoteConfirmationModal };
