import { isNoteGenerated } from "../../../../domain/note/model/noteGenerated";
import { isNoteWritten } from "../../../../domain/note/model/noteWritten";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { canTheBoxBeClosed, CanTheBoxBeClosedResult } from "../../../../projection/box/canTheBoxBeClosed";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { isBoxClosed } from "../../../../domain/box/model/boxClosed";
import { isBoxSentToSupervise } from "../../../../domain/box/model/boxSentToSupervise";
import { isProductVariantDeselected } from "../../../../domain/selection/model/ProductVariantDeselected";
import { isProductVariantReplaced } from "../../../../domain/selection/model/ProductVariantReplaced";
import { isProductVariantSelected } from "../../../../domain/selection/model/ProductVariantSelected";
import { isProductVariantUnmarkedAsCandidate } from "../../../../domain/selection/model/productVariantUnmarkedAsCandidate";
import { isProductVariantMarkedAsCandidate } from "../../../../domain/selection/model/productVariantMarkedAsCandidate";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isNoteWritten(event) ||
  isNoteGenerated(event) ||
  isBoxClosed(event) ||
  isBoxSentToSupervise(event) ||
  isProductVariantDeselected(event) ||
  isProductVariantReplaced(event) ||
  isProductVariantSelected(event) ||
  isProductVariantUnmarkedAsCandidate(event) ||
  isProductVariantMarkedAsCandidate(event);

interface UseCanTheBoxClosedFunctionArgs {
  readonly boxNumber: string;
}

interface UseCanTheBoxClosedFunction {
  (args: UseCanTheBoxClosedFunctionArgs): UseQueryFunctionResult<CanTheBoxBeClosedResult>;
}

const useCanTheBoxBeClosed: UseCanTheBoxClosedFunction = ({ boxNumber }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: canTheBoxBeClosed({ boxNumber }),
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useCanTheBoxBeClosed };
