import { ProcessManager } from "@lookiero/messaging.js";
import { QueryBus as MessagingQueryBus } from "@lookiero/messaging";
import BoxPreviewSent from "../../boxPreview/event/BoxPreviewSent";
import cdnImageUrl from "../../../../ui/_util/cdnImageUrl";
import Environment from "../../../projection/environment/model/Environment";
import {
  ViewBoxPreviewByBoxIdResult,
  viewBoxPreviewByBoxId,
} from "../../../projection/boxPreview/viewBoxPreviewByBoxId";

class PrefetchMediaWhenBoxPreviewSent implements ProcessManager<BoxPreviewSent> {
  private readonly messagingQueryBus!: MessagingQueryBus;
  private readonly environment: Environment;

  public constructor(environment: Environment) {
    this.environment = environment;
  }

  public async process({ boxId }: BoxPreviewSent): Promise<void> {
    const boxPreview: ViewBoxPreviewByBoxIdResult = await this.messagingQueryBus(viewBoxPreviewByBoxId({ boxId }));

    if (!boxPreview) {
      throw new Error(`BoxPreview for the provided box-id does not exist: ${boxId}`);
    }

    const {
      preview: {
        media: { perspectives, sizes, dpi },
      },
    } = this.environment;

    boxPreview.productVariants.forEach(({ media }) => {
      media.forEach(({ perspective, url }) => {
        if (perspectives.includes(perspective)) {
          Object.values(sizes).forEach((width) => {
            Object.values(dpi).forEach((dpi) => {
              const imageUrl = cdnImageUrl({ url, width, dpi });

              fetch(imageUrl, {
                credentials: "include",
                mode: "no-cors",
              });
            });
          });
        }
      });
    });
  }
}

export default PrefetchMediaWhenBoxPreviewSent;
