import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const PERSONAL_SHOPPER_FILTERING_SET = "personal_shopper_filtering_set";

interface PersonalShopperFilteringSetPayload {
  readonly aggregateId: string;
}

interface PersonalShopperFilteringSet
  extends DomainEvent<typeof PERSONAL_SHOPPER_FILTERING_SET>,
    PersonalShopperFilteringSetPayload {}

interface PersonalShopperFilteringSetFunction {
  (payload: PersonalShopperFilteringSetPayload): PersonalShopperFilteringSet;
}

const personalShopperFilteringSet: PersonalShopperFilteringSetFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: PERSONAL_SHOPPER_FILTERING_SET });

const isPersonalShopperFilteringSet = (event: DomainEvent<MessageName>): event is PersonalShopperFilteringSet =>
  event.name === PERSONAL_SHOPPER_FILTERING_SET;

export type { PersonalShopperFilteringSet };
export { PERSONAL_SHOPPER_FILTERING_SET, personalShopperFilteringSet, isPersonalShopperFilteringSet };
