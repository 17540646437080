import { isProductVariantUnmarkedAsCandidate } from "../../../../domain/selection/model/productVariantUnmarkedAsCandidate";
import { HttpSelectionsSaveFunction } from "./httpSelections";

interface HttpSelectionsUnmarkProductVariantAsCandidateFunction extends HttpSelectionsSaveFunction {}

const httpSelectionsUnmarkProductVariantAsCandidate: HttpSelectionsUnmarkProductVariantAsCandidateFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const productVariantUnmarkedAsCandidate = domainEvents.find(isProductVariantUnmarkedAsCandidate);

    if (!productVariantUnmarkedAsCandidate) {
      return;
    }

    await httpPost({
      endpoint: "/unmark-product-variant-as-candidate",
      body: {
        selection_id: aggregateId,
        product_variant_id: productVariantUnmarkedAsCandidate.productVariantId,
      },
    });
  };

export { httpSelectionsUnmarkProductVariantAsCandidate };
