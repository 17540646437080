import React, { FC } from "react";
import { ModalNotificationProjection } from "../../../../projection/notification/notification";
import { ModalNotificationItem } from "./ModalNotificationItem";
import "./modal-notifications.css";

interface ModalNotificationsProps {
  readonly notifications?: ModalNotificationProjection[];
  readonly onRemove: (notificationId: string) => void;
}

const ModalNotifications: FC<ModalNotificationsProps> = ({ notifications = [], onRemove }) => (
  <div className="modal-notifications" data-testid="modal-notifications">
    {notifications.map((notification) => (
      <ModalNotificationItem
        key={notification.id}
        notification={notification}
        testID={notification.id}
        onRemove={onRemove}
      />
    ))}
  </div>
);

export { ModalNotifications };
