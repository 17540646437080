import { FC, ReactNode } from "react";
import useBoxSharedContext from "../_behaviors/useBoxSharedContext";
import { useIsTheBoxOpen } from "../../../core/infrastructure/projection/box/react/useIsTheBoxOpen";
import Shortlist from "./Shortlist";

interface ShortlistForBoxProps {
  readonly children?: ReactNode;
}

const ShortlistForBox: FC<ShortlistForBoxProps> = ({ children }) => {
  const { box } = useBoxSharedContext();
  const [isTheBoxOpen = false] = useIsTheBoxOpen({ boxId: box.id });

  return isTheBoxOpen ? <Shortlist>{children}</Shortlist> : null;
};

export { ShortlistForBox };
