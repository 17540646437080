import { FC, ReactNode } from "react";
import classNames from "classnames";
import Text, { TextVariant } from "../../atoms/text/Text";
import "./card.css";

interface CardProps {
  readonly className?: string;
  readonly title?: string;
  readonly children: ReactNode;
  readonly label?: string;
}

const Card: FC<CardProps> = ({ className, title, children, label }: CardProps) => (
  <div aria-label={label} className={classNames("card", className)}>
    {title && (
      <Text className="card__title" variant={TextVariant.BODY_BOLD}>
        {title}
      </Text>
    )}
    {children}
  </div>
);

export default Card;
