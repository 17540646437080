import { FC, ReactNode, useCallback, useEffect, useRef } from "react";
import Button from "../../../../../../shared/ui/uiKit/atoms/button/Button";
import { IconVariant } from "../../../../../../shared/ui/uiKit/atoms/icon/Icon";
import classNames from "classnames";
import { useBoundingClientRect } from "../../../../../../shared/ui/uiKit/_behaviors/useBoundingClientRect/useBoundingClientRect";
import "./box-tab-section.css";

const ARROW_HEIGHT = 20;
const VIEWPORT_MARGIN = 8;

interface BoxTabSectionProps {
  readonly label?: string;
  readonly icon: IconVariant;
  readonly onClick: () => void;
  readonly counter?: number;
  readonly enabled: boolean;
  readonly active?: boolean;
  readonly children?: ReactNode;
  readonly hovered?: boolean;
  readonly onHoverChanged?: (hovered: boolean) => void;
  readonly viewportRect?: DOMRect;
  readonly position?: "top" | "bottom";
}

const BoxTabSection: FC<BoxTabSectionProps> = ({
  label = "box-tab-section",
  icon,
  onClick,
  enabled,
  active = false,
  counter,
  children,
  hovered,
  onHoverChanged,
  viewportRect = {} as DOMRect,
  position = "top",
}) => {
  const handleOnClick = useCallback(() => {
    if (!enabled) {
      return;
    }

    onClick();
  }, [enabled, onClick]);
  const mouseLeaveTimeoutRef = useRef<NodeJS.Timeout>();
  const handleOnMouseEnter = useCallback(() => {
    if (!enabled) {
      return;
    }

    clearTimeout(mouseLeaveTimeoutRef.current);
    onHoverChanged?.(true);
  }, [enabled, onHoverChanged]);
  const handleOnMouseLeave = useCallback(() => {
    if (!enabled) {
      return;
    }

    mouseLeaveTimeoutRef.current = setTimeout(() => onHoverChanged?.(false), 500);
  }, [enabled, onHoverChanged]);
  useEffect(() => () => clearTimeout(mouseLeaveTimeoutRef.current), []);

  const buttonRef = useRef<HTMLDivElement>(null);
  const buttonRect = useBoundingClientRect({ ref: buttonRef });
  const { top: buttonTop = 0, bottom: buttonBottom = 0, height: buttonHeight = 0 } = buttonRect || {};
  const { top: viewportTop = 0, bottom: viewportBottom = 0, width: viewportWidth = 0 } = viewportRect;
  let arrowTop, arrowBottom, contentTop, contentBottom;
  const contentLeft = viewportWidth + VIEWPORT_MARGIN;
  const contentMaxHeight = `calc(100% - ${VIEWPORT_MARGIN * 2}px)`;

  if (position === "top") {
    contentTop = VIEWPORT_MARGIN;
    arrowTop = buttonTop - viewportTop - contentTop + buttonHeight / 2 - ARROW_HEIGHT / 2;
  } else {
    contentBottom = VIEWPORT_MARGIN;
    arrowBottom = viewportBottom - buttonBottom - contentBottom + buttonHeight / 2 - ARROW_HEIGHT / 2;
  }

  return (
    <>
      <div
        ref={buttonRef}
        aria-label={label}
        className="box-tab-section"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <Button
          aria-label="box-tab-section-button"
          className={classNames("box-tab-section__button", { "box-tab-section__button--active": enabled && active })}
          disabled={!enabled}
          icon={icon}
          onClick={handleOnClick}
        />
        {counter ? (
          <span aria-label="box-tab-section-counter" className="box-tab-section__counter">
            {counter}
          </span>
        ) : null}
      </div>
      {children && enabled && (
        <div
          aria-label="box-tab-section-content"
          className="box-tab-section__content"
          style={{
            left: contentLeft,
            top: contentTop,
            bottom: contentBottom,
            maxHeight: contentMaxHeight,
            visibility: hovered ? "visible" : "hidden",
          }}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <div className="box-tab-section__content-arrow" style={{ top: arrowTop, bottom: arrowBottom }}>
            <svg viewBox="0 0 12 20">
              <path d="M-1.19249e-07 10L12 20L12 1.43099e-07L-1.19249e-07 10Z" />
            </svg>
          </div>
          <div className="box-tab-section__content-card">{children}</div>
        </div>
      )}
    </>
  );
};

export { BoxTabSection };
