import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { BoxProjection } from "./box";

const VIEW_BOX_BY_ID = "view_box_by_id";

interface ViewBoxByIdPayload {
  readonly id: string;
}

interface ViewBoxById extends Query<typeof VIEW_BOX_BY_ID>, ViewBoxByIdPayload {}

interface ViewBoxByIdFunction {
  (payload: ViewBoxByIdPayload): ViewBoxById;
}

const viewBoxById: ViewBoxByIdFunction = (payload) => ({
  ...query({ name: VIEW_BOX_BY_ID }),
  ...payload,
});

type ViewBoxByIdResult = BoxProjection | null;

interface BoxByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface BoxByIdView {
  (args: BoxByIdViewArgs): Promise<ViewBoxByIdResult>;
}

interface ViewBoxByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: BoxByIdView;
}

const viewBoxByIdHandler: QueryHandlerFunction<ViewBoxById, ViewBoxByIdResult, ViewBoxByIdHandlerFunctionArgs> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type { BoxByIdView, ViewBoxById, ViewBoxByIdResult };
export { VIEW_BOX_BY_ID, viewBoxById, viewBoxByIdHandler };
