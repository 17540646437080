import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import Country from "../../domain/country/model/Country";
import { PersonalShopperProductionProjection } from "./personalShopperProduction";

const VIEW_PERSONAL_SHOPPER_PRODUCTION_BY_CRITERIA = "view_personal_shopper_production_by_criteria";

interface ViewPersonalShopperProductionByCriteriaPayload {
  readonly country?: Country;
  readonly team?: string;
}

interface ViewPersonalShopperProductionByCriteria
  extends Query<typeof VIEW_PERSONAL_SHOPPER_PRODUCTION_BY_CRITERIA>,
    ViewPersonalShopperProductionByCriteriaPayload {}

interface ViewPersonalShopperProductionByCriteriaFunction {
  (payload: ViewPersonalShopperProductionByCriteriaPayload): ViewPersonalShopperProductionByCriteria;
}

const viewPersonalShopperProductionByCriteria: ViewPersonalShopperProductionByCriteriaFunction = (payload) => ({
  ...query({ name: VIEW_PERSONAL_SHOPPER_PRODUCTION_BY_CRITERIA }),
  ...payload,
});

type ViewPersonalShopperProductionByCriteriaResult = PersonalShopperProductionProjection[] | null;

interface PersonalShopperProductionByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly country?: Country;
  readonly team?: string;
}

interface PersonalShopperProductionByCriteriaView {
  (args: PersonalShopperProductionByCriteriaViewArgs): Promise<ViewPersonalShopperProductionByCriteriaResult>;
}

interface ViewPersonalShopperProductionByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PersonalShopperProductionByCriteriaView;
}

const viewPersonalShopperProductionByCriteriaHandler: QueryHandlerFunction<
  ViewPersonalShopperProductionByCriteria,
  ViewPersonalShopperProductionByCriteriaResult,
  ViewPersonalShopperProductionByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ country, team }) =>
    view({ country, team, signal });

export {
  VIEW_PERSONAL_SHOPPER_PRODUCTION_BY_CRITERIA,
  viewPersonalShopperProductionByCriteria,
  viewPersonalShopperProductionByCriteriaHandler,
};
export type { PersonalShopperProductionByCriteriaView, ViewPersonalShopperProductionByCriteriaResult };
