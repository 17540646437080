import { useCallback, ChangeEvent, FC, KeyboardEvent } from "react";
import classNames from "classnames";
import Error from "../../atoms/error/Error";
import Text, { TextVariant } from "../../atoms/text/Text";
import Icon, { IconVariant } from "../../atoms/icon/Icon";
import "./input.css";

interface InputProps {
  readonly className?: string;
  readonly icon?: IconVariant;
  readonly placeholder?: string;
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly onBlur?: () => void;
  readonly error?: string;
  readonly maxLength?: number;
  readonly type?: "text" | "search" | "number";
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
}
const Input: FC<InputProps> = ({
  className,
  icon,
  placeholder,
  type = "text",
  value,
  onChange,
  onBlur,
  maxLength,
  error,
  disabled = false,
  readOnly = false,
}: InputProps) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      if (maxLength === undefined || value.length <= maxLength) {
        onChange(value);
      }
    },
    [maxLength, onChange],
  );

  const handleOnKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => event.stopPropagation(), []);

  return (
    <div
      className={classNames("input", className, {
        "input--error": Boolean(error),
        "input--max-length": maxLength !== undefined,
        "input--disabled": disabled,
        "input--readonly": readOnly,
        "input--with-icon": icon,
      })}
    >
      <div className="input__container">
        <input
          className="input__input"
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
        />
        {maxLength && (
          <Text className="input__max-length" variant={TextVariant.BODY_SMALL}>
            <span className="input__max-length-count">{value.length}</span>/{maxLength}
          </Text>
        )}
        {icon && <Icon variant={icon} />}
        <span className="input__indicator" />
      </div>
      <Error error={error} />
    </div>
  );
};

export default Input;
