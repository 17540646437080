import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { isTheBoxOpen, IsTheBoxOpenResult } from "../../../../projection/box/isTheBoxOpen";
import { isBoxClosed } from "../../../../domain/box/model/boxClosed";

interface UseIsTheBoxOpenFunctionArgs {
  readonly boxId: string;
}

interface UseIsTheBoxOpenFunction {
  (args: UseIsTheBoxOpenFunctionArgs): UseQueryFunctionResult<IsTheBoxOpenResult>;
}

const useIsTheBoxOpen: UseIsTheBoxOpenFunction = ({ boxId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: isTheBoxOpen({ boxId }),
    invalidation: isBoxClosed,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useIsTheBoxOpen };
