import BoxPreviewStatus from "../../../domain/boxPreview/model/BoxPreviewStatus";
import Country from "../../../domain/country/model/Country";
import { BoxPreview, BoxPreviewProductVariant, ReplacedFor } from "../../../projection/boxPreview/boxPreview";
import Color from "../../../projection/color/model/Color";
import Media from "../../../projection/media/model/Media";
import Price from "../../../projection/price/model/Price";
import { ProductFeatureDto, toFeatureProjection } from "../product/productDetail";

interface BoxPreviewProductVariantDto {
  readonly brand: string;
  readonly color: Color;
  readonly features: ProductFeatureDto[];
  readonly id: string;
  readonly isCandidate: boolean;
  readonly media: Media[];
  readonly price: Price;
  readonly selected: boolean | null;
  readonly title: string;
}

interface BoxPreviewDto {
  readonly boxId: string;
  readonly comment: string;
  readonly countryCode: string;
  readonly customerId: string;
  readonly expiration: string; // YYYY-MM-DD HH:MM:SS
  readonly id: string;
  readonly looks: string[][];
  readonly notReplacedProductVariants: Record<string, string>;
  readonly productVariants: BoxPreviewProductVariantDto[];
  readonly replacedFor: Record<string, ReplacedFor> | undefined;
  readonly sentOn: string; // YYYY-MM-DD HH:MM:SS
  readonly status: string;
}

const boxPreviewProductVariantFromResponse = ({
  brand,
  selected,
  color,
  features,
  id,
  isCandidate,
  media,
  price,
  title,
}: BoxPreviewProductVariantDto): BoxPreviewProductVariant => ({
  brand,
  chosen: selected,
  color,
  features: features ? features.map((feature) => toFeatureProjection({ feature })) : [],
  id,
  isCandidate,
  media: media as Media[],
  price,
  title,
});

const toBoxPreviewProjection = ({
  boxId,
  comment,
  countryCode,
  customerId,
  expiration,
  id,
  looks,
  notReplacedProductVariants,
  productVariants,
  replacedFor,
  sentOn,
  status,
}: BoxPreviewDto): BoxPreview => ({
  boxId,
  comment,
  countryCode: countryCode as Country,
  customerId,
  expiration: new Date(expiration),
  id,
  looks,
  notReplacedProductVariants,
  productVariants: productVariants.map(boxPreviewProductVariantFromResponse),
  replacedFor: replacedFor || {},
  sentOn: new Date(sentOn),
  status: status as BoxPreviewStatus,
});

export type { BoxPreviewDto };
export { toBoxPreviewProjection };
