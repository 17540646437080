import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ProductDetailProjection } from "./productDetail";

const VIEW_PRODUCT_BY_ID = "view_product_by_id";

interface ViewProductByIdPayload {
  readonly id: string;
  readonly boxId: string;
}

interface ViewProductById extends Query<typeof VIEW_PRODUCT_BY_ID>, ViewProductByIdPayload {}

interface ViewProductByIdFunction {
  (payload: ViewProductByIdPayload): ViewProductById;
}

const viewProductById: ViewProductByIdFunction = (payload) => ({
  ...query({ name: VIEW_PRODUCT_BY_ID }),
  ...payload,
});

type ViewProductByIdResult = ProductDetailProjection | null;

interface ProductByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
  readonly boxId: string;
}

interface ProductByIdView {
  (args: ProductByIdViewArgs): Promise<ViewProductByIdResult>;
}

interface ViewProductByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ProductByIdView;
}

const viewProductByIdHandler: QueryHandlerFunction<
  ViewProductById,
  ViewProductByIdResult,
  ViewProductByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ id, boxId }) =>
    view({ id, boxId, signal });

export type { ProductByIdView, ViewProductById, ViewProductByIdResult };
export { VIEW_PRODUCT_BY_ID, viewProductById, viewProductByIdHandler };
