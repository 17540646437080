import { command, Command } from "@lookiero/messaging";

const UNMARK_PRODUCT_AS_FAVOURITE = "unmark_product_as_favourite";

interface UnmarkProductAsFavouritePayload {
  readonly aggregateId: string;
}

interface UnmarkProductAsFavourite extends Command<typeof UNMARK_PRODUCT_AS_FAVOURITE> {}

interface UnmarkProductAsFavouriteFunction {
  (payload: UnmarkProductAsFavouritePayload): UnmarkProductAsFavourite;
}

const unmarkProductAsFavourite: UnmarkProductAsFavouriteFunction = ({ aggregateId }) =>
  command({ aggregateId, name: UNMARK_PRODUCT_AS_FAVOURITE });

export type { UnmarkProductAsFavourite };
export { UNMARK_PRODUCT_AS_FAVOURITE, unmarkProductAsFavourite };
