import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpAnnotationUpdated } from "./httpAnnotationUpdated";
import { AnnotationsGetFunction, AnnotationsSaveFunction } from "../../../../domain/annotation/model/annotations";
import {
  ViewAnnotationById,
  ViewAnnotationByIdResult,
  viewAnnotationById,
} from "../../../../projection/annotation/viewAnnotationById";
import { Annotation } from "../../../../domain/annotation/model/annotation";
import invariant from "ts-invariant";

interface HttpAnnotationsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpAnnotationsGetFunction extends AnnotationsGetFunction<HttpAnnotationsGetFunctionArgs> {}

interface ToDomainFunction {
  (boxPreview: ViewAnnotationByIdResult): Annotation | never;
}

const toDomain: ToDomainFunction = (annotation) => {
  invariant(annotation, "No annotation found!");

  return {
    aggregateId: annotation.id,
    customerId: annotation.customerId,
    negative: annotation.negative,
    positive: annotation.positive,
    domainEvents: [],
  };
};

const getAnnotation: HttpAnnotationsGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(await queryBus<ViewAnnotationById, ViewAnnotationByIdResult>(viewAnnotationById({ id: aggregateId })));

interface HttpAnnotationsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpAnnotationsSaveFunction extends AnnotationsSaveFunction<HttpAnnotationsSaveFunctionArgs> {}

const saveAnnotation: HttpAnnotationsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await httpAnnotationUpdated({ httpPost })(aggregateRoot);
  };

export type { HttpAnnotationsSaveFunction };
export { getAnnotation, saveAnnotation };
