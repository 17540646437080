import { FC } from "react";
import { useViewForecastByCustomerId } from "../../../../../core/infrastructure/projection/forecast/react/useViewForecastByCustomerId";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CUSTOMER_I18N_PREFIX, CustomerI18n } from "../../../../componentLibrary/_i18n/CustomerI18n";
import "./forecast.css";

interface ForecastProps {
  readonly city: string;
  readonly region: string;
  readonly customerId: string;
}
const Forecast: FC<ForecastProps> = ({ city, region, customerId }) => {
  const [forecast] = useViewForecastByCustomerId({
    customerId,
  });
  const forecastText = useI18nMessage({ id: CustomerI18n.FORECAST_TEXT, prefix: CUSTOMER_I18N_PREFIX });

  return (
    <div aria-label="forecast" className="forecast">
      <Text className="forecast__city-region" variant={TextVariant.BODY_SMALL}>
        {city}, {region}
      </Text>

      {forecast && (
        <Text variant={TextVariant.BODY_BOLD}>
          {Math.round(forecast.averageMinTemperature)}º ~ {Math.round(forecast.averageMaxTemperature)}º {forecastText}
        </Text>
      )}
    </div>
  );
};

export { Forecast };
