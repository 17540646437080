import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { NoteTemplateProjection } from "./noteTemplate";
import { NoteTemplatesCountByCriteria } from "./countNoteTemplatesByCriteria";

interface NoteTemplatesSearchByCriteria extends NoteTemplatesCountByCriteria {
  readonly page: number;
  readonly perPage: number;
}

const SEARCH_NOTE_TEMPLATES_BY_CRITERIA = "search_note_templates_by_criteria";

interface SearchNoteTemplatesByCriteriaPayload {
  readonly criteria: NoteTemplatesSearchByCriteria;
}

interface SearchNoteTemplatesByCriteria
  extends Query<typeof SEARCH_NOTE_TEMPLATES_BY_CRITERIA>,
    SearchNoteTemplatesByCriteriaPayload {}

interface SearchNoteTemplatesByCriteriaFunction {
  (payload: SearchNoteTemplatesByCriteriaPayload): SearchNoteTemplatesByCriteria;
}

const searchNoteTemplatesByCriteria: SearchNoteTemplatesByCriteriaFunction = (payload) => ({
  ...query({ name: SEARCH_NOTE_TEMPLATES_BY_CRITERIA }),
  ...payload,
});

type SearchNoteTemplatesByCriteriaResult = NoteTemplateProjection[] | null;

interface NoteTemplatesSearchByCriteriaViewArgs extends CancelableQueryViewArgs {
  readonly criteria: NoteTemplatesSearchByCriteria;
}

interface NoteTemplatesSearchByCriteriaView {
  (args: NoteTemplatesSearchByCriteriaViewArgs): Promise<SearchNoteTemplatesByCriteriaResult>;
}

interface SearchNoteTemplatesByCriteriaHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: NoteTemplatesSearchByCriteriaView;
}

const searchNoteTemplatesByCriteriaHandler: QueryHandlerFunction<
  SearchNoteTemplatesByCriteria,
  SearchNoteTemplatesByCriteriaResult,
  SearchNoteTemplatesByCriteriaHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ criteria }) =>
    view({ criteria, signal });

export type { NoteTemplatesSearchByCriteriaView, SearchNoteTemplatesByCriteriaResult };
export { SEARCH_NOTE_TEMPLATES_BY_CRITERIA, searchNoteTemplatesByCriteria, searchNoteTemplatesByCriteriaHandler };
