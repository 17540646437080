import { isSelectionOrderRemoved } from "../../../../domain/selectionOrder/model/selectionOrderRemoved";
import { StorageSelectionOrdersSaveFunction } from "./storageSelectionOrders";

interface StorageSelectionOrdersRemoveFunction extends StorageSelectionOrdersSaveFunction {}

const storageSelectionOrdersRemove: StorageSelectionOrdersRemoveFunction =
  ({ storage: { remove } }) =>
  async ({ boxNumber, domainEvents }) => {
    const selectionOrderRemoved = domainEvents.find(isSelectionOrderRemoved);

    if (!selectionOrderRemoved) {
      return;
    }

    await remove(boxNumber);
  };

export { storageSelectionOrdersRemove };
