import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { SelectionOrderProjection } from "./selectionOrder";

const VIEW_SELECTION_ORDER_BY_BOX_NUMBER = "view_selection_order_by_box_number";

interface ViewSelectionOrderByBoxNumberPayload {
  readonly boxNumber: string;
}

interface ViewSelectionOrderByBoxNumber
  extends Query<typeof VIEW_SELECTION_ORDER_BY_BOX_NUMBER>,
    ViewSelectionOrderByBoxNumberPayload {}

interface ViewSelectionOrderByBoxNumberFunction {
  (payload: ViewSelectionOrderByBoxNumberPayload): ViewSelectionOrderByBoxNumber;
}

const viewSelectionOrderByBoxNumber: ViewSelectionOrderByBoxNumberFunction = (payload) => ({
  ...query({ name: VIEW_SELECTION_ORDER_BY_BOX_NUMBER }),
  ...payload,
});

type ViewSelectionOrderByBoxNumberResult = SelectionOrderProjection | null;

interface SelectionOrderByBoxNumberViewArgs extends CancelableQueryViewArgs {
  readonly boxNumber: string;
}

interface SelectionOrderByBoxNumberView {
  (args: SelectionOrderByBoxNumberViewArgs): Promise<ViewSelectionOrderByBoxNumberResult>;
}

interface ViewSelectionOrderByBoxNumberHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: SelectionOrderByBoxNumberView;
}

const viewSelectionOrderByBoxNumberHandler: QueryHandlerFunction<
  ViewSelectionOrderByBoxNumber,
  ViewSelectionOrderByBoxNumberResult,
  ViewSelectionOrderByBoxNumberHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxNumber }) =>
    view({ boxNumber, signal });

export type { SelectionOrderByBoxNumberView, ViewSelectionOrderByBoxNumber, ViewSelectionOrderByBoxNumberResult };
export { VIEW_SELECTION_ORDER_BY_BOX_NUMBER, viewSelectionOrderByBoxNumber, viewSelectionOrderByBoxNumberHandler };
