import { PersonalShopperTeamProjection } from "../../../../projection/personalShopperTeam/personalShopperTeam";
import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listPersonalShopperTeams } from "../../../../projection/personalShopperTeam/listPersonalShopperTeams";

interface UseListPersonalShopperTeamsFunction {
  (): UseQueryFunctionResult<PersonalShopperTeamProjection[]>;
}

const useListPersonalShopperTeams: UseListPersonalShopperTeamsFunction = () =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listPersonalShopperTeams(),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useListPersonalShopperTeams };
