import { useCallback } from "react";
import invariant from "ts-invariant";
import { v4 as uuid } from "uuid";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { generateNote } from "../../../../domain/note/command/generateNote";
import { useI18nMessage } from "@lookiero/i18n-react";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../../../../ui/componentLibrary/_i18n/NoteI18n";
import { useCreateToastNotification } from "../../../../../shared/notifications/infrastructure/domain/notification/react/useCreateToastNotification";
import { useLogger } from "../../../../../shared/logging/useLogger";
import { NotificationLevel } from "../../../../../shared/notifications/domain/notification/model/notification";

interface GenerateNoteFunction {
  (): Promise<void>;
}

interface UseGenerateNoteFunctionArgs {
  readonly id?: string;
  readonly boxId: string;
  readonly psId?: string;
}

interface UseGenerateNoteFunction {
  (args: UseGenerateNoteFunctionArgs): [generate: GenerateNoteFunction, status: CommandStatus];
}

const useGenerateNote: UseGenerateNoteFunction = ({ id, psId, boxId }) => {
  const logger = useLogger();
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });
  const errorMessage = useI18nMessage({
    id: NoteI18n.GENERATE_NOTE_ERROR,
    prefix: NOTE_I18N_PREFIX,
  });

  const generate: GenerateNoteFunction = useCallback(async () => {
    invariant(psId, "Cannot generate a note without a psId");

    try {
      await commandBus(generateNote({ aggregateId: id ?? uuid(), boxId, psId }));
    } catch (error) {
      createNotification({
        aggregateId: "generate-note-error",
        level: NotificationLevel.ERROR,
        bodyI18nKey: errorMessage,
      });
    }
  }, [boxId, commandBus, createNotification, errorMessage, id, psId]);

  return [generate, status];
};

export { useGenerateNote };
