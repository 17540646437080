import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const ANNOTATION_UPDATED = "annotation_updated";

interface AnnotationUpdatedPayload {
  readonly aggregateId: string;
}

interface AnnotationUpdated extends DomainEvent<typeof ANNOTATION_UPDATED> {}

interface AnnotationUpdatedFunction {
  (payload: AnnotationUpdatedPayload): AnnotationUpdated;
}

const annotationUpdated: AnnotationUpdatedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: ANNOTATION_UPDATED });

const isAnnotationUpdated = (event: DomainEvent<MessageName>): event is AnnotationUpdated =>
  event.name === ANNOTATION_UPDATED;

export type { AnnotationUpdated };
export { ANNOTATION_UPDATED, annotationUpdated, isAnnotationUpdated };
