import { IsTheBoxOpenView, IsTheBoxOpenResult } from "../../../projection/box/isTheBoxOpen";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpIsTheBoxOpenView extends IsTheBoxOpenView {}

interface HttpIsTheBoxOpenViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpIsTheBoxOpenViewFunction {
  (args: HttpIsTheBoxOpenViewFunctionArgs): HttpIsTheBoxOpenView;
}

interface IsTheBoxOpenResponse {
  readonly result: IsTheBoxOpenResult;
}

const httpIsTheBoxOpenView: HttpIsTheBoxOpenViewFunction =
  ({ httpPost }) =>
  async ({ boxId, signal }) =>
    await httpPost<IsTheBoxOpenResponse, IsTheBoxOpenResult>({
      endpoint: "/is-the-box-open",
      body: { box_id: boxId },
      signal,
      result: {
        error: false,
        success: (response) => response.result,
      },
    });

export { httpIsTheBoxOpenView };
