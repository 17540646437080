import { FC } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import {
  StyleProfilePrice3QuestionsExperiment,
  StyleProfileTranslatable,
} from "../../../../../../core/projection/styleProfile/styleProfile";
import { Answer } from "../../answer/Answer";
import { TextQuestionItem } from "../../../../../componentLibrary/organisms/styleProfileQuestion/components/textQuestionItem/TextQuestionItem";
import { PRICE_EXPERIMENT_I18N_PREFIX } from "../../../../../componentLibrary/_i18n/CustomerI18n";
import "./price-3-questions-experiment.css";

type PriceTuple = [keyof StyleProfilePrice3QuestionsExperiment, StyleProfileTranslatable | null];

interface Price3QuestionsExperimentProps {
  readonly price: StyleProfilePrice3QuestionsExperiment;
  readonly previousPrice?: StyleProfilePrice3QuestionsExperiment;
}

const Price3QuestionsExperiment: FC<Price3QuestionsExperimentProps> = ({ price, previousPrice }) => (
  <div className="price-3-questions-experiment">
    {(
      [
        ["blouse", price.blouse],
        ["denim", price.denim],
        ["dress", price.dress],
      ] as PriceTuple[]
    ).map(
      ([key, value]) =>
        value && (
          <div key={key} className="price-3-questions-experiment__value">
            <TextQuestionItem>
              <I18nMessage id={key} prefix={PRICE_EXPERIMENT_I18N_PREFIX} />
            </TextQuestionItem>
            <Answer answer={value} previousAnswer={previousPrice?.[key]}>
              {(value) => <TextQuestionItem>{value}</TextQuestionItem>}
            </Answer>
          </div>
        ),
    )}
  </div>
);
export { Price3QuestionsExperiment };
