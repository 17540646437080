import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { NotificationProjection } from "./notification";

const LIST_NOTIFICATIONS = "list_notifications";

interface ListNotifications extends Query<typeof LIST_NOTIFICATIONS> {}

interface ListNotificationsFunction {
  (): ListNotifications;
}

const listNotifications: ListNotificationsFunction = () => query({ name: LIST_NOTIFICATIONS });

type ListNotificationsResult = NotificationProjection[];

interface ListNotificationsView {
  (): Promise<ListNotificationsResult>;
}

interface ListNotificationsHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ListNotificationsView;
}

const listNotificationsHandler: QueryHandlerFunction<
  ListNotifications,
  ListNotificationsResult,
  ListNotificationsHandlerFunctionArgs
> =
  ({ view }) =>
  async () =>
    view();

export type { ListNotifications, ListNotificationsView, ListNotificationsResult };
export { LIST_NOTIFICATIONS, listNotifications, listNotificationsHandler };
