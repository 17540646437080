import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const NOTE_TEMPLATE_REMOVED = "note_template_removed";

interface NoteTemplateRemovedPayload {
  readonly aggregateId: string;
}

interface NoteTemplateRemoved extends DomainEvent<typeof NOTE_TEMPLATE_REMOVED> {}

interface NoteTemplateRemovedFunction {
  (payload: NoteTemplateRemovedPayload): NoteTemplateRemoved;
}

const noteTemplateRemoved: NoteTemplateRemovedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: NOTE_TEMPLATE_REMOVED });

const isNoteTemplateRemoved = (event: DomainEvent<MessageName>): event is NoteTemplateRemoved =>
  event.name === NOTE_TEMPLATE_REMOVED;

export type { NoteTemplateRemoved };
export { NOTE_TEMPLATE_REMOVED, noteTemplateRemoved, isNoteTemplateRemoved };
