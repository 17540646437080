import {
  AnnotationByCustomerIdView,
  ViewAnnotationByCustomerIdResult,
} from "../../../../projection/annotation/viewAnnotationByCustomerId";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { AnnotationProjection } from "../../../../projection/annotation/annotation";

interface HttpAnnotationByCustomerIdView extends AnnotationByCustomerIdView {}

interface HttpAnnotationByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpAnnotationByCustomerIdViewFunction {
  (args: HttpAnnotationByCustomerIdViewFunctionArgs): HttpAnnotationByCustomerIdView;
}

const httpAnnotationByCustomerIdView: HttpAnnotationByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<AnnotationProjection, ViewAnnotationByCustomerIdResult>({
      endpoint: "/view-annotation-by-customer-id",
      body: { customerId },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpAnnotationByCustomerIdView };
