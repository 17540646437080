enum Sizing {
  // LARGE_SIZE_PLUS_3 = "large_size_plus3", // Is this really being used??
  BIG_3 = "big_3",
  BIG_2 = "big_2",
  BIG_1 = "big_1",
  BIG = "big",
  NORMAL = "normal",
  SMALL = "small",
  SMALL_1 = "small_1",
  SMALL_2 = "small_2",
  SMALL_3 = "small_3",
  // SMALL_SIZE_LESS_3 = "small_size_less3", // Is this really being used??
}

export default Sizing;
