import { command, Command } from "@lookiero/messaging";

const UPDATE_ANNOTATION = "update_annotation";

interface UpdateAnnotationPayload {
  readonly aggregateId: string;
  readonly customerId: string;
  readonly positive: string;
  readonly negative: string;
}

interface UpdateAnnotation extends Command<typeof UPDATE_ANNOTATION>, UpdateAnnotationPayload {}

interface UpdateAnnotationFunction {
  (payload: UpdateAnnotationPayload): UpdateAnnotation;
}

const updateAnnotation: UpdateAnnotationFunction = ({ aggregateId, ...payload }) => ({
  ...command({ name: UPDATE_ANNOTATION, aggregateId }),
  ...payload,
});

export type { UpdateAnnotation };
export { UPDATE_ANNOTATION, updateAnnotation };
