import { createContext, FC, ReactNode, useCallback, useContext, useReducer } from "react";
import { invariant } from "ts-invariant";
import useBoxSharedContext from "./useBoxSharedContext";
import { ProductVariantProjection } from "../../../core/projection/product/productVariant";
import { useViewProductById } from "../../../core/infrastructure/projection/product/react/useViewProductById";

enum ProductVariantComparerAction {
  ADD = "add",
  REMOVE = "remove",
  CLEAR = "clear",
}
type ProductVariantComparerAddAction = {
  readonly type: ProductVariantComparerAction.ADD;
  readonly payload: {
    readonly productId: string;
    readonly productVariant: ProductVariantProjection;
  };
};
type ProductVariantComparerRemoveAction = {
  readonly type: ProductVariantComparerAction.REMOVE;
  readonly payload: {
    readonly productVariant: ProductVariantProjection;
  };
};
type ProductVariantComparerClearAction = {
  readonly type: ProductVariantComparerAction.CLEAR;
};

type ProductVariantComparerState = {
  readonly productIds: [string | null, string | null];
  readonly productVariants: [ProductVariantProjection | null, ProductVariantProjection | null];
};

const initialState: Readonly<ProductVariantComparerState> = {
  productIds: [null, null],
  productVariants: [null, null],
};

const reducer = (
  state: ProductVariantComparerState,
  action: ProductVariantComparerAddAction | ProductVariantComparerRemoveAction | ProductVariantComparerClearAction,
): ProductVariantComparerState => {
  const { productIds, productVariants } = state;

  switch (action.type) {
    case ProductVariantComparerAction.ADD:
      const { productId, productVariant: productVariantToBeAdded } = action.payload;

      if (
        state.productVariants.find(
          (productVariant: ProductVariantProjection | null) => productVariant?.id === productVariantToBeAdded.id,
        )
      ) {
        return state;
      }

      return {
        productIds: [productId, productIds[0] || null],
        productVariants: [productVariantToBeAdded, productVariants[0] || null],
      };
    case ProductVariantComparerAction.REMOVE:
      const { productVariant: productVariantToBeRemoved } = action.payload;

      const updatedProductVariants = productVariants.filter(
        (prevProductVariant) => prevProductVariant?.id !== productVariantToBeRemoved.id,
      );
      const productVariantIndex = productVariants.findIndex(
        (prevProductVariant) => prevProductVariant?.id !== productVariantToBeRemoved.id,
      );

      return {
        productIds: [productIds[productVariantIndex], null],
        productVariants: [updatedProductVariants[0] || null, null],
      };
    case ProductVariantComparerAction.CLEAR:
      return initialState;
  }
};

type ProductVariantStack = [ProductVariantProjection | null, ProductVariantProjection | null];

type ProductVariantComparerContextApi = {
  readonly productVariants: ProductVariantStack;
  readonly productVariantDetails: ProductVariantStack;
  readonly add: (productId: string, productVariant: ProductVariantProjection) => void;
  readonly remove: (productVariant: ProductVariantProjection) => void;
  readonly clear: () => void;
};
const ProductVariantComparerContext = createContext<ProductVariantComparerContextApi>(
  null as unknown as ProductVariantComparerContextApi,
);

type ProductVariantComparerContextProviderProps = {
  readonly children: ReactNode;
};

const ProductVariantComparerProvider: FC<ProductVariantComparerContextProviderProps> = ({
  children,
}: ProductVariantComparerContextProviderProps) => {
  const { box } = useBoxSharedContext();
  const [{ productIds, productVariants }, dispatch] = useReducer(reducer, initialState);

  const add = useCallback(
    (productId: string, productVariant: ProductVariantProjection) =>
      dispatch({
        type: ProductVariantComparerAction.ADD,
        payload: { productId, productVariant },
      }),
    [],
  );
  const remove = useCallback(
    (productVariant: ProductVariantProjection) =>
      dispatch({
        type: ProductVariantComparerAction.REMOVE,
        payload: { productVariant },
      }),
    [],
  );
  const clear = useCallback(() => dispatch({ type: ProductVariantComparerAction.CLEAR }), []);

  const [firstProductDetail] = useViewProductById({ boxId: box.id, productId: productIds[0] || undefined });
  const [secondProductDetail] = useViewProductById({ boxId: box.id, productId: productIds[1] || undefined });
  const productVariantDetails: ProductVariantStack = [
    firstProductDetail?.productVariants.find((productVariant) => productVariant.id === productVariants[0]?.id) || null,
    secondProductDetail?.productVariants.find((productVariant) => productVariant.id === productVariants[1]?.id) || null,
  ];

  const value: ProductVariantComparerContextApi = {
    productVariants,
    productVariantDetails,
    add,
    remove,
    clear,
  };

  return <ProductVariantComparerContext.Provider value={value}>{children}</ProductVariantComparerContext.Provider>;
};

const useProductVariantComparer = (): ProductVariantComparerContextApi => {
  const productVariantComparer = useContext<ProductVariantComparerContextApi>(ProductVariantComparerContext);

  invariant(
    productVariantComparer,
    "Your are trying to use the useProductVariantComparer hook without wrapping your app with the <ProductVariantComparerProvider>.",
  );

  return productVariantComparer;
};

export { ProductVariantComparerProvider };

export default useProductVariantComparer;
