import { isNoteWritten } from "../../../../domain/note/model/noteWritten";
import { HttpNotesSaveFunction } from "./httpNotes";

interface HttpNoteWriteFunction extends HttpNotesSaveFunction {}

const httpNoteWrite: HttpNoteWriteFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents, boxId, content, psId }) => {
    const noteWritten = domainEvents.find(isNoteWritten);

    if (!noteWritten) {
      return;
    }

    await httpPost({
      endpoint: "/write-note-by-id",
      body: {
        id: aggregateId,
        boxId,
        psId,
        content,
      },
    });
  };

export { httpNoteWrite };
