import Country from "../../../domain/country/model/Country";
import {
  StyleProfileCharacteristics,
  StyleProfilePreferences,
  StyleProfileStyles,
} from "../../../projection/styleProfile/styleProfile";
import { StyleProfileProjection } from "../../../projection/styleProfile/styleProfile";

interface TranslatableDto {
  readonly translationKey: string;
}

interface SocialNetworksDto {
  readonly pinterest: string | null;
  readonly instagram: string | null;
}

interface CustomerDto {
  readonly segment: TranslatableDto;
  readonly name: string;
  readonly birthdate: string;
  readonly parenthood: TranslatableDto;
  readonly employment: TranslatableDto;
  readonly photos: string[]; // uuid[]
  readonly socialNetworks: SocialNetworksDto;
  readonly address: {
    readonly city: string;
    readonly region: string;
    readonly country: string;
  };
}

interface CommentDto {
  readonly content: string;
  readonly submittedOn: string;
}

interface Price3QuestionsExperimentDto {
  readonly denim: TranslatableDto | null;
  readonly blouse: TranslatableDto | null;
}

interface ExperimentsDto {
  readonly price3Questions?: Price3QuestionsExperimentDto;
}

interface StyleProfileDto {
  readonly customer: CustomerDto;
  readonly comment: CommentDto | null;
  readonly customerServiceComment: CommentDto | null;
  readonly characteristics: StyleProfileCharacteristics;
  readonly styles: StyleProfileStyles;
  readonly preferences: StyleProfilePreferences;
  readonly experiments?: ExperimentsDto;
}

interface ToStyleProfileProjectionFunction {
  (styleProfile: StyleProfileDto): StyleProfileProjection;
}

const toStyleProfileProjection: ToStyleProfileProjectionFunction = (styleProfile) => ({
  ...styleProfile,
  customer: {
    ...styleProfile.customer,
    address: {
      ...styleProfile.customer.address,
      country: styleProfile.customer.address.country as Country,
    },
    birthdate: styleProfile.customer.birthdate ? new Date(styleProfile.customer.birthdate) : null,
  },
  comment: styleProfile.comment
    ? {
        ...styleProfile.comment,
        submittedOn: new Date(styleProfile.comment.submittedOn),
      }
    : null,
  customerServiceComment: styleProfile.customerServiceComment
    ? {
        ...styleProfile.customerServiceComment,
        submittedOn: new Date(styleProfile.customerServiceComment.submittedOn),
      }
    : null,
  experiments: styleProfile.experiments
    ? {
        ...styleProfile.experiments,
        price3Questions: styleProfile.experiments.price3Questions
          ? {
              ...styleProfile.experiments.price3Questions,
              dress: styleProfile.preferences.price,
            }
          : undefined,
      }
    : undefined,
});

export type { StyleProfileDto };
export { toStyleProfileProjection };
