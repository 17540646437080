import { FC } from "react";
import { DropdownIndicatorProps, components } from "react-select";
import FormInputOption from "../../_common/FormInputOption";
import Icon, { IconVariant } from "../../atoms/icon/Icon";

const DropdownIndicator: FC<DropdownIndicatorProps<FormInputOption, boolean>> = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon variant={IconVariant.MINOR_DROPDOWN} />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
