import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { StyleProfileProjection } from "./styleProfile";

const VIEW_STYLE_PROFILE_BY_BOX_ID = "view_style_profile_by_box_id";

interface ViewStyleProfileByBoxIdPayload {
  readonly boxId: string;
}

interface ViewStyleProfileByBoxId extends Query<typeof VIEW_STYLE_PROFILE_BY_BOX_ID>, ViewStyleProfileByBoxIdPayload {}

interface ViewStyleProfileByBoxIdFunction {
  (payload: ViewStyleProfileByBoxIdPayload): ViewStyleProfileByBoxId;
}

const viewStyleProfileByBoxId: ViewStyleProfileByBoxIdFunction = (payload) => ({
  ...query({ name: VIEW_STYLE_PROFILE_BY_BOX_ID }),
  ...payload,
});

type ViewStyleProfileByBoxIdResult = StyleProfileProjection | null;

interface StyleProfileByBoxIdViewArgs extends CancelableQueryViewArgs {
  readonly boxId: string;
}

interface StyleProfileByBoxIdView {
  (args: StyleProfileByBoxIdViewArgs): Promise<ViewStyleProfileByBoxIdResult>;
}

interface ViewStyleProfileByBoxIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: StyleProfileByBoxIdView;
}

const viewStyleProfileByBoxIdHandler: QueryHandlerFunction<
  ViewStyleProfileByBoxId,
  ViewStyleProfileByBoxIdResult,
  ViewStyleProfileByBoxIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxId }) =>
    view({ boxId, signal });

export type { ViewStyleProfileByBoxId, StyleProfileByBoxIdView, ViewStyleProfileByBoxIdResult };
export { VIEW_STYLE_PROFILE_BY_BOX_ID, viewStyleProfileByBoxId, viewStyleProfileByBoxIdHandler };
