import { isProductVariantReplaced } from "../../../../domain/selection/model/ProductVariantReplaced";
import { HttpSelectionsSaveFunction } from "./httpSelections";

interface HttpSelectionsReplaceFunction extends HttpSelectionsSaveFunction {}

const httpSelectionsReplace: HttpSelectionsReplaceFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents, boxId }) => {
    const productVariantReplaced = domainEvents.find(isProductVariantReplaced);

    if (!productVariantReplaced) {
      return;
    }

    await httpPost({
      endpoint: "/replace-product-variant",
      body: {
        selection_id: aggregateId,
        box_id: boxId,
        select_product_variant_id: productVariantReplaced.selectedProductVariantId,
        deselect_product_variant_id: productVariantReplaced.deselectedProductVariantId,
      },
    });
  };

export { httpSelectionsReplace };
