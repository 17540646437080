import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import invariant from "tiny-invariant";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import {
  PersonalShopperFilteringsGetFunction,
  PersonalShopperFilteringsSaveFunction,
} from "../../../../domain/personalShopperFiltering/model/personalShopperFilterings";
import { PersonalShopperFiltering } from "../../../../domain/personalShopperFiltering/model/personalShopperFiltering";
import { httpPersonalShopperFilteringsSet } from "./httpPersonalShopperFilteringsSet";
import {
  ViewPersonalShopperFilteringById,
  ViewPersonalShopperFilteringByIdResult,
  viewPersonalShopperFilteringById,
} from "../../../../projection/personalShopperFiltering/viewPersonalShopperFilteringById";

interface ToDomainFunction {
  (personalShopperFiltering: ViewPersonalShopperFilteringByIdResult): PersonalShopperFiltering | never;
}

const toDomain: ToDomainFunction = (personalShopperFiltering) => {
  invariant(personalShopperFiltering, "No personalShopperFiltering found!");

  return {
    aggregateId: personalShopperFiltering.id,
    boxId: personalShopperFiltering.boxId,
    customerId: personalShopperFiltering.customerId,
    positives: personalShopperFiltering.positives,
    negatives: personalShopperFiltering.negatives,
    psId: personalShopperFiltering.psId,
    domainEvents: [],
  };
};

interface HttpPersonalShopperFilteringsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpPersonalShopperFilteringsGetFunction
  extends PersonalShopperFilteringsGetFunction<HttpPersonalShopperFilteringsGetFunctionArgs> {}

const getPersonalShopperFiltering: HttpPersonalShopperFilteringsGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(
      await queryBus<ViewPersonalShopperFilteringById, ViewPersonalShopperFilteringByIdResult>(
        viewPersonalShopperFilteringById({ id: aggregateId }),
      ),
    );

interface HttpPersonalShopperFilteringsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpPersonalShopperFilteringsSaveFunction
  extends PersonalShopperFilteringsSaveFunction<HttpPersonalShopperFilteringsSaveFunctionArgs> {}

const savePersonalShopperFiltering: HttpPersonalShopperFilteringsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await httpPersonalShopperFilteringsSet({ httpPost })(aggregateRoot);
  };

export type { HttpPersonalShopperFilteringsSaveFunction };
export { getPersonalShopperFiltering, savePersonalShopperFiltering };
