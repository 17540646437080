import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ActivityTime } from "./activityTime";

const VIEW_ACTIVITY_TIME_BY_PS_ID_AND_BOX_ID = "view_activity_time_by_ps_id_and_box_id";

interface ViewActivityTimeByPsIdAndBoxIdPayload {
  readonly psId: string;
  readonly boxId: string;
}

interface ViewActivityTimeByPsIdAndBoxId
  extends Query<typeof VIEW_ACTIVITY_TIME_BY_PS_ID_AND_BOX_ID>,
    ViewActivityTimeByPsIdAndBoxIdPayload {}

interface ViewActivityTimeByPsIdAndBoxIdFunction {
  (payload: ViewActivityTimeByPsIdAndBoxIdPayload): ViewActivityTimeByPsIdAndBoxId;
}

const viewActivityTimeByPsIdAndBoxId: ViewActivityTimeByPsIdAndBoxIdFunction = (payload) => ({
  ...query({ name: VIEW_ACTIVITY_TIME_BY_PS_ID_AND_BOX_ID }),
  ...payload,
});

type ViewActivityTimeByPsIdAndBoxIdResult = ActivityTime | null;

interface ActivityTimeByPsIdAndBoxIdViewArgs extends CancelableQueryViewArgs {
  readonly psId: string;
  readonly boxId: string;
}

interface ActivityTimeByPsIdAndBoxIdView {
  (args: ActivityTimeByPsIdAndBoxIdViewArgs): Promise<ViewActivityTimeByPsIdAndBoxIdResult>;
}

interface ViewActivityTimeByPsIdAndBoxIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ActivityTimeByPsIdAndBoxIdView;
}

const viewActivityTimeByPsIdAndBoxIdHandler: QueryHandlerFunction<
  ViewActivityTimeByPsIdAndBoxId,
  ViewActivityTimeByPsIdAndBoxIdResult,
  ViewActivityTimeByPsIdAndBoxIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ psId, boxId }) =>
    view({ psId, boxId, signal });

export {
  VIEW_ACTIVITY_TIME_BY_PS_ID_AND_BOX_ID,
  viewActivityTimeByPsIdAndBoxId,
  viewActivityTimeByPsIdAndBoxIdHandler,
};
export type { ActivityTimeByPsIdAndBoxIdView, ViewActivityTimeByPsIdAndBoxId, ViewActivityTimeByPsIdAndBoxIdResult };
